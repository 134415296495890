import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import InsuredMember from "../../../../Page/Desktop/health-php/CARE/AdultOneDetails/InsuredMember";
import MInsuredMember from "../../../../Page/Mobile/health-php/CARE/MAdultOneDetails/MInsuredMember";
import { HEALTH_ROUTE } from "../../../../Router/Path/HealthRoutes";
import { ECompanyCode, EProductId } from "../../../../Services/Enum/EHome";
import { HEALTH_PROPOSAL_SERVICES } from "../../../../Services/health-php/HealthPropsalServices";
import { CommonDropdownSlice } from "../../../../Store/Slice_Reducer/CommonDropdown/CommonDropdownSlice";
import { HealthSlice } from "../../../../Store/Slice_Reducer/Health/HealthSlice";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import {
  calculateAge,
  calculateAgeInDays,
  calculateBMI,
  isEmpty,
  validateEmail,
  validateFullName,
  validateMobileNumber,
} from "../../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import { TDropdown } from "../../../../types/Common/TDropdown";
import { TDropdownData } from "../../../../types/DropdownData/DropdownData";
import { TAdultOneDetails } from "../../../../types/Health/ProposalDeatail/TProposalDetail";
import { THealthNomineeDetails } from "../../../../types/Health/THealthSlice";

export default function InsuredMemberContainer() {
  const isMobile = useIsMobile();
  const {
    ADD_FORM,
    INSURED_MEMBER_DETAILS,
    NOMINEE_RELATIONSHIP_STATUS,
    SELECTED_QUOTE_DATA,
    PAGE_STATUS,
    ADD_FORM_RESPONSE,
    QUOTE_LOADER,
    ADDON_DETAILS_ICIC,
  } = useAppSelector((state) => state.Health);
  const { Health, CKYC } = useAppSelector((state) => state);
  const navigate = useNavigate();

  const [proposerFields, setProposerFields] = useState<TAdultOneDetails>({
    ...INSURED_MEMBER_DETAILS.PROPOSER_DETAILS,
  });
  const [adultOneFields, setAdultOneFields] = useState<TAdultOneDetails>({
    ...INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS,
  });
  const [nomineeDetails, setNomineeDetails] = useState<THealthNomineeDetails>({
    ...INSURED_MEMBER_DETAILS.NOMINEE__DETAILS,
  });
  const [disbaledButton, setDisbaledButton] = useState(false);

  const [occupationList, setOpccupationList] = useState<TDropdownData[]>([]);
  const [relationshipList, setRelationshipList] = useState<TDropdownData[]>([]);
  const [maritalStatusList, setMaritalStatusList] = useState<TDropdownData[]>(
    []
  );
  const [nomineeRealtionshipList, setNomineeRealtionshipList] = useState<
    TDropdownData[]
  >([]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    // setAdultOneFields({ ...INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS });
    setNomineeDetails({ ...INSURED_MEMBER_DETAILS.NOMINEE__DETAILS });
    // setProposerFields({ ...INSURED_MEMBER_DETAILS.PROPOSER_DETAILS });
    getOccupationData();
    getRealtionData();
    getMartialStatusData();
    getNomineeRelationData();
  }, [INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value]);

  useEffect(() => {
    const prop_data = { ...INSURED_MEMBER_DETAILS.PROPOSER_DETAILS };
    setProposerFields({
      ...INSURED_MEMBER_DETAILS.PROPOSER_DETAILS,
      dob: {
        value: isEmpty(prop_data.dob.value)
          ? CKYC.CKYC_DETAILS.kycDOB
          : prop_data.dob.value,
        warning: false,
      },
      name: {
        value: isEmpty(prop_data.name.value)
          ? CKYC.CKYC_DETAILS.kycCustomerName
          : prop_data.name.value,
        warning: false,
      },
      mobile: {
        value: isEmpty(prop_data.mobile.value)
          ? CKYC.CKYC_DETAILS.kycMobile
          : prop_data.mobile.value,
        warning: false,
      },
      email: {
        value: isEmpty(prop_data.email.value)
          ? CKYC.CKYC_DETAILS.kycEmail
          : prop_data.email.value,
        warning: false,
      },
      aadhar: {
        value: isEmpty(prop_data.aadhar.value)
          ? CKYC.CKYC_DETAILS.kycAadhaar
          : prop_data.aadhar.value,
        warning: false,
      },
      pan_card: {
        value: isEmpty(prop_data.pan_card.value)
          ? CKYC.CKYC_DETAILS.kycPAN
          : prop_data.pan_card.value,
        warning: false,
      },
    });
    setAdultOneFields({
      ...INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS,
      dob: {
        value: !isEmpty(INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value)
          ? INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value
          : isEmpty(prop_data.dob.value)
          ? CKYC.CKYC_DETAILS.kycDOB
          : prop_data.dob.value,
        warning: false,
      },
      name: {
        value: !isEmpty(INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value)
          ? INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value
          : isEmpty(prop_data.name.value)
          ? CKYC.CKYC_DETAILS.kycCustomerName
          : prop_data.name.value,
        warning: false,
      },
      mobile: {
        value: isEmpty(prop_data.mobile.value)
          ? CKYC.CKYC_DETAILS.kycMobile
          : prop_data.mobile.value,
        warning: false,
      },
      email: {
        value: isEmpty(prop_data.email.value)
          ? CKYC.CKYC_DETAILS.kycEmail
          : prop_data.email.value,
        warning: false,
      },
      aadhar: {
        value: isEmpty(prop_data.aadhar.value)
          ? CKYC.CKYC_DETAILS.kycAadhaar
          : prop_data.aadhar.value,
        warning: false,
      },
      pan_card: {
        value: isEmpty(prop_data.pan_card.value)
          ? CKYC.CKYC_DETAILS.kycPAN
          : prop_data.pan_card.value,
        warning: false,
      },
    });
  }, [
    CKYC.CKYC_DETAILS,
    proposerFields.IS_PROPOSER_INSURED,
    INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
  ]);

  const getNomineeRelationData = () => {
    const onSuccess = (res: any) => {
      console.log("res..", res);
      if (res?.response.length > 0) {
        let data: TDropdown[] = res?.response?.map((NomineeData: any) => ({
          value: `${NomineeData.code}-${NomineeData.title}`,
          label: NomineeData.title,
        }));
        setNomineeRealtionshipList(data);
      }
    };
    const onError = (err: any) => {
      console.log("err..", err);
    };
    let param = {
      product_type_code: "HP",
      product_code: SELECTED_QUOTE_DATA.productDetails.product_code,
    };
    HEALTH_PROPOSAL_SERVICES.getNomineeRelationData(onSuccess, onError, param);
  };

  useEffect(() => {
    const isFemaleProposer =
      INSURED_MEMBER_DETAILS.PROPOSER_DETAILS.gender.value === "F";
    const hasAddon =
      ADDON_DETAILS_ICIC.Maternity ||
      ADDON_DETAILS_ICIC.Vaccination ||
      ADDON_DETAILS_ICIC.New_Born;
    const isNotMother = adultOneFields.relationship.value !== "Mother";

    if (
      SELECTED_QUOTE_DATA.CompanyDetails.company_code === ECompanyCode.ICIC &&
      isFemaleProposer &&
      hasAddon &&
      isNotMother &&
      adultOneFields.relationship.value !== ""
    ) {
      toast.warning(
        "Maternity, Vaccination and New Born Addons are allowed only for Relationship with Mother"
      );
      setDisbaledButton(true);
    } else {
      setDisbaledButton(false);
    }
  }, [
    INSURED_MEMBER_DETAILS.PROPOSER_DETAILS.gender.value,
    ADDON_DETAILS_ICIC,
    adultOneFields.relationship.value,
  ]);

  const getRealtionData = () => {
    const onSuccess = (res: any) => {
      console.log("res..", res);
      let data: TDropdown[] = res?.response?.map((maritalData: any) => ({
        // label: `${maritalData.title}-${maritalData.value}`,
        label: maritalData.title,
        value: maritalData.value,
      }));
      dispatch(
        HealthSlice.actions.HEALTH_DROPDOWNDATA({
          key: "ADULT1_RELATIONSHIP",
          value: data,
        })
      );
      setRelationshipList(data);
    };
    const onError = (err: any) => {
      console.log("err..", err);
    };
    let param = {
      product_code: SELECTED_QUOTE_DATA.productDetails.product_code,
      relationship: "adult1",
      buy_online_code: SELECTED_QUOTE_DATA.productDetails.buy_online_code,
    };
    HEALTH_PROPOSAL_SERVICES.getRelationData(onSuccess, onError, param);
  };

  const getMartialStatusData = () => {
    const onSuccess = (res: any) => {
      console.log("res..", res);
      let data: TDropdown[] = res?.response?.map((maritalData: any) => ({
        value: `${maritalData.code}-${maritalData.title}`,
        label: maritalData.title,
      }));
      dispatch(
        HealthSlice.actions.HEALTH_DROPDOWNDATA({
          key: "MARTIAL",
          value: data,
        })
      );
      setMaritalStatusList(data);
    };
    const onError = (err: any) => {
      console.log("err..", err);
    };
    let param = {
      product_code: SELECTED_QUOTE_DATA.productDetails.product_code,
      product_type_code: SELECTED_QUOTE_DATA.productDetails.product_type_code,
    };
    HEALTH_PROPOSAL_SERVICES.getMartialData(onSuccess, onError, param);
  };

  const getOccupationData = () => {
    const onSuccess = (res: any) => {
      let data: TDropdown[] = res?.response?.map((maritalData: any) => ({
        value:
          SELECTED_QUOTE_DATA.CompanyDetails.company_code === "G023"
            ? `${maritalData.code}-${maritalData.title}`
            : maritalData.code,
        label: maritalData.title,
      }));
      dispatch(
        HealthSlice.actions.HEALTH_DROPDOWNDATA({
          key: "OCCUPATION",
          value: data,
        })
      );
      dispatch(
        CommonDropdownSlice.actions.UPDATE_NIVA_BUPA_OCCUPATION({
          key: "NIVA_BUPA_OCCUPATION",
          value: data,
        })
      );
      setOpccupationList(data);
    };
    const onError = (err: any) => {
      console.log("err..", err);
    };
    let param = {
      product_code: SELECTED_QUOTE_DATA.productDetails.product_code,
      product_type_code: SELECTED_QUOTE_DATA.productDetails.product_type_code,
    };
    HEALTH_PROPOSAL_SERVICES.getOccupationData(onSuccess, onError, param);
  };

  const handleClick = () => {
    setProposerFields((prev) => ({
      ...prev,
      IS_PROPOSER_INSURED: !prev.IS_PROPOSER_INSURED,
    }));
  };

  const fieldsUpdateState = (attrName: any, value: any) => {
    if (attrName[0] === "nomineeDetails") {
      setNomineeDetails((prev) => ({
        ...prev,
        [attrName[1]]: {
          value: value,
          warning:
            attrName === "full_name"
              ? !validateFullName(value)
              : isEmpty(value),
        },
      }));
    } else if (attrName[1] === "adultOneDetails" && attrName[0] === "dob") {
      setAdultOneFields((prev) => ({
        ...prev,
        dob: {
          value: value,
          warning: isEmpty(value),
        },
      }));
    } else if (attrName[1] === "adultOneDetails") {
      setAdultOneFields((prev) => ({
        ...prev,
        [attrName[0]]: {
          value: value,
          warning:
            attrName[0] === "email"
              ? !validateEmail(value)
              : attrName[0] === "mobile"
              ? !validateMobileNumber(value)
              : attrName[0] === "gender"
              ? isEmpty(value)
              : isEmpty(value),
        },
      }));
    } else {
      setProposerFields((prev) => ({
        ...prev,
        [attrName]: {
          value: value,
          warning:
            attrName === "email"
              ? !validateEmail(value)
              : attrName === "mobile"
              ? !validateMobileNumber(value)
              : attrName === "gender"
              ? isEmpty(value)
              : isEmpty(value),
        },
      }));
    }
  };

  const UPDATE_PROPOSER_NOMINEE_DATA = (
    PROPOSER_DATA: TAdultOneDetails,
    NOMINEE_DETAILS: THealthNomineeDetails
  ) => {
    const onSuccess = (res: any) => {
      console.log("res...", res?.results);
    };
    const onError = (err: any) => {
      console.log("err..", err);
    };
    let param = {
      insured_name: PROPOSER_DATA.name.value,
      insured_dob: PROPOSER_DATA.dob.value,
      insured_gender: PROPOSER_DATA.gender.value,
      insured_mobile: PROPOSER_DATA.mobile.value,
      insured_email: PROPOSER_DATA.email.value,
      aadhar_number: PROPOSER_DATA.aadhar.value,
      insured_emergency: PROPOSER_DATA.emergencyPhone.value,
      insured_pan: "",
      insured_gst: "",
      adult1Relationship: PROPOSER_DATA.relationship.value,
      weight: PROPOSER_DATA.weight.value,
      heightFeet: PROPOSER_DATA.heightFeet.value,
      heightInches: PROPOSER_DATA.heightInches.value,
      maritalStatus: PROPOSER_DATA.maritalStatus.value,
      member_income:
        SELECTED_QUOTE_DATA.CompanyDetails.company_code === "G023"
          ? PROPOSER_DATA?.annual_income?.value
          : PROPOSER_DATA.member_income.value,
      qualification: PROPOSER_DATA.qualification.value,
      occupation: PROPOSER_DATA.occupation.value,
      nomineeName:
        NOMINEE_RELATIONSHIP_STATUS === "OTHER"
          ? NOMINEE_DETAILS.full_name.value
          : "",
      nomineeDOB:
        NOMINEE_RELATIONSHIP_STATUS === "OTHER"
          ? NOMINEE_DETAILS.dob.value
          : "",
      nomineeRelationShip:
        NOMINEE_RELATIONSHIP_STATUS === "OTHER"
          ? NOMINEE_DETAILS.relationship.value
          : "",
      section: "adult1_details",
      quote_no: ADD_FORM_RESPONSE.quote_no,
      insurance_type: "HP",

      cover: `${ADD_FORM?.spouse_status ? "2A" : "1A"}${
        (parseInt(ADD_FORM?.son_count?.value) || 0) +
          (parseInt(ADD_FORM?.daughter_count?.value) || 0) >
        0
          ? `${
              (parseInt(ADD_FORM?.son_count?.value) || 0) +
              (parseInt(ADD_FORM?.daughter_count?.value) || 0)
            }C`
          : ""
      }`,
    };
    HEALTH_PROPOSAL_SERVICES.getCapturedRecordData(onSuccess, onError, param);
  };
  const isGoDigitProduct =
    SELECTED_QUOTE_DATA.CompanyDetails.company_code === ECompanyCode.GO_DIGIT;

  const isICICIProduct = SELECTED_QUOTE_DATA.productDetails.id === 2551;

  const hdfcProductIds = [
    ECompanyCode.HDFC_HEALTH,
    ECompanyCode.HDFC_HEALTH,
    EProductId.HDFC_HEALTH,
    EProductId.HDFC_HEALTH_Global,
    EProductId.HDFC_HEALTH_Plan,
  ];

  const isHdfcProduct =
    SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
      ECompanyCode.HDFC_HEALTH ||
    hdfcProductIds.includes(SELECTED_QUOTE_DATA.productDetails.id);

  const validateForm = () => {
    let adult_one_data = { ...adultOneFields };
    let nominee_data = { ...nomineeDetails };
    adult_one_data = {
      ...adult_one_data,
      name: {
        ...adult_one_data.name,
        warning:
          adult_one_data.name.value !== ""
            ? false
            : isEmpty(adult_one_data.name.value),
      },
      dob: {
        ...adult_one_data.dob,
        warning: isEmpty(adult_one_data.dob.value),
      },
      relationship: {
        ...adult_one_data.relationship,
        warning:
          PAGE_STATUS === true
            ? false
            : isEmpty(`${adult_one_data.relationship.value}`),
      },
      weight: {
        ...adult_one_data.weight,
        warning: isEmpty(`${adult_one_data.weight.value}`),
      },
      heightFeet: {
        ...adult_one_data.heightFeet,
        warning: isEmpty(`${adult_one_data.heightFeet.value}`),
      },
      heightInches: {
        ...adult_one_data.heightInches,
        warning: isEmpty(`${adult_one_data.heightInches.value}`),
      },
      annual_income: {
        ...adult_one_data.annual_income,
        warning:
          (!isEmpty(`${adult_one_data.annual_income.value}`) &&
            isICICIProduct) ||
          SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
            ECompanyCode.NIVA_BUPA ||
          (!isEmpty(`${adult_one_data.annual_income.value}`) && hdfcProductIds)
            ? isEmpty(`${adult_one_data.annual_income.value}`)
            : false,
      },
      maritalStatus: {
        ...adult_one_data.maritalStatus,
        warning:
          SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
            ECompanyCode.STAR ||
          SELECTED_QUOTE_DATA.CompanyDetails.company_code === ECompanyCode.BAJAJ
            ? false
            : `${adult_one_data.maritalStatus.value}`.trim() === "",
      },
      occupation: {
        ...adult_one_data.occupation,
        warning:
          isEmpty(adult_one_data.occupation.value) &&
          (isHdfcProduct ||
            isGoDigitProduct ||
            SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
              ECompanyCode.NIVA_BUPA) &&
          isEmpty(adult_one_data.occupation.value)
            ? true
            : false,
      },
      qualification: {
        ...adult_one_data.qualification,
        warning:
          isEmpty(adult_one_data.qualification.value) &&
          SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
            ECompanyCode.NIVA_BUPA &&
          isEmpty(adult_one_data.qualification.value)
            ? true
            : false,
      },
    };

    if (NOMINEE_RELATIONSHIP_STATUS === "OTHER") {
      nominee_data = {
        ...nominee_data,
        full_name: {
          ...nominee_data.full_name,
          warning: !validateFullName(`${nominee_data.full_name.value}`),
        },
        dob: {
          ...nominee_data.dob,
          warning: isEmpty(`${nominee_data.dob.value}`),
        },
        relationship: {
          ...nominee_data.relationship,
          warning: isEmpty(`${nominee_data.relationship.value}`),
        },
      };
    }
    setAdultOneFields({ ...adult_one_data });
    setNomineeDetails({ ...nominee_data });
    let hasError = false;

    if (
      adult_one_data.name.warning ||
      adult_one_data.dob.warning ||
      adult_one_data.relationship.warning ||
      adult_one_data.weight.warning ||
      adult_one_data.heightFeet.warning ||
      adult_one_data.heightInches.warning ||
      adult_one_data.maritalStatus.warning ||
      adult_one_data.occupation.warning ||
      adult_one_data.annual_income.warning ||
      hasError
    ) {
      hasError = true;
    }

    if (NOMINEE_RELATIONSHIP_STATUS === "OTHER") {
      if (
        nominee_data.full_name.warning ||
        nominee_data.dob.warning ||
        nominee_data.relationship.warning ||
        hasError
      ) {
        hasError = true;
      }
    }

    const bmi = calculateBMI(
      adult_one_data.weight.value,
      adult_one_data.heightFeet.value,
      adult_one_data.heightInches.value
    );
    const roundedBMI = Math.round(bmi);

    if (
      SELECTED_QUOTE_DATA.productDetails.id ===
        EProductId.NIVA_BUPA_SENIOR_FIRST_GOLD_IND ||
      SELECTED_QUOTE_DATA.productDetails.id ===
        EProductId.NIVA_BUPA_Senior_First_Gold_Floater ||
      SELECTED_QUOTE_DATA.productDetails.id ===
        EProductId.NIVA_BUPA_Senior_First_Platinum_Floater ||
      SELECTED_QUOTE_DATA.productDetails.id ===
        EProductId.NIVA_BUPA_Senior_First_Platinum_IND
    ) {
      if (roundedBMI < 15 || roundedBMI > 37) {
        toast.error("BMI falls outside acceptable range!");
        hasError = true;
      }
    }

    if (SELECTED_QUOTE_DATA.CompanyDetails.company_code === "G033") {
      if (roundedBMI <= 19 || roundedBMI > 35) {
        toast.error("BMI falls between 20 to 35 range!");
        hasError = true;
      }
    }

    if (SELECTED_QUOTE_DATA.productDetails.id === 445) {
      if (roundedBMI >= 17 && roundedBMI <= 35) {
        toast.success("BMI falls within STP range!");
      } else if (roundedBMI > 35 && roundedBMI <= 37) {
        const loadedBMI = roundedBMI * 1.1;
        toast.warn(
          `BMI exceeded 35 but below or equal to 37. Applying 10 % loading.New BMI: ${loadedBMI} `
        );
      } else if (roundedBMI < 17 || roundedBMI > 37) {
        toast.error("BMI falls outside acceptable range!");
        hasError = true;
      }
    }
    if (
      SELECTED_QUOTE_DATA.productDetails.id === EProductId.HDFC_HEALTH_Global
    ) {
      if (calculateAge(adult_one_data.dob.value) >= 65) {
        toast.error("Age must be below 65 years or equal to 65 years.");
        hasError = true;
      }
    }

    if (
      SELECTED_QUOTE_DATA.productDetails.id === 2890 ||
      SELECTED_QUOTE_DATA.productDetails.id === 2891 ||
      SELECTED_QUOTE_DATA.productDetails.id === 2892 ||
      SELECTED_QUOTE_DATA.productDetails.id === 2893
    ) {
      if (calculateAgeInDays(`${adult_one_data.dob.value} `) <= 61 * 365) {
        toast.error("Age must be greater than 61");
        hasError = true;
      }
    }

    if (SELECTED_QUOTE_DATA.productDetails.id === 187) {
      if (
        calculateAgeInDays(`${adult_one_data.dob.value} `) <= 60 * 365 ||
        calculateAgeInDays(`${adult_one_data.dob.value} `) >= 75 * 365
      ) {
        toast.error("Age must be greater than 60 and less than 75");
        hasError = true;
        return;
      }
    }
    if (
      SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
      ECompanyCode.HDFC_HEALTH
    ) {
      if (calculateAgeInDays(`${adult_one_data.dob.value} `) > 24106) {
        toast.error("Age Must be below 65 year's or equal to 65 year's");
        return;
      }
    }
    if (
      SELECTED_QUOTE_DATA.productDetails.id === EProductId.CARE_SENIOR_CITIZEN
    ) {
      if (
        calculateAgeInDays(`${adult_one_data.dob.value} `) <= 61 * 365 ||
        calculateAgeInDays(`${adult_one_data.dob.value} `) >= 75 * 365
      ) {
        toast.error("Age must be greater than 61 and less than 75");
        hasError = true;
        return;
      }
    }

    if (
      SELECTED_QUOTE_DATA.CompanyDetails.company_code === ECompanyCode.GO_DIGIT
    ) {
      const age = calculateAge(adultOneFields.dob.value);
      const relationship = adultOneFields.relationship.value;

      // if (
      //   age >= 35 &&
      //   age <= 60 &&
      //   (relationship === "Father" || relationship === "Mother")
      // ) {
      //   toast.error("Adult Age Should be greater than 35 and less than 60");
      //   return;
      // }
      if (
        !ADD_FORM.spouse_status &&
        ADD_FORM.children === 0 &&
        age <= 85 &&
        adultOneFields.maritalStatus.value === "V-Married"
      ) {
        toast.error("Plan not available for Age less than 85 and Married");
        return;
      }
    }
    if (!hasError) {
      dispatch(
        HealthSlice.actions.BULK_UPLOAD({
          ...Health,
          INSURED_MEMBER_DETAILS: {
            ...Health.INSURED_MEMBER_DETAILS,
            ADULT_ONE_DETAILS: { ...adult_one_data },
            NOMINEE__DETAILS: { ...nominee_data },
          },
        })
      );

      const total_child = ADD_FORM.children;
      const windowURL = window.location.search;
      const params = new URLSearchParams(windowURL);
      const cus_type = params.get("cus_type");

      console.log(adult_one_data, "new");

      UPDATE_PROPOSER_NOMINEE_DATA(adult_one_data, nominee_data);
      if (PAGE_STATUS) {
        navigate(
          `${HEALTH_ROUTE.PROPOSAL_PAGE}/${
            HEALTH_ROUTE.PREVIEW_DETAILS
          }?type=HP&companyCode=${
            SELECTED_QUOTE_DATA?.CompanyDetails.company_code
          }&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${
            SELECTED_QUOTE_DATA?.productDetails.id
          }${cus_type === "user" ? "&cus_type=user" : ""}`
        );
      } else {
        if (ADD_FORM.spouse_status) {
          navigate(
            `${HEALTH_ROUTE.PROPOSAL_PAGE}/${
              HEALTH_ROUTE.ADULT_TWO_DETAILS
            }?type=HP&companyCode=${
              SELECTED_QUOTE_DATA?.CompanyDetails.company_code
            }&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${
              SELECTED_QUOTE_DATA?.productDetails.id
            }${cus_type === "user" ? "&cus_type=user" : ""}`
          );
        } else if (total_child > 0) {
          navigate(
            `${HEALTH_ROUTE.PROPOSAL_PAGE}/${
              HEALTH_ROUTE.CHILD_ONE_DETAILS
            }?type=HP&companyCode=${
              SELECTED_QUOTE_DATA?.CompanyDetails.company_code
            }&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${
              SELECTED_QUOTE_DATA?.productDetails.id
            }${cus_type === "user" ? "&cus_type=user" : ""}`
          );
        } else {
          if (
            SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
              ECompanyCode.HDFC_HEALTH ||
            SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
              ECompanyCode.NIVA_BUPA ||
            SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
              ECompanyCode.ADITY_BIRLA ||
            SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
              ECompanyCode.CIGNA
          ) {
            navigate(
              `${HEALTH_ROUTE.PROPOSAL_PAGE}/${
                HEALTH_ROUTE.BANK_DETAILS
              }?type=HP&companyCode=${
                SELECTED_QUOTE_DATA?.CompanyDetails.company_code
              }&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${
                SELECTED_QUOTE_DATA?.productDetails.id
              }${cus_type === "user" ? "&cus_type=user" : ""}`
            );
          } else {
            navigate(
              `${HEALTH_ROUTE.PROPOSAL_PAGE}/${
                HEALTH_ROUTE.ADDRESS_DETAILS
              }?type=HP&companyCode=${
                SELECTED_QUOTE_DATA?.CompanyDetails.company_code
              }&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${
                SELECTED_QUOTE_DATA?.productDetails.id
              }${cus_type === "user" ? "&cus_type=user" : ""}`
            );
          }
        }
      }
    }
  };

  return (
    <>
      {QUOTE_LOADER ? (
        <Box
          position="fixed"
          height="100%"
          width="100%"
          zIndex="991"
          top="0px"
          left="0px"
        />
      ) : null}
      {isMobile ? (
        <MInsuredMember
          adultOneDetails={adultOneFields}
          nomineeDetails={nomineeDetails}
          fieldsUpdateState={fieldsUpdateState}
          proposerFields={proposerFields}
          validateForm={validateForm}
          handleClick={handleClick}
          occupationList={occupationList}
          relationshipList={relationshipList}
          maritalStatusList={maritalStatusList}
          nomineeRealtionshipList={nomineeRealtionshipList}
        />
      ) : (
        <InsuredMember
          adultOneDetails={adultOneFields}
          nomineeDetails={nomineeDetails}
          fieldsUpdateState={fieldsUpdateState}
          proposerFields={proposerFields}
          validateForm={validateForm}
          handleClick={handleClick}
          disbaledButton={disbaledButton}
          occupationList={occupationList}
          relationshipList={relationshipList}
          maritalStatusList={maritalStatusList}
          nomineeRealtionshipList={nomineeRealtionshipList}
        />
      )}
    </>
  );
}
