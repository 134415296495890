import { Box } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Unstable_Grid2";
import { subYears } from "date-fns";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  extractNumbersFromString,
  isEmpty,
  isValid_Pan_Number,
  validateEmail,
  validateFullName,
  validateMobileNumber,
  validatePAN,
} from "../../../../../../SupportingFiles/HelpingFunction";
import { useAppDispatch, useAppSelector } from "../../../../../../Store/hooks";
import { proposalSlice } from "../../../../../../Store/Slice_Reducer/HI/Proposal/ProposalSlice";
import { PROPOSAL_SERVICES } from "../../../../../../Services/HI/ProposalService";
import {
  TCoOwnerDetails,
  TNomineeDetails,
  TOwnerDetails,
  TOwnerDetailsScreen,
} from "../../../../../../types/HI/TProposal";
import { toast } from "react-toastify";
import { pageSlice } from "../../../../../../Store/Slice_Reducer/HI/PageSlice/PageSlice";
import { HI_PATH } from "../../../../../../Router/Path/HIPath";
import { QUOTES_SERVICES } from "../../../../../../Services/HI/QuotesService";
import {
  CoOwnerDetails_DTO,
  OwnerDetailsScreen_DTO,
} from "../../../../../../DTO/HIDTO/ProposalDTO";
import RKTextField from "../../../../../../Component/FieldTypes/RKTextField/RKTextField";
import DatePicker from "../../../../../../Component/FieldTypes/DatePicker/DatePicker";
import SelectDropdown from "../../../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";
import SearchDropdown from "../../../../../../Component/FieldTypes/SearchDropdown/SearchDropdown";
import Footer from "../../../../../../Component/HI/Desktop/ProposalComponents/Footer/Footer";

const proposerGender_data = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
];

const insuredGender_data = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
];

const OwnerDetails: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const detailsState = useAppSelector(
    (state) => state.proposalInformation.ownerDetailsScreen
  );

  const { PAGE_STATUS } = useAppSelector((state) => state.pageSlice);
  const [load, setLoad] = useState<boolean>(false);
  const policyState = useAppSelector(
    (state) => state.proposalInformation.policy_information
  );

  const { proposalInformation, quote } = useAppSelector((state) => state);

  const [coOwnerDetails, setCoOwnerDetails] = useState(
    proposalInformation.ownerDetailsScreen.selectedCoOwner
  );
  const [nomineeAgeData, setNomineeAgeData] = useState([{}]);
  const [nomineeRelations, setNomineeRelations] = useState([]);

  useEffect(() => {
    setCoOwnerDetails(proposalInformation.ownerDetailsScreen.selectedCoOwner);
  }, [proposalInformation.ownerDetailsScreen.selectedCoOwner]);

  useEffect(() => {
    let data = [];
    for (var i = 5; i < 80; i++) {
      data.push({ label: `${i} Years`, value: i + " Years" });
    }
    setNomineeAgeData(data);
    getNomineeRelations();

    setTimeout(() => {
      if (proposalInformation.ckycInfo.verificationDoc.value === "D07") {
        dispatch(
          proposalSlice.actions.setOwnerDetailsScreen({
            ...proposalInformation.ownerDetailsScreen,
            ownerDetails: {
              ...proposalInformation.ownerDetailsScreen.ownerDetails,
              pancard: {
                value: proposalInformation.ckycInfo.docID.value,
                warning: false,
              },
            },
          })
        );
      }
    }, 1500);
  }, []);

  const getNomineeRelations = () => {
    const onSuccess = (res: any) => {
      setNomineeRelations(
        res.data.response.map((item: any) => {
          return {
            label: item.title,
            value: item.title,
          };
        })
      );
    };

    const onError = (err: any) => {
      console.log("errr", err);
    };

    PROPOSAL_SERVICES.get_nominee_relations(
      onSuccess,
      onError,
      `${policyState.productDetails.id}`
    );
  };

  const fieldsUpdateState = (attrName: any, value: any) => {
    const objToUse: any =
      detailsState[attrName[2] as keyof TOwnerDetailsScreen];

    let warn = false;

    if (attrName[0] === "mobile") {
      const validMobile = validateMobileNumber(value);

      if (!/^[0-9]*$/.test(value)) {
        return;
      }

      if (!validMobile) {
        warn = true;
      }
    }

    if (attrName[0] === "email") {
      const valid = validateEmail(value.trim());

      if (!valid) {
        warn = true;
      }
    }

    if (attrName[0] === "pancard") {
      const valid = isValid_Pan_Number(value.trim());

      if (!valid) {
        warn = true;
      }
    }

    if (attrName[0] === "nomineeAge") {
      if (Number(extractNumbersFromString(value)) > 18) {
        dispatch(
          proposalSlice.actions.setOwnerDetailsScreen({
            ...detailsState,
            nomineeDetails: {
              ...detailsState.nomineeDetails,
              appointeeName: {
                value: "",
                warning: false,
              },
            },
          })
        );
      }
    }

    // if (attrName[0] === "dob") {
    //   value = FORMAT_DD_MM_YYYY(value);
    // }
    dispatch(
      proposalSlice.actions.setOwnerDetailsScreen({
        ...detailsState,
        [attrName[2]]: {
          ...objToUse,
          [attrName[0]]: {
            warning: warn,
            [attrName[1]]: attrName[3]
              ? attrName[3] === "disableTrim"
                ? value
                : value.trim()
              : value.trim(),
          },
        },
      })
    );
    if (!value) {
      dispatch(
        proposalSlice.actions.setOwnerDetailsScreen({
          ...detailsState,
          [attrName[2]]: {
            ...objToUse,
            [attrName[0]]: {
              [attrName[1]]: attrName[3]
                ? attrName[3] === "disableTrim"
                  ? value
                  : value.trim()
                : value.trim(),
              warning: true,
            },
          },
        })
      );
    }
  };

  // useEffect(() => {
  //   if (!coOwnerDetails) {
  //     console.log("jjjjjjj");
  //     // dispatch()
  //   }
  // }, [coOwnerDetails]);
  const handleClick = () => {
    const prevValue = coOwnerDetails;
    setCoOwnerDetails(!prevValue);
    // dispatch(
    //   proposalSlice.actions.setCoOwnerState({
    //     ...proposalInformation.ownerDetailsScreen,
    //     selectedCoOwner: !prevValue,
    //   })
    // );
    dispatch(
      proposalSlice.actions.setOwnerDetailsScreen({
        ...proposalInformation.ownerDetailsScreen,
        coOwnerDetails: {
          dob: { value: null, warning: false },
          email: { value: "", warning: false },
          gender: { value: "", warning: false },
          mobile: { value: "", warning: false },
          name: { value: "", warning: false },
        },
        selectedCoOwner: !prevValue,
      })
    );
  };

  const updateDigitQuotePremium = (loadingToast: any) => {
    const onSuccess = (res: any) => {
      toast.dismiss(loadingToast);
      dispatch(
        proposalSlice.actions.setPolicyInfo({
          ...proposalInformation.policy_information,
          premiumDetails: res.data.quoteLists,
        })
      );
      setLoad(false);
      if (PAGE_STATUS) {
        dispatch(pageSlice.actions.UPDATE_PAGE_STATUS(false));
        navigate(-1);
      } else {
        setLoad(false);
        navigate(`${HI_PATH.Proposal}/${HI_PATH.PROPERTY_DETAILS}`);
      }
    };

    const onError = (err: any) => {
      console.log(err);
    };

    QUOTES_SERVICES.update_digit_quote_premium(
      onSuccess,
      onError,
      quote.quote_id
    );
  };

  const saveProposalInfo = (data: any) => {
    // const loadingToast = toast.loading("Updating Proposal...");
    setLoad((prev) => true);
    console.log("save proposal is true");
    let loadingToast = toast.loading("Loading...");
    const onSuccess = (res: any) => {
      toast.dismiss(loadingToast);
      console.log(loadingToast, 'loadingToast')

      // setLoad((prev) => false);
      toast.dismiss("Updating Proposal...");
      updateDigitQuotePremium(loadingToast);
    };

    const onError = (err: any) => {
      setLoad((prev) => false);
      toast.dismiss(loadingToast);
    };

    PROPOSAL_SERVICES.save_proposal_info(
      onSuccess,
      onError,
      data,
      quote.quote_id
    );
  };

  const check_error = () => {
    let hasError = false;
    let proposer_data: TOwnerDetailsScreen =
      proposalInformation.ownerDetailsScreen;
    let co_owner_details_data: TCoOwnerDetails =
      proposalInformation.ownerDetailsScreen.coOwnerDetails;
    let owner_details_data: TOwnerDetails =
      proposalInformation.ownerDetailsScreen.ownerDetails;
    let nominee_details_data: TNomineeDetails =
      proposalInformation.ownerDetailsScreen.nomineeDetails;

    co_owner_details_data = {
      ...co_owner_details_data,
      name: {
        ...co_owner_details_data.name,
        warning: !validateFullName(co_owner_details_data.name.value),
      },
      dob: {
        ...co_owner_details_data.dob,
        warning: isEmpty(co_owner_details_data.dob.value),
      },
      gender: {
        ...co_owner_details_data.gender,
        warning: isEmpty(co_owner_details_data.gender.value),
      },
      mobile: {
        ...co_owner_details_data.mobile,
        warning: !validateMobileNumber(co_owner_details_data.mobile.value),
      },
      email: {
        ...co_owner_details_data.email,
        warning: !validateEmail(co_owner_details_data.email.value),
      },
    };

    owner_details_data = {
      ...owner_details_data,
      name: {
        ...owner_details_data.name,
        warning: !validateFullName(owner_details_data.name.value),
      },
      dob: {
        ...owner_details_data.dob,
        warning: isEmpty(owner_details_data.dob.value),
      },
      gender: {
        ...owner_details_data.gender,
        warning: isEmpty(owner_details_data.gender.value),
      },
      mobile: {
        ...owner_details_data.mobile,
        warning: !validateMobileNumber(owner_details_data.mobile.value),
      },
      email: {
        ...owner_details_data.email,
        warning: !validateEmail(owner_details_data.email.value),
      },
      pancard: {
        ...owner_details_data.pancard,
        warning: !validatePAN(owner_details_data.pancard.value),
      },
    };

    nominee_details_data = {
      ...nominee_details_data,
      nomineeName: {
        ...nominee_details_data.nomineeName,
        warning: !validateFullName(nominee_details_data.nomineeName.value),
      },
      nomineeAge: {
        ...nominee_details_data.nomineeAge,
        warning: isEmpty(nominee_details_data.nomineeAge.value),
      },
      nomineeGender: {
        ...nominee_details_data.nomineeGender,
        warning: isEmpty(nominee_details_data.nomineeGender.value),
      },
      nomineeRelationshipWithOwner: {
        ...nominee_details_data.nomineeRelationshipWithOwner,
        // warning: false,
        warning: isEmpty(
          nominee_details_data.nomineeRelationshipWithOwner.value
        ),
      },
      appointeeName: {
        ...nominee_details_data.appointeeName,
        warning:
          Number(
            extractNumbersFromString(nominee_details_data.nomineeAge.value)
          ) > 18
            ? false
            : isEmpty(nominee_details_data.appointeeName.value) ||
            !validateFullName(nominee_details_data.appointeeName.value),
      },
    };

    proposer_data = {
      ...proposer_data,
      ownerDetails: owner_details_data,
      nomineeDetails: nominee_details_data,
    };

    if (coOwnerDetails) {
      proposer_data = {
        ...proposer_data,
        coOwnerDetails: co_owner_details_data,
      };

      if (
        co_owner_details_data.name.warning ||
        co_owner_details_data.dob.warning ||
        co_owner_details_data.gender.warning ||
        co_owner_details_data.mobile.warning ||
        co_owner_details_data.email.warning
      ) {
        hasError = true;
      }
    }

    if (
      hasError ||
      owner_details_data.name.warning ||
      owner_details_data.dob.warning ||
      owner_details_data.gender.warning ||
      owner_details_data.mobile.warning ||
      owner_details_data.email.warning ||
      owner_details_data.pancard.warning ||
      nominee_details_data.appointeeName.warning ||
      nominee_details_data.nomineeAge.warning ||
      nominee_details_data.nomineeGender.warning ||
      nominee_details_data.nomineeRelationshipWithOwner.warning ||
      nominee_details_data.nomineeName.warning
    ) {
      hasError = true;
    }
    dispatch(
      proposalSlice.actions.setOwnerDetailsScreen({
        ...proposer_data,
      })
    );

    return hasError;
  };

  const validate_form = () => {
    const hasError = check_error();

    const obj = proposalInformation.ownerDetailsScreen;
    if (hasError) {
      toast.error("Please Enter Valid Information");
      return;
    }

    let dto: OwnerDetailsScreen_DTO = {
      isCoOwner: false,
      nominee_details: {
        nomineeAge: Number(
          extractNumbersFromString(obj.nomineeDetails.nomineeAge.value)
        ),
        nomineeGender: obj.nomineeDetails.nomineeGender.value,
        nomineeName: obj.nomineeDetails.nomineeName.value,
        nomineeRelation: obj.nomineeDetails.nomineeRelationshipWithOwner.value,
      },
      owner_details: {
        dob: obj.ownerDetails.dob.value,
        email: obj.ownerDetails.email.value,
        gender: obj.ownerDetails.gender.value,
        mobile: obj.ownerDetails.mobile.value,
        name: obj.ownerDetails.name.value,
        panNo: obj.ownerDetails.pancard.value,
      },
    };

    if (obj.selectedCoOwner) {
      const objToUse: CoOwnerDetails_DTO = {
        dob: obj.coOwnerDetails.dob.value,
        email: obj.coOwnerDetails.email.value,
        gender: obj.coOwnerDetails.gender.value,
        mobile: obj.coOwnerDetails.mobile.value,
        name: obj.coOwnerDetails.name.value,
      };

      dto = { ...dto, isCoOwner: true, co_owner_details: { ...objToUse } };
    }

    if (!hasError) saveProposalInfo(dto);
  };

  return (
    <Box>
      <h5 className="sectionTitle">Owner Details</h5>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <FormControlLabel
            className="proposalCheckBoxWrapper"
            control={
              <Checkbox onClick={handleClick} checked={coOwnerDetails} />
            }
            label="You have a Co-Owner?"
          />
        </Grid>
        <Grid xs={12}>
          <h6>Owner Details</h6>
        </Grid>
        <Grid xs={6}>
          <RKTextField
            class_name="inputField"
            title={"Full Name"}
            validation_type="NAME"
            value={detailsState.ownerDetails.name.value}
            disabled={detailsState.ownerDetails.name.setFromKycResp}
            attrName={["name", "value", "ownerDetails", "disableTrim"]}
            value_update={fieldsUpdateState}
            warn_status={detailsState.ownerDetails.name.warning}
            error_message={
              isEmpty(detailsState.ownerDetails.name.value)
                ? "Enter Full Name"
                : !validateFullName(detailsState.ownerDetails.name.value)
                  ? "Enter Valid Full Name"
                  : ""
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={4}>
          {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                  label="DOB"
                  value={proposerDob}
                  onChange={(e:any) => {
                      setProposerDob(e.target.value);
                  }}
                  renderInput={(params) => <TextField {...params} />}
              />
          </LocalizationProvider> */}
          <DatePicker
            class_name="inputField"
            title={"DOB"}
            value={detailsState.ownerDetails.dob.value}
            disabled={detailsState.ownerDetails.dob.setFromKycResp}
            attrName={["dob", "value", "ownerDetails", "disableTrim"]}
            min_date={100}
            max_date={18}
            date_validation_type="YEARS"
            value_update={fieldsUpdateState}
            error_message="Select DOB"
            warn_status={detailsState.ownerDetails.dob.warning}
          />
        </Grid>
        <Grid xs={4}>
          <SelectDropdown
            class_name="inputField"
            title="Gender"
            value={detailsState.ownerDetails.gender.value}
            attrName={["gender", "value", "ownerDetails"]}
            value_update={fieldsUpdateState}
            data={proposerGender_data}
            warn_status={detailsState.ownerDetails.gender.warning}
            error_message="Select Gender"
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={4}>
          <RKTextField
            class_name="inputField"
            title={"Mobile"}
            max_length={10}
            validation_type="NUMBER"
            disabled={detailsState.ownerDetails.mobile.setFromKycResp}
            value={detailsState.ownerDetails.mobile.value}
            attrName={["mobile", "value", "ownerDetails"]}
            value_update={fieldsUpdateState}
            warn_status={detailsState.ownerDetails.mobile.warning}
            error_message={
              isEmpty(detailsState.ownerDetails.mobile.value)
                ? "Enter Mobile No."
                : !validateMobileNumber(detailsState.ownerDetails.mobile.value)
                  ? "Enter Valid Mobile No."
                  : ""
            }
          />
        </Grid>
        <Grid xs={6}>
          <RKTextField
            class_name="inputField"
            title={"Email"}
            value={detailsState.ownerDetails.email.value}
            attrName={["email", "value", "ownerDetails"]}
            value_update={fieldsUpdateState}
            warn_status={detailsState.ownerDetails.email.warning}
            error_message={
              isEmpty(detailsState.ownerDetails.email.value)
                ? "Enter Email"
                : !validateEmail(detailsState.ownerDetails.email.value)
                  ? "Enter Valid Email"
                  : ""
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={4}>
          <RKTextField
            class_name="inputField"
            title={"PAN"}
            value={detailsState.ownerDetails.pancard.value}
            attrName={["pancard", "value", "ownerDetails"]}
            value_update={fieldsUpdateState}
            warn_status={detailsState.ownerDetails.pancard.warning}
            validation_type="ALL_CAPS"
            error_message={
              isEmpty(detailsState.ownerDetails.pancard.value)
                ? "Enter PAN"
                : !validatePAN(detailsState.ownerDetails.pancard.value)
                  ? "Enter Valid PAN"
                  : ""
            }
            max_length={10}
          />
        </Grid>
      </Grid>

      {/* Insured Member Details */}
      {coOwnerDetails ? (
        <Box>
          <Grid container spacing={3}>
            <Grid xs={12}>
              <hr />
              <h6>Co-Owner Details</h6>
            </Grid>
            <Grid xs={6}>
              <RKTextField
                class_name="inputField"
                title={"Full Name"}
                validation_type="NAME"
                value={detailsState.coOwnerDetails.name.value}
                attrName={["name", "value", "coOwnerDetails", "disableTrim"]}
                value_update={fieldsUpdateState}
                warn_status={detailsState.coOwnerDetails.name.warning}
                error_message={
                  isEmpty(detailsState.coOwnerDetails.name.value)
                    ? "Enter Full Name"
                    : !validateFullName(detailsState.coOwnerDetails.name.value)
                      ? "Enter Valid Full Name"
                      : ""
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid xs={4}>
              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileDatePicker
                        label="DOB"
                        value={insuredDOB}
                        onChange={(e:any) => {
                            setInsuredDOB(e.target.value);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider> */}
              <DatePicker
                class_name="inputField"
                title="DOB"
                value={detailsState.coOwnerDetails.dob.value}
                attrName={["dob", "value", "coOwnerDetails", "disableTrim"]}
                value_update={fieldsUpdateState}
                warn_status={detailsState.coOwnerDetails.dob.warning}
                error_message={"Select DOB"}
                max_date={18}
                min_date={100}
                default_date={subYears(new Date(), 18)}
              />
            </Grid>
            <Grid xs={4}>
              <SelectDropdown
                class_name="inputField"
                title="Gender"
                value={detailsState.coOwnerDetails.gender.value}
                attrName={["gender", "value", "coOwnerDetails"]}
                value_update={fieldsUpdateState}
                data={insuredGender_data}
                warn_status={detailsState.coOwnerDetails.gender.warning}
                error_message={"Select Gender"}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid xs={4}>
              <RKTextField
                class_name="inputField"
                title={"Mobile"}
                max_length={10}
                validation_type="NUMBER"
                value={detailsState.coOwnerDetails.mobile.value}
                attrName={["mobile", "value", "coOwnerDetails"]}
                value_update={fieldsUpdateState}
                warn_status={detailsState.coOwnerDetails.mobile.warning}
                error_message={
                  isEmpty(detailsState.coOwnerDetails.mobile.value)
                    ? "Enter Mobile No."
                    : !validateMobileNumber(
                      detailsState.coOwnerDetails.mobile.value
                    )
                      ? "Enter Valid Mobile No."
                      : ""
                }
              />
            </Grid>
            <Grid xs={6}>
              <RKTextField
                class_name="inputField"
                title={"Email"}
                value={detailsState.coOwnerDetails.email.value}
                attrName={["email", "value", "coOwnerDetails"]}
                value_update={fieldsUpdateState}
                warn_status={detailsState.coOwnerDetails.email.warning}
                error_message={
                  isEmpty(detailsState.coOwnerDetails.email.value)
                    ? "Enter Email"
                    : !validateEmail(detailsState.coOwnerDetails.email.value)
                      ? "Enter Valid Email"
                      : ""
                }
              />
            </Grid>
          </Grid>
        </Box>
      ) : null}

      <Grid container spacing={3}>
        <Grid xs={12}>
          <h6>Nominee Details</h6>
        </Grid>
        <Grid xs={6}>
          <RKTextField
            class_name="inputField"
            title={"Name"}
            validation_type="NAME"
            value={detailsState.nomineeDetails.nomineeName.value}
            attrName={["nomineeName", "value", "nomineeDetails", "disableTrim"]}
            value_update={fieldsUpdateState}
            warn_status={detailsState.nomineeDetails.nomineeName.warning}
            error_message={
              isEmpty(detailsState.nomineeDetails.nomineeName.value)
                ? "Enter Full Name"
                : !validateFullName(
                  detailsState.nomineeDetails.nomineeName.value
                )
                  ? "Enter Valid Full Name"
                  : ""
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={4}>
          <SearchDropdown
            class_name="inputField"
            title="Age"
            value={detailsState.nomineeDetails.nomineeAge.value}
            attrName={["nomineeAge", "value", "nomineeDetails"]}
            value_update={fieldsUpdateState}
            data={nomineeAgeData}
            warn_status={detailsState.nomineeDetails.nomineeAge.warning}
            error_message={"Select Age"}
          />
        </Grid>
        {parseInt(detailsState.nomineeDetails.nomineeAge.value) <= 18 ? (
          <Grid xs={4}>
            <RKTextField
              class_name="inputField"
              title={"Appointee Name"}
              validation_type="NAME"
              value={detailsState.nomineeDetails.appointeeName.value}
              attrName={["appointeeName", "value", "nomineeDetails"]}
              value_update={fieldsUpdateState}
              warn_status={detailsState.nomineeDetails.appointeeName.warning}
              error_message={
                isEmpty(detailsState.nomineeDetails.appointeeName.value)
                  ? "Enter Appointee Name"
                  : !validateFullName(
                    detailsState.nomineeDetails.appointeeName.value
                  )
                    ? "Enter Valid Appointee Name"
                    : ""
              }
            />
          </Grid>
        ) : null}
        <Grid xs={4}>
          <SelectDropdown
            class_name="inputField"
            title="Gender"
            value={detailsState.nomineeDetails.nomineeGender.value}
            attrName={["nomineeGender", "value", "nomineeDetails"]}
            value_update={fieldsUpdateState}
            data={proposerGender_data}
            warn_status={detailsState.nomineeDetails.nomineeGender.warning}
            error_message="Select Gender"
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={6}>
          <SelectDropdown
            class_name="inputField"
            title="Nominee Relationship with Owner"
            value={
              detailsState.nomineeDetails.nomineeRelationshipWithOwner.value
            }
            attrName={[
              "nomineeRelationshipWithOwner",
              "value",
              "nomineeDetails",
            ]}
            value_update={fieldsUpdateState}
            data={nomineeRelations}
            warn_status={
              detailsState.nomineeDetails.nomineeRelationshipWithOwner.warning
            }
            error_message="Select Relationship"
          />
        </Grid>
      </Grid>
      <Footer continue_function={validate_form} loader={load} />
    </Box>
  );
};

export default OwnerDetails;
