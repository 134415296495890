import { NODE_DOMAIN, PHP_DOMAIN } from "../Domain";

export const TravelURLs = {
  GET_OCCUPATION_DATA: `${NODE_DOMAIN}/api/v1/travel/get-occupations`,
  NOMINEE_RELATION_LIST: `${NODE_DOMAIN}/api/v1/travel/get-nominne-relationship`,
  Capture_Record: `${NODE_DOMAIN}/api/v1/travel/capture-records`,
  MEDICAL_DATA: `${NODE_DOMAIN}/api/v1/travel/capture-records`,
  MAKE_PAYMENT: `${NODE_DOMAIN}/api/v1/travel/proposal`,
  GET_PREIUM_BY_ADDON: `${NODE_DOMAIN}/api/v1/travel/care-travel-addons`,
}
