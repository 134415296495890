import ReviewDetails from "../../../../../Page/Desktop/HI/ProposalPage/GoDigit/ReviewDetails/ReviewDetails";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";

const ReviewDetailsContainer = () => {
  const isMobile = useIsMobile();

  return <>{isMobile ? <></> : <ReviewDetails />}</>;
};

export default ReviewDetailsContainer;
