import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AddonDetailsOptimaRestore from "../../../Page/Desktop/health-php/HDFC/MedicalDetails/AddonDetailsOptimaRestore";
import MAddonDetailsOptimaRestore from "../../../Page/Mobile/health-php/HDFC/MMedicalDetails/MAddonDetailsOptimaRestore";
import { CKYC_ROUTES } from "../../../Router/Path/CKYCRoutes";
import { HEALTH_ROUTE } from "../../../Router/Path/HealthRoutes";
import { CKYCServcies } from "../../../Services/CKYC/CKYCServices";
import { HEALTH_PROPOSAL_SERVICES } from "../../../Services/health-php/HealthPropsalServices";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import { CKYCSlice } from "../../../Store/Slice_Reducer/CKYC/CKYCSlice";
import { HealthSlice } from "../../../Store/Slice_Reducer/Health/HealthSlice";
import { child_gender } from "../../../SupportingFiles/HealthHelper";
import { calculateAge } from "../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { THealthQuote } from "../../../types/Health/HealthQuotation/THealthQuote";
import { TCareAddon } from "../../../types/Health/TCareAddon";

export default function AddonDetailsContainerOptimaRestore() {
  const isMobile = useIsMobile();
  const {
    ADD_FORM,
    ADD_FORM_RESPONSE,
    ADDON_DETAILS,
    SELECTED_QUOTE_DATA,
    PAGE_STATUS,
  } = useAppSelector((state) => state.Health);
  const { Health, CKYC } = useAppSelector((state) => state);
  const { VERIFY_KYC_FORM } = useAppSelector((state) => state.CKYC);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [addonStatus, setAddonStatus] = useState<TCareAddon>(ADDON_DETAILS);
  const [loader, setLoader] = useState<boolean>(false);
  const [kycOpen, setKycOpen] = useState<boolean>(false);
  useEffect(() => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const txnId = params.get("txnId");
    const status = params.get("status");
    const kyc_id = params.get("kyc_id");
    if (txnId && status && kyc_id) {
      if (status === "pending") {
        toast.error("No Data Found!");

        window.location.href = `https://${window.location.href}${HEALTH_ROUTE.PROPOSAL_PAGE}/${CKYC_ROUTES.CKYC}?type=HP&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA.productDetails.id}`;
      }
      const prams = {
        quote_no: ADD_FORM_RESPONSE.quote_no,
        vehicle_owned: "Individual",
        kyc_id: kyc_id,
      };
      GET_CKYC_DETAILS_FROM_HDFC(prams);
    }
  }, []);

  const GET_CKYC_DETAILS_FROM_HDFC = (params: {
    quote_no: string;
    vehicle_owned: string;
    kyc_id: string;
  }) => {
    const onSuccess = (res: any) => {
      const results = res.results;
      const error = results.error;
      setLoader(false);
      if (!error) {
        const response = results.response;

        dispatch(
          CKYCSlice.actions.BULK_UPDATE({
            ...CKYC,
            CKYC_DETAILS: response,
          })
        );
        dispatch(
          HealthSlice.actions.BULK_UPLOAD({
            ...Health,
            INSURED_MEMBER_DETAILS: {
              ...Health.INSURED_MEMBER_DETAILS,
              PROPOSER_DETAILS: {
                ...Health.INSURED_MEMBER_DETAILS.PROPOSER_DETAILS,
                name: {
                  value: CKYC.CKYC_DETAILS.kycCustomerName,
                  warning: false,
                },
                dob: {
                  value: CKYC.CKYC_DETAILS.kycDOB,
                  warning: false,
                },
                mobile: {
                  value: CKYC.CKYC_DETAILS.kycMobile,
                  warning: false,
                },
                emergencyPhone: { value: "", warning: false },
                email: { value: CKYC.CKYC_DETAILS.kycEmail, warning: false },
                aadhar: { value: "", warning: false },
                gender: {
                  value: VERIFY_KYC_FORM.gender.value,
                  warning: false,
                },
                maritalStatus: { value: "", warning: false },
                weight: { value: "", warning: false },
                heightFeet: { value: "", warning: false },
                heightInches: { value: "", warning: false },
                occupation: { value: "", warning: false },
                relationship: { value: "", warning: false },
                proposer_name: { value: "", warning: false },
              },
            },
            ADDRESS_DETAILS: {
              ...Health.ADDRESS_DETAILS,
              pincode: {
                value: response.kycPincode,
                warning: false,
              },
              city: response.kycCity,
              state: response.kycState,
              addressLineOne: {
                value: response.kycAddress1,
                warning: false,
              },
              addressLineTwo: {
                value: response.kycAddress2,
                warning: false,
              },
              isMailingAddressSame: true,
              mailingPinCode: { value: "", warning: false },
              mailingCity: "",
              mailingState: "",
              mailingAddressLineOne: { value: "", warning: false },
              mailingAddressLineTwo: { value: "", warning: false },
            },
          })
        );
      }
    };
    const onError = () => {
      setLoader(false);
    };
    setKycOpen(true);
    setLoader(true);
    CKYCServcies.GET_CKYC_DETAILS_FROM_HDFC(onSuccess, onError, params);
  };
  useEffect(() => {
    updatePreiumApi();

    dispatch(HealthSlice.actions.ADDON_DETAILS_DATA(addonStatus));
  }, [addonStatus]);

  useEffect(() => {
    if (!addonStatus.recharge) {
      setAddonStatus((prevState) => ({
        ...prevState,
        hdcSa: { value: "", warning: false },
      }));
    }
    if (!addonStatus.ipaRiderCheck) {
      setAddonStatus((prevState) => ({
        ...prevState,
        ipaSa: { value: "", warning: false },
      }));
    }
  }, [addonStatus.recharge, addonStatus.ipaRiderCheck]);
  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "recharge" && value === false) {
      setAddonStatus((prevState) => ({
        ...prevState,
        hdcSa: { value: "", warning: false },
      }));
    } else if (attrName === "ipaRiderCheck" && value === false) {
      setAddonStatus((prevState) => ({
        ...prevState,
        ipaSa: { value: "", warning: false },
      }));
    }
    if (attrName === "recharge" && value === true) {
      setAddonStatus((prevState) => ({
        ...prevState,
        hdcSa: { value: "1000", warning: false },
      }));
    } else if (attrName === "ipaRiderCheck" && value === true) {
      setAddonStatus((prevState) => ({
        ...prevState,
        ipaSa: { value: "1000", warning: false },
      }));
    }
    setAddonStatus((prevState) => ({
      ...prevState,
      [attrName]:
        attrName === "hdcSa" || attrName === "ipaSa"
          ? { value: value, warning: false }
          : value,
    }));
  };

  const updatePreiumApi = () => {
    const onSuccess = (res: any) => {
      if (res.results.error === false) {
        let quoteData: THealthQuote = SELECTED_QUOTE_DATA;
        quoteData = {
          ...quoteData,
          premiumDetails: {
            ...quoteData.premiumDetails,
            finalPremium: res.results.response.premiumDetails.finalPremium,
          },
        };
        dispatch(
          HealthSlice.actions.BULK_UPLOAD({
            ...Health,
            SELECTED_QUOTE_DATA: quoteData,
            ADDON_DETAILS: addonStatus,
            QUOTE_LOADER: false,
          })
        );
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
    };

    dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(true));
    let param: any = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      product_code: SELECTED_QUOTE_DATA?.productDetails?.product_code,
      company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
      more_details: [
        {
          adult1Relation: "self",
          adult1Age: calculateAge(ADD_FORM.self_dob.value),
          adult1Gender: ADD_FORM?.gender?.value || "",
        },
        {
          adult2Relation: ADD_FORM?.gender.value === "M" ? "wife" : "husband",
          adult2Age: calculateAge(ADD_FORM.spouse_dob.value),
          adult2Gender: ADD_FORM?.gender?.value || "",
        },
        {
          childrelation_1:
            child_gender("1", ADD_FORM) === "M" ? "son" : "daughter",
          childage_1: ADD_FORM?.child_one_dob?.value || "",
          childgender_1: child_gender("1", ADD_FORM),
        },
        {
          childrelation_2:
            child_gender("2", ADD_FORM) === "M" ? "son" : "daughter",
          childage_2: ADD_FORM?.child_two_dob?.value || "",
          childgender_2: child_gender("2", ADD_FORM),
        },
        {
          childrelation_3:
            child_gender("3", ADD_FORM) === "M" ? "son" : "daughter",
          childage_3: ADD_FORM?.child_three_dob?.value || "",
          childgender_3: child_gender("3", ADD_FORM),
        },
        {
          childrelation_4:
            child_gender("4", ADD_FORM) === "M" ? "son" : "daughter",
          childage_4: ADD_FORM?.child_four_dob?.value || "",
          childgender_4: child_gender("4", ADD_FORM),
        },
      ],
      addon: {
        recharge: addonStatus.recharge === true ? "Yes" : "No",
        hdcSa: addonStatus.hdcSa.value,
        critical_illness: addonStatus.critical_illness === true ? "Yes" : "No",
        ipaRiderCheck: addonStatus.ipaRiderCheck === true ? "Yes" : "No",
        unlimitedRiderCheck:
          addonStatus.unlimitedRiderCheck === true ? "Yes" : "No",
        ipaSa: addonStatus.ipaRiderCheck
          ? Number(SELECTED_QUOTE_DATA.premiumDetails.sum_insured) * 5
          : "",
      },
    };

    HEALTH_PROPOSAL_SERVICES.getPeriumByAddonHdfc(onSuccess, onError, param);
  };
  const validate = () => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const cus_type = params.get("cus_type");

    const onSuccess = (res: any) => {
      if (!res.results.error) {
        setLoader(false);
        if (PAGE_STATUS) {
          navigate(
            `${HEALTH_ROUTE.PROPOSAL_PAGE}/${
              HEALTH_ROUTE.PREVIEW_DETAILS
            }?type=HP&companyCode=${
              SELECTED_QUOTE_DATA?.CompanyDetails.company_code
            }&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${
              SELECTED_QUOTE_DATA?.productDetails.id
            }${cus_type === "user" ? "&cus_type=user" : ""}`
          );
        } else {
          navigate(
            `${HEALTH_ROUTE.PROPOSAL_PAGE}/${
              HEALTH_ROUTE.ADULT_ONE_DETAILS
            }?type=HP&companyCode=${
              SELECTED_QUOTE_DATA?.CompanyDetails.company_code
            }&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${
              SELECTED_QUOTE_DATA?.productDetails.id
            }${cus_type === "user" ? "&cus_type=user" : ""}`
            // `${HEALTH_ROUTE.PROPOSAL_PAGE}/${CKYC_ROUTES.CKYC}?type=HP&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA.productDetails.id}`
          );
        }
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    setLoader(true);
    let param: any = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      product_code: SELECTED_QUOTE_DATA?.productDetails?.product_code,
      company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
      cover: `${ADD_FORM?.spouse_status ? "2A" : "1A"}${
        (parseInt(ADD_FORM?.son_count?.value) || 0) +
          (parseInt(ADD_FORM?.daughter_count?.value) || 0) >
        0
          ? `${
              (parseInt(ADD_FORM?.son_count?.value) || 0) +
              (parseInt(ADD_FORM?.daughter_count?.value) || 0)
            }C`
          : ""
      }`,
      section: "add_ons_details",
      addon: {
        recharge: addonStatus.recharge === true ? "Yes" : "No",
        hdcSa: addonStatus.hdcSa.value,
        critical_illness: addonStatus.critical_illness === true ? "Yes" : "No",
        ipaRiderCheck: addonStatus.ipaRiderCheck === true ? "Yes" : "No",
        unlimitedRiderCheck:
          addonStatus.unlimitedRiderCheck === true ? "Yes" : "No",
        ipaSa: addonStatus.ipaRiderCheck
          ? Number(SELECTED_QUOTE_DATA.premiumDetails.sum_insured) * 5
          : "",
      },
    };
    // if (PAGE_STATUS) {
    //        navigate(
    //     `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.PREVIEW_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}`
    //   );
    // } else {
    //   navigate(
    //     `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.ADULT_ONE_DETAILS}`
    //     // `${HEALTH_ROUTE.PROPOSAL_PAGE}/${CKYC_ROUTES.CKYC}?type=HP&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA.productDetails.id}`
    //   );
    // }

    HEALTH_PROPOSAL_SERVICES.getCapturedRecordData(onSuccess, onError, param);
  };

  const FinalPremium = () => {
    const onSuccess = (res: any) => {
      console.log("res", res);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    let param: any = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      final_premium: SELECTED_QUOTE_DATA?.premiumDetails?.finalPremium,
    };
    HEALTH_PROPOSAL_SERVICES.GET_FINAL_PREMIUM(onSuccess, onError, param);
  };

  useEffect(() => {
    FinalPremium();
  }, [SELECTED_QUOTE_DATA]);

  useEffect(() => {
    RECORD_CKYC_RESPONSE();
  }, [CKYC.CKYC_DETAILS]);

  const RECORD_CKYC_RESPONSE = () => {
    const onSuccess = (res: any) => {
      console.log("res...", res?.results);
    };
    const onError = (err: any) => {
      console.log("err..", err);
    };
    let param = {
      details: CKYC.CKYC_DETAILS,
      section: "kyc_details",
      quote_no: ADD_FORM_RESPONSE.quote_no,
      cover: `${ADD_FORM?.spouse_status ? "2A" : "1A"}${
        (parseInt(ADD_FORM?.son_count?.value) || 0) +
          (parseInt(ADD_FORM?.daughter_count?.value) || 0) >
        0
          ? `${
              (parseInt(ADD_FORM?.son_count?.value) || 0) +
              (parseInt(ADD_FORM?.daughter_count?.value) || 0)
            }C`
          : ""
      }`,
    };
    HEALTH_PROPOSAL_SERVICES.getCapturedRecordData(onSuccess, onError, param);
  };
  return isMobile ? (
    <MAddonDetailsOptimaRestore
      isLoading={loader}
      addonStatus={addonStatus}
      updateMasterState={updateMasterState}
      validate={validate}
      PAGE_STATUS={PAGE_STATUS}
    />
  ) : (
    // <MAddonDetails
    //   addonStatus={addonStatus}
    //   updateMasterState={updateMasterState}
    // />
    <AddonDetailsOptimaRestore
      isLoading={loader}
      addonStatus={addonStatus}
      updateMasterState={updateMasterState}
      validate={validate}
      PAGE_STATUS={PAGE_STATUS}
    />
  );
}
