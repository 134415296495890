import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import { DateFun, isEmpty } from "../../../../SupportingFiles/HelpingFunction";
import { toast } from "react-toastify";
import { TCareMedicalQuestion } from "../../../../types/Travel/HealthQuotation/TCareMedicalQuestion";
import MedicalDetails from "../../../../Page/Desktop/Travel/CARE/MedicalDetails/MedicalDetails";
import { TRAVEL_PROPOSAL_SERVICES } from "../../../../Services/Travel/TravelPropsalServices";
import { TravelSlice } from "../../../../Store/Slice_Reducer/Travel/TravelSlice";
import { TRAVEL_ROUTE } from "../../../../Router/Path/TravelRoutes";

export default function MedicalDetailsContainer() {
  const isMobile = useIsMobile();
  const {
    INSURED_MEMBER_DETAILS,
    PAGE_STATUS,
    MEDICAL_QUESTION_DATA_CARE,
    SELECTED_QUOTE_DATA,
    ADD_FORM_RESPONSE,
    ADD_FORM
    // Preview_Loader,
  } = useAppSelector((state) => state.Travel);
  const [medicalQuestionData, setMedicalQuestionData] =
    useState<TCareMedicalQuestion>(MEDICAL_QUESTION_DATA_CARE);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [change_status, setchange_status] = useState(false);

  const setMemberDataAsPerUserSelection = () => {
    let setMemberData: any = { ...MEDICAL_QUESTION_DATA_CARE };
    setMemberData = {
      MQ01: {
        main_question: {
          id: "MQ01",
          icon_class: "tobaco",
          title: "Do you have any pre existing Disease?",
          description:
            "",
          status: false,
          sub_question: {
            "MQ1-SQ1": {
              id: "MQ1-SQ1",
              icon_class: "",
              status: false,
              title: "",
              description:
                "Liver Disease?",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                },
              },
            },
            "MQ2-SQ2": {
              id: "MQ2-SQ2",
              icon_class: "",
              status: false,
              title: "",
              description:
                "Cancer / Tumor?",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,

                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
              },
              // field_data: {
              //   "MQ1-SQ1-F2": {
              //     id: "MQ1-SQ1-F2",
              //     title: "Existing Since",
              //     field_type: "DATEPICKER",
              //     value: { value: "", warning: false },
              //   },
              // },
            },
            "MQ3-SQ3": {
              id: "MQ3-SQ3",
              icon_class: "",
              status: false,
              title: "",
              description: "Coronary Artery Heart Disease ?",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,

                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
              },
              // field_data: {
              //   "MQ1-SQ1-F3": {
              //     id: "MQ1-SQ1-F3",
              //     field_type: "DATEPICKER",
              //     title: "Existing Since",
              //     value: { value: "", warning: false },
              //   },
              // },
            },
            "MQ4-SQ4": {
              id: "MQ4-SQ4",
              icon_class: "",
              status: false,
              title: "",
              description: "Kidney Disease?",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,

                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
              },
              // field_data: {
              //   "MQ1-SQ1-F4": {
              //     id: "MQ1-SQ1-F4",
              //     title: "Existing Since",
              //     field_type: "DATEPICKER",
              //     value: { value: "", warning: false },
              //   },
              // },
            },
            "MQ5-SQ5": {
              id: "MQ5-SQ5",
              icon_class: "",
              status: false,
              title: "",
              description: "Paralysis / Stroke?",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,

                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
              },
              // field_data: {
              //   "MQ1-SQ1-F5": {
              //     id: "MQ1-SQ1-F5",
              //     title: "Existing Since",
              //     field_type: "DATEPICKER",
              //     value: { value: "", warning: false },
              //   },
              // },
            },
            "MQ6-SQ6": {
              id: "MQ6-SQ6",
              icon_class: "",
              status: false,
              title: "",
              description: "Any other diseases or ailments not mentioned above?*",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,

                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
              },
              // field_data: {
              //   "MQ1-SQ1-F6": {
              //     id: "MQ1-SQ1-F6",
              //     field_type: "DATEPICKER",
              //     title: "Existing Since",
              //     value: { value: "", warning: false },
              //   },
              // },
            },
          },
        },
      },
      MQ02: {
        main_question: {
          id: "MQ02",
          icon_class: "tobaco",
          title: "Have you ever claimed under any travel policy?",
          description:
            "",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Existing Since",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Existing Since",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Existing Since",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Existing Since",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,

              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Existing Since",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Existing Since",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
      MQ03: {
        main_question: {
          id: "MQ03",
          icon_class: "tobaco",
          title: "Has any of the new person(s) to be insured been diagnosed / hospitalized for any illness / injury during the last 48 months?",
          description:
            "",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Existing Since",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Existing Since",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Existing Since",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Existing Since",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,

              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Existing Since",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Existing Since",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
    };
    setMedicalQuestionData({ ...setMemberData });
  };

  useEffect(() => {
    if (PAGE_STATUS === true
      // ||
      // Preview_Loader === true
    ) {
      setMedicalQuestionData(MEDICAL_QUESTION_DATA_CARE);
    } else {
      setMemberDataAsPerUserSelection();
    }
  }, [MEDICAL_QUESTION_DATA_CARE, PAGE_STATUS]);
  let flag = true;
  console.log(change_status, "sss", flag);

  const updateMasterState = (attrName: any, value: any) => {
    console.log(attrName, 'attrName', value)
    flag = value;
    let manipulatedMedicalQuestion: any = JSON.parse(
      JSON.stringify(medicalQuestionData)
    );
    if (attrName[0] === "question") {
      manipulatedMedicalQuestion = {
        ...manipulatedMedicalQuestion,
        [attrName[1]]: {
          ...manipulatedMedicalQuestion[attrName[1]],
          main_question: {
            ...manipulatedMedicalQuestion[attrName[1]].main_question,
            status: value,
          },
        },
      }
      setMedicalQuestionData({ ...manipulatedMedicalQuestion });
    }
    else if (attrName[0] === "subQuestion") {
      setchange_status(flag);
      let updatedSubQuestion = {
        ...manipulatedMedicalQuestion[attrName[1]].main_question.sub_question,
        [attrName[2]]: {
          ...manipulatedMedicalQuestion[attrName[1]].main_question.sub_question[
          attrName[2]
          ],
          status: value,
        },
      };
      let updatedMainQuestion = {
        ...manipulatedMedicalQuestion[attrName[1]].main_question,
        sub_question: updatedSubQuestion,
      };
      let updatedManipulatedMedicalQuestion = {
        ...manipulatedMedicalQuestion,
        [attrName[1]]: {
          ...manipulatedMedicalQuestion[attrName[1]],
          main_question: updatedMainQuestion,
        },
      };
      setMedicalQuestionData(updatedManipulatedMedicalQuestion);
    }
    else if (attrName[0] === "memberData") {
      if (
        manipulatedMedicalQuestion[attrName[1]]?.main_question.sub_question[
          attrName[2]
        ]?.member_data[attrName[3]] !== undefined
      ) {
        manipulatedMedicalQuestion = {
          ...manipulatedMedicalQuestion,
          [attrName[1]]: {
            ...manipulatedMedicalQuestion[attrName[1]],
            main_question: {
              ...manipulatedMedicalQuestion[attrName[1]]?.main_question,
              sub_question: {
                ...manipulatedMedicalQuestion[attrName[1]].main_question
                  .sub_question,
                [attrName[2]]: {
                  ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                    .sub_question?.[attrName[2]],
                  member_data: {
                    ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                      .sub_question?.[attrName[2]].member_data,
                    [attrName[3]]: {
                      ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                        .sub_question?.[attrName[2]].member_data?.[attrName[3]],
                      selection_status: value,
                    },
                  },
                },
              },
            },
          },
        };
        setMedicalQuestionData({ ...manipulatedMedicalQuestion });
      }
    }
    else if (attrName[0] === "mainMemberData") {
      if (
        manipulatedMedicalQuestion[attrName[1]]?.main_question?.member_data[
        attrName[2]
        ] !== undefined
      )
        manipulatedMedicalQuestion = {
          ...manipulatedMedicalQuestion,
          [attrName[1]]: {
            ...manipulatedMedicalQuestion[attrName[1]],
            main_question: {
              ...manipulatedMedicalQuestion[attrName[1]]?.main_question,
              member_data: {
                ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                  .member_data,
                [attrName[2]]: {
                  ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                    ?.member_data?.[attrName[2]],
                  selection_status: value,
                },
              },
            },
          },
        };


      console.log(attrName, "mainMemberData", manipulatedMedicalQuestion)
      setMedicalQuestionData(manipulatedMedicalQuestion);
    }
    else if (attrName[0] === "mainMemberDataField") {
      if (attrName[1] === "MQ02" || attrName[1] === "MQ03") {
        if (
          attrName[3] === "adult_one" ||
          attrName[3] === "adult_two" ||
          attrName[3] === "child_one" ||
          attrName[3] === "child_two" ||
          attrName[3] === "child_three" ||
          (attrName[3] === "child_four" &&
            manipulatedMedicalQuestion !== undefined)
        ) {
          console.log("attrName[3]", attrName[3]);

          manipulatedMedicalQuestion[attrName[1]].main_question.member_data[
            attrName[3]
          ].field_data[attrName[2]].value = {
            value: value,
            warning: isEmpty(value),
          };

          setMedicalQuestionData({ ...manipulatedMedicalQuestion });
        }
      } else {
        if (
          attrName[4] === "adult_one" ||
          attrName[4] === "adult_two" ||
          attrName[4] === "child_one" ||
          attrName[4] === "child_two" ||
          attrName[4] === "child_three" ||
          (attrName[4] === "child_four" &&
            manipulatedMedicalQuestion !== undefined)
        ) {
          manipulatedMedicalQuestion[attrName[1]].main_question.sub_question[
            attrName[2]
          ].member_data[attrName[4]].field_data[attrName[3]].value = {
            value: value,
            warning: isEmpty(value),
          };
          setMedicalQuestionData({ ...manipulatedMedicalQuestion });
        }
      }
    }

    console.log(medicalQuestionData, manipulatedMedicalQuestion, "manipulatedMedicalQuestion")

  };

  const validateForm = () => {
    // Ensure medicalQuestionData is not null or undefined
    if (!medicalQuestionData) {
      return; // Exit early if medicalQuestionData is null or undefined
    }
    // Deep copy the medicalQuestionData object to avoid state mutations
    let data: TCareMedicalQuestion = JSON.parse(
      JSON.stringify(medicalQuestionData)
    );
    let hasError = false;

    Object.values(data).forEach((element: any) => {
      const mainQuestion = element?.main_question;

      if (mainQuestion) {
        // Check if mainQuestion has subQuestions
        if (mainQuestion.sub_question) {
          Object.values(mainQuestion.sub_question).forEach((subQuestion: any) => {
            if (subQuestion && subQuestion.status) {
              const hasFalseSelectionStatus = Object.values(subQuestion.member_data).some(
                (memberdata: any) => !memberdata.selection_status
              );
              const hasTrueSelectionStatus = Object.values(subQuestion.member_data).some(
                (memberdata: any) => memberdata.selection_status
              );

              // if (hasFalseSelectionStatus && !hasTrueSelectionStatus) {
              //   hasError = true;
              //   toast.error("Select Sub-Question members");
              // }
            }
          });
        } else if (mainQuestion.status && mainQuestion.member_data) {
          // No subQuestions, handle member_data directly
          const hasFalseSelectionStatus = Object.values(mainQuestion.member_data).some(
            (memberdata: any) => !memberdata.selection_status
          );
          const hasTrueSelectionStatus = Object.values(mainQuestion.member_data).some(
            (memberdata: any) => memberdata.selection_status
          );

          if (hasFalseSelectionStatus && !hasTrueSelectionStatus) {
            hasError = true;
            toast.error("Select members");
          }
        }
      }
    });

    Object.values(data).forEach((element: any) => {
      const mainQuestion = element?.main_question;
      if (mainQuestion && mainQuestion.status && mainQuestion.sub_question) {
        Object.values(mainQuestion.sub_question).forEach((subQuestion: any) => {
          if (subQuestion && subQuestion.status) {
            Object.values(subQuestion.member_data).forEach(
              (memberdata: any) => {
                if (
                  memberdata &&
                  memberdata.selection_status &&
                  memberdata.field_data
                ) {
                  Object.values(memberdata.field_data).forEach(
                    (field_data: any) => {
                      if (field_data && field_data.value?.value === "") {
                        let clonedValue = {
                          ...field_data.value,
                          warning: true,
                        };

                        if (field_data.value) {
                          field_data.value = clonedValue;
                        }
                        hasError = true;
                      }
                    }
                  );
                }
              }
            );
          }
        });
      }
    });

    if (medicalQuestionData.MQ01.main_question.status) {
      if (
        medicalQuestionData.MQ01.main_question.status &&
        medicalQuestionData.MQ01.main_question.sub_question &&
        !medicalQuestionData.MQ01.main_question.sub_question["MQ1-SQ1"]?.status &&
        !medicalQuestionData.MQ01.main_question.sub_question["MQ3-SQ3"]?.status &&
        !medicalQuestionData.MQ01.main_question.sub_question["MQ5-SQ5"]?.status &&
        !medicalQuestionData.MQ01.main_question.sub_question["MQ2-SQ2"]?.status &&
        !medicalQuestionData.MQ01.main_question.sub_question["MQ4-SQ4"]?.status &&
        !medicalQuestionData.MQ01.main_question.sub_question["MQ6-SQ6"]?.status
      ) {
        toast.error("Please Select Sub Question");
        hasError = true;
      }
    }

    setMedicalQuestionData(data);
    if (!hasError) {
      dispatch(TravelSlice.actions.SAVE_MEDICAL_QUESTION_DATA_CARE(data));

      const onSuccess = (res: any) => {
        console.log(res, "dfnfgnfgn")
        const error = res.error
        if (!error) {
          // let quoteData: any = SELECTED_QUOTE_DATA;
          // quoteData = {
          //   ...quoteData,
          //   premiumDetails: {
          //     ...quoteData.premiumDetails,
          //     finalPremium: res.results.response.premiumDetails.finalPremium,
          //   },
          // };
          // dispatch(HealthSlice.actions.SAVE_SELECTED_QUOTE_DATA(quoteData));

          const windowURL = window.location.search;
          const params = new URLSearchParams(windowURL);
          const cus_type = params.get("cus_type");
          navigate(`${TRAVEL_ROUTE.PROPOSAL_PAGE}/${TRAVEL_ROUTE.PREVIEW_DETAILS}?type=TI&companyCode=${SELECTED_QUOTE_DATA?.companyCode}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productID}${cus_type === "user" ? "&cus_type=user" : ""}`);
        }
      };

      const onError = (err: any) => {
        console.log("err..", err);
      };

      let param: any = {
        "section": "medical_details",
        "quote_no": ADD_FORM_RESPONSE.quote_no,
        "cover": ADD_FORM.members.value,
        "saveAllDetails": "Yes",
        "finalPremium": "1125",

        // self
        medical_pre_existing: data.MQ01.main_question.status === true ? "Yes" : "No",
        adult1_liver: data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data?.adult_one?.selection_status ? "Yes" : "No",
        adult1_cancer: data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data?.adult_one?.selection_status ? "Yes" : "No",
        adult1_heart: data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data?.adult_one?.selection_status ? "Yes" : "No",
        adult1_kidney: data?.MQ01?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data?.adult_one?.selection_status ? "Yes" : "No",
        adult1_paralysis: data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data?.adult_one?.selection_status ? "Yes" : "No",
        adult1_other_disease: data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data?.adult_one?.selection_status ? "Yes" : "No",
        adult1_diseases: data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data?.adult_one.field_data?.["MQ1-SQ1-F2"].value?.value,// textarea
        adult1_Claimed: data?.MQ02?.main_question?.member_data?.adult_one.selection_status ? "Yes" : "No",
        claimedDisAdult1: data?.MQ02?.main_question?.member_data?.adult_one.field_data?.["MQ1-SQ1-F2"].value?.value,// textarea
        adult1_hospitalized: data?.MQ03?.main_question?.member_data?.adult_one.selection_status ? "Yes" : "No",
        hospitalizedDisAdult1: data?.MQ03?.main_question?.member_data?.adult_one.field_data?.["MQ1-SQ1-F2"].value?.value,// textarea

        // spouse
        spouse_liver: data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data?.adult_two?.selection_status ? "Yes" : "No",
        spouse_cancer: data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data?.adult_two?.selection_status ? "Yes" : "No",
        spouse_heart: data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data?.adult_two?.selection_status ? "Yes" : "No",
        spouse_kidney: data?.MQ01?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data?.adult_two?.selection_status ? "Yes" : "No",
        spouse_paralysis: data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data?.adult_two?.selection_status ? "Yes" : "No",
        spouse_other_disease: data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data?.adult_two?.selection_status ? "Yes" : "No",
        spouse_diseases: data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data?.adult_two.field_data?.["MQ1-SQ1-F2"].value?.value,// textarea
        spouse_Claimed: data?.MQ02?.main_question?.member_data?.adult_two.selection_status ? "Yes" : "No",
        claimedDisSpouse: data?.MQ02?.main_question?.member_data?.adult_two.field_data?.["MQ1-SQ1-F2"].value?.value,// textarea
        spouse_hospitalized: data?.MQ03?.main_question?.member_data?.adult_two.selection_status ? "Yes" : "No",
        hospitalizedDisSpouse: data?.MQ03?.main_question?.member_data?.adult_two.field_data?.["MQ1-SQ1-F2"].value?.value,// textarea

        // child1
        child1_liver: data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data?.child_one?.selection_status ? "Yes" : "No",
        child1_cancer: data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data?.child_one?.selection_status ? "Yes" : "No",
        child1_heart: data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data?.child_one?.selection_status ? "Yes" : "No",
        child1_kidney: data?.MQ01?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data?.child_one?.selection_status ? "Yes" : "No",
        child1_paralysis: data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data?.child_one?.selection_status ? "Yes" : "No",
        child1_other_disease: data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data?.child_one?.selection_status ? "Yes" : "No",
        child1_diseases: data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data?.child_one.field_data?.["MQ1-SQ1-F2"].value?.value,// textarea
        child1_Claimed: data?.MQ02?.main_question?.member_data?.child_one.selection_status ? "Yes" : "No",
        claimedDischild1: data?.MQ02?.main_question?.member_data?.child_one.field_data?.["MQ1-SQ1-F2"].value?.value,// textarea
        child1_hospitalized: data?.MQ03?.main_question?.member_data?.child_one.selection_status ? "Yes" : "No",
        hospitalizedDischild1: data?.MQ03?.main_question?.member_data?.child_one.field_data?.["MQ1-SQ1-F2"].value?.value,// textarea

        // child2
        child2_liver: data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data?.child_two?.selection_status ? "Yes" : "No",
        child2_cancer: data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data?.child_two?.selection_status ? "Yes" : "No",
        child2_heart: data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data?.child_two?.selection_status ? "Yes" : "No",
        child2_kidney: data?.MQ01?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data?.child_two?.selection_status ? "Yes" : "No",
        child2_paralysis: data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data?.child_two?.selection_status ? "Yes" : "No",
        child2_other_disease: data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data?.child_two?.selection_status ? "Yes" : "No",
        child2_diseases: data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data?.child_two.field_data?.["MQ1-SQ1-F2"].value?.value,// textarea
        child2_Claimed: data?.MQ02?.main_question?.member_data?.child_two.selection_status ? "Yes" : "No",
        claimedDischild2: data?.MQ02?.main_question?.member_data?.child_two.field_data?.["MQ1-SQ1-F2"].value?.value,// textarea
        child2_hospitalized: data?.MQ03?.main_question?.member_data?.child_two.selection_status ? "Yes" : "No",
        hospitalizedDischild2: data?.MQ03?.main_question?.member_data?.child_two.field_data?.["MQ1-SQ1-F2"].value?.value,// textarea

        // child3
        child3_liver: data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data?.child_three?.selection_status ? "Yes" : "No",
        child3_cancer: data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data?.child_three?.selection_status ? "Yes" : "No",
        child3_heart: data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data?.child_three?.selection_status ? "Yes" : "No",
        child3_kidney: data?.MQ01?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data?.child_three?.selection_status ? "Yes" : "No",
        child3_paralysis: data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data?.child_three?.selection_status ? "Yes" : "No",
        child3_other_disease: data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data?.child_three?.selection_status ? "Yes" : "No",
        child3_diseases: data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data?.child_three.field_data?.["MQ1-SQ1-F2"].value?.value,// textarea
        child3_Claimed: data?.MQ02?.main_question?.member_data?.child_three.selection_status ? "Yes" : "No",
        claimedDischild3: data?.MQ02?.main_question?.member_data?.child_three.field_data?.["MQ1-SQ1-F2"].value?.value,// textarea
        child3_hospitalized: data?.MQ03?.main_question?.member_data?.child_three.selection_status ? "Yes" : "No",
        hospitalizedDischild3: data?.MQ03?.main_question?.member_data?.child_three.field_data?.["MQ1-SQ1-F2"].value?.value,// textarea

        // child4
        child4_liver: data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data?.child_four?.selection_status ? "Yes" : "No",
        child4_cancer: data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data?.child_four?.selection_status ? "Yes" : "No",
        child4_heart: data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data?.child_four?.selection_status ? "Yes" : "No",
        child4_kidney: data?.MQ01?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data?.child_four?.selection_status ? "Yes" : "No",
        child4_paralysis: data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data?.child_four?.selection_status ? "Yes" : "No",
        child4_other_disease: data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data?.child_four?.selection_status ? "Yes" : "No",
        child4_diseases: data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data?.child_four.field_data?.["MQ1-SQ1-F2"].value?.value,// textarea
        child4_Claimed: data?.MQ02?.main_question?.member_data?.child_four.selection_status ? "Yes" : "No",
        claimedDischild4: data?.MQ02?.main_question?.member_data?.child_four.field_data?.["MQ1-SQ1-F2"].value?.value,// textarea
        child4_hospitalized: data?.MQ03?.main_question?.member_data?.child_four.selection_status ? "Yes" : "No",
        hospitalizedDischild4: data?.MQ03?.main_question?.member_data?.child_four.field_data?.["MQ1-SQ1-F2"].value?.value,// textarea
      };

      TRAVEL_PROPOSAL_SERVICES.getMedicalData(onSuccess, onError, param);
    }
  };

  return isMobile ? (
    null
  ) : (
    // null
    <MedicalDetails
      medicalQuestionData={medicalQuestionData}
      updateMasterState={updateMasterState}
      validateForm={validateForm}
      PAGE_STATUS={PAGE_STATUS}
    />
  );
}
