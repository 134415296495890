// faviconUtils.js

import { CLIENTS } from "../URLCollection/Clients";

function changeFaviconAndTitle() {
  var hostname = window.location.hostname;
  var faviconPath = "";
  var title = "";

  // Determine favicon based on hostname
  switch (hostname) {
    case CLIENTS.JIO:
      faviconPath = "/images/clients/favicon/jio.ico";
      title = "Jio Insurance Broking Ltd";
      document.body.classList.add("jio-insure");

      // Add the script tag to the head
      const scriptTag = document.createElement("script");
      scriptTag.textContent = `
          (function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
            var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l !== "dataLayer" ? "&l=" + l : "";
            j.async = true;
            j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
            f.parentNode.insertBefore(j, f);
          })(window, document, "script", "dataLayer", "GTM-NHS3BX2R");
        `;
      document.head.appendChild(scriptTag);

      // Add the noscript tag to the body
      const noscriptTag = document.createElement("noscript");
      const iframeTag = document.createElement("iframe");
      iframeTag.src =
        "https://www.googletagmanager.com/ns.html?id=GTM-NHS3BX2R";
      iframeTag.height = "0";
      iframeTag.width = "0";
      iframeTag.style.display = "none";
      iframeTag.style.visibility = "hidden";
      noscriptTag.appendChild(iframeTag);
      document.body.appendChild(noscriptTag);

      break;
    case CLIENTS.MOTILAL_PHP:
    case CLIENTS.MOTILAL:
      faviconPath = "/images/clients/favicon/motilal.ico";
      title = "MotilalOswal - Compare Online Insurance Quotes";
      document.body.classList.add("motilal-insure");
      break;
    case CLIENTS.AMAZE:
      faviconPath = "/images/clients/favicon/bimastreet.ico";
      title = "Bimastreet - Amaze Insurance Brokers";
      document.body.classList.add("bimastreet-insure");

      // Dynamically inject the GTM script and noscript tag for GTM
      const scriptTagAMAZE = document.createElement("script");
      scriptTagAMAZE.textContent = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-KBM2MCR5');`;
      document.head.appendChild(scriptTagAMAZE);

      // Add the noscript tag to the body for GTM iframe
      const noscriptTagAMAZE = document.createElement("noscript");
      const iframeTagAMAZE = document.createElement("iframe");
      iframeTagAMAZE.src =
        "https://www.googletagmanager.com/ns.html?id=GTM-KBM2MCR5";
      iframeTagAMAZE.height = "0";
      iframeTagAMAZE.width = "0";
      iframeTagAMAZE.style.display = "none";
      iframeTagAMAZE.style.visibility = "hidden";
      noscriptTagAMAZE.appendChild(iframeTagAMAZE);
      document.body.appendChild(noscriptTagAMAZE);
      break;
    case CLIENTS.AHALIA:
      faviconPath = "/images/clients/favicon/policyfirst.ico";
      title = "Ahalia - Policy First";
      document.body.classList.add("ahalia-insure");
      break;
    case CLIENTS.MUTHOOT:
      faviconPath = "/images/clients/favicon/muthoot.ico";
      title = "Muthoot Insurance Brokers";
      document.body.classList.add("muthoot-insure");
      break;
    case CLIENTS.POLICYPARIVAAR:
      faviconPath = "/images/clients/favicon/policyparivaar.ico";
      title = "Policy Parivaar";
      document.body.classList.add("pp-insure");
      break;
    case CLIENTS.WALNNUT:
      faviconPath = "/images/clients/favicon/policyparivaar.ico";
      title =
        "walnnut.com | Online Insurance - Compare Online Insurance Quotes - Health, Car, Two wheeler";
      document.body.classList.add("walnnut-insure");
      break;
    case CLIENTS.CLICKPOLICY:
      faviconPath = "/images/clients/favicon/clickpolicy.ico";
      title = "1 Click Policy Insurance Brokers";
      document.body.classList.add("clickpolicy-insure");
      break;
    case CLIENTS.UNISON:
      faviconPath = "/images/clients/favicon/unison.ico";
      title = "Unison";
      document.body.classList.add("unison-insure");
      break;
    case CLIENTS.BESECURED:
      faviconPath = "/images/clients/favicon/besecured.ico";
      title = "Besecured";
      document.body.classList.add("besecured-insure");
      break;
    case CLIENTS.GROWWINSURANCE:
      faviconPath = "/images/clients/favicon/groww.ico";
      title = "Rahe Insurance Broking Private Limited";
      document.body.classList.add("groww-insure");
      break;
    default:
      faviconPath = "/images/clients/favicon/jio.ico";
      title = "Jio Insurance Broking Ltd";
      document.body.classList.add("jio-insure");
      break;
  }

  // Set the favicon
  var link =
    document.querySelector("link[rel*='icon']") ||
    document.createElement("link");
  link.type = "image/x-icon";
  link.rel = "icon";
  link.href = faviconPath;
  document.getElementsByTagName("head")[0].appendChild(link);
  document.title = title;
}

export default changeFaviconAndTitle;
