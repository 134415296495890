import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";
import AddonQuestion from "../../../../../Component/AddonQuestion/AddonQuestion";
import SelectDropdown from "../../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";
import Footer from "../../../../../Component/Footer/Footer";
import Loader from "../../../../../Component/Loader/Loader";
import { HEALTH_ROUTE } from "../../../../../Router/Path/HealthRoutes";
import { useAppSelector } from "../../../../../Store/hooks";
import {
  FORMAT_DATE_DD_MM_YYYY,
  calculateAge,
} from "../../../../../SupportingFiles/HelpingFunction";
import { TCareAddon } from "../../../../../types/Health/TCareAddon";
import MFooter from "../../../../../Component/MFooter/MFooter";

function MAddonDetails({
  addonStatus,
  updateMasterState,
  validate,
  PAGE_STATUS,
  isLoading,
  mandatoryAddon,
}: {
  addonStatus: TCareAddon;
  PAGE_STATUS: boolean;
  updateMasterState: Function;
  validate: Function;
  isLoading: any;
  mandatoryAddon: any;
}) {
  const navigate = useNavigate();
  const { SELECTED_QUOTE_DATA, ADD_FORM } = useAppSelector(
    (state) => state.Health
  );
  const { CKYC_DETAILS } = useAppSelector((state) => state.CKYC);
  {
    isLoading && <Loader />;
  }
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Box>
          <h5 className="sectionTitle">Addon Details</h5>
          {SELECTED_QUOTE_DATA.productDetails.id === 2651 ? (
            <Grid container spacing={0} paddingTop="12px">
              <Grid xs={12}>
                <AddonQuestion
                  disable={
                    mandatoryAddon?.wellness_benefit === 1 ? true : false
                  }
                  addon_amount=""
                  main_class="addonQuestion borderBottom"
                  icon_class="bonus"
                  question_name="Wellness Benefit"
                  toggle_status={addonStatus.field_WB}
                  value_update={updateMasterState}
                  attrName={"field_WB"}
                  desc="We offer up to 30% discount on renewal premium for achieving your target of 10,000 steps a day"
                  showAsterisk={true}
                />
                <AddonQuestion
                  disable={mandatoryAddon?.be_fit === 1 ? true : false}
                  addon_amount=""
                  main_class="addonQuestion borderBottom"
                  icon_class="bonus"
                  question_name="Be-Fit Benefit"
                  toggle_status={addonStatus.field_BFB}
                  value_update={updateMasterState}
                  attrName={"field_BFB"}
                  desc=""
                  showAsterisk={true}
                />
                <AddonQuestion
                  disable={mandatoryAddon?.care_opd === 1 ? true : false}
                  addon_amount=""
                  main_class="addonQuestion borderBottom"
                  icon_class="bonus"
                  question_name="Care OPD (Outpatient Department)"
                  toggle_status={addonStatus.field_OPD}
                  value_update={updateMasterState}
                  attrName={"field_OPD"}
                  desc="Get covered for 4 General Physician consultations and any 4 Specialist consultations from the list of 14 specified specialists. You will get a maximum reimbursement of Rs 500 for each consultation."
                />
                <AddonQuestion
                  disable={mandatoryAddon?.air_ambulance === 1 ? true : false}
                  addon_amount=""
                  main_class="addonQuestion borderBottom"
                  icon_class="bonus"
                  question_name="Air Ambulance Cover"
                  desc="Air Ambulance Cover provides financial coverage for the
              expenses incurred in availing of an air ambulance in times of
              need."
                  toggle_status={addonStatus.AirAmbulanceCover}
                  value_update={updateMasterState}
                  attrName={"AirAmbulanceCover"}
                  showAsterisk={true}
                />
                <AddonQuestion
                  disable={
                    mandatoryAddon?.ped_wait_period_modification === 1
                      ? true
                      : false
                  }
                  addon_amount=""
                  main_class="addonQuestion borderBottom"
                  icon_class="bonus"
                  question_name="Pre-existing disease wait period modification"
                  toggle_status={addonStatus.field_43}
                  desc="Get your pre-existing disease waiting period reduced to 1 year, 2 years or 3 years from default existing 4 years waiting period"
                  value_update={updateMasterState}
                  attrName={"field_43"}
                  sub_details={
                    <Box maxWidth={"200px"}>
                      <SelectDropdown
                        title="Tenure"
                        value={addonStatus.field_PED_tenure.value}
                        value_update={updateMasterState}
                        attrName={"field_PED_tenure"}
                        data={[
                          { value: "1", label: "1 Year" },
                          { value: "2", label: "2 Years" },
                          { value: "3", label: "3 Years" },
                        ]}
                      />
                    </Box>
                  }
                />
                <AddonQuestion
                  addon_amount=""
                  main_class="addonQuestion borderBottom"
                  icon_class="bonus"
                  question_name="Claim Shield"
                  toggle_status={addonStatus.CareShield}
                  value_update={updateMasterState}
                  attrName={"CareShield"}
                  desc="Get claim for expenses incurred on 68 Non-Payable items as per list of items in policy T&C."
                  disable={mandatoryAddon?.claim_shield === 1 ? true : false}
                />
                <AddonQuestion
                  addon_amount=""
                  main_class="addonQuestion borderBottom"
                  icon_class="treatments"
                  question_name="Cumulative Bonus Super"
                  toggle_status={addonStatus.NCBSuper}
                  value_update={updateMasterState}
                  attrName={"NCBSuper"}
                  desc="Up to 100% of cover amount per year, max up to 500% of sum insured;shall not reduce in case of claim"
                  // disable={
                  //   calculateAge(
                  //     FORMAT_DATE_DD_MM_YYYY(ADD_FORM.self_dob.value)
                  //   ) <= 45 ||
                  //   (calculateAge(
                  //     FORMAT_DATE_DD_MM_YYYY(ADD_FORM.self_dob.value)
                  //   ) >= 76 &&
                  //     calculateAge(
                  //       FORMAT_DATE_DD_MM_YYYY(ADD_FORM.self_dob.value)
                  //     ) <= 99) ||
                  //   (calculateAge(
                  //     FORMAT_DATE_DD_MM_YYYY(ADD_FORM.spouse_dob.value)
                  //   ) >= 76 &&
                  //     calculateAge(
                  //       FORMAT_DATE_DD_MM_YYYY(ADD_FORM.spouse_dob.value)
                  //     ) <= 99)
                  // }
                  disable={
                    mandatoryAddon?.cumulative_bonus_super === 1 ? true : false
                  }
                  // showAsterisk={true}
                />
                <AddonQuestion
                  addon_amount=""
                  main_class="addonQuestion borderBottom"
                  icon_class="bonus"
                  question_name="Annual Health Check-up"
                  toggle_status={addonStatus.field_AHC}
                  value_update={updateMasterState}
                  attrName={"field_AHC"}
                  desc="Once for all insured every policy year."
                  disable={
                    mandatoryAddon?.annual_health_check_up === 1 ? true : false
                  }
                />
                <AddonQuestion
                  disable={mandatoryAddon?.instant_cover === 1 ? true : false}
                  addon_amount=""
                  main_class="addonQuestion borderBottom"
                  icon_class="bonus"
                  question_name="Instant Cover"
                  toggle_status={addonStatus.field_IC}
                  value_update={updateMasterState}
                  attrName={"field_IC"}
                  desc="Claim can be made for hospitalization related to Diabetes, Hypertension, Hyperlipidemia & Asthma after initial wait period of 30 days."
                />
              </Grid>
            </Grid>
          ) : SELECTED_QUOTE_DATA.productDetails.id === 1551 ? (
            <Grid container spacing={3}>
              <Grid xs={12}>
                <AddonQuestion
                  addon_amount=""
                  main_class="addonQuestion borderBottom"
                  icon_class="treatments"
                  question_name="Field CS"
                  toggle_status={addonStatus.field_CS}
                  value_update={updateMasterState}
                  attrName={"field_CS"}
                />
                <AddonQuestion
                  addon_amount=""
                  main_class="addonQuestion borderBottom"
                  icon_class="treatments"
                  question_name="Field HomeCare"
                  toggle_status={addonStatus.field_HomeCare}
                  value_update={updateMasterState}
                  attrName={"field_HomeCare"}
                />
                <AddonQuestion
                  addon_amount=""
                  main_class="addonQuestion borderBottom"
                  icon_class="treatments"
                  question_name="Field OPD"
                  toggle_status={addonStatus.field_OPD}
                  value_update={updateMasterState}
                  attrName={"field_OPD"}
                  disable={true}
                />
              </Grid>
            </Grid>
          ) : SELECTED_QUOTE_DATA.productDetails.id === 443 ? (
            <Grid container spacing={3}>
              <Grid xs={12}>
                <AddonQuestion
                  addon_amount=""
                  main_class="addonQuestion borderBottom"
                  icon_class="treatments"
                  question_name="Cumulative Bonus Super"
                  toggle_status={addonStatus.NCBSuper}
                  value_update={updateMasterState}
                  attrName={"NCBSuper"}
                  desc="You can add additional 50% increase in Sum Insure for every claim free year. Maximum increase allowed is 100% of policy Sum Insure. Hence with both NCB & NCB SUPER you can increase your Policy Sum Insure upto 150% in 5 consecutive claim free years."
                />
                <AddonQuestion
                  addon_amount=""
                  main_class="addonQuestion borderBottom"
                  icon_class="bonus"
                  question_name="Smart Select"
                  desc="Smart Select will allow 15% Discount on Premium in lieu of
              making cashless claims in Smart Select Network Hospitals. 20%
              Co-Payment will be applicable on re-imbersement claims in
              Network or outside network."
                  toggle_status={addonStatus.SmartSelect}
                  value_update={updateMasterState}
                  attrName={"SmartSelect"}
                />
                {/* <AddonQuestion
                  addon_amount=""
                  main_class="addonQuestion borderBottom"
                  icon_class="bonus"
                  question_name="Air Ambulance Cover"
                  desc="Air Ambulance Cover provides financial coverage for the
              expenses incurred in availing of an air ambulance in times of
              need."
                  toggle_status={addonStatus.AirAmbulanceCover}
                  value_update={updateMasterState}
                  attrName={"AirAmbulanceCover"}
                />
                <AddonQuestion
                  addon_amount=""
                  main_class="addonQuestion borderBottom"
                  icon_class="bonus"
                  question_name="Diabetes Kit"
                  toggle_status={addonStatus.field_DK}
                  value_update={updateMasterState}
                  attrName={"field_DK"}
                />
                <AddonQuestion
                  addon_amount=""
                  main_class="addonQuestion borderBottom"
                  icon_class="bonus"
                  question_name="Discount Connect + Unlimited E-consultation"
                  toggle_status={addonStatus.field_DUE}
                  value_update={updateMasterState}
                  attrName={"field_DUE"}
                /> */}
                <AddonQuestion
                  addon_amount=""
                  main_class="addonQuestion borderBottom"
                  icon_class="bonus"
                  question_name="Claim Shield"
                  toggle_status={addonStatus.CareShield}
                  value_update={updateMasterState}
                  attrName={"CareShield"}
                  desc="List of Non-payable expenses like attendant charges, gloves, mask etc, now payable under this benefit in the policy (For detailed list refer Annexure I of policy details)"
                />
                <AddonQuestion
                  addon_amount=""
                  main_class="addonQuestion borderBottom"
                  icon_class="bonus"
                  question_name="Waiver"
                  toggle_status={addonStatus.field_CPW}
                  value_update={updateMasterState}
                  attrName={"field_CPW"}
                  desc="If the age of the insured member or eldest insured member is 61 years or above, by opting for this add-on, you can get rid of the 20% co-payment worry. We will pay medical expenses incurred for hospitalization subject to policy terms and conditions."
                />
                <AddonQuestion
                  addon_amount=""
                  main_class="addonQuestion borderBottom"
                  icon_class="bonus"
                  question_name="OPD"
                  disable={CKYC_DETAILS.zone === "Zone 3" && true}
                  toggle_status={
                    CKYC_DETAILS.zone === "Zone 3"
                      ? true
                      : addonStatus.field_OPD
                  }
                  value_update={updateMasterState}
                  attrName={"field_OPD"}
                  desc="Through this optional cover, one can claim amount equivalent to their bills provided at the time of reimbursement. Coverage amount ranging from INR 5,000 to INR 50,000 can be opted."
                  sub_details={
                    <Box maxWidth={"200px"}>
                      <SelectDropdown
                        title="OPD"
                        value={addonStatus.field_OPD_data.value}
                        value_update={updateMasterState}
                        attrName={"field_OPD_data"}
                        data={[
                          { value: "5000", label: "5000" },
                          { value: "10000", label: "10000" },
                          { value: "15000", label: "15000" },
                          { value: "20000", label: "20000" },
                          { value: "25000", label: "25000" },
                          { value: "30000", label: "30000" },
                          { value: "35000", label: "35000" },
                          { value: "40000", label: "40000" },
                          { value: "45000", label: "45000" },
                          { value: "50000", label: "50000" },
                        ]}
                      />
                    </Box>
                  }
                />
              </Grid>
            </Grid>
          ) : SELECTED_QUOTE_DATA.productDetails.id === 2133 ? (
            <Grid container spacing={3}>
              <Grid xs={12}>
                <AddonQuestion
                  addon_amount=""
                  main_class="addonQuestion borderBottom"
                  icon_class="treatments"
                  question_name="NCB"
                  toggle_status={addonStatus.NCBSuper}
                  showAsterisk={true}
                  sub_details={
                    <p>
                      NCB Super will allow 15% Discount on Premium in lieu of
                      making cashless claims in Smart Select Network Hospitals.
                      20% Co-Payment will be applicable on re-imbersement claims
                      in Network or outside network.
                    </p>
                  }
                  value_update={updateMasterState}
                  attrName={"NCBSuper"}
                  disable={
                    (calculateAge(
                      FORMAT_DATE_DD_MM_YYYY(ADD_FORM.self_dob.value)
                    ) >= 76 &&
                      calculateAge(
                        FORMAT_DATE_DD_MM_YYYY(ADD_FORM.self_dob.value)
                      ) <= 99) ||
                    (calculateAge(
                      FORMAT_DATE_DD_MM_YYYY(ADD_FORM.spouse_dob.value)
                    ) >= 76 &&
                      calculateAge(
                        FORMAT_DATE_DD_MM_YYYY(ADD_FORM.spouse_dob.value)
                      ) <= 99) ||
                    (ADD_FORM.age.value <= "45" &&
                    CKYC_DETAILS.ncb_tag === "Yes"
                      ? true
                      : false)
                  }
                  desc="You can add additional 50% increase in Sum Insure for every claim free year. Maximum increase allowed is 100% of policy Sum Insure. Hence with both NCB & NCB SUPER you can increase your Policy Sum Insure upto 150% in 5 consecutive claim free years."
                />
                <AddonQuestion
                  addon_amount=""
                  main_class="addonQuestion borderBottom"
                  icon_class="treatments"
                  question_name="Room rent"
                  toggle_status={addonStatus.field_34}
                  sub_details={
                    <p>
                      Room rent will allow 15% Discount on Premium in lieu of
                      making cashless claims in Smart Select Network Hospitals.
                      20% Co-Payment will be applicable on re-imbersement claims
                      in Network or outside network.
                    </p>
                  }
                  value_update={updateMasterState}
                  attrName={"field_34"}
                  desc="By choosing this optional cover, the Room Rent/Room Category shall be limited to 'Single Private Room'"
                />
                <AddonQuestion
                  addon_amount=""
                  main_class="addonQuestion borderBottom"
                  icon_class="bonus"
                  question_name="Smart Select"
                  sub_details={
                    <p>
                      Smart Select will allow 15% Discount on Premium in lieu of
                      making cashless claims in Smart Select Network Hospitals.
                      20% Co-Payment will be applicable on re-imbersement claims
                      in Network or outside network.
                    </p>
                  }
                  toggle_status={addonStatus.SmartSelect}
                  value_update={updateMasterState}
                  attrName={"SmartSelect"}
                  desc="Get 15% discount on premium by restricting treatment on network hospital specially created for smart select optional cover"
                />
                <AddonQuestion
                  addon_amount=""
                  main_class="addonQuestion borderBottom"
                  icon_class="bonus"
                  question_name="Air Ambulance Cover"
                  sub_details={
                    <p>
                      Air Ambulance Cover provides financial coverage for the
                      expenses incurred in availing of an air ambulance in times
                      of need.
                    </p>
                  }
                  toggle_status={addonStatus.AirAmbulanceCover}
                  value_update={updateMasterState}
                  attrName={"AirAmbulanceCover"}
                  desc="The right cure may be miles away but never out of your reach with Air Ambulance Cover. For all those times when you might be recommended a treatment which is not in your city of stay."
                />
                <AddonQuestion
                  addon_amount=""
                  main_class="addonQuestion borderBottom"
                  icon_class="bonus"
                  question_name="Care Shield"
                  sub_details={
                    <p>
                      Care Shield offers various other innovative coverage
                      benefits in addition to your base health plan.
                    </p>
                  }
                  toggle_status={addonStatus.CareShield}
                  value_update={updateMasterState}
                  attrName={"CareShield"}
                  desc="Helps in getting claim of non-payable items, no impact on NCB if the claim is less than 25% of base Suminsured, and guaranteed CPI linked increase in Base SI."
                />

                <AddonQuestion
                  addon_amount=""
                  main_class="addonQuestion borderBottom"
                  icon_class="bonus"
                  question_name="Unlimited Automatic Recharge"
                  toggle_status={addonStatus.field_UAR}
                  value_update={updateMasterState}
                  attrName={"field_UAR"}
                  desc="Unlimited Automatic Recharge is an optional cover and an extension to Automatic Recharge. Almost all the terms and conditions applied to Automatic Recharge also applies to Unlimited Automatic Recharge. Only difference is that here, the Recharge can be availed unlimited times during the policy year"
                  disable={true}
                />
                {(calculateAge(
                  FORMAT_DATE_DD_MM_YYYY(ADD_FORM.self_dob.value)
                ) >= 61 ||
                  calculateAge(
                    FORMAT_DATE_DD_MM_YYYY(ADD_FORM.spouse_dob.value)
                  ) >= 61) && (
                  <AddonQuestion
                    addon_amount=""
                    main_class="addonQuestion borderBottom"
                    icon_class="bonus"
                    question_name="Co-Payment"
                    toggle_status={addonStatus.field_COPAY}
                    value_update={updateMasterState}
                    attrName={"field_COPAY"}
                    desc="Co-payment waiver for senior citizens With this benefit, you may waive the co-payment clause from senior citizen health insurance by paying an extra premium."
                  />
                )}
                <AddonQuestion
                  addon_amount=""
                  main_class="addonQuestion borderBottom"
                  icon_class="bonus"
                  question_name="Ext of Global Coverage"
                  sub_details={
                    <p>
                      It extends the medical coverage against emergency and
                      planned health expenses incurred anywhere. It covers the
                      cost of medical treatments taken within India as well as
                      abroad.
                    </p>
                  }
                  toggle_status={addonStatus.GlobalCoverage}
                  value_update={updateMasterState}
                  addonStatus={addonStatus}
                  attrName={"GlobalCoverage"}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={3}>
              <Grid xs={12}>
                <AddonQuestion
                  addon_amount=""
                  main_class="addonQuestion borderBottom"
                  icon_class="treatments"
                  question_name="NCB"
                  toggle_status={addonStatus.NCBSuper}
                  showAsterisk={true}
                  sub_details={
                    <p>
                      NCB Super will allow 15% Discount on Premium in lieu of
                      making cashless claims in Smart Select Network Hospitals.
                      20% Co-Payment will be applicable on re-imbersement claims
                      in Network or outside network.
                    </p>
                  }
                  value_update={updateMasterState}
                  attrName={"NCBSuper"}
                  disable={
                    (calculateAge(
                      FORMAT_DATE_DD_MM_YYYY(ADD_FORM.self_dob.value)
                    ) >= 76 &&
                      calculateAge(
                        FORMAT_DATE_DD_MM_YYYY(ADD_FORM.self_dob.value)
                      ) <= 99) ||
                    (calculateAge(
                      FORMAT_DATE_DD_MM_YYYY(ADD_FORM.spouse_dob.value)
                    ) >= 76 &&
                      calculateAge(
                        FORMAT_DATE_DD_MM_YYYY(ADD_FORM.spouse_dob.value)
                      ) <= 99) ||
                    (ADD_FORM.age.value <= "45" &&
                    CKYC_DETAILS.ncb_tag === "Yes"
                      ? true
                      : false)
                  }
                  desc="You can add additional 50% increase in Sum Insure for every claim free year. Maximum increase allowed is 100% of policy Sum Insure. Hence with both NCB & NCB SUPER you can increase your Policy Sum Insure upto 150% in 5 consecutive claim free years."
                />
                <AddonQuestion
                  addon_amount=""
                  main_class="addonQuestion borderBottom"
                  icon_class="treatments"
                  question_name="Room rent"
                  toggle_status={addonStatus.field_34}
                  sub_details={
                    <p>
                      Room rent will allow 15% Discount on Premium in lieu of
                      making cashless claims in Smart Select Network Hospitals.
                      20% Co-Payment will be applicable on re-imbersement claims
                      in Network or outside network.
                    </p>
                  }
                  value_update={updateMasterState}
                  attrName={"field_34"}
                  desc="By choosing this optional cover, the Room Rent/Room Category shall be limited to 'Single Private Room'"
                />
                <AddonQuestion
                  addon_amount=""
                  main_class="addonQuestion borderBottom"
                  icon_class="bonus"
                  question_name="Smart Select"
                  sub_details={
                    <p>
                      Smart Select will allow 15% Discount on Premium in lieu of
                      making cashless claims in Smart Select Network Hospitals.
                      20% Co-Payment will be applicable on re-imbersement claims
                      in Network or outside network.
                    </p>
                  }
                  toggle_status={addonStatus.SmartSelect}
                  value_update={updateMasterState}
                  attrName={"SmartSelect"}
                  desc="Get 15% discount on premium by restricting treatment on network hospital specially created for smart select optional cover"
                />
                <AddonQuestion
                  addon_amount=""
                  main_class="addonQuestion borderBottom"
                  icon_class="bonus"
                  question_name="Air Ambulance Cover"
                  sub_details={
                    <p>
                      Air Ambulance Cover provides financial coverage for the
                      expenses incurred in availing of an air ambulance in times
                      of need.
                    </p>
                  }
                  toggle_status={addonStatus.AirAmbulanceCover}
                  value_update={updateMasterState}
                  attrName={"AirAmbulanceCover"}
                  desc="The right cure may be miles away but never out of your reach with Air Ambulance Cover. For all those times when you might be recommended a treatment which is not in your city of stay."
                />
                <AddonQuestion
                  addon_amount=""
                  main_class="addonQuestion borderBottom"
                  icon_class="bonus"
                  question_name="Care Shield"
                  sub_details={
                    <p>
                      Care Shield offers various other innovative coverage
                      benefits in addition to your base health plan.
                    </p>
                  }
                  toggle_status={addonStatus.CareShield}
                  value_update={updateMasterState}
                  attrName={"CareShield"}
                  desc="Helps in getting claim of non-payable items, no impact on NCB if the claim is less than 25% of base Suminsured, and guaranteed CPI linked increase in Base SI."
                />
                <AddonQuestion
                  addon_amount=""
                  main_class="addonQuestion borderBottom"
                  icon_class="bonus"
                  question_name="Ext of Global Coverage"
                  sub_details={
                    <p>
                      It extends the medical coverage against emergency and
                      planned health expenses incurred anywhere. It covers the
                      cost of medical treatments taken within India as well as
                      abroad.
                    </p>
                  }
                  toggle_status={addonStatus.GlobalCoverage}
                  value_update={updateMasterState}
                  addonStatus={addonStatus}
                  attrName={"GlobalCoverage"}
                />
              </Grid>
            </Grid>
          )}
          <MFooter
            textName={PAGE_STATUS === true ? "Update" : "Continue"}
            attrName={"pageStatus"}
            forward={() => {
              validate();
            }}
            value={0}
            backward={() => {
              navigate(
                `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.QOUTE_PAGE}`
              );
            }}
          />
        </Box>
      )}
    </>
  );
}

export default MAddonDetails;
