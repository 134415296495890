import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Outlet } from "react-router-dom";
import LeftSidebar from "../../../Component/HI/Desktop/ProposalComponents/LeftSidebar/LeftSidebar";
import "../../../SCSS/CommonSCSS/ProposalForms.scss";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import Navbar from "../../../Component/Navbar/Navbar";

function HIProposalContainer() {
  const isMobile = useIsMobile();

  return (
    <Box className={"proposalWrapper"}>
      <Navbar />

      <Grid container columnSpacing={3} className="pb-0 pt-0">
        {/* Left Sidebar */}
        {!isMobile && <LeftSidebar class_name="homeIcon" />}
        <Grid xs className="proposalContentSection">
          <Outlet />
        </Grid>
      </Grid>
    </Box>
  );
}

export default HIProposalContainer;
