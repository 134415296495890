import VerifyKYC from "../../../../../Page/Desktop/HI/ProposalPage/GoDigit/VerifyKYC/VerifyKYC";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";

const VerifyKYCContainer = () => {
  const isMobile = useIsMobile();

  return <>{isMobile ? <></> : <VerifyKYC />}</>;
};

export default VerifyKYCContainer;
