import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import RidersBenefits from "../../../../../Page/Desktop/TermFullJourney/HDFC/Proposal/RidersBenefits/RidersBenefits";
import MRidersBenefits from "../../../../../Page/Mobile/TermFullJourney/HDFC/Proposal/RidersBenefits/MRidersBenefits";
import { PROPOSAL_PAGE_SERVICES } from "../../../../../Services/TermFullJourneyServices/ProposalPageServices";
import { TermFullJourneySlice } from "../../../../../Store/Slice_Reducer/TermFullJourney/TermFullJourneySlice";
import { useAppDispatch, useAppSelector } from "../../../../../Store/hooks";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";
import { TDropdown } from "../../../../../types/TermFullJourney/TDropdown";
import { TRidersBenefitsResponse } from "../../../../../types/TermFullJourney/TRidersBenefits";
import { TermSlice } from "../../../../../Store/Slice_Reducer/Term/TermSlice";
import { Box } from "@mui/material";

function RidersBenefitsContainer() {
  const isMobile = useIsMobile();

  const dispatch = useAppDispatch();
  const [tf001Data, setTf001Data] = useState<TDropdown[]>([]);
  const [tf002Data, setTf002Data] = useState<TDropdown[]>([]);
  const [tf003Data, setTf003Data] = useState<TDropdown[]>([]);
  const [tf004Data, setTf004Data] = useState<TDropdown[]>([]);
  const [tf005Data, setTf005Data] = useState<TDropdown[]>([]);

  const { PREMIUM_LOADER, RIDERS_BENEFITS } = useAppSelector(
    (state) => state.TermFullJourney
  );

  const {
    SELECTED_QUOTE_DATA,
    ADD_FORM_RESPONSE,
    ADD_FORM,
    QUOTE_FIELDS_DATA,
  } = useAppSelector((state) => state.Term);

  const [loaderTf001, setLoaderTf001] = useState<boolean>(false);
  const [loaderTf002, setLoaderTf002] = useState<boolean>(false);
  const [loaderTf003, setLoaderTf003] = useState<boolean>(false);
  const [loaderTf004, setLoaderTf004] = useState<boolean>(false);
  const [loaderTf005, setLoaderTf005] = useState<boolean>(false);

  useEffect(() => {
    GET_RIDERS_PREMIUM("");
  }, []);

  useEffect(() => {
    GET_RIDERS_PREMIUM("RIDERREC");
  }, [RIDERS_BENEFITS.local_value]);

  const GET_RIDERS_PREMIUM = (REQUEST_FOR: "" | "RIDERREC") => {
    const onSuccess = (data: any) => {
    dispatch(TermFullJourneySlice.actions.SET_LOADER_STATUS(false))
      setLoaderTf001(false);
      setLoaderTf002(false);
      setLoaderTf003(false);
      setLoaderTf004(false);
      setLoaderTf005(false);
      const error = data.error;
      const message = data.message;
      const response= data.response;
      if (!error) {
        dispatch(
          TermFullJourneySlice.actions.SET_RIDERS_BENEFITS_DATA(response)
        );
        dispatch(
          TermSlice.actions.SET_RIDERS_BENEFITS_DATA(response)
        );
      } else {
        toast.error("There are some technical error. Please contact to admin.");
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
    dispatch(TermFullJourneySlice.actions.SET_LOADER_STATUS(false))
      setLoaderTf001(false);
      setLoaderTf002(false);
      setLoaderTf003(false);
      setLoaderTf004(false);
      setLoaderTf005(false);
      toast.error("There are some technical error. Please contact to admin.");
    };

    const data = {
      product_ids: [`${SELECTED_QUOTE_DATA.productDetails.id}`],
      criticalIllnessSelected: RIDERS_BENEFITS.local_value.tf001Status
        ? "Yes"
        : "No",
      accidentalDeathSelected: RIDERS_BENEFITS.local_value.tf005Status
        ? "Yes"
        : "No",
      cancerCovSelected: RIDERS_BENEFITS.local_value.tf004Status ? "Yes" : "No",
      accidentalDisSelected: RIDERS_BENEFITS.local_value.tf002Status
        ? "Yes"
        : "No",
      criticalIllnessValue: RIDERS_BENEFITS.local_value.tf001Status
        ? RIDERS_BENEFITS.local_value.tf001Value
        : "",
      accidentalDisValue: RIDERS_BENEFITS.local_value.tf002Status
        ? RIDERS_BENEFITS.local_value.tf002Value
        : "",
      accidentalDeathValue: RIDERS_BENEFITS.local_value.tf005Status
        ? RIDERS_BENEFITS.local_value.tf005Value
        : "",
      personalAccSelected: RIDERS_BENEFITS.local_value.tf003Status
        ? "Yes"
        : "No",
      quote_no: ADD_FORM_RESPONSE.quote_no,
      adb: RIDERS_BENEFITS.local_value.BNF001Status ? "Yes" : "No",
      rop: RIDERS_BENEFITS.local_value.BNF002Status ? "Yes" : "No",
      wop: RIDERS_BENEFITS.local_value.BNF003Status ? "Yes" : "No",

      // buy_online_code: SELECTED_QUOTE_DATA.productDetails.buy_online_code,
      // product_id: `${SELECTED_QUOTE_DATA.productDetails.id}`,
      // quote_no: ADD_FORM_RESPONSE.quote_no,
      // age: `${ADD_FORM.age.value}`,
      // city: "",
      // company_code: SELECTED_QUOTE_DATA.companyDetails.company_code,
      // cover_pay_upto: QUOTE_FIELDS_DATA.coverageUpto,
      // cover_upto: QUOTE_FIELDS_DATA.coverageUpto,
      // frequency: `${QUOTE_FIELDS_DATA.payMode}`,
      // gender: ADD_FORM.gender.value,
      // pay_term: `${SELECTED_QUOTE_DATA.premiumDetails.pay_term}`,
      // policy_covers: "",
      // product_code: SELECTED_QUOTE_DATA.productDetails.product_code,
      // product_desc: SELECTED_QUOTE_DATA.productDetails.product_desc,
      // smoker: ADD_FORM.smoke,
      // state: "",
      // sum_assured: SELECTED_QUOTE_DATA.premiumDetails.sum_assured,
      // term: `${SELECTED_QUOTE_DATA.premiumDetails.term}`,
      // name: `${proposerDetails.full_name.value}`,
      // annual_income: `${proposerDetails.annual_income.value.code}`,
      // dob: `${ADD_FORM.age.value}`,
      // request_for: REQUEST_FOR,
      // criticalIllnessSelected: RIDERS_BENEFITS.local_value.tf001Status
      //   ? "Yes"
      //   : "No",
      // accidentalDisSelected: RIDERS_BENEFITS.local_value.tf002Status
      //   ? "Yes"
      //   : "No",
      // personalAccSelected:
      //   SELECTED_QUOTE_DATA.productDetails.product_code === "LTH101"
      //     ? "No"
      //     : RIDERS_BENEFITS.local_value.tf003Status
      //     ? "Yes"
      //     : "No",
      // cancerCovSelected: RIDERS_BENEFITS.local_value.tf004Status ? "Yes" : "No",
      // accidentalDeaSelected:
      //   SELECTED_QUOTE_DATA.productDetails.product_code === "LTH101"
      //     ? "No"
      //     : RIDERS_BENEFITS.local_value.tf005Status
      //     ? "Yes"
      //     : "No",
      // criticalIllnessValue: RIDERS_BENEFITS.local_value.tf001Status
      //   ? RIDERS_BENEFITS.local_value.tf001Value
      //   : RIDERS_BENEFITS.local_value.tf004Value,
      // accidentalDisValue: RIDERS_BENEFITS.local_value.tf002Value,
      // accidentalDeathValue:
      //   SELECTED_QUOTE_DATA.productDetails.product_code === "LTH101"
      //     ? ""
      //     : RIDERS_BENEFITS.local_value.tf003Status
      //     ? RIDERS_BENEFITS.local_value.tf003Value
      //     : RIDERS_BENEFITS.local_value.tf005Value,
      // adb: RIDERS_BENEFITS.local_value.BNF001Status ? "Yes" : "No",
      // rop: RIDERS_BENEFITS.local_value.BNF002Status ? "Yes" : "No",
      // wop: RIDERS_BENEFITS.local_value.BNF003Status ? "Yes" : "No",
    };
    dispatch(TermFullJourneySlice.actions.SET_LOADER_STATUS(true))
    PROPOSAL_PAGE_SERVICES.hdfcCreatePaymentLink(onSuccess, onError, data);
  };

  useEffect(() => {
    const start_tf001 = 5000;
    const end_tf001 = 5000000;

    let data_tf001: TDropdown[] = [];
    for (let i = start_tf001; i <= end_tf001; i += 5000) {
      data_tf001.push({ label: `${i}`, value: `${i}` });
    }

    setTf001Data(data_tf001);

    const start_tf002 = 100000;
    const end_tf002 = 20000000;

    let data_tf002: TDropdown[] = [];
    for (let i = start_tf002; i <= end_tf002; i += 100000) {
      data_tf002.push({ label: `${i}`, value: `${i}` });
    }

    setTf002Data(data_tf002);

    const start_tf003 = 5000;
    const end_tf003 = 5000000;

    let data_tf003: TDropdown[] = [];
    for (let i = start_tf003; i <= end_tf003; i += 5000) {
      data_tf003.push({ value: `${i}`, label: `${i}` });
    }

    setTf003Data(data_tf003);

    const start_tf004 = 5000;
    const end_tf004 = 5000000;

    let data_tf004: TDropdown[] = [];
    for (let i = start_tf004; i <= end_tf004; i += 5000) {
      data_tf004.push({ label: `${i}`, value: `${i}` });
    }

    setTf004Data(data_tf004);

    const start_tf005 = 5000;
    const end_tf005 = 10000000;

    let data_tf005: TDropdown[] = [];
    for (let i = start_tf005; i <= end_tf005; i += 5000) {
      data_tf005.push({ label: `${i}`, value: `${i}` });
    }

    setTf005Data(data_tf005);
  }, [RIDERS_BENEFITS]);

  useEffect(() => {
    setLoaderTf001(true);
  }, [
    RIDERS_BENEFITS.local_value.tf001Status,
    RIDERS_BENEFITS.local_value.tf001Value,
  ]);

  useEffect(() => {
    setLoaderTf002(true);
  }, [
    RIDERS_BENEFITS.local_value.tf002Status,
    RIDERS_BENEFITS.local_value.tf002Value,
  ]);

  useEffect(() => {
    setLoaderTf003(true);
  }, [
    RIDERS_BENEFITS.local_value.tf003Status,
    RIDERS_BENEFITS.local_value.tf003Value,
  ]);

  useEffect(() => {
    setLoaderTf004(true);
  }, [
    RIDERS_BENEFITS.local_value.tf004Status,
    RIDERS_BENEFITS.local_value.tf004Value,
  ]);

  useEffect(() => {
    setLoaderTf005(true);
  }, [
    RIDERS_BENEFITS.local_value.tf005Status,
    RIDERS_BENEFITS.local_value.tf005Value,
  ]);
  return (
    <>
     {PREMIUM_LOADER ? (
        <Box
          position="fixed"
          height="100%"
          width="100%"
          zIndex="991"
          top="0px"
          left="0px"
        />
      ) : null}
      {isMobile ? (
        <MRidersBenefits
          loaderTf001={loaderTf001}
          loaderTf002={loaderTf002}
          loaderTf003={loaderTf003}
          loaderTf004={loaderTf004}
          loaderTf005={loaderTf005}
          tf001Data={tf001Data}
          tf002Data={tf002Data}
          tf003Data={tf003Data}
          tf004Data={tf004Data}
          tf005Data={tf005Data}
        />
      ) : (
        <RidersBenefits
          loaderTf001={loaderTf001}
          loaderTf002={loaderTf002}
          loaderTf003={loaderTf003}
          loaderTf004={loaderTf004}
          loaderTf005={loaderTf005}
          tf001Data={tf001Data}
          tf002Data={tf002Data}
          tf003Data={tf003Data}
          tf004Data={tf004Data}
          tf005Data={tf005Data}
        />
      )}
    </>
  );
}

export default RidersBenefitsContainer;
