import { Route, Routes } from "react-router-dom";
import AddonDetailsContainer from "../../../../../Container/Travel/CARE/AddonDetailsContainer/AddonDetailsContainer";
import MedicalDetailsContainer from "../../../../../Container/Travel/CARE/MedicalDetailsContainer/MedicalDetailsContainer";
import { CKYC_ROUTES } from "../../../../Path/CKYCRoutes";
import { TRAVEL_ROUTE } from "../../../../Path/TravelRoutes";
import TravelProposalContainer from "../../TravelProposalContainer";
import CareCKYCContainer from "../../../../../Container/CKYC/CARE/CareCKYCContainer";

export const CARE_ROUTES = () => {
  return (
    <Routes>
      <Route
        path={TRAVEL_ROUTE.PROPOSAL_PAGE}
        element={<TravelProposalContainer />}
      >
        <Route path={CKYC_ROUTES.CKYC} element={<CareCKYCContainer />} />
        {/* <Route
          path={CKYC_ROUTES.MANUAL_CKYC}
          element={<CareManualKYCContainer />}
        /> */}
        <Route
          path={TRAVEL_ROUTE.ADDON_DETAILS}
          element={<AddonDetailsContainer />}
        />
        <Route
          path={TRAVEL_ROUTE.MEDICAL_DETAILS}
          element={<MedicalDetailsContainer />}
        />
      </Route>
    </Routes>
  );
};
