import HouseholdItemsBreakup from "../../../../../Page/Desktop/HI/ProposalPage/GoDigit/HouseHoldBreakup/HouseHoldBreakup";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";

const HouseHoldBreakupContainer = () => {
  const isMobile = useIsMobile();

  return <>{isMobile ? <></> : <HouseholdItemsBreakup />}</>;
};

export default HouseHoldBreakupContainer;
