import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Navbar from "../Navbar/Navbar";
import "./VehicleDetailsAreIncorrect.scss";
import { Link } from "react-router-dom";
import CustomButton from "../CustomButton/CustomButton";

const VehicleDetailsAreIncorrect = () => {
  const redirectionUrl =  window.location.pathname.includes("/car-insurance") ? "/car-insurance" : "/two-wheeler-insurance"

  return (
    <Box className="icvWrapper">
      {/* Navbar */}
      <Navbar />

      <Grid container spacing={3} marginTop={"100px"} alignItems={"center"}>
        <Grid xs={7}>
          <h2 className="mb-4">Oops! Vehicle Details are incorrect</h2>
          <p className="mb-4">
           Don't worry we are here to help! Head Back to home page.
          </p>
          
            <CustomButton
                text_name={"Go back to Home"}
                class_name="primaryBtn"
                size="large"
                onClickFunction={() => {
                      window.location.href =  redirectionUrl
                }} 
              />

        </Grid>
        <Grid xs={5}  textAlign="center">
          <img src="../images/oops_not found.jpg" alt="" style={{width:'100%'}} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default VehicleDetailsAreIncorrect