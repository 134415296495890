import { createSlice } from "@reduxjs/toolkit";
import { TRAVEL_REDUCERS } from "./TravelReducer";
import { TTravelSlice } from "../../../types/Travel/TTravelSlice";
import { CARE_QUESTION_INITIAL_VALUE } from "./MedicalInitialValue/Care";

const initialState: TTravelSlice = {
  DROPDOWN_DATA: {
    CountryList: [],
    SUM_INSURED: [
      {
        label: "1,00,000",
        value: "100000",
      },
      {
        label: "2,00,000",
        value: "200000",
      },
    ],
    OCCUPATION_DATA: [],
  },
  ADD_FORM: {
    frequency: { value: "", warning: false },
    travelCountries: { value: [], warning: false },
    travelStartDate: { value: "", warning: false },
    travelEndDate: { value: "", warning: false },
    plan_type: { value: "", warning: false },
    travelDays: { value: "", warning: false },
    travelName: { value: "", warning: false },
    travelEmail: { value: "", warning: false },
    travelMobile: { value: "", warning: false },
    travelAges: [],
    memberDetails: {
      adult1_age: { value: "", warning: false },
      adult2_age: { value: "", warning: false },
      child1_age: { value: "", warning: false },
      child2_age: { value: "", warning: false },
      child3_age: { value: "", warning: false },
      child4_age: { value: "", warning: false },
    },
    travel_ped: { value: "", warning: false },
    sum_insured: { value: "", warning: false },
    spouse_status: false,
    mobile: { value: "", warning: false },
    members: { value: "", warning: false },
  },
  ADD_FORM_RESPONSE: {
    quote_no: "",
    utm_medium: "",
    utm_source: "",
  },
  REDIRECTED: false,
  SELECTED_QUOTE_DATA: {
    logo: "",
    companyCode: "",
    productID: "",
    sumInsured: "",
    loader: false,
    quote_details: {
      productDetails: {
        sum_insured: 0,
        id: 0,
        product_code: "",
        product_name: "",
        product_desc: "",
        buy_online_code: "",
      },
      premiumDetails: {
        id: 0,
        quote_no: "",
        insurance_type: "",
        product_id: 0,
        product_code: "",
        company_code: "",
        sum_insured: "",
        premium: "",
        prev_premium: "",
        request: null,
        created: "",
        updated: "",
      },
      companyDetails: {
        company_code: "",
        company_name: "",
        company_address: "",
        logo: "",
      },
    },
  },
  INSURED_MEMBER_DETAILS: {
    NOMINEE__DETAILS: {
      full_name: { value: "", warning: false },
      dob: { value: "", warning: false },
      relationship: { value: "", warning: false },
      insured_member: true,
      prosper_details: true,
      localstate: false,
    },
    PROPOSER_DETAILS: {
      IS_PROPOSER_INSURED: true,
      name: { value: "", warning: false },
      dob: { value: "", warning: false },
      mobile: { value: "", warning: false },
      emergencyPhone: { value: "", warning: false },
      email: { value: "", warning: false },
      aadhar: { value: "", warning: false },
      gender: { value: "", warning: false },
      maritalStatus: { value: "", warning: false },
      weight: { value: "", warning: false },
      heightFeet: { value: "", warning: false },
      heightInches: { value: "", warning: false },
      proposer_name: { value: "", warning: false },
      relationship: { value: "", warning: false },
      pan_card: { value: "", warning: false },
      qualification: { value: "", warning: false },
      occupation: { value: "", warning: false },
      passportNumber: { value: "", warning: false },
    },
    ADULT_ONE_DETAILS: {
      name: { value: "", warning: false },
      occupation: { value: "", warning: false },
      dob: { value: "", warning: false },
      mobile: { value: "", warning: false },
      emergencyPhone: { value: "", warning: false },
      email: { value: "", warning: false },
      aadhar: { value: "", warning: false },
      gender: { value: "", warning: false },
      maritalStatus: { value: "", warning: false },
      weight: { value: "", warning: false },
      heightFeet: { value: "", warning: false },
      heightInches: { value: "", warning: false },
      proposer_name: { value: "", warning: false },
      relationship: { value: "", warning: false },
      pan_card: { value: "", warning: false },
      qualification: { value: "", warning: false },
      passportNumber: { value: "", warning: false },
    },
    ADULT_TWO_DETAILS: {
      name: { value: "", warning: false },
      dob: { value: null, warning: false },
      relationship: { value: "", warning: false },
      occupation: { value: "", warning: false },
      spouse_passport: { value: "", warning: false },
      weight: { value: "", warning: false },
      heightFeet: { value: "", warning: false },
      heightInches: { value: "", warning: false },
    },
    CHILD_ONE_DETAILS: {
      name: { value: "", warning: false },
      dob: { value: null, warning: false },
      relationship: { value: "", warning: false },
      passport: { value: "", warning: false },
      occupation: { value: "", warning: false },
      weight: { value: "", warning: false },
      heightFeet: { value: "", warning: false },
      heightInches: { value: "", warning: false },
    },
    CHILD_TWO_DETAILS: {
      name: { value: "", warning: false },
      dob: { value: null, warning: false },
      relationship: { value: "", warning: false },
      passport: { value: "", warning: false },
      occupation: { value: "", warning: false },
      weight: { value: "", warning: false },
      heightFeet: { value: "", warning: false },
      heightInches: { value: "", warning: false },
    },
    CHILD_THREE_DETAILS: {
      name: { value: "", warning: false },
      dob: { value: null, warning: false },
      relationship: { value: "", warning: false },
      passport: { value: "", warning: false },
      occupation: { value: "", warning: false },
      weight: { value: "", warning: false },
      heightFeet: { value: "", warning: false },
      heightInches: { value: "", warning: false },
    },
    CHILD_FOUR_DETAILS: {
      name: { value: "", warning: false },
      dob: { value: null, warning: false },
      relationship: { value: "", warning: false },
      passport: { value: "", warning: false },
      occupation: { value: "", warning: false },
      weight: { value: "", warning: false },
      heightFeet: { value: "", warning: false },
      heightInches: { value: "", warning: false },
    },
  },
  ADDRESS_DETAILS: {
    pincode: { value: "", warning: false },
    city: "",
    state: "",
    addressLineOne: { value: "", warning: false },
    addressLineTwo: { value: "", warning: false },
    isMailingAddressSame: true,
    mailingPinCode: { value: "", warning: false },
    mailingCity: "",
    mailingState: "",
    mailingAddressLineOne: { value: "", warning: false },
    mailingAddressLineTwo: { value: "", warning: false },
  },
  QUOTE_LOADER: false,
  PAGE_STATUS: false,
  NOMINEE_RELATIONSHIP_STATUS: "OTHER",
  ADDON_DETAILS: {
    field_EHA_emergency_hotel: false,
    field_LTC_life_threatening: true,
    field_RVF_refund_of_visa: false,
    field_ASC_adventure_sports_cover: false,
    field_SR_staff_replacement: false,
    field_SC_sports_cover: false,
  },
  MEDICAL_QUESTION_DATA_CARE: CARE_QUESTION_INITIAL_VALUE(),
  PAYMENT_DATA: {
    quoteId: "",
    proposal_no: "",
    QuoteIdRefNumber: "",
    finalpremium: 0,
    payment_link: "",
    err_description: "",
  },
};

export const TravelSlice = createSlice({
  name: "Travel",
  initialState,
  reducers: TRAVEL_REDUCERS,
});

export default TravelSlice.reducer;
