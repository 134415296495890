import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AddonQuestion from "../../../../Component/AddonQuestion/AddonQuestion";
import SelectDropdown from "../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";
import Footer from "../../../../Component/Footer/Footer";
import { HEALTH_ROUTE } from "../../../../Router/Path/HealthRoutes";
import { useAppSelector } from "../../../../Store/hooks";
import { TCareAddon } from "../../../../types/Health/TCareAddon";
import { formatToCurrency } from "../../../../SupportingFiles/HelpingFunction";

function AddonDetailsOptimaSecure({
  addonStatus,
  updateMasterState,
  validate,
  PAGE_STATUS,
  isLoading,
}: {
  addonStatus: TCareAddon;
  PAGE_STATUS: boolean;
  updateMasterState: Function;
  validate: Function;
  isLoading: any;
}) {
  const navigate = useNavigate();
  const { SELECTED_QUOTE_DATA, isDeduction_value } = useAppSelector(
    (state) => state.Health
  );
  const { CKYC_DETAILS } = useAppSelector((state) => state.CKYC);

  const [criticalSum_data, setCriticalSum_data] = useState<any[]>([]);

  let DEDUCTION_DATA = [
    { value: "25000", label: "25000" },
    { value: "50000", label: "50000" },
    { value: "100000", label: "100000" },
    { value: "200000", label: "200000" },
    { value: "300000", label: "300000" },
    Number(SELECTED_QUOTE_DATA.premiumDetails.sum_insured) >= 2500000
      ? {
          value: "500000",
          label: "500000",
        }
      : "",
  ];
  const setDeductionData = () => {
    if (SELECTED_QUOTE_DATA.premiumDetails.sum_insured >= 2500000) {
      return [
        { value: "25000", label: "25000" },
        { value: "50000", label: "50000" },
        { value: "100000", label: "100000" },
        { value: "200000", label: "200000" },
        { value: "300000", label: "300000" },
        { value: "500000", label: "500000" },
      ];
    } else {
      return [
        { value: "25000", label: "25000" },
        { value: "50000", label: "50000" },
        { value: "100000", label: "100000" },
        { value: "200000", label: "200000" },
        { value: "300000", label: "300000" },
      ];
    }
  };

  const PlanTypeOptions = [
    {
      label: "9  CriticalIllnesses covered",
      value: "PLAN1",
    },
    {
      label: "12 CriticalIllnesses covered",
      value: "PLAN2",
    },
    {
      label: "15 CriticalIllnesses covered",
      value: "PLAN3",
    },
    {
      label: "18 CriticalIllnesses covered",
      value: "PLAN4",
    },
    {
      label: "25 CriticalIllnesses covered",
      value: "PLAN5",
    },
    {
      label: "40 CriticalIllnesses covered",
      value: "PLAN6",
    },
    {
      label: "51 CriticalIllnesses coveredb",
      value: "PLAN7",
    },
  ];

  useEffect(() => {
    let newArray = [];
    for (
      let incomeValue = 100000;
      incomeValue <= parseInt(SELECTED_QUOTE_DATA.sumInsured);
      incomeValue += 100000
    ) {
      newArray.push({
        label: formatToCurrency(incomeValue),
        value: `${incomeValue}`,
      });
      setCriticalSum_data(newArray);
    }
  }, [SELECTED_QUOTE_DATA]);

  return (
    <>
      <Box>
        <h5 className="sectionTitle">Addon Details</h5>
        <Grid xs={12}>
          <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="overseas travel"
            toggle_status={addonStatus.overseas_travel}
            value_update={updateMasterState}
            attrName={"overseas_travel"}
          />
          <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="IPA Rider"
            toggle_status={addonStatus.ipa}
            value_update={updateMasterState}
            attrName={"ipa"}
          />
          {CKYC_DETAILS.kycCity.toLowerCase() === "mumbai".toLowerCase() && (
            <AddonQuestion
              addon_amount=""
              main_class="addonQuestion borderBottom"
              icon_class="bonus"
              question_name="optima well"
              toggle_status={addonStatus.optima_well}
              value_update={updateMasterState}
              attrName={"optima_well"}
            />
          )}
          <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Is Unlimited Restore"
            toggle_status={addonStatus.IsUnlimitedRestore}
            value_update={updateMasterState}
            attrName={"IsUnlimitedRestore"}
          />
          <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Critical Illness"
            toggle_status={addonStatus.Iscritical_Illness}
            value_update={updateMasterState}
            attrName={"Iscritical_Illness"}
            sub_details={
              <Box>
                <Grid container spacing={3}>
                  <Grid xs={4}>
                    <SelectDropdown
                      class_name="inputField"
                      title="Sum Insured"
                      value={addonStatus.Iscritical_Illness_sum_insured}
                      value_update={updateMasterState}
                      attrName={"Iscritical_Illness_sum_insured"}
                      data={criticalSum_data}
                    />
                  </Grid>
                  <Grid xs={4}>
                    <SelectDropdown
                      class_name="inputField"
                      title="Plan Type"
                      value={
                        addonStatus.Insured_CriticalIllnessAddonCover_PlanType
                      }
                      value_update={updateMasterState}
                      attrName={"Insured_CriticalIllnessAddonCover_PlanType"}
                      data={PlanTypeOptions}
                    />
                  </Grid>
                </Grid>
              </Box>
            }
          />
          <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="health Hospital Cash Benefit "
            toggle_status={addonStatus.health_hospital_cash_benefit}
            value_update={updateMasterState}
            attrName={"health_hospital_cash_benefit"}
            sub_details={
              <Box maxWidth={"200px"}>
                <SelectDropdown
                  title="Sum Insured"
                  value={addonStatus.health_hospital_cash_benefit_sum_insured}
                  value_update={updateMasterState}
                  attrName={"sub_health_hospital_cash_benefit"}
                  data={[
                    { value: "500", label: "500" },
                    { value: "1000", label: "1000" },
                    { value: "1500", label: "1500" },
                    { value: "2000", label: "2000" },
                  ]}
                />
              </Box>
            }
          />
          <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Global Rider"
            toggle_status={
              !addonStatus.health_hospital_cash_benefit
                ? addonStatus.health_hospital_cash_benefit
                : addonStatus.isGlobalRider
            }
            value_update={updateMasterState}
            attrName={"isGlobalRider"}
            disable={!addonStatus.health_hospital_cash_benefit}
          />
        </Grid>
        <Grid xs={6}>
          <SelectDropdown
            class_name=" inputField mb-5"
            title={"Deductibles"}
            attrName={"deduction"}
            value={isDeduction_value}
            value_update={updateMasterState}
            // data={DROPDOWN_DATA.DEDUCTION_DATA}
            data={setDeductionData()}
            warn_status={false}
            error_message={"Enter description"}
          />
        </Grid>
        <Footer
          textName={PAGE_STATUS === true ? "Update" : "Continue"}
          attrName={"pageStatus"}
          forward={() => {
            validate();
          }}
          value={0}
          backward={() => {
            navigate(
              `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.QOUTE_PAGE}`
            );
          }}
        />
      </Box>
    </>
  );
}

export default AddonDetailsOptimaSecure;
