import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { toast } from "react-toastify";
import SelectDropdown from "../../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";
import TermFooter from "../../../../../Component/Term/TermFooter/TermFooter";
import TermRiderQuestion from "../../../../../Component/Term/TermRiderQuestion/TermRiderQuestion";
import "../../../../../SCSS/CommonSCSS/ProposalForms.scss";
import { TERM_SERVICES } from "../../../../../Services/Term/TermServices";
import { useAppDispatch, useAppSelector } from "../../../../../Store/hooks";
import { TermSlice } from "../../../../../Store/Slice_Reducer/Term/TermSlice";
import { isEmpty } from "../../../../../SupportingFiles/HelpingFunction";
import { TDropdown } from "../../../../../types/Common/TDropdown";

function RidersBenefits({
  loaderTf001,
  loaderTf002,
  loaderTf003,
  loaderTf004,
  loaderTf005,
  tf001Data,
  tf002Data,
  tf003Data,
  tf004Data,
  tf005Data,
  loader
}: {
  loaderTf001: boolean;
  loaderTf002: boolean;
  loaderTf003: boolean;
  loaderTf004: boolean;
  loaderTf005: boolean;
  tf001Data: TDropdown[];
  tf002Data: TDropdown[];
  tf003Data: TDropdown[];
  tf004Data: TDropdown[];
  tf005Data: TDropdown[];
    loader: boolean;
}) {
  const dispatch = useAppDispatch();
  const { RIDERS_BENEFITS, SELECTED_QUOTE_DATA, ADD_FORM_RESPONSE } =
    useAppSelector((state) => state.Term);

  return (
    <Box>
      <h5 className="sectionTitle">Addon Details</h5>
      <Grid container spacing={3}>
        <Grid xs={12}>
          {SELECTED_QUOTE_DATA.productDetails.product_code ===
          "LTH101" ? null : (
            <TermRiderQuestion
              main_class="mAddonQuestion"
              icon_class="bonus"
              loader={loaderTf003}
              addon_amount={`${RIDERS_BENEFITS.riders_benefits_response?.riders?.TF003?.premium}`}
              question_name="Personal Accident Cover"
              toggle_status={RIDERS_BENEFITS.local_value.tf003Status}
              value_update={(a: any, v: any) => {
                dispatch(
                  TermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT({
                    updates: [
                      {
                        key: "tf003Status",
                        value: v,
                      },
                      {
                        key: "tf005Status",
                        value: false,
                      },
                    ],
                  })
                );
              }}
              sub_details={
                <Box sx={{ maxWidth: "250px", paddingBottom: "16px" }}>
                  <SelectDropdown
                    class_name="inputField"
                    attrName={""}
                    title="Select Value"
                    value={RIDERS_BENEFITS.local_value.tf003Value}
                    value_update={(a: any, v: any) => {
                      dispatch(
                        TermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT(
                          {
                            updates: [
                              {
                                key: "tf003Value",
                                value: v,
                              },
                            ],
                          }
                        )
                      );
                    }}
                    data={tf003Data}
                    warn_status={false}
                  />
                </Box>
              }
            />
          )}
          <TermRiderQuestion
            icon_class="bonus"
            loader={loaderTf001}
            addon_amount={`${RIDERS_BENEFITS?.riders_benefits_response?.riders?.TF001?.premium}`}
            question_name="60 Critical Illness Cover"
            toggle_status={RIDERS_BENEFITS.local_value.tf001Status}
            value_update={(a: any, v: any) => {
              dispatch(
                TermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT({
                  updates: [
                    {
                      key: "tf001Status",
                      value: v,
                    },
                    {
                      key: "tf004Status",
                      value: false,
                    },
                  ],
                })
              );
            }}
            sub_details={
              <Box sx={{ maxWidth: "250px", paddingBottom: "16px" }}>
                <SelectDropdown
                  class_name="inputField"
                  attrName={""}
                  title="Select Value"
                  value={RIDERS_BENEFITS.local_value.tf001Value}
                  value_update={(a: any, v: any) => {
                    dispatch(
                      TermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT({
                        updates: [
                          {
                            key: "tf001Value",
                            value: v,
                          },
                        ],
                      })
                    );
                  }}
                  data={tf001Data}
                  warn_status={false}
                />
              </Box>
            }
          />
          <TermRiderQuestion
            icon_class="bonus"
            loader={loaderTf002}
            addon_amount={`${RIDERS_BENEFITS.riders_benefits_response?.riders?.TF002?.premium}`}
            question_name="Accidental Disability Cover"
            sub_details={
              <Box sx={{ maxWidth: "250px", paddingBottom: "16px" }}>
                <SelectDropdown
                  class_name="inputField"
                  attrName={""}
                  title="Select Value"
                  value={RIDERS_BENEFITS.local_value.tf002Value}
                  value_update={(a: any, v: any) => {
                    dispatch(
                      TermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT({
                        updates: [
                          {
                            key: "tf002Value",
                            value: v,
                          },
                        ],
                      })
                    );
                  }}
                  data={tf002Data}
                  warn_status={false}
                />
              </Box>
            }
            toggle_status={RIDERS_BENEFITS.local_value.tf002Status}
            value_update={(a: any, v: any) => {
              dispatch(
                TermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT({
                  updates: [
                    {
                      key: "tf002Status",
                      value: v,
                    },
                  ],
                })
              );
            }}
          />
          <TermRiderQuestion
            icon_class="bonus"
            loader={loaderTf004}
            addon_amount={`${RIDERS_BENEFITS.riders_benefits_response?.riders?.TF001?.premium}`}
            question_name="Cancer Cover"
            sub_details={
              <Box sx={{ maxWidth: "250px", paddingBottom: "16px" }}>
                <SelectDropdown
                  class_name="inputField"
                  attrName={""}
                  title="Select Value"
                  value={RIDERS_BENEFITS.local_value.tf004Value}
                  value_update={(a: any, v: any) => {
                    dispatch(
                      TermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT({
                        updates: [
                          {
                            key: "tf004Value",
                            value: v,
                          },
                        ],
                      })
                    );
                  }}
                  data={tf004Data}
                  warn_status={false}
                />
              </Box>
            }
            toggle_status={RIDERS_BENEFITS.local_value.tf004Status}
            value_update={(a: any, v: any) => {
              dispatch(
                TermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT({
                  updates: [
                    {
                      key: "tf001Status",
                      value: false,
                    },
                    {
                      key: "tf004Status",
                      value: v,
                    },
                  ],
                })
              );
            }}
          />
          {SELECTED_QUOTE_DATA.productDetails.product_code ===
          "LTH101" ? null : (
            <TermRiderQuestion
              icon_class="bonus"
              loader={loaderTf005}
              addon_amount={`${RIDERS_BENEFITS.riders_benefits_response.riders?.TF003?.premium}`}
              question_name="Accidental Death Cover"
              sub_details={
                <Box sx={{ maxWidth: "250px", paddingBottom: "16px" }}>
                  <SelectDropdown
                    class_name="inputField"
                    attrName={""}
                    title="Select Value"
                    value={RIDERS_BENEFITS.local_value.tf005Value}
                    value_update={(a: any, v: any) => {
                      dispatch(
                        TermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT(
                          {
                            updates: [
                              {
                                key: "tf005Value",
                                value: v,
                              },
                            ],
                          }
                        )
                      );
                    }}
                    data={tf005Data}
                    warn_status={false}
                  />
                </Box>
              }
              toggle_status={RIDERS_BENEFITS.local_value.tf005Status}
              value_update={(a: any, v: any) => {
                dispatch(
                  TermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT({
                    updates: [
                      {
                        key: "tf003Status",
                        value: false,
                      },
                      {
                        key: "tf005Status",
                        value: v,
                      },
                    ],
                  })
                );
              }}
            />
          )}
          <h5 className="mt-10 pl-2">Benefits:</h5>
          <TermRiderQuestion
            icon_class="bonus"
            show_premium={false}
            question_name="Death Benefits as Instalment Option"
            toggle_status={RIDERS_BENEFITS.local_value.BNF001Status}
            value_update={(a: any, v: any) => {
              dispatch(
                TermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT({
                  updates: [
                    {
                      key: "BNF001Status",
                      value: v,
                    },
                  ],
                })
              );
            }}
          />
          <TermRiderQuestion
            icon_class="bonus"
            show_premium={false}
            question_name="Return of Premium"
            toggle_status={RIDERS_BENEFITS.local_value.BNF002Status}
            value_update={(a: any, v: any) => {
              dispatch(
                TermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT({
                  updates: [
                    {
                      key: "BNF002Status",
                      value: v,
                    },
                  ],
                })
              );
            }}
          />
          <TermRiderQuestion
            icon_class="bonus"
            show_premium={false}
            question_name="Waiver of Premium on 60 Critical Illness"
            toggle_status={RIDERS_BENEFITS.local_value.BNF003Status}
            value_update={(a: any, v: any) => {
              dispatch(
                TermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT({
                  updates: [
                    {
                      key: "BNF003Status",
                      value: v,
                    },
                  ],
                })
              );
            }}
          />
        </Grid>
      </Grid>
      <TermFooter
        loader={loader}
        forward={() => {
          const toastId = toast.loading("Please wait...");
          let params = {
            buy_action_url: SELECTED_QUOTE_DATA.productDetails.buy_online_code,
            quote_no: btoa(ADD_FORM_RESPONSE.quote_no),
            section: "add_ons_details",
            details: {},
          };

          const onSuccess = (res: any) => {
            toast.dismiss(toastId);
            window.location.href =
              RIDERS_BENEFITS.riders_benefits_response.redirectionUrl;
          };
          const onError = () => {};
          TERM_SERVICES.UPDATE_PROPOSER_DETAILS(onSuccess, onError, params);
        }}
        disableButton={isEmpty(
          RIDERS_BENEFITS?.riders_benefits_response?.finalPremium
        )}
        
      />
    </Box>
  );
}

export default RidersBenefits;
