import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../Store/hooks";
import { CarSlice } from "../../Store/Slice_Reducer/Car/CarSlice";
import { TWSlice } from "../../Store/Slice_Reducer/TwoWheeler/TWSlice";
import {
  FORMAT_DATE_DD_MM_YYYY,
  isEmpty,
} from "../../SupportingFiles/HelpingFunction";
import { CLIENTS } from "../../URLCollection/Clients";
import {
  pushMotorDataLayer,
  sendEventToNativeApp,
} from "../../utils/analytics";
interface Props {
  title: string;
  url?: string;
  isMailingAddresssame?: string;
  mailingDetailView?: any;
  preViewData: Array<{
    label: string;
    label_value: any;
  }>;
}
const MPreviewDetailBox: React.FC<Props> = ({
  title,
  preViewData,
  url,
  isMailingAddresssame,
  mailingDetailView,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { TW, Car } = useAppSelector((state) => state);
  const { ADDRESS_DETAILS } = useAppSelector((state) => state.Car);

  const [param, setParam] = React.useState("");
  React.useEffect(() => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const payment = params.get("cus_type");
    setParam(payment || "");
  }, []);

  console.log(">>>", param);
  const showShareLink =
    (!isEmpty(Car.ADD_FORM_RESPONSE.utm_medium) ||
      !isEmpty(TW.ADD_FORM_RESPONSE.utm_medium)) &&
    isEmpty(param);
  return (
    <Box className="previewBox">
      {preViewData?.length !== 0 && Array.isArray(preViewData) ? (
        <Grid container rowSpacing={0} columnSpacing={2}>
          <Grid xs={8}>
            <p
              className="previewBoxTitle"
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </Grid>
          <Grid xs={4} textAlign="right">
            <Link
              className="previewBoxEdit"
              onClick={() => {
                if (window.location.hostname === CLIENTS.JIO) {
                  pushMotorDataLayer(
                    "jioinsure_flow_details",
                    "review",
                    "edit",
                    "car",
                    Car.REDIRECTED ? "JFS app" : "Insure website"
                  );
                  const eventProps = {
                    action: "review",
                    click: "edit",
                    type: "car",
                    EntrySource: Car.REDIRECTED ? "JFS app" : "Insure website",
                  };
                  sendEventToNativeApp("jioinsure_flow_details", eventProps);
                }
                navigate(`${url}`);
                dispatch(TWSlice.actions.CHANGE_PAGE_STATUS(true));
                dispatch(CarSlice.actions.CHANGE_PAGE_STATUS(true));
              }}
            >
              Edit
            </Link>
          </Grid>
          <Grid xs={12}>
            <ul>
              {Array.isArray(preViewData) &&
                preViewData.length > 0 &&
                preViewData.map((data, index) => (
                  <>
                    <li>
                      <p>
                        {data.label}:{" "}
                        <span>
                          {data.label === "DOB"
                            ? FORMAT_DATE_DD_MM_YYYY(data?.label_value)
                            : data.label === "Weight"
                            ? `${data.label_value}kg`
                            : data?.label_value}
                        </span>
                      </p>
                      <div></div>
                    </li>
                    {TW.ADDRESS_DETAILS.isMailingAddressSame &&
                    Car.ADDRESS_DETAILS.isMailingAddressSame &&
                    title === "Address Details" &&
                    preViewData.length - 1 === index ? (
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        marginTop={"4px"}
                        border={"1px dashed #ccc"}
                        borderRadius={"8px"}
                        padding={"4px"}
                      >
                        <TaskAltIcon
                          sx={{
                            color: "#00785A",
                            marginRight: "3px",
                            height: "16px",
                          }}
                        />
                        <p style={{ fontSize: "12px", color: "#00785A" }}>
                          Mailing / Correspondence address is same as permanent
                          address.
                        </p>
                      </Box>
                    ) : null}
                  </>
                ))}
            </ul>
            {(!TW.ADDRESS_DETAILS.isMailingAddressSame ||
              !Car.ADDRESS_DETAILS.isMailingAddressSame) &&
            title === "Address Details" ? (
              <>
                <p
                  style={{
                    fontSize: "13px",
                    fontWeight: 600,
                    textTransform: "uppercase",
                    marginBottom: "8px",
                    marginTop: "8px",
                  }}
                >
                  {" "}
                  Mailing Address
                </p>
                <ul>
                  {mailingDetailView?.map((data: any) => (
                    <li>
                      <p>
                        {data.label}:{""}
                        <span>{data?.label_value}</span>
                      </p>
                    </li>
                  ))}
                </ul>
              </>
            ) : null}
          </Grid>
        </Grid>
      ) : null}
    </Box>
  );
};

export default MPreviewDetailBox;
