import * as React from "react";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../../Store/hooks";
import { TERM_FULL_JOURNEY_ROUTES } from "../../../../../../Router/Path/TermFullJourneyRoutes";

const MedicalDetailPreviewBoxICICI = () => {
  const { question } = useAppSelector(
    (state) => state.TermFullJourney.TermICICIMedicalQuestion
  );
  const navigate = useNavigate();
  const [medicalQuestionData, setMedicalQuestionData] =
    React.useState<any>(question);

  console.log("TermICICIMedicalQuestion", medicalQuestionData);
  const dispatch = useAppDispatch();

  let found = false;
  let founds = false;

  Object.values(medicalQuestionData).forEach((main_data: any) => {
    // Check main_question status
    if (
      main_data &&
      main_data.main_question &&
      typeof main_data.main_question.status === "boolean"
    ) {
      if (main_data.main_question.status) {
        found = true;
        console.log("main_data (main_question):", main_data);
        console.log("found (main_question):", found);
      }
    }

    // Check sub_question status if it exists
    if (main_data.sub_question && Array.isArray(main_data.sub_question)) {
      main_data.sub_question.forEach((sub_data: any) => {
        if (sub_data && typeof sub_data.status === "boolean") {
          if (sub_data.status) {
            founds = true;
            console.log("sub_data (sub_question):", sub_data);
            console.log("founds (sub_question):", founds);
          }
        }
      });
    }
  });

  console.log("Final found:", found);
  console.log("Final founds:", founds);

  return (
    <Box className="previewBox mb-6 mx-3">
      <Grid container columnSpacing={2}>
        <Grid xs={8}>
          {found || founds ? (
            <p className="previewBoxTitle">Insured Member's Medical Details</p>
          ) : (
            <p className="previewBoxTitle">No Medical History Declared</p>
          )}
        </Grid>
        <Grid xs={4} textAlign="right">
          <Link
            onClick={() => {
              navigate(
                `${TERM_FULL_JOURNEY_ROUTES.PROPOSER_DETAILS}/${TERM_FULL_JOURNEY_ROUTES.MEDICAL_PAGE}`
              );
            }}
            className="previewBoxEdit"
          >
            <EditIcon /> Edit
          </Link>
        </Grid>
      </Grid>
      <Grid xs={12}>
        {Object.values(medicalQuestionData).map((mainData: any) => (
          mainData.main_question.status ? (
            <div key={mainData.main_question.code}>
              <h6 className="question">{mainData.main_question.title}</h6>
              {mainData.main_question.field_data &&
                mainData.main_question.field_data.map(
                  (field: any, index: number) => (
                    <div key={index}>
                      <strong>{field.title}:</strong> {field.value}
                    </div>
                  )
                )}
            </div>
          ) : null
        ))}
      </Grid>
    </Box>
  );
};

export default MedicalDetailPreviewBoxICICI;
