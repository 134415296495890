import { Box, Link, Modal } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { TW_ROUTES } from "../../Router/Path/TWRoutes";
import { useAppSelector } from "../../Store/hooks";
import CustomButton from "../CustomButton/CustomButton";
import { HEALTH_PROPOSAL_SERVICES } from "../../Services/health-php/HealthPropsalServices";
import {
  isEmpty,
  validateEmail,
  validateMobileNumber,
} from "../../SupportingFiles/HelpingFunction";
import { useEffect, useState } from "react";
import { TFieldData } from "../../types/Common/TFieldData";
import RKTextField from "../FieldTypes/RKTextField/RKTextField";
import { toast } from "react-toastify";

function TMFooter({
  forward,
  value,
  disableButton,
  attrName,
  value_update,
  textName = "Continue",
  loader = false,
}: {
  forward?: Function;
  value?: any;
  backward?: Function;
  attrName?: any;
  value_update?: any;
  disableButton?: boolean;
  textName?: string;
  loader?: boolean;
}) {
  const { PAGE_STATUS, ADD_FORM_RESPONSE } = useAppSelector(
    (state) => state.TW
  );
  const path = window.location.pathname;
  const { TW, Car, Health } = useAppSelector((state) => state);
  //  const {DAta } = useAppSelector(
  //    (state) => state.Health?.ADD_FORM_RESPONSE?.
  //  );
  const [previewDetail, setPreviewDetail] = useState("");

  const [linkLoad, setLinkLoad] = useState<boolean>(false);
  useEffect(() => {
    // Get the current URL
    const url = window.location.href;

    // Create a URL object
    const urlObj = new URL(url);

    // Get the pathname
    const pathname = urlObj.pathname;

    // Split the pathname and get the last segment
    const segments = pathname.split("/");
    const desiredSegment = segments[segments.length - 1]; // This will be "preview-details"

    // Set the state
    setPreviewDetail(desiredSegment);
  }, []);
  const [param, setParam] = useState("");
  const [url, setUrl] = useState("");
  const [type, setType] = useState("");
  useEffect(() => {
    const fullURL = window.location.href;
    console.log("Full URL:", fullURL);
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const type = params.get("type");
    const payment = params.get("cus_type");
    setUrl(fullURL);
    setType(type || "");
    setParam(payment || "");
  }, []);

  const [shareURLData, setShareURLData] = useState<{
    email: TFieldData;
    mobile: TFieldData;
  }>({
    email: { value: "", warning: false },
    mobile: { value: "", warning: false },
  });
  const [showPaymentPopup, setShowPaymentPopup] = useState<boolean>(false);

  const handleDetails = (attrName: any, value: any) => {
    if (attrName === "email") {
      setShareURLData((prev) => ({
        ...prev,
        email: { value: value, warning: !validateEmail(value) },
      }));
    } else if (attrName === "mobile") {
      setShareURLData((prev) => ({
        ...prev,
        mobile: { value: value, warning: !validateMobileNumber(value) },
      }));
    }
  };

  const PAYMENT_LINK = () => {
    setLinkLoad(true);
    let data = { ...shareURLData };
    if (!isEmpty(data.email.value)) {
      data = {
        ...data,
        email: { ...data.email, warning: !validateEmail(data.email.value) },
      };
    } else {
      data = {
        ...data,
        email: { ...data.email, warning: false },
      };
    }
    if (!isEmpty(data.mobile.value)) {
      data = {
        ...data,
        mobile: {
          ...data.mobile,
          warning: !validateMobileNumber(data.mobile.value),
        },
      };
    } else {
      data = {
        ...data,
        mobile: {
          ...data.mobile,
          warning: false,
        },
      };
    }

    setShareURLData(data);
    // Determine if there's an error
    const hasError = data.email.warning || data.mobile.warning;

    if (isEmpty(data.email.value) && isEmpty(data.mobile.value)) {
      toast.error("Please fill mandatory fields.");
      setLinkLoad(false);
      return;
    }

    if (hasError) {
      setLinkLoad(false);
      return;
    }

    const onSuccess = (res: any) => {
      console.log("result", res);
      setLinkLoad(false);
      setShowPaymentPopup(false);
      setShareURLData({
        email: { value: "", warning: false },
        mobile: { value: "", warning: false },
      });
      toast.success("Link sent successfully");
    };

    const onError = (err: any) => {
      console.log("error", err);
      setLinkLoad(false);
    };

    let param = {
      customerName:
        type === "CAR"
          ? `${Car?.OWNER_DETAILS?.name?.value || "User"}`
          : type === "TW"
          ? `${TW?.OWNER_DETAILS?.name?.value || "User"}`
          : `${Health?.INSURED_MEMBER_DETAILS.PROPOSER_DETAILS.proposer_name.value}`,
      email: data.email.value,
      number: data.mobile.value,
      planName:
        type === "CAR"
          ? "Car Insurance"
          : type === "TW"
          ? "Tw Insurance"
          : "Health Insurance",
      insurer:
        type === "CAR"
          ? `${Car?.SELECTED_QUOTE_DATA?.quotationDetail?.CompanyDetails?.short_desc}`
          : type === "TW"
          ? `${TW?.SELECTED_QUOTE_DATA?.quotationDetail?.CompanyDetails?.short_desc}`
          : `${Health?.SELECTED_QUOTE_DATA?.CompanyDetails?.short_desc}`,
      paymentLink: `${url}&cus_type=user`,
      utm_source:
        type === "CAR"
          ? `${Car?.ADD_FORM_RESPONSE?.utm_source}`
          : type === "TW"
          ? `${TW?.ADD_FORM_RESPONSE?.utm_source}`
          : `${Health?.ADD_FORM_RESPONSE?.utm_source}`,
      utm_medium:
        type === "CAR"
          ? `${Car?.ADD_FORM_RESPONSE?.utm_medium}`
          : type === "TW"
          ? `${TW?.ADD_FORM_RESPONSE?.utm_medium}`
          : `${Health?.ADD_FORM_RESPONSE?.utm_medium}`,
      page:
        type === "CAR"
          ? previewDetail === "ckyc-verification"
            ? "KYCPage_4wheeler"
            : "OnSummary_car"
          : type === "TW"
          ? previewDetail === "ckyc-verification"
            ? "KYCPage_2wheeler"
            : "OnSummary_2Wheeler"
          : previewDetail === "ckyc-verification"
          ? "KYCPage_Health"
          : "OnSummary_Health",
    };

    HEALTH_PROPOSAL_SERVICES.PAYMENT_LINK(onSuccess, onError, param);
  };

  const showShareLink =
    (!isEmpty(Car.ADD_FORM_RESPONSE.utm_medium) ||
      !isEmpty(TW.ADD_FORM_RESPONSE.utm_medium)) &&
    isEmpty(param);

  const CUS = Car?.CUS_TYPE || TW?.CUS_TYPE;
  return (
    <Box className="mProposalFooter">
      <Modal open={showPaymentPopup} className="modalWrapper">
        <Box className="modalContent xsWidth kycPopups">
          <Grid container spacing={2}>
            <Grid xs={12} textAlign="center">
              <h4 className="popup-heading mb-2 mt-6">Enter Details</h4>
              <p className="grey_p">Enter Details to proceed</p>
              <Link
                className="close-button"
                onClick={() => {
                  setShowPaymentPopup(false);
                  setShareURLData({
                    email: { value: "", warning: false },
                    mobile: { value: "", warning: false },
                  });
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} textAlign="center">
            <Grid xs={12}>
              <RKTextField
                id="email"
                class_name="inputField mb-6"
                title={"Proposer Email"}
                value={shareURLData.email.value}
                attrName={"email"}
                value_update={handleDetails}
                error_message={
                  !isEmpty(shareURLData.email.value)
                    ? "Enter Valid E-mail"
                    : "Enter E-mail"
                }
                warn_status={shareURLData.email.warning}
              />
            </Grid>
            <Grid xs={12}>
              <RKTextField
                id="mobile"
                class_name="inputField"
                title={"Proposer Mobile"}
                value={shareURLData.mobile.value}
                attrName={"mobile"}
                value_update={handleDetails}
                error_message={
                  !isEmpty(shareURLData.mobile.value)
                    ? "Enter Valid Mobile No."
                    : "Enter Mobile No."
                }
                warn_status={shareURLData.mobile.warning}
                validation_type="NUMBER"
                max_length={10}
              />
            </Grid>
          </Grid>
          <Grid container columnSpacing={3} marginTop={"24px"}>
            <Grid xs={12} style={{ textAlign: "center" }}>
              <CustomButton
                text_name="Submit"
                class_name="primaryBtn mb-3"
                size="medium"
                onClickFunction={PAYMENT_LINK}
                loading={linkLoad}
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Grid container spacing={3}>
        <Grid xs={12} className="footerInner" alignItems="center" gap="12px">
          {showShareLink && !CUS && path.includes("preview-details") ? null : (
            <CustomButton
              disabled={disableButton}
              text_name={
                PAGE_STATUS
                  ? "Update"
                  : textName !== "Continue"
                  ? textName
                  : path ===
                    `${TW_ROUTES.PROPOSAL_PAGE}/${TW_ROUTES.PREVIEW_DEATILS}`
                  ? "Make Payment"
                  : textName
              }
              class_name="primaryBtn"
              size="large"
              onClickFunction={() => {
                forward && forward();
                value_update && value_update(attrName, value + 1);
              }}
              loading={loader}
            />
          )}
          {showShareLink && !CUS ? (
            <CustomButton
              text_name={"Share Link"}
              class_name="primaryBtn ml-3"
              size="large"
              onClickFunction={() => {
                setShowPaymentPopup(true);
              }}
            />
          ) : null}
        </Grid>
      </Grid>
    </Box>
  );
}

export default TMFooter;
