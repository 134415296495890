import { Box, FormControlLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import {
  isEmpty,
  validatePincode,
} from "../../../../../../SupportingFiles/HelpingFunction";
import { useAppSelector } from "../../../../../../Store/hooks";
import { TAddressDetails } from "../../../../../../types/HI/TProposal";
import { TKycResponse } from "../../../../../../types/HI/TKycResponse";
import { PROPOSAL_SERVICES } from "../../../../../../Services/HI/ProposalService";
import { toast } from "react-toastify";
import { proposalSlice } from "../../../../../../Store/Slice_Reducer/HI/Proposal/ProposalSlice";
import { pageSlice } from "../../../../../../Store/Slice_Reducer/HI/PageSlice/PageSlice";
import { HI_PATH } from "../../../../../../Router/Path/HIPath";
import { QUOTES_SERVICES } from "../../../../../../Services/HI/QuotesService";
import { kycResponseSlice } from "../../../../../../Store/Slice_Reducer/HI/KycResponse/KycResponseSlice";
import { AddressDetails_DTO } from "../../../../../../DTO/HIDTO/ProposalDTO";
import RKTextField from "../../../../../../Component/FieldTypes/RKTextField/RKTextField";
import Footer from "../../../../../../Component/HI/Desktop/ProposalComponents/Footer/Footer";

const AddressDetails: React.FC = () => {
  const navigate = useNavigate();
  const { PAGE_STATUS } = useAppSelector((state) => state.pageSlice);
  const [load, setLoad] = useState<boolean>(false);
  const address_details = useAppSelector(
    (state) => state.proposalInformation.addressDetails
  );
  const [addressDetails, setAddressDetails] =
    useState<TAddressDetails>(address_details);
  const { proposalInformation, quote } = useAppSelector((state) => state);

  const kycRespState = useAppSelector((state) => state.kycResponseSlice);
  const [kycResp, setKycResp] = useState<TKycResponse>(kycRespState);
  const [kycRespValidate, setKycRespValidate] = useState<any>({
    address_line1_warning: false,
    address_line2_warning: false,
    pincode_warning: false,
  });

  const dispatch = useDispatch();

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName[1] !== "kycResp") {
      setAddressDetails((prev: any) => ({
        ...prev,
        [attrName[1]]: {
          ...prev[attrName[1]],
          [attrName[0]]: {
            ...prev[attrName[1]][attrName[0]],
            value: value,
            warning:
              attrName[0] === "pincode"
                ? !validatePincode(value)
                : isEmpty(value),
          },
        },
      }));
    } else {
      setKycResp((prev) => ({ ...prev, [attrName[0]]: value }));
    }
  };

  const get_city_state_by_pincode = (
    pincode: string,
    type: "PROPERTY" | "PERMANENT" | "KYC"
  ) => {
    const onSuccess = (data: any) => {
      const response = data.data.response;
      const city = response.city_name;
      const state = response.state_name;

      if (type === "PROPERTY") {
        setAddressDetails((prev) => ({
          ...prev,
          propertyAddress: {
            ...prev.propertyAddress,
            city: { ...prev.propertyAddress.city, value: city },
            state: { ...prev.propertyAddress.state, value: state },
          },
        }));
      } else if (type === "PERMANENT") {
        setAddressDetails((prev) => ({
          ...prev,
          communicationAddress: {
            ...prev.communicationAddress,
            city: { ...prev.communicationAddress.city, value: city },
            state: { ...prev.communicationAddress.state, value: state },
          },
        }));
      } else if (type === "KYC") {
        setKycResp((prev) => ({
          ...prev,
          city: city,
          state: state,
        }));
      }
    };
    const onError = () => {
    };

    PROPOSAL_SERVICES.get_city_state_pincode(onSuccess, onError, pincode);
  };

  useEffect(() => {
    if (validatePincode(addressDetails.propertyAddress.pincode.value)) {
      get_city_state_by_pincode(
        addressDetails.propertyAddress.pincode.value,
        "PROPERTY"
      );
    }
  }, [addressDetails.propertyAddress.pincode.value]);

  useEffect(() => {
    if (validatePincode(addressDetails.communicationAddress.pincode.value)) {
      get_city_state_by_pincode(
        addressDetails.communicationAddress.pincode.value,
        "PERMANENT"
      );
    }
  }, [addressDetails.communicationAddress.pincode.value]);

  useEffect(() => {
    if (validatePincode(kycResp.pincode)) {
      get_city_state_by_pincode(kycResp.pincode, "KYC");
    }
  }, [kycResp.pincode]);

  const updateDigitQuotePremium = (loadingToast: any) => {
    const onSuccess = (res: any) => {
      toast.dismiss(loadingToast);
      dispatch(
        proposalSlice.actions.setPolicyInfo({
          ...proposalInformation.policy_information,
          premiumDetails: res.data.quoteLists,
        })
      );
      setLoad(false);
      if (PAGE_STATUS) {
        dispatch(pageSlice.actions.UPDATE_PAGE_STATUS(false));
        navigate(-1);
      } else {
        navigate(`${HI_PATH.Proposal}/${HI_PATH.REVIEW_DETAILS}`);
      }
    };

    const onError = (err: any) => {
      toast.dismiss(loadingToast);
      console.log(err);
    };

    QUOTES_SERVICES.update_digit_quote_premium(
      onSuccess,
      onError,
      quote.quote_id
    );
  };
  // let loadingToast: any;
  const saveProposalInfo = (data: any) => {
    // setWaitForApi(true);
    setLoad(true);
    console.log("callking proposal api");
    let loadingToast = toast.loading("Loading...");

    const onSuccess = (res: any) => {
      console.log("on sucess");

      // toast.dismiss(loadingToast);
      updateDigitQuotePremium(loadingToast);
    };

    const onError = (err: any) => {
      console.log("on err res", err);
      toast.dismiss(loadingToast);
      setLoad(false);
      // toast.dismiss(loadingToast);
    };

    PROPOSAL_SERVICES.save_proposal_info(
      onSuccess,
      onError,
      data,
      quote.quote_id
    );
  };

  const check_error = () => {
    let address_details: TAddressDetails = addressDetails;
    let kyc_response: TKycResponse = kycResp;
    let hasError: boolean = false;

    address_details = {
      ...address_details,
      propertyAddress: {
        ...address_details.propertyAddress,
        pincode: {
          ...address_details.propertyAddress.pincode,
          warning: !validatePincode(
            address_details.propertyAddress.pincode.value
          ),
        },
        address: {
          ...address_details.propertyAddress.address,
          warning: isEmpty(address_details.propertyAddress.address.value),
        },
        address2: {
          ...address_details.propertyAddress.address2,
          warning: isEmpty(address_details.propertyAddress.address2.value),
        },
      },
    };

    if (
      addressDetails.propertyAddress.pincode.warning ||
      addressDetails.propertyAddress.address.warning ||
      addressDetails.propertyAddress.address2.warning ||
      isEmpty(kyc_response.pincode)
    ) {
      hasError = true;
    }

    if (!address_details.isSame) {
      address_details = {
        ...address_details,
        communicationAddress: {
          ...address_details.communicationAddress,
          pincode: {
            ...address_details.communicationAddress.pincode,
            warning: !validatePincode(
              address_details.communicationAddress.pincode.value
            ),
          },
          address: {
            ...address_details.communicationAddress.address,
            warning: isEmpty(
              address_details.communicationAddress.address.value
            ),
          },
          address2: {
            ...address_details.communicationAddress.address2,
            warning: address_details.communicationAddress.address2.value
              ? isEmpty(address_details.communicationAddress.address2.value)
              : false,
          },
        },
      };

      if (
        address_details.communicationAddress.pincode.warning ||
        address_details.communicationAddress.address.warning ||
        address_details.communicationAddress.address2.warning ||
        hasError
      ) {
        hasError = true;
      }
    }
    const kycResponses = { ...kycResp };
    const kycResponseValidate = { ...kycRespValidate };

    if (isEmpty(kycResponses.pincode)) {
      kycResponseValidate.pincode_warning = true;
      hasError = true;
    }
    if (isEmpty(kycResponses.address_line_1)) {
      kycResponseValidate.address_line1_warning = true;
      hasError = true;
    }
    if (isEmpty(kycResponses.address_line_2)) {
      kycResponseValidate.address_line2_warning = true;
      hasError = true;
    }

    setKycRespValidate(kycResponseValidate);
    // }
    setAddressDetails(address_details);
    setKycResp(kyc_response);

    dispatch(
      proposalSlice.actions.setAddressDetails({
        ...address_details,
      })
    );
    dispatch(
      kycResponseSlice.actions.setKycResonse({
        ...kyc_response,
      })
    );
    return hasError;
  };

  const validate_form = () => {
    const warning = check_error();

    // console.log("warning", warning);
    // const obj = proposalInformation.addressDetails;
    const obj = addressDetails;

    let dto: AddressDetails_DTO = {
      isSame: obj.isSame,
      property_details: {
        address: obj.propertyAddress.address.value,
        city: obj.propertyAddress.city.value,
        pincode: obj.propertyAddress.pincode.value,
        state: obj.propertyAddress.state.value,
      },

      communication_address: {
        address:
          kycRespState.address_line_1 + " " + kycRespState.address_line_2,
        city: kycRespState.city,
        pincode: kycRespState.pincode,
        state: kycRespState.state,
        permanent_address: {
          address:
            kycRespState.address_line_1 + " " + kycRespState.address_line_2,
          city: kycRespState.city,
          pincode: kycRespState.pincode,
        },
      },
    };

    if (!obj.isSame) {
      dto = {
        ...dto,
        communication_address: {
          ...dto.communication_address,
          address: obj.communicationAddress.address.value,
          city: obj.communicationAddress.city.value,
          pincode: obj.communicationAddress.pincode.value,
        },
      };
    }

    if (!warning) {
      saveProposalInfo(dto);
    } else {
      toast.error("Please fill all fields");
      // toast.error("Please fill all fields");
      return;
    }
  };
  console.log("addressDetails", addressDetails);
  return (
    <Box className="proposalAddress">
      <h5 className="sectionTitle">Address Details</h5>
      <Grid container spacing={3} alignItems="center">
        <Grid xs={12}>
          <h6>Property Address (The property you want to get insured)</h6>
        </Grid>
        <Grid xs={3}>
          <RKTextField
            class_name="inputField"
            title={"Pincode"}
            value={addressDetails.propertyAddress.pincode.value}
            attrName={["pincode", "propertyAddress"]}
            max_length={6}
            value_update={updateMasterState}
            warn_status={addressDetails.propertyAddress.pincode.warning}
            disabled={addressDetails.propertyAddress.pincode.setFromKycResp}
            error_message={
              isEmpty(addressDetails.propertyAddress.pincode.value)
                ? "Enter Pincode"
                : "Enter Valid Pincode"
            }
            validation_type="NUMBER"
          />
        </Grid>
        <Grid xs={9}>
          {addressDetails.propertyAddress.pincode.value &&
            addressDetails.propertyAddress.city.value &&
            addressDetails.propertyAddress.state.value ? (
            <p>
              {addressDetails.propertyAddress.city.value +
                ", " +
                addressDetails.propertyAddress.state.value}
            </p>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={12} md={5}>
          <RKTextField
            class_name="inputField"
            title={"Address Line 1"}
            value={addressDetails.propertyAddress.address.value}
            attrName={["address", "propertyAddress"]}
            value_update={updateMasterState}
            warn_status={addressDetails.propertyAddress.address.warning}
            disabled={addressDetails.propertyAddress.address.setFromKycResp}
            error_message={
              isEmpty(addressDetails.propertyAddress.address.value)
                ? "Enter Address Line 1"
                : ""
            }
          />
        </Grid>
        <Grid xs={12} md={5}>
          <RKTextField
            class_name="inputField"
            title={"Address Line 2"}
            value={addressDetails.propertyAddress.address2.value}
            attrName={["address2", "propertyAddress"]}
            value_update={updateMasterState}
            warn_status={addressDetails.propertyAddress.address2.warning}
            disabled={addressDetails.propertyAddress.address2.setFromKycResp}
            error_message={
              isEmpty(addressDetails.propertyAddress.address2.value)
                ? "Enter Address Line 2"
                : ""
            }
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} alignItems="center">
        <Grid xs={12}>
          <h6>Permanent Address</h6>
        </Grid>

        <Grid xs={3}>
          <RKTextField
            class_name="inputField"
            title={"Pincode"}
            value={kycResp.pincode}
            attrName={["pincode", "kycResp"]}
            max_length={6}
            value_update={updateMasterState}
            warn_status={kycRespValidate.pincode_warning}
            error_message={isEmpty(kycResp.pincode) ? "Enter Pincode" : ""}
            disabled={false}
            validation_type="NUMBER"
          />
        </Grid>
        <Grid xs={9}>
          {kycResp.pincode && kycResp.city && kycResp.state ? (
            <p>
              {kycResp.city}, {kycResp.state}
            </p>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
      <Grid container spacing={3} alignItems="center">
        <Grid xs={12} md={5}>
          <RKTextField
            class_name="inputField"
            title={"Address Line 1"}
            value={kycResp.address_line_1}
            disabled={false}
            attrName={["address_line_1", "kycResp"]}
            value_update={updateMasterState}
            warn_status={kycRespValidate.address_line1_warning}
            error_message={
              isEmpty(kycResp.address_line_1) ? "Enter Address Line 1" : ""
            }
          />
        </Grid>
        <Grid xs={12} md={5}>
          <RKTextField
            class_name="inputField"
            title={"Address Line 2"}
            value={kycResp.address_line_2}
            disabled={false}
            attrName={["address_line_2", "kycResp"]}
            value_update={updateMasterState}
            warn_status={kycRespValidate.address_line2_warning}
            error_message={
              isEmpty(kycResp.address_line_2) ? "Enter Address Line 2" : ""
            }
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid xs={12}>
          <FormControlLabel
            className="proposalCheckBoxWrapper"
            control={
              <Checkbox
                checked={addressDetails.isSame}
                onClick={() => {
                  setAddressDetails((prev) => ({
                    ...prev,
                    isSame: !prev.isSame,
                  }));
                }}
              />
            }
            label="Mailing / Correspondence address is same as permanent address."
          />
        </Grid>
      </Grid>

      {/* Mailing Address */}
      {!addressDetails.isSame ? (
        <Box>
          <Grid container spacing={3}>
            <Grid xs={12}>
              <h6>Communication Address</h6>
            </Grid>
            <Grid xs={3}>
              <RKTextField
                class_name="inputField"
                title={"Pincode"}
                max_length={6}
                value={addressDetails.communicationAddress.pincode.value}
                attrName={["pincode", "communicationAddress"]}
                value_update={updateMasterState}
                warn_status={
                  addressDetails.communicationAddress.pincode.warning
                }
                error_message={
                  isEmpty(addressDetails.communicationAddress.pincode.value)
                    ? "Enter Pincode"
                    : "Enter Valid Pincode"
                }
              />
            </Grid>
            <Grid xs={9}>
              <p>
                {addressDetails.communicationAddress.city.value},{" "}
                {addressDetails.communicationAddress.state.value}
              </p>
            </Grid>
          </Grid>
          <Grid container spacing={3} alignItems="center">
            <Grid xs={6} md={5}>
              <RKTextField
                class_name="inputField"
                title={"Address Line 1"}
                value={addressDetails?.communicationAddress?.address?.value}
                attrName={["address", "communicationAddress"]}
                value_update={updateMasterState}
                warn_status={
                  addressDetails.communicationAddress.address.warning
                }
                error_message="Enter Address Line 1"
              />
            </Grid>
            <Grid xs={6} md={5}>
              <RKTextField
                class_name="inputField"
                title={"Address Line 2"}
                value={addressDetails.communicationAddress.address2.value}
                attrName={["address2", "communicationAddress"]}
                value_update={updateMasterState}
                warn_status={
                  addressDetails.communicationAddress.address2.warning
                }
                error_message="Enter Address Line 2"
              />
            </Grid>
          </Grid>
        </Box>
      ) : null}
      <Footer continue_function={validate_form} loader={load} />
    </Box>
  );
};

export default AddressDetails;
