import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import SelectDropdown from "../../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";
import TermFooter from "../../../../../Component/Term/TermFooter/TermFooter";
import TermRiderQuestion from "../../../../../Component/Term/TermRiderQuestion/TermRiderQuestion";
import { useAppDispatch, useAppSelector } from "../../../../../Store/hooks";
import { TermSlice } from "../../../../../Store/Slice_Reducer/Term/TermSlice";
import { formatToCurrency } from "../../../../../SupportingFiles/HelpingFunction";
import "./../../../../../SCSS/CommonSCSS/ProposalForms.scss";

function RidersBenefits({
  forward,
  tf003Data,
  tf001Data,
  loaderTf001,
  loaderTf003,
}: {
  forward: Function;
  tf003Data: any;
  tf001Data: any;
  loaderTf001: any;
  loaderTf003: any;
}) {
  const { RIDERS_BENEFITS, SELECTED_QUOTE_DATA } = useAppSelector(
    (state) => state.Term
  );
  const dispatch = useAppDispatch();
  return (
    <Box>
      <h5 className="sectionTitle">Addon Detail</h5>
      {SELECTED_QUOTE_DATA?.productDetails?.frequency === "5" ? null : (
        <Grid container spacing={3}>
          <Grid xs={12}>
            <TermRiderQuestion
              loader={loaderTf001}
              addon_amount={`${RIDERS_BENEFITS?.riders_benefits_response?.riders?.TF001?.premium}`}
              main_class="addonQuestion borderBottom"
              icon_class={"hospital"}
              question_name={"Accelerated Critical Illness Benefit"}
              policy_term=""
              desc={
                RIDERS_BENEFITS?.riders_benefits_response?.riders?.TF001
                  ?.description
              }
              sub_details={
                <Box sx={{ maxWidth: "250px" }} id="acb_select">
                  <SelectDropdown
                    id="acb_select_box"
                    class_name="inputField"
                    title="Select Value"
                    value={RIDERS_BENEFITS.local_value.tf001Value}
                    attrName={"tf001Value"}
                    value_update={(a: any, v: any) => {
                      dispatch(
                        TermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT(
                          {
                            updates: [
                              {
                                key: "tf001Value",
                                value: v,
                              },
                            ],
                          }
                        )
                      );
                    }}
                    data={tf001Data}
                    warn_status={false}
                  />
                </Box>
              }
              attrName={"tf001Value"}
              toggle_status={RIDERS_BENEFITS.local_value.tf001Status}
              value_update={(attrName: any, value: any) => {
                dispatch(
                  TermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT({
                    updates: [
                      {
                        key: "tf001Status",
                        value: value,
                      },
                    ],
                  })
                );
              }}
            />
          </Grid>
        </Grid>
      )}
      <Grid container spacing={3}>
        <Grid xs={12}>
          <TermRiderQuestion
            loader={loaderTf003}
            addon_amount={`${RIDERS_BENEFITS?.riders_benefits_response?.riders?.TF003?.premium}`}
            main_class="addonQuestion borderBottom"
            icon_class={"accident"}
            policy_term=""
            desc={
              RIDERS_BENEFITS?.riders_benefits_response?.riders?.TF003
                ?.description
            }
            question_name={`Accidental Death(AD) Benefit`}
            sub_details={
              <Box sx={{ maxWidth: "250px" }} id="adb_select">
                <SelectDropdown
                  id="adb_select_box"
                  class_name="inputField"
                  attrName={""}
                  title="Select Value"
                  value={RIDERS_BENEFITS.local_value.tf003Value}
                  value_update={(a: any, v: any) => {
                    dispatch(
                      TermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT({
                        updates: [
                          {
                            key: "tf003Value",
                            value: v,
                          },
                        ],
                      })
                    );
                  }}
                  data={tf003Data}
                  warn_status={false}
                />
              </Box>
            }
            toggle_status={RIDERS_BENEFITS.local_value.tf003Status}
            value_update={(attrName: any, value: any) => {
              dispatch(
                TermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT({
                  updates: [
                    {
                      key: "tf003Status",
                      value: value,
                    },
                  ],
                })
              );
            }}
            attrName={"tf003Value"}
          />
        </Grid>
      </Grid>
      <TermFooter
        forward={() => {
          forward();
        }}
      />
    </Box>
  );
}

export default RidersBenefits;
