import { Box, Collapse } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import { subDays } from "date-fns";
import { useAppSelector } from "../../../../Store/hooks";
import MemberRadioButtonNew from "../../../../Component/MemberRadioButton/MemberRadioButtonNew";
import DatePicker from "../../../../Component/FieldTypes/DatePicker/DatePicker";
import { calculateAgeInDays } from "../../../../SupportingFiles/HelpingFunction";
import RKTextField from "../../../../Component/FieldTypes/RKTextField/RKTextField";
import Footer from "../../../../Component/Footer/Footer";
import Medicaldetailsecure from "../../../../Component/MedicalQuestion/Medicaldetailsecure";
import SelectDropdown from "../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";
import { EProductId } from "../../../../Services/Enum/EHome";

function Medicaldetailsoptimarsecure({
  medicalQuestionData,
  updateMasterState,
  validateForm,
  PAGE_STATUS,
}: {
  medicalQuestionData: any;
  updateMasterState: Function;
  validateForm: Function;
  PAGE_STATUS: boolean;
}) {
  const { INSURED_MEMBER_DETAILS, SELECTED_QUOTE_DATA } = useAppSelector(
    (state) => state.Health
  );

  const { ADDON_DETAILS } = useAppSelector((state) => state.Health);

  const CurrentStatus_data: any = [
    {
      value: "CURED",
      label: "CURED",
    },
    {
      value: "NOT TREATED",
      label: "NOT TREATED",
    },
    {
      value: "ONGOING",
      label: "ONGOING",
    },
  ];
  const Details_of_treatment: any = [
    {
      value: "YES",
      label: "YES",
    },
    {
      value: "NO",
      label: "NO",
    },
  ];

  const LifeOfManagement_data: any = [
    {
      value: "MEDICAL",
      label: "MEDICAL",
    },
    {
      value: "SURGICAL",
      label: "SURGICAL",
    },
  ];

  return (
    <Box>
      <h5 className="sectionTitle">Underwriting Details</h5>
      <>
        {Object.values(medicalQuestionData).map(
          (data: any, indexMainQuestion: number) => {
            if (
              // data.main_question.sub_question &&
              data.main_question.id !== "MQ06"
            ) {
              return (
                <div key={indexMainQuestion}>
                  <Medicaldetailsecure
                    key={indexMainQuestion}
                    main_class="medicalQuestion borderBottom"
                    icon_class={data?.main_question?.icon_class}
                    question_name={data?.main_question?.title}
                    sub_details={<p>{data?.main_question?.description}</p>}
                    input_id_first={data?.main_question?.id}
                    input_id_second={data?.main_question?.id}
                    input_name="diagnosed"
                    toggle_button_status={data?.main_question?.status}
                    attrName={["question", data?.main_question?.id]}
                    value_update={updateMasterState}
                    data={data}
                    updateMasterState={updateMasterState}
                    select_disease={
                      <Collapse
                        in={data?.main_question?.status}
                        className="medicalQuestion-SubSection"
                      >
                        <Grid container spacing={3} className="pr-2 mb-3">
                          {data?.main_question?.sub_question && (
                            <Grid xs={12}>
                              {/* <h5>High or low blood pressure</h5> */}
                              {data?.main_question?.sub_question &&
                                Object.values(
                                  data?.main_question?.sub_question
                                )?.map(
                                  (
                                    dataSubQuestion: any,
                                    indexSubQuestion: number
                                  ) => (
                                    <>
                                      <Medicaldetailsecure
                                        key={indexSubQuestion}
                                        main_class="medicalQuestion borderBottom"
                                        icon_class={"tobaco"}
                                        question_name={
                                          dataSubQuestion?.description
                                        }
                                        sub_details={
                                          <p>{dataSubQuestion?.title}</p>
                                        }
                                        input_id_first={dataSubQuestion?.id}
                                        input_id_second={dataSubQuestion?.id}
                                        input_name="blood pressure"
                                        toggle_button_status={
                                          dataSubQuestion?.status
                                        }
                                        attrName={[
                                          "subQuestion",
                                          data?.main_question?.id,
                                          dataSubQuestion?.id,
                                        ]}
                                        value_update={updateMasterState}
                                        data={data}
                                        dataSubQuestion={dataSubQuestion}
                                        updateMasterState={updateMasterState}
                                        select_disease={
                                          <Collapse
                                            in={dataSubQuestion?.status}
                                            className="medicalQuestion-SubSection"
                                          >
                                            <Grid container spacing={2}>
                                              {Object.values(
                                                dataSubQuestion?.member_data
                                              )?.map(
                                                (
                                                  memberDataValue: any,
                                                  memberIndex: any
                                                ) => (
                                                  <Grid xs={6} md={3} lg={4}>
                                                    <>
                                                      {memberDataValue.name !==
                                                      "" ? (
                                                        <form>
                                                          {" "}
                                                          <MemberRadioButtonNew
                                                            class_name={
                                                              memberIndex ===
                                                                0 ||
                                                              memberIndex === 1
                                                                ? "adult"
                                                                : "child"
                                                            }
                                                            checked={
                                                              memberDataValue?.selection_status
                                                            }
                                                            label_name={
                                                              memberDataValue.name
                                                            }
                                                            attrName={[
                                                              "memberData",
                                                              data.main_question
                                                                .id,
                                                              dataSubQuestion.id,
                                                              memberDataValue.keyName,
                                                            ]}
                                                            value_update={
                                                              updateMasterState
                                                            }
                                                          />
                                                          {memberDataValue?.selection_status &&
                                                            memberDataValue.keyName && (
                                                              <>
                                                                {Object.values(
                                                                  memberDataValue.field_data
                                                                ).map(
                                                                  (
                                                                    field_data_data: any
                                                                  ) => {
                                                                    let datePickerRendered =
                                                                      false; // Flag to track DatePicker rendering
                                                                    return (
                                                                      <React.Fragment
                                                                        key={
                                                                          field_data_data.id
                                                                        }
                                                                      >
                                                                        {field_data_data.field_type ===
                                                                          "DATEPICKER" &&
                                                                          !datePickerRendered && (
                                                                            <>
                                                                              <DatePicker
                                                                                class_name="inputField mb-5"
                                                                                title={
                                                                                  field_data_data?.title
                                                                                }
                                                                                value={
                                                                                  field_data_data
                                                                                    ?.value
                                                                                    ?.value
                                                                                }
                                                                                attrName={[
                                                                                  "mainMemberDataField",
                                                                                  data
                                                                                    ?.main_question
                                                                                    ?.id,
                                                                                  dataSubQuestion?.id,
                                                                                  field_data_data.id,
                                                                                  memberDataValue.keyName,
                                                                                ]}
                                                                                value_update={
                                                                                  updateMasterState
                                                                                }
                                                                                min_date={
                                                                                  memberDataValue.keyName ===
                                                                                  "adult_one"
                                                                                    ? INSURED_MEMBER_DETAILS
                                                                                        .ADULT_ONE_DETAILS
                                                                                        .dob
                                                                                        .value
                                                                                      ? calculateAgeInDays(
                                                                                          `${INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value}`
                                                                                        )
                                                                                      : calculateAgeInDays(
                                                                                          `${INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value}`
                                                                                        )
                                                                                    : memberDataValue.keyName ===
                                                                                      "adult_two"
                                                                                    ? calculateAgeInDays(
                                                                                        `${INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.dob.value}`
                                                                                      )
                                                                                    : memberDataValue.keyName ===
                                                                                      "child_one"
                                                                                    ? calculateAgeInDays(
                                                                                        `${INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.dob.value}`
                                                                                      )
                                                                                    : memberDataValue.keyName ===
                                                                                      "child_two"
                                                                                    ? calculateAgeInDays(
                                                                                        `${INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.dob.value}`
                                                                                      )
                                                                                    : memberDataValue.keyName ===
                                                                                      "child_three"
                                                                                    ? calculateAgeInDays(
                                                                                        `${INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.dob.value}`
                                                                                      )
                                                                                    : memberDataValue.keyName ===
                                                                                      "child_four"
                                                                                    ? calculateAgeInDays(
                                                                                        `${INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.dob.value}`
                                                                                      )
                                                                                    : 25
                                                                                }
                                                                                max_date={
                                                                                  0
                                                                                }
                                                                                default_date={subDays(
                                                                                  new Date(),
                                                                                  memberDataValue.keyName ===
                                                                                    "adult_one"
                                                                                    ? INSURED_MEMBER_DETAILS
                                                                                        .ADULT_ONE_DETAILS
                                                                                        .dob
                                                                                        .value
                                                                                      ? calculateAgeInDays(
                                                                                          `${INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value}`
                                                                                        )
                                                                                      : calculateAgeInDays(
                                                                                          `${INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value}`
                                                                                        )
                                                                                    : memberDataValue.keyName ===
                                                                                      "adult_two"
                                                                                    ? calculateAgeInDays(
                                                                                        `${INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.dob.value}`
                                                                                      )
                                                                                    : memberDataValue.keyName ===
                                                                                      "child_one"
                                                                                    ? calculateAgeInDays(
                                                                                        `${INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.dob.value}`
                                                                                      )
                                                                                    : memberDataValue.keyName ===
                                                                                      "child_two"
                                                                                    ? calculateAgeInDays(
                                                                                        `${INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.dob.value}`
                                                                                      )
                                                                                    : memberDataValue.keyName ===
                                                                                      "child_three"
                                                                                    ? calculateAgeInDays(
                                                                                        `${INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.dob.value}`
                                                                                      )
                                                                                    : memberDataValue.keyName ===
                                                                                      "child_four"
                                                                                    ? calculateAgeInDays(
                                                                                        `${INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.dob.value}`
                                                                                      )
                                                                                    : 25
                                                                                )}
                                                                                warn_status={
                                                                                  field_data_data
                                                                                    ?.value
                                                                                    ?.warning
                                                                                }
                                                                                date_validation_type="DAYS"
                                                                                error_message={
                                                                                  "Select existing since"
                                                                                }
                                                                              />
                                                                            </>
                                                                          )}

                                                                        {field_data_data.field_type ===
                                                                          "TEXTFIELD" && (
                                                                          <RKTextField
                                                                            class_name="inputField mb-5"
                                                                            title={
                                                                              field_data_data?.title
                                                                            }
                                                                            attrName={[
                                                                              "mainMemberDataField",
                                                                              data
                                                                                ?.main_question
                                                                                ?.id,
                                                                              dataSubQuestion?.id,
                                                                              field_data_data.id,
                                                                              memberDataValue.keyName,
                                                                              "desc",
                                                                            ]}
                                                                            value={
                                                                              field_data_data
                                                                                ?.value
                                                                                ?.value
                                                                            }
                                                                            value_update={
                                                                              updateMasterState
                                                                            }
                                                                            warn_status={
                                                                              field_data_data
                                                                                ?.value
                                                                                ?.warning
                                                                            }
                                                                            error_message={
                                                                              "Enter description"
                                                                            }
                                                                          />
                                                                        )}
                                                                        {field_data_data.field_type ===
                                                                          "DROPDOWN" && (
                                                                          <SelectDropdown
                                                                            class_name=" inputField mb-5"
                                                                            title={
                                                                              field_data_data?.title
                                                                            }
                                                                            attrName={[
                                                                              "mainMemberDataField",
                                                                              data
                                                                                ?.main_question
                                                                                ?.id,
                                                                              dataSubQuestion?.id,
                                                                              field_data_data.id,
                                                                              memberDataValue.keyName,
                                                                              "desc",
                                                                            ]}
                                                                            value={
                                                                              field_data_data
                                                                                ?.value
                                                                                ?.value
                                                                            }
                                                                            value_update={
                                                                              updateMasterState
                                                                            }
                                                                            data={
                                                                              field_data_data?.title ===
                                                                              "Current Status"
                                                                                ? CurrentStatus_data
                                                                                : field_data_data?.title ===
                                                                                  "Is Medical Question Opted"
                                                                                ? Details_of_treatment
                                                                                : LifeOfManagement_data
                                                                            }
                                                                            warn_status={
                                                                              field_data_data
                                                                                ?.value
                                                                                ?.warning
                                                                            }
                                                                            error_message={
                                                                              "Enter description"
                                                                            }
                                                                          />
                                                                        )}

                                                                        {field_data_data.field_type ===
                                                                          "DATEPICKER" &&
                                                                          !datePickerRendered &&
                                                                          (datePickerRendered =
                                                                            true)}
                                                                      </React.Fragment>
                                                                    );
                                                                  }
                                                                )}
                                                              </>
                                                            )}
                                                        </form>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </>
                                                  </Grid>
                                                )
                                              )}
                                            </Grid>
                                          </Collapse>
                                        }
                                      />
                                    </>
                                  )
                                )}
                            </Grid>
                          )}
                        </Grid>
                      </Collapse>
                    }
                  />
                </div>
              );
            } else if (
              data.main_question.id === "MQ06" &&
              ADDON_DETAILS.Iscritical_Illness
            ) {
              return (
                <div key={indexMainQuestion}>
                  <Medicaldetailsecure
                    key={indexMainQuestion}
                    main_class="medicalQuestion borderBottom"
                    icon_class={data?.main_question?.icon_class}
                    question_name={data?.main_question?.title}
                    sub_details={<p>{data?.main_question?.description}</p>}
                    input_id_first={data?.main_question?.id}
                    input_id_second={data?.main_question?.id}
                    input_name="diagnosed"
                    toggle_button_status={data?.main_question?.status}
                    attrName={["question", data?.main_question?.id]}
                    value_update={updateMasterState}
                    data={data}
                    updateMasterState={updateMasterState}
                    select_disease={
                      <Collapse
                        in={data?.main_question?.status}
                        className="medicalQuestion-SubSection"
                      >
                        <Grid container spacing={3} className="pr-2 mb-3">
                          {data?.main_question?.sub_question && (
                            <Grid xs={12}>
                              {data?.main_question?.sub_question &&
                                Object.values(
                                  data?.main_question?.sub_question
                                )?.map(
                                  (
                                    dataSubQuestion: any,
                                    indexSubQuestion: number
                                  ) => (
                                    <>
                                      <Medicaldetailsecure
                                        key={indexSubQuestion}
                                        main_class="medicalQuestion borderBottom"
                                        icon_class={"tobaco"}
                                        question_name={
                                          dataSubQuestion?.description
                                        }
                                        sub_details={
                                          <p>{dataSubQuestion?.title}</p>
                                        }
                                        input_id_first={dataSubQuestion?.id}
                                        input_id_second={dataSubQuestion?.id}
                                        input_name="blood pressure"
                                        toggle_button_status={
                                          dataSubQuestion?.status
                                        }
                                        attrName={[
                                          "subQuestion",
                                          data?.main_question?.id,
                                          dataSubQuestion?.id,
                                        ]}
                                        value_update={updateMasterState}
                                        data={data}
                                        dataSubQuestion={dataSubQuestion}
                                        updateMasterState={updateMasterState}
                                        select_disease={
                                          <Collapse
                                            in={dataSubQuestion?.status}
                                            className="medicalQuestion-SubSection"
                                          >
                                            <Grid container spacing={2}>
                                              {Object.values(
                                                dataSubQuestion?.member_data
                                              )?.map(
                                                (
                                                  memberDataValue: any,
                                                  memberIndex: any
                                                ) => (
                                                  <Grid xs={6} md={3} lg={4}>
                                                    <>
                                                      {memberDataValue.name !==
                                                      "" ? (
                                                        <form>
                                                          {" "}
                                                          <MemberRadioButtonNew
                                                            class_name={
                                                              memberIndex ===
                                                                0 ||
                                                              memberIndex === 1
                                                                ? "adult"
                                                                : "child"
                                                            }
                                                            checked={
                                                              memberDataValue?.selection_status
                                                            }
                                                            label_name={
                                                              memberDataValue.name
                                                            }
                                                            attrName={[
                                                              "memberData",
                                                              data.main_question
                                                                .id,
                                                              dataSubQuestion.id,
                                                              memberDataValue.keyName,
                                                            ]}
                                                            value_update={
                                                              updateMasterState
                                                            }
                                                          />
                                                          {memberDataValue?.selection_status &&
                                                            memberDataValue.keyName && (
                                                              <>
                                                                {Object.values(
                                                                  memberDataValue.field_data
                                                                ).map(
                                                                  (
                                                                    field_data_data: any
                                                                  ) => {
                                                                    let datePickerRendered =
                                                                      false; // Flag to track DatePicker rendering
                                                                    return (
                                                                      <React.Fragment
                                                                        key={
                                                                          field_data_data.id
                                                                        }
                                                                      >
                                                                        {field_data_data.field_type ===
                                                                          "DATEPICKER" &&
                                                                          !datePickerRendered && (
                                                                            <DatePicker
                                                                              class_name="inputField mb-5"
                                                                              title={
                                                                                field_data_data?.title
                                                                              }
                                                                              value={
                                                                                field_data_data
                                                                                  ?.value
                                                                                  ?.value
                                                                              }
                                                                              attrName={[
                                                                                "mainMemberDataField",
                                                                                data
                                                                                  ?.main_question
                                                                                  ?.id,
                                                                                dataSubQuestion?.id,
                                                                                field_data_data.id,
                                                                                memberDataValue.keyName,
                                                                              ]}
                                                                              value_update={
                                                                                updateMasterState
                                                                              }
                                                                              min_date={
                                                                                memberDataValue.keyName ===
                                                                                "adult_one"
                                                                                  ? INSURED_MEMBER_DETAILS
                                                                                      .ADULT_ONE_DETAILS
                                                                                      .dob
                                                                                      .value
                                                                                    ? calculateAgeInDays(
                                                                                        `${INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value}`
                                                                                      )
                                                                                    : calculateAgeInDays(
                                                                                        `${INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value}`
                                                                                      )
                                                                                  : memberDataValue.keyName ===
                                                                                    "adult_two"
                                                                                  ? calculateAgeInDays(
                                                                                      `${INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.dob.value}`
                                                                                    )
                                                                                  : memberDataValue.keyName ===
                                                                                    "child_one"
                                                                                  ? calculateAgeInDays(
                                                                                      `${INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.dob.value}`
                                                                                    )
                                                                                  : memberDataValue.keyName ===
                                                                                    "child_two"
                                                                                  ? calculateAgeInDays(
                                                                                      `${INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.dob.value}`
                                                                                    )
                                                                                  : memberDataValue.keyName ===
                                                                                    "child_three"
                                                                                  ? calculateAgeInDays(
                                                                                      `${INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.dob.value}`
                                                                                    )
                                                                                  : memberDataValue.keyName ===
                                                                                    "child_four"
                                                                                  ? calculateAgeInDays(
                                                                                      `${INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.dob.value}`
                                                                                    )
                                                                                  : 25
                                                                              }
                                                                              max_date={
                                                                                0
                                                                              }
                                                                              default_date={subDays(
                                                                                new Date(),
                                                                                memberDataValue.keyName ===
                                                                                  "adult_one"
                                                                                  ? INSURED_MEMBER_DETAILS
                                                                                      .ADULT_ONE_DETAILS
                                                                                      .dob
                                                                                      .value
                                                                                    ? calculateAgeInDays(
                                                                                        `${INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value}`
                                                                                      )
                                                                                    : calculateAgeInDays(
                                                                                        `${INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value}`
                                                                                      )
                                                                                  : memberDataValue.keyName ===
                                                                                    "adult_two"
                                                                                  ? calculateAgeInDays(
                                                                                      `${INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.dob.value}`
                                                                                    )
                                                                                  : memberDataValue.keyName ===
                                                                                    "child_one"
                                                                                  ? calculateAgeInDays(
                                                                                      `${INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.dob.value}`
                                                                                    )
                                                                                  : memberDataValue.keyName ===
                                                                                    "child_two"
                                                                                  ? calculateAgeInDays(
                                                                                      `${INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.dob.value}`
                                                                                    )
                                                                                  : memberDataValue.keyName ===
                                                                                    "child_three"
                                                                                  ? calculateAgeInDays(
                                                                                      `${INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.dob.value}`
                                                                                    )
                                                                                  : memberDataValue.keyName ===
                                                                                    "child_four"
                                                                                  ? calculateAgeInDays(
                                                                                      `${INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.dob.value}`
                                                                                    )
                                                                                  : 25
                                                                              )}
                                                                              warn_status={
                                                                                field_data_data
                                                                                  ?.value
                                                                                  ?.warning
                                                                              }
                                                                              date_validation_type="DAYS"
                                                                              error_message={
                                                                                "Select existing since"
                                                                              }
                                                                            />
                                                                          )}

                                                                        {field_data_data.field_type ===
                                                                          "TEXTFIELD" && (
                                                                          <RKTextField
                                                                            class_name="inputField mb-5"
                                                                            title={
                                                                              field_data_data?.title
                                                                            }
                                                                            attrName={[
                                                                              "mainMemberDataField",
                                                                              data
                                                                                ?.main_question
                                                                                ?.id,
                                                                              dataSubQuestion?.id,
                                                                              field_data_data.id,
                                                                              memberDataValue.keyName,
                                                                              "desc",
                                                                            ]}
                                                                            value={
                                                                              field_data_data
                                                                                ?.value
                                                                                ?.value
                                                                            }
                                                                            value_update={
                                                                              updateMasterState
                                                                            }
                                                                            warn_status={
                                                                              field_data_data
                                                                                ?.value
                                                                                ?.warning
                                                                            }
                                                                            error_message={
                                                                              "Enter description"
                                                                            }
                                                                          />
                                                                        )}
                                                                        {field_data_data.field_type ===
                                                                          "DROPDOWN" && (
                                                                          <SelectDropdown
                                                                            class_name=" inputField mb-5"
                                                                            title={
                                                                              field_data_data?.title
                                                                            }
                                                                            attrName={[
                                                                              "mainMemberDataField",
                                                                              data
                                                                                ?.main_question
                                                                                ?.id,
                                                                              dataSubQuestion?.id,
                                                                              field_data_data.id,
                                                                              memberDataValue.keyName,
                                                                              "desc",
                                                                            ]}
                                                                            value={
                                                                              field_data_data
                                                                                ?.value
                                                                                ?.value
                                                                            }
                                                                            value_update={
                                                                              updateMasterState
                                                                            }
                                                                            data={
                                                                              field_data_data?.title ===
                                                                              "Current Status"
                                                                                ? CurrentStatus_data
                                                                                : field_data_data?.title ===
                                                                                  "Is Medical Question Opted"
                                                                                ? Details_of_treatment
                                                                                : LifeOfManagement_data
                                                                            }
                                                                            warn_status={
                                                                              field_data_data
                                                                                ?.value
                                                                                ?.warning
                                                                            }
                                                                            error_message={
                                                                              "Enter description"
                                                                            }
                                                                          />
                                                                        )}

                                                                        {field_data_data.field_type ===
                                                                          "DATEPICKER" &&
                                                                          !datePickerRendered &&
                                                                          (datePickerRendered =
                                                                            true)}
                                                                      </React.Fragment>
                                                                    );
                                                                  }
                                                                )}
                                                              </>
                                                            )}
                                                        </form>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </>
                                                  </Grid>
                                                )
                                              )}
                                            </Grid>
                                          </Collapse>
                                        }
                                      />
                                    </>
                                  )
                                )}
                            </Grid>
                          )}
                        </Grid>
                      </Collapse>
                    }
                  />
                </div>
              );
            }
          }
        )}
        {/* {SELECTED_QUOTE_DATA.productDetails.id ===
          EProductId.HDFC_HEALTH_Plan ||
        SELECTED_QUOTE_DATA.productDetails.id ===
          EProductId.HDFC_OPTIMA_SUPER_SECURE_Plan
          ? Object.values(medicalQuestionData).map(
              (data: any, indexMainQuestion: number) => {
                if (
                  data.main_question.id === "MQ06" &&
                  data.main_question.sub_question &&
                  ADDON_DETAILS.Iscritical_Illness
                ) {
                  return (
                    <div key={indexMainQuestion}>
                      <Medicaldetailsecure
                        key={indexMainQuestion}
                        main_class="medicalQuestion borderBottom"
                        icon_class={data?.main_question?.icon_class}
                        question_name={data?.main_question?.title}
                        sub_details={<p>{data?.main_question?.description}</p>}
                        input_id_first={data?.main_question?.id}
                        input_id_second={data?.main_question?.id}
                        input_name="diagnosed"
                        toggle_button_status={data?.main_question?.status}
                        attrName={["question", data?.main_question?.id]}
                        value_update={updateMasterState}
                        data={data}
                        updateMasterState={updateMasterState}
                        select_disease={
                          <Collapse
                            in={data?.main_question?.status}
                            className="medicalQuestion-SubSection"
                          >
                            <Grid container spacing={3} className="pr-2 mb-3">
                              {data?.main_question?.sub_question && (
                                <Grid xs={12}>
                                  {data?.main_question?.sub_question &&
                                    Object.values(
                                      data?.main_question?.sub_question
                                    )?.map(
                                      (
                                        dataSubQuestion: any,
                                        indexSubQuestion: number
                                      ) => (
                                        <>
                                          <Medicaldetailsecure
                                            key={indexSubQuestion}
                                            main_class="medicalQuestion borderBottom"
                                            icon_class={"tobaco"}
                                            question_name={
                                              dataSubQuestion?.description
                                            }
                                            sub_details={
                                              <p>{dataSubQuestion?.title}</p>
                                            }
                                            input_id_first={dataSubQuestion?.id}
                                            input_id_second={
                                              dataSubQuestion?.id
                                            }
                                            input_name="blood pressure"
                                            toggle_button_status={
                                              dataSubQuestion?.status
                                            }
                                            attrName={[
                                              "subQuestion",
                                              data?.main_question?.id,
                                              dataSubQuestion?.id,
                                            ]}
                                            value_update={updateMasterState}
                                            data={data}
                                            dataSubQuestion={dataSubQuestion}
                                            updateMasterState={
                                              updateMasterState
                                            }
                                            select_disease={
                                              <Collapse
                                                in={dataSubQuestion?.status}
                                                className="medicalQuestion-SubSection"
                                              >
                                                <Grid container spacing={2}>
                                                  {Object.values(
                                                    dataSubQuestion?.member_data
                                                  )?.map(
                                                    (
                                                      memberDataValue: any,
                                                      memberIndex: any
                                                    ) => (
                                                      <Grid
                                                        xs={6}
                                                        md={3}
                                                        lg={4}
                                                      >
                                                        <>
                                                          {memberDataValue.name !==
                                                          "" ? (
                                                            <form>
                                                              {" "}
                                                              <MemberRadioButtonNew
                                                                class_name={
                                                                  memberIndex ===
                                                                    0 ||
                                                                  memberIndex ===
                                                                    1
                                                                    ? "adult"
                                                                    : "child"
                                                                }
                                                                checked={
                                                                  memberDataValue?.selection_status
                                                                }
                                                                label_name={
                                                                  memberDataValue.name
                                                                }
                                                                attrName={[
                                                                  "memberData",
                                                                  data
                                                                    .main_question
                                                                    .id,
                                                                  dataSubQuestion.id,
                                                                  memberDataValue.keyName,
                                                                ]}
                                                                value_update={
                                                                  updateMasterState
                                                                }
                                                              />
                                                              {memberDataValue?.selection_status &&
                                                                memberDataValue.keyName && (
                                                                  <>
                                                                    {Object.values(
                                                                      memberDataValue.field_data
                                                                    ).map(
                                                                      (
                                                                        field_data_data: any
                                                                      ) => {
                                                                        let datePickerRendered =
                                                                          false; // Flag to track DatePicker rendering
                                                                        return (
                                                                          <React.Fragment
                                                                            key={
                                                                              field_data_data.id
                                                                            }
                                                                          >
                                                                            {field_data_data.field_type ===
                                                                              "DATEPICKER" &&
                                                                              !datePickerRendered && (
                                                                                <DatePicker
                                                                                  class_name="inputField mb-5"
                                                                                  title={
                                                                                    field_data_data?.title
                                                                                  }
                                                                                  value={
                                                                                    field_data_data
                                                                                      ?.value
                                                                                      ?.value
                                                                                  }
                                                                                  attrName={[
                                                                                    "mainMemberDataField",
                                                                                    data
                                                                                      ?.main_question
                                                                                      ?.id,
                                                                                    dataSubQuestion?.id,
                                                                                    field_data_data.id,
                                                                                    memberDataValue.keyName,
                                                                                  ]}
                                                                                  value_update={
                                                                                    updateMasterState
                                                                                  }
                                                                                  min_date={
                                                                                    memberDataValue.keyName ===
                                                                                    "adult_one"
                                                                                      ? INSURED_MEMBER_DETAILS
                                                                                          .ADULT_ONE_DETAILS
                                                                                          .dob
                                                                                          .value
                                                                                        ? calculateAgeInDays(
                                                                                            `${INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value}`
                                                                                          )
                                                                                        : calculateAgeInDays(
                                                                                            `${INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value}`
                                                                                          )
                                                                                      : memberDataValue.keyName ===
                                                                                        "adult_two"
                                                                                      ? calculateAgeInDays(
                                                                                          `${INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.dob.value}`
                                                                                        )
                                                                                      : memberDataValue.keyName ===
                                                                                        "child_one"
                                                                                      ? calculateAgeInDays(
                                                                                          `${INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.dob.value}`
                                                                                        )
                                                                                      : memberDataValue.keyName ===
                                                                                        "child_two"
                                                                                      ? calculateAgeInDays(
                                                                                          `${INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.dob.value}`
                                                                                        )
                                                                                      : memberDataValue.keyName ===
                                                                                        "child_three"
                                                                                      ? calculateAgeInDays(
                                                                                          `${INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.dob.value}`
                                                                                        )
                                                                                      : memberDataValue.keyName ===
                                                                                        "child_four"
                                                                                      ? calculateAgeInDays(
                                                                                          `${INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.dob.value}`
                                                                                        )
                                                                                      : 25
                                                                                  }
                                                                                  max_date={
                                                                                    0
                                                                                  }
                                                                                  default_date={subDays(
                                                                                    new Date(),
                                                                                    memberDataValue.keyName ===
                                                                                      "adult_one"
                                                                                      ? INSURED_MEMBER_DETAILS
                                                                                          .ADULT_ONE_DETAILS
                                                                                          .dob
                                                                                          .value
                                                                                        ? calculateAgeInDays(
                                                                                            `${INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value}`
                                                                                          )
                                                                                        : calculateAgeInDays(
                                                                                            `${INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value}`
                                                                                          )
                                                                                      : memberDataValue.keyName ===
                                                                                        "adult_two"
                                                                                      ? calculateAgeInDays(
                                                                                          `${INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.dob.value}`
                                                                                        )
                                                                                      : memberDataValue.keyName ===
                                                                                        "child_one"
                                                                                      ? calculateAgeInDays(
                                                                                          `${INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.dob.value}`
                                                                                        )
                                                                                      : memberDataValue.keyName ===
                                                                                        "child_two"
                                                                                      ? calculateAgeInDays(
                                                                                          `${INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.dob.value}`
                                                                                        )
                                                                                      : memberDataValue.keyName ===
                                                                                        "child_three"
                                                                                      ? calculateAgeInDays(
                                                                                          `${INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.dob.value}`
                                                                                        )
                                                                                      : memberDataValue.keyName ===
                                                                                        "child_four"
                                                                                      ? calculateAgeInDays(
                                                                                          `${INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.dob.value}`
                                                                                        )
                                                                                      : 25
                                                                                  )}
                                                                                  warn_status={
                                                                                    field_data_data
                                                                                      ?.value
                                                                                      ?.warning
                                                                                  }
                                                                                  date_validation_type="DAYS"
                                                                                  error_message={
                                                                                    "Select existing since"
                                                                                  }
                                                                                />
                                                                              )}

                                                                            {field_data_data.field_type ===
                                                                              "TEXTFIELD" && (
                                                                              <RKTextField
                                                                                class_name="inputField mb-5"
                                                                                title={
                                                                                  field_data_data?.title
                                                                                }
                                                                                attrName={[
                                                                                  "mainMemberDataField",
                                                                                  data
                                                                                    ?.main_question
                                                                                    ?.id,
                                                                                  dataSubQuestion?.id,
                                                                                  field_data_data.id,
                                                                                  memberDataValue.keyName,
                                                                                  "desc",
                                                                                ]}
                                                                                value={
                                                                                  field_data_data
                                                                                    ?.value
                                                                                    ?.value
                                                                                }
                                                                                value_update={
                                                                                  updateMasterState
                                                                                }
                                                                                warn_status={
                                                                                  field_data_data
                                                                                    ?.value
                                                                                    ?.warning
                                                                                }
                                                                                error_message={
                                                                                  "Enter description"
                                                                                }
                                                                                validation_type="NUMBER"
                                                                              />
                                                                            )}
                                                                            {field_data_data.field_type ===
                                                                              "DROPDOWN" && (
                                                                              <SelectDropdown
                                                                                class_name=" inputField mb-5"
                                                                                title={
                                                                                  field_data_data?.title
                                                                                }
                                                                                attrName={[
                                                                                  "mainMemberDataField",
                                                                                  data
                                                                                    ?.main_question
                                                                                    ?.id,
                                                                                  dataSubQuestion?.id,
                                                                                  field_data_data.id,
                                                                                  memberDataValue.keyName,
                                                                                  "desc",
                                                                                ]}
                                                                                value={
                                                                                  field_data_data
                                                                                    ?.value
                                                                                    ?.value
                                                                                }
                                                                                value_update={
                                                                                  updateMasterState
                                                                                }
                                                                                data={
                                                                                  field_data_data?.title ===
                                                                                  "Current Status"
                                                                                    ? CurrentStatus_data
                                                                                    : field_data_data?.title ===
                                                                                      "Is Medical Question Opted"
                                                                                    ? Details_of_treatment
                                                                                    : LifeOfManagement_data
                                                                                }
                                                                                warn_status={
                                                                                  field_data_data
                                                                                    ?.value
                                                                                    ?.warning
                                                                                }
                                                                                error_message={
                                                                                  "Enter description"
                                                                                }
                                                                              />
                                                                            )}

                                                                            {field_data_data.field_type ===
                                                                              "DATEPICKER" &&
                                                                              !datePickerRendered &&
                                                                              (datePickerRendered =
                                                                                true)}
                                                                          </React.Fragment>
                                                                        );
                                                                      }
                                                                    )}
                                                                  </>
                                                                )}
                                                            </form>
                                                          ) : (
                                                            ""
                                                          )}
                                                        </>
                                                      </Grid>
                                                    )
                                                  )}
                                                </Grid>
                                              </Collapse>
                                            }
                                          />
                                        </>
                                      )
                                    )}
                                </Grid>
                              )}
                            </Grid>
                          </Collapse>
                        }
                      />
                    </div>
                  );
                }
              }
            )
          : Object.values(medicalQuestionData).map(
              (data: any, indexMainQuestion: number) => {
                if (
                  data.main_question.id === "MQ06" &&
                  data.main_question.sub_question
                ) {
                  return (
                    <div key={indexMainQuestion}>
                      <Medicaldetailsecure
                        key={indexMainQuestion}
                        main_class="medicalQuestion borderBottom"
                        icon_class={data?.main_question?.icon_class}
                        question_name={data?.main_question?.title}
                        sub_details={<p>{data?.main_question?.description}</p>}
                        input_id_first={data?.main_question?.id}
                        input_id_second={data?.main_question?.id}
                        input_name="diagnosed"
                        toggle_button_status={data?.main_question?.status}
                        attrName={["question", data?.main_question?.id]}
                        value_update={updateMasterState}
                        data={data}
                        updateMasterState={updateMasterState}
                        select_disease={
                          <Collapse
                            in={data?.main_question?.status}
                            className="medicalQuestion-SubSection"
                          >
                            <Grid container spacing={3} className="pr-2 mb-3">
                              {data?.main_question?.sub_question && (
                                <Grid xs={12}>
                                  {data?.main_question?.sub_question &&
                                    Object.values(
                                      data?.main_question?.sub_question
                                    )?.map(
                                      (
                                        dataSubQuestion: any,
                                        indexSubQuestion: number
                                      ) => (
                                        <>
                                          <Medicaldetailsecure
                                            key={indexSubQuestion}
                                            main_class="medicalQuestion borderBottom"
                                            icon_class={"tobaco"}
                                            question_name={
                                              dataSubQuestion?.description
                                            }
                                            sub_details={
                                              <p>{dataSubQuestion?.title}</p>
                                            }
                                            input_id_first={dataSubQuestion?.id}
                                            input_id_second={
                                              dataSubQuestion?.id
                                            }
                                            input_name="blood pressure"
                                            toggle_button_status={
                                              dataSubQuestion?.status
                                            }
                                            attrName={[
                                              "subQuestion",
                                              data?.main_question?.id,
                                              dataSubQuestion?.id,
                                            ]}
                                            value_update={updateMasterState}
                                            data={data}
                                            dataSubQuestion={dataSubQuestion}
                                            updateMasterState={
                                              updateMasterState
                                            }
                                            select_disease={
                                              <Collapse
                                                in={dataSubQuestion?.status}
                                                className="medicalQuestion-SubSection"
                                              >
                                                <Grid container spacing={2}>
                                                  {Object.values(
                                                    dataSubQuestion?.member_data
                                                  )?.map(
                                                    (
                                                      memberDataValue: any,
                                                      memberIndex: any
                                                    ) => (
                                                      <Grid
                                                        xs={6}
                                                        md={3}
                                                        lg={4}
                                                      >
                                                        <>
                                                          {memberDataValue.name !==
                                                          "" ? (
                                                            <form>
                                                              {" "}
                                                              <MemberRadioButtonNew
                                                                class_name={
                                                                  memberIndex ===
                                                                    0 ||
                                                                  memberIndex ===
                                                                    1
                                                                    ? "adult"
                                                                    : "child"
                                                                }
                                                                checked={
                                                                  memberDataValue?.selection_status
                                                                }
                                                                label_name={
                                                                  memberDataValue.name
                                                                }
                                                                attrName={[
                                                                  "memberData",
                                                                  data
                                                                    .main_question
                                                                    .id,
                                                                  dataSubQuestion.id,
                                                                  memberDataValue.keyName,
                                                                ]}
                                                                value_update={
                                                                  updateMasterState
                                                                }
                                                              />
                                                              {memberDataValue?.selection_status &&
                                                                memberDataValue.keyName && (
                                                                  <>
                                                                    {Object.values(
                                                                      memberDataValue.field_data
                                                                    ).map(
                                                                      (
                                                                        field_data_data: any
                                                                      ) => {
                                                                        let datePickerRendered =
                                                                          false; // Flag to track DatePicker rendering
                                                                        return (
                                                                          <React.Fragment
                                                                            key={
                                                                              field_data_data.id
                                                                            }
                                                                          >
                                                                            {field_data_data.field_type ===
                                                                              "DATEPICKER" &&
                                                                              !datePickerRendered && (
                                                                                <DatePicker
                                                                                  class_name="inputField mb-5"
                                                                                  title={
                                                                                    field_data_data?.title
                                                                                  }
                                                                                  value={
                                                                                    field_data_data
                                                                                      ?.value
                                                                                      ?.value
                                                                                  }
                                                                                  attrName={[
                                                                                    "mainMemberDataField",
                                                                                    data
                                                                                      ?.main_question
                                                                                      ?.id,
                                                                                    dataSubQuestion?.id,
                                                                                    field_data_data.id,
                                                                                    memberDataValue.keyName,
                                                                                  ]}
                                                                                  value_update={
                                                                                    updateMasterState
                                                                                  }
                                                                                  min_date={
                                                                                    memberDataValue.keyName ===
                                                                                    "adult_one"
                                                                                      ? INSURED_MEMBER_DETAILS
                                                                                          .ADULT_ONE_DETAILS
                                                                                          .dob
                                                                                          .value
                                                                                        ? calculateAgeInDays(
                                                                                            `${INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value}`
                                                                                          )
                                                                                        : calculateAgeInDays(
                                                                                            `${INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value}`
                                                                                          )
                                                                                      : memberDataValue.keyName ===
                                                                                        "adult_two"
                                                                                      ? calculateAgeInDays(
                                                                                          `${INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.dob.value}`
                                                                                        )
                                                                                      : memberDataValue.keyName ===
                                                                                        "child_one"
                                                                                      ? calculateAgeInDays(
                                                                                          `${INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.dob.value}`
                                                                                        )
                                                                                      : memberDataValue.keyName ===
                                                                                        "child_two"
                                                                                      ? calculateAgeInDays(
                                                                                          `${INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.dob.value}`
                                                                                        )
                                                                                      : memberDataValue.keyName ===
                                                                                        "child_three"
                                                                                      ? calculateAgeInDays(
                                                                                          `${INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.dob.value}`
                                                                                        )
                                                                                      : memberDataValue.keyName ===
                                                                                        "child_four"
                                                                                      ? calculateAgeInDays(
                                                                                          `${INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.dob.value}`
                                                                                        )
                                                                                      : 25
                                                                                  }
                                                                                  max_date={
                                                                                    0
                                                                                  }
                                                                                  default_date={subDays(
                                                                                    new Date(),
                                                                                    memberDataValue.keyName ===
                                                                                      "adult_one"
                                                                                      ? INSURED_MEMBER_DETAILS
                                                                                          .ADULT_ONE_DETAILS
                                                                                          .dob
                                                                                          .value
                                                                                        ? calculateAgeInDays(
                                                                                            `${INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value}`
                                                                                          )
                                                                                        : calculateAgeInDays(
                                                                                            `${INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value}`
                                                                                          )
                                                                                      : memberDataValue.keyName ===
                                                                                        "adult_two"
                                                                                      ? calculateAgeInDays(
                                                                                          `${INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.dob.value}`
                                                                                        )
                                                                                      : memberDataValue.keyName ===
                                                                                        "child_one"
                                                                                      ? calculateAgeInDays(
                                                                                          `${INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.dob.value}`
                                                                                        )
                                                                                      : memberDataValue.keyName ===
                                                                                        "child_two"
                                                                                      ? calculateAgeInDays(
                                                                                          `${INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.dob.value}`
                                                                                        )
                                                                                      : memberDataValue.keyName ===
                                                                                        "child_three"
                                                                                      ? calculateAgeInDays(
                                                                                          `${INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.dob.value}`
                                                                                        )
                                                                                      : memberDataValue.keyName ===
                                                                                        "child_four"
                                                                                      ? calculateAgeInDays(
                                                                                          `${INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.dob.value}`
                                                                                        )
                                                                                      : 25
                                                                                  )}
                                                                                  warn_status={
                                                                                    field_data_data
                                                                                      ?.value
                                                                                      ?.warning
                                                                                  }
                                                                                  date_validation_type="DAYS"
                                                                                  error_message={
                                                                                    "Select existing since"
                                                                                  }
                                                                                />
                                                                              )}

                                                                            {field_data_data.field_type ===
                                                                              "TEXTFIELD" && (
                                                                              <RKTextField
                                                                                class_name="inputField mb-5"
                                                                                title={
                                                                                  field_data_data?.title
                                                                                }
                                                                                attrName={[
                                                                                  "mainMemberDataField",
                                                                                  data
                                                                                    ?.main_question
                                                                                    ?.id,
                                                                                  dataSubQuestion?.id,
                                                                                  field_data_data.id,
                                                                                  memberDataValue.keyName,
                                                                                  "desc",
                                                                                ]}
                                                                                value={
                                                                                  field_data_data
                                                                                    ?.value
                                                                                    ?.value
                                                                                }
                                                                                value_update={
                                                                                  updateMasterState
                                                                                }
                                                                                warn_status={
                                                                                  field_data_data
                                                                                    ?.value
                                                                                    ?.warning
                                                                                }
                                                                                error_message={
                                                                                  "Enter description"
                                                                                }
                                                                                validation_type="NUMBER"
                                                                              />
                                                                            )}
                                                                            {field_data_data.field_type ===
                                                                              "DROPDOWN" && (
                                                                              <SelectDropdown
                                                                                class_name=" inputField mb-5"
                                                                                title={
                                                                                  field_data_data?.title
                                                                                }
                                                                                attrName={[
                                                                                  "mainMemberDataField",
                                                                                  data
                                                                                    ?.main_question
                                                                                    ?.id,
                                                                                  dataSubQuestion?.id,
                                                                                  field_data_data.id,
                                                                                  memberDataValue.keyName,
                                                                                  "desc",
                                                                                ]}
                                                                                value={
                                                                                  field_data_data
                                                                                    ?.value
                                                                                    ?.value
                                                                                }
                                                                                value_update={
                                                                                  updateMasterState
                                                                                }
                                                                                data={
                                                                                  field_data_data?.title ===
                                                                                  "Current Status"
                                                                                    ? CurrentStatus_data
                                                                                    : field_data_data?.title ===
                                                                                      "Is Medical Question Opted"
                                                                                    ? Details_of_treatment
                                                                                    : LifeOfManagement_data
                                                                                }
                                                                                warn_status={
                                                                                  field_data_data
                                                                                    ?.value
                                                                                    ?.warning
                                                                                }
                                                                                error_message={
                                                                                  "Enter description"
                                                                                }
                                                                              />
                                                                            )}

                                                                            {field_data_data.field_type ===
                                                                              "DATEPICKER" &&
                                                                              !datePickerRendered &&
                                                                              (datePickerRendered =
                                                                                true)}
                                                                          </React.Fragment>
                                                                        );
                                                                      }
                                                                    )}
                                                                  </>
                                                                )}
                                                            </form>
                                                          ) : (
                                                            ""
                                                          )}
                                                        </>
                                                      </Grid>
                                                    )
                                                  )}
                                                </Grid>
                                              </Collapse>
                                            }
                                          />
                                        </>
                                      )
                                    )}
                                </Grid>
                              )}
                            </Grid>
                          </Collapse>
                        }
                      />
                    </div>
                  );
                }
              }
            )} */}
      </>
      <Footer
        textName={PAGE_STATUS ? "Update" : "Continue"}
        forward={() => validateForm()}
      />
    </Box>
  );
}

export default Medicaldetailsoptimarsecure;
