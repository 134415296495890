import { TCarAddonStatus } from "../types/Car/TCarAddonStatus";
import { TCarSingleQuoteDetail } from "../types/Car/TCarSingleQuoteDetail";
import { calculate_passenger_cover, isEmpty } from "./HelpingFunction";

function parse_value(value: any): number {
  if (isNaN(parseFloat(value))) {
    return 0;
  } else {
    return parseFloat(value);
  }
}

export type TGoDigitPlanName =
  | ""
  | "Pro"
  | "DPro"
  | "DCPro"
  | "DCEPro"
  | "DCTPro"
  | "DCETPro"
  | "DCRTIPro"
  | "DCERTIPro"
  | "DCERTIPro"
  | "DCTRTIPro"
  | "DCETRTIPro";

export const DETERMINE_PLAN_TYPE_GO_DIGIT = (
  ADDON_STATUS: TCarAddonStatus
): TGoDigitPlanName => {
  let plan_name: TGoDigitPlanName = "";

  if (
    ADDON_STATUS.rsa.value ||
    ADDON_STATUS.personalBelonging.value ||
    ADDON_STATUS.key_replacement.value
  ) {
    plan_name = "Pro";
  }

  if (ADDON_STATUS.depreciation_cover.value) {
    plan_name = "DPro";
  }

  if (ADDON_STATUS.consumable_cover.value) {
    plan_name = "DCPro";
  }

  if (ADDON_STATUS.consumable_cover.value && ADDON_STATUS.engine_cover.value) {
    plan_name = "DCEPro";
  }

  if (ADDON_STATUS.consumable_cover.value && ADDON_STATUS.tyre_cover.value) {
    plan_name = "DCTPro";
  }

  if (
    ADDON_STATUS.consumable_cover.value &&
    ADDON_STATUS.engine_cover.value &&
    ADDON_STATUS.tyre_cover.value
  ) {
    plan_name = "DCETPro";
  }

  if (ADDON_STATUS.consumable_cover.value && ADDON_STATUS.invoice_cover.value) {
    plan_name = "DCRTIPro";
  }

  if (
    ADDON_STATUS.consumable_cover.value &&
    ADDON_STATUS.engine_cover.value &&
    ADDON_STATUS.invoice_cover.value
  ) {
    plan_name = "DCERTIPro";
  }

  if (
    ADDON_STATUS.consumable_cover.value &&
    ADDON_STATUS.tyre_cover.value &&
    ADDON_STATUS.invoice_cover.value
  ) {
    plan_name = "DCTRTIPro";
  }

  if (
    ADDON_STATUS.consumable_cover.value &&
    ADDON_STATUS.engine_cover.value &&
    ADDON_STATUS.tyre_cover.value &&
    ADDON_STATUS.invoice_cover.value
  ) {
    plan_name = "DCETRTIPro";
  }

  return plan_name;
};

export function CarPremiumCal_go_digit(
  quote_details: TCarSingleQuoteDetail | undefined,
  addon_status: TCarAddonStatus,
  passenger_cover_user_value: string,
  PLAN_TYPE: string
) {
  let premium: number = 0;

  if (quote_details === undefined) return 0;

  const basicOD = parse_value(
    DETERMINE_PLAN_TYPE_GO_DIGIT(addon_status) === "Pro" ||
      isEmpty(DETERMINE_PLAN_TYPE_GO_DIGIT(addon_status))
      ? isEmpty(quote_details.premiumDetails.withOutBasicOd)
        ? `${quote_details.premiumDetails.basicOD}`
        : `${quote_details.premiumDetails.withOutBasicOd}`
      : `${quote_details.premiumDetails.basicOD}`
  );

  const basicTP = parse_value(quote_details.premiumDetails.basicTP);
  const ncbDiscount = parse_value(
    DETERMINE_PLAN_TYPE_GO_DIGIT(addon_status) === "Pro" ||
      isEmpty(DETERMINE_PLAN_TYPE_GO_DIGIT(addon_status))
      ? isEmpty(quote_details.premiumDetails.withOutNcbDiscount)
        ? `${quote_details.premiumDetails.ncbDiscount}`
        : `${quote_details.premiumDetails.withOutNcbDiscount}`
      : `${quote_details.premiumDetails.ncbDiscount}`
  );
  const paOwnerDriver = parse_value(quote_details.premiumDetails.paOwnerDriver);
  const driverCover = parse_value(quote_details.premiumDetails.driverCover);
  const GD_ProPlanPremium = parse_value(
    quote_details.premiumDetails.GD_ProPlanPremium
  );
  const GD_DProPlanPremium = parse_value(
    quote_details.premiumDetails.GD_DProPlanPremium
  );
  const GD_DCProPlanPremium = parse_value(
    quote_details.premiumDetails.GD_DCProPlanPremium
  );
  const GD_DCEProPlanPremium = parse_value(
    quote_details.premiumDetails.GD_DCEProPlanPremium
  );
  const GD_DCTProPlanPremium = parse_value(
    quote_details.premiumDetails.GD_DCTProPlanPremium
  );
  const GD_DCETProPlanPremium = parse_value(
    quote_details.premiumDetails.GD_DCETProPlanPremium
  );
  const GD_DCRTIProPlanPremium = parse_value(
    quote_details.premiumDetails.GD_DCRTIProPlanPremium
  );
  const GD_DCERTIProPlanPremium = parse_value(
    quote_details.premiumDetails.GD_DCERTIProPlanPremium
  );
  const GD_DCTRTIProPlanPremium = parse_value(
    quote_details.premiumDetails.GD_DCTRTIProPlanPremium
  );
  const GD_DCETRTIProPlanPremium = parse_value(
    quote_details.premiumDetails.GD_DCETRTIProPlanPremium
  );

  premium = basicOD + basicTP - ncbDiscount;

  // Define addon packages based on addon_status
  let selectedPackage = DETERMINE_PLAN_TYPE_GO_DIGIT(addon_status);

  // Calculate premium based on selected package
  switch (selectedPackage) {
    case "Pro":
      premium += parse_value(GD_ProPlanPremium);
      break;
    case "DPro":
      premium += parse_value(GD_DProPlanPremium);
      break;
    case "DCPro":
      premium += parse_value(GD_DCProPlanPremium);
      break;
    case "DCEPro":
      premium += parse_value(GD_DCEProPlanPremium);
      break;
    case "DCTPro":
      premium += parse_value(GD_DCTProPlanPremium);
      break;
    case "DCETPro":
      premium += parse_value(GD_DCETProPlanPremium);
      break;
    case "DCRTIPro":
      premium += parse_value(GD_DCRTIProPlanPremium);
      break;
    case "DCERTIPro":
      premium += parse_value(GD_DCERTIProPlanPremium);
      break;
    case "DCTRTIPro":
      premium += parse_value(GD_DCTRTIProPlanPremium);
      break;
    case "DCETRTIPro":
      premium += parse_value(GD_DCETRTIProPlanPremium);
      break;
    default:
  }

  // Add addon premiums
  if (addon_status.pa_owner_cover.value) premium += parse_value(paOwnerDriver);
  if (addon_status.driver_cover.value) premium += parse_value(driverCover);
  if (addon_status.passenger_cover.value) {
    premium += calculate_passenger_cover(
      Number(`${passenger_cover_user_value}`),
      quote_details,
      PLAN_TYPE
    );
  }

  // Calculate tax amount and final premium
  let tax_amount = (premium / 100) * 18;
  let final_premium = premium + tax_amount;

  return final_premium;
}
