import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import AddonQuestion from "../../../../../Component/AddonQuestion/AddonQuestion";
import Footer from "../../../../../Component/Footer/Footer";
import Loader from "../../../../../Component/Loader/Loader";
import { useNavigate } from "react-router-dom";
import { TCareAddon } from "../../../../../types/Travel/TTravelSlice";
import { TRAVEL_ROUTE } from "../../../../../Router/Path/TravelRoutes";

function AddonDetails({
  addonStatus,
  updateMasterState,
  validate,
  PAGE_STATUS,
  isLoading,
  mandatoryAddon,
  isContinueButtonDisabled,
}: {
  addonStatus: TCareAddon;
  PAGE_STATUS: boolean;
  updateMasterState: Function;
  validate: Function;
  isLoading: any;
  mandatoryAddon: any;
  isContinueButtonDisabled: boolean;
}) {
  const navigate = useNavigate();
  return (
    <>
      {isLoading && <Loader />}
      <Box>
        <h5 className="sectionTitle">Addon Details</h5>
        <Grid container spacing={3}>
          <Grid xs={12}>
            <AddonQuestion
              addon_amount=""
              main_class="addonQuestion borderBottom"
              icon_class="bonus"
              question_name="Emergency Hotel Accomodation, Burglary, Loan Protector "
              toggle_status={addonStatus.field_EHA_emergency_hotel}
              value_update={updateMasterState}
              attrName={"field_EHA_emergency_hotel"}
              desc=""
            />
          </Grid>
          <Grid xs={12}>
            <AddonQuestion
              addon_amount=""
              main_class="addonQuestion borderBottom"
              icon_class="bonus"
              question_name="Life threatening conditions due to PED- 10%"
              toggle_status={addonStatus.field_LTC_life_threatening}
              value_update={updateMasterState}
              attrName={"field_LTC_life_threatening"}
              desc=""
              disable={true}
            />
          </Grid>
          <Grid xs={12}>
            <AddonQuestion
              addon_amount=""
              main_class="addonQuestion borderBottom"
              icon_class="bonus"
              question_name="Refund of Visa fee (if visa Rejected)"
              toggle_status={addonStatus.field_RVF_refund_of_visa}
              value_update={updateMasterState}
              attrName={"field_RVF_refund_of_visa"}
              desc=""
            />
          </Grid>
          <Grid xs={12}>
            <AddonQuestion
              addon_amount=""
              main_class="addonQuestion borderBottom"
              icon_class="bonus"
              question_name="Adventure Sports Cover"
              toggle_status={addonStatus.field_ASC_adventure_sports_cover}
              value_update={updateMasterState}
              attrName={"field_ASC_adventure_sports_cover"}
              desc=""
            />
          </Grid>
          <Grid xs={12}>
            <AddonQuestion
              addon_amount=""
              main_class="addonQuestion borderBottom"
              icon_class="bonus"
              question_name="Staff Replacement"
              toggle_status={addonStatus.field_SR_staff_replacement}
              value_update={updateMasterState}
              attrName={"field_SR_staff_replacement"}
              desc=""
            />
          </Grid>
          <Grid xs={12}>
            <AddonQuestion
              addon_amount=""
              main_class="addonQuestion borderBottom"
              icon_class="bonus"
              question_name="Sports Cover"
              toggle_status={addonStatus.field_SC_sports_cover}
              value_update={updateMasterState}
              attrName={"field_SC_sports_cover"}
              desc=""
            />
          </Grid>
        </Grid>

        <Footer
          textName={PAGE_STATUS === true ? "Update" : "Continue"}
          attrName={"pageStatus"}
          disableButton={isContinueButtonDisabled}
          forward={() => {
            validate();
          }}
          value={0}
          backward={() => {
            navigate(
              `${TRAVEL_ROUTE.PROPOSAL_PAGE}/${TRAVEL_ROUTE.QOUTE_PAGE}`
            );
          }}
          loader={isLoading}
        />
      </Box>
    </>
  );
}

export default AddonDetails;
