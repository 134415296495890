import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import AddonDetails from "../../../../Page/Desktop/Travel/CARE/AddonDetails/AddonDetails";
import { TRAVEL_PROPOSAL_SERVICES } from "../../../../Services/Travel/TravelPropsalServices";
import { TravelSlice } from "../../../../Store/Slice_Reducer/Travel/TravelSlice";
import { TCareAddon, TTravelQuote } from "../../../../types/Travel/TTravelSlice";
import { TRAVEL_ROUTE } from "../../../../Router/Path/TravelRoutes";


export default function AddonDetailsContainer() {
  const isMobile = useIsMobile();
  const { Travel } = useAppSelector((state) => state);
  const {
    ADD_FORM,
    ADD_FORM_RESPONSE,
    ADDON_DETAILS,
    SELECTED_QUOTE_DATA,
    PAGE_STATUS,
    QUOTE_LOADER,
  } = useAppSelector((state) => state.Travel);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loader, setLoader] = useState<boolean>(false);
  const [addonStatus, setAddonStatus] = useState<TCareAddon>(ADDON_DETAILS);
  const [mandatoryAddon, setMandatoryAddon] = useState<[]>([]);
  const [isContinueButtonDisabled, setIsContinueButtonDisabled] = useState(false);

  const updatePreiumApi = () => {
    const onSuccess = (res: any) => {
      dispatch(TravelSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
      if (res.error === false) {
        setLoader(false);
        if (res?.quoteLists[0].premiumDetails.finalPremium) {
          dispatch(TravelSlice.actions.BULK_UPLOAD({
            ...Travel,
            SELECTED_QUOTE_DATA: {
              ...SELECTED_QUOTE_DATA,
              quote_details: {
                ...SELECTED_QUOTE_DATA.quote_details,
                premiumDetails: {
                  ...SELECTED_QUOTE_DATA.quote_details?.premiumDetails,
                  premium: res?.quoteLists[0]?.premiumDetails?.finalPremium,
                }
              }
            }
          }))
        }
      }
    };
    const onError = (err: any) => {
      dispatch(TravelSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
      console.log("err", err);
    };
    // setLoader(true);
    dispatch(TravelSlice.actions.UPDATE_QUOTE_LOADER_STATUS(true));
    let param: any = {
      "quote_no": ADD_FORM_RESPONSE.quote_no,
      "insurance_type": "Travel Insurance",
      "product_id": SELECTED_QUOTE_DATA.productID,
      "product_code": SELECTED_QUOTE_DATA.quote_details?.productDetails.product_code,
      "addon": {
        "field_EHA_emergency_hotel": addonStatus.field_EHA_emergency_hotel === true ? "1" : "0",//Emergency Hotel Accomodation, Burglary, Loan Protector / if frequency is multitrip then this addons is Mandatory
        "field_LTC_life_threatening": addonStatus.field_LTC_life_threatening === true ? "1" : "0",// Life threatening conditions due to PED- 10% /  Mandatory
        "field_RVF_refund_of_visa": addonStatus.field_RVF_refund_of_visa === true ? "1" : "0",//Refund of Visa fee (if visa Rejected)
        "field_ASC_adventure_sports_cover": addonStatus.field_ASC_adventure_sports_cover === true ? "1" : "0",//Adventure Sports Cover
        "field_SR_staff_replacement": addonStatus.field_SR_staff_replacement === true ? "1" : "0",//Staff Replacement
        "field_SC_sports_cover": addonStatus.field_SC_sports_cover === true ? "1" : "0"//Sports Cover
      }
    }
    TRAVEL_PROPOSAL_SERVICES.getPeriumByAddon(onSuccess, onError, param);
  };

  useEffect(() => {
    updatePreiumApi();
  }, [addonStatus]);

  const updateMasterState = (attrName: any, value: any) => {
    console.log(attrName, "attrName", value)
    setAddonStatus((prev: any) => ({
      ...prev,
      [attrName]: value,
    }));
    dispatch(TravelSlice.actions.ADDON_DETAILS_DATA(addonStatus));
  };

  const validate = () => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const cus_type = params.get("cus_type");
    const onSuccess = (res: any) => {
      if (!res.error) {
        setLoader(false);
        if (PAGE_STATUS) {
          navigate(
            `${TRAVEL_ROUTE.PROPOSAL_PAGE}/${TRAVEL_ROUTE.PREVIEW_DETAILS}?type=TI&companyCode=${SELECTED_QUOTE_DATA?.companyCode}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productID}`
          );
        } else {
          navigate(
            `${TRAVEL_ROUTE.PROPOSAL_PAGE}/${TRAVEL_ROUTE.ADULT_ONE_DETAILS}`
          );
        }
      }
    };
    const onError = (err: any) => {
      setLoader(false);
      console.log("err", err);
    };
    let param: any = {
      "quote_no": ADD_FORM_RESPONSE.quote_no,
      "insurance_type": "Travel Insurance",
      "product_id": SELECTED_QUOTE_DATA.productID,
      "product_code": SELECTED_QUOTE_DATA.quote_details?.productDetails.product_code,
      "section": "add_on",
      "cover": ADD_FORM.members.value,
      "saveAllDetails": "Yes",
      "finalPremium": SELECTED_QUOTE_DATA.quote_details.premiumDetails.premium,
      "addon": {
        "field_EHA_emergency_hotel": ADDON_DETAILS.field_EHA_emergency_hotel === true ? "1" : "0",//Emergency Hotel Accomodation, Burglary, Loan Protector / if frequency is multitrip then this addons is Mandatory
        "field_LTC_life_threatening": ADDON_DETAILS.field_LTC_life_threatening === true ? "1" : "0",// Life threatening conditions due to PED- 10% /  Mandatory
        "field_RVF_refund_of_visa": ADDON_DETAILS.field_RVF_refund_of_visa === true ? "1" : "0",//Refund of Visa fee (if visa Rejected)
        "field_ASC_adventure_sports_cover": ADDON_DETAILS.field_ASC_adventure_sports_cover === true ? "1" : "0",//Adventure Sports Cover
        "field_SR_staff_replacement": ADDON_DETAILS.field_SR_staff_replacement === true ? "1" : "0",//Staff Replacement
        "field_SC_sports_cover": ADDON_DETAILS.field_SC_sports_cover === true ? "1" : "0"//Sports Cover
      }
    }
    setLoader(true);
    TRAVEL_PROPOSAL_SERVICES.getCapturedRecordData(onSuccess, onError, param);
  };

  useEffect(() => {
    if (ADD_FORM.frequency.value !== "Single") {
      setAddonStatus((prev: any) => ({
        ...prev,
        field_EHA_emergency_hotel: true,
      }));
    }
  }, [])

  return (
    <>
      {QUOTE_LOADER ? (
        <Box
          position="fixed"
          height="100%"
          width="100%"
          zIndex="991"
          top="0px"
          left="0px"
        />
      ) : null}
      {isMobile ? (
        null
      ) : (
        <AddonDetails
          isLoading={loader}
          addonStatus={addonStatus}
          updateMasterState={updateMasterState}
          validate={validate}
          PAGE_STATUS={PAGE_STATUS}
          mandatoryAddon={mandatoryAddon}
          isContinueButtonDisabled={isContinueButtonDisabled}
        />
      )}
    </>
  );
}
