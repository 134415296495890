import { ECompanyCode, EProductId } from "../../../Services/Enum/EHome";
import { useAppSelector } from "../../../Store/hooks";
import BAJAJ_ROUTES from "./HealthProposalRoutes/BAJAJ/BAJAJ_ROUTES";
import { CARE_ROUTES } from "./HealthProposalRoutes/CARE/CARE_ROUTES";
import CIGNA_ROUTES from "./HealthProposalRoutes/CIGNA/CIGNA_ROUTES";
import { COMMON_ROUTES } from "./HealthProposalRoutes/common-php/common-routes";
import GODIGIT_ROUTES from "./HealthProposalRoutes/GODIGIT/GODIGIT_ROUTES";
import { HDFC_ROUTES } from "./HealthProposalRoutes/HDFC/HDFC_ROUTES";
import { ICICI_ROUTES } from "./HealthProposalRoutes/ICICI/ICICI_ROUTES";
import KOTAK_ROUTES from "./HealthProposalRoutes/Kotak/KOTAK_ROUTES";
import NIVABIPA_ROUTES from "./HealthProposalRoutes/niva-bupa/niva-bupa-routes";
import STAR_ROUTES from "./HealthProposalRoutes/STAR/STAR_ROUTES";
import ADITYA_BIRLA_ROUTES from "./HealthProposalRoutes/ADITYA_BIRLA/ADITYA_BIRLA_ROUTES";
import { RSA_ROUTES } from "./HealthProposalRoutes/RSA/RSARoutes";
import { RootState } from "../../../Store/Store";
import { COMMON_ROUTES_NODE } from "./HealthProposalRoutes/common-node/common-routes";

export default function HealthRoutes() {
  const { SELECTED_QUOTE_DATA } = useAppSelector((state) => state.Health);
  const company_code = useAppSelector(
    (state: RootState) =>
      state.Health.SELECTED_QUOTE_DATA.CompanyDetails.company_code
  );
  const { id } = useAppSelector(
    (state: RootState) => state.Health.SELECTED_QUOTE_DATA.productDetails
  );

  return (
    <>
      {`${id}` === "2943" ||
      `${id}` === "2944" ||
      `${id}` === "2945" ||
      `${id}` === "2946" ? (
        <COMMON_ROUTES_NODE />
      ) : (
        <COMMON_ROUTES />
      )}
      {(() => {
        switch (company_code) {
          case ECompanyCode.CARE:
            return <CARE_ROUTES />;
          case ECompanyCode.HDFC_HEALTH:
            return <HDFC_ROUTES />;
          case ECompanyCode.ICIC:
            return <ICICI_ROUTES />;
          case ECompanyCode.STAR:
            return <STAR_ROUTES />;
          case ECompanyCode.GO_DIGIT:
            return <GODIGIT_ROUTES />;
          case ECompanyCode.NIVA_BUPA:
            return <NIVABIPA_ROUTES />;
          case ECompanyCode.BAJAJ:
            return <BAJAJ_ROUTES />;
          case ECompanyCode.ADITY_BIRLA:
            return <ADITYA_BIRLA_ROUTES />;
          case ECompanyCode.KOTAK:
            return <KOTAK_ROUTES />;
          case ECompanyCode.CIGNA:
            return <CIGNA_ROUTES />;
          case ECompanyCode.RSA:
            if (
              ECompanyCode.HDFC_HEALTH ||
              SELECTED_QUOTE_DATA.productDetails.id ===
                EProductId.RSA_LIFELINE_SUPREME ||
              SELECTED_QUOTE_DATA.productDetails.id ===
                EProductId.RSA_LIFELINE_CLASSIC ||
              SELECTED_QUOTE_DATA.productDetails.id ===
                EProductId.RSA_LIFELINE_ELITE
            ) {
              return <RSA_ROUTES />;
            }
            break;
          default:
            return null;
        }
      })()}
    </>
  );
}
