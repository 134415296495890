import { NODE_DOMAIN, PHP_DOMAIN } from "../Domain";

export const CKYC_URLS = {
  CKYC_DROPDOWN_MASTER: `${NODE_DOMAIN}/api/v1/home/ckyc/`,
  get_document_type_list: (company_code: string, doc_for: string) =>
    `${NODE_DOMAIN}/api/v1/home/ckyc/${company_code}/doc/${doc_for}`,
  VERIFY_HDFC_CKYC: `${PHP_DOMAIN}/api/ckyc-ekyc-details/hdfc-ergo.json`,
  VERIFY_FUTURE_GENERALI_CKYC: `${PHP_DOMAIN}/api/ckyc-ekyc-details/future-generali.json`,
  VERIFY_RELIANCE_CKYC: `${PHP_DOMAIN}/api/ckyc-ekyc-details/reliance.json`,
  VERIFY_KOTAK_CKYC: `${PHP_DOMAIN}/api/ckyc-ekyc-details/kotak.json`,
  VERIFY_BAJAJ_CKYC: `${PHP_DOMAIN}/api/ckyc-ekyc-details/bajaj.json`,
  MANUAL_BAJAJ_CKYC: `${PHP_DOMAIN}/api/ckyc-ekyc-details/bajaj-upload-doc.json`,
  VERIFY_ICICI_CKYC: `${PHP_DOMAIN}/api/ckyc-ekyc-details/iciciLombard.json`,
  VERIFY_IFFCO_TOKIO_CKYC: `${PHP_DOMAIN}/api/ckyc-ekyc-details/iffco.json`,
  VERIFY_RSA_CKYC: `${PHP_DOMAIN}/api/ckyc-ekyc-details/royal-sund.json`,
  VERIFY_RSA_HP__CKYC: `${PHP_DOMAIN}/api/ckyc-ekyc-details/royalSundHealth.json`,
  VERIFY_TATA_CKYC: `${PHP_DOMAIN}/api/ckyc-ekyc-details/tata.json`,
  VERIFY_GO_DIGIT_CKYC: `${NODE_DOMAIN}/api/v1/home/kyc/godigit`,
  HDFC_MANUAL_KYC: `${PHP_DOMAIN}/api/ckyc-ekyc-details/kyc-document-lists.json`,
  ICICI_MANUAL_KYC: `${PHP_DOMAIN}/api/ckyc-ekyc-details/icici-kyc-verify-manual.json`,
  IFFCO_TOKIO_MANUAL_KYC: `${PHP_DOMAIN}/api/ckyc-ekyc-details/iffco-create-kyc.json`,
  GET_CKYC_DETAILS_FROM_HDFC: `${PHP_DOMAIN}/api/ckyc-ekyc-details/get-kyc-detail-hdfc.json`,
  GET_CKYC_DETAILS_FROM_KOTAK: `${PHP_DOMAIN}/api/ckyc-ekyc-details/getKycDetailKotak.json`,
  GET_CKYC_DETAILS_FROM_RSA: `${PHP_DOMAIN}/api/ckyc-ekyc-details/getKycDetailRoyalSund.json`,
  VERIFY_CARE_CKYC: `http://localhost:4000/api/v1/kyc/c-kyc-care`,
  VERIFY_ICICI_CKYC_HEALTH: `http://localhost:4000/api/v1/kyc/icici`,
  VERIFY_STAR_CKYC: `http://localhost:4000/api/v1/kyc/c-kyc-star`,
  VERIFY_ADITYA_BIRLA_CKYC: `${PHP_DOMAIN}/api/ckyc-ekyc-details/adityaBirla.json`,
  GET_CITY_BY_PINCODE: `http://localhost:4000/api/v1/health/get-star-city-area`,
  GET_AREA_BY_CITY: `http://localhost:4000/api/v1/health/get-star-city-area`,
  GET_FAMILY_RELATION: `${PHP_DOMAIN}/api/product-nominee-relationship-maps/get-adult-relationship-list.json`,
  VERIFY_STAR_MANUAL_CKYC: `${PHP_DOMAIN}/api/ckyc-ekyc-details/starUploadDoc.json`,
  VERIFY_NIVA_BUPA_CKYC: `${NODE_DOMAIN}/api/v1/kyc/c-kyc-max`,
  VERIFY_CIGNA_CKYC: `${PHP_DOMAIN}/api/ckyc-ekyc-details/cigna.json`,
  VERIFY_CIGNA_MANUAL_CKYC: `${PHP_DOMAIN}/api/ckyc-ekyc-details/cigna-upload-doc.json`,
  VERIFY_BAJAJ_CKYC_HEALTH: `${PHP_DOMAIN}/api/CkycEkycDetails/bajajHealth.json`,
  GET_KYC_DETAIL_ADITYA_BIRLA: `${PHP_DOMAIN}/api/ckyc-ekyc-details/getKycDetailAdityaBirla.json`,
};
