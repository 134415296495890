import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router";
import "../../../../../../SCSS/CommonSCSS/ProposalForms.scss"
import { TDropdown } from "../../../../../../types/TermFullJourney/TDropdown";
import { useAppDispatch, useAppSelector } from "../../../../../../Store/hooks";
import AddonQuestion from "../../../../../../Component/AddonQuestion/AddonQuestion";
import SelectDropdown from "../../../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";
import { TERM_FULL_JOURNEY_ROUTES } from "../../../../../../Router/Path/TermFullJourneyRoutes";
import Footer from "../../../../../../Component/Footer/Footer";
import { TermFullJourneySlice } from "../../../../../../Store/Slice_Reducer/TermFullJourney/TermFullJourneySlice";

function RidersBenefits({
  loaderTf001,
  loaderTf002,
  loaderTf003,
  loaderTf004,
  loaderTf005,
  tf001Data,
  tf002Data,
  tf003Data,
  tf004Data,
  tf005Data,
}: {
  loaderTf001: boolean;
  loaderTf002: boolean;
  loaderTf003: boolean;
  loaderTf004: boolean;
  loaderTf005: boolean;
  tf001Data: TDropdown[];
  tf002Data: TDropdown[];
  tf003Data: TDropdown[];
  tf004Data: TDropdown[];
  tf005Data: TDropdown[];
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { RIDERS_BENEFITS } = useAppSelector((state) => state.TermFullJourney);
  const { SELECTED_QUOTE_DATA } = useAppSelector((state) => state.Term);

  return (
    <Box>
      <h5 className="sectionTitle">Addon Details</h5>
      <Grid container spacing={3}>
        <Grid xs={12}>
            <AddonQuestion
              loader={loaderTf003}
              // addon_amount={
              //   RIDERS_BENEFITS.riders_benefits_response.riders.TF003?.premium
              // }
              question_name="Personal Accident Cover"
              toggle_status={RIDERS_BENEFITS.local_value.tf003Status}
              value_update={(a: any, v: any) => {
                dispatch(
                  TermFullJourneySlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT(
                    {
                      updates: [
                        {
                          key: "tf003Status",
                          value: v,
                        },
                        {
                          key: "tf005Status",
                          value: false,
                        },
                      ],
                    }
                  )
                );
              }}
            />
          <AddonQuestion
            loader={loaderTf001}
            // addon_amount={
            //   RIDERS_BENEFITS.riders_benefits_response.riders.TF001?.premium
            // }
            question_name="60 Critical Illness Cover"
            toggle_status={RIDERS_BENEFITS.local_value.tf001Status}
            value_update={(a: any, v: any) => {
              dispatch(
                TermFullJourneySlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT(
                  {
                    updates: [
                      {
                        key: "tf001Status",
                        value: v,
                      },
                      {
                        key: "tf004Status",
                        value: false,
                      },
                    ],
                  }
                )
              );
            }}
            sub_details={
              <Box sx={{ maxWidth: "250px", paddingBottom: "16px" }}>
                <SelectDropdown
                  attrName={""}
                  title="Select Value"
                  value={RIDERS_BENEFITS.local_value.tf001Value}
                  value_update={(a: any, v: any) => {
                    dispatch(
                      TermFullJourneySlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT(
                        {
                          updates: [
                            {
                              key: "tf001Value",
                              value: v,
                            },
                          ],
                        }
                      )
                    );
                  }}
                  data={tf001Data}
                  warn_status={false}
                />
              </Box>
            }
          />
          <AddonQuestion
            loader={loaderTf002}
            // addon_amount={
            //   RIDERS_BENEFITS.riders_benefits_response.riders.TF002?.premium
            // }
            question_name="Accidental Disability Cover"
            sub_details={
              <Box sx={{ maxWidth: "250px", paddingBottom: "16px" }}>
                <SelectDropdown
                  attrName={""}
                  title="Select Value"
                  value={RIDERS_BENEFITS.local_value.tf002Value}
                  value_update={(a: any, v: any) => {
                    dispatch(
                      TermFullJourneySlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT(
                        {
                          updates: [
                            {
                              key: "tf002Value",
                              value: v,
                            },
                          ],
                        }
                      )
                    );
                  }}
                  data={tf002Data}
                  warn_status={false}
                />
              </Box>
            }
            toggle_status={RIDERS_BENEFITS.local_value.tf002Status}
            value_update={(a: any, v: any) => {
              dispatch(
                TermFullJourneySlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT(
                  {
                    updates: [
                      {
                        key: "tf002Status",
                        value: v,
                      },
                    ],
                  }
                )
              );
            }}
          />
          <AddonQuestion
            loader={loaderTf004}
            // addon_amount={
            //   RIDERS_BENEFITS.riders_benefits_response.riders.TF001?.premium
            // }
            question_name="Cancer Cover"
           
            toggle_status={RIDERS_BENEFITS.local_value.tf004Status}
            value_update={(a: any, v: any) => {
              dispatch(
                TermFullJourneySlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT(
                  {
                    updates: [
                      {
                        key: "tf001Status",
                        value: false,
                      },
                      {
                        key: "tf004Status",
                        value: v,
                      },
                    ],
                  }
                )
              );
            }}
          />
          
            <AddonQuestion
              loader={loaderTf005}
              // addon_amount={
              //   RIDERS_BENEFITS.riders_benefits_response.riders.TF003?.premium
              // }
              question_name="Accidental Death Cover"
              sub_details={
                <Box sx={{ maxWidth: "250px", paddingBottom: "16px" }}>
                  <SelectDropdown
                    attrName={""}
                    title="Select Value"
                    value={RIDERS_BENEFITS.local_value.tf005Value}
                    value_update={(a: any, v: any) => {
                      dispatch(
                        TermFullJourneySlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT(
                          {
                            updates: [
                              {
                                key: "tf005Value",
                                value: v,
                              },
                            ],
                          }
                        )
                      );
                    }}
                    data={tf005Data}
                    warn_status={false}
                  />
                </Box>
              }
              toggle_status={RIDERS_BENEFITS.local_value.tf005Status}
              value_update={(a: any, v: any) => {
                dispatch(
                  TermFullJourneySlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT(
                    {
                      updates: [
                        {
                          key: "tf003Status",
                          value: false,
                        },
                        {
                          key: "tf005Status",
                          value: v,
                        },
                      ],
                    }
                  )
                );
              }}
            />
          <h5 className="mt-10 pl-2">Benefits:</h5>
          <AddonQuestion
            question_name="Death Benefits as Instalment Option"
            toggle_status={RIDERS_BENEFITS.local_value.BNF001Status}
            value_update={(a: any, v: any) => {
              dispatch(
                TermFullJourneySlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT(
                  {
                    updates: [
                      {
                        key: "BNF001Status",
                        value: v,
                      },
                    ],
                  }
                )
              );
            }}
          />
          <AddonQuestion
            question_name="Return of Premium"
            toggle_status={RIDERS_BENEFITS.local_value.BNF002Status}
            value_update={(a: any, v: any) => {
              dispatch(
                TermFullJourneySlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT(
                  {
                    updates: [
                      {
                        key: "BNF002Status",
                        value: v,
                      },
                    ],
                  }
                )
              );
            }}
          />
          <AddonQuestion
            question_name="Waiver of Premium on 60 Critical Illness"
            toggle_status={RIDERS_BENEFITS.local_value.BNF003Status}
            value_update={(a: any, v: any) => {
              dispatch(
                TermFullJourneySlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT(
                  {
                    updates: [
                      {
                        key: "BNF003Status",
                        value: v,
                      },
                    ],
                  }
                )
              );
            }}
          />
        </Grid>
      </Grid>
      <Footer
        forward={() => {
          navigate(TERM_FULL_JOURNEY_ROUTES.PROPOSER_DETAILS);
        }}
      />
    </Box>
  );
}

export default RidersBenefits;
