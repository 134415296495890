import { useState, useEffect } from "react";
import { TBank_Details_CIGNA } from "../../../types/Health/THealthSlice";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import {
  isEmpty,
  validateIFSC,
} from "../../../SupportingFiles/HelpingFunction";
import { HealthSlice } from "../../../Store/Slice_Reducer/Health/HealthSlice";
// import BankDetailsHDFC from "../../../Page/Desktop/Health/HDFC/BankDetails/BankDetails";
import { useNavigate } from "react-router-dom";
import { HEALTH_ROUTE } from "../../../Router/Path/HealthRoutes";
import { HEALTH_PROPOSAL_SERVICES } from "../../../Services/health-php/HealthPropsalServices";
import BankDetails from "../../../Page/Desktop/health-php/CIGNA/BankDetails/BankDetails";

const CignaBankDetailsContainer = () => {
  const { Health } = useAppSelector((state) => state);
  const navigate = useNavigate();
  const { BANK_DETAILS_CIGNA, ADD_FORM_RESPONSE, ADD_FORM, PAGE_STATUS } =
    useAppSelector((state) => state.Health);
  const [disbaledButton, setDisbaledButton] = useState(false);
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const [Bank_Details_CIGNA, SET_Bank_Details_CIGNA] =
    useState<TBank_Details_CIGNA>({
      ...BANK_DETAILS_CIGNA,
    });
  const [detailsFetched, setDetailsFetched] = useState<boolean>(false);

  const setBankDetailsEmpty = () => {
    SET_Bank_Details_CIGNA((prev) => ({
      ...prev,
      BankName: {
        value: "",
        warning: false,
      },
      BranchName: {
        value: "",
        warning: false,
      },
      City: {
        value: "",
        warning: false,
      },
      MicrNum: {
        value: "",
        warning: false,
      },
      State: {
        value: "",
        warning: false,
      },
      BankCd: {
        value: "",
        warning: false,
      },
    }));
  };

  useEffect(() => {
    if (BANK_DETAILS_CIGNA.IfscCd.value) {
      setDetailsFetched(true);
    }
    if (BANK_DETAILS_CIGNA.IfscCd.value.length === 11) {
      setBankDetailsEmpty();
    }
  }, [BANK_DETAILS_CIGNA.IfscCd]);

  const getBankDataByIFSC = (IFSCCODE: string) => {
    const onSuccess = (res: any) => {
      setDetailsFetched(true);
      SET_Bank_Details_CIGNA((prev) => ({
        ...prev,
        BankName: {
          value: res.BANK,
          warning: false,
        },
        BranchName: {
          value: res.BRANCH,
          warning: false,
        },
        City: {
          value: res.CITY,
          warning: false,
        },
        MicrNum: {
          value: res.MICR,
          warning: false,
        },
        State: {
          value: res.STATE,
          warning: false,
        },
        BankCd: {
          value: res.BANKCODE,
          warning: false,
        },
      }));
    };

    const onError = (err: any) => {
      console.log("err..", err);
    };

    let param = {
      IFSCCODE: IFSCCODE,
    };
    if (Bank_Details_CIGNA.IfscCd.value.length === 11) {
      HEALTH_PROPOSAL_SERVICES.GET_CIGNA_BANK_DETAILS(
        onSuccess,
        onError,
        param
      );
    }
  };

  const updateBankDetails = (
    attrName: keyof TBank_Details_CIGNA,
    value: string
  ) => {
    if (attrName == "IfscCd") {
      if (value) {
        if (value.length === 11) {
          getBankDataByIFSC(value);
          // SET_Bank_Details_CIGNA((prevState) => ({
          //   ...prevState,
          //   [attrName]: { ...prevState[attrName], value },
          // }));
        } else {
          setBankDetailsEmpty();
        }
      } else {
        setBankDetailsEmpty();
      }
      SET_Bank_Details_CIGNA((prevState) => ({
        ...prevState,
        [attrName]: { ...prevState[attrName], value },
      }));
    } else {
      SET_Bank_Details_CIGNA((prevState) => ({
        ...prevState,
        [attrName]: {
          value: value,
          warning: false,
        },
      }));
    }
  };
  console.log("Bank_Details_CIGNA", Bank_Details_CIGNA);
  const getCapturedRecordData = () => {
    const onSuccess = (res: any) => {
      console.log("res", res);
    };

    const onError = (err: any) => {
      console.log("err..", err);
    };

    let param = {
      AccountCategoryCd: Bank_Details_CIGNA.AccountCategoryCd.value,
      AccountTypeCd: Bank_Details_CIGNA.AccountTypeCd.value,
      BankCd: Bank_Details_CIGNA.BankCd.value,
      SortCd: Bank_Details_CIGNA.SortCd.value,
      BranchCd: Bank_Details_CIGNA.BranchCd.value,
      BankName: Bank_Details_CIGNA.BankName.value,
      BranchName: Bank_Details_CIGNA.BranchName.value,
      AccountNum: Bank_Details_CIGNA.AccountNum.value,
      LedgerFolioNum: Bank_Details_CIGNA.LedgerFolioNum.value,
      AccountHolderName: Bank_Details_CIGNA.AccountHolderName.value,
      CardTypeCd: Bank_Details_CIGNA.CardTypeCd.value,
      CardExpiryDt: Bank_Details_CIGNA.CardExpiryDt.value,
      IfscCd: Bank_Details_CIGNA.IfscCd.value,
      State: Bank_Details_CIGNA.State.value,
      City: Bank_Details_CIGNA.City.value,
      MicrNum: Bank_Details_CIGNA.MicrNum.value,

      quote_no: ADD_FORM_RESPONSE.quote_no,
      section: "bank_details",
      cover: `${ADD_FORM?.spouse_status ? "2A" : "1A"}${
        (parseInt(ADD_FORM?.son_count?.value) || 0) +
          (parseInt(ADD_FORM?.daughter_count?.value) || 0) >
        0
          ? `${
              (parseInt(ADD_FORM?.son_count?.value) || 0) +
              (parseInt(ADD_FORM?.daughter_count?.value) || 0)
            }C`
          : ""
      }`,
    };
    HEALTH_PROPOSAL_SERVICES.getCapturedRecordData(onSuccess, onError, param);
  };

  const validateForm = () => {
    let bank_details_cigna = { ...Bank_Details_CIGNA };
    bank_details_cigna = {
      ...bank_details_cigna,
      AccountNum: {
        ...bank_details_cigna.AccountNum,
        warning: isEmpty(bank_details_cigna.AccountNum.value),
      },
      AccountHolderName: {
        ...bank_details_cigna.AccountHolderName,
        warning: isEmpty(bank_details_cigna.AccountHolderName.value),
      },
      IfscCd: {
        ...bank_details_cigna.IfscCd,
        warning: isEmpty(bank_details_cigna.IfscCd.value),
      },
    };

    let hasError = false;
    if (
      bank_details_cigna.AccountNum.warning ||
      bank_details_cigna.AccountHolderName.warning ||
      bank_details_cigna.IfscCd.warning
    ) {
      hasError = true;
    }
    SET_Bank_Details_CIGNA({ ...bank_details_cigna });
    console.log("has error", hasError, bank_details_cigna);

    if (!hasError) {
      dispatch(
        HealthSlice.actions.BULK_UPLOAD({
          ...Health,
          BANK_DETAILS_CIGNA: {
            AccountCategoryCd: bank_details_cigna.AccountCategoryCd,
            AccountTypeCd: bank_details_cigna.AccountTypeCd,
            BankCd: bank_details_cigna.BankCd,
            SortCd: bank_details_cigna.SortCd,
            BranchCd: bank_details_cigna.BranchCd,
            BankName: bank_details_cigna.BankName,
            BranchName: bank_details_cigna.BranchName,
            AccountNum: bank_details_cigna.AccountNum,
            LedgerFolioNum: bank_details_cigna.LedgerFolioNum,
            AccountHolderName: bank_details_cigna.AccountHolderName,
            CardTypeCd: bank_details_cigna.CardTypeCd,
            CardExpiryDt: bank_details_cigna.CardExpiryDt,
            IfscCd: bank_details_cigna.IfscCd,
            State: bank_details_cigna.State,
            City: bank_details_cigna.City,
            MicrNum: bank_details_cigna.MicrNum,
          },
          PAGE_STATUS: false,
        })
      );
      getCapturedRecordData();
      if (PAGE_STATUS) {
        navigate(
          `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.PREVIEW_DETAILS}`
        );
      } else {
        navigate(
          `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.ADDRESS_DETAILS}`
        );
      }
    }
  };

  return isMobile ? null : (
    <BankDetails
      disbaledButton={disbaledButton}
      validateForm={validateForm}
      Bank_Details_CIGNA={Bank_Details_CIGNA}
      updateBankDetailsCigna={updateBankDetails}
      detailsFetched={detailsFetched}
    />
  );
};

export default CignaBankDetailsContainer;
