import SentimentDissatisfiedOutlinedIcon from "@mui/icons-material/SentimentDissatisfiedOutlined";
import { Box, Button, Link, Modal, TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { TVerifyCKYCForm } from "../../../../types/CKYC/TCKYC";
import { useAppSelector } from "../../../../Store/hooks";
import "./../../../../SCSS/CommonSCSS/ModalPopup.scss";
import CustomButton from "../../../../Component/CustomButton/CustomButton";
import RKTextField from "../../../../Component/FieldTypes/RKTextField/RKTextField";
import SelectDropdown from "../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";
import DatePicker from "../../../../Component/FieldTypes/DatePicker/DatePicker";
import MFooter from "../../../../Component/MFooter/MFooter";
import SearchDropdown from "../../../../Component/FieldTypes/SearchDropdown/SearchDropdown";
import { subYears } from "date-fns";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { uploadImage } from "../../../../SupportingFiles/HelpingFunction";
import Loader from "../../../../Component/Loader/Loader";
import { CLIENTS } from "../../../../URLCollection/Clients";
import { pushMotorDataLayer, sendEventToNativeApp } from "../../../../utils/analytics";

const MRSACKYC = ({
  updateMasterState,
  formDetails,
  validateForm,
  CONTINUE_TO_PROPOSAL,
  showManualPopup,
  setShowManualPopup,
  showCKYCDetailsPopup,
  setShowCKYCDetailsPopup,
  isLoading,
}: {
  updateMasterState: Function;
  formDetails: TVerifyCKYCForm;
  validateForm: Function;
  CONTINUE_TO_PROPOSAL: Function;
  showManualPopup: boolean;
  setShowManualPopup: Function;
  showCKYCDetailsPopup: boolean;
  setShowCKYCDetailsPopup: Function;
  isLoading: boolean;
}) => {
  const { POV, GENDER, CUSTOMER_TYPE } = useAppSelector(
    (state) => state.CommonDropdownData
  );
  const { MANUAL_KYC_THIRD_PARTY_URL, CKYC_DETAILS } = useAppSelector(
    (state) => state.CKYC
  );
  const { SELECTED_QUOTE_DATA } = useAppSelector((state) => state.Health);

  const [imageName, setImageName] = useState<{
    form_sixety: string;
  }>({ form_sixety: "" });
  const [pageStatus, setPageStatus] = useState<string>("");
  const { Car, TW } = useAppSelector((state) => state);

  useEffect(() => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const type = params.get("type");

    if (type) {
      setPageStatus(type);
    }
  }, []);
  return (
    <>
      {isLoading && <Loader />}
      <Box>
        <Modal
          open={showCKYCDetailsPopup}
          className="modalWrapperMobile mwMobile-kyc"
        >
          <Box className="modalContent">
            <Grid container spacing={2}>
              <Grid xs={12}>
                <h4 className="popup-heading mb-2">CKYC Details</h4>
                <p className="grey_p">
                  We fetch these details as per record, please verify
                </p>
                <Link
                  className="close-button"
                  onClick={() => {
                    setShowCKYCDetailsPopup(false);
                  }}
                />
                <hr />
              </Grid>
            </Grid>
            <Grid container spacing={2} textAlign="center">
              <Grid xs={12} className="">
                <span className="policy_number_box mb-4">
                  {CKYC_DETAILS.kycNo}
                </span>
              </Grid>
            </Grid>
            <Grid container spacing={2} textAlign="center">
              <Grid xs={6}>
                <h6>
                  Full Name:
                  <span>{CKYC_DETAILS.kycCustomerName}</span>
                </h6>
              </Grid>
              <Grid xs={6}>
                <h6>
                  DOB:
                  <span>{CKYC_DETAILS.kycDOB}</span>
                </h6>
              </Grid>
              <Grid xs={12}>
                <h6>
                  Address:
                  <span>{`${CKYC_DETAILS.kycAddress1}, ${CKYC_DETAILS.kycAddress2}, ${CKYC_DETAILS.kycAddress3}, ${CKYC_DETAILS.kycCity}, ${CKYC_DETAILS.kycState}, ${CKYC_DETAILS.kycPincode}`}</span>
                </h6>
              </Grid>
              <Grid xs={12}>
                <hr />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid xs={6} style={{ textAlign: "center" }} className="ctaBtn">
                <CustomButton
                  onClickFunction={() => {
                    setShowCKYCDetailsPopup(false);
                  }}
                  text_name={
                    <>
                      <SentimentDissatisfiedOutlinedIcon className="mr-2" />{" "}
                      It's not me
                    </>
                  }
                  class_name="secondaryBtn"
                  size="medium"
                />
              </Grid>
              <Grid xs={6} style={{ textAlign: "center" }} className="ctaBtn">
                <CustomButton
                  onClickFunction={() => {
                    CONTINUE_TO_PROPOSAL();
                  }}
                  text_name={"Continue"}
                  class_name="primaryBtn"
                  size="medium"
                />
              </Grid>
            </Grid>
          </Box>
        </Modal>

        <Modal
          open={showManualPopup}
          className="modalWrapperMobile mwMobile-kyc"
        >
          <Box className="modalContent">
            <Grid container spacing={2}>
              <Grid xs={12}>
                <h4 className="popup-heading mb-2">CKYC Details</h4>
                <Link
                  className="close-button"
                  onClick={() => {
                    setShowManualPopup(false);
                  }}
                />
                <hr />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid xs={12} className="ctaBtn">
                <h6 className="mb-0">
                  Sorry,we are not getting any information from insurer side,
                  please click below Button to upload or verify with other
                  Document
                </h6>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid xs={12} className="ctaBtn mt-5">
                <hr />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid xs={12} style={{ textAlign: "center" }} className="ctaBtn">
                <CustomButton
                  text_name={"Verify with other Document"}
                  size="medium"
                  class_name="secondaryBtn"
                  onClickFunction={() => {
                    if (window.location.hostname === CLIENTS.JIO) {
                      pushMotorDataLayer(
                        "jioinsure_flow_details",
                        "ckyc details failure",
                        `verify with other account`,
                        pageStatus === "TW"
                          ? "two wheeler"
                          : pageStatus?.toLocaleLowerCase(),
                        pageStatus === "TW"
                          ? TW.REDIRECTED
                            ? "JFS app"
                            : "Insure website"
                          : Car.REDIRECTED
                          ? "JFS app"
                          : "Insure website"
                      );
                      const eventProps = {
                        action: "ckyc",
                        status: "ckyc details failure",
                        click: "verify with other account",
                        type:
                          pageStatus === "TW"
                            ? "two wheeler"
                            : pageStatus?.toLocaleLowerCase(),
                        EntrySource:
                          pageStatus === "TW"
                            ? TW.REDIRECTED
                              ? "JFS app"
                              : "Insure website"
                            : Car.REDIRECTED
                            ? "JFS app"
                            : "Insure website",
                      };
                      sendEventToNativeApp(
                        "jioinsure_flow_details",
                        eventProps
                      );
                    }
                    setShowManualPopup(false);
                  }}
                />
              </Grid>
              <Grid xs={12} style={{ textAlign: "center" }} className="ctaBtn">
                <CustomButton
                  text_name={"Manual KYC"}
                  size="medium"
                  class_name="primaryBtn"
                  onClickFunction={() => {
                    if (window.location.hostname === CLIENTS.JIO) {
                      pushMotorDataLayer(
                        "jioinsure_flow_details",
                        "ckyc details failure",
                        `manual kyc`,
                        pageStatus === "TW"
                          ? "two wheeler"
                          : pageStatus?.toLocaleLowerCase(),
                        pageStatus === "TW"
                          ? TW.REDIRECTED
                            ? "JFS app"
                            : "Insure website"
                          : Car.REDIRECTED
                          ? "JFS app"
                          : "Insure website"
                      );
                      const eventProps = {
                        action: "ckyc",
                        status: "ckyc details failure",
                        click: "verify with other account",
                        type:
                          pageStatus === "TW"
                            ? "two wheeler"
                            : pageStatus?.toLocaleLowerCase(),
                        EntrySource:
                          pageStatus === "TW"
                            ? TW.REDIRECTED
                              ? "JFS app"
                              : "Insure website"
                            : Car.REDIRECTED
                            ? "JFS app"
                            : "Insure website",
                      };
                      sendEventToNativeApp(
                        "jioinsure_flow_details",
                        eventProps
                      );
                    }
                    setShowManualPopup(false);
                    window.location.href = MANUAL_KYC_THIRD_PARTY_URL;
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Modal>

        <h5 className="sectionTitle">CKYC/eKYC Details</h5>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <Grid xs={12}>
            <h6 className="mb-4 question" style={{ fontSize: "16px" }}>
              Provide CKYC Details for Proposer
            </h6>
            <p className="textGrey">
              <span className="textBold">
                CKYC refers to Central KYC (Know Your Customer)
              </span>
              , an initiative of the Government of India. The aim of this
              initiative is to have a structure in place which allows investors
              to complete their KYC only once before interacting with various
              entities across the financial sector.
            </p>
          </Grid>
        </Grid>
        <Grid container columnSpacing={2} rowSpacing={4}>
          <Grid xs={12}>
            <RKTextField
              class_name="inputField"
              title={"Proposer Full Name"}
              value={formDetails.full_name.value}
              attrName={"full_name"}
              validation_type="NAME"
              value_update={updateMasterState}
              warn_status={formDetails.full_name.warning}
              error_message={
                !formDetails?.full_name.value
                  ? "Enter Full Name"
                  : "Enter Valid Full Name"
              }
            />
          </Grid>
          {formDetails.customer_type.value === "Organization" ? (
            <Grid xs={6}>
              <DatePicker
                class_name="inputField"
                title={"Proposer DOI"}
                value={formDetails.dob.value}
                attrName={"dob"}
                value_update={updateMasterState}
                error_message="Select DOI"
                warn_status={formDetails.dob.warning}
                date_validation_type="YEARS"
                min_date={100}
                max_date={0}
                default_date={new Date()}
              />
            </Grid>
          ) : (
            <Grid xs={6}>
              <DatePicker
                class_name="inputField"
                title={"Proposer DOB"}
                value={formDetails.dob.value}
                attrName={"dob"}
                value_update={updateMasterState}
                error_message="Select DOB"
                warn_status={formDetails.dob.warning}
                date_validation_type="YEARS"
                min_date={100}
                max_date={18}
                default_date={subYears(new Date(), 18)}
              />
            </Grid>
          )}
          {formDetails.customer_type.value === "Organization" ? null : (
            <Grid xs={6}>
              <SelectDropdown
                class_name="inputField"
                title="Gender"
                value={formDetails.gender.value}
                attrName={"gender"}
                value_update={updateMasterState}
                data={GENDER}
                warn_status={formDetails.gender.warning}
                error_message="Select Gender"
              />
            </Grid>
          )}
          <Grid xs={12}>
            <SearchDropdown
              class_name="inputField"
              title="Document For Verification"
              value={formDetails.doc_type.value}
              attrName={"doc_type"}
              value_update={updateMasterState}
              data={
                window.location.hostname === CLIENTS.JIO && pageStatus === "CAR"
                  ? [{ label: "Pan Card", value: "PAN" }]
                  : POV
              }
              warn_status={formDetails.doc_type.warning}
              error_message="Select Doc for Verification"
            />
          </Grid>
          <Grid xs={6}>
            <RKTextField
              class_name="inputField"
              title={"Document ID"}
              value={formDetails.doc_id.value}
              attrName={"doc_id"}
              validation_type="ALPHANUMERIC"
              value_update={updateMasterState}
              warn_status={formDetails.doc_id.warning}
              error_message="Enter Doc ID Number"
            />
          </Grid>
          <Grid xs>
            <SelectDropdown
              class_name="inputField"
              title="Customer Type"
              value={formDetails.customer_type.value}
              attrName={"customer_type"}
              value_update={updateMasterState}
              data={CUSTOMER_TYPE}
              warn_status={formDetails.customer_type.warning}
              error_message="Select Customer Type"
            />
          </Grid>

          {SELECTED_QUOTE_DATA?.premiumDetails?.insurance_type ===
            "Health Plans" ||
          (window.location.hostname === CLIENTS.JIO && pageStatus === "CAR") ||
          (window.location.hostname === CLIENTS.JIO &&
            pageStatus === "TW") ? null : (
            <Grid xs={6}>
              <Box className="fieldbox">
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Form 60"
                  variant="outlined"
                  className="uploadField inputField"
                  value={imageName.form_sixety}
                />
                <Button
                  variant="contained"
                  className="browsebtn"
                  component="label"
                >
                  Browse{" "}
                  <input
                    hidden
                    accept="image/*"
                    multiple
                    type="file"
                    onChange={(e: any) => {
                      const file = e.target.files[0];
                      const fileSizeInMB = file.size / (1024 * 1024);

                      if (fileSizeInMB > 6) {
                        toast.error(
                          "File size exceeds the maximum limit of 5 MB."
                        );
                      } else {
                        setImageName({
                          form_sixety: file.name,
                        });
                        uploadImage(
                          file,
                          (base64Image: string, base64String: string) => {
                            updateMasterState("form_sixety", base64String);
                          }
                        );
                      }
                    }}
                  />
                </Button>
                {formDetails.form_sixety.warning ? (
                  <span className="error">Select valid file</span>
                ) : null}
              </Box>
            </Grid>
          )}
        </Grid>
        <MFooter forward={validateForm} loader={isLoading} />
      </Box>
    </>
  );
};

export default MRSACKYC;
