import { Box, Grid, Link, Modal } from "@mui/material";
import NivaBupaSenMedicalQuestion from "../../../../../Component/MedicalQuestion/NivaBupaSenMedicalQuestion";
import { TNivaBupaMedicalQuestion } from "../../../../../types/Health/HealthQuotation/TNivaBupaMedicalQuestion";
import HealthFooter from "../../HealthFooter/HealthFooter";
import Footer from "../../../../../Component/Footer/Footer";

const MedicalDetailNivaBupaSenior = ({
  medicalQuestionData,
  updateMasterState,
  validateForm,
  PAGE_STATUS,
  nstp,
  setNstp,
}: {
  medicalQuestionData: TNivaBupaMedicalQuestion;
  updateMasterState: Function;
  validateForm: Function;
  PAGE_STATUS: boolean;
  nstp: boolean;
  setNstp: Function;
}) => {
  return (
    <Box>
      <h5 className="sectionTitle">Underwriting Details</h5>
      <Modal open={nstp} className="modalWrapper">
        <Box className="modalContent xl-width kycPopups">
          <Grid container spacing={2}>
            <Grid xs={12}>
              <h4 className="popup-heading my-6">Sorry</h4>

              <Link
                className="close-button"
                onClick={() => {
                  setNstp(false);
                }}
              />
              <hr />
            </Grid>
            <Grid xs={12}>
              <p className="grey_p mt-6">
                For your current health status, you are unable to proceed with
                this insurer. Please consider checking with a different insurer.
              </p>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {Object.values(medicalQuestionData).map(
        (data, indexMainQuestion: number) => (
          <div key={indexMainQuestion}>
            <NivaBupaSenMedicalQuestion
              value_update={updateMasterState}
              data={data.main_question}
            />
          </div>
        )
      )}
      <Footer
        textName={PAGE_STATUS ? "Update" : "Continue"}
        forward={() => validateForm()}
      />
    </Box>
  );
};

export default MedicalDetailNivaBupaSenior;
