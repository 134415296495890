import EditIcon from "@mui/icons-material/Edit";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";
import "./AddressPreviewDetailBox.scss";
import { useAppDispatch, useAppSelector } from "../../../../../Store/hooks";
import { pageSlice } from "../../../../../Store/Slice_Reducer/HI/PageSlice/PageSlice";
import { HI_PATH } from "../../../../../Router/Path/HIPath";
import { validatePincode } from "../../../../../SupportingFiles/HelpingFunction";
interface AddressI {
  load?: boolean;
}
const AddressPreviewDetailBox = ({ load }: AddressI) => {
  const { addressDetails } = useAppSelector(
    (state) => state.proposalInformation
  );
  const KYC_RESPONSE_SLICE = useAppSelector((state) => state.kycResponseSlice);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleEditClicked = () => {
    dispatch(pageSlice.actions.UPDATE_PAGE_STATUS(true));

    navigate(`${HI_PATH.Proposal}/${HI_PATH.ADDRESS_DETAILS}`);
  };

  return (
    <Box className="previewBox">
      <Grid container rowSpacing={0} columnSpacing={2}>
        <Grid xs={8}>
          <p className="previewBoxTitle">Address Details</p>
        </Grid>
        <Grid xs={4} textAlign="right">
          {/* <Button onClick={handleEditClicked} className="previewBoxEdit">
            <EditIcon /> Edit
          </Button> */}
          {load ? (
            <Button
              onClick={handleEditClicked}
              className="previewBoxEdit"
              disabled
              style={{ color: "black" }}
            >
              <EditIcon /> Edit
            </Button>
          ) : (
            <Button onClick={handleEditClicked} className="previewBoxEdit">
              <EditIcon /> Edit
            </Button>
          )}
        </Grid>
        <Grid xs={12}>
          <ul>
            <li>
              <p>
                Property Address{" "}
                <span>{`${addressDetails.propertyAddress.address.value}, ${
                  addressDetails.propertyAddress.address2.value
                }, ${" " + addressDetails.propertyAddress.city.value} - ${
                  addressDetails.propertyAddress.state.value
                    ? addressDetails.propertyAddress.state.value
                    : ""
                }, ${addressDetails.propertyAddress.pincode.value}`}</span>
              </p>
            </li>
            {validatePincode(KYC_RESPONSE_SLICE.pincode) ? (
              <li>
                <p>
                  Permanent Address{" "}
                  <span>{`${KYC_RESPONSE_SLICE.address_line_1}, ${
                    KYC_RESPONSE_SLICE.address_line_2
                  },  ${" " + KYC_RESPONSE_SLICE.city} - ${
                    KYC_RESPONSE_SLICE.state ? KYC_RESPONSE_SLICE.state : ""
                  }, ${KYC_RESPONSE_SLICE.pincode}`}</span>
                </p>
              </li>
            ) : null}

            {!addressDetails.isSame ? (
              <li>
                <p>
                  Communication Address{" "}
                  <span>{`${
                    addressDetails.communicationAddress.address.value
                  }, ${addressDetails.communicationAddress.address2.value}, ${
                    addressDetails.communicationAddress.city.value
                  } - ${
                    addressDetails.communicationAddress.state.value
                      ? addressDetails.communicationAddress.state.value
                      : ""
                  },${
                    addressDetails.communicationAddress.pincode.value
                  }`}</span>
                </p>
              </li>
            ) : (
              <Box
                display={"flex"}
                alignItems={"center"}
                marginTop={"4px"}
                border={"1px dashed #ccc"}
                borderRadius={"8px"}
                padding={"4px"}
                // margin={"6px"}
              >
                <TaskAltIcon
                  sx={{
                    color: "#00785A",
                    marginRight: "3px",
                    height: "16px",
                  }}
                />
                <p style={{ fontSize: "12px", color: "#00785A" }}>
                  Mailing / Correspondence address is same as permanent address.
                </p>
              </Box>
            )}
          </ul>
        </Grid>

        {/* <Grid xs={8}>
          <p>Property Address</p>
        </Grid>
        <Grid xs={12}>
          <p>{`${addressDetails.propertyAddress.address.value}, ${
            addressDetails.propertyAddress.address2.value
          }, ${addressDetails.propertyAddress.city.value} - ${
            addressDetails.propertyAddress.state.value
              ? addressDetails.propertyAddress.state.value
              : ""
          },${addressDetails.propertyAddress.pincode.value}`}</p>
        </Grid> */}
      </Grid>

      {/* {validatePincode(KYC_RESPONSE_SLICE.pincode) ? (
        <Grid container spacing={0}>
          <Grid xs={8}>
            <p className="previewBoxTitle">Permanent Address</p>
          </Grid>
          <Grid xs={4} textAlign="right"></Grid>
          <Grid xs={12}>
            <p>{`${KYC_RESPONSE_SLICE.address_line_1}, ${
              KYC_RESPONSE_SLICE.address_line_2
            }, ${KYC_RESPONSE_SLICE.city} - ${
              KYC_RESPONSE_SLICE.state ? KYC_RESPONSE_SLICE.state : ""
            },${KYC_RESPONSE_SLICE.pincode}`}</p>
          </Grid>
        </Grid>
      ) : null}
      {!addressDetails.isSame ? (
        <Grid container spacing={0}>
          <Grid xs={8}>
            <p className="previewBoxTitle">Communication Address</p>
          </Grid>
          <Grid xs={4} textAlign="right"></Grid>
          <Grid xs={12}>
            <p>{`${addressDetails.communicationAddress.address.value}, ${
              addressDetails.communicationAddress.address2.value
            }, ${addressDetails.communicationAddress.city.value} - ${
              addressDetails.communicationAddress.state.value
                ? addressDetails.communicationAddress.state.value
                : ""
            },${addressDetails.communicationAddress.pincode.value}`}</p>
          </Grid>
        </Grid>
      ) : (
        <Box
          display={"flex"}
          alignItems={"center"}
          marginTop={"4px"}
          border={"1px dashed #ccc"}
          borderRadius={"8px"}
          padding={"4px"}
          margin={"6px"}
        >
          <TaskAltIcon
            sx={{
              color: "#00785A",
              marginRight: "3px",
              height: "16px",
            }}
          />
          <p style={{ fontSize: "12px", color: "#00785A" }}>
            Mailing / Correspondence address is same as permanent address.
          </p>
        </Box>
      )} */}
    </Box>
  );
};

export default AddressPreviewDetailBox;
