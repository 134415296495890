import { EVButton } from "@evervent_pvt_ltd/ui-kit";
import * as React from "react";
import { isEmpty } from "../../SupportingFiles/HelpingFunction";
import { CLIENTS } from "../../URLCollection/Clients";
import "./CustomButton.scss";

interface Props {
  id?: any;
  size?: "small" | "medium" | "large";
  class_name?: string;
  text_name: string | any;
  loading?: boolean;
  onClickFunction?: Function;
  disabled?: boolean;
  fullWidth?: boolean;
}

const CustomButton: React.FC<Props> = ({
  id,
  size,
  class_name = "regularPrimaryBtn",
  text_name,
  loading = false,
  onClickFunction = () => {},
  disabled,
  fullWidth = false,
}) => {
  React.useEffect(() => {
    // Get the hostname
    var hostname = window.location.hostname;
    // Get the navbar element
    var navbar = document.querySelector(".mediumPrimaryBtn");

    if (navbar !== null) {
      // Set background color based on hostname
      if (hostname === CLIENTS.AMAZE) {
        (navbar as HTMLElement).style.backgroundColor = "#00785a"; // Replace with your desired color
      } else if (hostname === CLIENTS.JIO) {
        (navbar as HTMLElement).style.backgroundColor = "#DF9F39"; // Replace with your desired color
      } else if (
        hostname === CLIENTS.MOTILAL ||
        hostname === CLIENTS.MOTILAL_PHP
      ) {
        (navbar as HTMLElement).style.backgroundColor = "#ff881c"; // Replace with your desired color
      }
      // Add more conditions for additional hostnames as needed
    }
  }, [window.location.hostname]);
  return (
    <div className="ctaBtn">
      <EVButton
        id={isEmpty(text_name) ? id : text_name}
        onClick={() => {
          if (loading === false) {
            onClickFunction();
          }
        }}
        text={text_name}
        className={class_name}
        loader={loading}
        disabled={disabled}
        fullWidth={fullWidth}
        size={size}
      />
    </div>
  );
};

export default CustomButton;
