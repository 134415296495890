import { Box, Button, Grid, Link, Modal } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { HI_PATH } from "../../../../../Router/Path/HIPath";
import CustomButton from "../../../../CustomButton/CustomButton";

interface Props {
  modalOpen: boolean;
  setModalOpen: any;
}

const KycFailedPopup = ({ modalOpen, setModalOpen }: Props) => {
  const navigate = useNavigate();

  return (
    <Modal open={modalOpen} className="modalWrapper">
      <Box className="modalInner lg-width">
        <>
          <Grid container spacing={2}>
            <Grid xs={12}>
              <h4 className="popup-heading mt-4">CKYC Details</h4>
              <Link
                href="#"
                className="close-button"
                onClick={() => {
                  setModalOpen(false);
                }}
              />
            </Grid>
            <Grid xs={12}>
              <h6 style={{ fontWeight: "400", textAlign: "center" }}>
                Sorry,we are not getting any information from insurer side,
                please click below Button to upload or verify with other
                Document
              </h6>
            </Grid>
            <Grid
              xs={12}
              className="ctaBtn mt-6"
              display="flex"
              justifyContent="center"
              gap="24px"
            >
              {/* <Button
                variant="contained"
                className="regularSecondaryBtn"
                onClick={() => {
                  setModalOpen(false);
                }}
              >
                Verify with other Document
              </Button> */}
              <CustomButton
                text_name="Verify with other Document"
                class_name="secondaryBtn"
                size="large"
                onClickFunction={() => {
                  setModalOpen(false);
                }}
              />

              <CustomButton
                text_name="Manual KYC"
                class_name="primaryBtn"
                size="large"
                onClickFunction={() => {
                  navigate(`${HI_PATH.Proposal}/${HI_PATH.MANUAL_KYC}`);
                  setModalOpen(false);
                }}
              />
            </Grid>
          </Grid>
        </>
      </Box>
    </Modal>
  );
};

export default KycFailedPopup;
