import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PreviewDetails from "../../../../Page/Desktop/Travel/CARE/PreviewDetails/PreviewDetails";
import { TRAVEL_ROUTE } from "../../../../Router/Path/TravelRoutes";
import { CKYCServcies } from "../../../../Services/CKYC/CKYCServices";
import { TRAVEL_PROPOSAL_SERVICES } from "../../../../Services/Travel/TravelPropsalServices";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import { CommonDropdownSlice } from "../../../../Store/Slice_Reducer/CommonDropdown/CommonDropdownSlice";
import { TravelSlice } from "../../../../Store/Slice_Reducer/Travel/TravelSlice";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import { TDropdown } from "../../../../types/Common/TDropdown";
import { TDropdownData } from "../../../../types/DropdownData/DropdownData";

export default function PreviewDetailsContainer() {
  const isMobile = useIsMobile();
  const { NOMINEE_RELATIONSHIP_STATUS, DROPDOWN_DATA } = useAppSelector(
    (state) => state.Travel
  );
  const navigate = useNavigate();
  const { Travel, CKYC, CommonDropdownData } = useAppSelector((state) => state);
  const { AREA_TOWN } = useAppSelector((state) => state.CommonDropdownData);
  const [previewDetails, setPreviewDetails] = useState<Array<any>>([]);
  const [occupationList, setOpccupationList] = useState<TDropdownData[]>([]);
  const PlanTypeOptions = [
    {
      label: "9  CriticalIllnesses covered",
      value: "PLAN1",
    },
    {
      label: "12 CriticalIllnesses covered",
      value: "PLAN2",
    },
    {
      label: "15 CriticalIllnesses covered",
      value: "PLAN3",
    },
    {
      label: "18 CriticalIllnesses covered",
      value: "PLAN4",
    },
    {
      label: "25 CriticalIllnesses covered",
      value: "PLAN5",
    },
    {
      label: "40 CriticalIllnesses covered",
      value: "PLAN6",
    },
    {
      label: "51 CriticalIllnesses coveredb",
      value: "PLAN7",
    },
  ];

  function findLabelByValue(value: string) {
    for (let i = 0; i < PlanTypeOptions.length; i++) {
      if (PlanTypeOptions[i].value === value) {
        return PlanTypeOptions[i].label;
      }
    }
    return null; // Return null if value is not found
  }

  useEffect(() => {
    setPreviewDetails([
      {
        title: "Proposer Details",
        url: `${TRAVEL_ROUTE.PROPOSAL_PAGE}/${TRAVEL_ROUTE.ADULT_ONE_DETAILS}`,
        detailView: [
          {
            label: "Full Name",
            label_value: INSURED_MEMBER_DETAILS.PROPOSER_DETAILS.name.value,
          },
          {
            label: "DOB",
            label_value: INSURED_MEMBER_DETAILS.PROPOSER_DETAILS.dob?.value,
          },
          {
            label: "Gender",
            label_value:
              INSURED_MEMBER_DETAILS.PROPOSER_DETAILS.gender.value === "M"
                ? "Male"
                : "Female",
          },
          {
            label: "Mobile",
            label_value: ADD_FORM.mobile.value,
          },
          {
            label: "Email",
            label_value: INSURED_MEMBER_DETAILS.PROPOSER_DETAILS.email.value,
          },
          {
            label: "Aadhar",
            label_value: INSURED_MEMBER_DETAILS.PROPOSER_DETAILS.aadhar.value,
          },
          INSURED_MEMBER_DETAILS.PROPOSER_DETAILS.pan_card.value
            ? {
                label: "Pan",
                label_value:
                  INSURED_MEMBER_DETAILS.PROPOSER_DETAILS.pan_card.value,
              }
            : null,
          {
            label: "Emergency Phone",
            label_value:
              INSURED_MEMBER_DETAILS.PROPOSER_DETAILS.emergencyPhone.value,
          },
        ],
      },
      {
        title: "1st Adult Details",
        url: `${TRAVEL_ROUTE.PROPOSAL_PAGE}/${TRAVEL_ROUTE.INSURED_MEMBER}`,
        detailView: [
          !INSURED_MEMBER_DETAILS.PROPOSER_DETAILS.IS_PROPOSER_INSURED
            ? {
                label: "Relationship with proposer",
                label_value:
                  INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.relationship.value,
              }
            : {
                label: "Relationship with proposer",
                label_value: "Self",
              },
          {
            label: "Full Name",
            label_value: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
          },
          !INSURED_MEMBER_DETAILS.PROPOSER_DETAILS.IS_PROPOSER_INSURED
            ? {
                label: "DOB",
                label_value: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value,
              }
            : {
                label: "DOB",
                label_value: INSURED_MEMBER_DETAILS.PROPOSER_DETAILS.dob?.value,
              },
          // {
          //   label: "DOB",
          //   label_value:
          //     INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value,
          // },
          !INSURED_MEMBER_DETAILS.PROPOSER_DETAILS.IS_PROPOSER_INSURED
            ? null
            : {
                label: "Gender",
                label_value:
                  INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.gender.value === "F"
                    ? "Female"
                    : "Male",
              },
          !INSURED_MEMBER_DETAILS.PROPOSER_DETAILS.IS_PROPOSER_INSURED
            ? null
            : "",
          // : {
          //     label: "Mobile",
          //     label_value: ADD_FORM.mobile.value,
          //   },
          !INSURED_MEMBER_DETAILS.PROPOSER_DETAILS.IS_PROPOSER_INSURED
            ? null
            : "",
          // {
          //   label: "Email",
          //   label_value: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.email.value,
          // },
          {
            label: "Aadhar",
            label_value: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.aadhar.value,
          },

          // INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.pan_card.value
          //   ? {
          //     label: "Pan",
          //     label_value:
          //       INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.pan_card.value,
          //   }
          //   : {
          //     label: "Pan",
          //     label_value:
          //       INSURED_MEMBER_DETAILS.PROPOSER_DETAILS.pan_card.value,
          //   },

          {
            label: "Emergency Number",
            label_value:
              INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.emergencyPhone.value,
          },
          {
            label: "Weight",
            label_value: `${INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.weight.value} Kg`,
          },
          {
            label: "Height",
            label_value: `${INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.heightFeet.value} Feet ${INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.heightInches.value} Inches`,
          },
          // {
          //   label: "Marital Status",
          //   label_value:
          //     DROPDOWN_DATA.MARTIAL.length !== 0
          //       ? FIND_LABEL_OF_VALUE(
          //         DROPDOWN_DATA.MARTIAL,
          //         INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.maritalStatus.value
          //       )
          //       : INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.maritalStatus.value.split(
          //         "-"
          //       )[1],
          // },
          // {
          //   label: "Occupation",
          //   label_value: FIND_LABEL_OF_VALUE(
          //     DROPDOWN_DATA.OCCUPATION,
          //     INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.occupation.value
          //   ),
          // },
          // {
          //   label: "Pan",
          //   label_value:
          //     INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.pan_card.value,
          // },
          // SELECTED_QUOTE_DATA.productDetails.id === 2551 && {
          //   label: "Annual Income",
          //   label_value:
          //     INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.annual_income.value,
          // },
          {
            label: "Qualification",
            label_value:
              INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.qualification.value,
          },
        ],
      },
      {
        title: "2nd Adult Details",
        url: `${TRAVEL_ROUTE.PROPOSAL_PAGE}/${TRAVEL_ROUTE.ADULT_TWO_DETAILS}`,
        detailView: ADD_FORM.spouse_status
          ? [
              {
                label: "Relationship with proposer",
                label_value:
                  INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.relationship?.value,
              },
              {
                label: "Full Name",
                label_value:
                  INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS?.name.value,
              },
              {
                label: "Height",
                label_value: `${INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS?.heightFeet.value} Feet ${INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS?.heightInches.value} Inches`,
              },
              {
                label: "Weight",
                label_value: `${INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS?.weight.value} Kg`,
              },
              {
                label: "DOB",
                label_value:
                  INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS?.dob?.value,
              },
              // {
              //   label: "Occupation",
              //   label_value:
              //     SELECTED_QUOTE_DATA?.CompanyDetails?.company_code ===
              //       ECompanyCode.NIVA_BUPA
              //       ? FIND_LABEL_OF_VALUE(
              //         DROPDOWN_DATA.OCCUPATION,
              //         INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.occupation
              //           .value
              //       )
              //       : "",
              // },
            ]
          : [],
      },
      {
        title: "1st Child Details",
        url: `${TRAVEL_ROUTE.PROPOSAL_PAGE}/${TRAVEL_ROUTE.CHILD_ONE_DETAILS}`,
        detailView: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value
          ? [
              {
                label: "Relationship with proposer",
                label_value:
                  INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.relationship.value,
              },
              {
                label: "Full Name",
                label_value:
                  INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              },
              {
                label: "Height",
                label_value: `${INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.heightFeet.value} Feet ${INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS?.heightInches.value} Inches`,
              },
              {
                label: "Weight",
                label_value: `${INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS?.weight.value} Kg`,
              },
              {
                label: "DOB",
                label_value:
                  INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS?.dob?.value,
              },
            ]
          : [],
      },
      {
        title: "2nd Child Details",
        url: `${TRAVEL_ROUTE.PROPOSAL_PAGE}/${TRAVEL_ROUTE.CHILD_TWO_DETAILS}`,
        detailView: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value
          ? [
              {
                label: "Relationship with proposer",
                label_value:
                  INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.relationship?.value,
              },
              {
                label: "Full Name",
                label_value:
                  INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              },
              {
                label: "Height",
                label_value: `${INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.heightFeet.value} Feet ${INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.heightInches.value} Inches`,
              },
              {
                label: "Weight",
                label_value: `${INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS?.weight.value} Kg`,
              },
              {
                label: "DOB",
                label_value:
                  INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.dob?.value,
              },
            ]
          : [],
      },
      {
        title: "3rd Child Details",
        url: `${TRAVEL_ROUTE.PROPOSAL_PAGE}/${TRAVEL_ROUTE.CHILD_THREE_DETAILS}`,
        detailView: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value
          ? [
              {
                label: "Relationship with proposer",
                label_value:
                  INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.relationship
                    ?.value,
              },
              {
                label: "Full Name",
                label_value:
                  INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
              },
              {
                label: "Height",
                label_value: `${INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.heightFeet.value} Feet ${INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.heightInches.value} Inches`,
              },
              {
                label: "Weight",
                label_value: `${INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.weight.value} Kg`,
              },
              {
                label: "DOB",
                label_value:
                  INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.dob?.value,
              },
            ]
          : [],
      },
      {
        title: "4th Child Details",
        url: `${TRAVEL_ROUTE.PROPOSAL_PAGE}/${TRAVEL_ROUTE.CHILD_FOUR_DETAILS}`,
        detailView: INSURED_MEMBER_DETAILS?.CHILD_FOUR_DETAILS.name.value
          ? [
              {
                label: "Relationship with proposer",
                label_value:
                  INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.relationship.value,
              },
              {
                label: "Full Name",
                label_value:
                  INSURED_MEMBER_DETAILS?.CHILD_FOUR_DETAILS.name.value,
              },
              {
                label: "Height",
                label_value: `${INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.heightFeet.value} Feet ${INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.heightInches.value} Inches`,
              },
              {
                label: "Weight",
                label_value: `${INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.weight.value} Kg`,
              },
              {
                label: "DOB",
                label_value:
                  INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.dob?.value,
              },
            ]
          : [],
      },
      {
        title: "Nominee Details",
        url: `${TRAVEL_ROUTE.PROPOSAL_PAGE}/${TRAVEL_ROUTE.ADULT_ONE_DETAILS}`,
        detailView:
          NOMINEE_RELATIONSHIP_STATUS === "OTHER"
            ? [
                {
                  label: "Relationship",
                  label_value:
                    `${INSURED_MEMBER_DETAILS.NOMINEE__DETAILS.relationship.value}`.split(
                      "-"
                    )[1],
                },
                {
                  label: "Nominee Name",
                  label_value:
                    INSURED_MEMBER_DETAILS.NOMINEE__DETAILS?.full_name?.value,
                },
                {
                  label: "DOB",
                  label_value:
                    INSURED_MEMBER_DETAILS.NOMINEE__DETAILS?.dob.value,
                },
              ]
            : [
                {
                  label: "Relationship",
                  label_value:
                    INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.relationship.value,
                },
                {
                  label: "Nominee Name",
                  label_value:
                    INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                },
                {
                  label: "DOB",
                  label_value:
                    INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.dob.value,
                },
              ],
      },
      {
        title: "Address Details",
        url: `${TRAVEL_ROUTE.PROPOSAL_PAGE}/${TRAVEL_ROUTE.ADDRESS_DETAILS}`,
        detailView: [
          {
            label: "H.No./Building",
            label_value: ADDRESS_DETAILS.addressLineOne.value,
          },
          // {
          //   label: "Street/Area",
          //   label_value:
          //     SELECTED_QUOTE_DATA.CompanyDetails.company_code === "G015"
          //       ? FIND_LABEL_OF_VALUE(
          //         AREA_TOWN,
          //         ADDRESS_DETAILS.addressLineTwo.value
          //       )
          //       : ADDRESS_DETAILS.addressLineTwo.value,
          // },
          {
            label: "City",
            label_value: ADDRESS_DETAILS.city,
          },
          {
            label: "Pincode",
            label_value: ADDRESS_DETAILS.pincode.value,
          },
        ],
        mailing_detail_view: ADDRESS_DETAILS.isMailingAddressSame
          ? null
          : [
              {
                label: "H.No./Building",
                label_value: ADDRESS_DETAILS.mailingAddressLineOne.value,
              },
              {
                label: "Street/Area",
                label_value: ADDRESS_DETAILS.mailingAddressLineTwo.value,
              },
              {
                label: "City",
                label_value: ADDRESS_DETAILS.mailingCity,
              },
              {
                label: "State",
                label_value: ADDRESS_DETAILS.mailingState,
              },
              {
                label: "Pincode",
                label_value: ADDRESS_DETAILS.mailingPinCode.value,
              },
            ],
      },
    ]);
  }, [Travel]);

  const {
    ADD_FORM,
    SELECTED_QUOTE_DATA,
    ADD_FORM_RESPONSE,
    ADDRESS_DETAILS,
    INSURED_MEMBER_DETAILS,
    QUOTE_LOADER,
  } = useAppSelector((state) => state.Travel);
  const dispatch = useAppDispatch();
  const { CKYC_DETAILS } = useAppSelector((state) => state.CKYC);
  const [loader, setLoader] = useState<boolean>(false);
  const [paymentForm, setPaymentForm] = useState<any>("");

  const [paymentFormData, setPaymentFormData] = useState<any>();

  const CREATE_PROPOSAL = () => {
    const onSuccess = (res: any) => {
      const results = res.results;
      const error = results.error;
      setLoader(false);
      dispatch(
        TravelSlice.actions.PAYMENT_DATA(results.proposalResponse.response)
      );
      const make_payment = (results: any) => {
        // Dynamically create the form element
        const form = document.createElement("form");
        form.action =
          "https://apiuat.religarehealthinsurance.com/portalui/PortalPayment.run";
        form.method = "post";
        form.id = "religare";
        form.name = "PAYMENTFORM";
        const proposalInput = document.createElement("input");
        proposalInput.type = "hidden";
        proposalInput.name = "proposalNum";
        proposalInput.value = results.proposalResponse.response.proposal_no;
        const returnURLInput = document.createElement("input");
        returnURLInput.type = "hidden";
        returnURLInput.name = "returnURL";
        returnURLInput.value = "www.google.com";
        form.appendChild(proposalInput);
        form.appendChild(returnURLInput);
        document.body.appendChild(form);
        form.submit();
        document.body.appendChild(form);
        form.submit();
      };
      make_payment(results);
    };

    const onError = () => {
      setLoader(false);
    };

    const params = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      insurance_type: "Travel Insurance",
      product_code:
        SELECTED_QUOTE_DATA.quote_details.productDetails.product_code,
    };
    setLoader(true);
    TRAVEL_PROPOSAL_SERVICES.makePayment(onSuccess, onError, params);
  };

  useEffect(() => {
    dispatch(TravelSlice.actions.CHANGE_PAGE_STATUS(true));
  }, []);

  return isMobile ? null : (
    <PreviewDetails
      PreviewDetails={previewDetails}
      navigate={navigate}
      CREATE_PROPOSAL={CREATE_PROPOSAL}
      loader={loader}
      paymentForm={paymentForm}
      paymentFormData={paymentFormData}
    />
  );
}
