import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../Store/hooks";
import {
  formatAmountToCurrency,
  formatToCurrency,
} from "../../SupportingFiles/HelpingFunction";

const HealthLeftSidebar = () => {
  const [totalChild, setTotalChild] = useState<number>(0);
  const { ADD_FORM, QUOTE_LOADER, SELECTED_QUOTE_DATA } = useAppSelector(
    (state) => state.Health
  );
  
  useEffect(() => {
    const total_child: number = ADD_FORM.children;

    setTotalChild(total_child);
  }, [ADD_FORM]);
  
  return (
    <Grid xs className="proposalLeftSidebar">
      <Box className={"healthIcon"}></Box>
      <h6 className="mb-2">
        {ADD_FORM.spouse_status ? "2 Adults" : "1 Adult"}
        {totalChild === 0 ? null : `,`}
        <span className="p-1">
          {totalChild === 0
            ? null
            : totalChild === 1
              ? `${totalChild} Child`
              : `${totalChild} Children`}
        </span>
      </h6>
      <img
        alt=""
        className="insurerLogo"
        src={SELECTED_QUOTE_DATA?.CompanyDetails?.logo}
      />
      <h6 className="mb-6">
        {SELECTED_QUOTE_DATA.productDetails?.product_name}
      </h6>

      <h5>
        {formatToCurrency(
          Number(SELECTED_QUOTE_DATA.premiumDetails.sum_insured)
        )}
      </h5>
      <p className="mb-6">Sum Insured</p>
      <Box>
        <h5>
          {SELECTED_QUOTE_DATA.premiumDetails.term === 1
            ? "1 Year"
            : `${SELECTED_QUOTE_DATA.premiumDetails.term} Year's`}
        </h5>
        <p className="mb-6">Policy Tenure</p>
      </Box>
      <h5 className="premium">
        {QUOTE_LOADER ? (
          <img src="../images/loader.svg" alt="" height="12px" />
        ) : (
          <>{formatAmountToCurrency(Number(SELECTED_QUOTE_DATA?.premiumDetails?.finalPremium))}</>
        )}
      </h5>
      <p className="mb-6">Premium (Incl. GST)</p>
    </Grid>
  );
};

export default HealthLeftSidebar;
