import { Route, Routes } from "react-router-dom";
import GoDigitCKYCContainer from "../../../../../Container/CKYC/GoDigit/GoDigitCKYCContainer";
import AddonDetailsContainer from "../../../../../Container/health-php/GODIGIT/AddonDetailsContainer";
import MedicalDetailsContainerComp from "../../../../../Container/health-php/GODIGIT/MedicalDetailsContainerGoDigit/MedicalDetailsContainerComp";
import { CKYC_ROUTES } from "../../../../Path/CKYCRoutes";
import { HEALTH_ROUTE } from "../../../../Path/HealthRoutes";
import HealthProposalContainer from "../../HealthProposalContainer";

const GODIGIT_ROUTES = () => {
  return (
    <Routes>
      <Route
        path={HEALTH_ROUTE.PROPOSAL_PAGE}
        element={<HealthProposalContainer />}
      >
        <Route path={CKYC_ROUTES.CKYC} element={<GoDigitCKYCContainer />} />
        <Route
          path={HEALTH_ROUTE.ADDON_DETAILS}
          element={<AddonDetailsContainer />}
        />
        <Route
          path={HEALTH_ROUTE.MEDICAL_DETAILS}
          element={<MedicalDetailsContainerComp />}
        />
      </Route>
    </Routes>
  );
};

export default GODIGIT_ROUTES;
