export enum TRAVEL_ROUTE {
    QOUTE_PAGE = "/quote/travel-quote",
    PROPOSAL_PAGE = "/travel-insurance",
    KYC_DETAILS = "travel-kyc-details",
    ADDON_DETAILS = "travel-addon-details",
    ADULT_ONE_DETAILS = "travel-adult-one-details",
    INSURED_MEMBER = "travel-adult-one-details_members",
    NOMINEE_DETAILS = "travel-noninee-details",
    ADULT_TWO_DETAILS = "travel-adult-two-details",
    CHILD_ONE_DETAILS = "traveller-three-details",
    CHILD_TWO_DETAILS = "traveller-four-details",
    CHILD_THREE_DETAILS = "traveller-five-details",
    CHILD_FOUR_DETAILS = "traveller-six-details",
    ADDRESS_DETAILS = "travel-address-details",
    MEDICAL_DETAILS = "travel-medical-details",
    PREVIEW_DETAILS = "travel-preview-details",
}


