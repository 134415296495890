import { Route, Routes } from "react-router-dom";
import PaymentConfirmationPageContainer from "../../../../../Container/TermFullJourney/ICICI/PaymentConfirmationPage/PaymentConfirmationPageContainer";
import PolicyConfirmationPageContainer from "../../../../../Container/TermFullJourney/ICICI/PolicyConfirmationPage/PolicyConfirmationPageContainer";
import AddressDetailsContainer from "../../../../../Container/TermFullJourney/ICICI/ProposalPage/AddressDetailsContainer/AddressDetailsContainer";
import CKYCContainer from "../../../../../Container/TermFullJourney/ICICI/ProposalPage/CKYCContainer/CKYCContainer";
import JourneyRequirementContainer from "../../../../../Container/TermFullJourney/ICICI/ProposalPage/JourneyRequirementContainer/JourneyRequirementContainer";
import MedicalPageContainer from "../../../../../Container/TermFullJourney/ICICI/ProposalPage/MedicalPageContainer/MedicalPageContainer";
import NRIDetailsContainer from "../../../../../Container/TermFullJourney/ICICI/ProposalPage/NRIDetailsContainer/NRIDetailsContainer";
import NomineeDetailsContainer from "../../../../../Container/TermFullJourney/ICICI/ProposalPage/NomineeDetailsConatiner/NomineeDetailsContainer";
import PreviewPageContainer from "../../../../../Container/TermFullJourney/ICICI/ProposalPage/PreviewPageContainer/PreviewPageContainer";
import ProposerDetailsContainer from "../../../../../Container/TermFullJourney/ICICI/ProposalPage/ProposerDetailsContainer/ProposerDetailsContainer";
import UploadDocumentContainer from "../../../../../Container/TermFullJourney/ICICI/ProposalPage/UploadDocumentContainer/UploadDocumentContainer";
import { TERM_FULL_JOURNEY_ROUTES } from "../../../../Path/TermFullJourneyRoutes";
import RidersBenefitsContainer from "../../../../../Container/TermFullJourney/ICICI/ProposalPage/RiderBenefitsContainer/RidersBenefitsContainer";

const ICICI_ROUTES_FJ = () => {
  return (
    <Routes>
      {/* <Route path={TW_ROUTES.OWNER_DETAILS} element={<TWRoutes />} /> */}
      <Route
        path={TERM_FULL_JOURNEY_ROUTES.RIDERS_BENEFITS}
        element={<RidersBenefitsContainer />}
      />
      <Route
        path={TERM_FULL_JOURNEY_ROUTES.PROPOSER_DETAILS}
        element={<ProposerDetailsContainer />}
      />
      <Route
        path={TERM_FULL_JOURNEY_ROUTES.COFIRMATION_PAGE}
        element={<PaymentConfirmationPageContainer />}
      />
      <Route
        path={TERM_FULL_JOURNEY_ROUTES.JOURNEY_REQUIREMENT}
        element={<JourneyRequirementContainer />}
      />
      <Route
        path={TERM_FULL_JOURNEY_ROUTES.NOMINEE_DETAILS}
        element={<NomineeDetailsContainer />}
      />
      <Route
        path={TERM_FULL_JOURNEY_ROUTES.ADDRESS_DETAILS}
        element={<AddressDetailsContainer />}
      />
      <Route path={TERM_FULL_JOURNEY_ROUTES.CKYC} element={<CKYCContainer />} />
      <Route
        path={TERM_FULL_JOURNEY_ROUTES.UPLOAD_DOCUMENT}
        element={<UploadDocumentContainer />}
      />
      <Route
        path={TERM_FULL_JOURNEY_ROUTES.MEDICAL_PAGE}
        element={<MedicalPageContainer />}
      />
      <Route
        path={TERM_FULL_JOURNEY_ROUTES.PREVIEW_PAGE}
        element={<PreviewPageContainer />}
      />
      <Route
        path={TERM_FULL_JOURNEY_ROUTES.POLICY_CONFIRMATION}
        element={<PolicyConfirmationPageContainer />}
      />
      <Route
        path={TERM_FULL_JOURNEY_ROUTES.NRI_DETAILS}
        element={<NRIDetailsContainer />}
      />
    </Routes>
  );
};

export default ICICI_ROUTES_FJ;
