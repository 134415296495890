import { Box, Modal, CircularProgress, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useAppSelector } from "../../Store/hooks";
import CustomButton from "../CustomButton/CustomButton";
import { COLORS } from "../../SupportingFiles/colors";

function MHealthRedirectPopup({
  open,
  setOpen,
  form,
}: {
  open: boolean;
  setOpen: Function;
  form: any;
}) {
  const { CIS_DATA, PAYMENT_DATA } = useAppSelector((state) => state.Health);
  const pdfUrl = CIS_DATA.document_url;

  const handleViewClick = () => {
    window.open(pdfUrl, "_blank");
  };

  return (
    <Modal open={open} className="modalWrapperMobile">
      <Box className="modalContent xsWidth kycPopups">
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Link
              className="close-button"
              onClick={() => {
                setOpen(false);
              }}
            />
          </Grid>
        </Grid>
        {pdfUrl === "" ? (
          <Grid container spacing={2} paddingTop={"50px"}>
            <Grid xs={12}>
              <p
                style={{
                  textAlign: "center",
                  marginBottom: "24px",
                  fontSize: "18px",
                }}
              >
                CIS Document failed to fetch, please try again
              </p>
            </Grid>
          </Grid>
        ) : (
          <>
            <Grid container spacing={2} paddingTop={"50px"}>
              <Grid xs={12}>
                <p
                  style={{
                    textAlign: "center",
                    marginBottom: "24px",
                    fontSize: "18px",
                  }}
                >
                  You can{" "}
                  <Link
                    sx={{ color: COLORS.primary, fontWeight: 700 }}
                    onClick={handleViewClick}
                  >
                    View
                  </Link>{" "}
                  and see our CIS Documents
                </p>

                <hr />
              </Grid>
            </Grid>
            <Grid container spacing={2} textAlign="center">
              <Grid xs={12} className=""></Grid>
            </Grid>
            <Grid container columnSpacing={3} marginTop={"24px"}>
              <Grid xs={12} style={{ textAlign: "center" }}>
                <CustomButton
                  text_name={"MAKEPAYMENT"}
                  class_name="primaryBtn"
                  size="medium"
                  onClickFunction={() => {
                    setOpen(false);
                    form.submit();
                  }}
                />
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </Modal>
  );
}

export default MHealthRedirectPopup;
