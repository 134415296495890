import ArrowLeftSharpIcon from "@mui/icons-material/ArrowLeftSharp";
import { Alert, AlertTitle, Box, Button, Link, TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  isEmpty,
  uploadImage,
  validateFullName,
} from "../../../../../../SupportingFiles/HelpingFunction";
import { useAppDispatch, useAppSelector } from "../../../../../../Store/hooks";
import { TDigit_Manual_Ckyc_Verify } from "../../../../../../types/HI/TProposal";
import { HI_PATH } from "../../../../../../Router/Path/HIPath";
import { PROPOSAL_SERVICES } from "../../../../../../Services/HI/ProposalService";
import { proposalSlice } from "../../../../../../Store/Slice_Reducer/HI/Proposal/ProposalSlice";
import { toast } from "react-toastify";
import { Manual_CKYC_DTO } from "../../../../../../DTO/HIDTO/ProposalDTO";
import RKTextField from "../../../../../../Component/FieldTypes/RKTextField/RKTextField";
import DatePicker from "../../../../../../Component/FieldTypes/DatePicker/DatePicker";
import SelectDropdown from "../../../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";
import Footer from "../../../../../../Component/HI/Desktop/ProposalComponents/Footer/Footer";
import CustomButton from "../../../../../../Component/CustomButton/CustomButton";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import { TDropdown } from "../../../../../../types/Common/TDropdown";

const gender_data = [
  { value: "M", label: "Male" },
  { value: "F", label: "Female" },
];

const ManualKYC = () => {
  const state = useAppSelector((state) => state);
  const navigate = useNavigate();

  const location = useLocation();

  const dispatch = useAppDispatch();

  const [kycDetails, setKycDetails] = useState<TDigit_Manual_Ckyc_Verify>({
    propFullName: { value: "", warning: false },
    ProposerDob: { value: null, warning: false },
    gender: { value: "", warning: false },
    doiType: { value: "", warning: false },
    doaType: { value: "", warning: false },
    doifile: "",
    doafile: "",
  });
  const [docList, setDocList] = useState<Array<TDropdown>>([]);
  const [addDocList, setAddDocList] = useState<Array<TDropdown>>([]);

  const [selectedAddressDoc, setSelectedAddressDoc] = useState<string>("");

  const [selectedIdDoc, setSelectedIdDoc] = useState<string>("");

  const [displayData, setDisplayData] = useState<any>({
    doc: "",
    warning: false,
    description: "",
    warnDescription: false,
    doc_name: "",
  });

  const [displayDataAddDoc, setDisplayDataAddDoc] = useState<any>({
    doc: "",
    warning: false,
    description: "",
    warnDescription: false,
    doc_name: "",
  });

  useEffect(() => {
    getVerificationDocuments();
    getDocumentsList();
    getAddressDocumentsList();
    setKycDetails(state.proposalInformation.manualCkycDigit);
  }, [
    state.proposalInformation.policy_information.CompanyDetails.company_code,
  ]);

  const getVerificationDocuments = () => {
    const onSuccess = (res: any) => {
      if (location.pathname.includes(HI_PATH.ProposalIcici)) {
        const data = res.data.data.map((item: any) => {
          return {
            value: item.doc_code,
            label: item.document,
          };
        });

        setDocList(data);
        setAddDocList(data);
      } else {
        const data = res.data.data.map((item: any) => {
          return {
            value: item.doc_code,
            label: item.document,
          };
        });

        const identityDocs: Array<TDropdown> = [...docList, ...data];
        const addDocs: Array<TDropdown> = [...addDocList, ...data];

        setDocList(identityDocs);
        setAddDocList(addDocs);
      }
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    PROPOSAL_SERVICES.get_document_type_list(
      onError,
      onSuccess,
      state.proposalInformation.policy_information.CompanyDetails.company_code,
      "POV"
    );
  };

  const getDocumentsList = () => {
    const onSuccess = (res: any) => {
      setDocList(
        res.data.data.map((item: any) => {
          return {
            value: item.doc_code,
            label: item.document,
          };
        })
      );
    };

    const onError = (err: any) => {};

    PROPOSAL_SERVICES.get_document_type_list(
      onError,
      onSuccess,
      state.proposalInformation.policy_information.CompanyDetails.company_code,
      "POI"
    );
  };

  const getAddressDocumentsList = () => {
    const onSuccess = (res: any) => {
      setAddDocList(
        res.data.data.map((item: any) => {
          return {
            key: item.doc_code,
            value: item.document,
          };
        })
      );
    };

    const onError = (err: any) => {};

    PROPOSAL_SERVICES.get_document_type_list(
      onSuccess,
      onError,
      state.proposalInformation.policy_information.CompanyDetails.company_code,
      "POA"
    );
  };

  const updateMasterState = (attrName: any, value: any) => {
    let valueToUse = value;
    let warnUser = false;

    if (attrName[0] == "doiType") {
      setSelectedIdDoc(value);
    }

    if (attrName[0] == "doaType") {
      setSelectedAddressDoc(value);
    }

    if (attrName[3] === setKycDetails) {
      dispatch(
        proposalSlice.actions.setManualKycInfo({
          ...state.proposalInformation.manualCkycDigit,
          [attrName[0]]: {
            [attrName[1]]: valueToUse,
            warning: warnUser,
          },
        })
      );
    }

    attrName[3]({
      ...attrName[2],
      [attrName[0]]: { [attrName[1]]: valueToUse, warning: warnUser },
    });
  };

  const check_form_validation = () => {
    let data = { ...kycDetails };

    data = {
      ...data,
      propFullName: {
        ...data.propFullName,
        warning: !validateFullName(data.propFullName.value),
      },
      ProposerDob: {
        ...data.ProposerDob,
        warning: isEmpty(data.ProposerDob.value),
      },
      doiType: {
        ...data.doiType,
        warning: isEmpty(data.doiType.value),
      },
      doaType: {
        ...data.doaType,
        warning: isEmpty(data.doaType.value),
      },
      gender: {
        ...data.gender,
        warning: isEmpty(data.gender.value),
      },
    };

    setKycDetails({ ...data });
    dispatch(
      proposalSlice.actions.setManualKycInfo({
        ...data,
      })
    );
    if (
      data.propFullName.warning ||
      data.ProposerDob.warning ||
      data.doiType.warning ||
      data.doaType.warning ||
      data.gender.warning ||
      !isEmpty(data.doifile) ||
      !isEmpty(data.doafile)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const validate_form = () => {
    const hasError = check_form_validation();
    if (!hasError) {
      const loadingToast = toast.loading("Verifying...");

      const onSuccess = (res: any) => {
        toast.dismiss(loadingToast);
        toast.success("Successfully Verified!");

        if (location.pathname.includes(HI_PATH.ProposalIcici)) {
          navigate(`${HI_PATH.Proposal}/${HI_PATH.ADD_ONS}`);
          dispatch(
            proposalSlice.actions.setDigitKycId(
              res.data.response.kyc_details.il_kyc_ref_no
            )
          );
        } else {
          navigate(`${HI_PATH.Proposal}/${HI_PATH.IN_BUILT_COVERAGES}`);
          dispatch(
            proposalSlice.actions.setDigitKycId(res.data.response.digitKYCId)
          );
        }
      };

      const onError = (err: any) => {
        toast.dismiss(loadingToast);
        toast.error("KYC Verification failed, Please try Again");
      };

      const data: Manual_CKYC_DTO = {
        address_doc_type:
          state.proposalInformation.manualCkycDigit.doaType.value,
        address_file: state.proposalInformation.manualCkycDigit.doafile,
        dob: state.proposalInformation.manualCkycDigit.ProposerDob.value
          ? format(
              new Date(
                state.proposalInformation.manualCkycDigit.ProposerDob.value
              ),
              "yyyy-MM-dd"
            )
          : "",
        doc_file: state.proposalInformation.manualCkycDigit.doifile,
        doc_type: state.proposalInformation.manualCkycDigit.doiType.value,
        gender: state.proposalInformation.manualCkycDigit.gender.value,
        quote_no: state.quote.quote_id,
      };
      PROPOSAL_SERVICES.manual_kyc_digit(onSuccess, onError, data);
    } else {
      toast.error("Please fill all fields.");
    }
  };

  return (
    <Box>
      <h5 className="sectionTitle">Manual CKYC Details</h5>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <h6 className="mb-2 question">Provide KYC Details for Proposer</h6>
          <Box
            sx={{
              display: "inline-flex",
              alignItems: "center",
              border: "1px solid #000",
              padding: "8px 12px",
              borderRadius: "8px",
              margin: "12px 0px",
              cursor: "pointer",
            }}
          >
            <Link
              className="backStep"
              style={{ color: "#1a1a1a" }}
              onClick={() => {
                let routeToUse = HI_PATH.Proposal;

                if (location.pathname.includes(HI_PATH.ProposalIcici)) {
                  routeToUse = HI_PATH.ProposalIcici;
                }

                navigate(
                  routeToUse +
                    "/" +
                    state.quote.quote_id +
                    "/" +
                    HI_PATH.VERIFY_CKYC
                );
              }}
            >
              <ReplayOutlinedIcon
                style={{
                  height: "18px",
                  width: "18px",
                  position: "relative",
                  top: "4px",
                }}
              />{" "}
              Try CKYC with another document
            </Link>
          </Box>

          <p className="textGrey ckyc_p">
            <span className="textBold">
              CKYC refers to Central KYC (Know Your Customer)
            </span>
            , an initiative of the Government of India. The aim of this
            initiative is to have a structure in place which allows investors to
            complete their KYC only once before interacting with various
            entities across the financial sector.
          </p>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid xs={6}>
          <RKTextField
            class_name="inputField"
            title={"Proposer Full Name"}
            validation_type="NAME"
            value={state.proposalInformation.manualCkycDigit.propFullName.value}
            attrName={["propFullName", "value", kycDetails, setKycDetails]}
            value_update={updateMasterState}
            warn_status={
              state.proposalInformation.manualCkycDigit.propFullName.warning
            }
            error_message={
              isEmpty(
                state.proposalInformation.manualCkycDigit.propFullName.value
              )
                ? "Enter Full Name"
                : "Enter Valid Full Name"
            }
          />
        </Grid>
        <Grid xs={6}>
          <DatePicker
            class_name="inputField"
            title={"Proposer DOB"}
            value={state.proposalInformation.manualCkycDigit.ProposerDob.value}
            attrName={["ProposerDob", "value", kycDetails, setKycDetails]}
            value_update={updateMasterState}
            error_message="Select DOB"
            min_date={100}
            max_date={18}
            date_validation_type="YEARS"
            warn_status={
              state.proposalInformation.manualCkycDigit.ProposerDob.warning
            }
          />
        </Grid>
        <Grid xs={6}>
          <SelectDropdown
            class_name="inputField"
            title="Document For Identification"
            value={state.proposalInformation.manualCkycDigit.doiType.value}
            attrName={["doiType", "value", kycDetails, setKycDetails]}
            value_update={updateMasterState}
            data={docList}
            warn_status={
              state.proposalInformation.manualCkycDigit.doiType.warning
            }
            error_message="Select Doc. for Identification"
          />
        </Grid>
        <Grid style={{ position: "relative" }} xs={6}>
          <Box className="fieldbox">
            <TextField
              fullWidth
              disabled
              id="outlined-basic"
              label="Identity Document"
              variant="outlined"
              className="uploadField inputField"
              value={displayData.doc_name}
            />
            <Button variant="contained" className="browsebtn" component="label">
              Browse{" "}
              <input
                hidden
                accept="image/*"
                multiple
                type="file"
                onChange={(e: any) => {
                  const file = e.target.files[0];
                  uploadImage(file, (base64Image: any, base64String: any) => {
                    dispatch(proposalSlice.actions.setDoiFile(base64Image));
                    setDisplayData((prevDisplayData: any) => ({
                      ...prevDisplayData,
                      warning:
                        base64Image === "" || prevDisplayData.data === ""
                          ? true
                          : false,
                      doc: base64Image,
                      doc_name: e.target.files[0]?.name,
                      description: prevDisplayData.data,
                    }));
                  });
                }}
              />
            </Button>
            {/* {formDetails.identityDocument.warning ? (
                <span className="error">Select valid file</span>
              ) : null} */}
          </Box>
        </Grid>

        <Grid xs={6}>
          <SelectDropdown
            class_name="inputField"
            title="Document For Address"
            value={state.proposalInformation.manualCkycDigit.doaType.value}
            attrName={["doaType", "value", kycDetails, setKycDetails]}
            value_update={updateMasterState}
            data={addDocList}
            warn_status={
              state.proposalInformation.manualCkycDigit.doaType.warning
            }
            error_message={"Select Doc for Address"}
          />
        </Grid>
        <Grid style={{ position: "relative" }} xs={6}>
          <Box className="fieldbox">
            <TextField
              fullWidth
              disabled
              id="outlined-basic"
              label="Address Document"
              variant="outlined"
              className="uploadField inputField"
              value={displayDataAddDoc.doc_name}
            />
            <Button variant="contained" className="browsebtn" component="label">
              Browse{" "}
              <input
                hidden
                accept="image/*"
                multiple
                type="file"
                onChange={(e: any) => {
                  const file = e.target.files[0];
                  uploadImage(file, (base64Image: any) => {
                    dispatch(proposalSlice.actions.setDoaFile(base64Image));
                    setDisplayDataAddDoc((prevDisplayData: any) => ({
                      ...prevDisplayData,
                      warning:
                        base64Image === "" || prevDisplayData.data === ""
                          ? true
                          : false,
                      doc: base64Image,
                      doc_name: e.target.files[0]?.name,
                      description: prevDisplayData.data,
                    }));
                  });
                }}
              />
            </Button>
            {/* {formDetails.identityDocument.warning ? (
                <span className="error">Select valid file</span>
              ) : null} */}
          </Box>
        </Grid>
        <Grid xs={6}>
          <SelectDropdown
            class_name="inputField"
            title="Gender"
            value={state.proposalInformation.manualCkycDigit.gender.value}
            attrName={["gender", "value", kycDetails, setKycDetails]}
            value_update={updateMasterState}
            data={gender_data}
            warn_status={
              state.proposalInformation.manualCkycDigit.gender.warning
            }
          />
        </Grid>
      </Grid>

      <Alert style={{ margin: 14, marginTop: 20 }} severity="info">
        <AlertTitle>Note:</AlertTitle>
        1. Document must include front and back side into single file, excluding
        PAN. <br /> 2. File size should be less than 1MB.
      </Alert>
      <Footer continue_function={validate_form} />
    </Box>
  );
};

export default ManualKYC;
