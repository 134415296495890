import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Route, Routes } from "react-router-dom";
import Navbar from "../../../../Component/Navbar/Navbar";
import MTermNavbar from "../../../../Component/Term/MTermNavbar/MTermNavbar";
import TermLeftSidebar from "../../../../Component/Term/TermLeftSidebar/TermLeftSidebar";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import { TERM_ROUTES } from "../../../Path/TermRoutes";
import RidersBenefitsContainer from "../../../../Container/Term/Godigit/RidersBenefitsContainer";
import BasicDetailsContainer from "../../../../Container/Term/Godigit/BasicDetailsContainer";

function GodigitRoutes() {
  const isMobile = useIsMobile();

  const MAX_LIFE_ROUTES = () => {
    return (
      <Routes>
        <Route
          path={TERM_ROUTES.RIDERS_BENEFITS}
          element={<RidersBenefitsContainer />}
        />
        <Route
          path={TERM_ROUTES.BASIC_DETAILS}
          element={<BasicDetailsContainer />}
        />
      </Routes>
    );
  };
  return (
    <>
      {isMobile ? (
        <Box className="proposalWrapperMobile">
          <MTermNavbar />
          <Grid container spacing={0} className="d-non">
            <Grid xs={12} className="proposalContentSection">
              {MAX_LIFE_ROUTES()}
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box className="proposalWrapper">
          <Navbar />
          <Grid container spacing={3} className="pb-0">
            <TermLeftSidebar />
            <Grid xs className="proposalContentSection">
              {MAX_LIFE_ROUTES()}
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
}

export default GodigitRoutes;
