import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import DatePicker from "../../../../Component/FieldTypes/DatePicker/DatePicker";
import RKTextField from "../../../../Component/FieldTypes/RKTextField/RKTextField";
// import SearchDropdown from "../../../../Component/FieldTypes/SearchDropdown/SearchDropdown";
// import SelectDropdown from "../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";
// import TermFooter from "../../../../Component/Term/TermFooter/TermFooter";
// import { useAppSelector } from "../../../../Store/hooks";
import { TTermProposerData } from "../../../../types/TTermSlice";
import MTermFooter from "../../../../Component/Term/MTermFooter/MTermFooter";

const MProposerDetails = ({
  updateMasterState,
  proposerDetails,
  setProposerDetails,
  validateForm,
  loader,
}: {
  updateMasterState: Function;
  proposerDetails: TTermProposerData;
  setProposerDetails: Function;
  validateForm: Function;
  loader: boolean;
}) => {
  return (
    <Box>
      <h5 className="sectionTitle">Proposer Details</h5>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <RKTextField
            class_name="inputField mb-2"
            title={"Full Name"}
            value={proposerDetails.full_name.value}
            attrName={["full_name", setProposerDetails, true]}
            value_update={updateMasterState}
            warn_status={proposerDetails.full_name.warning}
            error_message="Enter full name"
          />
        </Grid>
        <Grid xs={6}>
          <DatePicker
            class_name="inputField"
            title={"DOB"}
            value={proposerDetails.dob.value}
            attrName={["dob", setProposerDetails, true]}
            value_update={updateMasterState}
            error_message="Select DOB"
            warn_status={proposerDetails.dob.warning}
            min_date={60}
            disabled={true}
          />
        </Grid>
        <Grid xs={6}>
          <RKTextField
            class_name="inputField mb-2"
            title={"Mobile"}
            value={proposerDetails.mobile.value}
            attrName={["mobile", setProposerDetails, true]}
            value_update={updateMasterState}
            warn_status={proposerDetails.mobile.warning}
            error_message="Enter mobile no."
            validation_type={"NUMBER"}
            max_length={10}
          />
        </Grid>
        <Grid xs={12}>
          <RKTextField
            class_name="inputField mb-2"
            title={"Email"}
            value={proposerDetails.email.value}
            attrName={["email", setProposerDetails, true]}
            value_update={updateMasterState}
            warn_status={proposerDetails.email.warning}
            error_message="Enter Email"
          />
        </Grid>
      </Grid>
      <MTermFooter forward={validateForm} loader={loader} />
    </Box>
  );
};

export default MProposerDetails;
