import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { subYears } from "date-fns";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  FORMAT_YYYY_MM_DD,
  isEmpty,
  isValid_Aadhar,
  isValid_Driving_License_Number,
  isValid_Pan_Number,
  isValid_Passport_num,
  validateFullName,
} from "../../../../../../SupportingFiles/HelpingFunction";
import { DOC_TYPE_CODE } from "../../../../../../types/HI/Enums/DocTypeCode";
import { useAppDispatch, useAppSelector } from "../../../../../../Store/hooks";
import {
  TDigit_Ckyc_Verify,
  TProposal_Ckyc_Info,
} from "../../../../../../types/HI/TProposal";
import { proposalSlice } from "../../../../../../Store/Slice_Reducer/HI/Proposal/ProposalSlice";
import { PROPOSAL_SERVICES } from "../../../../../../Services/HI/ProposalService";
import { toast } from "react-toastify";
import { kycResponseSlice } from "../../../../../../Store/Slice_Reducer/HI/KycResponse/KycResponseSlice";
import KycSuccessPopup from "../../../../../../Component/HI/Desktop/ProposalComponents/KycResponsePopup/KycSuccessPopup";
import KycFailedPopup from "../../../../../../Component/HI/Desktop/ProposalComponents/KycResponsePopup/KycFailedPopup";
import RKTextField from "../../../../../../Component/FieldTypes/RKTextField/RKTextField";
import DatePicker from "../../../../../../Component/FieldTypes/DatePicker/DatePicker";
import SelectDropdown from "../../../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";
import Footer from "../../../../../../Component/HI/Desktop/ProposalComponents/Footer/Footer";

const verificationDoc_data = [
  { value: "D03", label: "CKYC Number" },
  { value: "PAN Card", Label: "PAN Card" },
];

const customerType_data = [
  { label: "Individual", value: "Individual" },
  // { key: "Organization", value: "Organization" },
];

const gender_data = [
  { value: "M", label: "Male" },
  { value: "F", label: "Female" },
];

const DOCS_LIST_TO_VALIDATE: Array<string> = [
  DOC_TYPE_CODE.Aadhar_POA,
  DOC_TYPE_CODE.Aadhar_POV,
  DOC_TYPE_CODE.Ckyc_Num,
  DOC_TYPE_CODE.Driving_License,
  DOC_TYPE_CODE.Pan_Card,
  DOC_TYPE_CODE.Passport,
  DOC_TYPE_CODE.ICICI_AADHAR,
  DOC_TYPE_CODE.ICICI_CKYC_NO,
  DOC_TYPE_CODE.ICICI_DRIVING_LICENCE,
  DOC_TYPE_CODE.ICICI_PAN,
  DOC_TYPE_CODE.ICICI_PASSPORT,
  DOC_TYPE_CODE.ICICI_VOTER_ID,
];

const VerifyKYC: React.FC = () => {
  const state = useAppSelector((state: any) => state);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);
  const [openFailedModal, setOpenFailedModal] = useState<boolean>(false);

  const [ckycDetails, setCkycDetails] = useState<TProposal_Ckyc_Info>({
    propFullName: { value: "", warning: false },
    ProposerDob: { value: "", warning: false },
    docID: { value: "", warning: false },
    customertype: { value: "", warning: false },
    verificationDoc: { value: "", warning: false },
    gender: { value: "", warning: false },
  });
  const [docList, setDocList] = useState<[]>([]);

  useEffect(() => {
    getDocumentsList();
    setCkycDetails(state.proposalInformation.ckycInfo);
  }, [
    state.proposalInformation.policy_information.CompanyDetails.company_code,
  ]);

  useEffect(() => {
    dispatch(
      proposalSlice.actions.setCkycInfo({
        ...state.proposalInformation.ckycInfo,
        customertype: {
          value: customerType_data[0].value,
          warning: false,
        },
      })
    );
  }, []);

  const getDocumentsList = () => {
    const onSuccess = (res: any) => {
      setDocList(
        res.data.data.map((item: any) => {
          return {
            value: item.doc_code,
            label: item.document,
          };
        })
      );
    };

    const onError = (err: any) => {
      console.log(err);
    };

    PROPOSAL_SERVICES.get_document_type_list(
      onError,
      onSuccess,
      state.proposalInformation.policy_information.CompanyDetails.company_code,
      "POV"
    );
  };

  const updateMasterState = (attrName: any, value: any) => {
    let valueToUse = value;
    let warnUser = false;

    if (attrName[0] === "docID") {
      valueToUse = valueToUse.replace(/\s/g, "");
      warnUser = CHECK_DOC_ID_WARN_STATUS(
        ckycDetails.verificationDoc.value,
        value
      );
    }

    if (attrName[0] === "verificationDoc") {
      const warnForDocId = CHECK_DOC_ID_WARN_STATUS(
        value,
        ckycDetails.docID.value
      );
      setCkycDetails((prev) => ({
        ...prev,
        verificationDoc: { value: value, warning: isEmpty(value) },
      }));
      dispatch(
        proposalSlice.actions.setCkycInfo({
          ...state.proposalInformation.ckycInfo,
          docID: {
            ...state.proposalInformation.ckycInfo.docID,
            warning: warnForDocId,
          },
          verificationDoc: {
            ...state.proposalInformation.ckycInfo.verificationDoc,
            value: value,
          },
        })
      );
    } else {
      if (attrName[3] === setCkycDetails) {
        dispatch(
          proposalSlice.actions.setCkycInfo({
            ...state.proposalInformation.ckycInfo,
            [attrName[0]]: {
              [attrName[1]]: valueToUse,
              warning: warnUser,
            },
          })
        );
        dispatch(
          proposalSlice.actions.setManualKycInfo({
            ...state.proposalInformation.manualCkycDigit,
            [attrName[0]]: {
              [attrName[1]]: valueToUse,
              warning: warnUser,
            },
          })
        );
      }

      attrName[3]({
        ...attrName[2],
        [attrName[0]]: { [attrName[1]]: valueToUse, warning: warnUser },
      });
    }
  };

  const CHECK_DOC_ID_WARN_STATUS = (
    docType: string,
    value: string
  ): boolean => {
    let warnUser = false;

    if (DOCS_LIST_TO_VALIDATE.includes(docType)) {
      switch (docType) {
        case DOC_TYPE_CODE.Aadhar_POA:
          warnUser = !isValid_Aadhar(value);
          break;

        case DOC_TYPE_CODE.ICICI_AADHAR:
          warnUser = !isValid_Aadhar(value);
          break;

        case DOC_TYPE_CODE.Aadhar_POV:
          warnUser = !isValid_Aadhar(value);
          break;

        case DOC_TYPE_CODE.Driving_License:
          warnUser = !isValid_Driving_License_Number(value);
          break;

        case DOC_TYPE_CODE.ICICI_DRIVING_LICENCE:
          warnUser = !isValid_Driving_License_Number(value);
          break;

        case DOC_TYPE_CODE.Pan_Card:
          warnUser = !isValid_Pan_Number(value);
          break;

        case DOC_TYPE_CODE.ICICI_PAN:
          warnUser = !isValid_Pan_Number(value);
          break;

        case DOC_TYPE_CODE.Passport:
          warnUser = !isValid_Passport_num(value);
          break;

        case DOC_TYPE_CODE.ICICI_PASSPORT:
          warnUser = !isValid_Passport_num(value);
          break;

        default:
          warnUser = false;
          break;
      }
    }
    return warnUser;
  };

  const validateCkycFields = () => {
    let data: TProposal_Ckyc_Info = state.proposalInformation.ckycInfo;

    data = {
      ...data,
      propFullName: {
        value: data.propFullName.value,
        warning: !validateFullName(data.propFullName.value),
      },
      ProposerDob: {
        value: data.ProposerDob.value,
        warning: isEmpty(data.ProposerDob.value),
      },
      verificationDoc: {
        value: data.verificationDoc.value,
        warning: isEmpty(data.verificationDoc.value),
      },
      docID: {
        value: data.docID.value,
        warning: isEmpty(data.docID.value),
      },
      customertype: {
        value: data.customertype.value,
        warning: isEmpty(data.customertype.value),
      },
      gender: {
        value: data.gender.value,
        warning: isEmpty(data.gender.value),
      },
    };

    setCkycDetails(data);
    dispatch(proposalSlice.actions.setCkycInfo({ ...data }));

    if (
      data.propFullName.warning ||
      data.ProposerDob.warning ||
      data.verificationDoc.warning ||
      data.docID.warning ||
      data.customertype.warning ||
      data.gender.warning
    ) {
      return false;
    } else {
      return true;
    }
  };

  const validate_form = () => {
    let loadingToast: any;
    const onSuccess = (res: any) => {
      loadingToast = toast.loading("Verifying...");
      toast.dismiss(loadingToast);
      toast.success("Successfully Verified!");

      const responseObj = res.data.response;

      const address = responseObj?.customer?.address;

      // Split the address by space
      const addressArray = address?.split(" ");

      // Find the middle index
      const middleIndex = Math.floor(addressArray?.length / 2);

      // Join the first half and second half of the address
      const firstHalf = addressArray?.slice(0, middleIndex)?.join(" ");
      const secondHalf = addressArray?.slice(middleIndex)?.join(" ");

      dispatch(proposalSlice.actions.setDigitKycId(responseObj.digitKYCId));
      dispatch(
        kycResponseSlice.actions.setKycResonse({
          ...state.kycResponseSlice,
          ...responseObj.customer,
          address_line_1: firstHalf,
          address_line_2: secondHalf,
          digitKYCId: responseObj.kycNo,
          kycStatus: res.data.message,
          dob: responseObj.kycDOB,
          first_name: responseObj.kycCustomerName,
        })
      );

      dispatch(
        proposalSlice.actions.setOwnerDetailsScreen({
          ...state.proposalInformation.ownerDetailsScreen,
          ownerDetails: {
            dob: {
              value: responseObj.kycDOB,
              warning: false,
              setFromKycResp: false,
            },
            email: {
              value: responseObj?.customer?.email,
              warning: false,
            },
            gender: {
              value: responseObj?.customer?.gender,
              warning: false,
            },
            mobile: {
              value: responseObj?.customer?.mobile_number,
              warning: false,
              setFromKycResp: false,
            },
            name: {
              value: responseObj.kycCustomerName,
              // " " +
              // responseObj?.customer?.last_name,
              warning: false,
              setFromKycResp: false,
            },
            pancard: {
              value: verificationDoc.value === "D07" ? docID.value : "",
              warning: true,
            },
          },
        })
      );

      if (res.data.message === "Success") {
        console.log("inside the success popup");
        setOpenSuccessModal(true);
      } else {
        console.log("inside the setOpenFailedModals popup");
        setOpenFailedModal(true);
      }

      // dispatch(
      //   proposalSlice.actions.setAddressDetails({
      //     ...state.proposalInformation.addressDetails,
      //     isSame: true,
      //     permanentAddress: {
      //       address1: {
      //         value: responseObj.customer.address,
      //         setFromKycResp: responseObj.customer.address ? true : false,
      //         warning: responseObj.customer.address ? false : true,
      //       },
      //       address2: {
      //         value: responseObj.customer.address,
      //         setFromKycResp: responseObj.customer.address ? true : false,
      //         warning: responseObj.customer.address ? false : true,
      //       },
      //       city: {
      //         value: responseObj.customer.city,
      //         setFromKycResp: responseObj.customer.city ? true : false,
      //         warning: responseObj.customer.city ? false : true,
      //       },
      //       pincode: {
      //         value: responseObj.customer.pincode,
      //         setFromKycResp: responseObj.customer.pincode ? true : false,
      //         warning: responseObj.customer.pincode ? false : true,
      //       },
      //     },
      //   })
      // );
    };

    const onError = (err: any) => {
      console.log("inside error section");
      toast.dismiss(loadingToast);

      setOpenFailedModal(true);

      toast("Ckyc Failed Please complete manual CKYC");
    };

    const {
      ProposerDob,
      customertype,
      docID,
      gender,
      propFullName,
      verificationDoc,
    }: TProposal_Ckyc_Info = state.proposalInformation.ckycInfo;

    let data: TDigit_Ckyc_Verify = {
      customerType: customertype.value,
      documentID: docID.value,
      dob: ProposerDob.value ? FORMAT_YYYY_MM_DD(`${ProposerDob.value}`) : "",
      quote_no: state.quote.quote_id,
      docCode: verificationDoc.value,
      fullName: propFullName.value,
      gender: gender.value.charAt(0),
    };

    let stopVerification = !validateCkycFields();

    if (!stopVerification) {
      PROPOSAL_SERVICES.ckyc_digit(onSuccess, onError, data);
    }
  };

  return (
    <Box>
      <KycSuccessPopup
        modalOpen={openSuccessModal}
        setModalOpen={setOpenSuccessModal}
      />
      <KycFailedPopup
        modalOpen={openFailedModal}
        setModalOpen={setOpenFailedModal}
      />
      <h5 className="sectionTitle">CKYC/eKYC Details</h5>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <h6 className="mb-4 question">Provide CKYC Details for Proposer</h6>
          <p className="textGrey ckyc_p">
            <span className="textBold">
              CKYC refers to Central KYC (Know Your Customer)
            </span>
            , an initiative of the Government of India. The aim of this
            initiative is to have a structure in place which allows investors to
            complete their KYC only once before interacting with various
            entities across the financial sector.
          </p>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={6}>
          <RKTextField
            class_name="inputField"
            title={"Proposer Full Name"}
            validation_type="NAME"
            value={state.proposalInformation.ckycInfo.propFullName.value}
            attrName={["propFullName", "value", ckycDetails, setCkycDetails]}
            value_update={updateMasterState}
            warn_status={ckycDetails.propFullName.warning}
            error_message={
              isEmpty(state.proposalInformation.ckycInfo.propFullName.value)
                ? "Enter Full Name"
                : "Enter Valid Full Name"
            }
          />
        </Grid>
        <Grid xs={6}>
          <DatePicker
            class_name="inputField"
            title={"Proposer DOB"}
            value={state.proposalInformation.ckycInfo.ProposerDob.value}
            attrName={["ProposerDob", "value", ckycDetails, setCkycDetails]}
            value_update={updateMasterState}
            error_message="Select DOB"
            warn_status={ckycDetails.ProposerDob.warning}
            max_date={18}
            min_date={100}
            default_date={subYears(new Date(), 18)}
          />
        </Grid>
        <Grid xs={6}>
          <SelectDropdown
            class_name="inputField"
            title="Document For Verification"
            value={state.proposalInformation.ckycInfo.verificationDoc.value}
            attrName={["verificationDoc", "value", ckycDetails, setCkycDetails]}
            value_update={updateMasterState}
            data={docList}
            warn_status={ckycDetails.verificationDoc.warning}
            error_message="Select Document for Verification"
          />
        </Grid>
        <Grid xs={6}>
          <RKTextField
            class_name="inputField"
            title={"Document ID"}
            value={state.proposalInformation.ckycInfo.docID.value}
            attrName={["docID", "value", ckycDetails, setCkycDetails]}
            value_update={updateMasterState}
            warn_status={ckycDetails.docID.warning}
            error_message="Enter Document ID"
            validation_type="ALL_CAPS"
          />
        </Grid>
        <Grid xs={6}>
          <SelectDropdown
            class_name="inputField"
            title="Customer Type"
            value={state.proposalInformation.ckycInfo.customertype.value}
            attrName={["customertype", "value", ckycDetails, setCkycDetails]}
            value_update={updateMasterState}
            data={customerType_data}
            warn_status={ckycDetails.customertype.warning}
            error_message="Select Customer Type"
          />
        </Grid>
        <Grid xs={6}>
          <SelectDropdown
            class_name="inputField"
            title="Gender"
            value={state.proposalInformation.ckycInfo.gender.value}
            attrName={["gender", "value", ckycDetails, setCkycDetails]}
            value_update={updateMasterState}
            data={gender_data}
            warn_status={ckycDetails.gender.warning}
            error_message="Select Gender"
          />
        </Grid>
      </Grid>
      <Footer continue_function={validate_form} />
    </Box>
  );
};

export default VerifyKYC;
