import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Id, toast } from "react-toastify";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";
import { useAppDispatch, useAppSelector } from "../../../../../Store/hooks";
import { TDropdown } from "../../../../../types/TermFullJourney/TDropdown";
import { TermFullJourneySlice } from "../../../../../Store/Slice_Reducer/TermFullJourney/TermFullJourneySlice";
import { TRidersBenefitsResponse } from "../../../../../types/TermFullJourney/TRidersBenefits";
import { TERM_SERVICES } from "../../../../../Services/Term/TermServices";
import { TERM_FULL_JOURNEY_ROUTES } from "../../../../../Router/Path/TermFullJourneyRoutes";
import { formatAmountToCurrency } from "../../../../../SupportingFiles/HelpingFunction";
import RidersBenefits from "../../../../../Page/Desktop/TermFullJourney/ICICI/Proposal/RidersBenefits/RidersBenefits";
import { TermSlice } from "../../../../../Store/Slice_Reducer/Term/TermSlice";

export type TICICIRidersStatus = {
  TF001: boolean;
  TF002: boolean;
  TF003: boolean;
  TF004: boolean;
};
const RidersBenefitsContainer = () => {
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const { RIDERS_BENEFITS,PREMIUM_LOADER } = useAppSelector((state) => state.TermFullJourney);
  const {
    SELECTED_QUOTE_DATA,
    QUOTE_FIELDS_DATA,
    ADD_FORM_RESPONSE,
  } = useAppSelector((state) => state.Term);
  const [tf003Data, setTf003Data] = useState<TDropdown[]>([]);
  const [tf001Data, setTf001Data] = useState<TDropdown[]>([]);
  const navigate = useNavigate();
  const [loaderTf001, setLoaderTf001] = useState<boolean>(false);
  const [loaderTf002, setLoaderTf002] = useState<boolean>(false);
  const [loaderTf003, setLoaderTf003] = useState<boolean>(false);

  let toastID: Id;
  const GET_RIDERS_PREMIUM = () => {
    dispatch(TermSlice.actions.UPDATE_LOADER_STATUS(true));
    const onSuccess = (data: any) => {
      toast.dismiss(toastID);
      setLoaderTf001(false);
      setLoaderTf002(false);
      setLoaderTf003(false);
      // const results = data.results;
      const results = data.response;
      const error = data.error;
      const message = data.message;
      const response: any = results;
      dispatch(TermSlice.actions.UPDATE_LOADER_STATUS(false));
      if (!error) {
        dispatch(
          TermFullJourneySlice.actions.UPDATE_LOADER_STATUS_AND_RIDER_RESPONSE({
            loader: false,
            rider_response: response,
          })
        );
        dispatch(TermSlice.actions.SET_RIDERS_BENEFITS_DATA(response));
      } else {
        toast.error("There are some technical error. Please contact to admin.");
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      dispatch(TermSlice.actions.UPDATE_LOADER_STATUS(false));
      toast.dismiss(toastID);
      setLoaderTf001(false);
      setLoaderTf002(false);
      setLoaderTf003(false);
      toast.error("There are some technical error. Please contact to admin.");
    };

    const data = {
      product_ids: [SELECTED_QUOTE_DATA.productDetails?.id],
      quote_no: ADD_FORM_RESPONSE?.quote_no,
      criticalIllnessSelected:
        QUOTE_FIELDS_DATA.payMode === "5"
          ? "No"
          : RIDERS_BENEFITS.local_value.tf001Status
          ? "Yes"
          : "No",
      accidentalDeathValue: RIDERS_BENEFITS.local_value.tf003Value,
      accidentalDeathSelected: RIDERS_BENEFITS.local_value.tf003Status
        ? "Yes"
        : "No",
      criticalIllnessValue:
        QUOTE_FIELDS_DATA.payMode === "5"
          ? ""
          : RIDERS_BENEFITS.local_value.tf001Value,
      occupation: "",
    };
    toast.dismiss(toastID);
    toastID = toast.loading("Please wait...");
    TERM_SERVICES.UPDATE_RIDERS_STATUS(onSuccess, onError, data);
  };

  const continue_function = () => {
    const toastId = toast.loading("Please wait...");
    let params = {
      buy_action_url: SELECTED_QUOTE_DATA.productDetails.buy_online_code,
      quote_no: btoa(ADD_FORM_RESPONSE.quote_no),
      section: "add_ons_details",
      details: {},
    };

    const onSuccess = (res: any) => {
      toast.dismiss(toastId);
      navigate(
        `${TERM_FULL_JOURNEY_ROUTES.PROPOSER_DETAILS}?type=TM&companyCode=${SELECTED_QUOTE_DATA?.companyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}`
      );
    };
    const onError = () => {};
    TERM_SERVICES.UPDATE_PROPOSER_DETAILS(onSuccess, onError, params);
  };

  useEffect(() => {
    const start_tf001 = 100000;
    const end_tf001 =
      parseInt(`${SELECTED_QUOTE_DATA.premiumDetails.sum_assured}`) > 10000000
        ? 10000000
        : parseInt(`${SELECTED_QUOTE_DATA.premiumDetails.sum_assured}`);

    let data_tf001: TDropdown[] = [];
    for (let i = start_tf001; i <= end_tf001; i += 100000) {
      data_tf001.push({ value: `${i}`, label: formatAmountToCurrency(i) });
    }

    setTf001Data(data_tf001);

    const start_tf003 = 100000;
    const end_tf003 =
      parseInt(`${SELECTED_QUOTE_DATA.premiumDetails.sum_assured}`) > 20000000
        ? 20000000
        : parseInt(`${SELECTED_QUOTE_DATA.premiumDetails.sum_assured}`);

    let data_tf003: TDropdown[] = [];
    for (let i = start_tf003; i <= end_tf003; i += 100000) {
      data_tf003.push({ value: `${i}`, label: formatAmountToCurrency(i) });
    }

    setTf003Data(data_tf003);
  }, [RIDERS_BENEFITS]);

  useEffect(() => {
    GET_RIDERS_PREMIUM();
  }, [
    RIDERS_BENEFITS.local_value.tf001Status,
    RIDERS_BENEFITS.local_value.tf003Status,
    RIDERS_BENEFITS.local_value.tf001Value,
    RIDERS_BENEFITS.local_value.tf003Value,
  ]);

  useEffect(() => {
    setLoaderTf001(true);
  }, [RIDERS_BENEFITS.local_value.tf001Status]);

  useEffect(() => {
    setLoaderTf003(true);
  }, [RIDERS_BENEFITS.local_value.tf003Status]);

  return (
    <>
      {PREMIUM_LOADER || loaderTf001 || loaderTf002 || loaderTf003 ? (
        <Box
          position={"fixed"}
          height={"100%"}
          width={"100%"}
          top="0"
          left="0"
          zIndex={911}
        />
      ) : null}
      {isMobile ? null : (
        // <MRidersBenefits
        //   forward={continue_function}
        //   tf001Data={tf001Data}
        //   tf003Data={tf003Data}
        //   loaderTf001={loaderTf001}
        //   loaderTf003={loaderTf003}
        // />

        <RidersBenefits
          loaderTf001={loaderTf001}
          forward={continue_function}
          tf003Data={tf003Data}
          tf001Data={tf001Data}
          loaderTf003={loaderTf003}
        />
      )}
    </>
  );
};

export default RidersBenefitsContainer;
