import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";
import AddonQuestion from "../../../../../Component/AddonQuestion/AddonQuestion";
import Loader from "../../../../../Component/Loader/Loader";
import MFooter from "../../../../../Component/MFooter/MFooter";
import { HEALTH_ROUTE } from "../../../../../Router/Path/HealthRoutes";
import { useAppSelector } from "../../../../../Store/hooks";
import { TICICAddon } from "../../../../../types/Health/ProposalDeatail/TICICAddon/TICICAddon";

function MAddonDetailsICIC({
  addonStatus,
  updateMasterState,
  validate,
  PAGE_STATUS,
  isLoading,
  trueCount,
}: {
  addonStatus: TICICAddon;
  PAGE_STATUS: boolean;
  updateMasterState: Function;
  validate: Function;
  isLoading: any;
  trueCount: any;
}) {
  const navigate = useNavigate();
  const { SELECTED_QUOTE_DATA, QUOTE_LOADER, INSURED_MEMBER_DETAILS } =
    useAppSelector((state) => state.Health);

  const { VERIFY_KYC_FORM } = useAppSelector((state) => state.CKYC);
  const FStatus =
    INSURED_MEMBER_DETAILS.PROPOSER_DETAILS.gender.value === "M" &&
    INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.relationship.value !== "Mother"
      ? true
      : false;
  return (
    <Box>
      {isLoading && <Loader />}
      <h5 className="sectionTitle">Addon Details</h5>
      <Grid xs={12}>
        {(Number(SELECTED_QUOTE_DATA.premiumDetails.sum_insured) <= 1000000 &&
          SELECTED_QUOTE_DATA.premiumDetails.term === 1) ||
        (Number(SELECTED_QUOTE_DATA.premiumDetails.sum_insured) <= 4000000 &&
          (SELECTED_QUOTE_DATA.premiumDetails.term === 2 ||
            SELECTED_QUOTE_DATA.premiumDetails.term === 3)) ? (
          <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Critical Illness"
            toggle_status={addonStatus.Critical_Illness}
            value_update={updateMasterState}
            attrName={"Critical_Illness"}
            desc="Provides cover for critical illness suffered.
Recieve cover for the Annual Sum Insured, up to ₹ 50 Lakhs, upon your first diagnosis of one or more Critical Illnesses, applicable for ages up to 50 years. (T&C Apply)
"
          />
        ) : null}
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="Maternity Cover"
          toggle_status={addonStatus.Maternity}
          value_update={updateMasterState}
          attrName={"Maternity"}
          disable={FStatus}
          desc="Covers medical expenses pertaining to maternity benefits.
All SI -Covers medical expenses up to 10% of the Annual SI, with a maximum limit of INR 1 Lakh (Maternity Sum Insured) for childbirth and/or pregnancy termination, limited to three instances, for insured females aged 21 to 45.
"
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="New Born"
          toggle_status={addonStatus.New_Born}
          value_update={updateMasterState}
          attrName={"New_Born"}
          disable={FStatus}
          desc="Only for Female"
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="Vaccination For New Born Baby"
          toggle_status={addonStatus.Vaccination}
          value_update={updateMasterState}
          attrName={"Vaccination"}
          disable={FStatus}
          desc="Only for Female"
        />
        {(Number(SELECTED_QUOTE_DATA.premiumDetails.sum_insured) <= 2000000 &&
          SELECTED_QUOTE_DATA.premiumDetails.term === 1) ||
        (Number(SELECTED_QUOTE_DATA.premiumDetails.sum_insured) <= 4000000 &&
          (SELECTED_QUOTE_DATA.premiumDetails.term === 2 ||
            SELECTED_QUOTE_DATA.premiumDetails.term === 3)) ? (
          <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="treatments"
            question_name="Personal Accident"
            toggle_status={addonStatus.personal_accident}
            value_update={updateMasterState}
            attrName={"personal_accident"}
            desc="Covers injury sustained due to personal accident.
All SI - Receive an annual sum insured of up to 50 lakhs for injuries sustained due to an accident, upto the age of 65. (T&C Apply)
"
          />
        ) : null}
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="Sum Insured Protect"
          toggle_status={addonStatus.SI_Protector}
          value_update={updateMasterState}
          attrName={"SI_Protector"}
          desc="The annual sum insured of your base policy will be increased cumulatively at each renewal based on the inflation rate in the past year as per the average CPI of the entire calendar year published by the Central Statistical Organization (CSO)."
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="Claim Protector"
          toggle_status={addonStatus.Claim_Protector}
          value_update={updateMasterState}
          attrName={"Claim_Protector"}
          disable={
            Number(SELECTED_QUOTE_DATA.premiumDetails.sum_insured) >= 1500000
              ? true
              : false
          }
          desc={
            Number(SELECTED_QUOTE_DATA.premiumDetails.sum_insured) >= 1500000
              ? "This covers all non payable items in case of a claim.This covers all the IRDAI list of non-payable items, incase they shall become payable in case of a claim."
              : ""
          }
          showAsterisk={
            Number(SELECTED_QUOTE_DATA.premiumDetails.sum_insured) >= 1500000
              ? true
              : false
          }
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="Compassionate Visit"
          toggle_status={addonStatus.Compassionate_Visit}
          value_update={updateMasterState}
          attrName={"Compassionate_Visit"}
          disable={
            Number(SELECTED_QUOTE_DATA.premiumDetails.sum_insured) >= 10000000
              ? true
              : false
          }
          desc={
            Number(SELECTED_QUOTE_DATA.premiumDetails.sum_insured) >= 10000000
              ? "Your immediate family member’s economy class air/train tickets is reimbursed if you are hospitalized outside your home city for more than 5 days. The maximum reimbursement amount is Rs 20,000 per policy year."
              : ""
          }
          showAsterisk={
            Number(SELECTED_QUOTE_DATA.premiumDetails.sum_insured) >= 10000000
              ? true
              : false
          }
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="Nursing at Home"
          toggle_status={addonStatus.Nursing_at_Home}
          value_update={updateMasterState}
          attrName={"Nursing_at_Home"}
          disable={
            Number(SELECTED_QUOTE_DATA.premiumDetails.sum_insured) >= 10000000
              ? true
              : false
          }
          desc={
            Number(SELECTED_QUOTE_DATA.premiumDetails.sum_insured) >= 10000000
              ? "Get up to ₹ 2,000/day for up to 10 days for medical services of a qualified nurse at your home, payable only if the liability is admitted under 'In-patient treatment'"
              : ""
          }
          showAsterisk={
            Number(SELECTED_QUOTE_DATA.premiumDetails.sum_insured) >= 10000000
              ? true
              : false
          }
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="BeFit A"
          toggle_status={addonStatus.BeFit_A}
          value_update={updateMasterState}
          attrName={"BeFit_A"}
          desc=" 	Routine Diagnostic, Minor Procedure cover & Pharmacy cover - ₹1000/- cover
 	E-counselling virtual sessions with qualified mental health medical practitioners - 6 sessions
 	Diet and nutrition e-consultation with qualified nutrition experts - 6 sessions
"
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="BeFit B"
          toggle_status={addonStatus.BeFit_B}
          value_update={updateMasterState}
          attrName={"BeFit_B"}
          desc=" 	Routine Diagnostic & Minor Procedure cover - ₹1000/- cover
 	Pharmacy cover - ₹1000/- cover
 	E-counselling virtual sessions with qualified mental health medical practitioners - 6 sessions
"
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="BeFit C"
          toggle_status={addonStatus.BeFit_C}
          value_update={updateMasterState}
          attrName={"BeFit_C"}
          desc="Get up to 4 outpatient consultations(OPD).
Routine diagnostic and minor procedures cover of ₹ 1,000.
Pharmacy cover of ₹ 1,000.
6 sessions for physiotherapy, e-counseling, diet and nutrition e-consultation each.
"
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="BeFit D"
          toggle_status={addonStatus.BeFit_D}
          value_update={updateMasterState}
          attrName={"BeFit_D"}
          desc=" 	Routine Diagnostic & Minor Procedure cover - ₹1000/- cover
 	Pharmacy cover - ₹1000/- cover
 	E-counselling virtual sessions with qualified mental health medical practitioners - 6 sessions
"
        />{" "}
        {/* <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="BeFit E"
          toggle_status={addonStatus.BeFit_E}
          value_update={updateMasterState}
          attrName={"BeFit_E"}
        />{" "}
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="BeFit F"
          toggle_status={addonStatus.BeFit_F}
          value_update={updateMasterState}
          attrName={"BeFit_F"}
        />{" "}
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="BeFit G"
          toggle_status={addonStatus.BeFit_G}
          value_update={updateMasterState}
          attrName={"BeFit_G"}
        />{" "}
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="BeFit H"
          toggle_status={addonStatus.BeFit_H}
          value_update={updateMasterState}
          attrName={"BeFit_H"}
        /> */}
      </Grid>
      <MFooter
        textName={PAGE_STATUS === true ? "Update" : "Continue"}
        attrName={"pageStatus"}
        forward={() => {
          validate();
        }}
        value={0}
        backward={() => {
          navigate(`${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.QOUTE_PAGE}`);
        }}
        disableButton={trueCount > 10}
        loader={QUOTE_LOADER}
      />
    </Box>
  );
}

export default MAddonDetailsICIC;
