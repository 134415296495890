import { Box, Checkbox, FormControlLabel } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { subYears } from "date-fns";
import CKYCDetailsPopup from "../../../../../Component/CKYCDetailsPopup/CKYCDetailsPopup";
import DatePicker from "../../../../../Component/FieldTypes/DatePicker/DatePicker";
import RKTextField from "../../../../../Component/FieldTypes/RKTextField/RKTextField";
import SelectDropdown from "../../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";
import Footer from "../../../../../Component/Footer/Footer";
import MemberRadioButton from "../../../../../Component/MemberRadioButton/MemberRadioButton";
import { useAppDispatch, useAppSelector } from "../../../../../Store/hooks";
import {
  isEmpty,
} from "../../../../../SupportingFiles/HelpingFunction";
import { TDropdownData } from "../../../../../types/DropdownData/DropdownData";
import { TAdultOneDetails, TTravelNomineeDetails } from "../../../../../types/Travel/TTravelSlice";

export default function AdultOneDetails({
  proposerFields,
  nomineeDetails,
  fieldsUpdateState,
  validateForm,
  handleClick,
  adultOneDetails,
  kycOpen,
  setKycOpen,
  loader,
  toggle_status,
  disbaledButton,
  setDisbaledButton,
  maritalStatusList,
  nomineeRealtionshipList,
}: {
  proposerFields: TAdultOneDetails;
  adultOneDetails: TAdultOneDetails;
  nomineeDetails: TTravelNomineeDetails;
  fieldsUpdateState: Function;
  validateForm: Function;
  handleClick: Function;
  kycOpen: boolean;
  setKycOpen: Function;
  loader: boolean;
  toggle_status: any;
  disbaledButton: boolean;
  setDisbaledButton: Function;
  maritalStatusList: TDropdownData[];
  nomineeRealtionshipList: TDropdownData[];
}) {
  const dispatch = useAppDispatch();
  const {
    ADD_FORM,
    DROPDOWN_DATA,
    PAGE_STATUS,
    NOMINEE_RELATIONSHIP_STATUS,
  } = useAppSelector((state) => state.Travel);
  const { GENDER, HEIGHT_FEET, HEIGHT_INCH } =
    useAppSelector((state) => state.CommonDropdownData);

  return (
    <Box>
      <CKYCDetailsPopup open={kycOpen} setOpen={setKycOpen} loader={loader} />
      {proposerFields.IS_PROPOSER_INSURED ? (
        <h5 className="sectionTitle">
          1<sup>st</sup> Adult Details
        </h5>
      ) : (
        <h5 className="sectionTitle">Proposer Details</h5>
      )}
      {/* <Grid container spacing={3}>
        <Grid xs={12}>
          <FormControlLabel
            className="proposalCheckBoxWrapper"
            control={
              <Checkbox
                disabled={PAGE_STATUS}
                defaultChecked={proposerFields.IS_PROPOSER_INSURED}
                checked={proposerFields.IS_PROPOSER_INSURED}
                value={proposerFields.IS_PROPOSER_INSURED}
                onChange={(e: any, v: any) => {
                  handleClick(v);
                }}
              />
            }
            label="Proposer is also an Insured Member"
          />
        </Grid>
      </Grid> */}

      {!proposerFields.IS_PROPOSER_INSURED ? (
        <>
          <Grid container spacing={3}>
            <Grid xs={6}>
              <RKTextField
                class_name="inputField"
                title={"Full Name"}
                value={proposerFields.name.value}
                attrName={"name"}
                value_update={fieldsUpdateState}
                warn_status={false}
                error_message={
                  proposerFields.name.value === "" ? "Enter Full Name" : ""
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid xs={4}>
              <DatePicker
                class_name="inputField"
                title={"DOB"}
                value={proposerFields.dob.value}
                attrName={"dob"}
                value_update={fieldsUpdateState}
                error_message="Enter Dob"
                warn_status={proposerFields.dob.warning}
                min_date={100 * 365}
                max_date={18 * 365}
              />
            </Grid>
            <Grid xs={4}>
              <SelectDropdown
                class_name="inputField"
                title="Gender"
                value={proposerFields.gender.value}
                attrName={"gender"}
                value_update={fieldsUpdateState}
                data={GENDER}
                warn_status={proposerFields.gender.warning}
                error_message="Select Gender"
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid xs={4}>
              <RKTextField
                validation_type="NUMBER"
                max_length={10}
                class_name="inputField"
                title={"Mobile"}
                value={ADD_FORM?.mobile?.value}
                attrName={"mobile"}
                value_update={fieldsUpdateState}
                warn_status={proposerFields.mobile.warning}
                error_message={
                  isEmpty(proposerFields.mobile.value)
                    ? "Enter Mobile Number"
                    : "Enter Valid Mobile Number"
                }
                disabled={true}
              />
            </Grid>
            <Grid xs={6}>
              <RKTextField
                class_name="inputField"
                title={"Email"}
                value={proposerFields.email.value}
                attrName={"email"}
                value_update={fieldsUpdateState}
                warn_status={proposerFields.email.warning}
                error_message={
                  isEmpty(proposerFields.email.value)
                    ? "Enter Email"
                    : "Enter Valid Email"
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid xs={4}>
              <RKTextField
                validation_type="NUMBER"
                max_length={12}
                class_name="inputField"
                title={"Aadhar"}
                value={proposerFields.aadhar.value}
                attrName={"aadhar"}
                value_update={fieldsUpdateState}
                warn_status={proposerFields.aadhar.warning}
                error_message={
                  isEmpty(proposerFields.aadhar.value)
                    ? "Enter Aadhar"
                    : "Enter Valid Aadhar"
                }
              />
            </Grid>
            <Grid xs={4}>
              <RKTextField
                validation_type="NUMBER"
                max_length={10}
                class_name="inputField"
                title={"Emergency Phone"}
                value={proposerFields.emergencyPhone.value}
                attrName={"emergencyPhone"}
                value_update={fieldsUpdateState}
                warn_status={proposerFields.emergencyPhone.warning}
              // error_message={
              //   proposerFields.emergencyPhone.value.toString().length < 11
              //     ? "Enter Valid Emergency Phone Number"
              //     : ""
              // }
              />
            </Grid>
            <Grid xs={4}>
              <RKTextField
                validation_type="ALPHANUMERIC"
                class_name="inputField"
                title={"PAN"}
                value={proposerFields.pan_card.value?.toUpperCase()}
                attrName={["pan_card"]}
                value_update={fieldsUpdateState}
                warn_status={proposerFields.pan_card.warning}
                error_message={
                  isEmpty(proposerFields.pan_card.value)
                    ? "Enter PAN"
                    : "Enter Valid Pan"
                }
                max_length={10}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid container spacing={3}>
            <Grid xs={6}>
              <RKTextField
                class_name="inputField"
                title={"Full Name"}
                value={adultOneDetails.name.value}
                attrName={["name", "adultOneDetails"]}
                value_update={fieldsUpdateState}
                warn_status={adultOneDetails.name.warning}
                error_message={"Enter Full Name"}
                disabled={true}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid xs={4}>
              <DatePicker
                class_name="inputField"
                title={"DOB"}
                value={adultOneDetails.dob.value}
                attrName={["dob", "adultOneDetails"]}
                value_update={fieldsUpdateState}
                error_message="Enter Dob"
                warn_status={adultOneDetails.dob.warning}
                default_date={subYears(new Date(), 18)}
                disabled={true}
              />
            </Grid>
            <Grid xs={4}>
              <SelectDropdown
                class_name="inputField"
                title="Gender"
                value={adultOneDetails.gender.value}
                attrName={["gender", "adultOneDetails"]}
                value_update={fieldsUpdateState}
                data={GENDER}
                warn_status={adultOneDetails.gender.warning}
                error_message="Select Gender"
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid xs={4}>
              <RKTextField
                validation_type="NUMBER"
                max_length={10}
                class_name="inputField"
                title={"Mobile"}
                value={ADD_FORM?.mobile?.value}
                attrName={["mobile", "adultOneDetails"]}
                value_update={fieldsUpdateState}
                warn_status={adultOneDetails.mobile.warning}
                error_message={
                  isEmpty(adultOneDetails.mobile.value)
                    ? "Enter Mobile Number"
                    : "Enter Valid Mobile Number"
                }
                disabled={true}
              />
            </Grid>
            <Grid xs={6}>
              <RKTextField
                class_name="inputField"
                title={"Email"}
                value={adultOneDetails.email.value}
                attrName={["email", "adultOneDetails"]}
                value_update={fieldsUpdateState}
                warn_status={adultOneDetails.email.warning}
                error_message={
                  isEmpty(adultOneDetails.email.value)
                    ? "Enter Email"
                    : "Enter Valid Email"
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid xs={4}>
              <RKTextField
                validation_type="NUMBER"
                max_length={12}
                class_name="inputField"
                title={"Aadhar"}
                value={adultOneDetails.aadhar.value}
                attrName={["aadhar", "adultOneDetails"]}
                value_update={fieldsUpdateState}
                warn_status={adultOneDetails.aadhar.warning}
                error_message={
                  isEmpty(adultOneDetails.aadhar.value)
                    ? "Enter Aadhar"
                    : "Enter Valid Aadhar"
                }
              />
            </Grid>
            <Grid xs={4}>
              <RKTextField
                validation_type="NUMBER"
                max_length={10}
                class_name="inputField"
                title={"Emergency Phone"}
                value={adultOneDetails.emergencyPhone.value}
                attrName={["emergencyPhone", "adultOneDetails"]}
                value_update={fieldsUpdateState}
                warn_status={adultOneDetails.emergencyPhone.warning}
                error_message={
                  adultOneDetails?.emergencyPhone?.value?.toString().length < 11
                    ? "Enter Valid Mobile No."
                    : ""
                }
              />
            </Grid>

            <Grid xs={4}>
              <RKTextField
                validation_type="ALPHANUMERIC"
                class_name="inputField"
                title={"PAN"}
                value={adultOneDetails.pan_card.value?.toUpperCase()}
                attrName={["pan_card", "adultOneDetails"]}
                value_update={fieldsUpdateState}
                warn_status={adultOneDetails.pan_card.warning}
                error_message={
                  isEmpty(adultOneDetails.pan_card.value)
                    ? "Enter PAN"
                    : "Enter Valid Pan "
                }
                alphabet_only={true}
                max_length={10}
              />
            </Grid>

          </Grid>
          <Grid container spacing={3}>
            <Grid xs={6} md={4} lg={3} className="weightField">
              <span className="weightIcon"></span>
              <RKTextField
                validation_type="NUMBER"
                max_length={3}
                class_name="inputField"
                title={"Weight (Kg)"}
                value={adultOneDetails.weight.value}
                attrName={["weight", "adultOneDetails"]}
                value_update={fieldsUpdateState}
                warn_status={adultOneDetails.weight.warning}
                error_message="Enter Weight"
              />
            </Grid>
            <Grid xs={6} md={6} lg={5} className="heightField">
              <span className="heightIcon"></span>
              <SelectDropdown
                class_name="inputField mr-2"
                title="Feet"
                value={adultOneDetails.heightFeet.value}
                attrName={["heightFeet", "adultOneDetails"]}
                value_update={fieldsUpdateState}
                data={HEIGHT_FEET}
                warn_status={adultOneDetails.heightFeet.warning}
                error_message="Select Height Feet"
              />
              <SelectDropdown
                class_name="inputField"
                title="Inches"
                value={adultOneDetails.heightInches.value}
                attrName={["heightInches", "adultOneDetails"]}
                value_update={fieldsUpdateState}
                data={HEIGHT_INCH}
                warn_status={adultOneDetails.heightInches.warning}
                error_message="Select Height Inches"
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid xs={6} md={5} lg={4}>
              <SelectDropdown
                class_name="inputField"
                title="Marital Status"
                value={adultOneDetails.maritalStatus.value}
                attrName={["maritalStatus", "adultOneDetails"]}
                value_update={fieldsUpdateState}
                data={maritalStatusList}
                warn_status={adultOneDetails.maritalStatus.warning}
                error_message="Select Marital Status"
              />
            </Grid>
            <Grid xs={4}>
              <RKTextField
                validation_type="ALPHANUMERIC"
                class_name="inputField"
                title={"Passport"}
                value={adultOneDetails.passportNumber.value}
                attrName={["passportNumber", "adultOneDetails"]}
                value_update={fieldsUpdateState}
                warn_status={adultOneDetails.passportNumber.warning}
                max_length={8}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid xs={12}>
              <hr />
              <h6>Nominee Details</h6>
            </Grid>
          </Grid>
          <Grid container spacing={3} justifyContent="center">
            {ADD_FORM.spouse_status ? (
              <Grid xs={4}>
                <MemberRadioButton
                  checked={NOMINEE_RELATIONSHIP_STATUS === "ADULT_2"}
                  class_name="adult"
                  input_name="ksdgkj"
                  input_id={"ADULT_2"}
                  label_name="Adult 2"
                  input_type="radio"
                  attrName={"nomineDetail"}
                  value={NOMINEE_RELATIONSHIP_STATUS === "ADULT_2"}
                  value_update={() => {
                    // dispatch(
                    //   HealthSlice.actions.CHANGE_NOMINEE_STATUS("ADULT_2")
                    // );
                  }}
                />
              </Grid>
            ) : null}
            {!ADD_FORM.spouse_status ? null : (
              <Grid xs={4}>
                <MemberRadioButton
                  checked={NOMINEE_RELATIONSHIP_STATUS === "OTHER"}
                  class_name="adult"
                  input_name="ksdgkj"
                  input_id="OTHER"
                  label_name="Other"
                  value={NOMINEE_RELATIONSHIP_STATUS === "OTHER"}
                  input_type="radio"
                  attrName={"nomineDetail"}
                  value_update={() => {
                    // dispatch(
                    //   HealthSlice.actions.CHANGE_NOMINEE_STATUS("OTHER")
                    // );
                  }}
                />
              </Grid>
            )}
          </Grid>
          {/* Nominee Member Details */}
          {/* {NOMINEE_RELATIONSHIP_STATUS === "OTHER" ? ( */}
          <Box>
            <Grid container spacing={3}>
              <Grid xs={6}>
                <RKTextField
                  validation_type="NAME"
                  class_name="inputField"
                  title={"Full Name"}
                  value={nomineeDetails.full_name.value}
                  attrName={["nomineeDetails", "full_name"]}
                  value_update={fieldsUpdateState}
                  warn_status={nomineeDetails.full_name.warning}
                  error_message="Enter Nominee Full Name"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={4}>
                <DatePicker
                  class_name="inputField"
                  title={"DOB"}
                  value={nomineeDetails.dob.value}
                  attrName={["nomineeDetails", "dob"]}
                  date_validation_type="YEARS"
                  min_date={100}
                  max_date={18}
                  default_date={subYears(new Date(), 18)}
                  value_update={fieldsUpdateState}
                  error_message="Enter Nominee Date Of Birth"
                  warn_status={nomineeDetails.dob.warning}
                />
              </Grid>
              <Grid xs={4}>
                <SelectDropdown
                  class_name="inputField"
                  title="Relationship"
                  value={nomineeDetails?.relationship?.value}
                  attrName={["nomineeDetails", "relationship"]}
                  value_update={fieldsUpdateState}
                  data={nomineeRealtionshipList}
                  warn_status={nomineeDetails?.relationship?.warning}
                  error_message="Enter Nominee RelationShip"
                />
              </Grid>
            </Grid>
          </Box>
          {/* ) : null} */}
        </>
      )}

      {/* Footer */}

      <Footer
        attrName={"pageStatus"}
        textName={PAGE_STATUS ? "Update" : "Continue"}
        value={1}
        value_update={() => { }}
        forward={() => {
          validateForm();
        }}
        disableButton={disbaledButton}
      />
    </Box>
  );
}
