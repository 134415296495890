import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { subYears } from "date-fns";
import { useAppSelector } from "../../../../../Store/hooks";
import RKTextField from "../../../../../Component/FieldTypes/RKTextField/RKTextField";
import SelectDropdown from "../../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";
import { TBank_Details_NIVABUPA } from "../../../../../types/Health/THealthSlice";
import {
  isEmpty,
  validateIFSC,
} from "../../../../../SupportingFiles/HelpingFunction";
import Footer from "../../../../../Component/Footer/Footer";

const BankDetailsNivaBupa = ({
  BankDetails,
  updateBankDetail,
  validateForm,
  disbaledButton,
}: {
  BankDetails: TBank_Details_NIVABUPA;
  updateBankDetail: Function;
  validateForm: Function;
  disbaledButton: boolean;
}) => {
  const { PAGE_STATUS } = useAppSelector((state) => state.Health);

  return (
    <>
      <Grid container spacing={3}>
        <h5 className="sectionTitle">Proposer's Bank Details</h5>
        <Grid container spacing={3}></Grid>
        <Grid xs={6}>
          <RKTextField
            class_name="inputField"
            title={"Bank Account Number"}
            value={BankDetails?.BankAccountNumber?.value}
            attrName={"BankAccountNumber"}
            value_update={updateBankDetail}
            warn_status={BankDetails?.BankAccountNumber?.warning}
            error_message={"Enter Bank Account Number"}
            max_length={21}
            validation_type="NUMBER"
          />
        </Grid>
        <Grid xs={6}>
          <SelectDropdown
            class_name="inputField"
            title="Bank Account Type"
            value={BankDetails?.BankAccountType?.value}
            attrName={"BankAccountType"}
            value_update={updateBankDetail}
            data={[
              {
                label: "Saving",
                value: "Saving",
              },
              {
                label: "Current",
                value: "Current",
              },
            ]}
            warn_status={BankDetails?.BankAccountType?.warning}
            error_message={"Enter Bank Account Type"}
          />
        </Grid>
        <Grid xs={6}>
          <RKTextField
            class_name="inputField"
            title={"IFSC Code"}
            value={BankDetails.IFSCCode.value}
            attrName={"IFSCCode"}
            value_update={updateBankDetail}
            warn_status={BankDetails.IFSCCode.warning}
            error_message={
              isEmpty(BankDetails.IFSCCode.value)
                ? "Enter IFSC Code"
                : !validateIFSC(BankDetails.IFSCCode.value)
                ? "Enter Valid IFSC Code"
                : ""
            }
            max_length={11}
          />
        </Grid>
        <Grid xs={6}>
          <RKTextField
            class_name="inputField"
            title={"Bank Name"}
            value={BankDetails?.BankName?.value}
            attrName={"BankName"}
            value_update={updateBankDetail}
            warn_status={BankDetails?.BankName?.warning}
            error_message={"Enter Bank Name"}
            disabled={true}
          />
        </Grid>
        <Grid xs={6}>
          <RKTextField
            class_name="inputField"
            title={"Customer Bank Branch"}
            value={BankDetails.CustomerBankBranch.value}
            attrName={"CustomerBankBranch"}
            value_update={updateBankDetail}
            warn_status={BankDetails.CustomerBankBranch.warning}
            error_message={"Enter Customer Bank Branch"}
            disabled={true}
          />
        </Grid>
        <Grid xs={6}>
          <RKTextField
            class_name="inputField"
            title={"Bank City"}
            value={BankDetails.BankCity.value}
            attrName={"BankCity"}
            value_update={updateBankDetail}
            warn_status={BankDetails.BankCity.warning}
            error_message={"Enter Bank City"}
            disabled={true}
          />
        </Grid>

        <Footer
          attrName={"pageStatus"}
          textName={PAGE_STATUS ? "Update" : "Continue"}
          value={1}
          value_update={() => {}}
          forward={() => {
            validateForm();
          }}
          disableButton={disbaledButton}
        />
      </Grid>
    </>
  );
};

export default BankDetailsNivaBupa;
