export const MaxLifeNegativePincode = [
  124112, 335523, 456771, 583229, 530046, 496116, 495689, 466116, 143117,
  493890, 342602, 583227, 342606, 531001, 531011, 414603, 825318, 202393,
  148033, 147111, 474012, 521340, 768033, 136043, 363621, 382845, 144004,
  132157, 531032, 124106, 533249, 148020, 247452, 136033, 250404, 342027,
  244501, 474004, 132117, 384001, 144804, 313324, 344001, 416115, 531031,
  531026, 531113, 465001, 425413, 456222, 247774, 584129, 577598, 251311,
  301404, 384350, 147102, 245412, 136117, 845101, 342601, 151509, 321203,
  475110, 422001, 474005, 241001, 788804, 312201, 788805, 335504, 521120,
  344704, 148106, 531033, 532222, 306302, 151506, 143114, 202130, 425105,
  422206, 305404, 123034, 585223, 272153, 752103, 148031, 144626, 328001,
  531055, 533001, 783384, 389350, 425002, 389151, 395002, 828109, 466221,
  124406, 583235, 383345, 770032, 151205, 132108, 127026, 390017, 132023,
  401106, 263152, 131023, 194101, 844102, 482008, 136131, 313301, 413517,
  389001, 584138, 425424, 583268, 244410, 312202, 425103, 847232, 845306,
  152033, 495692, 306102, 136021, 131027, 143412, 465337, 131306, 798621,
  783393, 474010, 127046, 273202, 801302, 723131, 202390, 201008, 222202,
  571428, 442201, 413203, 759013, 832303, 451666, 788001, 389230, 626189,
  585401, 700140, 125122, 454441, 385510, 305621, 767041, 305406, 312404,
  243632, 505306, 481449, 313341, 731244, 475001, 486882, 312203, 491668,
  441701, 125078, 583236, 127032, 202127, 383355, 534004, 251319, 383246,
  521212, 441901, 803301, 247778, 125111, 415508, 382355, 454660, 732138,
  753010, 759103, 456335, 143102, 517002, 382305, 828204, 783101, 784145,
  312605, 274304, 741103, 262405, 781011, 752030, 517644, 756100, 518501,
  465106, 202522, 847424, 483105, 813227, 305412, 457333, 343023, 311030,
  425414, 384220, 383317, 364320, 364250, 364070, 768119, 454111, 848102,
  847108, 531133, 474008, 382735, 364275, 444720, 342314, 342308, 281206,
  843116, 587201, 522015, 509105, 342028, 328025, 305925, 283115, 852216,
  587120, 531028, 388215, 383434, 523334, 522660, 262311, 853202, 783376,
  523263, 387510, 383316, 274301, 341022, 389152, 312024, 847106, 522661,
  456440, 311802, 782120, 476115, 457887, 383120, 274201, 382855, 212207,
  522329, 848503, 416106, 176027, 786103, 522314, 494665, 212108, 143305,
  783383, 845412, 741313, 482009, 303603, 695584, 495687, 491661, 204213,
  416306, 759024, 518502, 493992, 454331, 210424, 713360, 759026, 205142,
  795132, 364240, 845105, 844113, 522426, 828403, 204216, 828203, 784510,
  797116, 713381, 500065, 713519, 783390, 851214, 851117, 848506, 848132,
  847423, 847404, 847239, 847121, 847105, 847103, 841313, 841211, 833101,
  829105, 828128, 825402, 814131, 813321, 804402, 796691, 788160, 784116,
  783388, 783339, 782485, 782481, 782140, 782135, 782105, 781378, 781355,
  781349, 781310, 781303, 781126, 781120, 781030, 762100, 759018, 754030,
  754026, 752032, 731241, 721201, 721147, 713364, 700083, 686531, 686123,
  678555, 614616, 591231, 587112, 571446, 533262, 532312, 531027, 524401,
  521170, 518166, 516310, 496665, 495661, 494114, 493891, 484551, 483113,
  480886, 476554, 476134, 425442, 425403, 425116, 424104, 415512, 415108,
  389340, 388440, 388421, 388410, 388265, 388260, 388255, 388245, 388210,
  388180, 388140, 387710, 387640, 387540, 387360, 387355, 387350, 387345,
  387340, 387335, 387230, 387115, 383421, 383320, 383275, 383255, 383240,
  383225, 382308, 380025, 364765, 364740, 364730, 364720, 364510, 364330,
  364313, 364210, 364145, 364130, 364081, 362215, 361003, 344031, 341317,
  331303, 326501, 313342, 313334, 313333, 313330, 313329, 313327, 313325,
  313323, 313322, 313321, 313207, 313206, 313202, 311204, 311023, 305922,
  305203, 281104, 274502, 274409, 274302, 272161, 263658, 262124, 246736,
  245407, 227801, 223227, 212631, 206243, 203392, 182203, 174402, 171010,
  146107, 144044, 143408, 125101, 125058, 125037, 125022, 455112, 225004,
  828102, 125011, 125021, 125066, 144405, 303331, 305205, 307024, 312618,
  364760, 383006, 383422, 387365, 388239, 425509, 451442, 454129, 483053,
  497451, 506008, 507140, 782128, 782490, 788830, 822117, 829120, 835244,
  846008, 384345, 833212, 246159, 388543, 583122, 251003, 175021, 562162,
  798620, 833213, 833203, 833202, 788008, 786179, 769011, 759037, 713380,
  691590, 635652, 635106, 614623, 534218, 530015, 523171, 522234, 517589,
  497773, 497559, 497557, 470675, 461881, 441808, 440020, 425501, 391346,
  391220, 370040, 263158, 246177, 176097, 176071, 176055, 144033, 530035,
  628201, 628212, 628302, 685603, 754017, 124105, 382213, 781131, 457772,
  124001, 125001, 125002, 125004, 125005, 125006, 125033, 125050, 125055,
  126102, 244001, 244221, 251002, 306401, 307510, 331023, 335511, 335512,
  364290, 382424, 382475, 383315, 385001, 387375, 482002, 125007,
];
