import { NODE_DOMAIN, PHP_DOMAIN } from "../Domain";

export const TW_URLs = {
  GET_PROPOSAL_INFO: `${NODE_DOMAIN}/api/v1/premium/proposal-details`,
  GET_DETAILS_BY_QUOTE_NO: `${NODE_DOMAIN}/api/v1/premium/quotedetail`,
  GET_VEHICLE_NO_INFO: `${NODE_DOMAIN}/api/v1/premium/car-details?type=TW&CRegNo=`,
  GET_PREVIOUS_INSURER_LIST: `${NODE_DOMAIN}/api/v1/premium/previous-insurers?type=TW`,
  UPDATE_ADDON_STATUS: `${NODE_DOMAIN}/api/v1/premium/update-addon`,
  PREMIUM_RECALCULATION: `${NODE_DOMAIN}/api/v1/recalculate/motor`,
  CAPTURE_RECORD: `${NODE_DOMAIN}/api/v1/proposal/capture-records`,
  UPDATE_PERPOSAL_RECORDS: `${PHP_DOMAIN}/api/tw-proposals/capture-record.json`,
  UPDATE_FULL_PERPOSAL_RECORDS: `${PHP_DOMAIN}/api/tw-proposals/add.json`,
  GET_CITY_STATE_BY_PINCODE: `${NODE_DOMAIN}/api/v1/proposal/pincode`,
  saveQuotesForPickUpWhereYouLeft: `${PHP_DOMAIN}/api/lowest-premium-details/add.json`,
  VEHICLE_ON_LOAN: `${NODE_DOMAIN}/api/v1/proposal/bank`,
  PAYMENT_URL: `${PHP_DOMAIN}/api/tw-proposals/create-proposal.json`,
  BULK_UPDATE_ADDON_STATUS: `${NODE_DOMAIN}/api/v1/premium/update-bulk-addon`,
  GET_NOMINEE_RELATIONSHIP_LIST: `${NODE_DOMAIN}/api/v1/proposal/nominee/`,
  CIS_URL: `${PHP_DOMAIN}/api/hdfcs/cisDownload.json`,
  CREATE_RPOPOSAL: `${NODE_DOMAIN}/api/v1/proposal/motor`,
};
