import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { Box, Button, Link, Modal } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";
import { TW_ROUTES } from "../../../../Router/Path/TWRoutes";
import "../../../../SCSS/CommonSCSS/MProposalForms.scss";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import { TWSlice } from "../../../../Store/Slice_Reducer/TwoWheeler/TWSlice";
import { formatNumberWithCurrencySymbol } from "../../../../SupportingFiles/HelpingFunction";
import { TWPremiumCal } from "../../../../SupportingFiles/TWHelper";
import { FRONTEND_DOMAIN } from "../../../../URLCollection/Domain";
import CustomButton from "../../../../Component/CustomButton/CustomButton";

const MPremiumCalculationPopup = ({
  policyRevisionPopup,
  setPolicyRevisionPopup,
  recalculatedPremium,
}: {
  policyRevisionPopup: boolean;
  setPolicyRevisionPopup: any;
  recalculatedPremium: any;
}) => {
  const navigate = useNavigate();
  const { SELECTED_QUOTE_DATA, ADDON_STATUS, PAGE_STATUS, ADD_FORM_RESPONSE } =
    useAppSelector((state) => state.TW);
  const dispatch = useAppDispatch();
  const { VERIFY_KYC_FORM } = useAppSelector((state) => state.CKYC);
  const ContinueFun = () => {
    if (SELECTED_QUOTE_DATA.quotationDetail) {
      dispatch(
        TWSlice.actions.SET_SELECTED_QUOTE_DATA({
          ...SELECTED_QUOTE_DATA,
          quotationDetail: {
            ...SELECTED_QUOTE_DATA.quotationDetail,
            premiumDetails: {
              ...SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails,
              ...recalculatedPremium,
            },
          },
        })
      );
    }
    if (PAGE_STATUS) {
      navigate(-1);
    } else {
      if (VERIFY_KYC_FORM.customer_type.value === "Organization") {
        navigate(`${TW_ROUTES.PROPOSAL_PAGE}/${TW_ROUTES.ADDRESS_DETAILS}?type=TW&companyCode=${SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA.quotationDetail?.productDetails.id}`);
      } else {
        navigate(`${TW_ROUTES.PROPOSAL_PAGE}/${TW_ROUTES.NOMINEE_DETAILS}?type=TW&companyCode=${SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA.quotationDetail?.productDetails.id}`);
      }
    }
  };

  return (
    <Modal open={policyRevisionPopup} className="modalWrapper_m ">
      <Box className="modalInner">
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Link
              className="close-button"
              onClick={() => {
                setPolicyRevisionPopup(false);
              }}
            ></Link>
          </Grid>
        </Grid>
        <Grid container spacing={2} textAlign="center">
          <Grid xs={12}>
            <img
              src="../images/revision.svg"
              alt=""
              style={{ height: "90px" }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} textAlign="center">
          <Grid xs={12} className="mt-5">
            <img src={"/images/revision.svg"} width="240px" alt="" />
          </Grid>
          <Grid xs={12} className="mt-5">
            <h5>Policy Premium is Revised</h5>
          </Grid>
          {recalculatedPremium.premiumDetails ===
          SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails ? (
            <Grid xs={12}>
              <Box className="mb-3">
                {ADDON_STATUS.driver_cover.value && (
                  <div className="Section_li">
                    <div
                      className={
                        ADDON_STATUS.driver_cover.value === true &&
                        SELECTED_QUOTE_DATA?.quotationDetail?.premiumDetails &&
                        SELECTED_QUOTE_DATA?.quotationDetail?.premiumDetails
                          ?.paOwnerDriver > 0
                          ? "yes"
                          : "no"
                      }
                    >
                      Driver Cover
                    </div>
                  </div>
                )}
                {ADDON_STATUS.zero_depreciation.label && (
                  <div className="Section_li">
                    <div
                      className={
                        ADDON_STATUS.zero_depreciation.label &&
                        SELECTED_QUOTE_DATA?.quotationDetail?.premiumDetails &&
                        SELECTED_QUOTE_DATA?.quotationDetail?.premiumDetails
                          ?.depreciationCover > 0
                          ? "yes"
                          : "no"
                      }
                    >
                      Zero Depreciation
                    </div>
                  </div>
                )}
                {ADDON_STATUS.pa_owner_cover.value && (
                  <div className="Section_li">
                    <div
                      className={
                        ADDON_STATUS.pa_owner_cover.value === true &&
                        SELECTED_QUOTE_DATA?.quotationDetail?.premiumDetails &&
                        SELECTED_QUOTE_DATA?.quotationDetail?.premiumDetails
                          ?.paOwnerDriver > 0
                          ? "yes"
                          : "no"
                      }
                    >
                      Personal Accident Cover
                    </div>
                  </div>
                )}
                {ADDON_STATUS.rsa.value && (
                  <div className="Section_li">
                    <div
                      className={
                        ADDON_STATUS.rsa.value === true &&
                        SELECTED_QUOTE_DATA?.quotationDetail?.premiumDetails &&
                        SELECTED_QUOTE_DATA?.quotationDetail?.premiumDetails
                          ?.roadSideAssistance === 0
                          ? "yes"
                          : "no"
                      }
                    >
                      24x7 Roadside Assistance
                    </div>
                  </div>
                )}
              </Box>
              <p className="premium">
                <p className="premium">
                  {formatNumberWithCurrencySymbol(
                    TWPremiumCal(
                      recalculatedPremium,
                      ADDON_STATUS,
                      // ADDON_USER_VALUE.passengerCover.value,
                      // PLAN_TYPE,
                      VERIFY_KYC_FORM.customer_type.value
                    )
                  )}
                </p>
              </p>

              <h5 className="Idv_value">
                IDV <CurrencyRupeeIcon />
                {/* {SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails.IDV} */}
                {SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails.IDV !==
                recalculatedPremium.IDV ? (
                  <span>
                    {formatNumberWithCurrencySymbol(
                      `${SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails.IDV}`
                    )}
                  </span>
                ) : null}
                {formatNumberWithCurrencySymbol(recalculatedPremium.IDV)}
              </h5>
            </Grid>
          ) : (
            <Grid xs={12}>
              <Box display={"flex"} justifyContent={"center"} gap={"20px"}>
                {ADDON_STATUS.driver_cover.value && (
                  <div className="Section_li">
                    <div
                      className={
                        ADDON_STATUS.driver_cover.value === true &&
                        SELECTED_QUOTE_DATA?.quotationDetail?.premiumDetails &&
                        SELECTED_QUOTE_DATA?.quotationDetail?.premiumDetails
                          ?.paOwnerDriver > 0
                          ? "yes"
                          : "no"
                      }
                    >
                      Driver Cover
                    </div>
                  </div>
                )}
                {ADDON_STATUS.zero_depreciation.value && (
                  <div className="Section_li">
                    <div
                      className={
                        ADDON_STATUS.zero_depreciation.value === true &&
                        SELECTED_QUOTE_DATA?.quotationDetail?.premiumDetails &&
                        SELECTED_QUOTE_DATA?.quotationDetail?.premiumDetails
                          ?.depreciationCover > 0
                          ? "yes"
                          : "no"
                      }
                    >
                      Zero Depreciation
                    </div>
                  </div>
                )}
                {ADDON_STATUS.pa_owner_cover.value && (
                  <div className="Section_li">
                    <div
                      className={
                        ADDON_STATUS.pa_owner_cover.value === true &&
                        SELECTED_QUOTE_DATA?.quotationDetail?.premiumDetails &&
                        SELECTED_QUOTE_DATA?.quotationDetail?.premiumDetails
                          ?.paOwnerDriver > 0
                          ? "yes"
                          : "no"
                      }
                    >
                      Personal Accident Cover
                    </div>
                  </div>
                )}
                {ADDON_STATUS.rsa.value && (
                  <div className="Section_li">
                    <div
                      className={
                        ADDON_STATUS.rsa.value === true &&
                        SELECTED_QUOTE_DATA?.quotationDetail?.premiumDetails &&
                        SELECTED_QUOTE_DATA?.quotationDetail?.premiumDetails
                          ?.roadSideAssistance === 0
                          ? "yes"
                          : "no"
                      }
                    >
                      24x7 Roadside Assistance
                    </div>
                  </div>
                )}
              </Box>

              <p>Personal Accident Cover</p>
              <p className="premium">
                Premuim
                <span>
                  {formatNumberWithCurrencySymbol(
                    TWPremiumCal(
                      SELECTED_QUOTE_DATA.quotationDetail,
                      ADDON_STATUS,
                      VERIFY_KYC_FORM.customer_type.value
                    )
                  )}
                </span>
                {SELECTED_QUOTE_DATA.quotationDetail &&
                  formatNumberWithCurrencySymbol(
                    TWPremiumCal(
                      {
                        ...SELECTED_QUOTE_DATA.quotationDetail,
                        premiumDetails: {
                          ...SELECTED_QUOTE_DATA.quotationDetail
                            ?.premiumDetails,
                          ...recalculatedPremium,
                        },
                      },
                      ADDON_STATUS,
                      VERIFY_KYC_FORM.customer_type.value
                    )
                  )}
              </p>

              <h5 className="Idv_value">
                IDV{" "}
                {SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails.IDV !==
                recalculatedPremium.IDV ? (
                  <span>
                    {formatNumberWithCurrencySymbol(
                      `${SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails.IDV}`
                    )}
                  </span>
                ) : null}
                {formatNumberWithCurrencySymbol(recalculatedPremium.IDV)}
              </h5>
            </Grid>
          )}

          <Grid xs={12} style={{ textAlign: "center" }} className="ctaBtn">
            {/* <Button
              variant="contained"
              className="regularSecondaryBtn"
              onClick={() => {
                setPolicyRevisionPopup(false);
                ContinueFun();
              }}
            >
              Continue
            </Button> */}
            <CustomButton
              text_name={"Continue"}
              class_name="secondaryBtn"
              onClickFunction={() => {
                setPolicyRevisionPopup(false);
                ContinueFun();
              }}
            />
            <p
              onClick={() => {
                let url = `${FRONTEND_DOMAIN}${TW_ROUTES.QUOTE}?type=TW&quote_no=${ADD_FORM_RESPONSE.quote_no}`;

                // Check if the URL starts with "http:/" but not "http://"
                if (url.startsWith("http:/") && !url.startsWith("http://")) {
                  url = url.replace("http:/", "http://");
                } else if (
                  !url.startsWith("http://") &&
                  !url.startsWith("https://")
                ) {
                  url = "https://" + url;
                }
                window.location.href = url;
              }}
              style={{
                textAlign: "center",
                marginTop: "10px",
                color: "#568ee8",
              }}
            >
              Go back to Quotes.
            </p>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default MPremiumCalculationPopup;
