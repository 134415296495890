import React, { useEffect, useState } from "react";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { Box } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import RidersBenefits from "../../../Page/Desktop/Term/ShortPay/Godigit/RidersBenefits";
import { Id, toast } from "react-toastify";
import { TERM_ROUTES } from "../../../Router/Path/TermRoutes";
import { TERM_SERVICES } from "../../../Services/Term/TermServices";
import { useNavigate } from "react-router-dom";
import { TermSlice } from "../../../Store/Slice_Reducer/Term/TermSlice";
import { TRidersBenefitsResponse } from "../../../types/Term/TRidersBenifits";

const RidersBenefitsContainer = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { LOADER, SELECTED_QUOTE_DATA, ADD_FORM_RESPONSE, RIDERS_BENEFITS } =
    useAppSelector((state) => state.Term);
  const [loaderTf001, setLoaderTf001] = useState<boolean>(false);
  const [loaderTf002, setLoaderTf002] = useState<boolean>(false);
  const [loaderTf003, setLoaderTf003] = useState<boolean>(false);

  const continue_function = () => {
    const toastId = toast.loading("Please wait...");
    let params = {
      buy_action_url: SELECTED_QUOTE_DATA.productDetails.buy_online_code,
      quote_no: btoa(ADD_FORM_RESPONSE.quote_no),
      section: "add_ons_details",
      details: {},
    };

    const onSuccess = (res: any) => {
      toast.dismiss(toastId);
      navigate(
        `${TERM_ROUTES.BASIC_DETAILS}?type=TM&companyCode=${SELECTED_QUOTE_DATA?.companyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}`
      );
    };
    const onError = () => {};
    TERM_SERVICES.UPDATE_PROPOSER_DETAILS(onSuccess, onError, params);
  };
  let toastID: Id;
  const GET_RIDERS_PREMIUM = () => {
    dispatch(TermSlice.actions.UPDATE_LOADER_STATUS(true));
    const onSuccess = (data: any) => {
      toast.dismiss(toastID);
      setLoaderTf001(false);
      setLoaderTf002(false);
      setLoaderTf003(false);
      // const results = data.results;
      const results = data.response;
      const error = data.error;
      const message = data.message;
      const response: TRidersBenefitsResponse = results;

      if (!error) {
        dispatch(
          TermSlice.actions.UPDATE_LOADER_STATUS_AND_RIDER_RESPONSE({
            loader: false,
            rider_response: response,
          })
        );
      } else {
        toast.error("There are some technical error. Please contact to admin.");
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      dispatch(TermSlice.actions.UPDATE_LOADER_STATUS(false));
      toast.dismiss(toastID);
      setLoaderTf001(false);
      setLoaderTf002(false);
      setLoaderTf003(false);
      toast.error("There are some technical error. Please contact to admin.");
    };
    const data = {
      product_ids: [SELECTED_QUOTE_DATA.productDetails?.id],
      quote_no: ADD_FORM_RESPONSE?.quote_no,
      accidentalDeathSelected: RIDERS_BENEFITS.local_value.tf001Status
        ? "Yes"
        : "No",
      accidentalDisabilitySelected: RIDERS_BENEFITS.local_value.tf002Status
        ? "Yes"
        : "No",
      terminalIllnessSelected: RIDERS_BENEFITS.local_value.tf003Status
        ? "Yes"
        : "No",
    };
    toast.dismiss(toastID);
    toastID = toast.loading("Please wait...");
    TERM_SERVICES.UPDATE_RIDERS_STATUS(onSuccess, onError, data);
  };

  useEffect(() => {
    GET_RIDERS_PREMIUM();
  }, [
    RIDERS_BENEFITS.local_value.tf001Status,
    RIDERS_BENEFITS.local_value.tf002Status,
    RIDERS_BENEFITS.local_value.tf003Status,
  ]);

  return (
    <>
      {LOADER || loaderTf001 || loaderTf002 || loaderTf003 ? (
        <Box
          position={"fixed"}
          height={"100%"}
          width={"100%"}
          top="0"
          left="0"
          zIndex={911}
        />
      ) : null}
      {isMobile ? null : (
        <RidersBenefits
          forward={continue_function}
          loaderTf001={loaderTf001}
          loaderTf002={loaderTf002}
          loaderTf003={loaderTf003}
        />
      )}
    </>
  );
};

export default RidersBenefitsContainer;
