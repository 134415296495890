import blowfish from "egoroof-blowfish";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PaymentFailed from "../../../../Page/Desktop/TermFullJourney/HDFC/PaymentFailed/PaymentFailed";
import MPaymentConfirmation from "../../../../Page/Mobile/TermFullJourney/HDFC/PaymentConfirmation/MPaymentConfirmation";
import { TERM_FULL_JOURNEY_ROUTES } from "../../../../Router/Path/TermFullJourneyRoutes";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import { TermFullJourneySlice } from "../../../../Store/Slice_Reducer/TermFullJourney/TermFullJourneySlice";
import { ETermCompanyCode } from "../../../../Enum/ETermCompanyCode";
import { PROPOSAL_PAGE_SERVICES } from "../../../../Services/TermFullJourneyServices/ProposalPageServices";
import PaymentConfirmation from "../../../../Page/Desktop/TermFullJourney/HDFC/PaymentConfirmation/PaymentConfirmation";
import { TermSlice } from "../../../../Store/Slice_Reducer/Term/TermSlice";

function PaymentConfirmationPageContainer() {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { SELECTED_QUOTE_DATA, ADD_FORM_RESPONSE } = useAppSelector(
    (state) => state.Term
  );
  const dispatch = useAppDispatch();
  const [paymentStatus, setPaymentStatus] = useState<
    "SUCCESS" | "FAIL" | "PENDING"
  >("FAIL");
  const [paymentMessage, setPaymentMessage] = useState("");

  useEffect(() => {
    if (
      SELECTED_QUOTE_DATA.companyDetails.company_code === ETermCompanyCode.HDFC
    ) {
      const windowURL = window.location.search;
      const params = new URLSearchParams(windowURL);

      const appnum = params.get("appnum");
      const paymentstatus = params.get("paymentstatus");
      setPaymentMessage(paymentStatus);
      setPaymentStatus(paymentstatus === "Success" ? "SUCCESS" : "FAIL");

      dispatch(
        TermSlice.actions.UPDATE_QUOTE_DATA([
          {
            key: "appno",
            value: appnum || "",
          },
          {
            key: "transaction_id",
            value: appnum || "",
          },
        ])
      );

      sendConfirmationPageData({
        appnum: appnum,
        paymentstatus: paymentstatus,
      });
    } else if (
      SELECTED_QUOTE_DATA.companyDetails.company_code === ETermCompanyCode.ICICI
    ) {
      const windowURL = window.location.search;
      const params = new URLSearchParams(windowURL);

      const key = "!i@*6@($%(";
      let encrypted = params.get("encJson") || "";
      encrypted = encrypted.replace(/\s/g, "+");

      const bf = new blowfish(key, blowfish.MODE.ECB, blowfish.PADDING.NULL);

      // Convert the encrypted value from Base64 to Uint8Array
      const encryptedBytes = Uint8Array.from(atob(encrypted), (c) =>
        c.charCodeAt(0)
      );

      // Decrypt the value
      const decrypted = bf.decode(encryptedBytes);

      const cleanedDecrypted = `${decrypted}`.replace(
        /[\u0000-\u001F\u007F-\u009F]/g,
        ""
      );

      const decryptedJSON = JSON.parse(`${cleanedDecrypted}`);

      const app_no = decryptedJSON.identificationCode;
      const transaction_id = decryptedJSON.transactionID;
      dispatch(
        TermSlice.actions.UPDATE_QUOTE_DATA([
          {
            key: "transaction_id",
            value: transaction_id,
          },
          {
            key: "appno",
            value: app_no || decryptedJSON?.identificationCode,
          },
        ])
      );

      const paymentStatus = decryptedJSON.paymentStatus;
      setPaymentStatus(
        `${paymentStatus}`.toLowerCase() === "success" ||
          `${paymentStatus}`.toLowerCase() === "pending realisation"
          ? "SUCCESS"
          : "FAIL"
      );
      sendConfirmationPageData(decryptedJSON);
    }
  }, []);

  const sendConfirmationPageData = (response: any) => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;

      if (error === false) {
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      console.log(err);
    };

    const data = {
      buy_online_code: SELECTED_QUOTE_DATA.productDetails.buy_online_code,
      quote_no: btoa(ADD_FORM_RESPONSE.quote_no),
      response: response,
    };

    PROPOSAL_PAGE_SERVICES.sendPaymentConfirmationData(
      onSuccess,
      onError,
      data
    );
  };

  const continue_function = () => {
    navigate(TERM_FULL_JOURNEY_ROUTES.JOURNEY_REQUIREMENT);
  };
  return (
    <>
      {isMobile ? (
        <>
          {paymentStatus === "FAIL" ? (
            <PaymentFailed status={paymentMessage} />
          ) : (
            <MPaymentConfirmation continue_function={continue_function} />
          )}
        </>
      ) : (
        <>
          {paymentStatus === "FAIL" ? (
            <PaymentFailed status={paymentMessage} />
          ) : (
            <PaymentConfirmation
              continue_function={continue_function}
              payment_status={paymentStatus}
            />
          )}
        </>
      )}
    </>
  );
}

export default PaymentConfirmationPageContainer;
