import { NODE_DOMAIN } from "../Domain";

export const HIURLs = {
  get_quick_quotes_by_quote_id: `${NODE_DOMAIN}/api/v1/home/quote`,
  get_household_detail_by_quote_id: (id: string) =>
    `${NODE_DOMAIN}/api/v1/home/form/${id}`,
  update_household_details: `${NODE_DOMAIN}/api/v1/home/update-form`,
  get_city_state_by_pincode: `${NODE_DOMAIN}/api/v1/proposal/pincode`,
  policy_buy_action: `${NODE_DOMAIN}/api/v1/home/buyaction`,

  get_document_type_list: (company_code: string, doc_for: string) =>
    `${NODE_DOMAIN}/api/v1/home/ckyc/${company_code}/doc/${doc_for}`,

  ckyc_digit: `${NODE_DOMAIN}/api/v1/home/kyc/godigit`,
  manual_kyc_digit: `${NODE_DOMAIN}/api/v1/home/kyc/godigit/manual`,
  save_proposal_info: (quoteId: string) =>
    `${NODE_DOMAIN}/api/v1/proposal/update/${quoteId}`,

  create_quote: `${NODE_DOMAIN}/api/v1/home/createQuote`,

  icici_create_quote: `${NODE_DOMAIN}/api/v1/home/createQuote/icici`,

  get_policy_pdf: (policyNo: string) =>
    `${NODE_DOMAIN}/api/v1/home/pdf/${policyNo}`,
  get_payment_status: (policyNo: string) =>
    `${NODE_DOMAIN}/api/v1/home/paymentStatus/${policyNo}`,
  update_digit_quote_premium: (quoteId: string) =>
    `${NODE_DOMAIN}/api/v1/home/proposal/${quoteId}`,

  update_icici_quote_premium: (quoteId: string) =>
    `${NODE_DOMAIN}/api/v1/home/proposal/icici/${quoteId}`,

  get_company_list: `${NODE_DOMAIN}/api/v1/home/company/list`,

  get_nominee_relations: (productId: string) =>
    `${NODE_DOMAIN}/api/v1/proposal/nominee/${productId}`,

  get_bank_list: (companyCode: string, bankName?: string) =>
    `${NODE_DOMAIN}/api/v1/proposal/bank?product_code=${companyCode}&bankName=${bankName}`,
  get_porposal_page_info: (quoteId: string) =>
    `${NODE_DOMAIN}/api/v1/proposal/details/${quoteId}`,

  verify_icici_kyc: `${NODE_DOMAIN}/api/v1/home/kyc/icici`,

  get_plan_details: (productId: string) =>
    `${NODE_DOMAIN}/api/v1/home/plan/details/${productId}`,

  email_quotes: `${NODE_DOMAIN}/api/v1/home/email/quote`,

  download_brochure: (productId: string) =>
    `${NODE_DOMAIN}/api/v1/home/brochures/${productId}`,

  icici_payment_verify: (quoteNo: string, redirectUrl: string) =>
    `${NODE_DOMAIN}/api/v1/home/quote/icici/verify/${quoteNo}?redirectUrl=${redirectUrl}`,

  icici_policy_pdf: (quoteNo: string, policyNo: string) =>
    `${NODE_DOMAIN}/api/v1/home/icici/pdf?quote_no=${quoteNo}&policyNo=${policyNo}`,

  icici_manual_kyc: `${NODE_DOMAIN}/api/v1/home/kyc/icici/manual`,

  // icici_manual_kyc: () => {
  //   `${NODE_DOMAIN}/api/v1/home/kyc/icici/manual`;
  // },
};
