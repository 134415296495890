import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ECompanyCode } from "../../../../Services/Enum/EHome";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import {
  isEmpty,
  validateFullName,
} from "../../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import { TDropdown } from "../../../../types/Common/TDropdown";
import { TOtherMemberDetails } from "../../../../types/Travel/TTravelSlice";
import { TRAVEL_PROPOSAL_SERVICES } from "../../../../Services/Travel/TravelPropsalServices";
import { TravelSlice } from "../../../../Store/Slice_Reducer/Travel/TravelSlice";
import ChildFourDetails from "../../../../Page/Desktop/Travel/CARE/ChildFourDetails/ChildFourDetails";
import { TRAVEL_ROUTE } from "../../../../Router/Path/TravelRoutes";

export default function ChildFourDetailsContainer() {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { Travel } = useAppSelector((state) => state);
  const {
    ADD_FORM,
    INSURED_MEMBER_DETAILS,
    SELECTED_QUOTE_DATA,
    PAGE_STATUS,
    ADD_FORM_RESPONSE,
  } = useAppSelector((state) => state.Travel);
  const [memberFields, setMemberFields] = useState<TOtherMemberDetails>(
    INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS
  );

  useEffect(() => {
    setMemberFields({ ...INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS });
  }, [INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.dob.value]);

  const fieldsUpdateState = (attrName: any, value: any) => {
    setMemberFields((prev: any) => ({
      ...prev,
      [attrName]: {
        value: value,
        warning: false,
      },
    }));
  }

  const SAVE_CHILD_DATA = (data: TOtherMemberDetails) => {
    const onSuccess = (res: any) => {
      // const results = res.results;
      // const error = results.error;
      const windowURL = window.location.search;
      const params = new URLSearchParams(windowURL);
      const cus_type = params.get("cus_type");

      if (PAGE_STATUS) {
        navigate(
          `${TRAVEL_ROUTE.PROPOSAL_PAGE}/${TRAVEL_ROUTE.PREVIEW_DETAILS
          }?type=TI&companyCode=${SELECTED_QUOTE_DATA?.companyCode
          }&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productID
          }${cus_type === "user" ? "&cus_type=user" : ""}`
        );
      } else {
        navigate(
          `${TRAVEL_ROUTE.PROPOSAL_PAGE}/${TRAVEL_ROUTE.ADDRESS_DETAILS
          }?type=TI&companyCode=${SELECTED_QUOTE_DATA?.companyCode
          }&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productID
          }${cus_type === "user" ? "&cus_type=user" : ""}`
        );
      }
    }

    const onError = (err: any) => {
      console.log("errr", err);
    };
    let param = {
      child4Gender: data.relationship.value === "daughter" ? "F" : "M",
      child4Name: data.name.value,
      child4DOB: data.dob.value,
      child4Weight: data.weight.value,
      child4HeightFeet: data.heightFeet.value,
      child4HeightInches: data.heightInches.value,
      section: "child4_details",
      quote_no: ADD_FORM_RESPONSE.quote_no,
      cover: ADD_FORM.members.value,
    };
    TRAVEL_PROPOSAL_SERVICES.getCapturedRecordData(onSuccess, onError, param);
  };

  const validateForm = () => {
    let formValue = { ...memberFields };
    formValue = {
      ...formValue,
      dob: { ...formValue.dob, warning: isEmpty(formValue.dob.value) },
      heightFeet: {
        ...formValue.heightFeet,
        warning: isEmpty(formValue.heightFeet.value),
      },
      heightInches: {
        ...formValue.heightInches,
        warning: isEmpty(formValue.heightInches.value),
      },
      name: {
        ...formValue.name,
        warning: !validateFullName(formValue.name.value),
      },
      relationship: {
        ...formValue.relationship,
        warning: isEmpty(formValue.relationship.value),
      },
      weight: { ...formValue.weight, warning: isEmpty(formValue.weight.value) },
    };
    setMemberFields({ ...formValue });

    if (
      !formValue.dob.warning &&
      !formValue.heightFeet.warning &&
      !formValue.heightInches.warning &&
      !formValue.name.warning &&
      !formValue.relationship.warning &&
      !formValue.weight.warning
    ) {
      const windowURL = window.location.search;
      const params = new URLSearchParams(windowURL);
      const cus_type = params.get("cus_type");

      SAVE_CHILD_DATA(formValue);

      dispatch(
        TravelSlice.actions.BULK_UPLOAD({
          ...Travel,
          INSURED_MEMBER_DETAILS: {
            ...Travel.INSURED_MEMBER_DETAILS,
            CHILD_FOUR_DETAILS: { ...formValue },
          },
          PAGE_STATUS: false,
        })
      );
    }
  };
  return isMobile ? (
    null
  ) : (
    <ChildFourDetails
      fieldsUpdateState={fieldsUpdateState}
      memberFields={memberFields}
      validateForm={validateForm}
    />
  );
}
