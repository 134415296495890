import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddressDetails from "../../../Page/Desktop/TW/IffcoTokio/AddressDetails";
import MAddressDetails from "../../../Page/Mobile/TW/IffcoTokio/MAddressDetails";
import { TW_ROUTES } from "../../../Router/Path/TWRoutes";
import { TW_SERVICES } from "../../../Services/TW/TWServices";
import { useAppSelector } from "../../../Store/hooks";
import { TWSlice } from "../../../Store/Slice_Reducer/TwoWheeler/TWSlice";
import {
  isEmpty,
  validatePincode,
} from "../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { TAddressDetails } from "../../../types/Common/TAddressDetails";
import { CLIENTS } from "../../../URLCollection/Clients";
import {
  pushMotorDataLayer,
  sendEventToNativeApp,
} from "../../../utils/analytics";

const AddressDetailsContainer = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const {
    ADDRESS_DETAILS,
    PAGE_STATUS,
    ADD_FORM_RESPONSE,
    REDIRECTED,
    SELECTED_QUOTE_DATA,
  } = useAppSelector((state) => state.TW);

  const [pageStatus, setPageStatus] = useState<number>(1);
  const [mailingAddressTab, setMailingAddressTab] = useState(
    ADDRESS_DETAILS?.isMailingAddressSame === false ? false : true
  );
  // Permanent Address
  const [permanentAddress, setPermanentAddress] =
    useState<TAddressDetails>(ADDRESS_DETAILS);
  const validateAttribute = (attrName: string, value: any): boolean => {
    switch (attrName) {
      case "pincode":
        return !validatePincode(value);
      case "mailingPinCode":
        return !validatePincode(value);
      default:
        return isEmpty(value);
    }
  };

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "pageStatus") {
      validateForm();
      setPageStatus(value);
    } else if (attrName === "backbutton") {
      navigate(-1);
    } else {
      const updatedAttribute = {
        value: value,
        warning: validateAttribute(attrName[0], value),
      };
      attrName[3]((prevDetails: any) => ({
        ...prevDetails,
        [attrName[0]]: { ...prevDetails[attrName[0]], ...updatedAttribute },
      }));
    }
  };

  const handleClick = () => {
    setPermanentAddress((prev) => ({
      ...prev,
      isMailingAddressSame: !prev.isMailingAddressSame,
      mailingPinCode: { ...prev.mailingPinCode, warning: false },
      mailingAddressLineOne: {
        ...prev.mailingAddressLineOne,
        warning: false,
      },
      mailingAddressLineTwo: {
        ...prev.mailingAddressLineTwo,
        warning: false,
      },
      mailingCity: prev.mailingCity,
      mailingState: prev.mailingState,
    }));
  };
  const GetCityStateByPin = (key: string, value: any) => {
    const onSuccess = (res: any) => {
      const results = res?.results;
      const error = results.error;
      if (error === false) {
        const response = results?.response;
        const city = response?.city;
        const state = response?.state;
        let updatedPermanentAddress: TAddressDetails = { ...permanentAddress };
        if (key === "cityState") {
          updatedPermanentAddress = {
            ...updatedPermanentAddress,
            city: city,
            state: state,
          };

          setPermanentAddress(updatedPermanentAddress);
        } else if (key === "mailingAddresscityState") {
          updatedPermanentAddress = {
            ...updatedPermanentAddress,
            mailingCity: city,
            mailingState: state,
          };

          setPermanentAddress(updatedPermanentAddress);
        }
      }
    };

    const onError = (err: any) => {
      console.log("error", err);
    };
    // SELECTED_QUOTE_DATA.product_code;
    let param = {
      product_code: "HPAB02",
      pincode: key === "mailingAddresscityState" ? value : value,
    };

    TW_SERVICES.GetCityStateByPin(onSuccess, onError, param);
  };

  const validateForm = () => {
    let data: TAddressDetails = { ...permanentAddress };

    data = {
      ...data,
      pincode: {
        ...data.pincode,
        warning: !validatePincode(data.pincode.value),
      },
      addressLineOne: {
        ...data.addressLineOne,
        warning: isEmpty(data.addressLineOne.value),
      },
      addressLineTwo: {
        ...data.addressLineTwo,
        warning: isEmpty(data.addressLineTwo.value),
      },
    };

    if (!data.isMailingAddressSame) {
      data = {
        ...data,
        mailingPinCode: {
          ...data.mailingPinCode,
          warning: !validatePincode(data.mailingPinCode.value),
        },
        mailingAddressLineOne: {
          ...data.mailingAddressLineOne,
          warning: isEmpty(data.mailingAddressLineOne.value),
        },
        mailingAddressLineTwo: {
          ...data.mailingAddressLineTwo,
          warning: isEmpty(data.mailingAddressLineTwo.value),
        },
      };
    }

    let hasError: boolean = false;

    if (
      data.pincode.warning ||
      data.addressLineOne.warning ||
      data.addressLineTwo.warning ||
      hasError
    ) {
      hasError = true;
    }

    if (!data.isMailingAddressSame) {
      if (
        data.mailingPinCode.warning ||
        data.mailingAddressLineOne.warning ||
        data.mailingAddressLineTwo.warning ||
        hasError
      ) {
        hasError = true;
      }
    }
    if (!hasError) {
      if (window.location.hostname === CLIENTS.JIO) {
        pushMotorDataLayer(
          "jioinsure_flow_details",
          "address",
          "proceed",
          "two wheeler",
          REDIRECTED ? "JFS app" : "Insure website"
        );
        const eventProps = {
          action: "address",
          click: "continue",
          type: "two wheeler",
          EntrySource: REDIRECTED ? "JFS app" : "Insure website",
        };
        sendEventToNativeApp("jioinsure_flow_details", eventProps);
      }

      if (PAGE_STATUS) {
        navigate(
          `${TW_ROUTES.PROPOSAL_PAGE}/${TW_ROUTES.PREVIEW_DEATILS}?type=TW&companyCode=${SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA.quotationDetail?.productDetails.id}`
        );
      } else {
        navigate(
          `${TW_ROUTES.PROPOSAL_PAGE}/${TW_ROUTES.PREVIEW_DEATILS}?type=TW&companyCode=${SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA.quotationDetail?.productDetails.id}`
        );
      }
      updateRecords();
      dispatch(TWSlice.actions.ADDRESS_DETAILS(data));
    }
    setPermanentAddress(data);
  };
  useEffect(() => {
    if (validatePincode(permanentAddress.pincode.value)) {
    GetCityStateByPin("cityState", permanentAddress.pincode.value);
    }
  }, [permanentAddress.pincode.value]);

  useEffect(() => {
    if (validatePincode(permanentAddress.mailingPinCode.value)) {
    GetCityStateByPin(
      "mailingAddresscityState",
      permanentAddress?.mailingPinCode?.value
    );}
  }, [permanentAddress?.mailingPinCode?.value]);

  const updateRecords = () => {
    const onSuccess = (res: any) => {
      const results = res.results;
      const error = results.error;
      if (error === false) {
        const response = results.response;
      }
    };

    const onError = (err: any) => {
      console.log("error", err);
    };
    let param = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      addressPHouse: permanentAddress.addressLineOne.value,
      addressPPincode: permanentAddress.pincode.value,
      addressPArea: permanentAddress.addressLineTwo.value,
      addressPCity: permanentAddress.city?.split(" ")[0],
      addressPState: permanentAddress.state?.split(" ")[1],
      addressMailingSame: mailingAddressTab ? 1 : 0,
      addressMHouse: permanentAddress.mailingAddressLineOne.value,
      addressMPincode: permanentAddress.mailingPinCode.value,
      addressMArea: permanentAddress.mailingAddressLineTwo.value,
      addressMCity: permanentAddress.mailingCity?.split(" ")[0],
      addressMState: permanentAddress.mailingState?.split(" ")[1],
      section: "address_details",
      insurance_type: "TW",
    };

    TW_SERVICES.CAPTURE_RECORD_ON_EVERY_STEP(onSuccess, onError, param);
  };

  return (
    <>
      {isMobile ? (
        <MAddressDetails
          permanentAddress={permanentAddress}
          updateMasterState={updateMasterState}
          mailingAddressTab={mailingAddressTab}
          handleClick={handleClick}
          setPermanentAddress={setPermanentAddress}
          pageStatus={pageStatus}
          validateForm={validateForm}
        />
      ) : (
        <AddressDetails
          permanentAddress={permanentAddress}
          updateMasterState={updateMasterState}
          mailingAddressTab={mailingAddressTab}
          handleClick={handleClick}
          setPermanentAddress={setPermanentAddress}
          pageStatus={pageStatus}
        />
      )}
    </>
  );
};

export default AddressDetailsContainer;
