import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import RKTextField from "../../../../../Component/FieldTypes/RKTextField/RKTextField";
import Footer from "../../../../../Component/Footer/Footer";
import { useAppSelector } from "../../../../../Store/hooks";
import { isEmpty } from "../../../../../SupportingFiles/HelpingFunction";
import { TBank_Details_CIGNA } from "../../../../../types/Health/THealthSlice";
import DatePicker from "../../../../../Component/FieldTypes/DatePicker/DatePicker";
import { subYears } from "date-fns";
import SelectDropdown from "../../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";

const BankDetails = ({
  Bank_Details_CIGNA,
  updateBankDetailsCigna,
  disbaledButton,
  validateForm,
  detailsFetched,
}: {
  Bank_Details_CIGNA: TBank_Details_CIGNA;
  updateBankDetailsCigna: Function;
  validateForm: Function;
  disbaledButton: boolean;
  detailsFetched: boolean;
}) => {
  const { PAGE_STATUS } = useAppSelector((state) => state.Health);
  return (
    <>
      <Box>
        <h5 className="sectionTitle">Proposer's Bank Details</h5>
        <Grid container spacing={3}></Grid>
        <Grid container spacing={3}>
          <Grid xs={6}>
            <RKTextField
              class_name="inputField"
              title={"Account Number"}
              value={Bank_Details_CIGNA?.AccountNum?.value}
              attrName={"AccountNum"}
              value_update={updateBankDetailsCigna}
              warn_status={Bank_Details_CIGNA?.AccountNum?.warning}
              error_message={"Enter Account Number"}
              validation_type="NUMBER"
            />
          </Grid>
          <Grid xs={6}>
            <RKTextField
              class_name="inputField"
              title={"Account Holder Name"}
              value={Bank_Details_CIGNA?.AccountHolderName?.value}
              attrName={"AccountHolderName"}
              value_update={updateBankDetailsCigna}
              warn_status={Bank_Details_CIGNA?.AccountHolderName?.warning}
              error_message={"Enter Account Holder Name"}
            />
          </Grid>
          <Grid xs={6}>
          <RKTextField
              class_name="inputField"
              title="Account Category"
              value={Bank_Details_CIGNA?.AccountCategoryCd?.value}
              attrName={"AccountCategoryCd"}
              value_update={updateBankDetailsCigna}
              warn_status={Bank_Details_CIGNA?.AccountCategoryCd?.warning}
              error_message={"Enter Bank Account Category"}
            />
            {/* <SelectDropdown
              class_name="inputField"
              title="Account Category"
              value={Bank_Details_CIGNA?.AccountCategoryCd?.value}
              attrName={"AccountCategoryCd"}
              value_update={updateBankDetailsCigna}
              data={[
                {
                  label: "Asset",
                  value: "asset",
                },
                {
                  label: "Liability",
                  value: "liability",
                },
                {
                  label: "Equity",
                  value: "equity",
                },
                {
                  label: "Revenue",
                  value: "revenue",
                },
              ]}
              warn_status={Bank_Details_CIGNA?.AccountCategoryCd?.warning}
              error_message={"Enter Bank Account Category"}
            /> */}
          </Grid>
          <Grid xs={6}>
            <SelectDropdown
              class_name="inputField"
              title="Bank Account Type"
              value={Bank_Details_CIGNA?.AccountTypeCd?.value}
              attrName={"AccountTypeCd"}
              value_update={updateBankDetailsCigna}
              data={[
                {
                  label: "Saving",
                  value: "Saving",
                },
                {
                  label: "Current",
                  value: "Current",
                },
              ]}
              warn_status={Bank_Details_CIGNA?.AccountTypeCd?.warning}
              error_message={"Enter Bank Account Type"}
            />
          </Grid>
          <Grid xs={6}>
            <RKTextField
              class_name="inputField"
              title={"IFSC Code"}
              value={Bank_Details_CIGNA?.IfscCd?.value}
              attrName={"IfscCd"}
              value_update={updateBankDetailsCigna}
              warn_status={Bank_Details_CIGNA?.IfscCd?.warning}
              error_message={"Enter IFSC Code"}
              max_length={11}
              validation_type="ALPHANUMERIC"
            />
          </Grid>
          <Grid xs={6}>
            <RKTextField
              class_name="inputField"
              title={"Bank Code"}
              value={Bank_Details_CIGNA?.BankCd?.value}
              attrName={"BankCd"}
              value_update={updateBankDetailsCigna}
              warn_status={Bank_Details_CIGNA?.BankCd?.warning}
              error_message={"Enter Bank Code"}
              validation_type="ALPHANUMERIC"
              disabled={
                detailsFetched
                  ? !isEmpty(Bank_Details_CIGNA?.BankCd?.value)
                  : false
              }
            />
          </Grid>
          <Grid xs={6}>
            <RKTextField
              class_name="inputField"
              title={"Sort Code"}
              value={Bank_Details_CIGNA?.SortCd?.value}
              attrName={"SortCd"}
              value_update={updateBankDetailsCigna}
              warn_status={Bank_Details_CIGNA?.SortCd?.warning}
              max_length={6}
              validation_type="NUMBER"
            />
          </Grid>

          <Grid xs={6}>
            <RKTextField
              class_name="inputField"
              title={"Bank Name"}
              value={Bank_Details_CIGNA?.BankName?.value}
              attrName={"BankName"}
              value_update={updateBankDetailsCigna}
              warn_status={Bank_Details_CIGNA?.BankName?.warning}
              error_message={"Enter Bank Name"}
              disabled={
                detailsFetched
                  ? !isEmpty(Bank_Details_CIGNA?.BankName?.value)
                  : false
              }
            />
          </Grid>
          <Grid xs={6}>
            <RKTextField
              class_name="inputField"
              title={"Branch Name"}
              value={Bank_Details_CIGNA?.BranchName?.value}
              attrName={"BranchName"}
              value_update={updateBankDetailsCigna}
              warn_status={Bank_Details_CIGNA?.BranchName?.warning}
              error_message={"Enter Branch Name"}
              disabled={
                detailsFetched
                  ? !isEmpty(Bank_Details_CIGNA?.BranchName?.value)
                  : false
              }
            />
          </Grid>
          <Grid xs={6}>
            <RKTextField
              class_name="inputField"
              title={"Branch Code"}
              value={Bank_Details_CIGNA?.BranchCd?.value}
              attrName={"BranchCd"}
              value_update={updateBankDetailsCigna}
              warn_status={Bank_Details_CIGNA?.BranchCd?.warning}
              error_message={"Enter Branch code"}
              validation_type="ALPHANUMERIC"
            />
          </Grid>
          <Grid xs={6}>
            <RKTextField
              class_name="inputField"
              title={"Ledger Folio Number"}
              value={Bank_Details_CIGNA?.LedgerFolioNum?.value}
              attrName={"LedgerFolioNum"}
              value_update={updateBankDetailsCigna}
              warn_status={Bank_Details_CIGNA?.LedgerFolioNum?.warning}
              error_message={"Ledger Folio Number"}
              validation_type="NUMBER"
            />
          </Grid>
          <Grid xs={6}>
          <RKTextField
              class_name="inputField"
              title="Card Type"
              value={Bank_Details_CIGNA?.CardTypeCd?.value}
              attrName={"CardTypeCd"}
              value_update={updateBankDetailsCigna}
              warn_status={Bank_Details_CIGNA?.CardTypeCd?.warning}
              error_message={"Enter Card Type"}
            />
            {/* <SelectDropdown
              class_name="inputField"
              title="Card Type"
              value={Bank_Details_CIGNA?.CardTypeCd?.value}
              attrName={"CardTypeCd"}
              value_update={updateBankDetailsCigna}
              data={[
                {
                  label: "ATM",
                  value: "ATM",
                },
                {
                  label: "Debit",
                  value: "Debit",
                },
                {
                  label: "Credit",
                  value: "Credit",
                },
                {
                  label: "Prepaid",
                  value: "Prepaid",
                },
              ]}
              warn_status={Bank_Details_CIGNA?.CardTypeCd?.warning}
              error_message={"Enter Card Type"}
            /> */}
          </Grid>
          <Grid xs={6}>
            <DatePicker
              class_name="inputField"
              title={"Card Expiry Date"}
              value={Bank_Details_CIGNA?.CardExpiryDt?.value}
              attrName={"CardExpiryDt"}
              date_validation_type="DAYS"
              min_date={-1}
              max_date={-(365 * 5)}
              value_update={updateBankDetailsCigna}
              error_message={"Enter Card Expiry Date"}
              warn_status={Bank_Details_CIGNA?.CardExpiryDt?.warning}
            />
          </Grid>

          <Grid xs={6}>
            <RKTextField
              class_name="inputField"
              title={"State"}
              value={Bank_Details_CIGNA?.State?.value}
              attrName={"State"}
              value_update={updateBankDetailsCigna}
              disabled={
                detailsFetched
                  ? !isEmpty(Bank_Details_CIGNA?.State?.value)
                  : false
              }
            />
          </Grid>
          <Grid xs={6}>
            <RKTextField
              class_name="inputField"
              title={"City"}
              value={Bank_Details_CIGNA?.City?.value}
              attrName={"City"}
              value_update={updateBankDetailsCigna}
              warn_status={Bank_Details_CIGNA?.City?.warning}
              disabled={
                detailsFetched
                  ? !isEmpty(Bank_Details_CIGNA?.City?.value)
                  : false
              }
            />
          </Grid>
          <Grid xs={6}>
            <RKTextField
              class_name="inputField"
              title={"MICR No"}
              value={Bank_Details_CIGNA?.MicrNum?.value}
              attrName={"MicrNum"}
              value_update={updateBankDetailsCigna}
              warn_status={Bank_Details_CIGNA?.MicrNum?.warning}
              disabled={
                detailsFetched
                  ? !isEmpty(Bank_Details_CIGNA?.MicrNum?.value)
                  : false
              }
            />
          </Grid>
        </Grid>
        <Footer
          attrName={"pageStatus"}
          textName={PAGE_STATUS ? "Update" : "Continue"}
          value={1}
          value_update={() => {}}
          forward={() => {
            validateForm();
          }}
          disableButton={disbaledButton}
        />
      </Box>
    </>
  );
};

export default BankDetails;
