import ManualKYC from "../../../../../Page/Desktop/HI/ProposalPage/GoDigit/ManualKYC/ManualKYC";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";

const ManualKYCContainer = () => {
  const isMobile = useIsMobile();

  return <>{isMobile ? <></> : <ManualKYC />}</>;
};

export default ManualKYCContainer;
