import { GetAPI, PatchAPI, PostAPI } from "../../Axios/AxiosInstanceToken";
import { HIURLs } from "../../URLCollection/HI/HIURLs";

const get_document_type_list = (
  onSuccess: any,
  onError: any,
  companyCode: string,
  docFor: string
) => {
  GetAPI.call(
    `${HIURLs.get_document_type_list(companyCode, docFor)}`,
    onSuccess,
    onError
  );
};

const get_city_state_pincode = (
  onSuccess: any,
  onError: any,
  pincode: string
) => {
  PostAPI.call(
    HIURLs.get_city_state_by_pincode,
    { pincode: pincode },
    onSuccess,
    onError
  );
};

const ckyc_digit = (onSuccess: any, onError: any, dto: any) => {
  PostAPI.call(HIURLs.ckyc_digit, dto, onSuccess, onError);
};

const ckyc_icici = (onSuccess: any, onError: any, dto: any) => {
  PostAPI.call(HIURLs.verify_icici_kyc, dto, onSuccess, onError);
};

const manual_kyc_digit = (onSuccess: any, onError: any, dto: any) => {
  PostAPI.call(HIURLs.manual_kyc_digit, dto, onSuccess, onError);
};

const save_proposal_info = (
  onSuccess: any,
  onError: any,
  dto: any,
  quoteId: string
) => {
  PatchAPI.call(HIURLs.save_proposal_info(quoteId), dto, onSuccess, onError);
};

const get_nominee_relations = (
  onSuccess: any,
  onError: any,
  propertyId: string
) => {
  GetAPI.call(HIURLs.get_nominee_relations(propertyId), "", onSuccess, onError);
};

const get_bank_list = (
  onSuccess: any,
  onError: any,
  params: { companyCode: string; bankName?: string }
) => {
  GetAPI.call(
    HIURLs.get_bank_list(params.companyCode, params?.bankName),
    onSuccess,
    onError
  );
};

const get_proposal_page_info = (
  onSuccess: any,
  onError: any,
  quoteId: string
) => {
  GetAPI.call(
    HIURLs.get_porposal_page_info(quoteId),
    onSuccess,

    onError
  );
};

const icici_manual_kyc = (onSuccess: any, onError: any, dto: any) => {
  PostAPI.call(HIURLs.icici_manual_kyc, dto, onSuccess, onError);
};

export const PROPOSAL_SERVICES = {
  get_document_type_list,
  ckyc_digit,
  ckyc_icici,
  manual_kyc_digit,
  save_proposal_info,
  get_nominee_relations,
  get_bank_list,
  get_proposal_page_info,
  icici_manual_kyc,
  get_city_state_pincode,
};
