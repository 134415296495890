import { Route, Routes } from "react-router-dom";
import { TRAVEL_ROUTE } from "../../../../Path/TravelRoutes";
import TravelProposalContainer from "../../TravelProposalContainer";
import AdultOneDetailsContainer from "../../../../../Container/Travel/CARE/AdultOneDetailsContainer/AdultOneDetailsContainer";
import InsuredMemberContainer from "../../../../../Container/Travel/CARE/InsuredMemberContainer/InsuredMemberContainer";
import MemberDetailContainer from "../../../../../Container/Travel/CARE/AdultTwoDetailsContainer/AdultTwoDetailsContainer";
import ChildOneDetailsContainer from "../../../../../Container/Travel/CARE/ChildOneDetailsContainer/ChildOneDetailsContainer";
import ChildTwoDetailsContainer from "../../../../../Container/Travel/CARE/ChildTwoDetailsContainer/ChildTwoDetailsContainer";
import AddressDetailsContainer from "../../../../../Container/Travel/CARE/AddressDetailsContainer/AddressDetailsContainer";
import ChildFourDetailsContainer from "../../../../../Container/Travel/CARE/ChildFourDetailsContainer/ChildFourDetailsContainer";
import ChildThreeDetailsContainer from "../../../../../Container/Travel/CARE/ChildThreeDetailsContainer/ChildThreeDetailsContainer";
import PreviewDetailsContainer from "../../../../../Container/Travel/CARE/PreviewDetailsContainer/PreviewDetailsContainer";


export const COMMON_ROUTES = () => {
  return (
    <Routes>
      <Route
        path={TRAVEL_ROUTE.PROPOSAL_PAGE}
        element={<TravelProposalContainer />}>
        <Route
          path={TRAVEL_ROUTE.ADULT_ONE_DETAILS}
          element={<AdultOneDetailsContainer />}
        />
        <Route
          path={TRAVEL_ROUTE.INSURED_MEMBER}
          element={<InsuredMemberContainer />}
        />
        <Route
          path={TRAVEL_ROUTE.ADULT_TWO_DETAILS}
          element={<MemberDetailContainer />}
        />
        <Route
          path={TRAVEL_ROUTE.CHILD_ONE_DETAILS}
          element={<ChildOneDetailsContainer />}
        />
        <Route
          path={TRAVEL_ROUTE.CHILD_TWO_DETAILS}
          element={<ChildTwoDetailsContainer />}
        />
        <Route
          path={TRAVEL_ROUTE.CHILD_THREE_DETAILS}
          element={<ChildThreeDetailsContainer />}
        />
        <Route
          path={TRAVEL_ROUTE.CHILD_FOUR_DETAILS}
          element={<ChildFourDetailsContainer />}
        />
        <Route
          path={TRAVEL_ROUTE.ADDRESS_DETAILS}
          element={<AddressDetailsContainer />}
        />
        <Route
          path={TRAVEL_ROUTE.PREVIEW_DETAILS}
          element={<PreviewDetailsContainer />}
        />
      </Route>
    </Routes>
  );
};

