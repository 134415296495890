import { GetAPI, PostAPI } from "../../Axios/AxiosInstanceToken";
import { ETermFullJourneyMasterDropdown } from "../../Enum/ETermFullJourneyMasterDropdown";
import { TERM_FULL_JOURNEY_API_COLLECTION } from "../../URLCollection/TermFullJourney/TermFullJourney";

const getDetailsViaQuoteNo = (
  onSuccess: Function,
  onError: Function,
  query?: any
) => {
  GetAPI.call(
    TERM_FULL_JOURNEY_API_COLLECTION.GET_QUOTE_DETAILS,
    (res: any) => onSuccess(res.data),
    // {
    //   params: query,
    // },
    onError
  );
};

const GET_OCCUPATION_LIST = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    TERM_FULL_JOURNEY_API_COLLECTION.GET_OCCUPATION_LIST,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const getMaritalStatusList = (
  onSuccess: Function,
  onError: Function,
  params?: any
) => {
  PostAPI.call(
    TERM_FULL_JOURNEY_API_COLLECTION.GET_MARITAL_STATUS,
    params,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const createPaymentLink = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    TERM_FULL_JOURNEY_API_COLLECTION.CREATE_PAYMENT_LINK,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};


const hdfcCreatePaymentLink = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    TERM_FULL_JOURNEY_API_COLLECTION.HDFC_TERM_CREATE_PAYMENT_LINK,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};


const getPremiumMax = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  GetAPI.call(
    TERM_FULL_JOURNEY_API_COLLECTION.getPremiumMax,
    (res: any) => onSuccess(res.data),
    // {
    //   params: dto,
    // },
    onError
  );
};

const updateProposalPageDetails = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    TERM_FULL_JOURNEY_API_COLLECTION.PERPOSAL_TERM_DETAILS,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const updateProposalPageHDFCDetails = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    TERM_FULL_JOURNEY_API_COLLECTION.UPDATE_PROPOSAL_PAGE_HDFC_DATA,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const getCityStateOnBasisOfPincode = (
  onSuccess: (data: any) => void,

  onError: (data: any) => void,

  dto: {
    pincode: any;
  }
) => {
  PostAPI.call(
    TERM_FULL_JOURNEY_API_COLLECTION.GET_CITY_STATE_BY_PINCODE_ONLY,
    dto,

    (res: any) => onSuccess(res.data),

    onError
  );
};



const getDropdownDataMaster = (
  onSuccess: Function,
  onError: Function,
  prams: any
) => {
  PostAPI.call(
    TERM_FULL_JOURNEY_API_COLLECTION.GET_MASTER_DATA_ICICI,
    prams,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const getDropdownDataMasterICICI = (
  onSuccess: Function,
  onError: Function,
  prams: any
) => {
  PostAPI.call(
    TERM_FULL_JOURNEY_API_COLLECTION.GET_MASTER_DATA_ICICI,
    prams,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const getInsuranceCompanyMaster = (
  onSuccess: Function,
  onError: Function,
  param: any
) => {
  PostAPI.call(
    TERM_FULL_JOURNEY_API_COLLECTION.INSURANCE_COMPANY_MASTER,
    param,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const getCountryMaster = (onSuccess: Function, onError: Function) => {
  PostAPI.call(
    TERM_FULL_JOURNEY_API_COLLECTION.COUNTRY_MASTER,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const investmentProposalSubmission = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    TERM_FULL_JOURNEY_API_COLLECTION.INVESTMENT_PROPOSAL_SUB,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const sendPaymentConfirmationData = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    TERM_FULL_JOURNEY_API_COLLECTION.SEND_PAYMENT_CONFIRMATION_DATA,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const getProposalDetails = (
  onSuccess: Function,
  onError: Function,
  query: any
) => {
  GetAPI.call(
    TERM_FULL_JOURNEY_API_COLLECTION.GET_TERM_PROPOSAL_DETAILS,
    (res: any) => onSuccess(res.data),
    // {
    //   params: query,
    // },
    onError
  );
};

// const termGetPremiumICICI = (
//   onSuccess: Function,
//   onError: Function,
//   query: any
// ) => {
//   GetAPI.call(
//     TERM_FULL_JOURNEY_API_COLLECTION.ICICI_TERM_GET_PREMIUM,
//     (res: any) => onSuccess(res.data),
//     // {
//     //   params: query,
//     // },
//     onError
//   );
// };

const termGetPremiumICICI = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    TERM_FULL_JOURNEY_API_COLLECTION.PERPOSAL_TERM_DETAILS,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const GET_PROPOSAL_DETAILS = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    TERM_FULL_JOURNEY_API_COLLECTION.GET_PROPOSAL_DETAILS,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const GET_EDUCATION_MASTER = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    TERM_FULL_JOURNEY_API_COLLECTION.EDUCATION_MASTER,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const getNomineeRelationshipList = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    TERM_FULL_JOURNEY_API_COLLECTION.GET_NOMINEE_RELATIONSHIP_LIST,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const GET_REDIRECTION_URL = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    TERM_FULL_JOURNEY_API_COLLECTION.GET_REDIRECTION_URL,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

export const PROPOSAL_PAGE_SERVICES = {
  GET_EDUCATION_MASTER,
  termGetPremiumICICI,
  getDetailsViaQuoteNo,
  GET_OCCUPATION_LIST,
  getMaritalStatusList,
  updateProposalPageDetails,
  getNomineeRelationshipList,
  getCityStateOnBasisOfPincode,
  createPaymentLink,
  hdfcCreatePaymentLink,
  getDropdownDataMaster,
  getInsuranceCompanyMaster,
  getCountryMaster,
  investmentProposalSubmission,
  updateProposalPageHDFCDetails,
  sendPaymentConfirmationData,
  getProposalDetails,
  getDropdownDataMasterICICI,
  getPremiumMax,
  GET_PROPOSAL_DETAILS,
  GET_REDIRECTION_URL,
};
