import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import Navbar from "../../../Component/Navbar/Navbar";
import MNavbar from "../../../Component/MNavbar/MNavbar";
import HealthLeftSidebar from "../../../Component/HealthLeftSidebar/HealthLeftSidebar";

function HealthProposalContainer() {
  const isMobile = useIsMobile();

  const location = useLocation();
  const [path, setPath] = useState<string>("");

  useEffect(() => {
    const currentPath = location.pathname;
    setPath(currentPath);
  }, [location.pathname]);
  return (
    <Box className={`${isMobile ? "proposalWrapper_m" : "proposalWrapper"}`}>
      <>{isMobile ? <MNavbar /> : <Navbar />}</>
      <Grid container spacing={3} className="pb-0">
        {!isMobile && <HealthLeftSidebar />}
        <Grid xs className="proposalContentSection">
          <Outlet />
        </Grid>
      </Grid>
    </Box>
  );
}

export default HealthProposalContainer;
