import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import InspectionConfContainer from "../../../Container/Common/InspectionConfContainer";
import PaymentConfContainer from "../../../Container/Common/PaymentConfContainer";
import { CAR_ROUTES } from "../../Path/CarRoutes";
import { COMMON_ROUTES } from "../../Path/CommonRoutes";

function CommonRoutes() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div className="App">
      <Routes>
        <Route
          path={COMMON_ROUTES.PAYMENT_CONFIRMATION}
          element={<PaymentConfContainer />}
        />
        <Route
          path={COMMON_ROUTES.PAYMENT_CONFIRMATION_HEALTH}
          element={<PaymentConfContainer />}
        />
        <Route
          path={COMMON_ROUTES.PAYMENT_CONFIRMATION_CAR}
          element={<PaymentConfContainer />}
        />
        <Route
          path={COMMON_ROUTES.PAYMENT_CONFIRMATION_TW}
          element={<PaymentConfContainer />}
        />
        <Route
          path={CAR_ROUTES.INSPECTION_CONFIRMATION}
          element={<InspectionConfContainer />}
        />
      </Routes>
    </div>
  );
}

export default CommonRoutes;
