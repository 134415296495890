import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import MedicalDetailsComp from "../../../../Page/Desktop/health-php/GODIGIT/MedicalDetails";
import MMedicalDetails from "../../../../Page/Mobile/health-php/GODIGIT/MMedicalDetails";
import { HEALTH_ROUTE } from "../../../../Router/Path/HealthRoutes";
import { HEALTH_PROPOSAL_SERVICES } from "../../../../Services/health-php/HealthPropsalServices";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import { HealthSlice } from "../../../../Store/Slice_Reducer/Health/HealthSlice";
import { isEmpty } from "../../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import { TGoDigitMedicalQuestion } from "../../../../types/Health/HealthQuotation/TGodigitMedicalQuestion";
import { THealthQuote } from "../../../../types/Health/HealthQuotation/THealthQuote";
import {
  Asthma_Current_medication,
  Asthma_diagnosed,
  Consume_alcohal,
  Yes_Or_No,
  asthma_suffered,
  diabetes_diagnosed,
  diabetes_fbs,
  diabetes_hbA1c_value,
  diabetes_last_blood,
  diabetes_medication,
  diabetes_type,
  hyperlipidemia_diagnosed,
  hypertension_diagnosed,
  hypertension_diastolic,
  hypertension_systolic,
  hypertension_tablets,
  thyroid_diagnosed,
  thyroid_dysfunction,
  thyroid_medication,
  tobacco_consume,
  viral_fever_miltiple,
} from "./MedicalDropdownValue";

const MedicalDetailsContainerComp = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    INSURED_MEMBER_DETAILS,
    PAGE_STATUS,
    MEDICAL_QUESTION_DATA_GO_DIGIT,
    SELECTED_QUOTE_DATA,
    ADD_FORM_RESPONSE,
    Preview_Loader,
  } = useAppSelector((state) => state.Health);
  const [change_status, setchange_status] = useState(false);
  const [medicalQuestionData, setMedicalQuestionData] =
    useState<TGoDigitMedicalQuestion>(MEDICAL_QUESTION_DATA_GO_DIGIT);

  const [selectedItems, setSelectedItems] = useState([]);

  const handleChange = (event: any) => {
    setSelectedItems(event.target.value);
  };
  console.log("selectedItems", selectedItems);

  const setMemberDataAsPerUserSelection = () => {
    let setMemberData: any = { ...MEDICAL_QUESTION_DATA_GO_DIGIT };
    setMemberData = {
      MQ01: {
        main_question: {
          id: "MQ01",
          icon_class: "tobaco",
          title: "Pre-Existing Diseases",
          description:
            "Do you have any existing condition or disease for which they are being treated and/or and evaluated currently or in the past or have been diagnosed with? Other than common cold or viral fever?",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title:
                    "What are Your current and past medical conditions or disease?",
                  field_type: "MILTIPLE_DROPDOWN",
                  value: { value: [], warning: false },
                  data_value: viral_fever_miltiple,
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title:
                    "Have you been infected with Covid-19 in the last 15 days?",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title:
                    "What are Your current and past medical conditions or disease?",
                  field_type: "MILTIPLE_DROPDOWN",
                  value: { value: [], warning: false },
                  data_value: viral_fever_miltiple,
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title:
                    "Have you been infected with Covid-19 in the last 15 days?",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title:
                    "What are Your current and past medical conditions or disease?",
                  field_type: "MILTIPLE_DROPDOWN",
                  value: { value: [], warning: false },
                  data_value: viral_fever_miltiple,
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title:
                    "Have you been infected with Covid-19 in the last 15 days?",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title:
                    "What are Your current and past medical conditions or disease?",
                  field_type: "MILTIPLE_DROPDOWN",
                  value: { value: [], warning: false },
                  data_value: viral_fever_miltiple,
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title:
                    "Have you been infected with Covid-19 in the last 15 days?",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title:
                    "What are Your current and past medical conditions or disease?",
                  field_type: "MILTIPLE_DROPDOWN",
                  value: { value: [], warning: false },
                  data_value: viral_fever_miltiple,
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title:
                    "Have you been infected with Covid-19 in the last 15 days?",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title:
                    "What are Your current and past medical conditions or disease?",
                  field_type: "MILTIPLE_DROPDOWN",
                  value: { value: [], warning: false },
                  data_value: viral_fever_miltiple,
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title:
                    "Have you been infected with Covid-19 in the last 15 days?",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
      MQ02: {
        main_question: {
          id: "MQ02",
          icon_class: "tobaco",
          title: "Hospitalised",
          description: "",
          status: false,
          sub_question: {
            "MQ1-SQ1": {
              id: "MQ1-SQ1",
              icon_class: "",
              status: false,
              title: "Do you have Hyperlipidemia?",
              description: "",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Any complications with high lipids",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "On medication for High lipids",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "  When was it diagnosed",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: hyperlipidemia_diagnosed,
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Any complications with high lipids",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "On medication for High lipids",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "  When was it diagnosed",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: hyperlipidemia_diagnosed,
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Any complications with high lipids",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "On medication for High lipids",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "  When was it diagnosed",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: hyperlipidemia_diagnosed,
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Any complications with high lipids",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "On medication for High lipids",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "  When was it diagnosed",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: hyperlipidemia_diagnosed,
                    },
                  },
                  selection_status: false,
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,

                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Any complications with high lipids",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "On medication for High lipids",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "  When was it diagnosed",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: hyperlipidemia_diagnosed,
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Any complications with high lipids",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "On medication for High lipids",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "  When was it diagnosed",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: hyperlipidemia_diagnosed,
                    },
                  },
                },
              },
            },
            "MQ2-SQ2": {
              id: "MQ2-SQ2",
              icon_class: "",
              status: false,
              title: "Do you have Diabetes?",
              description: "",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Type of Diabetes",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_type,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "What was FBS value?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_fbs,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Current Medication",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_medication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "When was it diagnosed?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_diagnosed,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "When was your last Blood sugar tests done",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_last_blood,
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Were you hospitalized due to Diabetes?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F7": {
                      id: "MQ1-SQ1-F7",
                      title: "Do you have records of hospitalization, if any?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F8": {
                      id: "MQ1-SQ1-F8",
                      title: "Any complications for Diabetes",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F9": {
                      id: "MQ1-SQ1-F9",
                      title: "Do you have records of complications, if any?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F10": {
                      id: "MQ1-SQ1-F10",
                      title: "What was HbA1c value??",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_hbA1c_value,
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Type of Diabetes",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_type,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "What was FBS value?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_fbs,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Current Medication",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_medication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "When was it diagnosed?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_diagnosed,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "When was your last Blood sugar tests done",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_last_blood,
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Were you hospitalized due to Diabetes?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F7": {
                      id: "MQ1-SQ1-F7",
                      title: "Do you have records of hospitalization, if any?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F8": {
                      id: "MQ1-SQ1-F8",
                      title: "Any complications for Diabetes",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F9": {
                      id: "MQ1-SQ1-F9",
                      title: "Do you have records of complications, if any?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F10": {
                      id: "MQ1-SQ1-F10",
                      title: "What was HbA1c value??",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_hbA1c_value,
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Type of Diabetes",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_type,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "What was FBS value?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_fbs,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Current Medication",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_medication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "When was it diagnosed?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_diagnosed,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "When was your last Blood sugar tests done",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_last_blood,
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Were you hospitalized due to Diabetes?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F7": {
                      id: "MQ1-SQ1-F7",
                      title: "Do you have records of hospitalization, if any?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F8": {
                      id: "MQ1-SQ1-F8",
                      title: "Any complications for Diabetes",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F9": {
                      id: "MQ1-SQ1-F9",
                      title: "Do you have records of complications, if any?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F10": {
                      id: "MQ1-SQ1-F10",
                      title: "What was HbA1c value??",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_hbA1c_value,
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Type of Diabetes",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_type,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "What was FBS value?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_fbs,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Current Medication",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_medication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "When was it diagnosed?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_diagnosed,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "When was your last Blood sugar tests done",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_last_blood,
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Were you hospitalized due to Diabetes?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F7": {
                      id: "MQ1-SQ1-F7",
                      title: "Do you have records of hospitalization, if any?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F8": {
                      id: "MQ1-SQ1-F8",
                      title: "Any complications for Diabetes",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F9": {
                      id: "MQ1-SQ1-F9",
                      title: "Do you have records of complications, if any?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F10": {
                      id: "MQ1-SQ1-F10",
                      title: "What was HbA1c value??",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_hbA1c_value,
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Type of Diabetes",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_type,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "What was FBS value?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_fbs,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Current Medication",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_medication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "When was it diagnosed?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_diagnosed,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "When was your last Blood sugar tests done",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_last_blood,
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Were you hospitalized due to Diabetes?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F7": {
                      id: "MQ1-SQ1-F7",
                      title: "Do you have records of hospitalization, if any?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F8": {
                      id: "MQ1-SQ1-F8",
                      title: "Any complications for Diabetes",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F9": {
                      id: "MQ1-SQ1-F9",
                      title: "Do you have records of complications, if any?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F10": {
                      id: "MQ1-SQ1-F10",
                      title: "What was HbA1c value??",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_hbA1c_value,
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Type of Diabetes",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_type,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "What was FBS value?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_fbs,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Current Medication",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_medication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "When was it diagnosed?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_diagnosed,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "When was your last Blood sugar tests done",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_last_blood,
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Were you hospitalized due to Diabetes?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F7": {
                      id: "MQ1-SQ1-F7",
                      title: "Do you have records of hospitalization, if any?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F8": {
                      id: "MQ1-SQ1-F8",
                      title: "Any complications for Diabetes",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F9": {
                      id: "MQ1-SQ1-F9",
                      title: "Do you have records of complications, if any?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F10": {
                      id: "MQ1-SQ1-F10",
                      title: "What was HbA1c value??",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_hbA1c_value,
                    },
                  },
                },
              },
            },
            "MQ3-SQ3": {
              id: "MQ3-SQ3",
              icon_class: "",
              status: false,
              title: "Do you have Asthma?",
              description: "",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "When was it diagnosed?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Asthma_diagnosed,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Medication",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Asthma_Current_medication,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title:
                        "How many times a year have you suffered with Asthma",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: asthma_suffered,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Were you hospitalized due to Asthma?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Do you have records of hospitalization, if any?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Any complications due to Asthma?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F7": {
                      id: "MQ1-SQ1-F7",
                      title: "Do you have records of complications, if any?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "When was it diagnosed?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Asthma_diagnosed,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Medication",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Asthma_Current_medication,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title:
                        "How many times a year have you suffered with Asthma",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: asthma_suffered,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Were you hospitalized due to Asthma?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Do you have records of hospitalization, if any?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Any complications due to Asthma?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F7": {
                      id: "MQ1-SQ1-F7",
                      title: "Do you have records of complications, if any?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "When was it diagnosed?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Asthma_diagnosed,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Medication",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Asthma_Current_medication,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title:
                        "How many times a year have you suffered with Asthma",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: asthma_suffered,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Were you hospitalized due to Asthma?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Do you have records of hospitalization, if any?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Any complications due to Asthma?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F7": {
                      id: "MQ1-SQ1-F7",
                      title: "Do you have records of complications, if any?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "When was it diagnosed?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Asthma_diagnosed,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Medication",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Asthma_Current_medication,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title:
                        "How many times a year have you suffered with Asthma",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: asthma_suffered,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Were you hospitalized due to Asthma?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Do you have records of hospitalization, if any?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Any complications due to Asthma?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F7": {
                      id: "MQ1-SQ1-F7",
                      title: "Do you have records of complications, if any?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "When was it diagnosed?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Asthma_diagnosed,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Medication",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Asthma_Current_medication,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title:
                        "How many times a year have you suffered with Asthma",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: asthma_suffered,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Were you hospitalized due to Asthma?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Do you have records of hospitalization, if any?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Any complications due to Asthma?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F7": {
                      id: "MQ1-SQ1-F7",
                      title: "Do you have records of complications, if any?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "When was it diagnosed?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Asthma_diagnosed,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Medication",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Asthma_Current_medication,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title:
                        "How many times a year have you suffered with Asthma",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: asthma_suffered,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Were you hospitalized due to Asthma?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Do you have records of hospitalization, if any?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Any complications due to Asthma?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F7": {
                      id: "MQ1-SQ1-F7",
                      title: "Do you have records of complications, if any?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                  },
                },
              },
            },
            "MQ4-SQ4": {
              id: "MQ4-SQ4",
              icon_class: "",
              status: false,
              title: "Do you have Thyroid?",
              description: "",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Type of Thyroid dysfunction?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: thyroid_dysfunction,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Medication",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: thyroid_medication,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "When was it diagnosed?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: thyroid_diagnosed,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Any associated complications",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Type of Thyroid dysfunction?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: thyroid_dysfunction,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Medication",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: thyroid_medication,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "When was it diagnosed?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: thyroid_diagnosed,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Any associated complications",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Type of Thyroid dysfunction?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: thyroid_dysfunction,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Medication",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: thyroid_medication,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "When was it diagnosed?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: thyroid_diagnosed,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Any associated complications",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Type of Thyroid dysfunction?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: thyroid_dysfunction,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Medication",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: thyroid_medication,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "When was it diagnosed?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: thyroid_diagnosed,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Any associated complications",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,

                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Type of Thyroid dysfunction?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: thyroid_dysfunction,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Medication",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: thyroid_medication,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "When was it diagnosed?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: thyroid_diagnosed,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Any associated complications",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Type of Thyroid dysfunction?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: thyroid_dysfunction,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Medication",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: thyroid_medication,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "When was it diagnosed?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: thyroid_diagnosed,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Any associated complications",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                  },
                },
              },
            },
            "MQ5-SQ5": {
              id: "MQ5-SQ5",
              icon_class: "",
              status: false,
              title: "Do you have Hypertension?",
              description: "",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "When was it diagnosed?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: hypertension_diagnosed,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "No. of tablets",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: hypertension_tablets,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Systolic Reading",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: hypertension_systolic,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diastolic Reading",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: hypertension_diastolic,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title:
                        "Any complications related to Hypertension (related to nerves, eyes, heart or kidney)",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Do you have records of complications, if any?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F7": {
                      id: "MQ1-SQ1-F7",
                      title: "Were you hospitalized due to Hypertension?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "When was it diagnosed?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: hypertension_diagnosed,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "No. of tablets",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: hypertension_tablets,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Systolic Reading",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: hypertension_systolic,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diastolic Reading",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: hypertension_diastolic,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title:
                        "Any complications related to Hypertension (related to nerves, eyes, heart or kidney)",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Do you have records of complications, if any?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F7": {
                      id: "MQ1-SQ1-F7",
                      title: "Were you hospitalized due to Hypertension?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "When was it diagnosed?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: hypertension_diagnosed,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "No. of tablets",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: hypertension_tablets,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Systolic Reading",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: hypertension_systolic,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diastolic Reading",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: hypertension_diastolic,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title:
                        "Any complications related to Hypertension (related to nerves, eyes, heart or kidney)",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Do you have records of complications, if any?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F7": {
                      id: "MQ1-SQ1-F7",
                      title: "Were you hospitalized due to Hypertension?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "When was it diagnosed?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: hypertension_diagnosed,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "No. of tablets",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: hypertension_tablets,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Systolic Reading",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: hypertension_systolic,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diastolic Reading",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: hypertension_diastolic,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title:
                        "Any complications related to Hypertension (related to nerves, eyes, heart or kidney)",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Do you have records of complications, if any?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F7": {
                      id: "MQ1-SQ1-F7",
                      title: "Were you hospitalized due to Hypertension?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "When was it diagnosed?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: hypertension_diagnosed,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "No. of tablets",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: hypertension_tablets,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Systolic Reading",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: hypertension_systolic,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diastolic Reading",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: hypertension_diastolic,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title:
                        "Any complications related to Hypertension (related to nerves, eyes, heart or kidney)",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Do you have records of complications, if any?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F7": {
                      id: "MQ1-SQ1-F7",
                      title: "Were you hospitalized due to Hypertension?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "When was it diagnosed?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: hypertension_diagnosed,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "No. of tablets",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: hypertension_tablets,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Systolic Reading",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: hypertension_systolic,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diastolic Reading",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: hypertension_diastolic,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title:
                        "Any complications related to Hypertension (related to nerves, eyes, heart or kidney)",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Do you have records of complications, if any?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                    "MQ1-SQ1-F7": {
                      id: "MQ1-SQ1-F7",
                      title: "Were you hospitalized due to Hypertension?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Yes_Or_No,
                    },
                  },
                },
              },
            },
            "MQ6-SQ6": {
              id: "MQ6-SQ6",
              icon_class: "",
              status: false,
              title: "Do you  have any Gynaecological problem ?",
              description: "",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "what is the name of your condition?",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "what is the name of your condition?",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "what is the name of your condition?",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "what is the name of your condition?",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,

                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "what is the name of your condition?",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "what is the name of your condition?",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
              },
            },
            "MQ7-SQ7": {
              id: "MQ7-SQ7",
              icon_class: "",
              status: false,
              title: "Is any member going through any medications currently",
              description: "",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Name of the medicines taken?",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Name of the medicines taken?",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Name of the medicines taken?",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Name of the medicines taken?",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,

                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Name of the medicines taken?",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Name of the medicines taken?",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
              },
            },
            "MQ8-SQ8": {
              id: "MQ8-SQ8",
              icon_class: "",
              status: false,
              title:
                "Do you face any symptoms like chest pain, fatigue, weight loss, dizziness, joint pain, change in bowel habit, difficulty in breathing, pain in abdomen, bleeding/pain while passing stools etc?",
              description: "",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Details of your undiagnosed symptoms?",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Details of your undiagnosed symptoms?",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Details of your undiagnosed symptoms?",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Details of your undiagnosed symptoms?",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,

                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Details of your undiagnosed symptoms?",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Details of your undiagnosed symptoms?",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
              },
            },
          },
        },
      },
      MQ03: {
        main_question: {
          id: "MQ03",
          icon_class: "tobaco",
          title: "Intoxication Declaration",
          description:
            "Select `Yes` if any insured member Smokes, consumes Tobacco in any form, or drinks Alcohol?",
          status: false,
          sub_question: {
            "MQ1-SQ1": {
              id: "MQ1-SQ1",
              icon_class: "",
              status: false,
              title: "Do you consume alcohol?",
              description: "",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "How often do you consume alchohol?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Consume_alcohal,
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "How often do you consume alchohol?",
                      field_type: "DROPDOWN",
                      data_value: Consume_alcohal,
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "How often do you consume alchohol?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Consume_alcohal,
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "How often do you consume alchohol?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Consume_alcohal,
                    },
                  },
                  selection_status: false,
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,

                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "How often do you consume alchohol?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Consume_alcohal,
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "How often do you consume alchohol?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Consume_alcohal,
                    },
                  },
                },
              },
            },
            "MQ2-SQ2": {
              id: "MQ2-SQ2",
              icon_class: "",
              status: false,
              title: "Do you consume tobacco?",
              description: "",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "In which form do you consumes tobacco?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: tobacco_consume,
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "In which form do you consumes tobacco?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: tobacco_consume,
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "In which form do you consumes tobacco?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: tobacco_consume,
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "In which form do you consumes tobacco?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: tobacco_consume,
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "In which form do you consumes tobacco?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: tobacco_consume,
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "In which form do you consumes tobacco?",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: tobacco_consume,
                    },
                  },
                },
              },
            },
          },
        },
      },
    };
    setMedicalQuestionData({ ...setMemberData });
  };

  useEffect(() => {
    if (PAGE_STATUS === true || Preview_Loader === true) {
      setMedicalQuestionData(MEDICAL_QUESTION_DATA_GO_DIGIT);
    } else {
      setMemberDataAsPerUserSelection();
    }
  }, [MEDICAL_QUESTION_DATA_GO_DIGIT]);

  let flag = true;

  const updateMasterState = (attrName: any, value: any) => {
    flag = value;

    // console.log('sss',flag)
    console.log(attrName, "attrName", value);
    let manipulatedMedicalQuestion: any = JSON.parse(
      JSON.stringify(medicalQuestionData)
    );
    if (attrName[0] === "question") {
      console.log(manipulatedMedicalQuestion[attrName[1]], "Before Update");
      manipulatedMedicalQuestion = {
        ...manipulatedMedicalQuestion,
        [attrName[1]]: {
          ...manipulatedMedicalQuestion[attrName[1]],
          main_question: {
            ...manipulatedMedicalQuestion[attrName[1]].main_question,
            status: value,
          },
        },
      };
      if (value === false && attrName[1] === "MQ01") {
        if (!value) {
          let memberData =
            manipulatedMedicalQuestion?.[attrName[1]]?.main_question
              .member_data;
          Object.keys(memberData).forEach((memberDataKey: any) => {
            memberData[memberDataKey].selection_status = false;
            let fieldData = memberData[memberDataKey]?.field_data;
            Object.keys(fieldData).forEach((fieldDataKey: any) => {
              fieldData[fieldDataKey].value = {
                value:
                  fieldData[fieldDataKey].field_type === "TEXTFIELD" ? "" : [],
                warning: false,
              };
            });
          });
        }
      }

      if (!value && (attrName[1] === "MQ02" || attrName[1] === "MQ03")) {
        let subQues =
          manipulatedMedicalQuestion?.[attrName[1]]?.main_question.sub_question;
        Object.keys(subQues).forEach((subQuesKey: any) => {
          console.log("sub ques", subQues[subQuesKey]);
          subQues[subQuesKey].status = false;
          let memberData = subQues[subQuesKey].member_data;
          Object.keys(memberData).forEach((memberDataKey: any) => {
            memberData[memberDataKey].selection_status = false;
            let fieldData = memberData[memberDataKey].field_data;

            Object.keys(fieldData).forEach((fieldDataKey: any) => {
              fieldData[fieldDataKey].value = {
                value:
                  fieldData[fieldDataKey].field_type === "TEXTFIELD" ? "" : [],
                warning: false,
              };
            });
          });
        });
      }

      setMedicalQuestionData({ ...manipulatedMedicalQuestion });
    } else if (attrName[0] === "subQuestion") {
      setchange_status(flag);

      let updatedSubQuestion = {
        ...manipulatedMedicalQuestion[attrName[1]].main_question.sub_question,
        [attrName[2]]: {
          ...manipulatedMedicalQuestion[attrName[1]].main_question.sub_question[
            attrName[2]
          ],
          status: value,
        },
      };
      let updatedMainQuestion = {
        ...manipulatedMedicalQuestion[attrName[1]].main_question,
        sub_question: updatedSubQuestion,
      };

      let updatedManipulatedMedicalQuestion = {
        ...manipulatedMedicalQuestion,
        [attrName[1]]: {
          ...manipulatedMedicalQuestion[attrName[1]],
          main_question: updatedMainQuestion,
        },
      };

      let subQues =
        manipulatedMedicalQuestion?.[attrName[1]]?.main_question.sub_question;
      Object.keys(subQues).forEach((subQuesKey: any) => {
        console.log("subQues", subQues[subQuesKey]);
        if (subQues[subQuesKey].id === attrName[2]) {
          subQues[subQuesKey].status = false;
          // }
          let memberData = subQues[subQuesKey].member_data;
          Object.keys(memberData).forEach((memberDataKey: any) => {
            if (memberData[memberDataKey].id === attrName[3]) {
              memberData[memberDataKey].selection_status = false;
            }
            let fieldData = memberData[memberDataKey].field_data;

            Object.keys(fieldData).forEach((fieldDataKey: any) => {
              fieldData[fieldDataKey].value = {
                value:
                  fieldData[fieldDataKey].field_type === "TEXTFIELD" ? "" : [],
                warning: false,
              };
            });
          });
        }
      });

      setMedicalQuestionData(updatedManipulatedMedicalQuestion);
    } else if (attrName[0] === "mainMember") {
      if (
        manipulatedMedicalQuestion[attrName[1]]?.main_question.sub_question[
          attrName[2]
        ]?.member_data[attrName[3]] !== undefined
      ) {
        manipulatedMedicalQuestion = {
          ...manipulatedMedicalQuestion,
          [attrName[1]]: {
            ...manipulatedMedicalQuestion[attrName[1]],
            main_question: {
              ...manipulatedMedicalQuestion[attrName[1]]?.main_question,
              sub_question: {
                ...manipulatedMedicalQuestion[attrName[1]].main_question
                  .sub_question,
                [attrName[2]]: {
                  ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                    .sub_question?.[attrName[2]],
                  member_data: {
                    ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                      .sub_question?.[attrName[2]].member_data,
                    [attrName[3]]: {
                      ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                        .sub_question?.[attrName[2]].member_data?.[attrName[3]],
                      selection_status: value,
                    },
                  },
                },
              },
            },
          },
        };

        if (!value) {
          let memberData =
            manipulatedMedicalQuestion?.[attrName[1]]?.main_question
              .sub_question?.[attrName[2]].member_data;

          console.log("mem data", memberData);

          Object.keys(memberData).forEach((memberDataKey: any) => {
            if (memberData[memberDataKey].keyName === attrName[3]) {
              const fieldData = memberData[memberDataKey].field_data;
              Object.keys(fieldData).forEach((fieldDataKey: any) => {
                fieldData[fieldDataKey].value = {
                  value:
                    fieldData[fieldDataKey].field_type === "TEXTFIELD"
                      ? ""
                      : [],
                  warning: false,
                };
              });
            }
          });
        }

        setMedicalQuestionData({ ...manipulatedMedicalQuestion });
      }
    } else if (attrName[0] === "mainMemberData") {
      let mainQues = manipulatedMedicalQuestion[attrName[1]]?.main_question;
      if (
        manipulatedMedicalQuestion[attrName[1]]?.main_question?.member_data[
          attrName[2]
        ] !== undefined
      )
        if (!value && mainQues.status) {
          let memberData = mainQues?.member_data;
          Object.keys(memberData).forEach((memberDataKey: any) => {
            console.log("member key", memberData[memberDataKey]);
            if (memberData[memberDataKey].keyName === attrName[2]) {
              let fieldData = memberData[memberDataKey]?.field_data;
              Object.keys(fieldData).forEach((fieldDataKey: any) => {
                console.log("field >>>", fieldData[fieldDataKey]);
                fieldData[fieldDataKey].value = {
                  value:
                    fieldData[fieldDataKey].field_type === "TEXTFIELD"
                      ? ""
                      : [],
                  warning: false,
                };
              });
            }
          });
        }
      manipulatedMedicalQuestion = {
        ...manipulatedMedicalQuestion,
        [attrName[1]]: {
          ...manipulatedMedicalQuestion[attrName[1]],
          main_question: {
            ...manipulatedMedicalQuestion[attrName[1]]?.main_question,
            member_data: {
              ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                .member_data,
              [attrName[2]]: {
                ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                  ?.member_data?.[attrName[2]],
                selection_status: value,
              },
            },
          },
        },
      };

      setMedicalQuestionData(manipulatedMedicalQuestion);
    } else if (attrName[0] === "mainMemberDataField") {
      if (
        ((attrName[1] === "MQ02" || attrName[1] === "MQ03") &&
          attrName[2] === "adult_one") ||
        ((attrName[1] === "MQ02" || attrName[1] === "MQ03") &&
          attrName[2] === "adult_two") ||
        ((attrName[1] === "MQ02" || attrName[1] === "MQ03") &&
          attrName[2] === "child_one") ||
        ((attrName[1] === "MQ02" || attrName[1] === "MQ03") &&
          attrName[2] === "child_two") ||
        ((attrName[1] === "MQ02" || attrName[1] === "MQ03") &&
          attrName[2] === "child_three") ||
        ((attrName[1] === "MQ02" || attrName[1] === "MQ03") &&
          attrName[2] === "child_four" &&
          manipulatedMedicalQuestion !== undefined)
      ) {
        manipulatedMedicalQuestion[attrName[1]].main_question.sub_question[
          attrName[4]
        ].member_data[attrName[2]].field_data[attrName[3]].value = {
          value: value,
          warning: isEmpty(value),
        };

        setMedicalQuestionData({ ...manipulatedMedicalQuestion });
      } else {
        if (
          attrName[2] === "adult_one" ||
          attrName[2] === "adult_two" ||
          attrName[2] === "child_one" ||
          attrName[2] === "child_two" ||
          attrName[2] === "child_three" ||
          (attrName[2] === "child_four" &&
            manipulatedMedicalQuestion !== undefined)
        ) {
        }

        manipulatedMedicalQuestion[attrName[1]].main_question.member_data[
          attrName[2]
        ].field_data[attrName[3]].value = {
          value: value,
          warning: isEmpty(value),
        };

        setMedicalQuestionData({ ...manipulatedMedicalQuestion });
      }
    } else if (attrName[0] === "multiple_dripdown") {
      manipulatedMedicalQuestion.MQ01.main_question.member_data[
        attrName[2]
      ].field_data[attrName[3]].value = {
        value: value,
        warning: isEmpty(value),
      };

      setMedicalQuestionData({ ...manipulatedMedicalQuestion });
    }
  };
  console.log("medical", medicalQuestionData);
  const validateForm = () => {
    if (!medicalQuestionData) {
      return;
    }
    let data: TGoDigitMedicalQuestion = JSON.parse(
      JSON.stringify(medicalQuestionData)
    );
    let hasError = false;

    Object.values(data).forEach((element: any) => {
      const mainQuestion = element?.main_question;
      if (mainQuestion && mainQuestion.status) {
        const memberData = mainQuestion.member_data;
        if (memberData) {
          let showToast = true;
          hasError = true;
          Object.values(memberData).forEach((member: any) => {
            if (member.selection_status && member.field_data) {
              showToast = false;
              hasError = true;
              Object.values(member.field_data).forEach((field: any) => {
                if (
                  (field.value?.value === "" && selectedItems.length === 0) ||
                  (typeof field?.value?.value === "object" &&
                    field?.value?.value)
                ) {
                  hasError = !field?.value?.value?.length;
                  field.value = {
                    ...field.value,
                    warning: !field?.value?.value?.length,
                  };
                }
              });
            }
          });
          if (showToast) {
            toast.error("Please Select Member!");
          }
        }

        //subquestions
        console.log("amin q", mainQuestion);
        if (mainQuestion.status && mainQuestion.sub_question) {
          let showSubQuesToast = true;
          hasError = true;
          Object.keys(mainQuestion?.sub_question).forEach((subQKey) => {
            if (
              mainQuestion?.status &&
              mainQuestion?.sub_question?.[subQKey]?.status
            ) {
              showSubQuesToast = false;
              hasError = true;
              console.log("sub q", mainQuestion?.sub_question?.[subQKey]);
              const memberData =
                mainQuestion.sub_question?.[subQKey]?.member_data;
              let showToast = true;
              hasError = true;
              Object.keys(memberData).forEach((memberDataKey) => {
                const isMemberSelected =
                  memberData?.[memberDataKey].selection_status;
                if (isMemberSelected) hasError = false;
                let fieldData = memberData?.[memberDataKey]?.field_data;
                Object.keys(fieldData).forEach((fieldDataKey: any) => {
                  let fieldDataObj = fieldData[fieldDataKey];
                  if (isMemberSelected) {
                    showToast = false;

                    hasError = !fieldDataObj?.value?.value.length;
                    fieldDataObj.value = {
                      value: fieldDataObj?.value?.value,
                      warning: isEmpty(fieldDataObj?.value?.value),
                    };
                  } else {
                    fieldDataObj.value = {
                      value: "",
                      warning: false,
                    };
                  }
                });
              });

              if (showToast) {
                toast.error("Please Select Member!");
              }
            } else if (!mainQuestion?.status) hasError = false;
          });
          if (showSubQuesToast) {
            toast.error("Please Select Sub Questions");
          }

          setMedicalQuestionData(data);
        }
      }
    });
    setMedicalQuestionData(data);

    console.log("error", hasError);

    if (!hasError) {
      dispatch(HealthSlice.actions.SAVE_MEDICAL_QUESTION_DATA_GO_DIGIT(data));
      const onSuccess = (res: any) => {
        dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
        if (res.results.error === false) {
          let quoteData: THealthQuote = SELECTED_QUOTE_DATA;
          quoteData = {
            ...quoteData,
            premiumDetails: {
              ...quoteData.premiumDetails,
              finalPremium: res.results.response.premiumDetails.finalPremium,
            },
          };
          dispatch(HealthSlice.actions.SAVE_SELECTED_QUOTE_DATA(quoteData));
        }
      };
      const onError = (err: any) => {
        dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
        console.log("err..", err);
      };
      dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(true));
      const param = {
        quote_no: ADD_FORM_RESPONSE.quote_no,
        company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
        product_code: SELECTED_QUOTE_DATA.productDetails.product_code,
        medical_info: "Yes",
        personalHabitDetailsDiseases: data.MQ03.main_question.status
          ? "Yes"
          : "No", //Select "Yes" if any insured member Smokes, consumes Tobacco in any form, or drinks Alcohol?
        medicalAlcoholDiseases: data.MQ03.main_question.sub_question?.[
          "MQ1-SQ1"
        ].status
          ? "Yes"
          : "No", //Do you consume alcohol?
        medicalAlcohol: data.MQ03.main_question.sub_question?.["MQ1-SQ1"]
          .member_data?.adult_one.selection_status
          ? "Yes"
          : "No",
        consume_alcohal:
          data.MQ03.main_question.sub_question?.["MQ1-SQ1"].member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F1"].value?.value, //How often do you consume alchohol? // Occasional or Social,Once in a week,Daily
        medicalHospitalizedDiseases: data.MQ02.main_question.status
          ? "Yes"
          : "No", //Hospitalised
        asthmaDiseases: data.MQ02.main_question.sub_question?.["MQ3-SQ3"].status
          ? "Yes"
          : "No", //Do you have Asthma?
        medicalAsthma: data.MQ02.main_question.sub_question?.["MQ3-SQ3"]
          .member_data?.adult_one.selection_status
          ? "Yes"
          : "No",
        asthma_diagnosed:
          data.MQ02.main_question.sub_question?.["MQ3-SQ3"].member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F1"].value?.value, // Upto 10yrs , More than 10yrs  //When was it diagnosed?
        asthma_medication:
          data.MQ02.main_question.sub_question?.["MQ3-SQ3"].member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F2"].value?.value, // Non Steroids, None , Steriods //Current Medication?
        asthma_suffered:
          data.MQ02.main_question.sub_question?.["MQ3-SQ3"].member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F3"].value?.value, // Less than 2 , more than 5, 2 to 5 //How many times a year have you suffered with Asthma?
        asthma_hospitalized:
          data.MQ02.main_question.sub_question?.["MQ3-SQ3"].member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F4"].value?.value, //Were you hospitalized due to Asthma?
        asthma_hospitalized_record:
          data.MQ02.main_question.sub_question?.["MQ3-SQ3"].member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F5"].value?.value, //Do you have records of hospitalization, if any?
        asthma_complications:
          data.MQ02.main_question.sub_question?.["MQ3-SQ3"].member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F6"].value?.value, //Any complications due to Asthma?
        asthma_complications_records:
          data.MQ02.main_question.sub_question?.["MQ3-SQ3"].member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F7"].value?.value, //Do you have records of complications, if any?
        medicalDiabetesDiseases: data.MQ02.main_question.sub_question?.[
          "MQ2-SQ2"
        ].status
          ? "Yes"
          : "No", //Do you have Diabetes?
        medicalDiabetes: data.MQ02.main_question.sub_question?.["MQ2-SQ2"]
          .member_data?.adult_one.selection_status
          ? "Yes"
          : "No",
        diabetes_type:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F1"].value?.value, //Insulin dependant and Non Insulin dependant // Type of Diabetes?
        diabetes_fbs:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F2"].value?.value, //Abnormal , Within normal limits , Not listed // What was FBS value?
        diabetes_medication:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F3"].value?.value, // Oral medication , Insulin // Current Medication
        diabetes_diagnosed:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F4"].value?.value, // More than 10yrs and Upto 10yrs // When was it diagnosed?
        diabetes_last_blood:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F5"].value?.value, // Not done in last 6 months  , Less than 3 months and More than 3months // When was your last Blood sugar tests done?
        diabetes_hospitalized:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F6"].value?.value, //Were you hospitalized due to Diabetes?
        diabetes_hospitalization:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F7"].value?.value, //Do you have records of hospitalization, if any?
        diabetes_complications:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F8"].value?.value, //Any complications for Diabetes
        dibabets_complications_records:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F9"].value?.value, // Do you have records of complications, if any?
        dibabets_HbA1c:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F10"].value?.value, // upto 7.5% and More than 7.5%//What was HbA1c value?
        medicalAnyDiseases: data.MQ02?.main_question.sub_question?.["MQ8-SQ8"]
          .status
          ? "Yes"
          : "No", //Do you face any symptoms like chest pain, fatigue, weight loss, dizziness, joint pain, change in bowel habit, difficulty in breathing, pain in abdomen, bleeding/pain while passing stools etc?
        medicalAnySymtoms: data.MQ02.main_question.sub_question?.["MQ8-SQ8"]
          .member_data?.adult_one.selection_status
          ? "Yes"
          : "No",
        undiagnosedSystoms:
          data.MQ02.main_question.sub_question?.["MQ8-SQ8"].member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F1"].value?.value, // text //Details of your undiagnosed symptoms?
        medicalGynaecologicalDiseases: data.MQ02.main_question.sub_question?.[
          "MQ6-SQ6"
        ].status
          ? "Yes"
          : "No", //Do you  have any Gynaecological problem ?
        medicalGynaecological: data.MQ02.main_question.sub_question?.["MQ6-SQ6"]
          .member_data?.adult_one.selection_status
          ? "Yes"
          : "No",
        gynaecological_condition:
          data.MQ02.main_question.sub_question?.["MQ6-SQ6"].member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F1"]?.value?.value, // Text // what is the name of your condition?
        medicalMedicineDiseases: data.MQ02.main_question.sub_question?.[
          "MQ7-SQ7"
        ].status
          ? "Yes"
          : "No", // Is any member going through any medications currently
        medicalMedicine: data.MQ02.main_question.sub_question?.["MQ7-SQ7"]
          .member_data?.adult_one.selection_status
          ? "Yes"
          : "No",
        medicine_name:
          data.MQ02.main_question.sub_question?.["MQ7-SQ7"].member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F1"]?.value?.value, // Name of the medicines taken?
        medicalTobaccoDiseases: data.MQ03.main_question.sub_question?.[
          "MQ2-SQ2"
        ].status
          ? "Yes"
          : "No", // Do you consume tobacco?
        medicalTobacco: data.MQ03.main_question.sub_question?.["MQ2-SQ2"]
          .member_data?.adult_one.selection_status
          ? "Yes"
          : "No",
        consume_tobacco:
          data.MQ03.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F1"].value?.value, //In which form do you consumes tobacco?
        thyroidDiseases: data.MQ02.main_question.sub_question?.["MQ4-SQ4"]
          .status
          ? "Yes"
          : "No", //Do you have Thyroid?
        thyroidMedical: data.MQ02.main_question.sub_question?.["MQ4-SQ4"]
          .member_data?.adult_one.selection_status
          ? "Yes"
          : "No",
        thyroid_diagnosed:
          data.MQ02.main_question.sub_question?.["MQ4-SQ4"].member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F3"].value?.value, //10yrs or more ,Less than 5yrs and 5 to 9yrs //When was it diagnosed?
        thyroid_dysfunction:
          data.MQ02.main_question.sub_question?.["MQ4-SQ4"].member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F1"].value?.value, //Hyperthyroidism , Hypothyroidism and Not sure //Type of Thyroid dysfunction?
        thyroid_medication:
          data.MQ02.main_question.sub_question?.["MQ4-SQ4"].member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F2"].value?.value, // Thyroxine , Eltroxine and Not listed //Current Medication
        thyroid_complications:
          data.MQ02.main_question.sub_question?.["MQ4-SQ4"].member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F4"].value?.value, //Any associated complications
        medicalHypertensionDiseases: data.MQ02.main_question.sub_question?.[
          "MQ5-SQ5"
        ].status
          ? "Yes"
          : "No", // Do you have Hypertension?
        medicalHypertension: data.MQ02.main_question.sub_question?.["MQ5-SQ5"]
          .member_data?.adult_one.selection_status
          ? "Yes"
          : "No", //"hypertension_lipids": "yes",
        hypertension_diagnosed:
          data.MQ02.main_question.sub_question?.["MQ5-SQ5"].member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F1"].value?.value, //Upto 10yrs and More than 10yrs //When was it diagnosed?
        hypertension_tabltes:
          data.MQ02.main_question.sub_question?.["MQ5-SQ5"].member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F2"].value?.value, // More than two and two or less //No. of tablets
        hypertension_systolic:
          data.MQ02.main_question.sub_question?.["MQ5-SQ5"].member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F3"].value?.value, // More than 130 to 149 mm of Hg , 130 mm of Hg or less and 150 mm of Hg or more//Systolic Reading
        hypertension_diastolic:
          data.MQ02.main_question.sub_question?.["MQ5-SQ5"].member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F4"].value?.value, // 90 mm of Hg or less , 90 to 95 mm of Hg and More than 95 mm of Hg //Diastolic Reading
        hypertension_hospitalization:
          data.MQ02.main_question.sub_question?.["MQ5-SQ5"].member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F7"].value?.value, //Were you hospitalized due to Hypertension?
        hypertension_complications:
          data.MQ02.main_question.sub_question?.["MQ5-SQ5"].member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F6"].value?.value, //Do you have records of complications, if any?
        hypertension_complications_records:
          data.MQ02.main_question.sub_question?.["MQ5-SQ5"].member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F5"].value?.value, // Any complications related to Hypertension (related to nerves, eyes, heart or kidney)
        medicalHyperlipidemiaDiseases: data.MQ02.main_question.sub_question?.[
          "MQ1-SQ1"
        ].status
          ? "Yes"
          : "No", // Do you have Hyperlipidemia?
        medicalHyperlipidemia: data.MQ02.main_question.sub_question?.["MQ1-SQ1"]
          .member_data?.adult_one.selection_status
          ? "Yes"
          : "No",
        hyperlipidemia_complications:
          data.MQ02.main_question.sub_question?.["MQ1-SQ1"].member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F1"].value?.value, //Any complications with high lipids
        hyperlipidemia_lipids:
          data.MQ02.main_question.sub_question?.["MQ1-SQ1"].member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F2"].value?.value, //On medication for High lipids
        hyperlipidemia_diagnosed:
          data.MQ02.main_question.sub_question?.["MQ1-SQ1"].member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F3"].value?.value, // 5 to 9yrs , Less than 5yrs and 10yrs or more//When was it diagnosed
        //    "medicalClaimDiseases": "Yes",
        //   "medicalClaim": "Yes",
        medical_conditions_disease:
          data.MQ01.main_question.status === true ? "Yes" : "No", //Do you have any existing condition or disease for which they are being treated and/or and evaluated currently or in the past or have been diagnosed with? Other than common cold or viral fever?
        medical_conditions:
          data.MQ01.main_question.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "No",
        medical_sub_conditions:
          data.MQ01.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        medical_covidTested:
          data.MQ01.main_question.member_data?.adult_one.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value, //Have you been infected with Covid-19 in the last 15 days?
        //    "spouse_medicalAlcoholDiseases": "Yes",
        spouse_medicalAlcohol: data.MQ03.main_question.sub_question?.["MQ1-SQ1"]
          .member_data?.adult_two.selection_status
          ? "Yes"
          : "No",
        spouse_consume_alcohal:
          data.MQ03.main_question.sub_question?.["MQ1-SQ1"].member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        spouse_medicalAsthma: data.MQ02.main_question.sub_question?.["MQ3-SQ3"]
          .member_data?.adult_two.selection_status
          ? "Yes"
          : "No",
        spouse_asthma_diagnosed:
          data.MQ02.main_question.sub_question?.["MQ3-SQ3"].member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F1"].value?.value, // Upto 10yrs , More than 10yrs  //When was it diagnosed?
        spouse_asthma_medication:
          data.MQ02.main_question.sub_question?.["MQ3-SQ3"].member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F2"].value?.value, // Non Steroids, None , Steriods //Current Medication?
        spouse_asthma_suffered:
          data.MQ02.main_question.sub_question?.["MQ3-SQ3"].member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F3"].value?.value, // Less than 2 , more than 5, 2 to 5 //How many times a year have you suffered with Asthma?
        spouse_asthma_hospitalized:
          data.MQ02.main_question.sub_question?.["MQ3-SQ3"].member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F4"].value?.value, //Were you hospitalized due to Asthma?
        spouse_asthma_hospitalized_record:
          data.MQ02.main_question.sub_question?.["MQ3-SQ3"].member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F5"].value?.value, //Do you have records of hospitalization, if any?
        spouse_asthma_complications:
          data.MQ02.main_question.sub_question?.["MQ3-SQ3"].member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F6"].value?.value, //Any complications due to Asthma?
        spouse_asthma_complications_records:
          data.MQ02.main_question.sub_question?.["MQ3-SQ3"].member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F7"].value?.value,
        spouse_medicalDiabetes: data.MQ02.main_question.sub_question?.[
          "MQ2-SQ2"
        ].member_data?.adult_two.selection_status
          ? "Yes"
          : "No",
        spouse_diabetes_type:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F1"].value?.value, //Insulin dependant and Non Insulin dependant // Type of Diabetes?
        spouse_diabetes_fbs:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F2"].value?.value, //Abnormal , Within normal limits , Not listed // What was FBS value?
        spouse_diabetes_medication:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F3"]?.value?.value, // Oral medication , Insulin // Current Medication
        spouse_diabetes_diagnosed:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F4"].value?.value, // More than 10yrs and Upto 10yrs // When was it diagnosed?
        spouse_diabetes_last_blood:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F5"].value?.value, // Not done in last 6 months  , Less than 3 months and More than 3months // When was your last Blood sugar tests done?
        spouse_diabetes_hospitalized:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F6"].value?.value, //Were you hospitalized due to Diabetes?
        spouse_diabetes_hospitalization:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F7"].value?.value, //Do you have records of hospitalization, if any?
        spouse_diabetes_complications:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F8"].value?.value, //Any complications for Diabetes
        spouse_dibabets_complications_records:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F9"].value?.value, // Do you have records of complications, if any?
        spouse_dibabets_HbA1c:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F10"].value?.value, // upto 7.5% and More than 7.5%
        spouse_medicalAnySymtoms: data.MQ02.main_question.sub_question?.[
          "MQ8-SQ8"
        ].member_data?.adult_two.selection_status
          ? "Yes"
          : "No",
        spouse_undiagnosedSystoms:
          data.MQ02.main_question.sub_question?.["MQ8-SQ8"].member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F1"].value?.value, // text
        spouse_medicalGynaecological: data.MQ02.main_question.sub_question?.[
          "MQ6-SQ6"
        ].member_data?.adult_two.selection_status
          ? "Yes"
          : "No",
        spouse_gynaecological_condition:
          data.MQ02.main_question.sub_question?.["MQ6-SQ6"].member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F1"]?.value?.value, // Text
        spouse_medicalMedicine: data.MQ02.main_question.sub_question?.[
          "MQ7-SQ7"
        ].member_data?.adult_two.selection_status
          ? "Yes"
          : "No",
        spouse_medicine_name:
          data.MQ02.main_question.sub_question?.["MQ7-SQ7"].member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F1"]?.value?.value,
        spouse_medicalTobacco: data.MQ03.main_question.sub_question?.["MQ2-SQ2"]
          .member_data?.adult_two.selection_status
          ? "Yes"
          : "No",
        spouse_consume_tobacco:
          data.MQ03.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        spouse_thyroidMedical: data.MQ02.main_question.sub_question?.["MQ4-SQ4"]
          .member_data?.adult_two.selection_status
          ? "Yes"
          : "No",
        spouse_thyroid_diagnosed:
          data.MQ02.main_question.sub_question?.["MQ4-SQ4"].member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F3"].value?.value, //10yrs or more ,Less than 5yrs and 5 to 9yrs //When was it diagnosed?
        spouse_thyroid_dysfunction:
          data.MQ02.main_question.sub_question?.["MQ4-SQ4"].member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F1"].value?.value, //Hyperthyroidism , Hypothyroidism and Not sure //Type of Thyroid dysfunction?
        spouse_thyroid_medication:
          data.MQ02.main_question.sub_question?.["MQ4-SQ4"].member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F2"].value?.value, // Thyroxine , Eltroxine and Not listed //Current Medication
        spouse_thyroid_complications:
          data.MQ02.main_question.sub_question?.["MQ4-SQ4"].member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F4"].value?.value,
        spouse_hypertension_lipids: data.MQ02.main_question.sub_question?.[
          "MQ5-SQ5"
        ].member_data?.adult_two.selection_status
          ? "Yes"
          : "No",
        spouse_medicalHypertension: data.MQ02.main_question.sub_question?.[
          "MQ5-SQ5"
        ].member_data?.adult_two.selection_status
          ? "Yes"
          : "No", //"hypertension_lipids": "yes",
        spouse_hypertension_diagnosed:
          data.MQ02.main_question.sub_question?.["MQ5-SQ5"].member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F1"].value?.value, //Upto 10yrs and More than 10yrs //When was it diagnosed?
        spouse_hypertension_tabltes:
          data.MQ02.main_question.sub_question?.["MQ5-SQ5"].member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F2"].value?.value, // More than two and two or less //No. of tablets
        spouse_hypertension_systolic:
          data.MQ02.main_question.sub_question?.["MQ5-SQ5"].member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F3"].value?.value, // More than 130 to 149 mm of Hg , 130 mm of Hg or less and 150 mm of Hg or more//Systolic Reading
        spouse_hypertension_diastolic:
          data.MQ02.main_question.sub_question?.["MQ5-SQ5"].member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F4"].value?.value, // 90 mm of Hg or less , 90 to 95 mm of Hg and More than 95 mm of Hg //Diastolic Reading
        spouse_hypertension_hospitalization:
          data.MQ02.main_question.sub_question?.["MQ5-SQ5"].member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F7"].value?.value, //Were you hospitalized due to Hypertension?
        spouse_hypertension_complications:
          data.MQ02.main_question.sub_question?.["MQ5-SQ5"].member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F6"].value?.value, //Do you have records of complications, if any?
        spouse_hypertension_complications_records:
          data.MQ02.main_question.sub_question?.["MQ5-SQ5"].member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F5"].value?.value,
        spouse_medicalHyperlipidemia: data.MQ02.main_question.sub_question?.[
          "MQ1-SQ1"
        ].member_data?.adult_two.selection_status
          ? "Yes"
          : "No",
        spouse_hyperlipidemia_complications:
          data.MQ02.main_question.sub_question?.["MQ1-SQ1"].member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F1"].value?.value, //Any complications with high lipids
        spouse_hyperlipidemia_lipids:
          data.MQ02.main_question.sub_question?.["MQ1-SQ1"].member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F2"].value?.value, //On medication for High lipids
        spouse_hyperlipidemia_diagnosed:
          data.MQ02.main_question.sub_question?.["MQ1-SQ1"].member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F3"].value?.value, // 5 to 9yrs , Less than 5yrs and 10yrs or more

        spouse_medical_conditions:
          data.MQ01.main_question.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "No",
        spouse_medical_sub_conditions:
          data.MQ01.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value, //
        spouse_medical_covidTested:
          data.MQ01.main_question.member_data?.adult_two.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        //    "child1_medicalAlcoholDiseases": "Yes",
        child1_medicalAlcohol: data.MQ03.main_question.sub_question?.["MQ1-SQ1"]
          .member_data?.child_one.selection_status
          ? "Yes"
          : "No",
        child1_consume_alcohal:
          data.MQ03.main_question.sub_question?.["MQ1-SQ1"].member_data
            ?.child_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        child1_medicalAsthma: data.MQ02.main_question.sub_question?.["MQ3-SQ3"]
          .member_data?.child_one.selection_status
          ? "Yes"
          : "No",
        child1_asthma_diagnosed:
          data.MQ02.main_question.sub_question?.["MQ3-SQ3"].member_data
            ?.child_one.field_data?.["MQ1-SQ1-F1"].value?.value, // Upto 10yrs , More than 10yrs  //When was it diagnosed?
        child1_asthma_medication:
          data.MQ02.main_question.sub_question?.["MQ3-SQ3"].member_data
            ?.child_one.field_data?.["MQ1-SQ1-F2"].value?.value, // Non Steroids, None , Steriods //Current Medication?
        child1_asthma_suffered:
          data.MQ02.main_question.sub_question?.["MQ3-SQ3"].member_data
            ?.child_one.field_data?.["MQ1-SQ1-F3"].value?.value, // Less than 2 , more than 5, 2 to 5 //How many times a year have you suffered with Asthma?
        child1_asthma_hospitalized:
          data.MQ02.main_question.sub_question?.["MQ3-SQ3"].member_data
            ?.child_one.field_data?.["MQ1-SQ1-F4"].value?.value, //Were you hospitalized due to Asthma?
        child1_asthma_hospitalized_record:
          data.MQ02.main_question.sub_question?.["MQ3-SQ3"].member_data
            ?.child_one.field_data?.["MQ1-SQ1-F5"].value?.value, //Do you have records of hospitalization, if any?
        child1_asthma_complications:
          data.MQ02.main_question.sub_question?.["MQ3-SQ3"].member_data
            ?.child_one.field_data?.["MQ1-SQ1-F6"].value?.value, //Any complications due to Asthma?
        child1_asthma_complications_records:
          data.MQ02.main_question.sub_question?.["MQ3-SQ3"].member_data
            ?.child_one.field_data?.["MQ1-SQ1-F7"].value?.value,
        child1_medicalDiabetes: data.MQ02.main_question.sub_question?.[
          "MQ2-SQ2"
        ].member_data?.child_one.selection_status
          ? "Yes"
          : "No",
        child1_diabetes_type:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.child_one.field_data?.["MQ1-SQ1-F1"].value?.value, //Insulin dependant and Non Insulin dependant // Type of Diabetes?
        child1_diabetes_fbs:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.child_one.field_data?.["MQ1-SQ1-F2"].value?.value, //Abnormal , Within normal limits , Not listed // What was FBS value?
        child1_diabetes_medication:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.child_one.field_data?.["MQ1-SQ1-F3"]?.value?.value, // Oral medication , Insulin // Current Medication
        child1_diabetes_diagnosed:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.child_one.field_data?.["MQ1-SQ1-F4"].value?.value, // More than 10yrs and Upto 10yrs // When was it diagnosed?
        child1_diabetes_last_blood:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.child_one.field_data?.["MQ1-SQ1-F5"].value?.value, // Not done in last 6 months  , Less than 3 months and More than 3months // When was your last Blood sugar tests done?
        child1_diabetes_hospitalized:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.child_one.field_data?.["MQ1-SQ1-F6"].value?.value, //Were you hospitalized due to Diabetes?
        child1_diabetes_hospitalization:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.child_one.field_data?.["MQ1-SQ1-F7"].value?.value, //Do you have records of hospitalization, if any?
        child1_diabetes_complications:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.child_one.field_data?.["MQ1-SQ1-F8"].value?.value, //Any complications for Diabetes
        child1_dibabets_complications_records:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.child_one.field_data?.["MQ1-SQ1-F9"].value?.value, // Do you have records of complications, if any?
        child1_dibabets_HbA1c:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.child_one.field_data?.["MQ1-SQ1-F10"].value?.value, // upto 7.5% and More than 7.5%
        child1_medicalAnySymtoms: data.MQ02.main_question.sub_question?.[
          "MQ8-SQ8"
        ].member_data?.child_one.selection_status
          ? "Yes"
          : "No",
        child1_undiagnosedSystoms:
          data.MQ02.main_question.sub_question?.["MQ8-SQ8"].member_data
            ?.child_one.field_data?.["MQ1-SQ1-F1"].value?.value, // text
        child1_medicalGynaecological: data.MQ02.main_question.sub_question?.[
          "MQ6-SQ6"
        ].member_data?.child_one.selection_status
          ? "Yes"
          : "No",
        child1_gynaecological_condition:
          data.MQ02.main_question.sub_question?.["MQ6-SQ6"].member_data
            ?.child_one.field_data?.["MQ1-SQ1-F1"]?.value?.value, // Text
        child1_medicalMedicine: data.MQ02.main_question.sub_question?.[
          "MQ7-SQ7"
        ].member_data?.child_one.selection_status
          ? "Yes"
          : "No",
        child1_medicine_name:
          data.MQ02.main_question.sub_question?.["MQ7-SQ7"].member_data
            ?.child_one.field_data?.["MQ1-SQ1-F1"]?.value?.value,

        child1_medicalTobacco: data.MQ03.main_question.sub_question?.["MQ2-SQ2"]
          .member_data?.child_one.selection_status
          ? "Yes"
          : "No",
        child1_consume_tobacco:
          data.MQ03.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.child_one.field_data?.["MQ1-SQ1-F1"].value?.value,

        child1_thyroidMedical: data.MQ02.main_question.sub_question?.["MQ4-SQ4"]
          .member_data?.child_one.selection_status
          ? "Yes"
          : "No",
        child1_thyroid_diagnosed:
          data.MQ02.main_question.sub_question?.["MQ4-SQ4"].member_data
            ?.child_one.field_data?.["MQ1-SQ1-F3"].value?.value, //10yrs or more ,Less than 5yrs and 5 to 9yrs //When was it diagnosed?
        child1_thyroid_dysfunction:
          data.MQ02.main_question.sub_question?.["MQ4-SQ4"].member_data
            ?.child_one.field_data?.["MQ1-SQ1-F1"].value?.value, //Hyperthyroidism , Hypothyroidism and Not sure //Type of Thyroid dysfunction?
        child1_thyroid_medication:
          data.MQ02.main_question.sub_question?.["MQ4-SQ4"].member_data
            ?.child_one.field_data?.["MQ1-SQ1-F2"].value?.value, // Thyroxine , Eltroxine and Not listed //Current Medication
        child1_thyroid_complications:
          data.MQ02.main_question.sub_question?.["MQ4-SQ4"].member_data
            ?.child_one.field_data?.["MQ1-SQ1-F4"].value?.value,

        child1_hypertension_lipids: data.MQ02.main_question.sub_question?.[
          "MQ5-SQ5"
        ].member_data?.child_one.selection_status
          ? "Yes"
          : "No",
        child1_medicalHypertension: data.MQ02.main_question.sub_question?.[
          "MQ5-SQ5"
        ].member_data?.adult_two.selection_status
          ? "Yes"
          : "No", //"hypertension_lipids": "yes",
        child1_hypertension_diagnosed:
          data.MQ02.main_question.sub_question?.["MQ5-SQ5"].member_data
            ?.child_one.field_data?.["MQ1-SQ1-F1"].value?.value, //Upto 10yrs and More than 10yrs //When was it diagnosed?
        child1_hypertension_tabltes:
          data.MQ02.main_question.sub_question?.["MQ5-SQ5"].member_data
            ?.child_one.field_data?.["MQ1-SQ1-F2"].value?.value, // More than two and two or less //No. of tablets
        child1_hypertension_systolic:
          data.MQ02.main_question.sub_question?.["MQ5-SQ5"].member_data
            ?.child_one.field_data?.["MQ1-SQ1-F3"].value?.value, // More than 130 to 149 mm of Hg , 130 mm of Hg or less and 150 mm of Hg or more//Systolic Reading
        child1_hypertension_diastolic:
          data.MQ02.main_question.sub_question?.["MQ5-SQ5"].member_data
            ?.child_one.field_data?.["MQ1-SQ1-F4"].value?.value, // 90 mm of Hg or less , 90 to 95 mm of Hg and More than 95 mm of Hg //Diastolic Reading
        child1_hypertension_hospitalization:
          data.MQ02.main_question.sub_question?.["MQ5-SQ5"].member_data
            ?.child_one.field_data?.["MQ1-SQ1-F7"].value?.value, //Were you hospitalized due to Hypertension?
        child1_hypertension_complications:
          data.MQ02.main_question.sub_question?.["MQ5-SQ5"].member_data
            ?.child_one.field_data?.["MQ1-SQ1-F6"].value?.value, //Do you have records of complications, if any?
        child1_hypertension_complications_records:
          data.MQ02.main_question.sub_question?.["MQ5-SQ5"].member_data
            ?.child_one.field_data?.["MQ1-SQ1-F5"].value?.value,

        child1_medicalHyperlipidemia: data.MQ02.main_question.sub_question?.[
          "MQ1-SQ1"
        ].member_data?.child_one.selection_status
          ? "Yes"
          : "No",
        child1_hyperlipidemia_complications:
          data.MQ02.main_question.sub_question?.["MQ1-SQ1"].member_data
            ?.child_one.field_data?.["MQ1-SQ1-F1"].value?.value, //Any complications with high lipids
        child1_hyperlipidemia_lipids:
          data.MQ02.main_question.sub_question?.["MQ1-SQ1"].member_data
            ?.child_one.field_data?.["MQ1-SQ1-F2"].value?.value, //On medication for High lipids
        child1_hyperlipidemia_diagnosed:
          data.MQ02.main_question.sub_question?.["MQ1-SQ1"].member_data
            ?.child_one.field_data?.["MQ1-SQ1-F3"].value?.value, // 5 to 9yrs , Less than 5yrs and 10yrs or more

        // child1_medicalClaim: "Yes",
        // child1_medical_conditions_disease: "Diabetes", // Diabetes,COPD,Alzhemeirs disease,Systemic Lupus Erythmatous,Kidney failure,Thyroid problem,Kidney Stone,Cancer,Myasthenia gravis,
        child1_medical_covidTested:
          data.MQ01.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        child1_medical_conditions:
          data.MQ01.main_question.member_data?.child_one.selection_status ===
          true
            ? "Yes"
            : "No",
        child1_medical_sub_conditions:
          data.MQ01.main_question.member_data?.child_one.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value, //

        //    "child2_medicalAlcoholDiseases": "Yes",
        child2_medicalAlcohol: data.MQ03.main_question.sub_question?.["MQ1-SQ1"]
          .member_data?.child_two.selection_status
          ? "Yes"
          : "No",
        child2_consume_alcohal:
          data.MQ03.main_question.sub_question?.["MQ1-SQ1"].member_data
            ?.child_two.field_data?.["MQ1-SQ1-F1"].value?.value,

        child2_medicalAsthma: data.MQ02.main_question.sub_question?.["MQ3-SQ3"]
          .member_data?.child_two.selection_status
          ? "Yes"
          : "No",
        child2_asthma_diagnosed:
          data.MQ02.main_question.sub_question?.["MQ3-SQ3"].member_data
            ?.child_two.field_data?.["MQ1-SQ1-F1"].value?.value, // Upto 10yrs , More than 10yrs  //When was it diagnosed?
        child2_asthma_medication:
          data.MQ02.main_question.sub_question?.["MQ3-SQ3"].member_data
            ?.child_two.field_data?.["MQ1-SQ1-F2"].value?.value, // Non Steroids, None , Steriods //Current Medication?
        child2_asthma_suffered:
          data.MQ02.main_question.sub_question?.["MQ3-SQ3"].member_data
            ?.child_two.field_data?.["MQ1-SQ1-F3"].value?.value, // Less than 2 , more than 5, 2 to 5 //How many times a year have you suffered with Asthma?
        child2_asthma_hospitalized:
          data.MQ02.main_question.sub_question?.["MQ3-SQ3"].member_data
            ?.child_two.field_data?.["MQ1-SQ1-F4"].value?.value, //Were you hospitalized due to Asthma?
        child2_asthma_hospitalized_record:
          data.MQ02.main_question.sub_question?.["MQ3-SQ3"].member_data
            ?.child_two.field_data?.["MQ1-SQ1-F5"].value?.value, //Do you have records of hospitalization, if any?
        child2_asthma_complications:
          data.MQ02.main_question.sub_question?.["MQ3-SQ3"].member_data
            ?.child_two.field_data?.["MQ1-SQ1-F6"].value?.value, //Any complications due to Asthma?
        child2_asthma_complications_records:
          data.MQ02.main_question.sub_question?.["MQ3-SQ3"].member_data
            ?.child_two.field_data?.["MQ1-SQ1-F7"].value?.value,

        child2_medicalDiabetes: data.MQ02.main_question.sub_question?.[
          "MQ2-SQ2"
        ].member_data?.child_two.selection_status
          ? "Yes"
          : "No",
        child2_diabetes_type:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.child_two.field_data?.["MQ1-SQ1-F1"].value?.value, //Insulin dependant and Non Insulin dependant // Type of Diabetes?
        child2_diabetes_fbs:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.child_two.field_data?.["MQ1-SQ1-F2"].value?.value, //Abnormal , Within normal limits , Not listed // What was FBS value?
        child2_diabetes_medication:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.child_two.field_data?.["MQ1-SQ1-F3"]?.value?.value, // Oral medication , Insulin // Current Medication
        child2_diabetes_diagnosed:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.child_two.field_data?.["MQ1-SQ1-F4"].value?.value, // More than 10yrs and Upto 10yrs // When was it diagnosed?
        child2_diabetes_last_blood:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.child_two.field_data?.["MQ1-SQ1-F5"].value?.value, // Not done in last 6 months  , Less than 3 months and More than 3months // When was your last Blood sugar tests done?
        child2_diabetes_hospitalized:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.child_two.field_data?.["MQ1-SQ1-F6"].value?.value, //Were you hospitalized due to Diabetes?
        child2_diabetes_hospitalization:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.child_two.field_data?.["MQ1-SQ1-F7"].value?.value, //Do you have records of hospitalization, if any?
        child2_diabetes_complications:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.child_two.field_data?.["MQ1-SQ1-F8"].value?.value, //Any complications for Diabetes
        child2_dibabets_complications_records:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.child_two.field_data?.["MQ1-SQ1-F9"].value?.value, // Do you have records of complications, if any?
        child2_dibabets_HbA1c:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.child_two.field_data?.["MQ1-SQ1-F10"].value?.value, // upto 7.5% and More than 7.5%

        child2_medicalAnySymtoms: data.MQ02.main_question.sub_question?.[
          "MQ8-SQ8"
        ].member_data?.child_two.selection_status
          ? "Yes"
          : "No",
        child2_undiagnosedSystoms:
          data.MQ02.main_question.sub_question?.["MQ8-SQ8"].member_data
            ?.child_two.field_data?.["MQ1-SQ1-F1"].value?.value, // text

        child2_medicalGynaecological: data.MQ02.main_question.sub_question?.[
          "MQ6-SQ6"
        ].member_data?.child_two.selection_status
          ? "Yes"
          : "No",
        child2_gynaecological_condition:
          data.MQ02.main_question.sub_question?.["MQ6-SQ6"].member_data
            ?.child_two.field_data?.["MQ1-SQ1-F1"]?.value?.value, // Text

        child2_medicalMedicine: data.MQ02.main_question.sub_question?.[
          "MQ7-SQ7"
        ].member_data?.child_two.selection_status
          ? "Yes"
          : "No",
        child2_medicine_name:
          data.MQ02.main_question.sub_question?.["MQ7-SQ7"].member_data
            ?.child_two.field_data?.["MQ1-SQ1-F1"]?.value?.value,

        child2_medicalTobacco: data.MQ03.main_question.sub_question?.["MQ2-SQ2"]
          .member_data?.child_two.selection_status
          ? "Yes"
          : "No",
        child2_consume_tobacco:
          data.MQ03.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.child_two.field_data?.["MQ1-SQ1-F1"].value?.value,

        child2_thyroidMedical: data.MQ02.main_question.sub_question?.["MQ4-SQ4"]
          .member_data?.child_two.selection_status
          ? "Yes"
          : "No",
        child2_thyroid_diagnosed:
          data.MQ02.main_question.sub_question?.["MQ4-SQ4"].member_data
            ?.child_two.field_data?.["MQ1-SQ1-F3"].value?.value, //10yrs or more ,Less than 5yrs and 5 to 9yrs //When was it diagnosed?
        child2_thyroid_dysfunction:
          data.MQ02.main_question.sub_question?.["MQ4-SQ4"].member_data
            ?.child_two.field_data?.["MQ1-SQ1-F1"].value?.value, //Hyperthyroidism , Hypothyroidism and Not sure //Type of Thyroid dysfunction?
        child2_thyroid_medication:
          data.MQ02.main_question.sub_question?.["MQ4-SQ4"].member_data
            ?.child_two.field_data?.["MQ1-SQ1-F2"].value?.value, // Thyroxine , Eltroxine and Not listed //Current Medication
        child2_thyroid_complications:
          data.MQ02.main_question.sub_question?.["MQ4-SQ4"].member_data
            ?.child_two.field_data?.["MQ1-SQ1-F4"].value?.value,

        child2_hypertension_lipids: data.MQ02.main_question.sub_question?.[
          "MQ5-SQ5"
        ].member_data?.child_two.selection_status
          ? "Yes"
          : "No",
        child2_medicalHypertension: data.MQ02.main_question.sub_question?.[
          "MQ5-SQ5"
        ].member_data?.child_two.selection_status
          ? "Yes"
          : "No", //"hypertension_lipids": "yes",
        child2_hypertension_diagnosed:
          data.MQ02.main_question.sub_question?.["MQ5-SQ5"].member_data
            ?.child_two.field_data?.["MQ1-SQ1-F1"].value?.value, //Upto 10yrs and More than 10yrs //When was it diagnosed?
        child2_hypertension_tabltes:
          data.MQ02.main_question.sub_question?.["MQ5-SQ5"].member_data
            ?.child_two.field_data?.["MQ1-SQ1-F2"].value?.value, // More than two and two or less //No. of tablets
        child2_hypertension_systolic:
          data.MQ02.main_question.sub_question?.["MQ5-SQ5"].member_data
            ?.child_two.field_data?.["MQ1-SQ1-F3"].value?.value, // More than 130 to 149 mm of Hg , 130 mm of Hg or less and 150 mm of Hg or more//Systolic Reading
        child2_hypertension_diastolic:
          data.MQ02.main_question.sub_question?.["MQ5-SQ5"].member_data
            ?.child_two.field_data?.["MQ1-SQ1-F4"].value?.value, // 90 mm of Hg or less , 90 to 95 mm of Hg and More than 95 mm of Hg //Diastolic Reading
        child2_hypertension_hospitalization:
          data.MQ02.main_question.sub_question?.["MQ5-SQ5"].member_data
            ?.child_two.field_data?.["MQ1-SQ1-F7"].value?.value, //Were you hospitalized due to Hypertension?
        child2_hypertension_complications:
          data.MQ02.main_question.sub_question?.["MQ5-SQ5"].member_data
            ?.child_two.field_data?.["MQ1-SQ1-F6"].value?.value, //Do you have records of complications, if any?
        child2_hypertension_complications_records:
          data.MQ02.main_question.sub_question?.["MQ5-SQ5"].member_data
            ?.child_two.field_data?.["MQ1-SQ1-F5"].value?.value,

        child2_medicalHyperlipidemia: data.MQ02.main_question.sub_question?.[
          "MQ1-SQ1"
        ].member_data?.child_two.selection_status
          ? "Yes"
          : "No",
        child2_hyperlipidemia_complications:
          data.MQ02.main_question.sub_question?.["MQ1-SQ1"].member_data
            ?.child_two.field_data?.["MQ1-SQ1-F1"].value?.value, //Any complications with high lipids
        child2_hyperlipidemia_lipids:
          data.MQ02.main_question.sub_question?.["MQ1-SQ1"].member_data
            ?.child_two.field_data?.["MQ1-SQ1-F2"].value?.value, //On medication for High lipids
        child2_hyperlipidemia_diagnosed:
          data.MQ02.main_question.sub_question?.["MQ1-SQ1"].member_data
            ?.child_two.field_data?.["MQ1-SQ1-F3"].value?.value, // 5 to 9yrs , Less than 5yrs and 10yrs or more

        // child2_medicalClaim: "Yes",
        // child2_medical_conditions_disease: "Diabetes", // Diabetes,COPD,Alzhemeirs disease,Systemic Lupus Erythmatous,Kidney failure,Thyroid problem,Kidney Stone,Cancer,Myasthenia gravis,
        child2_medical_covidTested:
          data.MQ01.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        child2_medical_conditions:
          data.MQ01.main_question.member_data?.child_two.selection_status ===
          true
            ? "Yes"
            : "No",
        child2_medical_sub_conditions:
          data.MQ01.main_question.member_data?.child_two.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value, //
        child3_medicalAlcohol: data.MQ03.main_question.sub_question?.["MQ1-SQ1"]
          .member_data?.child_three.selection_status
          ? "Yes"
          : "No",
        child3_consume_alcohal:
          data.MQ03.main_question.sub_question?.["MQ1-SQ1"].member_data
            ?.child_three.field_data?.["MQ1-SQ1-F1"].value?.value,
        child3_medicalAsthma: data.MQ02.main_question.sub_question?.["MQ3-SQ3"]
          .member_data?.child_three.selection_status
          ? "Yes"
          : "No",
        child3_asthma_diagnosed:
          data.MQ02.main_question.sub_question?.["MQ3-SQ3"].member_data
            ?.child_three.field_data?.["MQ1-SQ1-F1"].value?.value, // Upto 10yrs , More than 10yrs  //When was it diagnosed?
        child3_asthma_medication:
          data.MQ02.main_question.sub_question?.["MQ3-SQ3"].member_data
            ?.child_three.field_data?.["MQ1-SQ1-F2"].value?.value, // Non Steroids, None , Steriods //Current Medication?
        child3_asthma_suffered:
          data.MQ02.main_question.sub_question?.["MQ3-SQ3"].member_data
            ?.child_three.field_data?.["MQ1-SQ1-F3"].value?.value, // Less than 2 , more than 5, 2 to 5 //How many times a year have you suffered with Asthma?
        child3_asthma_hospitalized:
          data.MQ02.main_question.sub_question?.["MQ3-SQ3"].member_data
            ?.child_three.field_data?.["MQ1-SQ1-F4"].value?.value, //Were you hospitalized due to Asthma?
        child3_asthma_hospitalized_record:
          data.MQ02.main_question.sub_question?.["MQ3-SQ3"].member_data
            ?.child_three.field_data?.["MQ1-SQ1-F5"].value?.value, //Do you have records of hospitalization, if any?
        child3_asthma_complications:
          data.MQ02.main_question.sub_question?.["MQ3-SQ3"].member_data
            ?.child_three.field_data?.["MQ1-SQ1-F6"].value?.value, //Any complications due to Asthma?
        child3_asthma_complications_records:
          data.MQ02.main_question.sub_question?.["MQ3-SQ3"].member_data
            ?.child_three.field_data?.["MQ1-SQ1-F7"].value?.value,
        child3_medicalDiabetes: data.MQ02.main_question.sub_question?.[
          "MQ2-SQ2"
        ].member_data?.child_three.selection_status
          ? "Yes"
          : "No",
        child3_diabetes_type:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.child_three.field_data?.["MQ1-SQ1-F1"].value?.value, //Insulin dependant and Non Insulin dependant // Type of Diabetes?
        child3_diabetes_fbs:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.child_three.field_data?.["MQ1-SQ1-F2"].value?.value, //Abnormal , Within normal limits , Not listed // What was FBS value?
        child3_diabetes_medication:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.child_three.field_data?.["MQ1-SQ1-F3"]?.value?.value, // Oral medication , Insulin // Current Medication
        child3_diabetes_diagnosed:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.child_three.field_data?.["MQ1-SQ1-F4"].value?.value, // More than 10yrs and Upto 10yrs // When was it diagnosed?
        child3_diabetes_last_blood:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.child_three.field_data?.["MQ1-SQ1-F5"].value?.value, // Not done in last 6 months  , Less than 3 months and More than 3months // When was your last Blood sugar tests done?
        child3_diabetes_hospitalized:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.child_three.field_data?.["MQ1-SQ1-F6"].value?.value, //Were you hospitalized due to Diabetes?
        child3_diabetes_hospitalization:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.child_three.field_data?.["MQ1-SQ1-F7"].value?.value, //Do you have records of hospitalization, if any?
        child3_diabetes_complications:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.child_three.field_data?.["MQ1-SQ1-F8"].value?.value, //Any complications for Diabetes
        child3_dibabets_complications_records:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.child_three.field_data?.["MQ1-SQ1-F9"].value?.value, // Do you have records of complications, if any?
        child3_dibabets_HbA1c:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.child_three.field_data?.["MQ1-SQ1-F10"].value?.value, // upto 7.5% and More than 7.5%
        child3_medicalAnySymtoms: data.MQ02.main_question.sub_question?.[
          "MQ8-SQ8"
        ].member_data?.child_three.selection_status
          ? "Yes"
          : "No",
        child3_undiagnosedSystoms:
          data.MQ02.main_question.sub_question?.["MQ8-SQ8"].member_data
            ?.child_three.field_data?.["MQ1-SQ1-F1"].value?.value, // text
        child3_medicalGynaecological: data.MQ02.main_question.sub_question?.[
          "MQ6-SQ6"
        ].member_data?.child_three.selection_status
          ? "Yes"
          : "No",
        child3_gynaecological_condition:
          data.MQ02.main_question.sub_question?.["MQ6-SQ6"].member_data
            ?.child_three.field_data?.["MQ1-SQ1-F1"]?.value?.value, // Text
        child3_medicalMedicine: data.MQ02.main_question.sub_question?.[
          "MQ7-SQ7"
        ].member_data?.child_three.selection_status
          ? "Yes"
          : "No",
        child3_medicine_name:
          data.MQ02.main_question.sub_question?.["MQ7-SQ7"].member_data
            ?.child_three.field_data?.["MQ1-SQ1-F1"]?.value?.value,
        child3_medicalTobacco: data.MQ03.main_question.sub_question?.["MQ2-SQ2"]
          .member_data?.child_three.selection_status
          ? "Yes"
          : "No",
        child3_consume_tobacco:
          data.MQ03.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.child_three.field_data?.["MQ1-SQ1-F1"].value?.value,
        child3_thyroidMedical: data.MQ02.main_question.sub_question?.["MQ4-SQ4"]
          .member_data?.child_three.selection_status
          ? "Yes"
          : "No",
        child3_thyroid_diagnosed:
          data.MQ02.main_question.sub_question?.["MQ4-SQ4"].member_data
            ?.child_three.field_data?.["MQ1-SQ1-F3"].value?.value, //10yrs or more ,Less than 5yrs and 5 to 9yrs //When was it diagnosed?
        child3_thyroid_dysfunction:
          data.MQ02.main_question.sub_question?.["MQ4-SQ4"].member_data
            ?.child_three.field_data?.["MQ1-SQ1-F1"].value?.value, //Hyperthyroidism , Hypothyroidism and Not sure //Type of Thyroid dysfunction?
        child3_thyroid_medication:
          data.MQ02.main_question.sub_question?.["MQ4-SQ4"].member_data
            ?.child_three.field_data?.["MQ1-SQ1-F2"].value?.value, // Thyroxine , Eltroxine and Not listed //Current Medication
        child3_thyroid_complications:
          data.MQ02.main_question.sub_question?.["MQ4-SQ4"].member_data
            ?.child_three.field_data?.["MQ1-SQ1-F4"].value?.value,
        child3_hypertension_lipids: data.MQ02.main_question.sub_question?.[
          "MQ5-SQ5"
        ].member_data?.child_three.selection_status
          ? "Yes"
          : "No",
        child3_medicalHypertension: data.MQ02.main_question.sub_question?.[
          "MQ5-SQ5"
        ].member_data?.child_three.selection_status
          ? "Yes"
          : "No", //"hypertension_lipids": "yes",
        child3_hypertension_diagnosed:
          data.MQ02.main_question.sub_question?.["MQ5-SQ5"].member_data
            ?.child_three.field_data?.["MQ1-SQ1-F1"].value?.value, //Upto 10yrs and More than 10yrs //When was it diagnosed?
        child3_hypertension_tabltes:
          data.MQ02.main_question.sub_question?.["MQ5-SQ5"].member_data
            ?.child_three.field_data?.["MQ1-SQ1-F2"].value?.value, // More than two and two or less //No. of tablets
        child3_hypertension_systolic:
          data.MQ02.main_question.sub_question?.["MQ5-SQ5"].member_data
            ?.child_three.field_data?.["MQ1-SQ1-F3"].value?.value, // More than 130 to 149 mm of Hg , 130 mm of Hg or less and 150 mm of Hg or more//Systolic Reading
        child3_hypertension_diastolic:
          data.MQ02.main_question.sub_question?.["MQ5-SQ5"].member_data
            ?.child_three.field_data?.["MQ1-SQ1-F4"].value?.value, // 90 mm of Hg or less , 90 to 95 mm of Hg and More than 95 mm of Hg //Diastolic Reading
        child3_hypertension_hospitalization:
          data.MQ02.main_question.sub_question?.["MQ5-SQ5"].member_data
            ?.child_three.field_data?.["MQ1-SQ1-F7"].value?.value, //Were you hospitalized due to Hypertension?
        child3_hypertension_complications:
          data.MQ02.main_question.sub_question?.["MQ5-SQ5"].member_data
            ?.child_three.field_data?.["MQ1-SQ1-F6"].value?.value, //Do you have records of complications, if any?
        child3_hypertension_complications_records:
          data.MQ02.main_question.sub_question?.["MQ5-SQ5"].member_data
            ?.child_three.field_data?.["MQ1-SQ1-F5"].value?.value,
        child3_medicalHyperlipidemia: data.MQ02.main_question.sub_question?.[
          "MQ1-SQ1"
        ].member_data?.child_three.selection_status
          ? "Yes"
          : "No",
        child3_hyperlipidemia_lipids:
          data.MQ02.main_question.sub_question?.["MQ1-SQ1"].member_data
            ?.child_three.field_data?.["MQ1-SQ1-F2"].value?.value,
        child3_hyperlipidemia_diagnosed:
          data.MQ02.main_question.sub_question?.["MQ1-SQ1"].member_data
            ?.child_three.field_data?.["MQ1-SQ1-F3"].value?.value, // 5 to 9yrs , Less than 5yrs and 10yrs or more
        child3_hyperlipidemia_complications:
          data.MQ02.main_question.sub_question?.["MQ1-SQ1"].member_data
            ?.child_three.field_data?.["MQ1-SQ1-F1"].value?.value,
        // child3_medicalClaim: "Yes",
        // child3_medical_conditions_disease: "Diabetes", // Diabetes,COPD,Alzhemeirs disease,Systemic Lupus Erythmatous,Kidney failure,Thyroid problem,Kidney Stone,Cancer,Myasthenia gravis,
        child3_medical_covidTested:
          data.MQ01.main_question.member_data?.child_three.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        child3_medical_conditions:
          data.MQ01.main_question.member_data?.child_three.selection_status ===
          true
            ? "Yes"
            : "No",
        child3_medical_sub_conditions:
          data.MQ01.main_question.member_data?.child_three.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value, //
        child4_medicalAlcohol: data.MQ03.main_question.sub_question?.["MQ1-SQ1"]
          .member_data?.child_four.selection_status
          ? "Yes"
          : "No",
        child4_consume_alcohal:
          data.MQ03.main_question.sub_question?.["MQ1-SQ1"].member_data
            ?.child_four.field_data?.["MQ1-SQ1-F1"].value?.value,
        child4_medicalAsthma: data.MQ02.main_question.sub_question?.["MQ3-SQ3"]
          .member_data?.child_four.selection_status
          ? "Yes"
          : "No",
        child4_asthma_diagnosed:
          data.MQ02.main_question.sub_question?.["MQ3-SQ3"].member_data
            ?.child_four.field_data?.["MQ1-SQ1-F1"].value?.value, // Upto 10yrs , More than 10yrs  //When was it diagnosed?
        child4_asthma_medication:
          data.MQ02.main_question.sub_question?.["MQ3-SQ3"].member_data
            ?.child_four.field_data?.["MQ1-SQ1-F2"].value?.value, // Non Steroids, None , Steriods //Current Medication?
        child4_asthma_suffered:
          data.MQ02.main_question.sub_question?.["MQ3-SQ3"].member_data
            ?.child_four.field_data?.["MQ1-SQ1-F3"].value?.value, // Less than 2 , more than 5, 2 to 5 //How many times a year have you suffered with Asthma?
        child4_asthma_hospitalized:
          data.MQ02.main_question.sub_question?.["MQ3-SQ3"].member_data
            ?.child_four.field_data?.["MQ1-SQ1-F4"].value?.value, //Were you hospitalized due to Asthma?
        child4_asthma_hospitalized_record:
          data.MQ02.main_question.sub_question?.["MQ3-SQ3"].member_data
            ?.child_four.field_data?.["MQ1-SQ1-F5"].value?.value, //Do you have records of hospitalization, if any?
        child4_asthma_complications:
          data.MQ02.main_question.sub_question?.["MQ3-SQ3"].member_data
            ?.child_four.field_data?.["MQ1-SQ1-F6"].value?.value, //Any complications due to Asthma?
        child4_asthma_complications_records:
          data.MQ02.main_question.sub_question?.["MQ3-SQ3"].member_data
            ?.child_four.field_data?.["MQ1-SQ1-F7"].value?.value,
        child4_medicalDiabetes: data.MQ02.main_question.sub_question?.[
          "MQ2-SQ2"
        ].member_data?.child_four.selection_status
          ? "Yes"
          : "No",
        child4_diabetes_type:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.child_four.field_data?.["MQ1-SQ1-F1"].value?.value, //Insulin dependant and Non Insulin dependant // Type of Diabetes?
        child4_diabetes_fbs:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.child_four.field_data?.["MQ1-SQ1-F2"].value?.value, //Abnormal , Within normal limits , Not listed // What was FBS value?
        child4_diabetes_medication:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.child_four.field_data?.["MQ1-SQ1-F3"]?.value?.value, // Oral medication , Insulin // Current Medication
        child4_diabetes_diagnosed:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.child_four.field_data?.["MQ1-SQ1-F4"].value?.value, // More than 10yrs and Upto 10yrs // When was it diagnosed?
        child4_diabetes_last_blood:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.child_four.field_data?.["MQ1-SQ1-F5"].value?.value, // Not done in last 6 months  , Less than 3 months and More than 3months // When was your last Blood sugar tests done?
        child4_diabetes_hospitalized:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.child_four.field_data?.["MQ1-SQ1-F6"].value?.value, //Were you hospitalized due to Diabetes?
        child4_diabetes_hospitalization:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.child_four.field_data?.["MQ1-SQ1-F7"].value?.value, //Do you have records of hospitalization, if any?
        child4_diabetes_complications:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.child_four.field_data?.["MQ1-SQ1-F8"].value?.value, //Any complications for Diabetes
        child4_dibabets_complications_records:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.child_four.field_data?.["MQ1-SQ1-F9"].value?.value, // Do you have records of complications, if any?
        child4_dibabets_HbA1c:
          data.MQ02.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.child_four.field_data?.["MQ1-SQ1-F10"].value?.value, // upto 7.5% and More than 7.5%
        child4_medicalAnySymtoms: data.MQ02.main_question.sub_question?.[
          "MQ8-SQ8"
        ].member_data?.child_four.selection_status
          ? "Yes"
          : "No",
        child4_undiagnosedSystoms:
          data.MQ02.main_question.sub_question?.["MQ8-SQ8"].member_data
            ?.child_four.field_data?.["MQ1-SQ1-F1"].value?.value, // text
        child4_medicalGynaecological: data.MQ02.main_question.sub_question?.[
          "MQ6-SQ6"
        ].member_data?.child_four.selection_status
          ? "Yes"
          : "No",
        child4_gynaecological_condition:
          data.MQ02.main_question.sub_question?.["MQ6-SQ6"].member_data
            ?.child_four.field_data?.["MQ1-SQ1-F1"]?.value?.value, // Text
        child4_medicalMedicine: data.MQ02.main_question.sub_question?.[
          "MQ7-SQ7"
        ].member_data?.child_four.selection_status
          ? "Yes"
          : "No",
        child4_medicine_name:
          data.MQ02.main_question.sub_question?.["MQ7-SQ7"].member_data
            ?.child_four.field_data?.["MQ1-SQ1-F1"]?.value?.value,
        child4_medicalTobacco: data.MQ03.main_question.sub_question?.["MQ2-SQ2"]
          .member_data?.child_four.selection_status
          ? "Yes"
          : "No",
        child4_consume_tobacco:
          data.MQ03.main_question.sub_question?.["MQ2-SQ2"].member_data
            ?.child_four.field_data?.["MQ1-SQ1-F1"].value?.value,
        child4_thyroidMedical: data.MQ02.main_question.sub_question?.["MQ4-SQ4"]
          .member_data?.child_four.selection_status
          ? "Yes"
          : "No",
        child4_thyroid_diagnosed:
          data.MQ02.main_question.sub_question?.["MQ4-SQ4"].member_data
            ?.child_four.field_data?.["MQ1-SQ1-F3"].value?.value, //10yrs or more ,Less than 5yrs and 5 to 9yrs //When was it diagnosed?
        child4_thyroid_dysfunction:
          data.MQ02.main_question.sub_question?.["MQ4-SQ4"].member_data
            ?.child_four.field_data?.["MQ1-SQ1-F1"].value?.value, //Hyperthyroidism , Hypothyroidism and Not sure //Type of Thyroid dysfunction?
        child4_thyroid_medication:
          data.MQ02.main_question.sub_question?.["MQ4-SQ4"].member_data
            ?.child_four.field_data?.["MQ1-SQ1-F2"].value?.value, // Thyroxine , Eltroxine and Not listed //Current Medication
        child4_thyroid_complications:
          data.MQ02.main_question.sub_question?.["MQ4-SQ4"].member_data
            ?.child_four.field_data?.["MQ1-SQ1-F4"].value?.value,
        child4_hypertension_lipids: data.MQ02.main_question.sub_question?.[
          "MQ5-SQ5"
        ].member_data?.child_four.selection_status
          ? "Yes"
          : "No",
        child4_medicalHypertension: data.MQ02.main_question.sub_question?.[
          "MQ5-SQ5"
        ].member_data?.child_four.selection_status
          ? "Yes"
          : "No", //"hypertension_lipids": "yes",
        child4_hypertension_diagnosed:
          data.MQ02.main_question.sub_question?.["MQ5-SQ5"].member_data
            ?.child_four.field_data?.["MQ1-SQ1-F1"].value?.value, //Upto 10yrs and More than 10yrs //When was it diagnosed?
        child4_hypertension_tabltes:
          data.MQ02.main_question.sub_question?.["MQ5-SQ5"].member_data
            ?.child_four.field_data?.["MQ1-SQ1-F2"].value?.value, // More than two and two or less //No. of tablets
        child4_hypertension_systolic:
          data.MQ02.main_question.sub_question?.["MQ5-SQ5"].member_data
            ?.child_four.field_data?.["MQ1-SQ1-F3"].value?.value, // More than 130 to 149 mm of Hg , 130 mm of Hg or less and 150 mm of Hg or more//Systolic Reading
        child4_hypertension_diastolic:
          data.MQ02.main_question.sub_question?.["MQ5-SQ5"].member_data
            ?.child_four.field_data?.["MQ1-SQ1-F4"].value?.value, // 90 mm of Hg or less , 90 to 95 mm of Hg and More than 95 mm of Hg //Diastolic Reading
        child4_hypertension_hospitalization:
          data.MQ02.main_question.sub_question?.["MQ5-SQ5"].member_data
            ?.child_four.field_data?.["MQ1-SQ1-F7"].value?.value, //Were you hospitalized due to Hypertension?
        child4_hypertension_complications:
          data.MQ02.main_question.sub_question?.["MQ5-SQ5"].member_data
            ?.child_four.field_data?.["MQ1-SQ1-F6"].value?.value, //Do you have records of complications, if any?
        child4_hypertension_complications_records:
          data.MQ02.main_question.sub_question?.["MQ5-SQ5"].member_data
            ?.child_four.field_data?.["MQ1-SQ1-F5"].value?.value,
        child4_medicalHyperlipidemia: data.MQ02.main_question.sub_question?.[
          "MQ1-SQ1"
        ].member_data?.child_four.selection_status
          ? "Yes"
          : "No",
        child4_hyperlipidemia_complications:
          data.MQ02.main_question.sub_question?.["MQ1-SQ1"].member_data
            ?.child_four.field_data?.["MQ1-SQ1-F1"].value?.value, //Any complications with high lipids
        child4_hyperlipidemia_lipids:
          data.MQ02.main_question.sub_question?.["MQ1-SQ1"].member_data
            ?.child_four.field_data?.["MQ1-SQ1-F2"].value?.value, //On medication for High lipids
        child4_hyperlipidemia_diagnosed:
          data.MQ02.main_question.sub_question?.["MQ1-SQ1"].member_data
            ?.child_four.field_data?.["MQ1-SQ1-F3"].value?.value, // 5 to 9yrs , Less than 5yrs and 10yrs or more
        // child4_medicalClaim: "Yes",
        // child4_medical_conditions_disease: "Diabetes", // Diabetes,COPD,Alzhemeirs disease,Systemic Lupus Erythmatous,Kidney failure,Thyroid problem,Kidney Stone,Cancer,Myasthenia gravis,
        child4_medical_covidTested:
          data.MQ01.main_question.member_data?.child_four.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        child4_medical_conditions:
          data.MQ01.main_question.member_data?.child_four.selection_status ===
          true
            ? "Yes"
            : "No",
        child4_medical_sub_conditions:
          data.MQ01.main_question.member_data?.child_four.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value, //
      };
      const windowURL = window.location.search;
      const params = new URLSearchParams(windowURL);
      const cus_type = params.get("cus_type");

      HEALTH_PROPOSAL_SERVICES.getMedicalData(onSuccess, onError, param);
      navigate(
        `${HEALTH_ROUTE.PROPOSAL_PAGE}/${
          HEALTH_ROUTE.PREVIEW_DETAILS
        }?type=HP&companyCode=${
          SELECTED_QUOTE_DATA?.CompanyDetails.company_code
        }&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${
          SELECTED_QUOTE_DATA?.productDetails.id
        }${cus_type === "user" ? "&cus_type=user" : ""}`
      );
    }
  };

  return (
    <>
      {isMobile ? (
        <MMedicalDetails
          medicalQuestionData={medicalQuestionData}
          updateMasterState={updateMasterState}
          validateForm={validateForm}
          PAGE_STATUS={PAGE_STATUS}
          handleChange={handleChange}
          selectedItems={selectedItems}
        />
      ) : (
        <MedicalDetailsComp
          medicalQuestionData={medicalQuestionData}
          updateMasterState={updateMasterState}
          validateForm={validateForm}
          PAGE_STATUS={PAGE_STATUS}
          handleChange={handleChange}
          selectedItems={selectedItems}
        />
      )}
    </>
  );
};

export default MedicalDetailsContainerComp;
