import React, { useEffect, useState } from "react";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import MedicalDetailKotak from "../../../../Page/Desktop/health-php/Kotak/MedicalDetailKotak/MedicalDetailKotak";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import { TKotakMedicalQuestion } from "../../../../types/Health/HealthQuotation/TKotakMedicalQuestion";
import { isEmpty } from "../../../../SupportingFiles/HelpingFunction";
import { HealthSlice } from "../../../../Store/Slice_Reducer/Health/HealthSlice";
import { HEALTH_PROPOSAL_SERVICES } from "../../../../Services/health-php/HealthPropsalServices";
import { HEALTH_ROUTE } from "../../../../Router/Path/HealthRoutes";
import { THealthQuote } from "../../../../types/Health/HealthQuotation/THealthQuote";
import MMedicalDetailKotak from "../../../../Page/Mobile/health-php/Kotak/MMedicalDetailKotak/MMedicalDetailKotak";
import MedicalDetailsRSA from "../../../../Page/Desktop/health-php/RSA/MedicalDetailsRSA/MedicalDetailsRSA";
import { TRSAMedicalQuestion } from "../../../../types/Health/HealthQuotation/TRSAMedicalQuestion";
import MMedicalDetailsRSA from "../../../../Page/Mobile/health-php/RSA/MMedicalDetailsRSA/MMedicalDetailsRSA";

const MedicalDetailsContainerRSA = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    PAGE_STATUS,
    MEDICAL_QUESTION_DATA_RSA,
    SELECTED_QUOTE_DATA,
    ADD_FORM_RESPONSE,
    Preview_Loader,
    INSURED_MEMBER_DETAILS,
  } = useAppSelector((state) => state.Health);
  const { Health } = useAppSelector((state) => state);
  const [change_status, setchange_status] = useState(false);
  const [medicalQuestionData, setMedicalQuestionData] =
    useState<TRSAMedicalQuestion>(MEDICAL_QUESTION_DATA_RSA);
  console.log("MEDICAL_QUESTION_DATA_RSA", MEDICAL_QUESTION_DATA_RSA);

  const setMemberDataAsPerUserSelection = () => {
    let setMemberData: any = { ...MEDICAL_QUESTION_DATA_RSA };
    setMemberData = {
      MQ01: {
        main_question: {
          id: "MQ01",
          icon_class: "tobaco",
          title: "Medical History",
          description:
            "Within the last 2 years have you consulted a doctor or healthcare professional? (other than Preventive or Pre-Employment Health Check-up)?",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
          },
        },
      },
      MQ02: {
        main_question: {
          id: "MQ02",
          icon_class: "tobaco",
          title: "medicalMedicationDiseases",
          description:
            "Health Check-up(Within the last 2 years have you undergone any detailed investigation other than Preventive or Pre-Employment Health Check-up (e.g. X-ray, CT Scan, biopsy, MRI, Sonography, etc)?)",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
          },
        },
      },
      MQ03: {
        main_question: {
          id: "MQ03",
          icon_class: "Cancer",
          title: "Medical Treatment",
          description:
            "Within the last 5 years have you been to a hospital for an operation/medical treatment?",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
          },
        },
      },
      MQ04: {
        main_question: {
          id: "MQ04",
          icon_class: "tobaco",
          title: "Medicines",
          description:
            "Do you take tablets, medicines or drugs on a regular basis?",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
          },
        },
      },
      MQ05: {
        main_question: {
          id: "MQ05",
          icon_class: "tobaco",
          title: "Medical conditions",
          description:
            "Within the last 3 months have you experienced any health problems or medical conditions which you/proposed insured person have/has not seen a doctor for?",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
          },
        },
      },
      MQ06: {
        main_question: {
          id: "MQ06",
          icon_class: "tobaco",
          title: "Hospitalized",
          description:
            "Have any of the person(s) proposed to be insured ever suffered from or taken treatment, or been hospitalized for or have been recommended to undergo / take investigations / medication / surgery or undergone a surgery for any of the following - Diabetes; Hypertension; Ulcer / Cyst / Cancer; Cardiac Disorder; Kidney or Urinary Tract Disorder; Disorder of muscle / bone / joint; Respiratory disorder; Digestive tract or gastrointestinal disorder; Nervous System disorder; Mental Illness or disorder, HIV or AIDS?",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
          },
        },
      },
      MQ07: {
        main_question: {
          id: "MQ07",
          icon_class: "tobaco",
          title: "Alcohol",
          description:
            "Do you consume Alcohol(Beer - No. of Pints/week, Wine & Spirit - ml/week)?",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Number of years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Number of years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Number of years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Number of years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Number of years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Number of years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
      MQ08: {
        main_question: {
          id: "MQ08",
          icon_class: "tobaco",
          title: "Smoking",
          description: "Do you smoke?",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Number of years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Number of years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Number of years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Number of years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Number of years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Number of years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
      MQ09: {
        main_question: {
          id: "MQ09",
          icon_class: "tobaco",
          title: "Tobacco",
          description: "Do you consume Tobacco/Gutka/Pan/Pan masala?",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Number of years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Number of years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Number of years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Number of years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Number of years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Number of years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
      MQ10: {
        main_question: {
          id: "MQ10",
          icon_class: "tobaco",
          title: "Narcotics",
          description: "Do you consume Narcotics?",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Number of years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Number of years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Number of years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Number of years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Number of years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Quantity",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Number of years",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },
    };
    setMedicalQuestionData({ ...setMemberData });
  };
  console.log("medicalQuestionData", medicalQuestionData);

  useEffect(() => {
    if (PAGE_STATUS === true || Preview_Loader === true) {
      setMedicalQuestionData(MEDICAL_QUESTION_DATA_RSA);
    } else {
      setMemberDataAsPerUserSelection();
    }
  }, [MEDICAL_QUESTION_DATA_RSA]);
  let flag = false;

  const updateMasterState = (attrName: any, value: any) => {
    // debugger
    flag = value;
    // console.log('sss',flag)
    console.log("attrName", attrName, value);
    let manipulatedMedicalQuestion: any = JSON.parse(
      JSON.stringify(medicalQuestionData)
    );
    if (attrName[0] === "question") {
      // debugger;
      manipulatedMedicalQuestion = {
        ...manipulatedMedicalQuestion,
        [attrName[1]]: {
          ...manipulatedMedicalQuestion[attrName[1]],
          main_question: {
            ...manipulatedMedicalQuestion[attrName[1]].main_question,
            status: value,
          },
        },
      };

      if (value === false) {
        // Update selection_status of members
        manipulatedMedicalQuestion[attrName[1]].main_question.member_data = {
          ...manipulatedMedicalQuestion[attrName[1]].main_question.member_data,
          adult_one: {
            ...manipulatedMedicalQuestion[attrName[1]].main_question.member_data
              .adult_one,
            selection_status: false,
          },
          adult_two: {
            ...manipulatedMedicalQuestion[attrName[1]].main_question.member_data
              .adult_two,
            selection_status: false,
          },
          child_one: {
            ...manipulatedMedicalQuestion[attrName[1]].main_question.member_data
              .child_one,
            selection_status: false,
          },
          child_two: {
            ...manipulatedMedicalQuestion[attrName[1]].main_question.member_data
              .child_two,
            selection_status: false,
          },
          child_three: {
            ...manipulatedMedicalQuestion[attrName[1]].main_question.member_data
              .child_three,
            selection_status: false,
          },
          child_four: {
            ...manipulatedMedicalQuestion[attrName[1]].main_question.member_data
              .child_four,
            selection_status: false,
          },
        };
      }

      setMedicalQuestionData({ ...manipulatedMedicalQuestion });
    } else if (attrName[0] === "mainMember") {
      if (
        manipulatedMedicalQuestion[attrName[1]]?.main_question?.member_data[
          attrName[2]
        ] !== undefined
      )
        manipulatedMedicalQuestion = {
          ...manipulatedMedicalQuestion,
          [attrName[1]]: {
            ...manipulatedMedicalQuestion[attrName[1]],
            main_question: {
              ...manipulatedMedicalQuestion[attrName[1]]?.main_question,
              member_data: {
                ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                  .member_data,
                [attrName[2]]: {
                  ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                    ?.member_data?.[attrName[2]],
                  selection_status: value,
                },
              },
            },
          },
        };

      setMedicalQuestionData(manipulatedMedicalQuestion);
    } else if (attrName[0] === "mainMemberDataField") {
      if (
        attrName[3] === "adult_one" ||
        attrName[3] === "adult_two" ||
        attrName[3] === "child_one" ||
        attrName[3] === "child_two" ||
        attrName[3] === "child_three" ||
        (attrName[3] === "child_four" &&
          manipulatedMedicalQuestion !== undefined)
      ) {
        console.log("attrName[3]", attrName[3]);

        manipulatedMedicalQuestion[attrName[1]].main_question.member_data[
          attrName[3]
        ].field_data[attrName[2]].value = {
          value: value,
          warning: isEmpty(value),
        };

        setMedicalQuestionData({ ...manipulatedMedicalQuestion });
      }
    }
  };

  // const updateMasterState = (attrName: any, value: any) => {
  //   // debugger
  //   flag = value;
  //   // console.log('sss',flag)
  //   console.log("attrName",attrName, value);
  //   let manipulatedMedicalQuestion: any = JSON.parse(
  //     JSON.stringify(medicalQuestionData)
  //   );
  //   if (attrName[0] === "question") {
  //     // debugger;
  //     manipulatedMedicalQuestion = {
  //       ...manipulatedMedicalQuestion,
  //       [attrName[1]]: {
  //         ...manipulatedMedicalQuestion[attrName[1]],
  //         main_question: {
  //           ...manipulatedMedicalQuestion[attrName[1]].main_question,
  //           status: value,
  //         },
  //       },
  //     };
  //     // if (value === false) {
  //     //   // Update selection_status of members
  //     //   manipulatedMedicalQuestion[attrName[1]].main_question.member_data = {
  //     //     ...manipulatedMedicalQuestion[attrName[1]].main_question.member_data,
  //     //     adult_one: {
  //     //       ...manipulatedMedicalQuestion[attrName[1]].main_question.member_data
  //     //         .adult_one,
  //     //       selection_status: false,
  //     //     },
  //     //     adult_two: {
  //     //       ...manipulatedMedicalQuestion[attrName[1]].main_question.member_data
  //     //         .adult_two,
  //     //       selection_status: false,
  //     //     },
  //     //     child_one: {
  //     //       ...manipulatedMedicalQuestion[attrName[1]].main_question.member_data
  //     //         .child_one,
  //     //       selection_status: false,
  //     //     },
  //     //     child_two: {
  //     //       ...manipulatedMedicalQuestion[attrName[1]].main_question.member_data
  //     //         .child_two,
  //     //       selection_status: false,
  //     //     },
  //     //     child_three: {
  //     //       ...manipulatedMedicalQuestion[attrName[1]].main_question.member_data
  //     //         .child_three,
  //     //       selection_status: false,
  //     //     },
  //     //     child_four: {
  //     //       ...manipulatedMedicalQuestion[attrName[1]].main_question.member_data
  //     //         .child_four,
  //     //       selection_status: false,
  //     //     },
  //     //   };
  //     // }

  //     setMedicalQuestionData({ ...manipulatedMedicalQuestion });
  //   } else if (attrName[0] === "subQuestion") {
  //     // debugger;
  //     setchange_status(flag);

  //     let updatedSubQuestion = {
  //       ...manipulatedMedicalQuestion[attrName[1]].main_question.member_data,
  //       [attrName[2]]: {
  //         ...manipulatedMedicalQuestion[attrName[1]].main_question.member_data[
  //           attrName[2]
  //         ],
  //         status: value,
  //       },
  //     };
  //     let updatedMainQuestion = {
  //       ...manipulatedMedicalQuestion[attrName[1]].main_question,
  //       member_data: updatedSubQuestion,
  //     };

  //     let updatedManipulatedMedicalQuestion = {
  //       ...manipulatedMedicalQuestion,
  //       [attrName[1]]: {
  //         ...manipulatedMedicalQuestion[attrName[1]],
  //         main_question: updatedMainQuestion,
  //       },
  //     };

  //     setMedicalQuestionData(updatedManipulatedMedicalQuestion);
  //   } else if (attrName[0] === "mainMember") {
  //     // debugger;
  //     console.log("attrName[3]1",attrName[3]);

  //     if (manipulatedMedicalQuestion[attrName[1]]?.main_question.member_data[attrName[2]]?.member_data[attrName[3]] !== undefined) {
  //       manipulatedMedicalQuestion = {
  //         ...manipulatedMedicalQuestion,
  //         [attrName[1]]: {
  //           ...manipulatedMedicalQuestion[attrName[1]],
  //           main_question: {
  //             ...manipulatedMedicalQuestion[attrName[1]]?.main_question,
  //             member_data: {
  //               ...manipulatedMedicalQuestion[attrName[1]].main_question
  //                 .member_data,
  //               [attrName[2]]: {
  //                 ...manipulatedMedicalQuestion[attrName[1]]?.main_question
  //                   .member_data?.[attrName[2]],
  //                 member_data: {
  //                   ...manipulatedMedicalQuestion[attrName[1]]?.main_question
  //                     .member_data?.[attrName[2]].member_data,
  //                   [attrName[3]]: {
  //                     ...manipulatedMedicalQuestion[attrName[1]]?.main_question
  //                       .member_data?.[attrName[2]].member_data?.[attrName[3]],
  //                     selection_status: value,
  //                   },
  //                 },
  //               },
  //             },
  //           },
  //         },
  //       };
  //       setMedicalQuestionData({ ...manipulatedMedicalQuestion });
  //     }
  //   } else if (attrName[0] === "mainMemberData") {
  //     // debugger;
  //     if (
  //       manipulatedMedicalQuestion[attrName[1]]?.main_question?.member_data[attrName[2]] !== undefined)
  //       manipulatedMedicalQuestion = {
  //         ...manipulatedMedicalQuestion,
  //         [attrName[1]]: {
  //           ...manipulatedMedicalQuestion[attrName[1]],
  //           main_question: {
  //             ...manipulatedMedicalQuestion[attrName[1]]?.main_question,
  //             member_data: {
  //               ...manipulatedMedicalQuestion[attrName[1]]?.main_question
  //                 .member_data,
  //               [attrName[2]]: {
  //                 ...manipulatedMedicalQuestion[attrName[1]]?.main_question
  //                   ?.member_data?.[attrName[2]],
  //                 selection_status: value,
  //               },
  //             },
  //           },
  //         },
  //       };

  //     setMedicalQuestionData(manipulatedMedicalQuestion);
  //   } else if (attrName[0] === "mainMemberDataField" && attrName[1] === "MQ02") {
  //     // debugger;
  //     if (
  //       attrName[2] === "adult_one" ||
  //       attrName[2] === "adult_two" ||
  //       attrName[2] === "child_one" ||
  //       attrName[2] === "child_two" ||
  //       attrName[2] === "child_three" ||
  //       (attrName[2] === "child_four" &&
  //         manipulatedMedicalQuestion !== undefined)
  //     ) {
  //       manipulatedMedicalQuestion[attrName[1]].main_question.member_data[
  //         attrName[4]
  //       ].member_data[attrName[2]].field_data[attrName[3]].value = {
  //         value: value,
  //         warning: isEmpty(value),
  //       };

  //       setMedicalQuestionData({ ...manipulatedMedicalQuestion });
  //     }
  //   } else if (attrName[0] === "mainMemberDataField" && attrName[1] !== "MQ02") {
  //     if ( attrName[2] === "adult_one" ||attrName[2] === "adult_two" ||attrName[2] === "child_one" ||attrName[2] === "child_two" || attrName[2] === "child_three" ||
  //       (attrName[2] === "child_four" && manipulatedMedicalQuestion !== undefined)
  //     ) {
  //       manipulatedMedicalQuestion[attrName[1]].main_question.member_data[attrName[2]].field_data["MQ1-SQ1-F1"].value = {
  //         value: value,
  //         warning: isEmpty(value),
  //     }

  //     setMedicalQuestionData({ ...manipulatedMedicalQuestion });
  //     };

  //   }
  // };

  const validateForm = () => {
    if (!medicalQuestionData) {
      return; // Exit early if medicalQuestionData is null or undefined
    }
    let data: TKotakMedicalQuestion = JSON.parse(
      JSON.stringify(medicalQuestionData)
    );
    let hasError = false;

    Object.values(data).forEach((element) => {
      const mainQuestion = element?.main_question;
      if (mainQuestion && mainQuestion.status) {
        const memberData = mainQuestion.member_data;
        if (memberData) {
          Object.values(memberData).forEach((member) => {
            if (member.selection_status && member.field_data) {
              Object.values(member.field_data).forEach((field) => {
                if (field.value?.value === "") {
                  field.value = {
                    ...field.value,
                    warning: true,
                  };
                  hasError = true;
                }
              });
            }
          });
        }
      }
    });
    setMedicalQuestionData(data);
    console.log("hasError", hasError);
    if (!hasError) {
      dispatch(HealthSlice.actions.SAVE_MEDICAL_QUESTION_DATA_RSA(data));
      const onSuccess = (res: any) => {
        dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
        if (res.results.error === false) {
          let quoteData: THealthQuote = SELECTED_QUOTE_DATA;
          quoteData = {
            ...quoteData,
            premiumDetails: {
              ...quoteData.premiumDetails,
              finalPremium: res.results.response.premiumDetails.finalPremium,
            },
          };
          dispatch(HealthSlice.actions.SAVE_SELECTED_QUOTE_DATA(quoteData));
        }
      };
      const onError = (err: any) => {
        dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
        console.log("err..", err);
      };
      dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(true));
      const param = {
        quote_no: ADD_FORM_RESPONSE.quote_no,
        company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
        product_code: SELECTED_QUOTE_DATA.productDetails.product_code,
        medical_info: "Yes",
        medicalPreExistingDiseases:
          data.MQ01.main_question.status === true ? "Yes" : "No", //Are you currently in good mental and physical health
        medicalPreExisting:
          data.MQ01.main_question.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "No",
        spouse_medicalPreExisting:
          data.MQ01.main_question.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "No",
        child1_medicalPreExisting:
          data.MQ01.main_question.member_data?.child_one.selection_status ===
          true
            ? "Yes"
            : "No",
        child2_medicalPreExisting:
          data.MQ01.main_question.member_data?.child_two.selection_status ===
          true
            ? "Yes"
            : "No",
        child3_medicalPreExisting:
          data.MQ01.main_question.member_data?.child_three.selection_status ===
          true
            ? "Yes"
            : "No",
        child4_medicalPreExisting:
          data.MQ01.main_question.member_data?.child_three.selection_status ===
          true
            ? "Yes"
            : "No",

        medicalMedicationDiseases:
          data.MQ02.main_question.status === true ? "Yes" : "No", //Are you currently in good mental and physical health
        medicalMedication:
          data.MQ02.main_question.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "No",
        spouse_medicalMedication:
          data.MQ02.main_question.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "No",
        child1_medicalMedication:
          data.MQ02.main_question.member_data?.child_one.selection_status ===
          true
            ? "Yes"
            : "No",
        child2_medicalMedication:
          data.MQ02.main_question.member_data?.child_two.selection_status ===
          true
            ? "Yes"
            : "No",
        child3_medicalMedication:
          data.MQ02.main_question.member_data?.child_three.selection_status ===
          true
            ? "Yes"
            : "No",
        child4_medicalMedication:
          data.MQ02.main_question.member_data?.child_three.selection_status ===
          true
            ? "Yes"
            : "No",

        medicalHospitalizedDiseases:
          data.MQ03.main_question.status === true ? "Yes" : "No", //Any hospitalization in the past Yes / No
        medicalHospitalized:
          data.MQ03.main_question.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "No",
        spouse_medicalHospitalized:
          data.MQ03.main_question.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "No",
        child1_medicalHospitalized:
          data.MQ03.main_question.member_data?.child_one.selection_status ===
          true
            ? "Yes"
            : "No",
        child2_medicalHospitalized:
          data.MQ03.main_question.member_data?.child_two.selection_status ===
          true
            ? "Yes"
            : "No",
        child3_medicalHospitalized:
          data.MQ03.main_question.member_data?.child_three.selection_status ===
          true
            ? "Yes"
            : "No",
        child4_medicalHospitalized:
          data.MQ03.main_question.member_data?.child_three.selection_status ===
          true
            ? "Yes"
            : "No",

        medicalMedicinesDiseases:
          data.MQ04.main_question.status === true ? "Yes" : "No", //Any hospitalization in the past Yes / No
        medicalMedicines:
          data.MQ04.main_question.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "No",
        spouse_medicalMedicines:
          data.MQ04.main_question.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "No",
        child1_medicalMedicines:
          data.MQ04.main_question.member_data?.child_one.selection_status ===
          true
            ? "Yes"
            : "No",
        child2_medicalMedicines:
          data.MQ04.main_question.member_data?.child_two.selection_status ===
          true
            ? "Yes"
            : "No",
        child3_medicalMedicines:
          data.MQ04.main_question.member_data?.child_three.selection_status ===
          true
            ? "Yes"
            : "No",
        child4_medicalMedicines:
          data.MQ04.main_question.member_data?.child_three.selection_status ===
          true
            ? "Yes"
            : "No",

        medicalProblemDiseases:
          data.MQ05.main_question.status === true ? "Yes" : "No", //Any hospitalization in the past Yes / No
        medicalProblem:
          data.MQ05.main_question.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "No",
        spouse_medicalProblem:
          data.MQ05.main_question.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "No",
        child1_medicalProblem:
          data.MQ05.main_question.member_data?.child_one.selection_status ===
          true
            ? "Yes"
            : "No",
        child2_medicalProblem:
          data.MQ05.main_question.member_data?.child_two.selection_status ===
          true
            ? "Yes"
            : "No",
        child3_medicalProblem:
          data.MQ05.main_question.member_data?.child_three.selection_status ===
          true
            ? "Yes"
            : "No",
        child4_medicalProblem:
          data.MQ05.main_question.member_data?.child_three.selection_status ===
          true
            ? "Yes"
            : "No",

        medicalHospitalizationDiseases:
          data.MQ05.main_question.status === true ? "Yes" : "No", //Any hospitalization in the past Yes / No
        medicalHospitalization:
          data.MQ05.main_question.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "No",
        spouse_medicalHospitalization:
          data.MQ05.main_question.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "No",
        child1_medicalHospitalization:
          data.MQ05.main_question.member_data?.child_one.selection_status ===
          true
            ? "Yes"
            : "No",
        child2_medicalHospitalization:
          data.MQ05.main_question.member_data?.child_two.selection_status ===
          true
            ? "Yes"
            : "No",
        child3_medicalHospitalization:
          data.MQ05.main_question.member_data?.child_three.selection_status ===
          true
            ? "Yes"
            : "No",
        child4_medicalHospitalization:
          data.MQ05.main_question.member_data?.child_three.selection_status ===
          true
            ? "Yes"
            : "No",

        medicalAlcoholDiseases:
          data.MQ07.main_question.status === true ? "Yes" : "No", // Accidents / burns
        medicalAlcohol:
          data.MQ07.main_question.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "No",
        alcoholQuantity:
          data?.MQ07?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        alcoholDuration:
          data?.MQ07?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,

        spouse_medicalAlcohol:
          data.MQ07.main_question.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "No",
        spouse_alcoholQuantity:
          data?.MQ07?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        spouse_alcoholDuration:
          data?.MQ07?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,

        child1_medicalAlcohol:
          data.MQ07.main_question.member_data?.child_one.selection_status ===
          true
            ? "Yes"
            : "No",
        child1_alcoholQuantity:
          data?.MQ07?.main_question?.member_data?.child_one?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        child1_alcoholDuration:
          data?.MQ07?.main_question?.member_data?.child_one?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,

        child2_medicalAlcohol:
          data.MQ07.main_question.member_data?.child_two.selection_status ===
          true
            ? "Yes"
            : "No",
        child2_alcoholQuantity:
          data?.MQ07?.main_question?.member_data?.child_two?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        child2_alcoholDuration:
          data?.MQ07?.main_question?.member_data?.child_two?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,

        child3_medicalAlcohol:
          data.MQ07.main_question.member_data?.child_three.selection_status ===
          true
            ? "Yes"
            : "No",
        child3_alcoholQuantity:
          data?.MQ07?.main_question?.member_data?.child_three?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        child3_alcoholDuration:
          data?.MQ07?.main_question?.member_data?.child_three?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,

        child4_medicalAlcohol:
          data.MQ07.main_question.member_data?.child_three.selection_status ===
          true
            ? "Yes"
            : "No",
        child4_alcoholQuantity:
          data?.MQ07?.main_question?.member_data?.child_three?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        child4_alcoholDuration:
          data?.MQ07?.main_question?.member_data?.child_three?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,

        medicalSmokingDiseases:
          data.MQ08.main_question.status === true ? "Yes" : "No", // Accidents / burns
        medicalSmoking:
          data.MQ08.main_question.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "No",
        smokingQuantity:
          data?.MQ08?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        smokingDuration:
          data?.MQ08?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,

        spouse_medicalSmoking:
          data.MQ08.main_question.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "No",
        spouse_smokingQuantity:
          data?.MQ08?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        spouse_smokingDuration:
          data?.MQ08?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,

        child1_medicalSmoking:
          data.MQ08.main_question.member_data?.child_one.selection_status ===
          true
            ? "Yes"
            : "No",
        child1_smokingQuantity:
          data?.MQ08?.main_question?.member_data?.child_one?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        child1_smokingDuration:
          data?.MQ08?.main_question?.member_data?.child_one?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,

        child2_medicalSmoking:
          data.MQ08.main_question.member_data?.child_two.selection_status ===
          true
            ? "Yes"
            : "No",
        child2_smokingQuantity:
          data?.MQ08?.main_question?.member_data?.child_two?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        child2_smokingDuration:
          data?.MQ08?.main_question?.member_data?.child_two?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,

        child3_medicalSmoking:
          data.MQ08.main_question.member_data?.child_three.selection_status ===
          true
            ? "Yes"
            : "No",
        child3_smokingQuantity:
          data?.MQ08?.main_question?.member_data?.child_three?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        child3_smokingDuration:
          data?.MQ08?.main_question?.member_data?.child_three?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,

        child4_medicalSmoking:
          data.MQ08.main_question.member_data?.child_three.selection_status ===
          true
            ? "Yes"
            : "No",
        child4_smokingQuantity:
          data?.MQ08?.main_question?.member_data?.child_three?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        child4_smokingDuration:
          data?.MQ08?.main_question?.member_data?.child_three?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,

        medicalOtherSubstanceDiseases:
          data.MQ09.main_question.status === true ? "Yes" : "No", //Smoking / Tobacco consumption
        medicalOtherSubstance:
          data.MQ09.main_question.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "No",
        OtherSubstanceQuantity:
          data?.MQ09?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        OtherSubstanceDuration:
          data?.MQ09?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,
        spouse_medicalOtherSubstance:
          data.MQ09.main_question.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "No",
        spouse_OtherSubstanceQuantity:
          data?.MQ09?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        spouse_OtherSubstanceDuration:
          data?.MQ09?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,
        child1_medicalOtherSubstance:
          data.MQ09.main_question.member_data?.child_one.selection_status ===
          true
            ? "Yes"
            : "No",
        child1_OtherSubstanceQuantity:
          data?.MQ09?.main_question?.member_data?.child_one?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        child1_OtherSubstanceDuration:
          data?.MQ09?.main_question?.member_data?.child_one?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,
        child2_medicalOtherSubstance:
          data.MQ09.main_question.member_data?.child_two.selection_status ===
          true
            ? "Yes"
            : "No",
        child2_OtherSubstanceQuantity:
          data?.MQ09?.main_question?.member_data?.child_two?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        child2_OtherSubstanceDuration:
          data?.MQ09?.main_question?.member_data?.child_two?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,
        child3_medicalOtherSubstance:
          data.MQ09.main_question.member_data?.child_three.selection_status ===
          true
            ? "Yes"
            : "No",
        child3_OtherSubstanceQuantity:
          data?.MQ09?.main_question?.member_data?.child_three?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        child3_OtherSubstanceDuration:
          data?.MQ09?.main_question?.member_data?.child_three?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,

        child4_medicalOtherSubstance:
          data.MQ09.main_question.member_data?.child_three.selection_status ===
          true
            ? "Yes"
            : "No",
        child4_OtherSubstanceQuantity:
          data?.MQ09?.main_question?.member_data?.child_three?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        child4_OtherSubstanceDuration:
          data?.MQ09?.main_question?.member_data?.child_three?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,

        medicalNaroticsDiseases:
          data.MQ10.main_question.status === true ? "Yes" : "No", //Alcohol consumption
        medicalNarotics:
          data.MQ10.main_question.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "No",
        naroticsQuantity:
          data?.MQ10?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        naroticsDuration:
          data?.MQ10?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,

        spouse_medicalNarotics:
          data.MQ10.main_question.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "No",
        spouse_naroticsQuantity:
          data?.MQ10?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        spouse_naroticsDuration:
          data?.MQ10?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,

        child1_medicalNarotics:
          data.MQ10.main_question.member_data?.child_three.selection_status ===
          true
            ? "Yes"
            : "No",
        child1_naroticsQuantity:
          data?.MQ10?.main_question?.member_data?.child_three?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        child1_naroticsDuration:
          data?.MQ10?.main_question?.member_data?.child_three?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,

        child2_medicalNarotics:
          data.MQ10.main_question.member_data?.child_three.selection_status ===
          true
            ? "Yes"
            : "No",
        child2_naroticsQuantity:
          data?.MQ10?.main_question?.member_data?.child_three?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        child2_naroticsDuration:
          data?.MQ10?.main_question?.member_data?.child_three?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,

        child3_medicalNarotics:
          data.MQ10.main_question.member_data?.child_three.selection_status ===
          true
            ? "Yes"
            : "No",
        child3_naroticsQuantity:
          data?.MQ10?.main_question?.member_data?.child_three?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        child3_naroticsDuration:
          data?.MQ10?.main_question?.member_data?.child_three?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,

        child4_medicalNarotics:
          data.MQ10.main_question.member_data?.child_three.selection_status ===
          true
            ? "Yes"
            : "No",
        child4_naroticsQuantity:
          data?.MQ10?.main_question?.member_data?.child_three?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        child4_naroticsDuration:
          data?.MQ10?.main_question?.member_data?.child_three?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,

        // medicalCancelDiseases:
        //   data.MQ10.main_question.status === true ? "Yes" : "No", //Have you or any person proposed to be insured under the policy has been refused insurance cover by any insurance company or being accepted on special terms?
        // medicalCancel:
        //   data.MQ10.main_question.member_data?.adult_one.selection_status ===
        //   true
        //     ? "Yes"
        //     : "No",
        // spouse_medicalCancel:
        //   data.MQ10.main_question.member_data?.adult_two.selection_status ===
        //   true
        //     ? "Yes"
        //     : "No",
        // child1_medicalCancel:
        //   data.MQ10.main_question.member_data?.child_one.selection_status ===
        //   true
        //     ? "Yes"
        //     : "No",
        // child2_medicalCancel:
        //   data.MQ10.main_question.member_data?.child_two.selection_status ===
        //   true
        //     ? "Yes"
        //     : "No",
        // child3_medicalCancel:
        //   data.MQ10.main_question.member_data?.child_three.selection_status ===
        //   true
        //     ? "Yes"
        //     : "No",
        // medicalOtherDiseasesDiseases:
        //   data.MQ02.main_question.member_data?.["MQ14-SQ14"].status === true
        //     ? "Yes"
        //     : "No", //Any other illness/ Injury details
        // medicalOtherDiseases:
        //   data.MQ02.main_question.member_data?.["MQ14-SQ14"].member_data
        //     ?.adult_one.selection_status === true
        //     ? "Yes"
        //     : "No",
        // other_diseases_month_year:
        //   data.MQ02.main_question.member_data?.["MQ14-SQ14"].member_data
        //     ?.adult_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        // other_diseases_desc:
        //   data.MQ02.main_question.member_data?.["MQ14-SQ14"].member_data
        //     ?.adult_one.field_data?.["MQ1-SQ1-F2"].value?.value,
        // spouse_medicalOtherDiseases:
        //   data.MQ02.main_question.member_data?.["MQ14-SQ14"].member_data
        //     ?.adult_two.selection_status === true
        //     ? "Yes"
        //     : "No",
        // spouse_other_diseases_month_year:
        //   data.MQ02.main_question.member_data?.["MQ14-SQ14"].member_data
        //     ?.adult_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        // spouse_other_diseases_desc:
        //   data.MQ02.main_question.member_data?.["MQ14-SQ14"].member_data
        //     ?.adult_two.field_data?.["MQ1-SQ1-F2"].value?.value,
        // child1_medicalOtherDiseases:
        //   data.MQ02.main_question.member_data?.["MQ14-SQ14"].member_data
        //     ?.child_one.selection_status === true
        //     ? "Yes"
        //     : "No",
        // child1_other_diseases_month_year:
        //   data.MQ02.main_question.member_data?.["MQ14-SQ14"].member_data
        //     ?.child_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        // child1_other_diseases_desc:
        //   data.MQ02.main_question.member_data?.["MQ14-SQ14"].member_data
        //     ?.child_one.field_data?.["MQ1-SQ1-F2"].value?.value,
        // child2_medicalOtherDiseases:
        //   data.MQ02.main_question.member_data?.["MQ14-SQ14"].member_data
        //     ?.child_two.selection_status === true
        //     ? "Yes"
        //     : "No",
        // child2_other_diseases_month_year:
        //   data.MQ02.main_question.member_data?.["MQ14-SQ14"].member_data
        //     ?.child_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        // child2_other_diseases_desc:
        //   data.MQ02.main_question.member_data?.["MQ14-SQ14"].member_data
        //     ?.child_two.field_data?.["MQ1-SQ1-F2"].value?.value,
        // child3_medicalOtherDiseases:
        //   data.MQ02.main_question.member_data?.["MQ14-SQ14"].member_data
        //     ?.child_three.selection_status === true
        //     ? "Yes"
        //     : "No",
        // child3_other_diseases_month_year:
        //   data.MQ02.main_question.member_data?.["MQ14-SQ14"].member_data
        //     ?.child_three.field_data?.["MQ1-SQ1-F1"].value?.value,
        // child3_other_diseases_desc:
        //   data.MQ02.main_question.member_data?.["MQ14-SQ14"].member_data
        //     ?.child_three.field_data?.["MQ1-SQ1-F2"].value?.value,
      };
      const windowURL = window.location.search;
      const params = new URLSearchParams(windowURL);
      const cus_type = params.get("cus_type");

      HEALTH_PROPOSAL_SERVICES.getMedicalData(onSuccess, onError, param);
      navigate(
        `${HEALTH_ROUTE.PROPOSAL_PAGE}/${
          HEALTH_ROUTE.PREVIEW_DETAILS
        }?type=HP&companyCode=${
          SELECTED_QUOTE_DATA?.CompanyDetails.company_code
        }&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${
          SELECTED_QUOTE_DATA?.productDetails.id
        }${cus_type === "user" ? "&cus_type=user" : ""}`
      );
    }
  };

  return (
    <>
      {isMobile ? (
        <MMedicalDetailsRSA
          medicalQuestionData={medicalQuestionData}
          updateMasterState={updateMasterState}
          validateForm={validateForm}
          PAGE_STATUS={PAGE_STATUS}
        />
      ) : (
        <MedicalDetailsRSA
          medicalQuestionData={medicalQuestionData}
          updateMasterState={updateMasterState}
          validateForm={validateForm}
          PAGE_STATUS={PAGE_STATUS}
        />
      )}
    </>
  );
};

export default MedicalDetailsContainerRSA;
