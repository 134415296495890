import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import { formatToCurrency } from "../../../../../SupportingFiles/HelpingFunction";
import "./MAddonCoverQuestion.scss";
import ToggleSwitch from "../../../../Term/ToggleSwitch/ToggleSwitch";

interface Props {
  loader?: boolean;
  addon_amount?: string;
  main_class?: string;
  question_name: string;
  sub_details?: any;
  value_update?: Function;
  attrName?: string;
  toggle_status?: boolean | undefined;
  show_premium?: boolean;
  desc?: string;
  free?: boolean;
}
const MAddonCoverQuestion: React.FC<Props> = ({
  loader,
  addon_amount,
  main_class,
  question_name,
  sub_details,
  value_update,
  attrName,
  toggle_status,
  show_premium = true,
  free = false,
  desc,
}) => {
  return (
    <Box className={"mAddonQuestion borderBottom " + main_class}>
      <Grid container spacing={2} alignItems="center">
        <Grid xs={8} className="mAddonQuestionInner">
          <div>
            <h6>{question_name}</h6>
            <p>{desc}</p>
          </div>
        </Grid>
        <Grid xs={4} textAlign="right" className="pt-4">
          <Box sx={{ height: "25px" }}>
            {show_premium ? (
              toggle_status ? (
                loader ? (
                  <img src="images/loader.svg" height="10px" alt="Brand Logo" />
                ) : (
                  <p className="amount">
                    {formatToCurrency(Number(`${addon_amount}`))}
                  </p>
                )
              ) : null
            ) : null}
            {free ? <p className="amount">Free</p> : null}
          </Box>

          {free ? null : (
            <ToggleSwitch
              toggle_status={toggle_status}
              value_update={value_update}
              attrName={attrName}
            />
          )}
        </Grid>
      </Grid>
      {toggle_status ? sub_details : null}
    </Box>
  );
};

export default MAddonCoverQuestion;
