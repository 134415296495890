import Masonry from "@mui/lab/Masonry";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "../../../../../../SCSS/CommonSCSS/MProposalForms.scss";
import { TAddressDetails } from "../../../../../../types/TermFullJourney/TAddressDetails";
import { useAppSelector } from "../../../../../../Store/hooks";
import {
  formatAmountToCurrency,
  formatToCurrency,
} from "../../../../../../SupportingFiles/HelpingFunction";
import PreviewDetailBox from "../../../../../../Component/Mobile/TermFullJourney/PreviewDetails/PreviewDetailBox/PreviewDetailBox";
import AddressDetailBox from "../../../../../../Component/Mobile/TermFullJourney/PreviewDetails/AddressDetailsBox/AddressDetailsBox";
import Footer from "../../../../../../Component/Mobile/TermFullJourney/ProposalForm/Common/Footer/Footer";

const MPreviewDetails = ({
  loader,
  PreviewDetails,
  permanentAddressDetails,
  mailingAddressDetails,
  address_details,
  continueFunction,
}: {
  loader: boolean;
  PreviewDetails: Array<any>;
  permanentAddressDetails: any;
  mailingAddressDetails: any;
  address_details: TAddressDetails;
  continueFunction: Function;
}) => {
  const { SELECTED_QUOTE_DATA, QUOTE_FIELDS_DATA } = useAppSelector(
    (state) => state.Term
  );

  return (
    <Box className="proposalPreviewMobile">
      <h5 className="sectionTitle">Just make sure, all details are correct</h5>
      <Box className="previewDetailBox">
        <Grid container spacing={2}>
          <Grid xs={6}>
            <img src={SELECTED_QUOTE_DATA.companyDetails.logo} alt="logo" />
            <p className="mt-3">
              {SELECTED_QUOTE_DATA.productDetails.product_name}
            </p>
            <p>
              Policy Tenure:{" "}
              <span>{`${SELECTED_QUOTE_DATA.premiumDetails.term} Years`}</span>
            </p>
          </Grid>
          <Grid xs={6} textAlign="right">
            <p className="premium">
              Premium:{" "}
              <span>
                {formatAmountToCurrency(
                  Number(SELECTED_QUOTE_DATA.premiumDetails.finalPremium)
                )}
              </span>
            </p>
            <p>
              Sum Assured:{" "}
              <span>
                {formatToCurrency(
                  Number(SELECTED_QUOTE_DATA.premiumDetails.sum_assured)
                )}
              </span>
            </p>
            <p>
              Payment Mode: <span>{QUOTE_FIELDS_DATA.payMode}</span>
            </p>
          </Grid>
        </Grid>
      </Box>
      <Masonry columns={1} spacing={2} className="px-0 mx-0">
        {PreviewDetails.map((data, index) => (
          <PreviewDetailBox
            editFunction={data.editFunction}
            title={data.title}
            data={data.detailView}
          />
        ))}
        <AddressDetailBox
          editFunction={permanentAddressDetails.editFunction}
          permanentAddress={permanentAddressDetails.detailView}
          mailingAddress={mailingAddressDetails.detailView}
          permanent_mailing_address_same={
            address_details.is_mailing_and_permanent_address_same
          }
        />
      </Masonry>
      <Footer loader={loader} continueFunction={continueFunction} />
    </Box>
  );
};
export default MPreviewDetails;
