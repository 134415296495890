import { Box, Collapse } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { subDays } from "date-fns";
import React, { useState } from "react";
import DatePicker from "../../../../../Component/FieldTypes/DatePicker/DatePicker";
import RKTextField from "../../../../../Component/FieldTypes/RKTextField/RKTextField";
import MFooter from "../../../../../Component/MFooter/MFooter";
import MMedicalDetailQuestion from "../../../../../Component/MMedicalDetailQuestion/MMedicalDetailQuestion";
import MemberRadioButtonNew from "../../../../../Component/MemberRadioButton/MemberRadioButtonNew";
import { useAppSelector } from "../../../../../Store/hooks";
import { calculateAge, calculateAgeInDays } from "../../../../../SupportingFiles/HelpingFunction";
import "./../../../../../SCSS/CommonSCSS/MProposalForms.scss";
import MonthYearPicker from "../../../../../Component/FieldTypes/MonthYearPicker/MonthYearPicker";
// import React from "react";

const MMedicalDetails = ({
  medicalQuestionData,
  updateMasterState,
  validateForm,
  PAGE_STATUS,
}: {
  medicalQuestionData: any;
  updateMasterState: Function;
  validateForm: Function;
  PAGE_STATUS: boolean;
}) => {
  const [checkInnerQuestion, setCheckInnerQuestion] = useState({
    "1": false,
    "2": false,
    "3": false,
    "4": false,
    "5": false,
    "6": false,
  });
  const handleSetInnerQusetion = (event: any) => {
    const { name, checked } = event.target;
    setCheckInnerQuestion((prev) => ({ ...prev, [name]: checked }));
  };
  const [memberStatus, setMemberStatus] = useState({
    member1: false,
    member2: false,
  });
  const handleMemberStatus = (event: any) => {
    const { name, checked } = event.target;
    setMemberStatus((prev) => ({ ...prev, [name]: checked }));
  };
  const { INSURED_MEMBER_DETAILS } = useAppSelector((state) => state.Health);
  const { VERIFY_KYC_FORM } = useAppSelector((state) => state.CKYC);

  return (
    <>
      <Box>
        <Grid container className="row" columnSpacing={3} marginBottom={"24px"}>
          <Grid xs={12}>
            <h5 className="sectionTitle">Medical Details</h5>
          </Grid>
        </Grid>

        {Object.values(medicalQuestionData).map(
          (questionData: any, index: any) => (
            <MMedicalDetailQuestion
              key={index}
              main_Question={`Q. ${questionData.main_question.title}`}
              mainQues_info={questionData.main_question.description}
              data={questionData}
              updateMasterState={updateMasterState}
              attrName={["question", questionData?.main_question?.id]}
              value_update={updateMasterState}
              toggle_button_status={questionData?.main_question?.status}
              innerQuestiondata={
                <Collapse in={questionData?.main_question?.status}>
                  <Box>
                    {questionData.main_question.sub_question &&
                      Object.values(
                        questionData.main_question.sub_question
                      ).map(
                        (dataSubQuestion: any, indexSubQuestion: number) => {
                          console.log("dataSubQuestionssssss", dataSubQuestion);
                          return (
                            <MMedicalDetailQuestion
                              key={indexSubQuestion}
                              main_Question={`${dataSubQuestion?.title}`}
                              mainQues_info={dataSubQuestion?.description}
                              data={questionData}
                              updateMasterState={updateMasterState}
                              attrName={[
                                "subQuestion",
                                questionData?.main_question?.id,
                                dataSubQuestion?.id,
                              ]}
                              value_update={updateMasterState}
                              toggle_button_status={dataSubQuestion?.status}
                              innerQuestiondata={
                                <Collapse in={dataSubQuestion?.status}>
                                  <Grid
                                    container
                                    columnSpacing={2}
                                    margin={"0 0 12px 0"}>
                                    {dataSubQuestion?.member_data &&
                                      Object.values(
                                        dataSubQuestion?.member_data
                                      ).map(
                                        (
                                          memberDataValue: any,
                                          memberIndex: number
                                        ) => {
                                          console.log(
                                            "memberDataValue>>>>>>>>>>>",
                                            memberDataValue
                                          );
                                          return (
                                            <Grid xs={6}>
                                              <Box key={indexSubQuestion}>
                                                {memberDataValue.name !== "" ? (
                                                  <>
                                                    <form>
                                                      {" "}
                                                      <MemberRadioButtonNew
                                                        class_name={
                                                          memberIndex === 0 ||
                                                            memberIndex === 1
                                                            ? "adult"
                                                            : "child"
                                                        }
                                                        checked={
                                                          memberDataValue?.selection_status
                                                        }
                                                        label_name={
                                                          memberDataValue.name
                                                        }
                                                        attrName={[
                                                          "memberData",
                                                          questionData
                                                            .main_question.id,
                                                          dataSubQuestion.id,
                                                          memberDataValue.keyName,
                                                        ]}
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                      />
                                                      {memberDataValue?.selection_status &&
                                                        memberDataValue.keyName && (
                                                          <>
                                                            {Object.values(
                                                              memberDataValue.field_data
                                                            ).map(
                                                              (
                                                                field_data_data: any
                                                              ) => {
                                                                let datePickerRendered =
                                                                  false; // Flag to track DatePicker rendering
                                                                return (
                                                                  <React.Fragment
                                                                    key={
                                                                      field_data_data.id
                                                                    }>
                                                                    {field_data_data.field_type ===
                                                                      "DATEPICKER" &&
                                                                      !datePickerRendered ? (
                                                                        <MonthYearPicker
                                                                        class_name="inputField mb-5"
                                                                        date_validation_type="YEARS"
                                                                        error_message={
                                                                          "Select existing since"
                                                                        }
                                                                      title={
                                                                          field_data_data?.title
                                                                        }
                                                                        value={
                                                                          field_data_data
                                                                            ?.value
                                                                            ?.value
                                                                        }
                                                                        attrName={[
                                                                          "mainMemberDataField",
                                                                          questionData
                                                                            ?.main_question
                                                                            ?.id,
                                                                          dataSubQuestion?.id,
                                                                          field_data_data.id,
                                                                          memberDataValue.keyName,
                                                                        ]}
                                                                        value_update={
                                                                          updateMasterState
                                                                        }
                                                                        min_date={
                                                                          memberDataValue.keyName ===
                                                                            "adult_one"
                                                                            ? INSURED_MEMBER_DETAILS
                                                                              .ADULT_ONE_DETAILS
                                                                              .dob
                                                                              .value
                                                                              ? calculateAge(
                                                                                `${INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value}`
                                                                              )
                                                                              : calculateAge(
                                                                                `${INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value}`
                                                                              )
                                                                            : memberDataValue.keyName ===
                                                                              "adult_two"
                                                                              ? calculateAge(
                                                                                `${INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.dob.value}`
                                                                              )
                                                                              : memberDataValue.keyName ===
                                                                                "child_one"
                                                                                ? calculateAge(
                                                                                  `${INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.dob.value}`
                                                                                )
                                                                                : memberDataValue.keyName ===
                                                                                  "child_two"
                                                                                  ? calculateAge(
                                                                                    `${INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.dob.value}`
                                                                                  )
                                                                                  : memberDataValue.keyName ===
                                                                                    "child_three"
                                                                                    ? calculateAge(
                                                                                      `${INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.dob.value}`
                                                                                    )
                                                                                    : memberDataValue.keyName ===
                                                                                      "child_four"
                                                                                      ? calculateAge(
                                                                                        `${INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.dob.value}`
                                                                                      )
                                                                                      : 25
                                                                        }
                                                                        max_date={
                                                                          0
                                                                        }
                                                                        default_date={subDays(
                                                                          new Date(),
                                                                          memberDataValue.keyName ===
                                                                            "adult_one"
                                                                            ? INSURED_MEMBER_DETAILS
                                                                              .ADULT_ONE_DETAILS
                                                                              .dob
                                                                              .value
                                                                              ? calculateAge(
                                                                                `${INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value}`
                                                                              )
                                                                              : calculateAge(
                                                                                `${INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value}`
                                                                              )
                                                                            : memberDataValue.keyName ===
                                                                              "adult_two"
                                                                              ? calculateAge(
                                                                                `${INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.dob.value}`
                                                                              )
                                                                              : memberDataValue.keyName ===
                                                                                "child_one"
                                                                                ? calculateAge(
                                                                                  `${INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.dob.value}`
                                                                                )
                                                                                : memberDataValue.keyName ===
                                                                                  "child_two"
                                                                                  ? calculateAge(
                                                                                    `${INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.dob.value}`
                                                                                  )
                                                                                  : memberDataValue.keyName ===
                                                                                    "child_three"
                                                                                    ? calculateAge(
                                                                                      `${INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.dob.value}`
                                                                                    )
                                                                                    : memberDataValue.keyName ===
                                                                                      "child_four"
                                                                                      ? calculateAge(
                                                                                        `${INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.dob.value}`
                                                                                      )
                                                                                      : 25
                                                                        )}
                                                                        warn_status={
                                                                          field_data_data
                                                                            ?.value
                                                                            ?.warning
                                                                        }
                                                                      />
                                                                      // <DatePicker
                                                                      //   class_name="inputField mb-4 mt-3"
                                                                      //   title={
                                                                      //     field_data_data?.title
                                                                      //   }
                                                                      //   value={
                                                                      //     field_data_data
                                                                      //       ?.value
                                                                      //       ?.value
                                                                      //   }
                                                                      //   attrName={[
                                                                      //     "mainMemberDataField",
                                                                      //     questionData
                                                                      //       ?.main_question
                                                                      //       ?.id,
                                                                      //     dataSubQuestion?.id,
                                                                      //     field_data_data.id,
                                                                      //     memberDataValue.keyName,
                                                                      //   ]}
                                                                      //   value_update={
                                                                      //     updateMasterState
                                                                      //   }
                                                                      //   min_date={
                                                                      //     memberDataValue.keyName ===
                                                                      //       "adult_one"
                                                                      //       ? INSURED_MEMBER_DETAILS
                                                                      //         .ADULT_ONE_DETAILS
                                                                      //         .dob
                                                                      //         .value
                                                                      //         ? calculateAgeInDays(
                                                                      //           `${INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value}`
                                                                      //         )
                                                                      //         : calculateAgeInDays(
                                                                      //           `${INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value}`
                                                                      //         )
                                                                      //       : memberDataValue.keyName ===
                                                                      //         "adult_two"
                                                                      //         ? calculateAgeInDays(
                                                                      //           `${INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.dob.value}`
                                                                      //         )
                                                                      //         : memberDataValue.keyName ===
                                                                      //           "child_one"
                                                                      //           ? calculateAgeInDays(
                                                                      //             `${INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.dob.value}`
                                                                      //           )
                                                                      //           : memberDataValue.keyName ===
                                                                      //             "child_two"
                                                                      //             ? calculateAgeInDays(
                                                                      //               `${INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.dob.value}`
                                                                      //             )
                                                                      //             : memberDataValue.keyName ===
                                                                      //               "child_three"
                                                                      //               ? calculateAgeInDays(
                                                                      //                 `${INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.dob.value}`
                                                                      //               )
                                                                      //               : memberDataValue.keyName ===
                                                                      //                 "child_four"
                                                                      //                 ? calculateAgeInDays(
                                                                      //                   `${INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.dob.value}`
                                                                      //                 )
                                                                      //                 : 25
                                                                      //   }
                                                                      //   max_date={
                                                                      //     0
                                                                      //   }
                                                                      //   default_date={subDays(
                                                                      //     new Date(),
                                                                      //     memberDataValue.keyName ===
                                                                      //       "adult_one"
                                                                      //       ? INSURED_MEMBER_DETAILS
                                                                      //         .ADULT_ONE_DETAILS
                                                                      //         .dob
                                                                      //         .value
                                                                      //         ? calculateAgeInDays(
                                                                      //           `${INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value}`
                                                                      //         )
                                                                      //         : calculateAgeInDays(
                                                                      //           `${INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value}`
                                                                      //         )
                                                                      //       : memberDataValue.keyName ===
                                                                      //         "adult_two"
                                                                      //         ? calculateAgeInDays(
                                                                      //           `${INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.dob.value}`
                                                                      //         )
                                                                      //         : memberDataValue.keyName ===
                                                                      //           "child_one"
                                                                      //           ? calculateAgeInDays(
                                                                      //             `${INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.dob.value}`
                                                                      //           )
                                                                      //           : memberDataValue.keyName ===
                                                                      //             "child_two"
                                                                      //             ? calculateAgeInDays(
                                                                      //               `${INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.dob.value}`
                                                                      //             )
                                                                      //             : memberDataValue.keyName ===
                                                                      //               "child_three"
                                                                      //               ? calculateAgeInDays(
                                                                      //                 `${INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.dob.value}`
                                                                      //               )
                                                                      //               : memberDataValue.keyName ===
                                                                      //                 "child_four"
                                                                      //                 ? calculateAgeInDays(
                                                                      //                   `${INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.dob.value}`
                                                                      //                 )
                                                                      //                 : 25
                                                                      //   )}
                                                                      //   warn_status={
                                                                      //     field_data_data
                                                                      //       ?.value
                                                                      //       ?.warning
                                                                      //   }
                                                                      //   date_validation_type="DAYS"
                                                                      //   error_message={
                                                                      //     "Select exist. since"
                                                                      //   }
                                                                      // />
                                                                    ) : (
                                                                      field_data_data.field_type ===
                                                                      "TEXTFIELD" && (
                                                                        <RKTextField
                                                                          class_name="mb-4 mt-3"
                                                                          title={
                                                                            field_data_data?.title
                                                                          }
                                                                          attrName={[
                                                                            "mainMemberDataField",
                                                                            questionData
                                                                              ?.main_question
                                                                              ?.id,
                                                                            dataSubQuestion?.id,
                                                                            field_data_data.id,
                                                                            memberDataValue.keyName,
                                                                            "desc",
                                                                          ]}
                                                                          value={
                                                                            field_data_data
                                                                              ?.value
                                                                              ?.value
                                                                          }
                                                                          value_update={
                                                                            updateMasterState
                                                                          }
                                                                          warn_status={
                                                                            field_data_data
                                                                              ?.value
                                                                              ?.warning
                                                                          }
                                                                          error_message={
                                                                            "Enter desc"
                                                                          }
                                                                        />
                                                                      )
                                                                    )}
                                                                    {field_data_data.field_type ===
                                                                      "DATEPICKER" &&
                                                                      !datePickerRendered &&
                                                                      (datePickerRendered =
                                                                        true)}
                                                                  </React.Fragment>
                                                                );
                                                              }
                                                            )}
                                                          </>
                                                        )}
                                                    </form>
                                                  </>
                                                ) : null}
                                              </Box>
                                            </Grid>
                                          );
                                        }
                                      )}
                                  </Grid>
                                </Collapse>
                              }
                            />
                          );
                        }
                      )}
                  </Box>
                </Collapse>
              }
            />
          )
        )}
        <MFooter
          textName={PAGE_STATUS ? "Update" : "Continue"}
          forward={() => validateForm()}
        />
      </Box>
    </>
  );
};

export default MMedicalDetails;
