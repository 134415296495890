import { NODE_DOMAIN, PHP_DOMAIN } from "../Domain";

export const CommonURLs = {
  GENERATE_TOKEN: `${PHP_DOMAIN}/api/users/generate-token.json`,

  //Premium Confiramtion
  PREMIUM_CONFIRMATION_NODE: `${NODE_DOMAIN}/api/v1/health/payment`,
  PREMIUM_CONFIRMATION: `${PHP_DOMAIN}/api/policies/payment-confirmation.json`,
  DOWNLOAD_POLICY: `${NODE_DOMAIN}/api/v1/email/storage-component`,
};
