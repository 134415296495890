import { GetAPI, PostAPI } from "../../Axios/AxiosInstanceToken";
import { TERM_FULL_JOURNEY_API_COLLECTION } from "../../URLCollection/TermFullJourney/TermFullJourney";

const ckyc = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    TERM_FULL_JOURNEY_API_COLLECTION.HDFC_CKYC,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const term_application_submission = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    TERM_FULL_JOURNEY_API_COLLECTION.HDFC_TERM_APP_SUBMISSION,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const get_term_requirement = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    TERM_FULL_JOURNEY_API_COLLECTION.HDFC_TERM_GET_REQUIREMENT,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const get_dropdown_master = (
  onSuccess: Function,
  onError: Function,
  under_writing_code: any
) => {
  PostAPI.call(
    TERM_FULL_JOURNEY_API_COLLECTION.HDFC_TERM_GET_REQUIREMENT,
    under_writing_code,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const uploda_doc_image = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    TERM_FULL_JOURNEY_API_COLLECTION.HDFC_UPLOAD_DOCUMENT,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

export const HDFC_PROPOSAL_PAGE_SERVICES = {
  ckyc,
  term_application_submission,
  get_term_requirement,
  get_dropdown_master,
  uploda_doc_image,
};
