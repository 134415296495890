import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Medicaldetailsoptimarestore from "../../../Page/Desktop/health-php/HDFC/Medicaldetailsoptimarestore";
import MMedicaldetailsoptimarestore from "../../../Page/Mobile/health-php/HDFC/MMedicaldetailsoptimarestore";
import { HEALTH_ROUTE } from "../../../Router/Path/HealthRoutes";
import { HEALTH_PROPOSAL_SERVICES } from "../../../Services/health-php/HealthPropsalServices";
import { HealthSlice } from "../../../Store/Slice_Reducer/Health/HealthSlice";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import { child_gender } from "../../../SupportingFiles/HealthHelper";
import {
  calculateAge,
  isEmpty,
} from "../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { TCareMedicalQuestion } from "../../../types/Health/HealthQuotation/TCareMedicalQuestion";
import { THealthQuote } from "../../../types/Health/HealthQuotation/THealthQuote";

export default function MedicalDetailsContainerOptimarestore() {
  const isMobile = useIsMobile();
  const {
    INSURED_MEMBER_DETAILS,
    PAGE_STATUS,
    MEDICAL_QUESTION_DATA_OPTIMA_RESTORE,
    SELECTED_QUOTE_DATA,
    ADD_FORM_RESPONSE,
    ADD_FORM,
  } = useAppSelector((state) => state.Health);
  const [medicalQuestionData, setMedicalQuestionData] =
    useState<TCareMedicalQuestion>(MEDICAL_QUESTION_DATA_OPTIMA_RESTORE);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  function deepCopy(obj: any) {
    return JSON.parse(JSON.stringify(obj));
  }
  const updateMemberNames = (
    data: TCareMedicalQuestion,
    memberDetails: any
  ) => {
    // Create a deep copy of the data object to ensure immutability
    const updatedData = deepCopy(data);

    // Update the main question member names
    Object.values(updatedData).forEach((question: any) => {
      Object.values(question.main_question.member_data).forEach(
        (member: any) => {
          const memberKeyName = member.keyName.toUpperCase() + "_DETAILS";
          if (
            memberDetails[memberKeyName] &&
            memberDetails[memberKeyName].name &&
            memberDetails[memberKeyName].name.value
          ) {
            // Update the name property of the member in the new object
            member.name = memberDetails[memberKeyName].name.value;
          } else {
            member.name = "";
          }
        }
      );
    });

    // Update the sub question member names
    Object.values(updatedData).forEach((question: any) => {
      question.main_question.sub_question &&
        Object.values(question.main_question.sub_question).forEach(
          (sub_data: any) => {
            sub_data.member_data &&
              Object.values(sub_data.member_data).forEach((member: any) => {
                const memberKeyName = member.keyName.toUpperCase() + "_DETAILS";
                if (
                  memberDetails[memberKeyName] &&
                  memberDetails[memberKeyName].name &&
                  memberDetails[memberKeyName].name.value
                ) {
                  // Update the name property of the member in the new object
                  member.name = memberDetails[memberKeyName].name.value;
                } else {
                  member.name = "";
                }
              });
          }
        );
    });

    return updatedData; // Return the updated data
  };

  useEffect(() => {
    if (PAGE_STATUS) {
      setMedicalQuestionData(MEDICAL_QUESTION_DATA_OPTIMA_RESTORE);
    } else {
      const updatedData = updateMemberNames(
        MEDICAL_QUESTION_DATA_OPTIMA_RESTORE,
        INSURED_MEMBER_DETAILS
      );
      setMedicalQuestionData(updatedData);
    }
  }, []);
  const updateMasterState = (attrName: any, value: any) => {
    console.log(attrName, "attrName", value);
    let manipulatedMedicalQuestion: any = JSON.parse(
      JSON.stringify(medicalQuestionData)
    );
    if (attrName[0] === "question") {
      manipulatedMedicalQuestion = {
        ...manipulatedMedicalQuestion,
        [attrName[1]]: {
          ...manipulatedMedicalQuestion[attrName[1]],
          main_question: {
            ...manipulatedMedicalQuestion[attrName[1]].main_question,
            status: value,
          },
        },
      };
      let updatedSubQuestion = {
        ...manipulatedMedicalQuestion[attrName[1]].main_question.member_data,
      };
      if (value === false) {
        if (attrName[1] === "MQ01") {
          Object.values(updatedSubQuestion).forEach((member: any) => {
            member.selection_status = false;
            member.field_data["MQ1-SQ1-F1"].value = {
              value: "",
              warning: false,
            };
            member.field_data["MQ1-SQ1-F2"].value = {
              value: "",
              warning: false,
            };
          });
        }
      }
      if (attrName[1] === "MQ02") {
        Object.values(updatedSubQuestion).forEach((member: any) => {
          member.selection_status = false;
          member.field_data["MQ1-SQ1-F1"].value = {
            value: "",
            warning: false,
          };
        });
      }
      if (attrName[1] === "MQ03") {
        Object.values(updatedSubQuestion).forEach((member: any) => {
          member.selection_status = false;
          member.field_data["MQ1-SQ1-F1"].value = {
            value: "",
            warning: false,
          };
          member.field_data["MQ1-SQ1-F2"].value = {
            value: "",
            warning: false,
          };
          member.field_data["MQ1-SQ1-F3"].value = {
            value: "",
            warning: false,
          };
          member.field_data["MQ1-SQ1-F4"].value = {
            value: "",
            warning: false,
          };
        });
      }

      let updatedMainQuestion = {
        ...manipulatedMedicalQuestion[attrName[1]].main_question,
        member_data: updatedSubQuestion,
      };

      let updatedManipulatedMedicalQuestion = {
        ...manipulatedMedicalQuestion,
        [attrName[1]]: {
          ...manipulatedMedicalQuestion[attrName[1]],
          main_question: updatedMainQuestion,
        },
      };

      setMedicalQuestionData(updatedManipulatedMedicalQuestion);
    } else if (attrName[0] === "subQuestion") {
      let updatedSubQuestion = {
        ...manipulatedMedicalQuestion[attrName[1]].main_question.sub_question,
        [attrName[2]]: {
          ...manipulatedMedicalQuestion[attrName[1]].main_question.sub_question[
            attrName[2]
          ],
          status: value,
        },
      };

      if (value === false) {
        // Check if the attribute name is neither "MQ15-SQ15" nor "MQ16-SQ16"
        if (attrName[2]) {
          Object.values(updatedSubQuestion[attrName[2]].member_data).forEach(
            (member: any) => {
              member.selection_status = false;
            }
          );
        }
        // If attribute name is "MQ15-SQ15" or "MQ16-SQ16", update specific fields for all members
      }

      let updatedMainQuestion = {
        ...manipulatedMedicalQuestion[attrName[1]].main_question,
        sub_question: updatedSubQuestion,
      };

      let updatedManipulatedMedicalQuestion = {
        ...manipulatedMedicalQuestion,
        [attrName[1]]: {
          ...manipulatedMedicalQuestion[attrName[1]],
          main_question: updatedMainQuestion,
        },
      };

      setMedicalQuestionData(updatedManipulatedMedicalQuestion);
    } else if (attrName[0] === "memberData") {
      if (
        manipulatedMedicalQuestion[attrName[1]]?.main_question.sub_question[
          attrName[2]
        ]?.member_data[attrName[3]] !== undefined
      ) {
        manipulatedMedicalQuestion = {
          ...manipulatedMedicalQuestion,
          [attrName[1]]: {
            ...manipulatedMedicalQuestion[attrName[1]],
            main_question: {
              ...manipulatedMedicalQuestion[attrName[1]]?.main_question,
              sub_question: {
                ...manipulatedMedicalQuestion[attrName[1]].main_question
                  .sub_question,
                [attrName[2]]: {
                  ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                    .sub_question?.[attrName[2]],
                  member_data: {
                    ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                      .sub_question?.[attrName[2]].member_data,
                    [attrName[3]]: {
                      ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                        .sub_question?.[attrName[2]].member_data?.[attrName[3]],
                      selection_status: value,
                    },
                  },
                  // field_data: {
                  //   ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                  //     .sub_question?.[attrName[2]]?.field_data,
                  //   value: { value: "", warning: false },
                  // },
                },
              },
            },
          },
        };
        setMedicalQuestionData({ ...manipulatedMedicalQuestion });
      }
    } else if (attrName[0] === "mainMemberData") {
      if (
        manipulatedMedicalQuestion[attrName[1]]?.main_question?.member_data[
          attrName[2]
        ] !== undefined
      )
        manipulatedMedicalQuestion = {
          ...manipulatedMedicalQuestion,
          [attrName[1]]: {
            ...manipulatedMedicalQuestion[attrName[1]],
            main_question: {
              ...manipulatedMedicalQuestion[attrName[1]]?.main_question,
              member_data: {
                ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                  .member_data,
                [attrName[2]]: {
                  ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                    ?.member_data?.[attrName[2]],
                  selection_status: value,
                },
              },
            },
          },
        };

      setMedicalQuestionData(manipulatedMedicalQuestion);
    } else if (attrName[0] === "mainMemberDataField") {
      if (
        attrName[2] === "adult_one" ||
        attrName[2] === "adult_two" ||
        attrName[2] === "child_one" ||
        attrName[2] === "child_two" ||
        attrName[2] === "child_three" ||
        (attrName[2] === "child_four" &&
          manipulatedMedicalQuestion !== undefined)
      ) {
        manipulatedMedicalQuestion[attrName[1]].main_question.member_data[
          attrName[2]
        ].field_data[attrName[3]].value = {
          value: value,
          warning: isEmpty(value),
        };
      }
      setMedicalQuestionData({ ...manipulatedMedicalQuestion });
    }
  };
  const validateForm = () => {
    // Ensure medicalQuestionData is not null or undefined
    if (!medicalQuestionData) {
      return; // Exit early if medicalQuestionData is null or undefined
    }
    // Deep copy the medicalQuestionData object to avoid state mutations
    let data: TCareMedicalQuestion = JSON.parse(
      JSON.stringify(medicalQuestionData)
    );
    let hasError = false;

    Object.values(data).forEach((element: any) => {
      const mainQuestion = element?.main_question;
      if (mainQuestion) {
        Object.values(mainQuestion.member_data).forEach((member_data: any) => {
          <>{console.log(member_data, "member_data")}</>;
          if (member_data) {
            if (
              member_data &&
              member_data.selection_status &&
              member_data.field_data
            ) {
              Object.values(member_data.field_data).forEach(
                (field_data: any) => {
                  if (field_data && field_data.value?.value === "") {
                    let clonedValue = {
                      ...field_data.value,
                      warning: true,
                    };

                    if (field_data.value) {
                      field_data.value = clonedValue;
                    }

                    //     }
                    hasError = true;
                  }
                }
              );
            }
          }
        });
      }
    });

    setMedicalQuestionData(data);
    if (!hasError) {
      dispatch(
        HealthSlice.actions.SAVE_MEDICAL_QUESTION_DATA_HDFC_OPTIMA_RESTORE(data)
      );

      //api calling
      const onSuccess = (res: any) => {
        if (res.results.error === false) {
          let quoteData: THealthQuote = SELECTED_QUOTE_DATA;
          quoteData = {
            ...quoteData,
            premiumDetails: {
              ...quoteData.premiumDetails,
              finalPremium: res.results.response.premiumDetails.finalPremium,
            },
          };
          dispatch(HealthSlice.actions.SAVE_SELECTED_QUOTE_DATA(quoteData));
        }
      };
      const onError = (err: any) => {
        console.log("err..", err);
      };

      let param: any = {
        company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
        quote_no: ADD_FORM_RESPONSE.quote_no,
        product_code: SELECTED_QUOTE_DATA.productDetails.product_code,
        product_type_code: SELECTED_QUOTE_DATA.productDetails.product_type_code,
        more_details: [
          {
            adult1Relation: "self",
            adult1Age: calculateAge(ADD_FORM.self_dob.value),
            adult1Gender: ADD_FORM?.gender?.value || "",
          },
          {
            adult2Relation: ADD_FORM?.gender.value === "M" ? "wife" : "husband",
            adult2Age: calculateAge(ADD_FORM.spouse_dob.value),
            adult2Gender: ADD_FORM?.gender?.value || "",
          },
          {
            childrelation_1:
              child_gender("1", ADD_FORM) === "M" ? "son" : "daughter",
            childage_1: ADD_FORM?.child_one_dob?.value || "",
            childgender_1: child_gender("1", ADD_FORM),
          },
          {
            childrelation_2:
              child_gender("2", ADD_FORM) === "M" ? "son" : "daughter",
            childage_2: ADD_FORM?.child_two_dob?.value || "",
            childgender_2: child_gender("2", ADD_FORM),
          },
          {
            childrelation_3:
              child_gender("3", ADD_FORM) === "M" ? "son" : "daughter",
            childage_3: ADD_FORM?.child_three_dob?.value || "",
            childgender_3: child_gender("3", ADD_FORM),
          },
          {
            childrelation_4:
              child_gender("4", ADD_FORM) === "M" ? "son" : "daughter",
            childage_4: ADD_FORM?.child_four_dob?.value || "",
            childgender_4: child_gender("4", ADD_FORM),
          },
        ],
        medicalSmokingDiseases:
          data?.MQ01?.main_question?.status === true ? "Yes" : "No", // Does any of the members Smoke?
        medicalSmoking:
          data?.MQ01?.main_question?.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "No",
        smoking_month:
          data?.MQ01?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        smokingDuration:
          data?.MQ01?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,
        spouse_medicalSmoking:
          data?.MQ01?.main_question?.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "No",
        spouse_smoking_month:
          data?.MQ01?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        spouse_smokingDuration:
          data?.MQ01?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,
        medicalTobaccoDiseases:
          data?.MQ02?.main_question?.status === true ? "Yes" : "No", // Do you Chew Pan Masala?
        medicalTobacco:
          data?.MQ02?.main_question?.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "No",
        tobacco_month:
          data?.MQ02?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        spouse_medicalTobacco:
          data?.MQ02?.main_question?.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "No",
        spouse_tobacco_month:
          data?.MQ02?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,
        medicalAlcoholDiseases:
          data?.MQ03?.main_question?.status === true ? "Yes" : "No",
        medicalAlcohol:
          data?.MQ03?.main_question?.member_data?.adult_one.selection_status ===
          true
            ? "Yes"
            : "No",
        alcohol_month:
          data?.MQ03?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        alcohol_year:
          data?.MQ03?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,
        alcohol_desc:
          data?.MQ03?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F3"
          ]?.value?.value,
        alcohol_days:
          data?.MQ03?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F4"
          ]?.value?.value,
        spouse_medicalAlcohol:
          data?.MQ03?.main_question?.member_data?.adult_two.selection_status ===
          true
            ? "Yes"
            : "No", // Do you drink Alcohol (per week)?
        spouse_alcohol_month:
          data?.MQ03?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value,
        spouse_alcohol_year:
          data?.MQ03?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value,
        spouse_alcohol_desc:
          data?.MQ03?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F3"
          ]?.value?.value,
        spouse_alcohol_days:
          data?.MQ03?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F4"
          ]?.value?.value,
        medicalPreExistingDiseases:
          data?.MQ04?.main_question?.status === true ? "Yes" : "No", // Does any person(s) to be insured has any Pre-Existing diseases?
        medicalDiabetesDiseases:
          data?.MQ04?.main_question?.sub_question?.["MQ1-SQ1"]?.status === true
            ? "Yes"
            : "No", // Diabetes
        medicalDiabetes:
          data?.MQ04?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.adult_one?.selection_status === true
            ? "Yes"
            : "No",
        spouse_medicalDiabetes:
          data?.MQ04?.main_question?.sub_question?.sub_question?.member_data
            ?.adult_two?.selection_status === true
            ? "Yes"
            : "No",
        child1_medicalDiabetes:
          data?.MQ04?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.child_one?.selection_status === true
            ? "Yes"
            : "No",
        child2_medicalDiabetes:
          data?.MQ04?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.child_two?.selection_status === true
            ? "Yes"
            : "No",
        child3_medicalDiabetes:
          data?.MQ04?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.child_three?.selection_status === true
            ? "Yes"
            : "No",
        child4_medicalDiabetes:
          data?.MQ04?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.child_four?.selection_status === true
            ? "Yes"
            : "No",
        medicalCancerDiseases:
          data?.MQ04?.main_question?.sub_question?.["MQ2-SQ2"]?.status === true
            ? "Yes"
            : "No", // Tumour or Cancer?
        medicalCancer:
          data?.MQ04?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.adult_one?.selection_status === true
            ? "Yes"
            : "No",
        spouse_medicalCancer:
          data?.MQ04?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.adult_two?.selection_status === true
            ? "Yes"
            : "No",
        child1_medicalCancer:
          data?.MQ04?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.child_one?.selection_status === true
            ? "Yes"
            : "No",
        child2_medicalCancer:
          data?.MQ04?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.child_two?.selection_status === true
            ? "Yes"
            : "No",
        child3_medicalCancer:
          data?.MQ04?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.child_three?.selection_status === true
            ? "Yes"
            : "No",
        child4_medicalCancer:
          data?.MQ04?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.child_four?.selection_status === true
            ? "Yes"
            : "No",
        medicalCardiacDiseases:
          data?.MQ04?.main_question?.sub_question?.["MQ3-SQ3"]?.status === true
            ? "Yes"
            : "No", // Heart & Circulatory disorders?
        medicalCardiac:
          data?.MQ04?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.adult_one?.selection_status === true
            ? "Yes"
            : "No",
        spouse_medicalCardiac:
          data?.MQ04?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.adult_two?.selection_status === true
            ? "Yes"
            : "No",
        child1_medicalCardiac:
          data?.MQ04?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.child_one?.selection_status === true
            ? "Yes"
            : "No",
        child2_medicalCardiac:
          data?.MQ04?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.child_two?.selection_status === true
            ? "Yes"
            : "No",
        child3_medicalCardiac:
          data?.MQ04?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.child_three?.selection_status === true
            ? "Yes"
            : "No",
        child4_medicalCardiac:
          data?.MQ04?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.child_four?.selection_status === true
            ? "Yes"
            : "No",
        medicalJointDiseases:
          data?.MQ04?.main_question?.sub_question?.["MQ4-SQ4"]?.status === true
            ? "Yes"
            : "No", // Disorder of Spine and Joints?
        medicalJoint:
          data?.MQ04?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
            ?.adult_one?.selection_status === true
            ? "Yes"
            : "No",
        spouse_medicalJoint:
          data?.MQ04?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
            ?.adult_two?.selection_status === true
            ? "Yes"
            : "No",
        child1_medicalJoint:
          data?.MQ04?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
            ?.child_one?.selection_status === true
            ? "Yes"
            : "No",
        child2_medicalJoint:
          data?.MQ04?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
            ?.child_two?.selection_status === true
            ? "Yes"
            : "No",
        child3_medicalJoint:
          data?.MQ04?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
            ?.child_three?.selection_status === true
            ? "Yes"
            : "No",
        child4_medicalJoint:
          data?.MQ04?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
            ?.child_four?.selection_status === true
            ? "Yes"
            : "No",
        medicalKidneyDiseases:
          data?.MQ04?.main_question?.sub_question?.["MQ5-SQ5"]?.status === true
            ? "Yes"
            : "No", // Disorders of the stomach including intestine, Kidney, Prostate?
        medicalKidney:
          data?.MQ04?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
            ?.adult_one?.selection_status === true
            ? "Yes"
            : "No",
        spouse_medicalKidney:
          data?.MQ04?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
            ?.adult_two?.selection_status === true
            ? "Yes"
            : "No",
        child1_medicalKidney:
          data?.MQ04?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
            ?.child_one?.selection_status === true
            ? "Yes"
            : "No",
        child2_medicalKidney:
          data?.MQ04?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
            ?.child_two?.selection_status === true
            ? "Yes"
            : "No",
        child3_medicalKidney:
          data?.MQ04?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
            ?.child_three?.selection_status === true
            ? "Yes"
            : "No",
        child4_medicalKidney:
          data?.MQ04?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
            ?.child_four?.selection_status === true
            ? "Yes"
            : "No",
        medicalParalysisDiseases:
          data?.MQ04?.main_question?.sub_question?.["MQ6-SQ6"]?.status === true
            ? "Yes"
            : "No", // Nervous disorder, fits, mental condition ?
        medicalParalysis:
          data?.MQ04?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
            ?.adult_one?.selection_status === true
            ? "Yes"
            : "No",
        spouse_medicalParalysis:
          data?.MQ04?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
            ?.adult_two?.selection_status === true
            ? "Yes"
            : "No",
        child1_medicalParalysis:
          data?.MQ04?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
            ?.child_one?.selection_status === true
            ? "Yes"
            : "No",
        child2_medicalParalysis:
          data?.MQ04?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
            ?.child_two?.selection_status === true
            ? "Yes"
            : "No",
        child3_medicalParalysis:
          data?.MQ04?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
            ?.child_three?.selection_status === true
            ? "Yes"
            : "No",
        child4_medicalParalysis:
          data?.MQ04?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
            ?.child_four?.selection_status === true
            ? "Yes"
            : "No",
        medicalRespiratoryDiseases:
          data?.MQ04?.main_question?.sub_question?.["MQ7-SQ7"]?.status === true
            ? "Yes"
            : "No", // Respiratory disorder?
        medicalRespiratory:
          data?.MQ04?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
            ?.adult_one?.selection_status === true
            ? "Yes"
            : "No",
        spouse_medicalRespiratory:
          data?.MQ04?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
            ?.adult_two?.selection_status === true
            ? "Yes"
            : "No",
        child1_medicalRespiratory:
          data?.MQ04?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
            ?.child_one?.selection_status === true
            ? "Yes"
            : "No",
        child2_medicalRespiratory:
          data?.MQ04?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
            ?.child_two?.selection_status === true
            ? "Yes"
            : "No",
        child3_medicalRespiratory:
          data?.MQ04?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
            ?.child_three?.selection_status === true
            ? "Yes"
            : "No",
        child4_medicalRespiratory:
          data?.MQ04?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
            ?.child_four?.selection_status === true
            ? "Yes"
            : "No",
        medicalEndocrineDiseases:
          data?.MQ04?.main_question?.sub_question?.["MQ8-SQ8"]?.status === true
            ? "Yes"
            : "No", // Thyroid Disorder
        medicalEndocrine:
          data?.MQ04?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
            ?.adult_one?.selection_status === true
            ? "Yes"
            : "No",
        spouse_medicalEndocrine:
          data?.MQ04?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
            ?.adult_two?.selection_status === true
            ? "Yes"
            : "No",
        child1_medicalEndocrine:
          data?.MQ04?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
            ?.child_one?.selection_status === true
            ? "Yes"
            : "No",
        child2_medicalEndocrine:
          data?.MQ04?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
            ?.child_two?.selection_status === true
            ? "Yes"
            : "No",
        child3_medicalEndocrine:
          data?.MQ04?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
            ?.child_three?.selection_status === true
            ? "Yes"
            : "No",
        child4_medicalEndocrine:
          data?.MQ04?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
            ?.child_four?.selection_status === true
            ? "Yes"
            : "No",
        medicalMedicationDiseases:
          data?.MQ04?.main_question?.sub_question?.["MQ9-SQ9"]?.status === true
            ? "Yes"
            : "No", // Any ongoing diseases or ailment requiring surgical or medical treatment?
        medicalMedication:
          data?.MQ04?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
            ?.adult_one?.selection_status === true
            ? "Yes"
            : "No",
        spouse_medicalMedication:
          data?.MQ04?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
            ?.adult_two?.selection_status === true
            ? "Yes"
            : "No",
        child1_medicalMedication:
          data?.MQ04?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
            ?.child_one?.selection_status === true
            ? "Yes"
            : "No",
        child2_medicalMedication:
          data?.MQ04?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
            ?.child_two?.selection_status === true
            ? "Yes"
            : "No",
        child3_medicalMedication:
          data?.MQ04?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
            ?.child_three?.selection_status === true
            ? "Yes"
            : "No",
        child4_medicalMedication:
          data?.MQ04?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
            ?.child_four?.selection_status === true
            ? "Yes"
            : "No",
        medicalProlongedDiseases:
          data?.MQ04?.main_question?.sub_question?.["MQ10-SQ10"]?.status ===
          true
            ? "Yes"
            : "No", // Have you or any other member proposed to be insured under this policy sought medical  advice or undergone any treatment medical or surgical in past 5 years due to any of the diseases/conditions listed above or otherwise or attended follow up for any disease /condition / ailment/ injury / addiction (except for infrequent common illness for example fever, common cold, loose motions, cough and cold, headaches, acidity ?
        medicalProlonged:
          data?.MQ04?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
            ?.adult_one?.selection_status === true
            ? "Yes"
            : "No",
        spouse_medicalProlonged:
          data?.MQ04?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
            ?.adult_two?.selection_status === true
            ? "Yes"
            : "No",
        child1_medicalProlonged:
          data?.MQ04?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
            ?.child_one?.selection_status === true
            ? "Yes"
            : "No",
        child2_medicalProlonged:
          data?.MQ04?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
            ?.child_two?.selection_status === true
            ? "Yes"
            : "No",
        child3_medicalProlonged:
          data?.MQ04?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
            ?.child_three?.selection_status === true
            ? "Yes"
            : "No",
        child4_medicalProlonged:
          data?.MQ04?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
            ?.child_four?.selection_status === true
            ? "Yes"
            : "No",
        // last_consultation_date: "No",
        medical_info: "Yes",
      };
      const windowURL = window.location.search;
      const params = new URLSearchParams(windowURL);
      const cus_type = params.get("cus_type");

      HEALTH_PROPOSAL_SERVICES.getMedicalData(onSuccess, onError, param);
      navigate(
        `${HEALTH_ROUTE.PROPOSAL_PAGE}/${
          HEALTH_ROUTE.PREVIEW_DETAILS
        }?type=HP&companyCode=${
          SELECTED_QUOTE_DATA?.CompanyDetails.company_code
        }&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${
          SELECTED_QUOTE_DATA?.productDetails.id
        }${cus_type === "user" ? "&cus_type=user" : ""}`
      );
    }
  };

  return isMobile ? (
    <MMedicaldetailsoptimarestore
      medicalQuestionData={medicalQuestionData}
      updateMasterState={updateMasterState}
      validateForm={validateForm}
      PAGE_STATUS={PAGE_STATUS}
    />
  ) : (
    <Medicaldetailsoptimarestore
      medicalQuestionData={medicalQuestionData}
      updateMasterState={updateMasterState}
      validateForm={validateForm}
      PAGE_STATUS={PAGE_STATUS}
    />
  );
}
