import { TCareMedicalQuestion } from "../../../../types/Health/HealthQuotation/TCareMedicalQuestion";

export const CARE_QUESTION_INITIAL_VALUE = (): any => {
  return {
    MQ01: {
      main_question: {
        id: "MQ01",
        icon_class: "tobaco",
        title: "Do you have any pre existing Disease?",
        description: "",
        status: false,
        sub_question: {
          "MQ1-SQ1": {
            id: "MQ1-SQ1",
            icon_class: "",
            status: false,
            title: "Liver Disease?",
            description: "Liver Disease?",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "adult",
                selection_status: false,
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "adult",
                selection_status: false,
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "child",
                selection_status: false,
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "child",
                selection_status: false,
              },
              child_three: {
                keyName: "child_three",
                name: "",
                image_class: "child",
                selection_status: false,
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "child",
                selection_status: false,
              },
            },
          },
          "MQ2-SQ2": {
            id: "MQ2-SQ2",
            icon_class: "",
            status: false,
            title: "",
            description: "Cancer / Tumor?",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "adult",
                selection_status: false,
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "adult",
                selection_status: false,
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "child",
                selection_status: false,
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "child",
                selection_status: false,
              },
              child_three: {
                keyName: "child_three",
                name: "",
                image_class: "child",
                selection_status: false,
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "child",
                selection_status: false,
              },
            },
            // field_data: {
            //   "MQ1-SQ1-F2": {
            //     id: "MQ1-SQ1-F2",
            //     title: "Existing Since",
            //     field_type: "TEXTFIELD",
            //     value: { value: "", warning: false },
            //   },
            // },
          },
          "MQ3-SQ3": {
            id: "MQ3-SQ3",
            icon_class: "",
            status: false,
            title: "",
            description: "Coronary Artery Heart Disease ?",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "adult",
                selection_status: false,
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "adult",
                selection_status: false,
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "child",
                selection_status: false,
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "child",
                selection_status: false,
              },
              child_three: {
                keyName: "child_three",
                name: "",
                image_class: "child",
                selection_status: false,
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "child",
                selection_status: false,
              },
            },
            // field_data: {
            //   "MQ1-SQ1-F3": {
            //     id: "MQ1-SQ1-F3",
            //     field_type: "TEXTFIELD",
            //     title: "Existing Since",
            //     value: { value: "", warning: false },
            //   },
            // },
          },
          "MQ4-SQ4": {
            id: "MQ4-SQ4",
            icon_class: "",
            status: false,
            title: "",
            description: "Kidney Disease?",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "adult",
                selection_status: false,
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "adult",
                selection_status: false,
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "child",
                selection_status: false,
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "child",
                selection_status: false,
              },
              child_three: {
                keyName: "child_three",
                name: "",
                image_class: "child",
                selection_status: false,
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "child",
                selection_status: false,
              },
            },
            // field_data: {
            //   "MQ1-SQ1-F4": {
            //     id: "MQ1-SQ1-F4",
            //     title: "Existing Since",
            //     field_type: "TEXTFIELD",
            //     value: { value: "", warning: false },
            //   },
            // },
          },
          "MQ5-SQ5": {
            id: "MQ5-SQ5",
            icon_class: "",
            status: false,
            title: "",
            description: "Paralysis / Stroke?",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "adult",
                selection_status: false,
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "adult",
                selection_status: false,
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "child",
                selection_status: false,
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "child",
                selection_status: false,
              },
              child_three: {
                keyName: "child_three",
                name: "",
                image_class: "child",
                selection_status: false,
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "child",
                selection_status: false,
              },
            },
            // field_data: {
            //   "MQ1-SQ1-F5": {
            //     id: "MQ1-SQ1-F5",
            //     title: "Existing Since",
            //     field_type: "TEXTFIELD",
            //     value: { value: "", warning: false },
            //   },
            // },
          },
          "MQ6-SQ6": {
            id: "MQ6-SQ6",
            icon_class: "",
            status: false,
            title: "",
            description: "Any other diseases or ailments not mentioned above?*",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Existing Since",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "adult",
                selection_status: false,
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Existing Since",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "child",
                selection_status: false,
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Existing Since",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "child",
                selection_status: false,
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Existing Since",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
              child_three: {
                keyName: "child_three",
                name: "",
                image_class: "child",
                selection_status: false,
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "child",
                selection_status: false,
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            // field_data: {
            //   "MQ1-SQ1-F6": {
            //     id: "MQ1-SQ1-F6",
            //     field_type: "TEXTFIELD",
            //     title: "Existing Since",
            //     value: { value: "", warning: false },
            //   },
            // },
          },
        },
      },
    },
    MQ02: {
      main_question: {
        id: "MQ02",
        icon_class: "tobaco",
        title: "Have you ever claimed under any travel policy?",
        description: "",
        status: false,
        member_data: {
          adult_one: {
            keyName: "adult_one",
            name: "",
            image_class: "adult",
            selection_status: false,
            "MQ1-SQ1-F2": {
              id: "MQ1-SQ1-F2",
              title: "Existing Since",
              field_type: "TEXTFIELD",
              value: { value: "", warning: false },
            },
          },
          adult_two: {
            keyName: "adult_two",
            name: "",
            image_class: "adult",
            selection_status: false,
            "MQ1-SQ1-F2": {
              id: "MQ1-SQ1-F2",
              title: "Existing Since",
              field_type: "TEXTFIELD",
              value: { value: "", warning: false },
            },
          },
          child_one: {
            keyName: "child_one",
            name: "",
            image_class: "child",
            selection_status: false,
            "MQ1-SQ1-F2": {
              id: "MQ1-SQ1-F2",
              title: "Existing Since",
              field_type: "TEXTFIELD",
              value: { value: "", warning: false },
            },
          },
          child_two: {
            keyName: "child_two",
            name: "",
            image_class: "child",
            selection_status: false,
            "MQ1-SQ1-F2": {
              id: "MQ1-SQ1-F2",
              title: "Existing Since",
              field_type: "TEXTFIELD",
              value: { value: "", warning: false },
            },
          },
          child_three: {
            keyName: "child_three",
            name: "",

            image_class: "child",
            selection_status: false,
            "MQ1-SQ1-F2": {
              id: "MQ1-SQ1-F2",
              title: "Existing Since",
              field_type: "TEXTFIELD",
              value: { value: "", warning: false },
            },
          },
          child_four: {
            keyName: "child_four",
            name: "",
            image_class: "child",
            selection_status: false,
            "MQ1-SQ1-F2": {
              id: "MQ1-SQ1-F2",
              title: "Existing Since",
              field_type: "TEXTFIELD",
              value: { value: "", warning: false },
            },
          },
        },
      },
    },
    MQ03: {
      main_question: {
        id: "MQ03",
        icon_class: "tobaco",
        title:
          "Has anyone been diagnosed / hospitalized / or under any treatment for any illness / injury during the last 48 months ?",
        description: "",
        status: false,
        member_data: {
          adult_one: {
            keyName: "adult_one",
            name: "",
            image_class: "adult",
            selection_status: false,
            "MQ1-SQ1-F2": {
              id: "MQ1-SQ1-F2",
              title: "Existing Since",
              field_type: "TEXTFIELD",
              value: { value: "", warning: false },
            },
          },
          adult_two: {
            keyName: "adult_two",
            name: "",
            image_class: "adult",
            selection_status: false,
            "MQ1-SQ1-F2": {
              id: "MQ1-SQ1-F2",
              title: "Existing Since",
              field_type: "TEXTFIELD",
              value: { value: "", warning: false },
            },
          },
          child_one: {
            keyName: "child_one",
            name: "",
            image_class: "child",
            selection_status: false,
            "MQ1-SQ1-F2": {
              id: "MQ1-SQ1-F2",
              title: "Existing Since",
              field_type: "TEXTFIELD",
              value: { value: "", warning: false },
            },
          },
          child_two: {
            keyName: "child_two",
            name: "",
            image_class: "child",
            selection_status: false,
            "MQ1-SQ1-F2": {
              id: "MQ1-SQ1-F2",
              title: "Existing Since",
              field_type: "TEXTFIELD",
              value: { value: "", warning: false },
            },
          },
          child_three: {
            keyName: "child_three",
            name: "",
            image_class: "child",
            selection_status: false,
            "MQ1-SQ1-F2": {
              id: "MQ1-SQ1-F2",
              title: "Existing Since",
              field_type: "TEXTFIELD",
              value: { value: "", warning: false },
            },
          },
          child_four: {
            keyName: "child_four",
            name: "",
            image_class: "child",
            selection_status: false,
            "MQ1-SQ1-F2": {
              id: "MQ1-SQ1-F2",
              title: "Existing Since",
              field_type: "TEXTFIELD",
              value: { value: "", warning: false },
            },
          },
        },
      },
    },
  };
};
