import { Route, Routes } from "react-router-dom";
import { TERM_FULL_JOURNEY_ROUTES } from "../../../../Path/TermFullJourneyRoutes";
import ProposerDetailsContainer from "../../../../../Container/TermFullJourney/HDFC/ProposalPage/ProposerDetailsContainer/ProposerDetailsContainer";
import RidersBenefitsContainer from "../../../../../Container/TermFullJourney/HDFC/ProposalPage/RidersBenefitsContainer/RidersBenefitsContainer";
import PaymentConfirmationPageContainer from "../../../../../Container/TermFullJourney/HDFC/PaymentConfirmationPage/PaymentConfirmationPageContainer";
import JourneyRequirementContainer from "../../../../../Container/TermFullJourney/HDFC/ProposalPage/JourneyRequirementContainer/JourneyRequirementContainer";
import NomineeDetailsContainer from "../../../../../Container/TermFullJourney/HDFC/ProposalPage/NomineeDetailsConatiner/NomineeDetailsContainer";
import AddressDetailsContainer from "../../../../../Container/TermFullJourney/HDFC/ProposalPage/AddressDetailsContainer/AddressDetailsContainer";
import CKYCContainer from "../../../../../Container/TermFullJourney/HDFC/ProposalPage/CKYCContainer/CKYCContainer";
import UploadDocumentContainer from "../../../../../Container/TermFullJourney/HDFC/ProposalPage/UploadDocumentContainer/UploadDocumentContainer";
import MedicalPageContainer from "../../../../../Container/TermFullJourney/HDFC/ProposalPage/MedicalPageContainer/MedicalPageContainer";
import PreviewPageContainer from "../../../../../Container/TermFullJourney/HDFC/ProposalPage/PreviewPageContainer/PreviewPageContainer";
import PolicyConfirmationPageContainer from "../../../../../Container/TermFullJourney/HDFC/PolicyConfirmationPage/PolicyConfirmationPageContainer";

const HDFC_ROUTES_FJ = () => {
    return (
      <Routes>
        <Route
          path={TERM_FULL_JOURNEY_ROUTES.PROPOSER_DETAILS}
          element={<ProposerDetailsContainer />}
        />
        <Route
          path={TERM_FULL_JOURNEY_ROUTES.RIDERS_BENEFITS}
          element={<RidersBenefitsContainer />}
        />
        <Route
          path={TERM_FULL_JOURNEY_ROUTES.COFIRMATION_PAGE}
          element={<PaymentConfirmationPageContainer />}
        />
        <Route
          path={TERM_FULL_JOURNEY_ROUTES.JOURNEY_REQUIREMENT}
          element={<JourneyRequirementContainer />}
        />
        <Route
          path={TERM_FULL_JOURNEY_ROUTES.NOMINEE_DETAILS}
          element={<NomineeDetailsContainer />}
        />
        <Route
          path={TERM_FULL_JOURNEY_ROUTES.ADDRESS_DETAILS}
          element={<AddressDetailsContainer />}
        />
        <Route path={TERM_FULL_JOURNEY_ROUTES.CKYC} element={<CKYCContainer />} />
        <Route
          path={TERM_FULL_JOURNEY_ROUTES.UPLOAD_DOCUMENT}
          element={<UploadDocumentContainer />}
        />
        <Route
          path={TERM_FULL_JOURNEY_ROUTES.MEDICAL_PAGE}
          element={<MedicalPageContainer />}
        />
        <Route
          path={TERM_FULL_JOURNEY_ROUTES.PREVIEW_PAGE}
          element={<PreviewPageContainer />}
        />
        <Route
          path={TERM_FULL_JOURNEY_ROUTES.POLICY_CONFIRMATION}
          element={<PolicyConfirmationPageContainer />}
        />
      </Routes>
    );
  };

  export default HDFC_ROUTES_FJ