import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";
import AddOnsContainer from "../../../../../Container/HI/ProposalPage/GoDigit/AddOns/AddOnsContainer";
import AddressDetailsContainer from "../../../../../Container/HI/ProposalPage/GoDigit/AddressDetails/AddressDetailsContainer";
import HouseHoldBreakupContainer from "../../../../../Container/HI/ProposalPage/GoDigit/HouseHoldBreakup/HouseHoldBreakupContainer";
import InBuiltCoveragesContainer from "../../../../../Container/HI/ProposalPage/GoDigit/InBuiltCoverages/InBuiltCoveragesContainer";
import ManualKYCContainer from "../../../../../Container/HI/ProposalPage/GoDigit/ManualKYC/ManualKYCContainer";
import OwnerDetailsContainer from "../../../../../Container/HI/ProposalPage/GoDigit/OwnerDetails/OwnerDetailsContainer";
import PropertyDetailsContainer from "../../../../../Container/HI/ProposalPage/GoDigit/PropertyDetails/PropertyDetailsContainer";
import ReviewDetailsContainer from "../../../../../Container/HI/ProposalPage/GoDigit/ReviewDetails/ReviewDetailsContainer";
import VerifyKYCContainer from "../../../../../Container/HI/ProposalPage/GoDigit/VerifyKYC/VerifyKYCContainer";
import { PROPOSAL_SERVICES } from "../../../../../Services/HI/ProposalService";
import { QUOTES_SERVICES } from "../../../../../Services/HI/QuotesService";
import { useAppSelector } from "../../../../../Store/hooks";
import { householdDetailsSlice } from "../../../../../Store/Slice_Reducer/HI/HouseholdDetails/HouseholdDetailSlice";
import { kycResponseSlice } from "../../../../../Store/Slice_Reducer/HI/KycResponse/KycResponseSlice";
import { proposalSlice } from "../../../../../Store/Slice_Reducer/HI/Proposal/ProposalSlice";
import { quoteSlice } from "../../../../../Store/Slice_Reducer/HI/Quotes/QuoteSlice";
import {
  extractNumbersFromString,
  isEmpty,
} from "../../../../../SupportingFiles/HelpingFunction";
import { THouseholdDetails } from "../../../../../types/HI/THouseholdDetails";
import { KYC_STATUS } from "../../../../../types/HI/TKycResponse";
import { HI_PATH } from "../../../../Path/HIPath";
import HIProposalContainer from "../../HIProposalContainer";

interface PROPOSAL_INFO_RESPONSE {
  insurance: {
    id: string;
    quote_no: string;
    module: string;
    insurance_type: string;
    sum_insured: string;
    name: string;
    email: string;
    mobile: string;
    pincode: string;
    state: string;
    city: string;
    status: string;
    source_url: string;
    utm_source: string;
    utm_medium: string;
    utm_medium_d: string;
    utm_medium_m: string;
    utm_campaign: string;
    utm_keyword: string;
    tagged_by_agent: string;
    cron_mail_status: string;
    ip_address: string;
    prop_event: string;
    owner_type: string;
    building_type: string;
    building_value: string;
    household_value: string;
    carpet_area: string;
    cost_of_construction: string;
    tenor: string;
    created: string;
    updated: string;
    insured_for: string;
  };
  proposal: {
    id: string;
    quote_no: string;
    owner_details: string;
    co_owner_details: string;
    property_details: string;
    communication_address: string;
    loan_details: string;
    nominee_details: string;
    household_details: string;
    add_ons: string;
    in_built_coverages: string;
    created: string;
    updated: string;
  };
  buyAction: {
    id: string;
    quote_no: string;
    insurance_type: string;
    product_id: string;
    product_code: string;
    company_code: string;
    sum_insured: string;
    premium: string;
    prev_premium: string;
    request: string | null;
    created: string;
    updated: string;
  };
  CompanyDetails: {
    id: string;
    company_master_id: string;
    company_code: string;
    valid_from: string;
    valid_to: string;
    product_type_id: string;
    product_type_code: string;
    claim_ratio: string;
    avg_time_to_sttle_claim: string;
    net_income_ration: string;
    policy_sales_growth: string;
    customer_satisfaction: string;
    logo: string;
    status: string;
    created: string;
    logoUrl: string;
  };
  productDetails: {
    id: string;
    parent_id: string;
    product_type_id: string;
    product_type_code: string;
    product_code: string;
    product_name: string;
    product_desc: string;
    valid_from: string;
    valid_to: string;
    rating: string;
    brochure: string;
    quote_action: string;
    rate_calc_method: string;
    proposal_method: string;
    is_online: string;
    status: string;
    created: string;
    tax_calc_method: string;
    discount_calc_method: string;
    buy_online_code: string;
    special_feature_codes: string;
    policy_covers: string;
    bs_ty_new: string;
    bs_ty_rollover: string;
    bs_ty_exp: string;
    short_name: string;
    prem_calc_action: string;
    tax_calc_action: string;
    discount_calc_action: string;
  };
  ckycDetails: string;
}

const GO_DIGIT_HI_PROPOSAL_ROUTES = () => {
  const dispatch = useDispatch();
  const state = useAppSelector((state) => state);

  const location = useLocation();
  const [isHIPath, setIsHIPath] = useState<boolean>(false);

  useEffect(() => {
    setIsHIPath(Object.values(HI_PATH).includes(location.pathname));
  }, [location.pathname]);

  useEffect(() => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const type = params.get("type");
    const quote_no = params.get("quote_no");
    const product_id = params.get("product_id");
    const companyCode = params.get("companyCode");

    if (!isEmpty(quote_no) && quote_no && type === "HI") {
      dispatch(quoteSlice.actions.setQuoteId(btoa(quote_no)));

      setTimeout(() => {
        getProposalInfo(btoa(quote_no));
      }, 1000);
    }
  }, [location.pathname]);

  const getProposalInfo = (quoteId: string) => {
    const onSuccess = (res: any) => {
      const dataToUse: PROPOSAL_INFO_RESPONSE = res.data.data;

      const objToSet: THouseholdDetails = {
        buildingValue: {
          value: dataToUse.insurance.building_value,
          warning: false,
        },
        carpetArea: {
          value: dataToUse.insurance.carpet_area,
          warning: false,
        },
        costOfconstruction: {
          value: dataToUse.insurance.cost_of_construction,
          warning: false,
        },
        email: dataToUse.insurance.email,
        householdItems: {
          value: dataToUse.insurance.household_value,
          warning: false,
        },
        policyTerm: Number(dataToUse.insurance.tenor.split("-")[1]),
        sumInsuredFor: dataToUse.insurance.insured_for,
        youAre: dataToUse.insurance.owner_type,
        youHave: dataToUse.insurance.building_type,
        name: dataToUse.insurance.name,
      };

      let navigateToBreakup = false;
      const householdSI_threshhold = 1000000;

      if (
        Number(extractNumbersFromString(dataToUse.insurance.household_value)) >
        householdSI_threshhold
      ) {
        navigateToBreakup = true;
      } else if (
        Number(extractNumbersFromString(dataToUse.insurance.household_value)) >
        Number(extractNumbersFromString(dataToUse.insurance.building_value)) *
          0.2
      ) {
        navigateToBreakup = true;
      }

      const commAddressObj = JSON.parse(
        dataToUse.proposal.communication_address
      );
      const propertyDetails = JSON.parse(dataToUse.proposal.property_details);

      dispatch(householdDetailsSlice.actions.setHouseholdDetails(objToSet));
      dispatch(quoteSlice.actions.setQuoteId(quoteId));
      dispatch(quoteSlice.actions.setNavigateToBreakup(navigateToBreakup));

      if (commAddressObj) {
        dispatch(
          proposalSlice.actions.setAddressDetails({
            communicationAddress: {
              address: { value: commAddressObj?.address, warning: false },
              address2: { value: "", warning: false },
              city: { value: commAddressObj.city, warning: false },
              state: { value: commAddressObj?.state, warning: false },
              pincode: { value: commAddressObj.pincode, warning: false },
            },
            isSame: state.proposalInformation.addressDetails.isSame,
            propertyAddress: {
              address: {
                setFromKycResp: false,
                value: propertyDetails.address,
                warning: false,
              },
              address2: {
                setFromKycResp: false,
                value: "",
                warning: false,
              },
              city: {
                setFromKycResp: false,
                value: propertyDetails.city,
                warning: false,
              },
              state: {
                setFromKycResp: false,
                value: propertyDetails?.state,
                warning: false,
              },
              pincode: {
                setFromKycResp: false,
                value: propertyDetails.pincode,
                warning: false,
              },
            },
          })
        );
      }
      dispatch(
        proposalSlice.actions.setPolicyInfo({
          ...state.proposalInformation.policy_information,
          CompanyDetails: {
            avg_time_to_sttle_claim:
              dataToUse.CompanyDetails.avg_time_to_sttle_claim,
            claim_ratio: dataToUse.CompanyDetails.claim_ratio,
            company_code: dataToUse.CompanyDetails.company_code,
            company_master_id: dataToUse.CompanyDetails.company_master_id,
            created: dataToUse.CompanyDetails.created,
            customer_satisfaction:
              dataToUse.CompanyDetails.customer_satisfaction,
            id: dataToUse.CompanyDetails.id,
            logo: dataToUse.CompanyDetails.logo,
            logoUrl: dataToUse.CompanyDetails.logoUrl,
            net_income_ration: dataToUse.CompanyDetails.net_income_ration,
            policy_sales_growth: dataToUse.CompanyDetails.policy_sales_growth,
            product_type_code: dataToUse.CompanyDetails.product_type_code,
            product_type_id: dataToUse.CompanyDetails.product_type_id,
            status: dataToUse.CompanyDetails.status,
            valid_from: dataToUse.CompanyDetails.valid_from,
            valid_to: dataToUse.CompanyDetails.valid_to,
          },
          productDetails: {
            brochure: dataToUse.productDetails.brochure,
            bs_ty_exp: dataToUse.productDetails.bs_ty_exp,
            bs_ty_new: dataToUse.productDetails.bs_ty_new,
            bs_ty_rollover: dataToUse.productDetails.bs_ty_rollover,
            buy_online_code: dataToUse.productDetails.buy_online_code,
            created: dataToUse.productDetails.created,
            discount_calc_action: dataToUse.productDetails.discount_calc_action,
            discount_calc_method: dataToUse.productDetails.discount_calc_method,
            id: dataToUse.productDetails.id,
            is_online: dataToUse.productDetails.is_online,
            parent_id: dataToUse.productDetails.parent_id,
            policy_covers: dataToUse.productDetails.policy_covers,
            prem_calc_action: dataToUse.productDetails.prem_calc_action,
            product_code: dataToUse.productDetails.product_code,
            product_desc: dataToUse.productDetails.product_desc,
            product_name: dataToUse.productDetails.product_name,
            product_type_code: dataToUse.productDetails.product_type_code,
            product_type_id: dataToUse.productDetails.product_type_id,
            proposal_method: dataToUse.productDetails.proposal_method,
            quote_action: dataToUse.productDetails.quote_action,
            rate_calc_method: dataToUse.productDetails.rate_calc_method,
            rating: dataToUse.productDetails.rating,
            short_name: dataToUse.productDetails.short_name,
            special_feature_codes:
              dataToUse.productDetails.special_feature_codes,
            status: dataToUse.productDetails.status,
            tax_calc_action: dataToUse.productDetails.tax_calc_action,
            tax_calc_method: dataToUse.productDetails.tax_calc_method,
            valid_from: dataToUse.productDetails.valid_from,
            valid_to: dataToUse.productDetails.valid_to,
          },
        })
      );

      if (dataToUse.ckycDetails) {
        const kycData: {
          kycStatus: KYC_STATUS;
          customer: {
            customer_type: string;
            first_name: string;
            middle_name: string | null;
            last_name: string | null;
            gender: string;
            dob_or_doi: string;
            address: string;
            city: string;
            state: string;
            pincode: string;
            mobile_number: string;
            email: string;
            panNumber: string | null;
            gstinNumber: string | null;
            aadharNumber: string | null;
            ckycNumber: string | null;
            digitKYCReferenceId: string | null;
            drivingLicenseNumber: string | null;
            voterIdNumber: string | null;
            passportNumber: string | null;
          };
          digitKYCId: string;
        } = JSON.parse(JSON.parse(dataToUse.ckycDetails));

        if (kycData.customer) {
          const address = kycData.customer.address;

          // Split the address by space
          const addressArray = address.split(" ");

          // Find the middle index
          const middleIndex = Math.floor(addressArray.length / 2);

          // Join the first half and second half of the address
          const firstHalf = addressArray.slice(0, middleIndex).join(" ");
          const secondHalf = addressArray.slice(middleIndex).join(" ");
          dispatch(
            kycResponseSlice.actions.setKycResonse({
              aadharNumber: kycData.customer.aadharNumber || "",
              address_line_1: firstHalf || "",
              address_line_2: secondHalf || "",
              city: kycData.customer.city || "",
              ckycNumber: kycData.customer.ckycNumber || "",
              customer_type: kycData.customer.customer_type || "",
              digitKYCId: kycData.digitKYCId || "",
              dob: kycData.customer.dob_or_doi || "",
              email: kycData.customer.email || "",
              first_name: kycData.customer.first_name || "",
              gender: kycData.customer.gender || "",
              gstinNumber: kycData.customer.gstinNumber || "",
              kycStatus: kycData.kycStatus,
              last_name: kycData.customer.last_name || "",
              middle_name: kycData.customer.middle_name || "",
              mobile_number: kycData.customer.mobile_number || "",
              panNumber: kycData.customer.panNumber || "",
              pincode: kycData.customer.pincode || "",
              state: kycData.customer.state || "",
              iciciKYCId: "",
              iciciKycStatus: KYC_STATUS.FAILED,
            })
          );

          dispatch(
            proposalSlice.actions.setOwnerDetailsScreen({
              ...state.proposalInformation.ownerDetailsScreen,
              ownerDetails: {
                dob: {
                  setFromKycResp: false,
                  value: kycData.customer.dob_or_doi,
                  warning: kycData.customer.dob_or_doi ? false : true,
                },
                email: {
                  value: kycData.customer.email,
                  warning: kycData.customer.email ? false : true,
                },
                gender: {
                  value: kycData.customer.gender === "M" ? "Male" : "Female",
                  warning: kycData.customer.gender ? false : true,
                },
                mobile: {
                  setFromKycResp: false,
                  value: kycData.customer.mobile_number,
                  warning: kycData.customer.mobile_number ? false : true,
                },
                name: {
                  setFromKycResp: false,
                  value:
                    kycData.customer.first_name +
                    " " +
                    (kycData.customer.middle_name
                      ? kycData.customer.middle_name
                      : " ") +
                    " " +
                    (kycData.customer.last_name
                      ? kycData.customer.last_name
                      : " "),
                  warning: kycData.customer.first_name ? false : true,
                },
                pancard: {
                  value: kycData.customer.panNumber || "",
                  warning: false,
                },
              },
            })
          );
          // debugger;
        }
      }

      if (quoteId) {
        updateDigitQuotePremium(quoteId);
      }
    };

    const onError = (err: any) => {
      console.log(err);
    };

    PROPOSAL_SERVICES.get_proposal_page_info(onError, onSuccess, quoteId);
  };

  const updateDigitQuotePremium = (quoteId: string) => {
    const quoteIdToUse = quoteId ? quoteId : state.quote.quote_id;

    if (!quoteIdToUse) {
      return;
    }
    const onSuccess = (res: any) => {
      dispatch(proposalSlice.actions.setPolicyPremiumInfo(res.data.quoteLists));
    };

    const onError = (err: any) => {
      console.log(err);
    };

    QUOTES_SERVICES.update_digit_quote_premium(
      onError,
      onSuccess,
      quoteIdToUse
    );
  };

  return (
    <Routes>
      <Route path={HI_PATH.Proposal} element={<HIProposalContainer />}>
        <Route
          path={HI_PATH.HOUSE_HOLD_BREAKUP}
          element={<HouseHoldBreakupContainer />}
        />
        <Route path={HI_PATH.VERIFY_CKYC} element={<VerifyKYCContainer />} />
        <Route path={HI_PATH.MANUAL_KYC} element={<ManualKYCContainer />} />
        <Route
          path={HI_PATH.IN_BUILT_COVERAGES}
          element={<InBuiltCoveragesContainer />}
        />
        <Route path={HI_PATH.ADD_ONS} element={<AddOnsContainer />} />
        <Route
          path={HI_PATH.OWNER_DETAILS}
          element={<OwnerDetailsContainer />}
        />
        <Route
          path={HI_PATH.PROPERTY_DETAILS}
          element={<PropertyDetailsContainer />}
        />
        <Route
          path={HI_PATH.ADDRESS_DETAILS}
          element={<AddressDetailsContainer />}
        />
        <Route
          path={HI_PATH.REVIEW_DETAILS}
          element={<ReviewDetailsContainer />}
        />
      </Route>
    </Routes>
  );
};

export default GO_DIGIT_HI_PROPOSAL_ROUTES;
