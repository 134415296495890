import { TCareMedicalQuestion } from "../../../types/Health/HealthQuotation/TCareMedicalQuestion";

//Disease or disorder of eye, ear, nose or throat (except any sight related problems corrected by prescription lenses)?
const Cigarette = (data: TCareMedicalQuestion) => {
  return {
    medicalCigrate: data.MQ06?.main_question?.sub_question?.["MQ1-SQ1"]
      ?.status
      ? "Yes"
      : "No", // 6.1.Cigarette
    cigarette:
      data?.MQ06?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.adult_one.field_data?.["MQ1-SQ1-F1"].value?.value,
    spouse_cigarette:
      data?.MQ06?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.adult_two.field_data?.["MQ1-SQ1-F1"].value?.value,
    child1_cigarette:
      data?.MQ06?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_one.field_data?.["MQ1-SQ1-F1"].value?.value,
    child2_cigarette:
      data?.MQ06?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_two.field_data?.["MQ1-SQ1-F1"].value?.value,
    child3_cigarette:
      data?.MQ06?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_three.field_data?.["MQ1-SQ1-F1"].value?.value,
    child4_cigarette:
      data?.MQ06?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_four.field_data?.["MQ1-SQ1-F1"].value?.value,

    cigaretteQuantity:
      data?.MQ06?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.adult_one.field_data?.["MQ1-SQ1-F2"].value?.value,
    spouseCigarette:
      data?.MQ06?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.adult_two.field_data?.["MQ1-SQ1-F2"].value?.value,
    child1CigaretteWeek:
      data?.MQ06?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_one.field_data?.["MQ1-SQ1-F2"].value?.value,
    child2CigaretteWeek:
      data?.MQ06?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_two.field_data?.["MQ1-SQ1-F2"].value?.value,
    child3CigaretteWeek:
      data?.MQ06?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_three.field_data?.["MQ1-SQ1-F2"].value?.value,
    child4CigaretteWeek:
      data?.MQ06?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_four.field_data?.["MQ1-SQ1-F2"].value?.value,

    cigarette_Duration:
      data?.MQ06?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.adult_one.field_data?.["MQ1-SQ1-F3"].value?.value,
    spouse_cigarette_month:
      data?.MQ06?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.adult_two.field_data?.["MQ1-SQ1-F3"].value?.value,
    child1_cigarette_month:
      data?.MQ06?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_one.field_data?.["MQ1-SQ1-F3"].value?.value,
    child2_cigarette_month:
      data?.MQ06?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_two.field_data?.["MQ1-SQ1-F3"].value?.value,
    child3_cigarette_month:
      data?.MQ06?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_three.field_data?.["MQ1-SQ1-F3"].value?.value,
    child4_cigarette_month:
      data?.MQ06?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_four.field_data?.["MQ1-SQ1-F3"].value?.value,

    cigarette_year:
      data?.MQ06?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.adult_one.field_data?.["MQ1-SQ1-F4"].value?.value,
    spouse_cigarette_year:
      data?.MQ06?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.adult_two.field_data?.["MQ1-SQ1-F4"].value?.value,
    child1_cigarette_year:
      data?.MQ06?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_one.field_data?.["MQ1-SQ1-F4"].value?.value,
    child2_cigarette_year:
      data?.MQ06?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_two.field_data?.["MQ1-SQ1-F4"].value?.value,
    child3_cigarette_year:
      data?.MQ06?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_three.field_data?.["MQ1-SQ1-F4"].value?.value,
    child4_cigarette_year:
      data?.MQ06?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_four.field_data?.["MQ1-SQ1-F4"].value?.value,
  };
}

const Bidi = (data: TCareMedicalQuestion) => {
  return {
    medicalSmoking: data.MQ06?.main_question?.sub_question?.["MQ2-SQ2"]
      ?.status
      ? "Yes"
      : "No",
    smoking:
      data?.MQ06?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.adult_one.field_data?.["MQ1-SQ1-F1"].value?.value,
    spouse_smokingQuantity:
      data?.MQ06?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.adult_two.field_data?.["MQ1-SQ1-F1"].value?.value,
    child1_smokingDuration:
      data?.MQ06?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.child_one.field_data?.["MQ1-SQ1-F1"].value?.value,
    child2_smokingDuration:
      data?.MQ06?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.child_two.field_data?.["MQ1-SQ1-F1"].value?.value,
    child3_smokingDuration:
      data?.MQ06?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.child_three.field_data?.["MQ1-SQ1-F1"].value?.value,
    child4_smokingDuration:
      data?.MQ06?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.child_four.field_data?.["MQ1-SQ1-F1"].value?.value,

    smokingQuantity:
      data?.MQ06?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.adult_one.field_data?.["MQ1-SQ1-F2"].value?.value,
    spouse_smoking_Quantity:
      data?.MQ06?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.adult_two.field_data?.["MQ1-SQ1-F2"].value?.value,
    child1_medicalSmoking:
      data?.MQ06?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.child_one.field_data?.["MQ1-SQ1-F2"].value?.value,
    child2_medicalSmoking:
      data?.MQ06?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.child_two.field_data?.["MQ1-SQ1-F2"].value?.value,
    child3_medicalSmoking:
      data?.MQ06?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.child_three.field_data?.["MQ1-SQ1-F2"].value?.value,
    child4_medicalSmoking:
      data?.MQ06?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.child_four.field_data?.["MQ1-SQ1-F2"].value?.value,

    smokingDuration:
      data?.MQ06?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.adult_one.field_data?.["MQ1-SQ1-F3"].value?.value,
    spouse_smoking_month:
      data?.MQ06?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.adult_two.field_data?.["MQ1-SQ1-F3"].value?.value,
    child1_smoking_month:
      data?.MQ06?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.child_one.field_data?.["MQ1-SQ1-F3"].value?.value,
    child2_smoking_month:
      data?.MQ06?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.child_two.field_data?.["MQ1-SQ1-F3"].value?.value,
    child3_smoking_month:
      data?.MQ06?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.child_three.field_data?.["MQ1-SQ1-F3"].value?.value,
    child4_smoking_month:
      data?.MQ06?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.child_four.field_data?.["MQ1-SQ1-F3"].value?.value,

    smoking_year:
      data?.MQ06?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.adult_one.field_data?.["MQ1-SQ1-F4"].value?.value,
    spouse_smoking_year:
      data?.MQ06?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.adult_two.field_data?.["MQ1-SQ1-F4"].value?.value,
    child1_smoking_year:
      data?.MQ06?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.child_one.field_data?.["MQ1-SQ1-F4"].value?.value,
    child2_smoking_year:
      data?.MQ06?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.child_two.field_data?.["MQ1-SQ1-F4"].value?.value,
    child3_smoking_year:
      data?.MQ06?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.child_three.field_data?.["MQ1-SQ1-F4"].value?.value,
    child4_smoking_year:
      data?.MQ06?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.child_four.field_data?.["MQ1-SQ1-F4"].value?.value,
  };
}

const Tobacco = (data: TCareMedicalQuestion) => {
  return {
    TobaccoDiseases: data.MQ06?.main_question?.sub_question?.["MQ3-SQ3"]
      ?.status
      ? "Yes"
      : "No",
    medicalTobaccoDiseases:
      data?.MQ06?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.adult_one.field_data?.["MQ1-SQ1-F1"].value?.value,
    spouse_medicalTobacco:
      data?.MQ06?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.adult_two.field_data?.["MQ1-SQ1-F1"].value?.value,
    child1_medicalTobacco:
      data?.MQ06?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.child_one.field_data?.["MQ1-SQ1-F1"].value?.value,
    child2_medicalTobacco:
      data?.MQ06?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.child_two.field_data?.["MQ1-SQ1-F1"].value?.value,
    child3_medicalTobacco:
      data?.MQ06?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.child_three.field_data?.["MQ1-SQ1-F1"].value?.value,
    child4_medicalTobacco:
      data?.MQ06?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.child_four.field_data?.["MQ1-SQ1-F1"].value?.value,

    medicalTobacco:
      data?.MQ06?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.adult_one.field_data?.["MQ1-SQ1-F2"].value?.value,
    spouse_tobacco_week:
      data?.MQ06?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.adult_two.field_data?.["MQ1-SQ1-F2"].value?.value,
    child1_tobacco_week:
      data?.MQ06?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.child_one.field_data?.["MQ1-SQ1-F2"].value?.value,
    child2_tobacco_week:
      data?.MQ06?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.child_two.field_data?.["MQ1-SQ1-F2"].value?.value,
    child3_tobacco_week:
      data?.MQ06?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.child_three.field_data?.["MQ1-SQ1-F2"].value?.value,
    child4_tobacco_week:
      data?.MQ06?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.child_four.field_data?.["MQ1-SQ1-F2"].value?.value,

    tobacco_month:
      data?.MQ06?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.adult_one.field_data?.["MQ1-SQ1-F3"].value?.value,
    spouse_tobacco_month:
      data?.MQ06?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.adult_two.field_data?.["MQ1-SQ1-F3"].value?.value,
    child1_tobacco_month:
      data?.MQ06?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.child_one.field_data?.["MQ1-SQ1-F3"].value?.value,
    child2_tobacco_month:
      data?.MQ06?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.child_two.field_data?.["MQ1-SQ1-F3"].value?.value,
    child3_tobacco_month:
      data?.MQ06?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.child_three.field_data?.["MQ1-SQ1-F3"].value?.value,
    child4_tobacco_month:
      data?.MQ06?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.child_four.field_data?.["MQ1-SQ1-F3"].value?.value,

    tobacco_year:
      data?.MQ06?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.adult_one.field_data?.["MQ1-SQ1-F4"].value?.value,
    spouse_tobacco_year:
      data?.MQ06?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.adult_two.field_data?.["MQ1-SQ1-F4"].value?.value,
    child1_tobacco_year:
      data?.MQ06?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.child_one.field_data?.["MQ1-SQ1-F4"].value?.value,
    child2_tobacco_year:
      data?.MQ06?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.child_two.field_data?.["MQ1-SQ1-F4"].value?.value,
    child3_tobacco_year:
      data?.MQ06?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.child_three.field_data?.["MQ1-SQ1-F4"].value?.value,
    child4_tobacco_year:
      data?.MQ06?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.child_four.field_data?.["MQ1-SQ1-F4"].value?.value,
  };
}

const Alcohol = (data: TCareMedicalQuestion) => {
  return {
    consume_alcohal: data.MQ06?.main_question?.sub_question?.["MQ4-SQ4"]
      ?.status
      ? "Yes"
      : "No",
    alcohol_days:
      data?.MQ06?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
        ?.adult_one.field_data?.["MQ1-SQ1-F1"].value?.value,
    spouse_alcohol_days:
      data?.MQ06?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
        ?.adult_two.field_data?.["MQ1-SQ1-F1"].value?.value,
    child1_alcohol_days:
      data?.MQ06?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
        ?.child_one.field_data?.["MQ1-SQ1-F1"].value?.value,
    child2_alcohol_days:
      data?.MQ06?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
        ?.child_two.field_data?.["MQ1-SQ1-F1"].value?.value,
    child3_alcohol_days:
      data?.MQ06?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
        ?.child_three.field_data?.["MQ1-SQ1-F1"].value?.value,
    child4_alcohol_days:
      data?.MQ06?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
        ?.child_four.field_data?.["MQ1-SQ1-F1"].value?.value,

    alcohol_week:
      data?.MQ06?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
        ?.adult_one.field_data?.["MQ1-SQ1-F2"].value?.value,
    spouse_alcohol_week:
      data?.MQ06?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
        ?.adult_two.field_data?.["MQ1-SQ1-F2"].value?.value,
    child1_medicalAlcohol:
      data?.MQ06?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
        ?.child_one.field_data?.["MQ1-SQ1-F2"].value?.value,
    child2_medicalAlcohol:
      data?.MQ06?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
        ?.child_two.field_data?.["MQ1-SQ1-F2"].value?.value,
    child3_medicalAlcohol:
      data?.MQ06?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
        ?.child_three.field_data?.["MQ1-SQ1-F2"].value?.value,
    child4_medicalAlcohol:
      data?.MQ06?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
        ?.child_four.field_data?.["MQ1-SQ1-F2"].value?.value,

    alcohol_month:
      data?.MQ06?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
        ?.adult_one.field_data?.["MQ1-SQ1-F3"].value?.value,
    spouse_alcohol_month:
      data?.MQ06?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
        ?.adult_two.field_data?.["MQ1-SQ1-F3"].value?.value,
    child1_alcohol_month:
      data?.MQ06?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
        ?.child_one.field_data?.["MQ1-SQ1-F3"].value?.value,
    child2_alcohol_month:
      data?.MQ06?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
        ?.child_two.field_data?.["MQ1-SQ1-F3"].value?.value,
    child3_alcohol_month:
      data?.MQ06?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
        ?.child_three.field_data?.["MQ1-SQ1-F3"].value?.value,
    child4_alcohol_month:
      data?.MQ06?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
        ?.child_four.field_data?.["MQ1-SQ1-F3"].value?.value,

    alcohol_year:
      data?.MQ06?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
        ?.adult_one.field_data?.["MQ1-SQ1-F4"].value?.value,
    spouse_alcohol_year:
      data?.MQ06?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
        ?.adult_two.field_data?.["MQ1-SQ1-F4"].value?.value,
    child1_alcohol_year:
      data?.MQ06?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
        ?.child_one.field_data?.["MQ1-SQ1-F4"].value?.value,
    child2_alcohol_year:
      data?.MQ06?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
        ?.child_two.field_data?.["MQ1-SQ1-F4"].value?.value,
    child3_alcohol_year:
      data?.MQ06?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
        ?.child_three.field_data?.["MQ1-SQ1-F4"].value?.value,
    child4_alcohol_year:
      data?.MQ06?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
        ?.child_four.field_data?.["MQ1-SQ1-F4"].value?.value,
  };
}

const Gutka = (data: TCareMedicalQuestion) => {
  return {
    medicalpan_masala_Quantit: data.MQ06?.main_question?.sub_question?.[
      "MQ5-SQ5"
    ]?.status
      ? "Yes"
      : "No",
    consume_tobacco:
      data?.MQ06?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.adult_one.field_data?.["MQ1-SQ1-F1"].value?.value,
    spouse_pan_masala_Quantity:
      data?.MQ06?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.adult_two.field_data?.["MQ1-SQ1-F1"].value?.value,
    child1_pan_masala_Quantity:
      data?.MQ06?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.child_one.field_data?.["MQ1-SQ1-F1"].value?.value,
    child2_pan_masala_Quantity:
      data?.MQ06?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.child_two.field_data?.["MQ1-SQ1-F1"].value?.value,
    child3_pan_masala_Quantity:
      data?.MQ06?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.child_three.field_data?.["MQ1-SQ1-F1"].value?.value,
    child4_pan_masala_Quantity:
      data?.MQ06?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.child_four.field_data?.["MQ1-SQ1-F1"].value?.value,

    pan_masala_week:
      data?.MQ06?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.adult_one.field_data?.["MQ1-SQ1-F2"].value?.value,
    spouse_pan_masala_week:
      data?.MQ06?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.adult_two.field_data?.["MQ1-SQ1-F2"].value?.value,
    child1_pan_masala_week:
      data?.MQ06?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.child_one.field_data?.["MQ1-SQ1-F2"].value?.value,
    child2_pan_masala_week:
      data?.MQ06?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.child_two.field_data?.["MQ1-SQ1-F2"].value?.value,
    child3_pan_masala_week:
      data?.MQ06?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.child_three.field_data?.["MQ1-SQ1-F2"].value?.value,
    child4_pan_masala_week:
      data?.MQ06?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.child_four.field_data?.["MQ1-SQ1-F2"].value?.value,

    pan_masala_month:
      data?.MQ06?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.adult_one.field_data?.["MQ1-SQ1-F3"].value?.value,
    spouse_pan_masala_month:
      data?.MQ06?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.adult_two.field_data?.["MQ1-SQ1-F3"].value?.value,
    child1_pan_masala_month:
      data?.MQ06?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.child_one.field_data?.["MQ1-SQ1-F3"].value?.value,
    child2_pan_masala_month:
      data?.MQ06?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.child_two.field_data?.["MQ1-SQ1-F3"].value?.value,
    child3_pan_masala_month:
      data?.MQ06?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.child_three.field_data?.["MQ1-SQ1-F3"].value?.value,
    child4_pan_masala_month:
      data?.MQ06?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.child_four.field_data?.["MQ1-SQ1-F3"].value?.value,

    medical_pan_masala_disease:
      data?.MQ06?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.adult_one.field_data?.["MQ1-SQ1-F4"].value?.value,
    spouse_pan_masala_disease:
      data?.MQ06?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.adult_two.field_data?.["MQ1-SQ1-F4"].value?.value,
    child1_medical_pan_masala_disease:
      data?.MQ06?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.child_one.field_data?.["MQ1-SQ1-F4"].value?.value,
    child2_medical_pan_masala_disease:
      data?.MQ06?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.child_two.field_data?.["MQ1-SQ1-F4"].value?.value,
    child3_medical_pan_masala_disease:
      data?.MQ06?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.child_three.field_data?.["MQ1-SQ1-F4"].value?.value,
    child4_medical_pan_masala_disease:
      data?.MQ06?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.child_four.field_data?.["MQ1-SQ1-F4"].value?.value,
  };
}

const Drugs = (data: TCareMedicalQuestion) => {
  return {
    medical_Narotics_Quantity: data.MQ06?.main_question?.sub_question?.[
      "MQ6-SQ6"
    ]?.status
      ? "Yes"
      : "No",
    consume_Narotics:
      data?.MQ06?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.adult_one.field_data?.["MQ1-SQ1-F1"].value?.value,
    spouse_Narotics:
      data?.MQ06?.main_question?.sub_question?.["MQ6-SQ6"].member_data
        ?.adult_two.field_data?.["MQ1-SQ1-F1"].value?.value,
    child1_Narotics:
      data?.MQ06?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.child_one.field_data?.["MQ1-SQ1-F1"].value?.value,
    child2_Narotics:
      data?.MQ06?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.child_two.field_data?.["MQ1-SQ1-F1"].value?.value,
    child3_Narotics:
      data?.MQ06?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.child_three.field_data?.["MQ1-SQ1-F1"].value?.value,
    child4_Narotics:
      data?.MQ06?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.child_four.field_data?.["MQ1-SQ1-F1"].value?.value,

    medicalNarotics:
      data?.MQ06?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.adult_one.field_data?.["MQ1-SQ1-F2"].value?.value,
    spouse_medicalNarotics:
      data?.MQ06?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.adult_two.field_data?.["MQ1-SQ1-F2"].value?.value,
    child1_medicalNarotics:
      data?.MQ06?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.child_one.field_data?.["MQ1-SQ1-F2"].value?.value,
    child2_medicalNarotics:
      data?.MQ06?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.child_two.field_data?.["MQ1-SQ1-F2"].value?.value,
    child3_medicalNarotics:
      data?.MQ06?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.child_three.field_data?.["MQ1-SQ1-F2"].value?.value,
    child4_medicalNarotics:
      data?.MQ06?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.child_four.field_data?.["MQ1-SQ1-F2"].value?.value,

    naroticsQuantity:
      data?.MQ06?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.adult_one.field_data?.["MQ1-SQ1-F3"].value?.value,
    spouse_naroticsQuantity:
      data?.MQ06?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.adult_two.field_data?.["MQ1-SQ1-F3"].value?.value,
    child1_naroticsQuantity:
      data?.MQ06?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.child_one.field_data?.["MQ1-SQ1-F3"].value?.value,
    child2_naroticsQuantity:
      data?.MQ06?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.child_two.field_data?.["MQ1-SQ1-F3"].value?.value,
    child3_naroticsQuantity:
      data?.MQ06?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.child_three.field_data?.["MQ1-SQ1-F3"].value?.value,
    child4_naroticsQuantity:
      data?.MQ06?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.child_four.field_data?.["MQ1-SQ1-F3"].value?.value,

    naroticsDuration:
      data?.MQ06?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.adult_one.field_data?.["MQ1-SQ1-F4"].value?.value,
    spouse_naroticsDuration:
      data?.MQ06?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.adult_two.field_data?.["MQ1-SQ1-F4"].value?.value,
    child1_naroticsDuration:
      data?.MQ06?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.child_one.field_data?.["MQ1-SQ1-F4"].value?.value,
    child2_naroticsDuration:
      data?.MQ06?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.child_two.field_data?.["MQ1-SQ1-F4"].value?.value,
    child3_naroticsDuration:
      data?.MQ06?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.child_three.field_data?.["MQ1-SQ1-F4"].value?.value,
    child4_naroticsDuration:
      data?.MQ06?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.child_four.field_data?.["MQ1-SQ1-F4"].value?.value,
  };
}

const PlannedSurgery=(data:TCareMedicalQuestion)=>{
  return{
    medicalPlanDiseases:
    `${data.MQ02?.main_question?.status}` === "true" ? "Yes" : "No", //Has planned a surgery?
  medicalPlan:
    `${data?.MQ02?.main_question?.member_data?.adult_one?.selection_status}` ===
      "true"
      ? "Yes"
      : "No",
  plan_diagnosis:
    data?.MQ02?.main_question?.member_data?.adult_one?.field_data?.[
      "MQ1-SQ1-F1"
    ]?.value?.value,
  plan_surgery_date:
    data?.MQ02?.main_question?.member_data?.adult_one?.field_data?.[
      "MQ1-SQ1-F2"
    ]?.value?.value,
  plan_last_consultation_date:
    data?.MQ02?.main_question?.member_data?.adult_one?.field_data?.[
      "MQ1-SQ1-F3"
    ]?.value?.value,
  plan_current_status:
    data?.MQ02?.main_question?.member_data?.adult_one?.field_data?.[
      "MQ1-SQ1-F4"
    ]?.value?.value, // CURED,NOT TREATED,ONGOING
  plan_line_management:
    data?.MQ02?.main_question?.member_data?.adult_one?.field_data?.[
      "MQ1-SQ1-F5"
    ]?.value?.value, //1.MEDICAL,SURGICAL
  plan_proposed_surgery:
    data?.MQ02?.main_question?.member_data?.adult_one?.field_data?.[
      "MQ1-SQ1-F6"
    ]?.value?.value,
  plan_opted:
    data?.MQ02?.main_question?.member_data?.adult_one?.field_data?.[
      "MQ1-SQ1-F7"
    ]?.value?.value, // ;
  spouse_medicalPlan:
    `${data?.MQ02?.main_question?.member_data?.adult_two?.selection_status}` ===
      "true"
      ? "Yes"
      : "No",
  spouse_plan_diagnosis:
    data?.MQ02?.main_question?.member_data?.adult_two?.field_data?.[
      "MQ1-SQ1-F1"
    ]?.value?.value,
  spouse_plan_surgery_date:
    data?.MQ02?.main_question?.member_data?.adult_two?.field_data?.[
      "MQ1-SQ1-F2"
    ]?.value?.value,
  spouse_plan_last_consultation_date:
    data?.MQ02?.main_question?.member_data?.adult_two?.field_data?.[
      "MQ1-SQ1-F3"
    ]?.value?.value,
  spouse_plan_current_status:
    data?.MQ02?.main_question?.member_data?.adult_two?.field_data?.[
      "MQ1-SQ1-F4"
    ]?.value?.value, // CURED,NOT TREATED,ONGOING
  spouse_plan_line_management:
    data?.MQ02?.main_question?.member_data?.adult_two?.field_data?.[
      "MQ1-SQ1-F5"
    ]?.value?.value, //1.MEDICAL,SURGICAL
  spouse_plan_proposed_surgery:
    data?.MQ02?.main_question?.member_data?.adult_two?.field_data?.[
      "MQ1-SQ1-F6"
    ]?.value?.value,
  spouse_plan_opted:
    data?.MQ02?.main_question?.member_data?.adult_two?.field_data?.[
      "MQ1-SQ1-F7"
    ]?.value?.value, // ;
  child1_medicalPlan:
    `${data?.MQ02?.main_question?.member_data?.child_one?.selection_status}` ===
      "true"
      ? "Yes"
      : "No",
  child1_plan_diagnosis:
    data?.MQ02?.main_question?.member_data?.child_one?.field_data?.[
      "MQ1-SQ1-F1"
    ]?.value?.value,
  child1_plan_surgery_date:
    data?.MQ02?.main_question?.member_data?.child_one?.field_data?.[
      "MQ1-SQ1-F2"
    ]?.value?.value,
  child1_plan_last_consultation_date:
    data?.MQ02?.main_question?.member_data?.child_one?.field_data?.[
      "MQ1-SQ1-F3"
    ]?.value?.value,
  child1_plan_current_status:
    data?.MQ02?.main_question?.member_data?.child_one?.field_data?.[
      "MQ1-SQ1-F4"
    ]?.value?.value, // CURED,NOT TREATED,ONGOING
  child1_plan_line_management:
    data?.MQ02?.main_question?.member_data?.child_one?.field_data?.[
      "MQ1-SQ1-F5"
    ]?.value?.value, //1.MEDICAL,SURGICAL
  child1_plan_proposed_surgery:
    data?.MQ02?.main_question?.member_data?.child_one?.field_data?.[
      "MQ1-SQ1-F6"
    ]?.value?.value,
  child1_plan_opted:
    data?.MQ02?.main_question?.member_data?.child_one?.field_data?.[
      "MQ1-SQ1-F7"
    ]?.value?.value,
  //2
  child2_medicalPlan:
    `${data?.MQ02?.main_question?.member_data?.child_two?.selection_status}` ===
      "true"
      ? "Yes"
      : "No",
  child2_plan_diagnosis:
    data?.MQ02?.main_question?.member_data?.child_two?.field_data?.[
      "MQ1-SQ1-F1"
    ]?.value?.value,
  child2_plan_surgery_date:
    data?.MQ02?.main_question?.member_data?.child_two?.field_data?.[
      "MQ1-SQ1-F2"
    ]?.value?.value,
  child2_plan_last_consultation_date:
    data?.MQ02?.main_question?.member_data?.child_two?.field_data?.[
      "MQ1-SQ1-F3"
    ]?.value?.value,
  child2_plan_current_status:
    data?.MQ02?.main_question?.member_data?.child_two?.field_data?.[
      "MQ1-SQ1-F4"
    ]?.value?.value, // CURED,NOT TREATED,ONGOING
  child2_plan_line_management:
    data?.MQ02?.main_question?.member_data?.child_two?.field_data?.[
      "MQ1-SQ1-F5"
    ]?.value?.value, //1.MEDICAL,SURGICAL
  child2_plan_proposed_surgery:
    data?.MQ02?.main_question?.member_data?.child_two?.field_data?.[
      "MQ1-SQ1-F6"
    ]?.value?.value,
  child2_plan_opted:
    data?.MQ02?.main_question?.member_data?.child_two?.field_data?.[
      "MQ1-SQ1-F7"
    ]?.value?.value,
  //3
  child3_medicalPlan:
    `${data?.MQ02?.main_question?.member_data?.child_three?.selection_status}` ===
      "true"
      ? "Yes"
      : "No",
  child3_plan_diagnosis:
    data?.MQ02?.main_question?.member_data?.child_three?.field_data?.[
      "MQ1-SQ1-F1"
    ]?.value?.value,
  child3_plan_surgery_date:
    data?.MQ02?.main_question?.member_data?.child_three?.field_data?.[
      "MQ1-SQ1-F2"
    ]?.value?.value,
  child3_plan_last_consultation_date:
    data?.MQ02?.main_question?.member_data?.child_three?.field_data?.[
      "MQ1-SQ1-F3"
    ]?.value?.value,
  child3_plan_current_status:
    data?.MQ02?.main_question?.member_data?.child_three?.field_data?.[
      "MQ1-SQ1-F4"
    ]?.value?.value, // CURED,NOT TREATED,ONGOING
  child3_plan_line_management:
    data?.MQ02?.main_question?.member_data?.child_three?.field_data?.[
      "MQ1-SQ1-F5"
    ]?.value?.value, //1.MEDICAL,SURGICAL
  child3_plan_proposed_surgery:
    data?.MQ02?.main_question?.member_data?.child_three?.field_data?.[
      "MQ1-SQ1-F6"
    ]?.value?.value,
  child3_plan_opted:
    data?.MQ02?.main_question?.member_data?.child_three?.field_data?.[
      "MQ1-SQ1-F7"
    ]?.value?.value,
  //4
  child4_medicalPlan:
    `${data?.MQ02?.main_question?.member_data?.child_four?.selection_status}` ===
      "true"
      ? "Yes"
      : "No",
  child4_plan_diagnosis:
    data?.MQ02?.main_question?.member_data?.child_four?.field_data?.[
      "MQ1-SQ1-F1"
    ]?.value?.value,
  child4_plan_surgery_date:
    data?.MQ02?.main_question?.member_data?.child_four?.field_data?.[
      "MQ1-SQ1-F2"
    ]?.value?.value,
  child4_plan_last_consultation_date:
    data?.MQ02?.main_question?.member_data?.child_four?.field_data?.[
      "MQ1-SQ1-F3"
    ]?.value?.value,
  child4_plan_current_status:
    data?.MQ02?.main_question?.member_data?.child_four?.field_data?.[
      "MQ1-SQ1-F4"
    ]?.value?.value, // CURED,NOT TREATED,ONGOING
  child4_plan_line_management:
    data?.MQ02?.main_question?.member_data?.child_four?.field_data?.[
      "MQ1-SQ1-F5"
    ]?.value?.value, //1.MEDICAL,SURGICAL
  child4_plan_proposed_surgery:
    data?.MQ02?.main_question?.member_data?.child_four?.field_data?.[
      "MQ1-SQ1-F6"
    ]?.value?.value,
  child4_plan_opted:
    data?.MQ02?.main_question?.member_data?.child_four?.field_data?.[
      "MQ1-SQ1-F7"
    ]?.value?.value,

  }
}

const HospitalizedPast=(data:TCareMedicalQuestion)=>{
  return{
    medicalHospitalizedDiseases: data?.MQ03?.main_question?.status
    ? "Yes"
    : "No", //5. Was hospitalized in the past?
  medicalHospitalize:
    `${data?.MQ03?.main_question?.member_data?.adult_one?.selection_status}` ===
      "true"
      ? "Yes"
      : "No",
  hospitalized_diagnosis:
    data?.MQ03?.main_question?.member_data?.adult_one?.field_data?.[
      "MQ1-SQ1-F1"
    ]?.value?.value,
  hospitalized_diagnosis_date:
    data?.MQ03?.main_question?.member_data?.adult_one?.field_data?.[
      "MQ1-SQ1-F2"
    ]?.value?.value,
  hospitalized_last_consultation_date:
    data?.MQ03?.main_question?.member_data?.adult_one?.field_data?.[
      "MQ1-SQ1-F3"
    ]?.value?.value,
  hospitalized_current_status:
    data?.MQ03?.main_question?.member_data?.adult_one?.field_data?.[
      "MQ1-SQ1-F4"
    ]?.value?.value, // CURED,NOT TREATED,ONGOING
  hospitalized_line_management:
    data?.MQ03?.main_question?.member_data?.adult_one?.field_data?.[
      "MQ1-SQ1-F5"
    ]?.value?.value, //1.MEDICAL,SURGICAL
  hospitalized_opted:
    data?.MQ03?.main_question?.member_data?.adult_one?.field_data?.[
      "MQ1-SQ1-F6"
    ]?.value?.value, // ;
  spouse_medicalHospitalize:
    `${data?.MQ03?.main_question?.member_data?.adult_two?.selection_status}` ===
      "true"
      ? "Yes"
      : "No",
  spouse_hospitalized_diagnosis:
    data?.MQ03?.main_question?.member_data?.adult_two?.field_data?.[
      "MQ1-SQ1-F1"
    ]?.value?.value,
  spouse_hospitalized_diagnosis_date:
    data?.MQ03?.main_question?.member_data?.adult_two?.field_data?.[
      "MQ1-SQ1-F2"
    ]?.value?.value,
  spouse_hospitalized_last_consultation_date:
    data?.MQ03?.main_question?.member_data?.adult_two?.field_data?.[
      "MQ1-SQ1-F3"
    ]?.value?.value,
  spouse_hospitalized_current_status:
    data?.MQ03?.main_question?.member_data?.adult_two?.field_data?.[
      "MQ1-SQ1-F4"
    ]?.value?.value, // CURED,NOT TREATED,ONGOING
  spouse_hospitalized_line_management:
    data?.MQ03?.main_question?.member_data?.adult_two?.field_data?.[
      "MQ1-SQ1-F5"
    ]?.value?.value, //1.MEDICAL,SURGICAL
  spouse_hospitalized_opted:
    data?.MQ03?.main_question?.member_data?.adult_two?.field_data?.[
      "MQ1-SQ1-F6"
    ]?.value?.value, //
  child1_medicalHospitalize:
    `${data?.MQ03?.main_question?.member_data?.child_one?.selection_status}` ===
      "true"
      ? "Yes"
      : "No",
  child1_hospitalized_diagnosis:
    data?.MQ03?.main_question?.member_data?.child_one?.field_data?.[
      "MQ1-SQ1-F1"
    ]?.value?.value,
  child1_hospitalized_diagnosis_date:
    data?.MQ03?.main_question?.member_data?.child_one?.field_data?.[
      "MQ1-SQ1-F2"
    ]?.value?.value,
  child1_hospitalized_last_consultation_date:
    data?.MQ03?.main_question?.member_data?.child_one?.field_data?.[
      "MQ1-SQ1-F3"
    ]?.value?.value,
  child1_hospitalized_current_status:
    data?.MQ03?.main_question?.member_data?.child_one?.field_data?.[
      "MQ1-SQ1-F4"
    ]?.value?.value, // CURED,NOT TREATED,ONGOING
  child1_hospitalized_line_management:
    data?.MQ03?.main_question?.member_data?.child_one?.field_data?.[
      "MQ1-SQ1-F5"
    ]?.value?.value, //1.MEDICAL,SURGICAL
  child1_hospitalized_opted:
    data?.MQ03?.main_question?.member_data?.child_one?.field_data?.[
      "MQ1-SQ1-F6"
    ]?.value?.value, //

  //2
  child2_medicalHospitalize:
    `${data?.MQ03?.main_question?.member_data?.child_two?.selection_status}` ===
      "true"
      ? "Yes"
      : "No",
  child2_hospitalized_diagnosis:
    data?.MQ03?.main_question?.member_data?.child_two?.field_data?.[
      "MQ1-SQ1-F1"
    ]?.value?.value,
  child2_hospitalized_diagnosis_date:
    data?.MQ03?.main_question?.member_data?.child_two?.field_data?.[
      "MQ1-SQ1-F2"
    ]?.value?.value,
  child2_hospitalized_last_consultation_date:
    data?.MQ03?.main_question?.member_data?.child_two?.field_data?.[
      "MQ1-SQ1-F3"
    ]?.value?.value,
  child2_hospitalized_current_status:
    data?.MQ03?.main_question?.member_data?.child_two?.field_data?.[
      "MQ1-SQ1-F4"
    ]?.value?.value, // CURED,NOT TREATED,ONGOING
  child2_hospitalized_line_management:
    data?.MQ03?.main_question?.member_data?.child_two?.field_data?.[
      "MQ1-SQ1-F5"
    ]?.value?.value, //1.MEDICAL,SURGICAL
  child2_hospitalized_opted:
    data?.MQ03?.main_question?.member_data?.child_two?.field_data?.[
      "MQ1-SQ1-F6"
    ]?.value?.value, //
  //3
  child3_medicalHospitalize:
    `${data?.MQ03?.main_question?.member_data?.child_three?.selection_status}` ===
      "true"
      ? "Yes"
      : "No",
  child3_hospitalized_diagnosis:
    data?.MQ03?.main_question?.member_data?.child_three?.field_data?.[
      "MQ1-SQ1-F1"
    ]?.value?.value,
  child3_hospitalized_diagnosis_date:
    data?.MQ03?.main_question?.member_data?.child_three?.field_data?.[
      "MQ1-SQ1-F2"
    ]?.value?.value,
  child3_hospitalized_last_consultation_date:
    data?.MQ03?.main_question?.member_data?.child_three?.field_data?.[
      "MQ1-SQ1-F3"
    ]?.value?.value,
  child3_hospitalized_current_status:
    data?.MQ03?.main_question?.member_data?.child_three?.field_data?.[
      "MQ1-SQ1-F4"
    ]?.value?.value, // CURED,NOT TREATED,ONGOING
  child3_hospitalized_line_management:
    data?.MQ03?.main_question?.member_data?.child_three?.field_data?.[
      "MQ1-SQ1-F5"
    ]?.value?.value, //1.MEDICAL,SURGICAL
  child3_hospitalized_opted:
    data?.MQ03?.main_question?.member_data?.child_three?.field_data?.[
      "MQ1-SQ1-F6"
    ]?.value?.value, //
  //4
  child4_medicalHospitalize:
    `${data?.MQ03?.main_question?.member_data?.child_four?.selection_status}` ===
      "true"
      ? "Yes"
      : "No",
  child4_hospitalized_diagnosis:
    data?.MQ03?.main_question?.member_data?.child_four?.field_data?.[
      "MQ1-SQ1-F1"
    ]?.value?.value,
  child4_hospitalized_diagnosis_date:
    data?.MQ03?.main_question?.member_data?.child_four?.field_data?.[
      "MQ1-SQ1-F2"
    ]?.value?.value,
  child4_hospitalized_last_consultation_date:
    data?.MQ03?.main_question?.member_data?.child_four?.field_data?.[
      "MQ1-SQ1-F3"
    ]?.value?.value,
  child4_hospitalized_current_status:
    data?.MQ03?.main_question?.member_data?.child_four?.field_data?.[
      "MQ1-SQ1-F4"
    ]?.value?.value, // CURED,NOT TREATED,ONGOING
  child4_hospitalized_line_management:
    data?.MQ03?.main_question?.member_data?.child_four?.field_data?.[
      "MQ1-SQ1-F5"
    ]?.value?.value, //1.MEDICAL,SURGICAL
  child4_hospitalized_opted:
    data?.MQ03?.main_question?.member_data?.child_four?.field_data?.[
      "MQ1-SQ1-F6"
    ]?.value?.value, //
  }
}
export const HDFC_MEDICAL_REQUEST = (
  data: any,
  ADD_FORM_RESPONSE: any,
  SELECTED_QUOTE_DATA: any
) => {

  return {
    company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
    quote_no: ADD_FORM_RESPONSE.quote_no,
    product_code: SELECTED_QUOTE_DATA.productDetails.product_code,
    product_type_code: SELECTED_QUOTE_DATA.productDetails.product_type_code,
    medicalCongenitalExistingDiseases: data?.MQ01?.main_question?.status
      ? "Yes"
      : "No", //Has an ailment or disability or deformity including due to accident or congenital disease?
    medicalHypertensionDiseases:
      `${data.MQ01?.main_question?.sub_question?.["MQ4-SQ4"]?.status}` ===
        "true"
        ? "Yes"
        : "No", //Hypertension/ High blood pressure?
    medicalHypertension:
      data?.MQ01?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
        ?.adult_one?.selection_status === true
        ? "Yes"
        : "No",
    hypertension_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    hypertension_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    spouse_medicalHypertension:
      data?.MQ01?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
        ?.adult_two?.selection_status === true
        ? "Yes"
        : "No",
    spouse_hypertension_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    spouse_hypertension_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child1_medicalHypertension:
      data?.MQ01?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
        ?.child_one?.selection_status === true
        ? "Yes"
        : "No",
    child1_hypertension_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child1_hypertension_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    //2
    child2_medicalHypertension:
      data?.MQ01?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
        ?.child_two?.selection_status === true
        ? "Yes"
        : "No",
    child2_hypertension_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child2_hypertension_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    //3
    child3_medicalHypertension:
      data?.MQ01?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
        ?.child_three?.selection_status === true
        ? "Yes"
        : "No",
    child3_hypertension_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child3_hypertension_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    //4
    child4_medicalHypertension:
      data?.MQ01?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
        ?.child_four?.selection_status === true
        ? "Yes"
        : "No",
    child4_hypertension_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child4_hypertension_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    //
    medicalDiabetesDiseases:
      `${data.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.status}` ===
        "true"
        ? "Yes"
        : "No", //Diabetes/ High blood sugar/Sugar in urine?
    medicalDiabetes:
      data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.adult_one?.selection_status === true
        ? "Yes"
        : "No", // for adult 1
    diabetes_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    diabetes_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    diabetes_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    diabetes_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    diabetes_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    diabetes_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    diabetes_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes , No
    spouse_medicalDiabetes:
      data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.adult_two?.selection_status === true
        ? "Yes"
        : "No", // adult 2
    spouse_diabetes_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    spouse_diabetes_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    spouse_diabetes_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    spouse_diabetes_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    spouse_diabetes_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    spouse_diabetes_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    spouse_diabetes_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes , No
    child1_medicalDiabetes:
      data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.child_one?.selection_status === true
        ? "Yes"
        : "No", // adult 2
    child1_diabetes_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child1_diabetes_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child1_diabetes_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    child1_diabetes_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child1_diabetes_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child1_diabetes_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child1_diabetes_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes , No
    //2
    child2_medicalDiabetes:
      data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.child_two?.selection_status === true
        ? "Yes"
        : "No", // adult 2
    child2_diabetes_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child2_diabetes_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child2_diabetes_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    child2_diabetes_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child2_diabetes_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child2_diabetes_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child2_diabetes_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes , No
    //3
    child3_medicalDiabetes:
      data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.child_three?.selection_status === true
        ? "Yes"
        : "No", // adult 2
    child3_diabetes_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child3_diabetes_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child3_diabetes_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    child3_diabetes_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child3_diabetes_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child3_diabetes_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child3_diabetes_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes , No
    //4
    child4_medicalDiabetes:
      data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.child_four?.selection_status === true
        ? "Yes"
        : "No", // adult 2
    child4_diabetes_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child4_diabetes_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child4_diabetes_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    child4_diabetes_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child4_diabetes_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child4_diabetes_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child4_diabetes_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes , No
    //
    medicalCancerDiseases:
      `${data.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.status}` ===
        "true"
        ? "Yes"
        : "No", //Cancer, Tumour, Growth or Cyst of any kind??
    medicalCancer:
      data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
        ?.adult_one?.selection_status === true
        ? "Yes"
        : "No",
    cancer_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    cancer_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    cancer_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    cancer_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    cancer_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    cancer_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    cancer_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes , No
    spouse_medicalCancer:
      data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
        ?.adult_two?.selection_status === true
        ? "Yes"
        : "No",
    spouse_cancer_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    spouse_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    spouse_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    spouse_cancer_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    spouse_cancer_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    spouse_cancer_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    spouse_cancer_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes , No
    child1_medicalCancer:
      data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
        ?.child_one?.selection_status === true
        ? "Yes"
        : "No",
    child1_cancer_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child1_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child1_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child1_cancer_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child1_cancer_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child1_cancer_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child1_cancer_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes , No

    //2
    child2_medicalCancer:
      data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
        ?.child_two?.selection_status === true
        ? "Yes"
        : "No",
    child2_cancer_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child2_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child2_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child2_cancer_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child2_cancer_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child2_cancer_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child2_cancer_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes , No
    //3
    child3_medicalCancer:
      data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
        ?.child_three?.selection_status === true
        ? "Yes"
        : "No",
    child3_cancer_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child3_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child3_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child3_cancer_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child3_cancer_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child3_cancer_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child3_cancer_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes , No
    //4
    child4_medicalCancer:
      data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
        ?.child_four?.selection_status === true
        ? "Yes"
        : "No",
    child4_cancer_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child4_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child4_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child4_cancer_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child4_cancer_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child4_cancer_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child4_cancer_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes , No
    medicalHeartDiseases:
      `${data.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.status}` ===
        "true"
        ? "Yes"
        : "No", //Chest Pain/ Heart Attack or any other Heart Disease/ Problem?
    medicalHeart:
      data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
        ?.adult_one?.selection_status === true
        ? "Yes"
        : "No",
    heart_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    heart_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    heart_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    heart_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    heart_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    heart_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    heart_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes , No
    spouse_medicalHeart:
      data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
        ?.adult_two?.selection_status === true
        ? "Yes"
        : "No",
    spouse_heart_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    spouse_heart_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    spouse_heart_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    spouse_heart_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    spouse_heart_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    spouse_heart_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    spouse_heart_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes , No
    child1_medicalHeart:
      data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
        ?.child_one?.selection_status === true
        ? "Yes"
        : "No",
    child1_heart_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child1_heart_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child1_heart_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child1_heart_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child1_heart_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child1_heart_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child1_heart_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes , No

    //2
    child2_medicalHeart:
      data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
        ?.child_two?.selection_status === true
        ? "Yes"
        : "No",
    child2_heart_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child2_heart_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child2_heart_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child2_heart_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child2_heart_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child2_heart_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child2_heart_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes , No
    //3
    child3_medicalHeart:
      data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
        ?.child_three?.selection_status === true
        ? "Yes"
        : "No",
    child3_heart_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child3_heart_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child3_heart_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child3_heart_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child3_heart_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child3_heart_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child3_heart_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes , No
    //4
    child4_medicalHeart:
      data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
        ?.child_four?.selection_status === true
        ? "Yes"
        : "No",
    child4_heart_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child4_heart_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child4_heart_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child4_heart_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child4_heart_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child4_heart_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child4_heart_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes , No

    medicalJaundiceDiseases:
      `${data.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.status}` ===
        "true"
        ? "Yes"
        : "No", //Liver or Gall Bladder ailment/Jaundice/Hepatitis B or C?
    medicalJaundice:
      data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
        ?.adult_one?.selection_status === true
        ? "Yes"
        : "No",
    jaundice_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    jaundice_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    jaundice_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    jaundice_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    jaundice_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    jaundice_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    jaundice_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes , No
    spouse_medicalJaundice:
      data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
        ?.adult_two?.selection_status === true
        ? "Yes"
        : "No",
    spouse_jaundice_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    spouse_jaundice_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    spouse_jaundice_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    spouse_jaundice_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    spouse_jaundice_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    spouse_jaundice_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    spouse_jaundice_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes , No
    child1_medicalJaundice:
      data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
        ?.child_one?.selection_status === true
        ? "Yes"
        : "No",
    child1_jaundice_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child1_jaundice_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child1_jaundice_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child1_jaundice_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child1_jaundice_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child1_jaundice_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child1_jaundice_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes , No

    child2_medicalJaundice:
      data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
        ?.child_two?.selection_status === true
        ? "Yes"
        : "No",
    child2_jaundice_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child2_jaundice_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child2_jaundice_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child2_jaundice_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child2_jaundice_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child2_jaundice_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child2_jaundice_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes , No

    ////

    child3_medicalJaundice:
      data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
        ?.child_three?.selection_status === true
        ? "Yes"
        : "No",
    child3_jaundice_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child3_jaundice_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child3_jaundice_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child3_jaundice_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child3_jaundice_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child3_jaundice_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child3_jaundice_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes , No

    //
    child4_medicalJaundice:
      data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
        ?.child_four?.selection_status === true
        ? "Yes"
        : "No",
    child4_jaundice_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child4_jaundice_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child4_jaundice_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child4_jaundice_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child4_jaundice_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child4_jaundice_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child4_jaundice_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes , No
    //

    medicalKidneyDiseases:
      `${data.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.status}` ===
        "true"
        ? "Yes"
        : "No", // Kidney ailment or Diseases of Reproductive organs?
    medicalKidney:
      `${data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data?.adult_one?.selection_status}` ===
        "true"
        ? "Yes"
        : "No",
    kidney_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    kidney_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    kidney_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    kidney_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    kidney_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    kidney_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    kidney_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes , No
    spouse_medicalKidney:
      `${data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data?.adult_one?.selection_status}` ===
        "true"
        ? "Yes"
        : "No",
    spouse_kidney_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    spouse_kidney_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    spouse_kidney_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    spouse_kidney_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    spouse_kidney_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    spouse_kidney_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    spouse_kidney_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes , No
    child1_medicalKidney:
      data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_one?.selection_status === true
        ? "Yes"
        : "No",
    child1_kidney_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child1_kidney_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child1_kidney_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    child1_kidney_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child1_kidney_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child1_kidney_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child1_kidney_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes , No

    //2
    child2_medicalKidney:
      data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_two?.selection_status === true
        ? "Yes"
        : "No",
    child2_kidney_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child2_kidney_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child2_kidney_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    child2_kidney_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child2_kidney_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child2_kidney_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child2_kidney_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes , No
    //3
    child3_medicalKidney:
      data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_three?.selection_status === true
        ? "Yes"
        : "No",
    child3_kidney_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child3_kidney_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child3_kidney_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    child3_kidney_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child3_kidney_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child3_kidney_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child3_kidney_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes , No
    //4
    child4_medicalKidney:
      data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_four?.selection_status === true
        ? "Yes"
        : "No",
    child4_kidney_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child4_kidney_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child4_kidney_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    child4_kidney_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child4_kidney_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child4_kidney_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child4_kidney_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes , No

    medicalAsthmaDiseases:
      `${data.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.status}` ===
        "true"
        ? "Yes"
        : "No", // Tuberculosis/ Asthma or any other Lung disorder?
    medicalAsthma:
      data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
        ?.adult_one?.selection_status === true
        ? "Yes"
        : "No",
    asthma_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    asthma_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    asthma_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    asthma_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    asthma_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    asthma_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    asthma_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes , No
    sposue_medicalAsthma:
      data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
        ?.adult_two?.selection_status === true
        ? "Yes"
        : "No",
    spouse_asthma_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    spouse_asthma_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    spouse_asthma_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    spouse_asthma_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    spouse_asthma_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    spouse_asthma_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    spouse_asthma_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes , No
    child1_medicalAsthma:
      data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
        ?.child_one?.selection_status === true
        ? "Yes"
        : "No",
    child1_asthma_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child1_asthma_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child1_asthma_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    child1_asthma_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child1_asthma_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child1_asthma_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child1_asthma_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes , No
    //2
    child2_medicalAsthma:
      data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
        ?.child_two?.selection_status === true
        ? "Yes"
        : "No",
    child2_asthma_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child2_asthma_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child2_asthma_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    child2_asthma_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child2_asthma_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child2_asthma_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child2_asthma_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes , No
    //3
    child3_medicalAsthma:
      data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
        ?.child_three?.selection_status === true
        ? "Yes"
        : "No",
    child3_asthma_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child3_asthma_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child3_asthma_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    child3_asthma_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child3_asthma_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child3_asthma_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child3_asthma_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes , No
    //4
    child4_medicalAsthma:
      data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
        ?.child_four?.selection_status === true
        ? "Yes"
        : "No",
    child4_asthma_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child4_asthma_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child4_asthma_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    child4_asthma_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child4_asthma_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child4_asthma_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child4_asthma_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes , No

    medicalUlcerDiseases:
      `${data.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.status}` ===
        "true"
        ? "Yes"
        : "No", // Ulcer (Stomach/ Duodenal), or any ailment of Digestive System?
    medicalUlcer:
      data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
        ?.adult_one?.selection_status === true
        ? "Yes"
        : "No",
    ulcer_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    ulcer_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    ulcer_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    ulcer_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    ulcer_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    ulcer_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    ulcer_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes , No
    spouse_medicalUlcer:
      data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
        ?.adult_two?.selection_status === true
        ? "Yes"
        : "No",
    spouse_ulcer_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    spouse_ulcer_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    spouse_ulcer_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    spouse_ulcer_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    spouse_ulcer_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    spouse_ulcer_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    spouse_ulcer_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes , No
    //child1
    child1_medicalUlcer:
      data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
        ?.child_one?.selection_status === true
        ? "Yes"
        : "No",
    child1_ulcer_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child1_ulcer_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child1_ulcer_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    child1_ulcer_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child1_ulcer_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child1_ulcer_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child1_ulcer_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes , No

    //child2
    child2_medicalUlcer:
      data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
        ?.child_two?.selection_status === true
        ? "Yes"
        : "No",
    child2_ulcer_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child2_ulcer_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child2_ulcer_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    child2_ulcer_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child2_ulcer_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child2_ulcer_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child2_ulcer_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes , No

    //child3

    child3_medicalUlcer:
      data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
        ?.child_three?.selection_status === true
        ? "Yes"
        : "No",
    child3_ulcer_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child3_ulcer_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child3_ulcer_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    child3_ulcer_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child3_ulcer_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child3_ulcer_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child3_ulcer_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes , No

    //child4
    child4_medicalUlcer:
      data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
        ?.child_three?.selection_status === true
        ? "Yes"
        : "No",
    child4_ulcer_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child4_ulcer_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child4_ulcer_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    child4_ulcer_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child4_ulcer_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child4_ulcer_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child4_ulcer_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes , No
    //
    medicalHaemophiliaDiseases:
      `${data.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.status}` ===
        "true"
        ? "Yes"
        : "No", //Any Blood disorder (example Anaemia, Haemophilia, Thalassaemia) or any genetic disorder?
    medicalHaemophilia:
      data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
        ?.adult_one?.selection_status === true
        ? "Yes"
        : "No",
    haemophilia_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    haemophilia_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    haemophilia_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    haemophilia_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    haemophilia_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    haemophilia_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    haemophilia_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes
    spouse_medicalHaemophilia:
      data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
        ?.adult_two?.selection_status === true
        ? "Yes"
        : "No",
    spouse_haemophilia_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    spouse_haemophilia_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    spouse_haemophilia_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    spouse_haemophilia_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    spouse_haemophilia_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    spouse_haemophilia_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    spouse_haemophilia_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes ,
    child1_medicalHaemophilia:
      data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
        ?.child_one?.selection_status === true
        ? "Yes"
        : "No",
    child1_haemophilia_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child1_haemophilia_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child1_haemophilia_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    child1_haemophilia_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child1_haemophilia_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child1_haemophilia_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child1_haemophilia_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes ,
    //2
    child2_medicalHaemophilia:
      data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
        ?.child_two?.selection_status === true
        ? "Yes"
        : "No",
    child2_haemophilia_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child2_haemophilia_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child2_haemophilia_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    child2_haemophilia_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child2_haemophilia_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child2_haemophilia_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child2_haemophilia_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes ,
    //3
    child3_medicalHaemophilia:
      data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
        ?.child_three?.selection_status === true
        ? "Yes"
        : "No",
    child3_haemophilia_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child3_haemophilia_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child3_haemophilia_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    child3_haemophilia_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child3_haemophilia_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child3_haemophilia_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child3_haemophilia_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes ,
    //4
    child4_medicalHaemophilia:
      data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
        ?.child_four?.selection_status === true
        ? "Yes"
        : "No",
    child4_haemophilia_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child4_haemophilia_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child4_haemophilia_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    child4_haemophilia_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child4_haemophilia_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child4_haemophilia_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child4_haemophilia_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes ,

    medicalHIVDiseases:
      `${data.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.status}` ===
        "true"
        ? "Yes"
        : "No", // HIV Infection/AIDS or Positive test for HIV?
    medicalHIV:
      data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.child_one?.selection_status === true
        ? "Yes"
        : "No",
    hiv_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    hiv_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    hiv_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    hiv_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    hiv_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    hiv_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    hiv_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes ,
    spouse_medicalHIV:
      data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.adult_two?.selection_status === true
        ? "Yes"
        : "No",
    spouse_hiv_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    spouse_hiv_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    spouse_hiv_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    spouse_hiv_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    spouse_hiv_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    spouse_hiv_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    spouse_hiv_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F7"]?.value?.value, //
    child1_medicalHIV:
      data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.child_one?.selection_status === true
        ? "Yes"
        : "No",
    child1_hiv_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child1_hiv_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child1_hiv_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    child1_hiv_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child1_hiv_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child1_hiv_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child1_hiv_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes , Noemophilia_opted'] : 'No';
    //2
    child2_medicalHIV:
      data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.child_two?.selection_status === true
        ? "Yes"
        : "No",
    child2_hiv_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child2_hiv_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child2_hiv_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    child2_hiv_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child2_hiv_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child2_hiv_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child2_hiv_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes , Noemophilia_opted'] : 'No';
    //3
    child3_medicalHIV:
      data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.child_three?.selection_status === true
        ? "Yes"
        : "No",
    child3_hiv_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child3_hiv_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child3_hiv_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    child3_hiv_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child3_hiv_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child3_hiv_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child3_hiv_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes , Noemophilia_opted'] : 'No';
    //4
    child4_medicalHIV:
      data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.child_four?.selection_status === true
        ? "Yes"
        : "No",
    child4_hiv_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child4_hiv_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child4_hiv_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    child4_hiv_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child4_hiv_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child4_hiv_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child4_hiv_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes , Noemophilia_opted'] : 'No';

    medicalMentalDiseases:
      `${data.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.status}` ===
        "true"
        ? "Yes"
        : "No", // Nervous, Psychiatric or Mental or Sleep disorder?
    medicalMental:
      data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.adult_one?.selection_status === true
        ? "Yes"
        : "No",
    mental_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    mental_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    mental_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    mental_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    mental_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    mental_hiv_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    mental_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes ,
    spouse_medicalMental:
      data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.adult_two?.selection_status === true
        ? "Yes"
        : "No",
    spouse_mental_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    spouse_mental_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    spouse_mental_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    spouse_mental_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    spouse_mental_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    spouse_mental_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    spouse_mental_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes ,
    child1_medicalMental:
      data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.child_one?.selection_status === true
        ? "Yes"
        : "No",
    child1_mental_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child1_mental_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child1_mental_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    child1_mental_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child1_mental_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child1_mental_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child1_mental_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes ,
    //
    //child2
    child2_medicalMental:
      data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.child_two?.selection_status === true
        ? "Yes"
        : "No",
    child2_mental_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child2_mental_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child2_mental_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    child2_mental_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child2_mental_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child2_mental_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child2_mental_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes ,
    // child3
    child3_medicalMental:
      data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.child_three?.selection_status === true
        ? "Yes"
        : "No",
    child3_mental_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child3_mental_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child3_mental_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    child3_mental_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child3_mental_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child3_mental_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child3_mental_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes ,
    //child4

    child4_medicalMental:
      data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.child_four?.selection_status === true
        ? "Yes"
        : "No",
    child4_mental_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child4_mental_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child4_mental_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    child4_mental_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child4_mental_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child4_mental_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child4_mental_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes ,
    //
    medicalStrokeDiseases:
      `${data.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.status}` ===
        "true"
        ? "Yes"
        : "No", // Stroke/ Paralysis/ Epilepsy (Fits) or any other Nervous disorder (Brain / Spinal Cord etc.)?
    medicalStroke:
      data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
        ?.adult_one?.selection_status === true
        ? "Yes"
        : "No",
    stroke_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    stroke_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    stroke_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    stroke_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    stroke_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    stroke_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    stroke_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes ,
    spouse_medicalStroke:
      data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
        ?.adult_two?.selection_status === true
        ? "Yes"
        : "No",
    spouse_stroke_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    spouse_stroke_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    spouse_stroke_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    spouse_stroke_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    spouse_stroke_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    spouse_stroke_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    spouse_stroke_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes ,
    child1_medicalStroke:
      data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
        ?.child_one?.selection_status === true
        ? "Yes"
        : "No",
    child1_stroke_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child1_stroke_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child1_stroke_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    child1_stroke_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child1_stroke_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child1_stroke_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child1_stroke_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes ,

    //2
    child2_medicalStroke:
      data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
        ?.child_two?.selection_status === true
        ? "Yes"
        : "No",
    child2_stroke_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child2_stroke_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child2_stroke_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    child2_stroke_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child2_stroke_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child2_stroke_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child2_stroke_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes ,
    //3
    child3_medicalStroke:
      data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
        ?.child_three?.selection_status === true
        ? "Yes"
        : "No",
    child3_stroke_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child3_stroke_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child3_stroke_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    child3_stroke_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child3_stroke_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child3_stroke_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child3_stroke_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes ,
    //4
    child4_medicalStroke:
      data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
        ?.child_four?.selection_status === true
        ? "Yes"
        : "No",
    child4_stroke_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child4_stroke_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child4_stroke_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    child4_stroke_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child4_stroke_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child4_stroke_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child4_stroke_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes ,

    medicalThyroidDiseases:
      `${data.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.status}` ===
        "true"
        ? "Yes"
        : "No", //. Abnormal Thyroid Function/ Goiter or any Endocrine organ disorders?
    medicalThyroid:
      data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.adult_one?.selection_status === true
        ? "Yes"
        : "No",
    thyroid_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    thyroid_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    thyroid_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    thyroid_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    thyroid_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    thyroid_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    thyroid_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // ;
    spouse_medicalThyroid:
      data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.adult_two?.selection_status === true
        ? "Yes"
        : "No",
    spouse_thyroid_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    spouse_thyroid_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    spouse_thyroid_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    spouse_thyroid_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    spouse_thyroid_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    spouse_thyroid_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    spouse_thyroid_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes ,
    child1_medicalThyroid:
      data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.child_one?.selection_status === true
        ? "Yes"
        : "No",
    child1_thyroid_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child1_thyroid_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child1_thyroid_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    child1_thyroid_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child1_thyroid_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child1_thyroid_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child1_thyroid_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes
    //2
    child2_medicalThyroid:
      data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.child_two?.selection_status === true
        ? "Yes"
        : "No",
    child2_thyroid_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child2_thyroid_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child2_thyroid_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    child2_thyroid_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child2_thyroid_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child2_thyroid_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child2_thyroid_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes
    //3
    child3_medicalThyroid:
      data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.child_three?.selection_status === true
        ? "Yes"
        : "No",
    child3_thyroid_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child3_thyroid_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child3_thyroid_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    child3_thyroid_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child3_thyroid_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child3_thyroid_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child3_thyroid_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes
    //4
    child4_medicalThyroid:
      data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.child_four?.selection_status === true
        ? "Yes"
        : "No",
    child4_thyroid_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child4_thyroid_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child4_thyroid_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    child4_thyroid_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child4_thyroid_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child4_thyroid_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child4_thyroid_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // Yes

    medicalThroatDiseases:
      `${data.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.status}` ===
        "true"
        ? "Yes"
        : "No", // Eye or vision disorders/ Ear/ Nose or Throat diseases?
    medicalThroat:
      data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
        ?.adult_one?.selection_status === true
        ? "Yes"
        : "No",
    throat_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    throat_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    throat_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    throat_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    throat_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    throat_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    throat_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // ;
    spouse_medicalThroat:
      data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
        ?.adult_two?.selection_status === true
        ? "Yes"
        : "No",
    spouse_throat_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    spouse_throat_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    spouse_throat_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    spouse_throat_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    spouse_throat_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    spouse_throat_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    spouse_throat_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // ;
    child1_medicalThroat:
      data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
        ?.child_one?.selection_status === true
        ? "Yes"
        : "No",
    child1_throat_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child1_throat_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child1_throat_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    child1_throat_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child1_throat_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child1_throat_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child1_throat_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // ;
    //2
    child2_medicalThroat:
      data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
        ?.child_two?.selection_status === true
        ? "Yes"
        : "No",
    child2_throat_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child2_throat_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child2_throat_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    child2_throat_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child2_throat_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child2_throat_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child2_throat_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // ;
    //3
    child3_medicalThroat:
      data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
        ?.child_three?.selection_status === true
        ? "Yes"
        : "No",
    child3_throat_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child3_throat_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child3_throat_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    child3_throat_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child3_throat_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child3_throat_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child3_throat_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // ;
    //4
    child4_medicalThroat:
      data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
        ?.child_four?.selection_status === true
        ? "Yes"
        : "No",
    child4_throat_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child4_throat_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child4_throat_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    child4_throat_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child4_throat_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child4_throat_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child4_throat_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // ;

    medicalJointDiseases:
      `${data.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.status}` ===
        "true"
        ? "Yes"
        : "No", // Arthritis, Spondylitis, Fracture or any other disorder of Muscle Bone/ Joint/ Ligament/ Cartilage?
    medicalJoint:
      data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
        ?.adult_one?.selection_status === true
        ? "Yes"
        : "No",
    joint_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    joint_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    joint_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    joint_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    joint_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    joint_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    joint_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // ;
    spouse_medicalJoint:
      data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
        ?.adult_two?.selection_status === true
        ? "Yes"
        : "No",
    spouse_joint_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    spouse_joint_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    spouse_joint_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    spouse_joint_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    spouse_joint_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    spouse_joint_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    spouse_joint_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // ;
    child1_medicalJoint:
      data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
        ?.child_one?.selection_status === true
        ? "Yes"
        : "No",
    child1_joint_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child1_joint_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child1_joint_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    child1_joint_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child1_joint_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child1_joint_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child1_joint_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // ;
    //2
    child2_medicalJoint:
      data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
        ?.child_two?.selection_status === true
        ? "Yes"
        : "No",
    child2_joint_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child2_joint_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child2_joint_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    child2_joint_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child2_joint_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child2_joint_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child2_joint_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // ;
    //3
    child3_medicalJoint:
      data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
        ?.child_three?.selection_status === true
        ? "Yes"
        : "No",
    child3_joint_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child3_joint_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child3_joint_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    child3_joint_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child3_joint_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child3_joint_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child3_joint_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // ;
    //4
    child4_medicalJoint:
      data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
        ?.child_four?.selection_status === true
        ? "Yes"
        : "No",
    child4_joint_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child4_joint_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child4_joint_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    child4_joint_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child4_joint_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child4_joint_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child4_joint_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // ;

    anyMedicalDiseases:
      `${data.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.status}` ===
        "true"
        ? "Yes"
        : "No", // Any other disease/condition not mentioned above?
    anyMedical:
      data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
        ?.adult_one?.selection_status === true
        ? "Yes"
        : "No",
    any_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    any_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    any_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    any_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    any_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    any_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    any_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // ;
    spouse_anyMedical:
      data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
        ?.adult_two?.selection_status === true
        ? "Yes"
        : "No",
    spouse_any_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    spouse_any_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    spouse_any_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    spouse_any_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    spouse_any_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    spouse_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    spouse_any_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // ;
    child1_anyMedical:
      data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
        ?.child_one?.selection_status === true
        ? "Yes"
        : "No",
    child1_any_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child1_any_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child1_any_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    child1_any_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child1_any_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child1_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child1_any_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // ;

    //2
    child2_anyMedical:
      data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
        ?.child_two?.selection_status === true
        ? "Yes"
        : "No",
    child2_any_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child2_any_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child2_any_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    child2_any_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child2_any_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child2_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child2_any_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // ;
    //3
    child3_anyMedical:
      data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
        ?.child_three?.selection_status === true
        ? "Yes"
        : "No",
    child3_any_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child3_any_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child3_any_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    child3_any_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child3_any_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child3_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child3_any_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // ;
    //4
    child4_anyMedical:
      data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
        ?.child_four?.selection_status === true
        ? "Yes"
        : "No",
    child4_any_diagnosis:
      data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child4_any_diagnosis_date:
      data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child4_any_last_consultation_date:
      data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    child4_any_current_status:
      data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child4_any_line_management:
      data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child4_treatment:
      data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child4_any_opted:
      data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F7"]?.value?.value, // ;

   
    medicalMedicineDiseases:
      `${data.MQ07?.main_question?.status}` ===
      "true"
        ? "Yes"
        : "No", // Takes medicines regularly?
    medicalMedicine:
      data?.MQ07?.main_question?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    medicine_diagnosis:
      data?.MQ07?.main_question?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    medicine_last_consultation_date:
      data?.MQ07?.main_question?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    medicine_current_status:
      data?.MQ07?.main_question?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    medicine_line_management:
      data?.MQ07?.main_question?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    medicine_opted:
      data?.MQ07?.main_question?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value, //
    spouse_medicalMedicine:
      data?.MQ07?.main_question?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    spouse_medicine_diagnosis:
      data?.MQ07?.main_question?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    spouse_medicine_last_consultation_date:
      data?.MQ07?.main_question?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    spouse_medicine_current_status:
      data?.MQ07?.main_question?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    spouse_medicine_line_management:
      data?.MQ07?.main_question?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    spouse_medicine_opted:
      data?.MQ07?.main_question?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F1"]?.value?.value, //
    child1_medicalMedicine:
      data?.MQ07?.main_question?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child1_medicine_diagnosis:
      data?.MQ07?.main_question?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child1_medicine_last_consultation_date:
      data?.MQ07?.main_question?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child1_medicine_current_status:
      data?.MQ07?.main_question?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child1_medicine_line_management:
      data?.MQ07?.main_question?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child1_medicine_opted:
      data?.MQ07?.main_question?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value, // Yes,No
    //2
    child2_medicalMedicine:
      data?.MQ07?.main_question?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child2_medicine_diagnosis:
      data?.MQ07?.main_question?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child2_medicine_last_consultation_date:
      data?.MQ07?.main_question?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child2_medicine_current_status:
      data?.MQ07?.main_question?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child2_medicine_line_management:
      data?.MQ07?.main_question?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child2_medicine_opted:
      data?.MQ07?.main_question?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F1"]?.value?.value, // Yes,No
    //3
    child3_medicalMedicine:
      data?.MQ07?.main_question?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child3_medicine_diagnosis:
      data?.MQ07?.main_question?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child3_medicine_last_consultation_date:
      data?.MQ07?.main_question?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child3_medicine_current_status:
      data?.MQ07?.main_question?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child3_medicine_line_management:
      data?.MQ07?.main_question?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child3_medicine_opted:
      data?.MQ07?.main_question?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F1"]?.value?.value, // Yes,No
    //4
    child4_medicalMedicine:
      data?.MQ07?.main_question?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child4_medicine_diagnosis:
      data?.MQ07?.main_question?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child4_medicine_last_consultation_date:
      data?.MQ07?.main_question?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child4_medicine_current_status:
      data?.MQ07?.main_question?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F4"]?.value?.value, // CURED,NOT TREATED,ONGOING
    child4_medicine_line_management:
      data?.MQ07?.main_question?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F5"]?.value?.value, //1.MEDICAL,SURGICAL
    child4_medicine_opted:
      data?.MQ07?.main_question?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F1"]?.value?.value, // Yes,No
    //
    investigationMedicineDiseases:
      `${data.MQ08?.main_question?.status}` ===
      "true"
        ? "Yes"
        : "No", //4. Has been advised investigation or further tests?
    investigationMedicine:
      data?.MQ08?.main_question?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    test_date:
      data?.MQ08?.main_question?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    test_type: "sfdf",
    test_finding: "hdfg",
    test_opted:
      data?.MQ08?.main_question?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    spouse_investigationMedicine:
      data?.MQ08?.main_question?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    spouse_test_date:
      data?.MQ08?.main_question?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    spouse_test_type: "sfdf",
    spouse_test_finding: "hdfg",
    spouse_test_opted:
      data?.MQ08?.main_question?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child1_investigationMedicine:
      data?.MQ08?.main_question?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child1_test_date:
      data?.MQ08?.main_question?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child1_test_type: "sfdf",
    child1_test_finding: "hdfg",
    child1_test_opted:
      data?.MQ08?.main_question?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    
   
    medicalPregnantDiseases: data?.MQ04?.main_question?.status
      ? "Yes"
      : "No", //6. Is Pregnant?
    medicalPregnant: data?.MQ04?.main_question?.member_data?.adult_one
      ?.selection_status
      ? "Yes"
      : "No", //
    pregnant_delivery_date:
      data?.MQ04?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    pregnant_opted:
      data?.MQ04?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    spouse_medicalPregnant: data?.MQ04?.main_question?.member_data
      ?.adult_two?.selection_status
      ? "Yes"
      : "No",
    spouse_pregnant_delivery_date:
      data?.MQ04?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    spouse_pregnant_opted:
      data?.MQ04?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child1_medicalPregnant:
      `${data?.MQ04?.main_question?.member_data?.child_one?.selection_status}` ===
        "true"
        ? "Yes"
        : "No",
    child1_pregnant_delivery_date:
      data?.MQ04?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child1_pregnant_opted:
      data?.MQ04?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    //2
    child2_medicalPregnant:
      `${data?.MQ04?.main_question?.member_data?.child_two?.selection_status}` ===
        "true"
        ? "Yes"
        : "No",
    child2_pregnant_delivery_date:
      data?.MQ04?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child2_pregnant_opted:
      data?.MQ04?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    //3
    child3_medicalPregnant:
      `${data?.MQ04?.main_question?.member_data?.child_three?.selection_status}` ===
        "true"
        ? "Yes"
        : "No",
    child3_pregnant_delivery_date:
      data?.MQ04?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child3_pregnant_opted:
      data?.MQ04?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    //4
    child4_medicalPregnant:
      `${data?.MQ04?.main_question?.member_data?.child_four?.selection_status}` ===
        "true"
        ? "Yes"
        : "No",
    child4_pregnant_delivery_date:
      data?.MQ04?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child4_pregnant_opted:
      data?.MQ04?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,

    //None of the above
    medicalNoneDiseases: data?.MQ05?.main_question?.status ? "Yes" : "No",
    medicalNone:
      `${data?.MQ05?.main_question?.member_data?.adult_one?.selection_status}` ===
        "true"
        ? "Yes"
        : "No",
    none_remarks:
      data?.MQ05?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    none_opted:
      data?.MQ05?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    spouse_medicalNone:
      `${data?.MQ05?.main_question?.member_data?.adult_two?.selection_status}` ===
        "true"
        ? "Yes"
        : "No",
    spouse_none_remarks:
      data?.MQ05?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    spouse_none_opted:
      data?.MQ05?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child1_medicalNone:
      `${data?.MQ04?.main_question?.member_data?.child_one?.selection_status}` ===
        "true"
        ? "Yes"
        : "No",
    child1_none_remarks:
      data?.MQ05?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child1_none_opted:
      data?.MQ05?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    //2
    child2_medicalNone:
      `${data?.MQ04?.main_question?.member_data?.child_two?.selection_status}` ===
        "true"
        ? "Yes"
        : "No",
    child2_none_remarks:
      data?.MQ05?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child2_none_opted:
      data?.MQ05?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    //3
    child3_medicalNone:
      `${data?.MQ04?.main_question?.member_data?.child_three?.selection_status}` ===
        "true"
        ? "Yes"
        : "No",
    child3_none_remarks:
      data?.MQ05?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child3_none_opted:
      data?.MQ05?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    //4
    child4_medicalNone:
      `${data?.MQ04?.main_question?.member_data?.child_four?.selection_status}` ===
        "true"
        ? "Yes"
        : "No",
    child4_none_remarks:
      data?.MQ05?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child4_none_opted:
      data?.MQ05?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    medical_info: "Yes",
    ...Cigarette(data),
    ...Bidi(data),
    ...Tobacco(data),
    ...Alcohol(data),
    ...Gutka(data),
    ...Drugs(data),
    ...PlannedSurgery(data),
    ...HospitalizedPast(data)
  };
};
