import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Navbar from "../../../../Component/Navbar/Navbar";
import TermLeftSidebar from "../../../../Component/Term/TermLeftSidebar/TermLeftSidebar";
import ProposalBasicDetailConatiner from "../../../../Container/Term/Tata/ProposalBasicDetailConatiner";
import MTermNavbar from "../../../../Component/Term/MTermNavbar/MTermNavbar";

const TATARoutes = () => {
  const isMobile = useIsMobile();
  return (
    <>
      {isMobile ? (
        <Box className="proposalWrapper_m">
          <MTermNavbar />
          <Grid container spacing={3} className="pb-0">
            <Grid xs className="proposalContentSection">
              <ProposalBasicDetailConatiner />
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box className="proposalWrapper">
          <Navbar />
          <Grid container spacing={3} className="pb-0">
            <TermLeftSidebar />
            <Grid xs className="proposalContentSection">
              <ProposalBasicDetailConatiner />
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default TATARoutes;
