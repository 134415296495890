import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ProposerDetails from "../../../Page/Desktop/Term/ShortPay/MaxLife/ProposerDetails";
import MProposerDetails from "../../../Page/Mobile/Term/MaxLife/MProposerDetails";
import { TERM_ROUTES } from "../../../Router/Path/TermRoutes";
import { TERM_SERVICES } from "../../../Services/Term/TermServices";
import { TermSlice } from "../../../Store/Slice_Reducer/Term/TermSlice";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import {
  calculateAge,
  FORMAT_DATE_DD_MM_YYYY,
  isEmpty,
  validateAadharNumber,
  validateEmail,
  validateFullName,
  validateMobileNumber,
  valueExistsInObjects,
} from "../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { TDropdown } from "../../../types/Common/TDropdown";
import { TTermProposerData } from "../../../types/TTermSlice";
import { TRidersBenefitsResponse } from "../../../types/Term/TRidersBenifits";
import { CLIENTS } from "../../../URLCollection/Clients";
import { MaxLifeNegativePincode } from "../../../SupportingFiles/MaxlifeNegtivePincode";

const ProposerDetailsContainer = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [recRiders, setRecRiders] = useState<TRidersBenefitsResponse>({
    ...TermSlice.getInitialState().RIDERS_BENEFITS.riders_benefits_response,
  });
  const [loader, setLoader] = useState<boolean>(false);
  const { Term } = useAppSelector((state) => state);
  const {
    SELECTED_QUOTE_DATA,
    DROPDOWN_DATA,
    ADD_FORM_RESPONSE,
    PROPOSER_DATA,
    QUOTE_FIELDS_DATA,
    ADD_FORM,
    LOADER,
    RIDERS_BENEFITS,
  } = useAppSelector((state) => state.Term);
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();
  const state = useAppSelector((state) => state);
  const [proposerDetails, setProposerDetails] =
    useState<TTermProposerData>(PROPOSER_DATA);

  const product_code = { SSP: "LTM050", STEP: "LTM052" };
  useEffect(() => {
    if (proposerDetails.education.value === "01") {
      let data: TDropdown[] = [
        {
          value: "07",
          label: "Salaried",
        },
        {
          value: "08",
          label: "Self-employed",
        },
      ];

      dispatch(
        TermSlice.actions.BULK_UPDATE({
          DROPDOWN_DATA: {
            ...Term.DROPDOWN_DATA,
            OCCUPATION_DATA: data,
          },
        })
      );
    } else if (
      (proposerDetails.education.value === "03" ||
        proposerDetails.education.value === "08") &&
      proposerDetails.gender.value === "F" &&
      calculateAge(proposerDetails.dob.value) >= 18 &&
      calculateAge(proposerDetails.dob.value) <= 50
    ) {
      let data: TDropdown[] = [
        {
          value: "07",
          label: "Salaried",
        },
        {
          value: "08",
          label: "Self-employed",
        },
      ];

      if (
        SELECTED_QUOTE_DATA.productDetails.product_code !== product_code.STEP
      ) {
        data.push({
          value: "01",
          label: "HouseWife",
        });
      }

      dispatch(
        TermSlice.actions.BULK_UPDATE({
          DROPDOWN_DATA: {
            ...Term.DROPDOWN_DATA,
            OCCUPATION_DATA: data,
          },
        })
      );
    } else if (proposerDetails.gender.value === "F") {
      let data: TDropdown[] = [
        {
          value: "07",
          label: "Salaried",
        },
        {
          value: "08",
          label: "Self-employed",
        },
        {
          value: "01",
          label: "HouseWife",
        },
      ];
      dispatch(
        TermSlice.actions.BULK_UPDATE({
          DROPDOWN_DATA: {
            ...Term.DROPDOWN_DATA,
            OCCUPATION_DATA: data,
          },
        })
      );
    }
  }, [proposerDetails.education.value]);

  useEffect(() => {
    if (proposerDetails?.occupation?.value === "01") {
      let data = DROPDOWN_DATA.EDUCATION_MASTER;
      const valuesToRemove = ["01"];

      const filteredArray = data.filter(
        (item) => !valuesToRemove.includes(item.value)
      );
      // setProposerDetails((prev) => ({
      //   ...prev,
      //   education: {
      //     value: "03",
      //     warning: false,
      //   },
      // }));
      dispatch(
        TermSlice.actions.BULK_UPDATE({
          DROPDOWN_DATA: {
            ...Term.DROPDOWN_DATA,
            EDUCATION_MASTER: filteredArray,
          },
        })
      );
    } else {
      let data: TDropdown[] = [
        {
          value: "01",
          label: "10th Pass",
        },
        {
          value: "08",
          label: "12th Pass",
        },
        {
          value: "03",
          label: "Graduate & Above",
        },
      ];

      dispatch(
        TermSlice.actions.BULK_UPDATE({
          DROPDOWN_DATA: {
            ...Term.DROPDOWN_DATA,
            EDUCATION_MASTER: data,
          },
        })
      );
    }
  }, [proposerDetails.occupation]);

  useEffect(() => {
    if (
      !isEmpty(proposerDetails.education.value) &&
      !isEmpty(proposerDetails.occupation.value) &&
      !isEmpty(proposerDetails.annual_income.value) &&
      !isEmpty(proposerDetails.sum_assured.value)
    ) {
      dispatch(
        TermSlice.actions.BULK_UPDATE({
          SELECTED_QUOTE_DATA: {
            ...Term.SELECTED_QUOTE_DATA,
            sumAssured: proposerDetails.sum_assured.value,
          },
        })
      );
    }
  }, [
    proposerDetails.education,
    proposerDetails.occupation,
    proposerDetails.annual_income,
    proposerDetails.sum_assured,
  ]);

  const occupation_data_local = {
    salaried: "07",
    self_employed: "08",
    house_wife: "01",
  };
  const education_data_local = {
    "10th Pass": "01",
    "12th Pass": "08",
    "Graduate & Above": "03",
  };

  const validate_annual_income = (value: string) => {
    const annual_income = parseInt(value);
    if (isNaN(annual_income)) {
      return false;
    }
    const occupation = proposerDetails.occupation.value;
    const education =
      proposerDetails?.education?.value?.value === undefined
        ? proposerDetails?.education?.value
        : proposerDetails?.education?.value?.value;

    if (SELECTED_QUOTE_DATA.productDetails.product_code === product_code.STEP) {
      if (
        occupation === occupation_data_local.self_employed &&
        annual_income >= 300000
      ) {
        return true;
      } else if (
        occupation !== occupation_data_local.self_employed &&
        annual_income < 1000000
      ) {
        return false;
      }
    }

    const age = calculateAge(`${proposerDetails.dob.value}`);

    if (education === "03") {
      if (occupation === occupation_data_local.salaried) {
        if (age <= 40) {
          if (annual_income >= 300000) {
            return true;
          } else {
            return false;
          }
        } else if (age > 40) {
          if (annual_income >= 500000) {
            return true;
          } else {
            return false;
          }
        }
      } else if (
        occupation === occupation_data_local.self_employed ||
        occupation === occupation_data_local.house_wife
      ) {
        if (annual_income >= 500000) {
          return true;
        } else {
          return false;
        }
      }
    } else if (education === "08") {
      if (annual_income >= 500000) {
        return true;
      } else {
        return false;
      }
    } else if (education === "01") {
      if (annual_income >= 1000000) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  useEffect(() => {
    if (DROPDOWN_DATA.MAX_SUM_ASSURED.length > 0) {
      setProposerDetails((prev) => ({
        ...prev,
        sum_assured: {
          value: valueExistsInObjects(
            prev.sum_assured.value,
            DROPDOWN_DATA.MAX_SUM_ASSURED
          )
            ? prev.sum_assured.value
            : "",
          warning: false,
        },
      }));
    }
  }, [
    proposerDetails?.education?.value,
    proposerDetails?.occupation?.value,
    DROPDOWN_DATA.MAX_SUM_ASSURED,
  ]);

  useEffect(() => {
    let min_sum_assured = 25;
    let max_sum_assured = 5000000;
    const age = calculateAge(
      FORMAT_DATE_DD_MM_YYYY(`${proposerDetails?.dob?.value}`)
    );
    const annual_income = parseInt(
      isEmpty(proposerDetails.annual_income.value)
        ? state.Term.PROPOSER_DATA.annual_income.value
        : proposerDetails.annual_income.value
    );

    let data: TDropdown[] = [];

    if (annual_income >= 500000) {
      if (age >= 18 && age <= 35) {
        max_sum_assured = annual_income * 25;
      } else if (age >= 36 && age <= 40) {
        max_sum_assured = annual_income * 20;
      } else if (age >= 41 && age <= 45) {
        max_sum_assured = annual_income * 20;
      } else if (age >= 46 && age <= 50) {
        max_sum_assured = annual_income * 15;
      } else if (age >= 51 && age <= 60) {
        max_sum_assured = annual_income * 10;
      }

      if (annual_income >= 1000000) {
        if (age <= 45) {
          max_sum_assured =
            max_sum_assured > 350000000 ? 350000000 : max_sum_assured;
        } else {
          max_sum_assured =
            max_sum_assured > 150000000 ? 150000000 : max_sum_assured;
        }
      }
    } else {
      if (age >= 18 && age <= 35) {
        max_sum_assured = annual_income * 17;
      } else if (age >= 36 && age <= 40) {
        max_sum_assured = annual_income * 12;
      }
    }

    max_sum_assured = max_sum_assured / 100000;

    if (max_sum_assured > 50 && annual_income < 500000) max_sum_assured = 50;

    if (
      proposerDetails.occupation.value === "01" &&
      proposerDetails.education.value === "03"
    ) {
      if (annual_income >= 1000000) {
        if (max_sum_assured > 100) {
          max_sum_assured = 100;
        }
      } else {
        if (max_sum_assured > 50) {
          max_sum_assured = 50;
        }
      }
    } else if (
      proposerDetails.occupation.value !== "01" &&
      proposerDetails.education.value === "03"
    ) {
      if (max_sum_assured > 100) {
        max_sum_assured = 100;
      }
    } else {
      if (
        proposerDetails.occupation.value === "01" &&
        proposerDetails.education.value !== "03"
      ) {
        if (max_sum_assured > 50) {
          max_sum_assured = 50;
        }
      }
    }

    if (
      SELECTED_QUOTE_DATA.productDetails.product_code === product_code.STEP &&
      age >= 46
    ) {
      max_sum_assured = 1500;
    }
    data = [];
    for (let i = min_sum_assured; i <= max_sum_assured; i++) {
      const label = `${i >= 100 ? i / 100 : i} ${i >= 100 ? "Cr" : "Lakh"}`;
      data.push({ value: `${i}00000`, label: label });
    }

    dispatch(
      TermSlice.actions.UPDATE_DROPDOWN_DATA({
        key: "MAX_SUM_ASSURED",
        value: data,
      })
    );
  }, [
    proposerDetails.annual_income,
    proposerDetails.occupation,
    proposerDetails.dob,
    SELECTED_QUOTE_DATA.productDetails.product_code,
  ]);

  useEffect(() => {
    if (!isEmpty(proposerDetails?.occupation?.value)) GET_RIDERS_PREMIUM();
  }, [proposerDetails?.sum_assured?.value, proposerDetails?.occupation?.value]);

  const GET_RIDERS_PREMIUM = () => {
    setLoader(true);
    dispatch(TermSlice.actions.UPDATE_LOADER_STATUS(true));

    if (window.location.hostname === CLIENTS.MOTILAL_PHP) {
      const onSuccess = (data: any) => {
        setLoader(false);
        const results = data.results;
        const error = results.error;
        const message = results.message;
        const response: TRidersBenefitsResponse = results.response;

        if (!error) {
          setLoader(false);
          if (
            proposerDetails?.sum_assured?.value !==
              QUOTE_FIELDS_DATA.sumAssured &&
            !isEmpty(proposerDetails?.sum_assured?.value) &&
            proposerDetails?.occupation?.value === "07"
          ) {
            setOpen(true);
            setRecRiders(response);
            dispatch(
              TermSlice.actions.BULK_UPDATE({
                LOADER: false,
              })
            );
          } else {
            dispatch(
              TermSlice.actions.BULK_UPDATE({
                LOADER: false,
                RIDERS_BENEFITS: {
                  ...Term.RIDERS_BENEFITS,
                  riders_benefits_response: response,
                },
                QUOTE_FIELDS_DATA: {
                  ...Term.QUOTE_FIELDS_DATA,
                  sumAssured: isEmpty(proposerDetails?.sum_assured?.value)
                    ? Term.QUOTE_FIELDS_DATA.sumAssured
                    : proposerDetails?.sum_assured?.value,
                },
                SELECTED_QUOTE_DATA: {
                  ...Term.SELECTED_QUOTE_DATA,
                  premiumDetails: {
                    ...Term.SELECTED_QUOTE_DATA.premiumDetails,
                    term: response.term,
                    pay_term: response.pay_term,
                    sum_assured: proposerDetails?.sum_assured?.value,
                  },
                  sumAssured: proposerDetails?.sum_assured?.value,
                },
              })
            );
          }
        } else {
          toast.error(
            "We regret to inform you that you are not eligible for this plan"
          );
          console.log({ error: error, message: message });
        }
      };
      const onError = (err: any) => {
        setLoader(false);
        dispatch(TermSlice.actions.UPDATE_LOADER_STATUS(false));
        toast.error(
          "We regret to inform you that you are not eligible for this plan"
        );
      };
      const data = {
        quote_no: ADD_FORM_RESPONSE.quote_no,
        name: proposerDetails.full_name.value,
        mobile: proposerDetails.mobile.value,
        pincode: ADD_FORM.pincode.value,
        product_id: `${SELECTED_QUOTE_DATA.productDetails.id}`,
        company_code: SELECTED_QUOTE_DATA.companyDetails.company_code,
        frequency: `${Term.QUOTE_FIELDS_DATA.payMode}`,
        gender: ADD_FORM.gender.value,
        pay_term: `${SELECTED_QUOTE_DATA.premiumDetails.pay_term}`,
        product_code: SELECTED_QUOTE_DATA.productDetails.product_code,
        smoker: ADD_FORM.smoke,
        sum_assured: isEmpty(proposerDetails.sum_assured.value)
          ? Term.SELECTED_QUOTE_DATA.premiumDetails.sum_assured
          : proposerDetails.sum_assured.value,
        term: `${SELECTED_QUOTE_DATA.premiumDetails.term}`,
        dob: PROPOSER_DATA.dob.value,
        criticalIllnessSelected:
          Term.QUOTE_FIELDS_DATA.payMode === "5"
            ? "No"
            : Term.RIDERS_BENEFITS.local_value.tf001Status
            ? "Yes"
            : "No",
        accidentalDisSelected: "No",
        personalAccSelected: "No",
        cancerCovSelected: "No",
        accidentalDeathSelected: Term.RIDERS_BENEFITS.local_value.tf003Status
          ? "Yes"
          : "No",
        criticalIllnessValue:
          Term.QUOTE_FIELDS_DATA.payMode === "5"
            ? ""
            : Term.RIDERS_BENEFITS.local_value.tf001Value,
        accidentalDisValue: "",
        accidentalDeathValue: Term.RIDERS_BENEFITS.local_value.tf003Value,
        adb: "No",
        rop: "No",
        wop: "No",
        annual_income: ADD_FORM.annualIncome.value,
        wopSelected: Term.RIDERS_BENEFITS.local_value.tf004Status
          ? "Yes"
          : "No",
        occupation: isEmpty(proposerDetails?.occupation?.value)
          ? "No"
          : proposerDetails?.occupation?.value,
      };
      TERM_SERVICES.RIDERS_BENEFITS_DETAIL(onSuccess, onError, data);
    } else {
      const onSuccess = (data: any) => {
        setLoader(false);

        const results = data;
        const error = results.error;
        const message = results.message;
        const response: TRidersBenefitsResponse =
          results.response.premiumDetails;

        if (!error) {
          setLoader(false);
          if (
            proposerDetails?.sum_assured?.value !==
              QUOTE_FIELDS_DATA.sumAssured &&
            !isEmpty(proposerDetails?.sum_assured?.value) &&
            proposerDetails?.occupation?.value === "07"
          ) {
            setOpen(true);
            setRecRiders(response);
            dispatch(
              TermSlice.actions.BULK_UPDATE({
                LOADER: false,
              })
            );
          } else {
            dispatch(
              TermSlice.actions.BULK_UPDATE({
                LOADER: false,
                RIDERS_BENEFITS: {
                  ...Term.RIDERS_BENEFITS,
                  riders_benefits_response: response,
                },
                QUOTE_FIELDS_DATA: {
                  ...Term.QUOTE_FIELDS_DATA,
                  sumAssured: isEmpty(proposerDetails?.sum_assured?.value)
                    ? Term.QUOTE_FIELDS_DATA.sumAssured
                    : proposerDetails?.sum_assured?.value,
                },
                SELECTED_QUOTE_DATA: {
                  ...Term.SELECTED_QUOTE_DATA,
                  premiumDetails: {
                    ...Term.SELECTED_QUOTE_DATA.premiumDetails,
                    term: response.term,
                    pay_term: response.pay_term,
                    sum_assured: isEmpty(proposerDetails?.sum_assured?.value)
                      ? Term.QUOTE_FIELDS_DATA.sumAssured
                      : proposerDetails?.sum_assured?.value,
                  },
                  sumAssured: proposerDetails?.sum_assured?.value,
                },
              })
            );
          }
        } else {
          toast.error(
            "We regret to inform you that you are not eligible for this plan"
          );
          console.log({ error: error, message: message });
        }
      };
      const onError = (err: any) => {
        setLoader(false);
        dispatch(TermSlice.actions.UPDATE_LOADER_STATUS(false));
        toast.error(
          "We regret to inform you that you are not eligible for this plan"
        );
      };
      const data = {
        product_ids: [SELECTED_QUOTE_DATA.productDetails.id],
        quote_no: ADD_FORM_RESPONSE.quote_no,
        criticalIllnessSelected:
          Term.QUOTE_FIELDS_DATA.payMode === "5"
            ? "No"
            : Term.RIDERS_BENEFITS.local_value.tf001Status
            ? "Yes"
            : "No",
        accidentalDeathSelected: Term.RIDERS_BENEFITS.local_value.tf003Status
          ? "Yes"
          : "No",
        criticalIllnessValue:
          Term.QUOTE_FIELDS_DATA.payMode === "5"
            ? ""
            : Term.RIDERS_BENEFITS.local_value.tf001Value,
        accidentalDeathValue: Term.RIDERS_BENEFITS.local_value.tf003Value,
        wopSelected: Term.RIDERS_BENEFITS.local_value.tf004Status
          ? "Yes"
          : "No",
        occupation: isEmpty(proposerDetails?.occupation?.value)
          ? "No"
          : proposerDetails?.occupation?.value,
        sum_assured: isEmpty(proposerDetails.sum_assured.value)
          ? Term.SELECTED_QUOTE_DATA.premiumDetails.sum_assured
          : proposerDetails.sum_assured.value,
      };
      TERM_SERVICES.RIDERS_BENEFITS_DETAIL_NODE(onSuccess, onError, data);
    }
  };

  useEffect(() => {
    dispatch(
      TermSlice.actions.BULK_UPDATE({
        PROPOSER_DATA: proposerDetails,
      })
    );
  }, [proposerDetails]);

  type ValueTypes = keyof TTermProposerData;
  const updateMasterState = (attrName: ValueTypes, value: any) => {
    if (attrName === "full_name") {
      setProposerDetails((prev) => ({
        ...prev,
        full_name: { value: value, warning: !validateFullName(value) },
      }));
    } else if (attrName === "email") {
      setProposerDetails((prev) => ({
        ...prev,
        email: { value: value, warning: !validateEmail(value) },
      }));
    } else if (attrName === "annual_income") {
      setProposerDetails((prev) => ({
        ...prev,
        annual_income: {
          value: value,
          warning: isEmpty(value),
        },
      }));
    }
    //  else if (attrName === "aadhar_number") {
    //   setProposerDetails((prev) => ({
    //     ...prev,
    //     aadhar_number: { value: value, warning: !validateAadharNumber(value) },
    //   }));
    // }
    // else if (attrName === "emergency_number") {
    //   setProposerDetails((prev) => ({
    //     ...prev,
    //     emergency_number: {
    //       value: value,
    //       warning: !validateMobileNumber(value),
    //     },
    //   }));
    // }
    else if (attrName === "diabetic") {
      setProposerDetails((prev) => ({
        ...prev,
        diabetic: value,
      }));
    } else if (attrName === "housewifePayerStatus") {
      setProposerDetails((prev) => ({
        ...prev,
        housewifePayerStatus: value,
      }));
    } else if (attrName === "housewifePayerRelation") {
      setProposerDetails((prev) => ({
        ...prev,
        housewifePayerRelation: {
          value: value,
          warning: isEmpty(value),
        },
      }));
    } else if (attrName === "sum_assured") {
      setProposerDetails((prev) => ({
        ...prev,
        [attrName]: {
          value: value,
          warning: isEmpty(value),
        },
      }));
    } else {
      setProposerDetails((prev) => ({
        ...prev,
        [attrName]: {
          value: value,
          warning: isEmpty(value),
        },
      }));
    }
  };

  useEffect(() => {
    setProposerDetails((prev) => ({
      ...prev,
      sum_assured: {
        value: QUOTE_FIELDS_DATA.sumAssured,
        warning: false,
      },
    }));
  }, [proposerDetails.annual_income.value]);

  const validateForm = () => {
    let data: TTermProposerData = {
      ...proposerDetails,
    };
    data = {
      ...data,
      full_name: {
        ...data.full_name,
        warning: !validateFullName(data.full_name.value),
      },
      dob: {
        ...data.dob,
        warning: isEmpty(data?.dob?.value),
      },
      gender: {
        ...data.gender,
        warning: isEmpty(data?.gender?.value),
      },
      mobile: {
        ...data.mobile,
        warning: isEmpty(data.mobile.value),
      },
      email: {
        ...data.email,
        warning: !validateEmail(data.email.value),
      },
      // aadhar_number: {
      //   ...data.aadhar_number,
      //   warning: isEmpty(data.aadhar_number.value)
      //     ? false
      //     : !validateAadharNumber(data.aadhar_number.value),
      // },
      // emergency_number: {
      //   ...data.emergency_number,
      //   warning: !validateMobileNumber(data.emergency_number.value),
      // },
      annual_income: {
        ...data.annual_income,
        warning: !validate_annual_income(data?.annual_income?.value),
      },
      sum_assured: {
        ...data.sum_assured,
        warning:
          isEmpty(data?.sum_assured.value) ||
          isEmpty(QUOTE_FIELDS_DATA.sumAssured),
      },
      occupation: {
        ...data.occupation,
        warning: isEmpty(data?.occupation?.value),
      },
      education: {
        ...data.education,
        warning: isEmpty(data?.education?.value),
      },
    };
    const annual_income = Number(data.annual_income.value);
    const age = calculateAge(FORMAT_DATE_DD_MM_YYYY(data.dob.value));

    if (data.education.value === education_data_local["Graduate & Above"]) {
      if (data.occupation.value === occupation_data_local.salaried) {
        if (age <= 40) {
          if (annual_income < 300000) {
            toast.error("Min. annual income is ₹ 3 Lakh");
            return;
          }
        } else {
          if (annual_income < 500000) {
            toast.error("Min. annual income is ₹ 5 Lakh");
            return;
          }
        }
      } else if (
        data.occupation.value === occupation_data_local.self_employed
      ) {
        if (annual_income < 500000) {
          toast.error("Min. annual income is ₹ 5 Lakh");
          return;
        }
      }
    } else {
      if (
        data.education.value === education_data_local["10th Pass"] ||
        data.education.value === education_data_local["12th Pass"]
      ) {
        if (
          data.occupation.value === occupation_data_local.salaried ||
          data.occupation.value === occupation_data_local.self_employed
        ) {
          if (annual_income < 500000) {
            toast.error("Min. annual income is ₹ 5 Lakh");
            return;
          }
        }
      }
    }

    if (data.occupation.value === "01") {
      if (!data.housewifePayerStatus) {
        data = {
          ...data,
          housewifePayerRelation: {
            ...data.housewifePayerRelation,
            warning: isEmpty(data?.housewifePayerRelation.value),
          },
        };
      } else {
        data = {
          ...data,
          housewifePayerRelation: {
            ...data.housewifePayerRelation,
            warning: false,
          },
        };
      }
    } else {
      data = {
        ...data,
        housewifePayerStatus: false,
        housewifePayerRelation: { value: "", warning: false },
      };
    }
    setProposerDetails(data);

    let hasError = false;

    // if (
    //   proposerDetails.occupation.value === "01" &&
    //   +QUOTE_FIELDS_DATA.sumAssured > 5000000
    // ) {
    //   toast.error("Select upto 50 lakh sum assured in case of housewife");
    //   hasError = true;
    // }

    if (
      data.full_name.warning ||
      data.dob.warning ||
      data.gender.warning ||
      data.mobile.warning ||
      data.email.warning ||
      // data.aadhar_number.warning ||
      data.annual_income.warning ||
      data.occupation.warning ||
      data.sum_assured.warning ||
      data.housewifePayerRelation.warning
    ) {
      hasError = true;
    }

    // if (data.mobile.value === data.emergency_number.value) {
    //   toast.error("Mobile no. and emergency no. should be different.");
    //   hasError = hasError || data.mobile.value === data.emergency_number?.value;
    // }

    if (
      (state.Term.RIDERS_BENEFITS.local_value.tf001Status ||
        state.Term.RIDERS_BENEFITS.local_value.tf004Status) &&
      data?.occupation?.value === "Housewife"
    ) {
      if (
        state.Term.RIDERS_BENEFITS.local_value.tf001Status &&
        state.Term.RIDERS_BENEFITS.local_value.tf004Status
      ) {
        toast.error(
          "Critical Illness and Disability & Waiver of Premium Rider is not allowed for housewife"
        );
      }
      if (state.Term.RIDERS_BENEFITS.local_value.tf001Status) {
        toast.error(
          "Critical Illness and Disability Rider is not allowed for housewife"
        );
      }
      if (state.Term.RIDERS_BENEFITS.local_value.tf004Status) {
        toast.error("Waiver of Premium Rider is not allowed for housewife");
      }

      return;
    }

    if (MaxLifeNegativePincode.includes(Number(ADD_FORM.pincode.value))) {
      if (data.occupation.value === "01") {
        toast.error(
          "Housewife cannot apply for Max Life policy in the provided pincode."
        );
        return;
      } else {
        if (Number(data.annual_income.value) < 1000000) {
          toast.error(
            "An annual income of at least ₹ 10 lakhs is mandatory for the current pincode."
          );
          return;
        }
      }
    }

    if (
      data.education.value === "01" &&
      data.occupation.value !== "01" &&
      Number(data.annual_income.value) < 1000000
    ) {
      toast.error("Annual income should be at least 10 lakhs.");
      return;
    }

    if (
      data.education.value === "08" &&
      data.occupation.value === "01" &&
      Number(data.annual_income.value) < 1000000
    ) {
      toast.error(
        "Housewife cannot apply for the policy with less than 10 lakhs annual income."
      );
      return;
    }

    if (!hasError) {
      dispatch(
        TermSlice.actions.BULK_UPDATE({
          PROPOSER_DATA: data,
        })
      );

      updateProposerDetailsData(data);
    }
  };

  const GET_REDIRECTION_URL = () => {
    setLoader(true);
    if (window.location.hostname === CLIENTS.MOTILAL_PHP) {
      const onSuccess = (res: any) => {
        setLoader(false);
        const results = res.results;
        const error = results.error;
        if (!error) {
          const response = results.response;
          const redirectionUrl = response.redirectionUrl;

          if (!isEmpty(redirectionUrl)) {
            window.location.href = redirectionUrl;
          } else {
            toast.error("Redirestion URL Missing");
          }
        }
      };
      const onError = () => {
        setLoader(false);
      };
      const params = {
        quote_no: ADD_FORM_RESPONSE.quote_no,
        criticalIllnessSumAssured: RIDERS_BENEFITS.local_value.tf001Status
          ? RIDERS_BENEFITS.local_value.tf001Value
          : "",
        accDeathSumAssured: RIDERS_BENEFITS.local_value.tf003Status
          ? RIDERS_BENEFITS.local_value.tf003Value
          : "",
        product_code: SELECTED_QUOTE_DATA.productDetails.product_code,
        buy_online_code: SELECTED_QUOTE_DATA.productDetails.buy_online_code,
        product_id: SELECTED_QUOTE_DATA.productDetails.id,
      };
      TERM_SERVICES.GET_REDIRECTION_URL_LARAVEL(onSuccess, onError, params);
    } else {
      const onSuccess = (res: any) => {
        setLoader(false);
        const results = res;
        const error = results.error;
        if (!error) {
          const response = results.response;
          const redirectionUrl = results.redirectionUrl;

          if (!isEmpty(redirectionUrl)) {
            window.location.href = redirectionUrl;
          } else {
            toast.error("Redirestion URL Missing");
          }
        }
      };
      const onError = () => {
        setLoader(false);
      };
      const params = {
        quote_no: ADD_FORM_RESPONSE.quote_no,
        criticalIllnessSumAssured: RIDERS_BENEFITS.local_value.tf001Status
          ? RIDERS_BENEFITS.local_value.tf001Value
          : "",
        accDeathSumAssured: RIDERS_BENEFITS.local_value.tf003Status
          ? RIDERS_BENEFITS.local_value.tf003Value
          : "",
        product_code: SELECTED_QUOTE_DATA.productDetails.product_code,
        buy_online_code: SELECTED_QUOTE_DATA.productDetails.buy_online_code,
        product_ids: [SELECTED_QUOTE_DATA.productDetails.id],
      };
      TERM_SERVICES.GET_REDIRECTION_URL(onSuccess, onError, params);
    }
  };

  const updateProposerDetailsData = (proposerDetails: TTermProposerData) => {
    setLoader(true);
    if (window.location.hostname === CLIENTS.MOTILAL_PHP) {
      const onSuccess = (data: any) => {
        setLoader(false);
        const results = data.results;
        const error = results.error;
        const message = results.message;

        if (!error) {
          if (SELECTED_QUOTE_DATA?.productDetails.frequency === "5") {
            GET_REDIRECTION_URL();
          } else {
            navigate(TERM_ROUTES.RIDERS_BENEFITS);
          }
        } else {
          console.log({ error: error, message: message });
        }
      };
      const onError = (err: any) => {
        setLoader(false);
        console.log(err);
      };
      const data = {
        quote_no: btoa(ADD_FORM_RESPONSE.quote_no),
        buy_online_code: SELECTED_QUOTE_DATA.productDetails.buy_online_code,
        section: "proposer_details",
        details: {
          payer_selected:
            proposerDetails.occupation.value !== "01"
              ? ""
              : proposerDetails.housewifePayerStatus
              ? "Yes"
              : "No",
          housewife_payer_relationship:
            proposerDetails.occupation.value !== "01"
              ? ""
              : proposerDetails.housewifePayerRelation.value,
          proposer_relationship: proposerDetails.is_proposer_is_insured_member
            ? "Yes"
            : "No",
          aadhar_number: proposerDetails.aadhar_number.value,
          insured_aadhar: "",
          insured_dob: "",
          insured_email: "",
          insured_gender: "",
          insured_marital_status: "",
          insured_mobile: "",
          insured_name: "",
          insured_occupation: "",
          premium: SELECTED_QUOTE_DATA.premiumDetails.finalPremium,
          proposer_annual_income: proposerDetails.annual_income.value,
          proposer_dob: proposerDetails.dob.value,
          proposer_email: proposerDetails.email.value,
          proposer_emergency_no: proposerDetails.emergency_number.value,
          proposer_gender: proposerDetails.gender.value,
          proposer_marital_status: proposerDetails.marital_status.value,
          proposer_mobile: proposerDetails.mobile.value,
          proposer_name: proposerDetails.full_name.value,
          proposer_occupation: proposerDetails.occupation.value,
          proposer_qualification: proposerDetails.education.value,
          proposer_occDesc: proposerDetails.occupation_desc.value,
          proposer_diabetes: proposerDetails.diabetic ? "01" : "02",
          address_pincode: ADD_FORM.pincode.value,
        },
      };
      TERM_SERVICES.UPDATE_PROPOSER_DETAILS_LARAVEL(onSuccess, onError, data);
    } else {
      const onSuccess = (data: any) => {
        setLoader(false);
        const results = data;
        const error = results.error;
        const message = results.message;

        if (!error) {
          if (SELECTED_QUOTE_DATA?.productDetails.frequency === "5") {
            GET_REDIRECTION_URL();
          } else {
            navigate(
              `${TERM_ROUTES.RIDERS_BENEFITS}?type=TM&companyCode=${SELECTED_QUOTE_DATA?.companyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}`
            );
          }
        } else {
          console.log({ error: error, message: message });
        }
      };
      const onError = (err: any) => {
        setLoader(false);
        console.log(err);
      };

      const dob_split = proposerDetails.dob.value.split("-");
      let dob = proposerDetails.dob.value;
      if (`${dob_split[0]}`.length === 4) {
        dob = `${dob_split[2]}-${dob_split[1]}-${dob_split[0]}`;
      }
      const data = {
        quote_no: btoa(ADD_FORM_RESPONSE.quote_no),
        buy_online_code: SELECTED_QUOTE_DATA.productDetails.buy_online_code,
        section: "proposer_details",
        details: {
          payer_selected: proposerDetails.housewifePayerStatus ? "Yes" : "No",
          housewife_payer_relationship:
            proposerDetails.housewifePayerRelation.value,
          proposer_relationship: proposerDetails.is_proposer_is_insured_member
            ? "Yes"
            : "No",
          aadhar_number: proposerDetails.aadhar_number.value,
          insured_aadhar: "",
          insured_dob: "",
          insured_email: "",
          insured_gender: "",
          insured_marital_status: "",
          insured_mobile: "",
          insured_name: "",
          insured_occupation: "",
          premium: SELECTED_QUOTE_DATA.premiumDetails.finalPremium,
          proposer_annual_income: proposerDetails.annual_income.value,
          proposer_dob: dob,
          proposer_email: proposerDetails.email.value,
          proposer_emergency_no: proposerDetails.emergency_number.value,
          proposer_gender: proposerDetails.gender.value,
          proposer_marital_status: proposerDetails.marital_status.value,
          proposer_mobile: proposerDetails.mobile.value,
          proposer_name: proposerDetails.full_name.value,
          proposer_occupation: proposerDetails.occupation.value,
          proposer_qualification: proposerDetails.education.value,
          proposer_occDesc: proposerDetails.occupation_desc.value,
          proposer_diabetes: proposerDetails.diabetic ? "01" : "02",
          address_pincode: ADD_FORM.pincode.value,
        },
      };
      TERM_SERVICES.UPDATE_PROPOSER_DETAILS(onSuccess, onError, data);
    }
  };
  return (
    <>
      {LOADER ? (
        <Box
          position="fixed"
          height="100%"
          width="100%"
          zIndex="991"
          top="0px"
          left="0px"
        />
      ) : null}
      {isMobile ? (
        <MProposerDetails
          proposerDetails={proposerDetails}
          setProposerDetails={setProposerDetails}
          updateMasterState={updateMasterState}
          validateForm={validateForm}
          loader={loader}
          open={open}
          setOpen={setOpen}
          recRiders={recRiders}
          setRecRiders={setRecRiders}
        />
      ) : (
        <ProposerDetails
          proposerDetails={proposerDetails}
          setProposerDetails={setProposerDetails}
          updateMasterState={updateMasterState}
          validateForm={validateForm}
          loader={loader}
          open={open}
          setOpen={setOpen}
          recRiders={recRiders}
          setRecRiders={setRecRiders}
        />
      )}
    </>
  );
};

export default ProposerDetailsContainer;
