import { Route, Routes } from "react-router-dom";
import NivaBupaBankDetailsContainer from "../../../../../Container/BankDetails/NivaBupa/NivaBupaBankDetailsContainer";
import NIVABUPACKYCContainerPHP from "../../../../../Container/CKYC/Niva_bupa/NIVABUPACKYCContainer";
import AddonDetailsCOntainerNIVABUPAPHP from "../../../../../Container/health-php/NIVA_BUPA/AddonDetailsCOntainerNIVABUPA";
import MedicalDetailContainerNivaBupaPHP from "../../../../../Container/health-php/NIVA_BUPA/MedicalDetailContainerNivaBupa";
import MedicalNivaBupaSenContainerPHP from "../../../../../Container/health-php/NIVA_BUPA/MedicalNivaBupaSenContainer";
import { EProductId } from "../../../../../Services/Enum/EHome";
import { useAppSelector } from "../../../../../Store/hooks";
import { CKYC_ROUTES } from "../../../../Path/CKYCRoutes";
import { HEALTH_ROUTE } from "../../../../Path/HealthRoutes";
import HealthProposalContainer from "../../HealthProposalContainer";
import AddonDetailsCOntainerNIVABUPA from "../../../../../Container/health-node/NIVA_BUPA/AddonDetailsCOntainerNIVABUPA";
import MedicalDetailContainerNivaBupa from "../../../../../Container/health-node/NIVA_BUPA/MedicalDetailContainerNivaBupa";
import NIVABUPACKYCContainer from "../../../../../Container/ckyc-node/niva-bupa/NIVABUPACKYCContainer";

const NIVABIPA_ROUTES = () => {
  const { id } = useAppSelector(
    (state) => state.Health.SELECTED_QUOTE_DATA.productDetails
  );
  return (
    <Routes>
      <Route
        path={HEALTH_ROUTE.PROPOSAL_PAGE}
        element={<HealthProposalContainer />}
      >
        {`${id}` === "2943" ||
        `${id}` === "2944" ||
        `${id}` === "2945" ||
        `${id}` === "2946" ? (
          <Route path={CKYC_ROUTES.CKYC} element={<NIVABUPACKYCContainer />} />
        ) : (
          <Route
            path={CKYC_ROUTES.CKYC}
            element={<NIVABUPACKYCContainerPHP />}
          />
        )}
        {`${id}` === "2943" ||
        `${id}` === "2944" ||
        `${id}` === "2945" ||
        `${id}` === "2946" ? (
          <Route
            path={HEALTH_ROUTE.ADDON_DETAILS}
            element={<AddonDetailsCOntainerNIVABUPA />}
          />
        ) : (
          <Route
            path={HEALTH_ROUTE.ADDON_DETAILS}
            element={<AddonDetailsCOntainerNIVABUPAPHP />}
          />
        )}
        {id === EProductId.NIVA_BUPA_SENIOR_FIRST_GOLD_IND ||
        id === EProductId.NIVA_BUPA_Senior_First_Gold_Floater ||
        id === EProductId.NIVA_BUPA_Senior_First_Platinum_Floater ||
        id === EProductId.NIVA_BUPA_Senior_First_Platinum_IND ? (
          <Route
            path={HEALTH_ROUTE.MEDICAL_DETAILS}
            element={<MedicalNivaBupaSenContainerPHP />}
          />
        ) : `${id}` === "2943" ||
          `${id}` === "2944" ||
          `${id}` === "2945" ||
          `${id}` === "2946" ? (
          <Route
            path={HEALTH_ROUTE.MEDICAL_DETAILS}
            element={<MedicalDetailContainerNivaBupa />}
          />
        ) : (
          <Route
            path={HEALTH_ROUTE.MEDICAL_DETAILS}
            element={<MedicalDetailContainerNivaBupaPHP />}
          />
        )}
        <Route
          path={HEALTH_ROUTE.BANK_DETAILS}
          element={<NivaBupaBankDetailsContainer />}
        />
      </Route>
    </Routes>
  );
};

export default NIVABIPA_ROUTES;
