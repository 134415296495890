import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AddonDetails from "../../../Page/Desktop/health-php/GODIGIT/AddonDetails";
import MAddonDetails from "../../../Page/Mobile/health-php/GODIGIT/MAddonDetails";
import { HEALTH_ROUTE } from "../../../Router/Path/HealthRoutes";
import { HEALTH_PROPOSAL_SERVICES } from "../../../Services/health-php/HealthPropsalServices";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import { HealthSlice } from "../../../Store/Slice_Reducer/Health/HealthSlice";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { THealthQuote } from "../../../types/Health/HealthQuotation/THealthQuote";
import { TGoDigitAddon } from "../../../types/Health/TGoDigitAddon";
import { CKYC_ROUTES } from "../../../Router/Path/CKYCRoutes";

export default function AddonDetailsContainer() {
  const isMobile = useIsMobile();
  const {
    ADD_FORM,
    ADD_FORM_RESPONSE,
    SELECTED_QUOTE_DATA,
    PAGE_STATUS,
    ADDON_DETAILS_GODDIGIT,
    QUOTE_LOADER,
  } = useAppSelector((state) => state.Health);
  const { Health, CKYC } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [addonStatus, setAddonStatus] = useState<TGoDigitAddon>(
    ADDON_DETAILS_GODDIGIT
  );
  const [loader, setLoader] = useState<boolean>(false);

  // useEffect(() => {
  //   const windowURL = window.location.search;
  //   const params = new URLSearchParams(windowURL);
  //   const txnId = params.get("txnId");
  //   const status = params.get("status");
  //   const kyc_id = params.get("kyc_id");

  //   if (txnId && status && kyc_id) {
  //     const prams = {
  //       quote_no: ADD_FORM_RESPONSE.quote_no,
  //       vehicle_owned: "Individual",
  //       kyc_id: kyc_id,
  //     };
  //   }
  // }, []);

  useEffect(() => {
    updatePreiumApi();
    dispatch(HealthSlice.actions.ADDON_DETAILS_DATA_GODIGIT(addonStatus));
  }, [addonStatus]);

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "consumables_cover") {
      setAddonStatus((prevState) => ({
        ...prevState,
        consumables_cover: value,
      }));
    }
  };

  const updatePreiumApi = () => {
    const onSuccess = (res: any) => {
      dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
      if (res.results.error === false) {
        let quoteData: THealthQuote = SELECTED_QUOTE_DATA;
        quoteData = {
          ...quoteData,
          premiumDetails: {
            ...quoteData.premiumDetails,
            finalPremium: res.results.response.premiumDetails.finalPremium,
          },
        };
        dispatch(
          HealthSlice.actions.BULK_UPLOAD({
            ...Health,
            SELECTED_QUOTE_DATA: quoteData,
            ADDON_DETAILS_GODDIGIT: addonStatus,
            QUOTE_LOADER: false,
          })
        );
      }
    };
    const onError = (err: any) => {
      dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
      console.log("err", err);
    };

    dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(true));
    let param: any = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      product_code: SELECTED_QUOTE_DATA?.productDetails?.product_code,
      company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
      addon: {
        consumables_cover:
          addonStatus.consumables_cover === true ? "Yes" : "No",
      },
    };

    HEALTH_PROPOSAL_SERVICES.getPeriumByAddonGoDigit(onSuccess, onError, param);
  };

  const validate = () => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const cus_type = params.get("cus_type");

    const onSuccess = (res: any) => {
      if (!res.results.error) {
        setLoader(false);
        if (PAGE_STATUS) {
          navigate(
            `${HEALTH_ROUTE.PROPOSAL_PAGE}/${
              HEALTH_ROUTE.PREVIEW_DETAILS
            }?type=HP&companyCode=${
              SELECTED_QUOTE_DATA?.CompanyDetails.company_code
            }&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${
              SELECTED_QUOTE_DATA?.productDetails.id
            }${cus_type === "user" ? "&cus_type=user" : ""}`
          );
        } else {
          navigate(
            `${HEALTH_ROUTE.PROPOSAL_PAGE}/${
              HEALTH_ROUTE.ADULT_ONE_DETAILS
            }?type=HP&companyCode=${
              SELECTED_QUOTE_DATA?.CompanyDetails.company_code
            }&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${
              SELECTED_QUOTE_DATA?.productDetails.id
            }${cus_type === "user" ? "&cus_type=user" : ""}`
            // `${HEALTH_ROUTE.PROPOSAL_PAGE}/${CKYC_ROUTES.CKYC}?type=HP&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA.productDetails.id}`
          );
        }
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    setLoader(true);
    let param: any = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      section: "add_ons_details",
      product_code: SELECTED_QUOTE_DATA?.productDetails?.product_code,
      company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
      cover: `${ADD_FORM?.spouse_status ? "2A" : "1A"}${
        (parseInt(ADD_FORM?.son_count?.value) || 0) +
          (parseInt(ADD_FORM?.daughter_count?.value) || 0) >
        0
          ? `${
              (parseInt(ADD_FORM?.son_count?.value) || 0) +
              (parseInt(ADD_FORM?.daughter_count?.value) || 0)
            }C`
          : ""
      }`,
      consumables_cover: addonStatus.consumables_cover === true ? "Yes" : "No",
    };
    // if (PAGE_STATUS) {
    //   navigate(
    //     `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.PREVIEW_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}`
    //   );
    // } else {
    //   navigate(
    //     `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.ADULT_ONE_DETAILS}`
    //     // `${HEALTH_ROUTE.PROPOSAL_PAGE}/${CKYC_ROUTES.CKYC}?type=HP&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA.productDetails.id}`
    //   );
    // }

    HEALTH_PROPOSAL_SERVICES.getCapturedRecordData(onSuccess, onError, param);
  };

  const FinalPremium = () => {
    const onSuccess = (res: any) => {
      console.log("res", res);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    let param: any = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      final_premium: SELECTED_QUOTE_DATA?.premiumDetails?.finalPremium,
    };
    HEALTH_PROPOSAL_SERVICES.GET_FINAL_PREMIUM(onSuccess, onError, param);
  };

  useEffect(() => {
    FinalPremium();
  }, [SELECTED_QUOTE_DATA]);

  useEffect(() => {
    RECORD_CKYC_RESPONSE();
  }, [CKYC.CKYC_DETAILS]);

  const RECORD_CKYC_RESPONSE = () => {
    const onSuccess = (res: any) => {
      console.log("res...", res?.results);
    };
    const onError = (err: any) => {
      console.log("err..", err);
    };
    let param = {
      details: CKYC.CKYC_DETAILS,
      section: "kyc_details",
      quote_no: ADD_FORM_RESPONSE.quote_no,
      cover: `${ADD_FORM?.spouse_status ? "2A" : "1A"}${
        (parseInt(ADD_FORM?.son_count?.value) || 0) +
          (parseInt(ADD_FORM?.daughter_count?.value) || 0) >
        0
          ? `${
              (parseInt(ADD_FORM?.son_count?.value) || 0) +
              (parseInt(ADD_FORM?.daughter_count?.value) || 0)
            }C`
          : ""
      }`,
    };
    HEALTH_PROPOSAL_SERVICES.getCapturedRecordData(onSuccess, onError, param);
  };
  return (
    <>
      {QUOTE_LOADER ? (
        <Box
          position="fixed"
          height="100%"
          width="100%"
          zIndex="991"
          top="0px"
          left="0px"
        />
      ) : null}
      {isMobile ? (
        <MAddonDetails
          isLoading={loader}
          addonStatus={addonStatus}
          updateMasterState={updateMasterState}
          validate={validate}
          PAGE_STATUS={PAGE_STATUS}
        />
      ) : (
        <AddonDetails
          isLoading={loader}
          addonStatus={addonStatus}
          updateMasterState={updateMasterState}
          validate={validate}
          PAGE_STATUS={PAGE_STATUS}
        />
      )}
    </>
  );
}
