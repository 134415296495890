import { Route, Routes } from "react-router-dom";
import KotakCKYCContainer from "../../../../../Container/CKYC/Kotak/KotakCKYCContainer";
import AddonContainerKotak from "../../../../../Container/health-php/Kotak/AddonContainerKotak/AddonContainerKotak";
import MedicalDetailContainer from "../../../../../Container/health-php/Kotak/MedicalDetailContainer/MedicalDetailContainer";
import { CKYC_ROUTES } from "../../../../Path/CKYCRoutes";
import { HEALTH_ROUTE } from "../../../../Path/HealthRoutes";
import HealthProposalContainer from "../../HealthProposalContainer";

const KOTAK_ROUTES = () => {
  return (
    <Routes>
      <Route
        path={HEALTH_ROUTE.PROPOSAL_PAGE}
        element={<HealthProposalContainer />}
      >
        <Route path={CKYC_ROUTES.CKYC} element={<KotakCKYCContainer />} />
        <Route
          path={HEALTH_ROUTE.ADDON_DETAILS}
          element={<AddonContainerKotak />}
        />
        <Route
          path={HEALTH_ROUTE.MEDICAL_DETAILS}
          element={<MedicalDetailContainer />}
        />
      </Route>
    </Routes>
  );
};

export default KOTAK_ROUTES;
