import React, { useEffect, useState } from "react";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import AddonDetailsKotak from "../../../../Page/Desktop/health-php/Kotak/AddonDetailsKotak";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import { TKOTAK_EDGE } from "../../../../types/Health/ProposalDeatail/TKotak/TKOTAK";
import { HealthSlice } from "../../../../Store/Slice_Reducer/Health/HealthSlice";
import { THealthQuote } from "../../../../types/Health/HealthQuotation/THealthQuote";
import { HEALTH_PROPOSAL_SERVICES } from "../../../../Services/health-php/HealthPropsalServices";
import { useNavigate } from "react-router-dom";
import { HEALTH_ROUTE } from "../../../../Router/Path/HealthRoutes";
import MAddonDetailsKotak from "../../../../Page/Mobile/health-php/Kotak/MAddonDetailsKotak";
import { CKYC_ROUTES } from "../../../../Router/Path/CKYCRoutes";

const AddonContainerKotak = () => {
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const {
    ADDON_DETAILS_KOTAK,
    PAGE_STATUS,
    SELECTED_QUOTE_DATA,
    ADD_FORM_RESPONSE,
    ADD_FORM,
  } = useAppSelector((state) => state.Health);

  const { Health, CKYC } = useAppSelector((state) => state);
  const [addonStatus, setAddonStatus] =
    useState<TKOTAK_EDGE>(ADDON_DETAILS_KOTAK);
  const navigate = useNavigate();
  const [loader, setLoader] = useState<boolean>(false);

  const updateMasterState = (attrName: any, value: any) => {
    setAddonStatus((prev) => ({
      ...prev,
      [attrName]: value,
    }));
  };

  useEffect(() => {
    updatePreiumApi();
    dispatch(HealthSlice.actions.ADDON_DETAILS_DATA_KOTAK_EDGE(addonStatus));
  }, [addonStatus]);

  const updatePreiumApi = () => {
    const onSuccess = (res: any) => {
      dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
      if (res.results.error === false) {
        let quoteData: THealthQuote = SELECTED_QUOTE_DATA;
        quoteData = {
          ...quoteData,
          premiumDetails: {
            ...quoteData.premiumDetails,
            finalPremium: res.results.response.premiumDetails.finalPremium,
          },
        };
        dispatch(
          HealthSlice.actions.BULK_UPLOAD({
            ...Health,
            SELECTED_QUOTE_DATA: quoteData,
            ADDON_DETAILS_KOTAK: addonStatus,
            QUOTE_LOADER: false,
          })
        );
      }
    };
    const onError = (err: any) => {
      dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
      console.log("err", err);
    };

    dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(true));
    let param: any = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      product_code: SELECTED_QUOTE_DATA?.productDetails?.product_code,
      company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
      addon: {
        critical_illness: addonStatus.critical_illness === true ? "Yes" : "No", // edge ,elite,total
        compassionate_visit:
          addonStatus.compassionate_visit === true ? "Yes" : "No", // edge ,elite,total
        air_ambulance: addonStatus.air_ambulance === true ? "Yes" : "No", // edge,total
        personal_accident:
          addonStatus.personal_accident === true ? "Yes" : "No", // edge ,elite,total
        claim_protect: addonStatus.claim_protect === true ? "Yes" : "No", // edge ,elite,total
        inflation_proptect:
          addonStatus.inflation_proptect === true ? "Yes" : "No", // edge ,elite,total
        super_ncb: addonStatus.super_ncb === true ? "Yes" : "No", // edge ,elite,total
        restoration_benefit_plus:
          addonStatus.restoration_benefit_plus === true ? "Yes" : "No", // edge ,elite,total
        maternity_benefit:
          addonStatus.maternity_benefit === true ? "Yes" : "No", // edge ,elite,total
        new_born_baby_cover:
          addonStatus.new_born_baby_cover === true ? "Yes" : "No", // edge ,elite,total
        vaccination_expenses:
          addonStatus.vaccination_expenses === true ? "Yes" : "No", // edge ,elite,total
        hospital_daily_cash: "No", //total
        home_nursing_benefit: "No", //total
        daily_cash: "No", //total
        convalescence_benefit: "No", //total
        cap_on_room_rent: "No", //total
      },
    };
    HEALTH_PROPOSAL_SERVICES.getPeriumByAddonKotak(onSuccess, onError, param);
  };

  const validate = () => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const cus_type = params.get("cus_type");

    const onSuccess = (res: any) => {
      if (!res.results.error) {
        setLoader(false);
        if (PAGE_STATUS) {
          navigate(
            `${HEALTH_ROUTE.PROPOSAL_PAGE}/${
              HEALTH_ROUTE.PREVIEW_DETAILS
            }?type=HP&companyCode=${
              SELECTED_QUOTE_DATA?.CompanyDetails.company_code
            }&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${
              SELECTED_QUOTE_DATA?.productDetails.id
            }${cus_type === "user" ? "&cus_type=user" : ""}`
          );
        } else {
          navigate(
            `${HEALTH_ROUTE.PROPOSAL_PAGE}/${
              HEALTH_ROUTE.ADULT_ONE_DETAILS
            }?type=HP&companyCode=${
              SELECTED_QUOTE_DATA?.CompanyDetails.company_code
            }&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${
              SELECTED_QUOTE_DATA?.productDetails.id
            }${cus_type === "user" ? "&cus_type=user" : ""}`
            // `${HEALTH_ROUTE.PROPOSAL_PAGE}/${CKYC_ROUTES.CKYC}?type=HP&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA.productDetails.id}`
          );
        }
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    setLoader(true);
    let param: any = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      product_code: SELECTED_QUOTE_DATA?.productDetails?.product_code,
      company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
      section: "add_ons_details",
      cover: `${ADD_FORM?.spouse_status ? "2A" : "1A"}${
        (parseInt(ADD_FORM?.son_count?.value) || 0) +
          (parseInt(ADD_FORM?.daughter_count?.value) || 0) >
        0
          ? `${
              (parseInt(ADD_FORM?.son_count?.value) || 0) +
              (parseInt(ADD_FORM?.daughter_count?.value) || 0)
            }C`
          : ""
      }`,
      critical_illness: addonStatus.critical_illness === true ? "Yes" : "No", // edge ,elite,total
      compassionate_visit:
        addonStatus.compassionate_visit === true ? "Yes" : "No", // edge ,elite,total
      air_ambulance: addonStatus.air_ambulance === true ? "Yes" : "No", // edge,total
      personal_accident: addonStatus.personal_accident === true ? "Yes" : "No", // edge ,elite,total
      claim_protect: addonStatus.claim_protect === true ? "Yes" : "No", // edge ,elite,total
      inflation_proptect:
        addonStatus.inflation_proptect === true ? "Yes" : "No", // edge ,elite,total
      super_ncb: addonStatus.super_ncb === true ? "Yes" : "No", // edge ,elite,total
      restoration_benefit_plus:
        addonStatus.restoration_benefit_plus === true ? "Yes" : "No", // edge ,elite,total
      maternity_benefit: addonStatus.maternity_benefit === true ? "Yes" : "No", // edge ,elite,total
      new_born_baby_cover:
        addonStatus.new_born_baby_cover === true ? "Yes" : "No", // edge ,elite,total
      vaccination_expenses:
        addonStatus.vaccination_expenses === true ? "Yes" : "No", // edge ,elite,total
      hospital_daily_cash: "No", //total
      home_nursing_benefit: "No", //total
      daily_cash: "No", //total
      convalescence_benefit: "No", //total
      cap_on_room_rent: "No", //total
    };

    // if (PAGE_STATUS) {
    //   navigate(
    //     `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.PREVIEW_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}`
    //   );
    // } else {
    //   navigate(
    //     `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.ADULT_ONE_DETAILS}`
    //     // `${HEALTH_ROUTE.PROPOSAL_PAGE}/${CKYC_ROUTES.CKYC}?type=HP&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA.productDetails.id}`
    //   );
    // }

    HEALTH_PROPOSAL_SERVICES.getCapturedRecordData(onSuccess, onError, param);
  };

  const FinalPremium = () => {
    const onSuccess = (res: any) => {
      console.log("res", res);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    let param: any = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      final_premium: SELECTED_QUOTE_DATA?.premiumDetails?.finalPremium,
    };
    HEALTH_PROPOSAL_SERVICES.GET_FINAL_PREMIUM(onSuccess, onError, param);
  };

  useEffect(() => {
    FinalPremium();
  }, [SELECTED_QUOTE_DATA]);

  useEffect(() => {
    RECORD_CKYC_RESPONSE();
  }, [CKYC.CKYC_DETAILS]);

  const RECORD_CKYC_RESPONSE = () => {
    const onSuccess = (res: any) => {
      console.log("res...", res?.results);
    };
    const onError = (err: any) => {
      console.log("err..", err);
    };
    let param = {
      details: CKYC.CKYC_DETAILS,
      section: "kyc_details",
      quote_no: ADD_FORM_RESPONSE.quote_no,
      cover: `${ADD_FORM?.spouse_status ? "2A" : "1A"}${
        (parseInt(ADD_FORM?.son_count?.value) || 0) +
          (parseInt(ADD_FORM?.daughter_count?.value) || 0) >
        0
          ? `${
              (parseInt(ADD_FORM?.son_count?.value) || 0) +
              (parseInt(ADD_FORM?.daughter_count?.value) || 0)
            }C`
          : ""
      }`,
    };
    HEALTH_PROPOSAL_SERVICES.getCapturedRecordData(onSuccess, onError, param);
  };
  return (
    <>
      {isMobile ? (
        <MAddonDetailsKotak
          addonStatus={addonStatus}
          validate={validate}
          PAGE_STATUS={PAGE_STATUS}
          isLoading={loader}
          updateMasterState={updateMasterState}
        />
      ) : (
        <AddonDetailsKotak
          addonStatus={addonStatus}
          validate={validate}
          PAGE_STATUS={PAGE_STATUS}
          isLoading={loader}
          updateMasterState={updateMasterState}
        />
      )}
    </>
  );
};

export default AddonContainerKotak;
