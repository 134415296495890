import { useEffect, useState } from "react";
import ProposerDetails from "../../../../../Page/Desktop/TermFullJourney/ICICI/Proposal/ProposerDetails/ProposerDetails";
import MProposerDetails from "../../../../../Page/Mobile/TermFullJourney/ICICI/Proposal/ProposerDetails/MProposerDetails";
import { CreatePaymentLinkDTO } from "../../../../../Services/DTO/TermFullJourney/CreatePaymentLinkDTO";
import { ProposerDetailsAPIDTO } from "../../../../../Services/DTO/TermFullJourney/ProposerDetailsAPIDTO";
import { PROPOSAL_PAGE_SERVICES } from "../../../../../Services/TermFullJourneyServices/ProposalPageServices";
import { TermFullJourneySlice } from "../../../../../Store/Slice_Reducer/TermFullJourney/TermFullJourneySlice";
import { useAppDispatch, useAppSelector } from "../../../../../Store/hooks";
import {
  FORMAT_YYYY_MM_DD,
  calculate_age,
  isEmpty,
  validateAadharNumber,
  validateEmail,
  validateFullName,
  validateMobileNumber,
  validatePAN,
} from "../../../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";
import { TInsuredMemberDetails } from "../../../../../types/TermFullJourney/TInsuredMemberDetails";
import {
  PROPOSAL_PAGE_SECTION_STATUS_API,
  TProposerDetails,
} from "../../../../../types/TermFullJourney/TProposerDetails";
import { TDropdown } from "../../../../../types/Common/TDropdown";

interface DataItem {
  key: string;
  value: string;
}
const ProposerDetailsContainer = () => {
  const [loader, setLoader] = useState<boolean>(false);
  const {
    SELECTED_QUOTE_DATA,
    ADD_FORM,
    ADD_FORM_RESPONSE,
    QUOTE_FIELDS_DATA,
    PROPOSER_DATA,
  } = useAppSelector((state) => state.Term);
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();
  const state = useAppSelector((state) => state);
  const { insuredDetails, proposerDetails } = useAppSelector(
    (state) => state.TermFullJourney
  );
  const [proposerDetail, setProposerDetails] =
    useState<TProposerDetails>(proposerDetails);
  const [insuredMemberDetails, setInsuredMemberDetails] =
    useState<TInsuredMemberDetails>(insuredDetails);

  useEffect(() => {
    getOccupationList();
    getMaritalStatusList();
    getInsuranceCompanyMaster();
    getDropdownMaster();
    getEducationMaster();

    setProposerDetails({
      ...state.TermFullJourney.proposerDetails,
      full_name: { value: "", warning: false },
      mobile: { value: ADD_FORM.mobile.value, warning: false },
      email: { value: "", warning: false },
      gender: { value: ADD_FORM.gender.value, warning: false },
      dob: {
        value: PROPOSER_DATA.dob.value,
        warning: false,
      },
    });
  }, [SELECTED_QUOTE_DATA]);

  const getOccupationList = () => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;

      if (error === false) {
        const occupationData: Array<TDropdown> = response.map((obj: any) => {
          return {
            value: obj.code,
            label: obj.title,
          };
        });

        dispatch(
          TermFullJourneySlice.actions.setOccupationData(occupationData)
        );
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      setLoader(false);
    };

    let params = {
      product_type_code: "TP",
      product_code: state.Term.SELECTED_QUOTE_DATA.productDetails.product_code,
      buy_online_code:
        state.Term.SELECTED_QUOTE_DATA.productDetails.buy_online_code,
    };
    PROPOSAL_PAGE_SERVICES.GET_OCCUPATION_LIST(onSuccess, onError, params);
  };

  function replaceKeyWithLabel(data: any) {
    // Create a new object to store transformed data
    const transformedData: any = {};

    // Iterate through each property of the original data
    for (const prop in data) {
      // Map over the array and replace 'key' with 'label'
      transformedData[prop] = data[prop].map((item: DataItem) => ({
        label: item.value,
        value: item.key,
      }));
    }
    return transformedData;
  }

  const getDropdownMaster = () => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = results.error;
      const message = results.message;
      let response = results.response;
      if (error === false) {
        console.log(response);
        // let buyingPolicy_reponse: Array<any> = response.buyingPolicy;
        // let buyingPolicy_reponse_manupulated: TDropdown[] = [];
        // buyingPolicy_reponse.forEach((element) => {
        //   buyingPolicy_reponse_manupulated.push({
        //     value: element.key,
        //     label: element.value,
        //   });
        // });
        // response = {
        //   ...response,
        //   buyingPolicy: buyingPolicy_reponse_manupulated,
        // };
        dispatch(
          TermFullJourneySlice.actions.UPDATE_DROPDOWN_MASTER_DATA(
            replaceKeyWithLabel(response)
          )
        );
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      setLoader(false);
    };
    let params = {
      product_type_code: "TP",
      product_code: state.Term.SELECTED_QUOTE_DATA.productDetails.product_code,
      buy_online_code:
        state.Term.SELECTED_QUOTE_DATA.productDetails.buy_online_code,
    };
    PROPOSAL_PAGE_SERVICES.getDropdownDataMasterICICI(
      onSuccess,
      onError,
      params
    );
  };

  const getEducationMaster = () => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;
      console.log("sdfdgrfhfh", response);
      if (error === false) {
        const data: Array<TDropdown> = response.map((obj: any) => {
          return {
            value: obj.code,
            label: obj.title,
          };
        });

        dispatch(TermFullJourneySlice.actions.setEducationMaster(data));
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      setLoader(false);
    };

    let params = {
      product_type_code: "TP",
      product_code: state.Term.SELECTED_QUOTE_DATA.productDetails.product_code,
      buy_online_code:
        state.Term.SELECTED_QUOTE_DATA.productDetails.buy_online_code,
    };

    PROPOSAL_PAGE_SERVICES.GET_EDUCATION_MASTER(onSuccess, onError, params);
  };

  const getMaritalStatusList = () => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;
      if (error === false) {
        const maritalStatusData: Array<TDropdown> = response.map((obj: any) => {
          return {
            value: obj.code,
            label: obj.title,
          };
        });

        dispatch(
          TermFullJourneySlice.actions.setMaritalStatusData(maritalStatusData)
        );
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      setLoader(false);
    };
    PROPOSAL_PAGE_SERVICES.getMaritalStatusList(onSuccess, onError, {
      product_code: SELECTED_QUOTE_DATA.productDetails.product_code,
      product_type_code: "TP",
      buy_online_code: SELECTED_QUOTE_DATA.productDetails.buy_online_code,
    });
  };

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName[0] === "is_proposer_is_insured_member") {
      attrName[1]((prev: any) => ({
        ...prev,
        [attrName[0]]: value,
      }));
    } else if (attrName[0] === "full_name") {
      attrName[1]((prev: any) => ({
        ...prev,
        [attrName[0]]: {
          value: value,
          warning: !validateFullName(value),
        },
      }));
    } else if (attrName[0] === "mobile") {
      attrName[1]((prev: any) => ({
        ...prev,
        [attrName[0]]: {
          value: value,
          warning: !validateMobileNumber(value),
        },
      }));
    } else if (attrName[0] === "emergency_number") {
      attrName[1]((prev: any) => ({
        ...prev,
        [attrName[0]]: {
          value: value,
          warning: !validateMobileNumber(value),
        },
      }));
    } else if (attrName[0] === "email") {
      attrName[1]((prev: any) => ({
        ...prev,
        [attrName[0]]: {
          value: value,
          warning: !validateEmail(value),
        },
      }));
    } else if (attrName[0] === "aadhar_number") {
      attrName[1]((prev: any) => ({
        ...prev,
        [attrName[0]]: {
          value: value,
          warning: !validateAadharNumber(value),
        },
      }));
    } else if (attrName[0] === "occupation") {
      attrName[1]((prev: any) => ({
        ...prev,
        [attrName[0]]: {
          value: value,
          warning: attrName[2] === false ? false : isEmpty(value),
        },
      }));
      if (value === "BSEM") {
        attrName[1]((prev: any) => ({
          ...prev,
          ["org_name"]: {
            value: "Others",
            warning: false,
          },
        }));
      }
    } else if (attrName[0] === "pan_no") {
      attrName[1]((prev: any) => ({
        ...prev,
        [attrName[0]]: {
          value: value,
          warning: !validatePAN(value),
        },
      }));
    } else if (attrName[0] === "nationality") {
      attrName[1]((prev: any) => ({
        ...prev,
        [attrName[0]]: value,
      }));
    } else if (attrName[0] === "marital_status") {
      setProposerDetails((prev) => ({
        ...prev,
        marital_status: { value: value, warning: isEmpty(value) },
      }));
    } else if (attrName[0] === "education") {
      setProposerDetails((prev) => ({
        ...prev,
        education: { value: value, warning: isEmpty(value) },
      }));
    } else if (attrName[0] === "org_type") {
      setProposerDetails((prev) => ({
        ...prev,
        org_type: { value: value, warning: isEmpty(value) },
      }));
    } else if (attrName[0] === "occupation") {
      setProposerDetails((prev) => ({
        ...prev,
        occupation: { value: value, warning: isEmpty(value) },
      }));
    } else if (attrName[0] === "org_name") {
      setProposerDetails((prev) => ({
        ...prev,
        org_name: { value: value, warning: isEmpty(value) },
      }));
    } else if (attrName[0] === "obj_of_buying_policy") {
      setProposerDetails((prev) => ({
        ...prev,
        obj_of_buying_policy: { value: value, warning: isEmpty(value) },
      }));
    } else if (attrName[0] === "annual_incomr") {
      setProposerDetails((prev) => ({
        ...prev,
        annual_income: { value: value, warning: false },
      }));
    } else {
      attrName[1]((prev: any) => ({
        ...prev,
        [attrName[0]]: {
          value: value,
          warning: isEmpty(value),
        },
      }));
    }
  };

  const validateForm = () => {
    let proposer_details_data: TProposerDetails = { ...proposerDetail };
    let insured_member_data: TInsuredMemberDetails = {
      ...insuredMemberDetails,
    };

    proposer_details_data = {
      ...proposer_details_data,
      full_name: {
        ...proposer_details_data.full_name,
        warning: !validateFullName(
          `${proposer_details_data?.full_name?.value}`
        ),
      },
    };
    proposer_details_data = {
      ...proposer_details_data,
      dob: {
        ...proposer_details_data.dob,
        warning: isEmpty(proposer_details_data?.dob?.value),
      },
    };
    proposer_details_data = {
      ...proposer_details_data,
      gender: {
        ...proposer_details_data.gender,
        warning: isEmpty(proposer_details_data?.gender?.value),
      },
    };
    proposer_details_data = {
      ...proposer_details_data,
      mobile: {
        ...proposer_details_data.mobile,
        warning: !validateMobileNumber(proposer_details_data.mobile.value),
      },
    };
    proposer_details_data = {
      ...proposer_details_data,
      email: {
        ...proposer_details_data.email,
        warning: !validateEmail(`${proposer_details_data.email.value}`),
      },
    };
    proposer_details_data = {
      ...proposer_details_data,
      aadhar_number: {
        ...proposer_details_data.aadhar_number,
        warning: !validateAadharNumber(
          `${proposer_details_data.aadhar_number?.value}`
        ),
      },
    };
    proposer_details_data = {
      ...proposer_details_data,
      emergency_number: {
        ...proposer_details_data.emergency_number,
        warning: !validateMobileNumber(
          proposer_details_data.emergency_number.value
        ),
      },
    };
    proposer_details_data = {
      ...proposer_details_data,
      marital_status: {
        ...proposer_details_data.marital_status,
        warning: isEmpty(proposer_details_data?.marital_status?.value),
      },
    };
    proposer_details_data = {
      ...proposer_details_data,
      annual_income: {
        ...proposer_details_data.annual_income,
        warning: isEmpty(proposer_details_data?.annual_income?.value),
      },
    };
    proposer_details_data = {
      ...proposer_details_data,
      education: {
        ...proposer_details_data.education,
        warning: isEmpty(proposer_details_data?.education?.value),
      },
    };
    proposer_details_data = {
      ...proposer_details_data,
      occupation: {
        ...proposer_details_data.occupation,
        warning: isEmpty(proposer_details_data?.occupation?.value),
      },
    };

    proposer_details_data = {
      ...proposer_details_data,
      pan_no: {
        ...proposer_details_data.pan_no,
        warning: !validatePAN(`${proposer_details_data?.pan_no?.value}`),
      },
    };

    proposer_details_data = {
      ...proposer_details_data,
      obj_of_buying_policy: {
        ...proposer_details_data.obj_of_buying_policy,
        warning: isEmpty(proposer_details_data?.obj_of_buying_policy?.value),
      },
    };
    if (proposerDetail.occupation.value === "SPRO") {
      proposer_details_data = {
        ...proposer_details_data,
        occupation_desc: {
          ...proposer_details_data.occupation_desc,
          warning: isEmpty(proposer_details_data?.occupation_desc?.value),
        },
      };
    }
    if (
      proposerDetail.occupation.value === "SPVT" ||
      proposerDetail.occupation.value === "BSEM" ||
      proposerDetail.occupation.value === "SPRO"
    ) {
      proposer_details_data = {
        ...proposer_details_data,
        org_name: {
          ...proposer_details_data.org_name,
          warning: isEmpty(proposer_details_data?.org_name?.value),
        },
      };
      if (proposerDetail.org_name.value === "NOT ANSWERED") {
        proposer_details_data = {
          ...proposer_details_data,
          org_description: {
            ...proposer_details_data.org_description,
            warning: isEmpty(proposer_details_data?.org_description?.value),
          },
        };
      }
      proposer_details_data = {
        ...proposer_details_data,
        org_type: {
          ...proposer_details_data.org_type,
          warning: isEmpty(proposer_details_data?.org_type?.value),
        },
      };
      if (proposerDetail.org_type.value === "NOT ANSWERED") {
        proposer_details_data = {
          ...proposer_details_data,
          org_type_desc: {
            ...proposer_details_data.org_type_desc,
            warning: isEmpty(proposer_details_data?.org_type_desc?.value),
          },
        };
      }
    }

    if (proposerDetail.occupation.value === "PROF") {
      proposer_details_data = {
        ...proposer_details_data,
        my_profession: {
          ...proposer_details_data.my_profession,
          warning: isEmpty(proposer_details_data?.my_profession?.value),
        },
      };
    }

    if (!proposer_details_data.is_proposer_is_insured_member) {
      insured_member_data = {
        ...insured_member_data,
        full_name: {
          ...insured_member_data.full_name,
          warning: !validateFullName(`${insured_member_data.full_name.value}`),
        },
      };
      insured_member_data = {
        ...insured_member_data,
        dob: {
          ...insured_member_data.dob,
          warning: isEmpty(insured_member_data?.dob?.value),
        },
      };
      insured_member_data = {
        ...insured_member_data,
        gender: {
          ...insured_member_data.gender,
          warning: isEmpty(insured_member_data?.gender?.value),
        },
      };
      insured_member_data = {
        ...insured_member_data,
        aadhar_number: {
          ...insured_member_data.aadhar_number,
          warning: !validateAadharNumber(
            `${insured_member_data.aadhar_number.value}`
          ),
        },
      };
      insured_member_data = {
        ...insured_member_data,
        marital_status: {
          ...insured_member_data.marital_status,
          warning: isEmpty(insured_member_data?.marital_status?.value),
        },
      };
      insured_member_data = {
        ...insured_member_data,
        occupation: {
          ...insured_member_data.occupation,
          warning: isEmpty(insured_member_data?.occupation?.value),
        },
      };

      if (
        insuredMemberDetails.occupation.value === "SPVT" ||
        insuredMemberDetails.occupation.value === "BSEM" ||
        insuredMemberDetails.occupation.value === "SPRO" ||
        insuredMemberDetails.occupation.value === "PROF"
      ) {
        insured_member_data = {
          ...insured_member_data,
          org_name: {
            ...insured_member_data.org_name,
            warning: isEmpty(insured_member_data?.org_name?.value),
          },
        };

        insured_member_data = {
          ...insured_member_data,
          org_desc: {
            ...insured_member_data.org_desc,
            warning: isEmpty(insured_member_data?.org_desc?.value),
          },
        };
      }
    } else {
      insured_member_data = {
        full_name: proposer_details_data.full_name,
        email: proposer_details_data.email,
        dob: proposer_details_data.dob,
        gender: proposer_details_data.gender,
        mobile: proposer_details_data.mobile,
        aadhar_number: proposer_details_data.aadhar_number,
        marital_status: proposer_details_data.marital_status,
        occupation: proposer_details_data.occupation,
        org_desc: proposer_details_data.org_description,
        org_name: proposer_details_data.org_name,
        education: proposer_details_data.education,
      };
    }
    setProposerDetails(proposer_details_data);
    setInsuredMemberDetails(insured_member_data);

    let hasError = false;

    if (
      proposer_details_data.full_name.warning === true ||
      proposer_details_data.dob.warning === true ||
      proposer_details_data.gender.warning === true ||
      proposer_details_data.mobile.warning === true ||
      proposer_details_data.email.warning === true ||
      proposer_details_data.aadhar_number.warning === true ||
      proposer_details_data.marital_status.warning === true ||
      proposer_details_data.annual_income.warning === true ||
      proposer_details_data.occupation.warning === true ||
      proposer_details_data.org_name.warning === true ||
      proposer_details_data.org_type.warning === true ||
      proposer_details_data.org_description.warning === true ||
      proposer_details_data.industry_type.warning === true ||
      proposer_details_data.industry_type_desc.warning === true ||
      proposer_details_data.obj_of_buying_policy.warning === true ||
      proposer_details_data.pan_no.warning === true
    ) {
      hasError = true;
    }
    if (proposer_details_data.is_proposer_is_insured_member === false) {
      if (
        insured_member_data.full_name.warning === true ||
        insured_member_data.email.warning === true ||
        insured_member_data.dob.warning === true ||
        insured_member_data.gender.warning === true ||
        insured_member_data.mobile.warning === true ||
        insured_member_data.aadhar_number.warning === true ||
        insured_member_data.marital_status.warning === true ||
        insured_member_data.occupation.warning === true ||
        insured_member_data.org_name.warning === true ||
        insured_member_data.org_desc.warning === true
      ) {
        hasError = true;
      }
    }

    if (!hasError) {
      dispatch(
        TermFullJourneySlice.actions.setProposerDetails(proposer_details_data)
      );
      dispatch(
        TermFullJourneySlice.actions.setInsuredDetails(insured_member_data)
      );

      ICICI_GET_PREMIUM(insured_member_data, proposer_details_data);
    }
  };

  const ICICI_GET_PREMIUM = (
    insuredMemberDetails: TInsuredMemberDetails,
    proposerDetail: TProposerDetails
  ) => {
    const onSuccess = (data: any) => {
      const error = data.error;
      if (error === false) {
        GET_REDIRECTION_URL();
        // updateProposerDetailsData(insuredMemberDetails, proposerDetail);
      }
    };
    const onError = (err: any) => {
      setLoader(false);
    };
    let seprateProposerDate = proposerDetail?.dob?.value.split("-");
    let seprateDate = insuredMemberDetails?.dob?.value.split("-");
    setLoader(true);
    const data = {
      buy_online_code: "IPRU",
      quote_no: btoa(ADD_FORM_RESPONSE.quote_no),
      section: "proposer_details",
      details: {
        insured_date: seprateDate[2],
        insured_month: seprateDate[1],
        insured_year: seprateDate[0],
        proposer_date: seprateProposerDate[2],
        proposer_month: seprateProposerDate[1],
        proposer_year: seprateProposerDate[0],
        proposer_relationship: "Yes",
        aadhar_number: proposerDetail.aadhar_number.value,
        insured_aadhar: insuredMemberDetails.aadhar_number.value,
        insured_dob: insuredMemberDetails.dob.value,
        insured_email: insuredMemberDetails.email.value,
        insured_gender: insuredMemberDetails.gender.value,
        insured_mobile: insuredMemberDetails.mobile.value,
        insured_name: insuredMemberDetails.full_name.value,
        insured_occupation: insuredMemberDetails.occupation.value,
        insured_nameOfOrg: insuredMemberDetails.org_name.value,
        insured_nameOfOrgDesc: insuredMemberDetails.org_desc.value,
        premium: SELECTED_QUOTE_DATA.premiumDetails.premium,
        proposer_annual_income: proposerDetail.annual_income.value,
        proposer_dob: proposerDetail.dob.value,
        proposer_email: proposerDetail.email.value,
        proposer_emergency_no: proposerDetail.emergency_number.value,
        proposer_gender: proposerDetail.gender.value,
        proposer_mobile: proposerDetail.mobile.value,
        proposer_name: proposerDetail.full_name.value,
        proposer_education: proposerDetail.education.value,
        proposer_occupation: proposerDetail.occupation.value,
        proposer_occDesc: proposerDetail.occupation_desc.value,
        proposer_nameOfOrg: proposerDetail.org_name.value,
        proposer_nameOfOrgDesc: proposerDetail.org_description.value,
        proposer_myProf: "",
        proposer_indsType: "",
        proposer_indsTypeDesc: "",
        proposer_orgType: proposerDetail.org_type.value,
        proposer_orgTypeDesc: proposerDetail.org_type_desc.value,
        proposer_objBuyingPolicy: proposerDetail.obj_of_buying_policy.value,
        insured_education: insuredMemberDetails.education.value,
      },
    };

    PROPOSAL_PAGE_SERVICES.termGetPremiumICICI(onSuccess, onError, data);
  };

  const GET_REDIRECTION_URL = async () => {
    setLoader(true);
    const onSuccess = (res: any) => {
      setLoader(false);
      const error = res.error;
      const response = res.response;
      if (!error) {
        setLoader(false);
        console.log(response);
        const application_url = response[0].application_url;

        window.location.href = application_url;
      }
    };
    const onError = () => {
      setLoader(false);
    };
    const params = {
      product_ids: [SELECTED_QUOTE_DATA.productDetails.id],
      quote_no: ADD_FORM_RESPONSE.quote_no,
      criticalIllnessSelected: "No",
      accidentalDeathSelected: "No",
      criticalIllnessValue: "",
      accidentalDeathValue: "",

      occupation: proposerDetail.occupation.value,
    };
    PROPOSAL_PAGE_SERVICES.GET_REDIRECTION_URL(onSuccess, onError, params);
  };

  const updateProposerDetailsData = (
    insuredMemberDetails: TInsuredMemberDetails,
    proposerDetail: TProposerDetails
  ) => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = results.error;
      const message = results.message;

      if (error === false) {
        createPaymentLink();
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      setLoader(false);
    };

    const dob = `${FORMAT_YYYY_MM_DD(`${proposerDetail.dob.value}`)}`.split(
      "-"
    );
    const insured_dob = `${FORMAT_YYYY_MM_DD(
      `${insuredMemberDetails.dob.value}`
    )}`.split("-");
    const data: ProposerDetailsAPIDTO = {
      buy_online_code: SELECTED_QUOTE_DATA.productDetails.buy_online_code,
      quote_no: btoa(ADD_FORM_RESPONSE.quote_no),
      section: PROPOSAL_PAGE_SECTION_STATUS_API.PROPOSAL_DETAILS,
      details: {
        insured_date: insured_dob[1],
        insured_month: insured_dob[2],
        insured_year: insured_dob[0],
        proposer_date: dob[1],
        proposer_month: dob[2],
        proposer_year: dob[0],
        proposer_relationship:
          proposerDetail.is_proposer_is_insured_member === true ? "Yes" : "No",
        aadhar_number: proposerDetail.aadhar_number.value,
        insured_aadhar: insuredMemberDetails.aadhar_number.value,
        insured_dob: FORMAT_YYYY_MM_DD(`${insuredMemberDetails.dob.value}`),
        insured_email: insuredMemberDetails.email.value,
        insured_gender: insuredMemberDetails.gender.value,
        insured_marital_status: insuredMemberDetails.marital_status.value,
        insured_mobile: insuredMemberDetails.mobile.value,
        insured_name: insuredMemberDetails.full_name.value,
        insured_occupation: insuredMemberDetails.occupation.value,
        insured_nameOfOrg: insuredMemberDetails.org_name.value,
        insured_nameOfOrgDesc: insuredMemberDetails.org_desc.value,
        premium: `${SELECTED_QUOTE_DATA.premiumDetails.finalPremium}`,
        proposer_annual_income: proposerDetail.annual_income.value,
        proposer_dob: FORMAT_YYYY_MM_DD(`${proposerDetail.dob.value}`),
        proposer_email: proposerDetail.email.value,
        proposer_emergency_no: proposerDetail.emergency_number.value,
        proposer_gender: proposerDetail.gender.value,
        proposer_marital_status: proposerDetail.marital_status.value,
        proposer_mobile: proposerDetail.mobile.value,
        proposer_name: proposerDetail.full_name.value,
        proposer_education: proposerDetail.education.value,
        proposer_occupation: proposerDetail.occupation.value,
        proposer_occDesc: proposerDetail.occupation_desc.value,
        proposer_nameOfOrg: proposerDetail.org_name.value,
        proposer_nameOfOrgDesc: proposerDetail.org_description.value,
        proposer_myProf: proposerDetail.my_profession.value,
        proposer_indsType: proposerDetail.industry_type.value,
        proposer_indsTypeDesc: proposerDetail.industry_type_desc.value,
        proposer_orgType: proposerDetail.org_type.value,
        proposer_orgTypeDesc: proposerDetail.org_type_desc.value,
        proposer_objBuyingPolicy: proposerDetail.obj_of_buying_policy.value,
        insured_education: insuredMemberDetails.education.value,
      },
    };
    setLoader(true);

    PROPOSAL_PAGE_SERVICES.updateProposalPageDetails(onSuccess, onError, data);
  };

  const createPaymentLink = () => {
    const onSuccess = (data: any) => {
      setLoader(false);
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;

      if (error === false) {
        const PAYMENT_URL = response.paymentUrl;

        dispatch(TermFullJourneySlice.actions.SAVE_PAYMENT_URL(PAYMENT_URL));
        window.location.href = PAYMENT_URL;
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      setLoader(false);
    };

    const data: CreatePaymentLinkDTO = {
      buy_online_code: SELECTED_QUOTE_DATA.productDetails.buy_online_code,
      product_id: `${SELECTED_QUOTE_DATA.productDetails.id}`,
      product_name: SELECTED_QUOTE_DATA.productDetails.product_name,
      quote_no: btoa(ADD_FORM_RESPONSE.quote_no),
    };
    PROPOSAL_PAGE_SERVICES.createPaymentLink(onSuccess, onError, data);
  };

  const getInsuranceCompanyMaster = () => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;

      if (error === false) {
        let data: TDropdown[] = response.map((obj: any) => {
          return {
            value: obj.code,
            label: obj.title,
          };
        });

        dispatch(TermFullJourneySlice.actions.setInsuranceCompany(data));
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {};
    PROPOSAL_PAGE_SERVICES.getInsuranceCompanyMaster(onSuccess, onError, {
      product_code: SELECTED_QUOTE_DATA.productDetails.product_code,
      product_type_code: "TP",
      buy_online_code: SELECTED_QUOTE_DATA.productDetails.buy_online_code,
      company_code: state.Term.SELECTED_QUOTE_DATA.companyDetails.company_code,
    });
  };

  return (
    <>
      {isMobile ? (
        <MProposerDetails
          proposerDetails={proposerDetail}
          setProposerDetails={setProposerDetails}
          insuredMemberDetails={insuredMemberDetails}
          setInsuredMemberDetails={setInsuredMemberDetails}
          updateMasterState={updateMasterState}
          validateForm={validateForm}
          loader={loader}
        />
      ) : (
        <ProposerDetails
          proposerDetails={proposerDetail}
          setProposerDetails={setProposerDetails}
          insuredMemberDetails={insuredMemberDetails}
          setInsuredMemberDetails={setInsuredMemberDetails}
          updateMasterState={updateMasterState}
          validateForm={validateForm}
          loader={loader}
        />
      )}
    </>
  );
};

export default ProposerDetailsContainer;
