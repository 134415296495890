import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import BajajManualKYC from "../../../Page/Desktop/CKYC/Bajaj/BajajManualKYC";
import MBajajManualKYC from "../../../Page/Mobile/CKYC/Bajaj/MBajajManualKYC";
import { CKYCServcies } from "../../../Services/CKYC/CKYCServices";
import { CAR_SERVICES } from "../../../Services/Car/CarServices";
import { TW_ADD_FORM_SERVICES } from "../../../Services/TW/TWAddFormServices";
import { CKYCSlice } from "../../../Store/Slice_Reducer/CKYC/CKYCSlice";
import { CommonDropdownSlice } from "../../../Store/Slice_Reducer/CommonDropdown/CommonDropdownSlice";
import { HealthSlice } from "../../../Store/Slice_Reducer/Health/HealthSlice";
import { PaymentGatewaySlice } from "../../../Store/Slice_Reducer/PaymentGateway/PaymentGatewaySlice";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import { CAR_CREATE_PROPOSAL_REQUEST } from "../../../SupportingFiles/CarHelper";
import {
  isEmpty,
  validateFullName,
} from "../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { TW_CREATE_PROPOSAL_REQUEST } from "../../../SupportingFiles/TWHelper";
import { CLIENTS } from "../../../URLCollection/Clients";
import { TManualCKYCForm } from "../../../types/CKYC/TCKYC";
import { TDropdown } from "../../../types/Common/TDropdown";
import {
  pushMotorDataLayer,
  sendEventToNativeApp,
} from "../../../utils/analytics";

function BajajManualKYCContainer() {
  const [loader, setLoader] = useState<boolean>(false);
  const [showManualPopup, setShowManualPopup] = useState<boolean>(false);
  const [showCKYCDetailsPopup, setShowCKYCDetailsPopup] =
    useState<boolean>(false);
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const REDUX_HEAD = useAppSelector((state) => state);
  const { ADD_FORM_RESPONSE } = useAppSelector((state) => state.TW);
  const { TW, CKYC, Car, Health } = useAppSelector((state) => state);
  const [formDetails, setFormDetails] = useState<TManualCKYCForm>({
    customer_type: { value: "Individual", warning: false },
    dob: { value: "", warning: false },
    doc_type_id_proof: { value: "", warning: false },
    doc_no_id_proof: { value: "", warning: false },
    doc_id_proof_image: { value: "", warning: false },
    full_name: { value: "", warning: false },
    gender: { value: "", warning: false },
    doc_address_proof_image: { value: "", warning: false },
    doc_type_address_proof: { value: "", warning: false },
  });
  const [pageStatus, setPageStatus] = useState<string>("");
  const { VERIFY_KYC_FORM } = useAppSelector((state) => state.CKYC);

  useEffect(() => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const type = params.get("type");

    if (type) {
      setPageStatus(type);
    }
  }, []);

  console.log(pageStatus, "pageStatus");

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "full_name") {
      setFormDetails((prev) => ({
        ...prev,
        [attrName]: { value: value, warning: !validateFullName(value) },
      }));
    } else if (attrName === "doc_no_id_proof") {
      setFormDetails((prev) => ({
        ...prev,
        [attrName]: { value: value, warning: isEmpty(value) },
      }));
    } else if (attrName === "customer_type") {
      setFormDetails((prev) => ({
        ...prev,
        [attrName]: { value: value, warning: isEmpty(value) },
        dob: { value: "", warning: false },
      }));
    } else if (
      attrName === "doc_address_proof_image" ||
      attrName === "doc_id_proof_image"
    ) {
      setFormDetails((prev) => ({
        ...prev,
        [attrName]: { value: value, warning: false },
      }));
    } else {
      setFormDetails((prev) => ({
        ...prev,
        [attrName]: { value: value, warning: isEmpty(value) },
      }));
    }
  };

  useEffect(() => {
    setFormDetails((prev) => ({
      ...prev,
      customer_type: REDUX_HEAD.CKYC.VERIFY_KYC_FORM.customer_type,
    }));
  }, []);

  useEffect(() => {
    GET_DROPDOWN_MASTER("POV");
    dispatch(
      CommonDropdownSlice.actions.UPDATE_DROPDOWN_DATA({
        key: "POV",
        value: [
          { label: "Driving License", value: "D" },
          { label: "GSTIN", value: "G" },
          { label: "NREGA Job card", value: "F" },
          { label: "Passport", value: "A" },
          { label: "UID", value: "E" },
          { label: "Voter ID", value: "B" },
        ],
      })
    );
  }, []);

  const GET_DROPDOWN_MASTER = (TYPE: "POI" | "POA" | "POV") => {
    const onSuccess = (res: any) => {
      const results = res.results;
      const error = results.error;

      if (!error) {
        const response = results.response;
        const manupulated_data: TDropdown[] = Object.entries(response).map(
          ([key, value]) => ({
            label: `${value}`,
            value: `${key}`,
          })
        );

        dispatch(
          CommonDropdownSlice.actions.UPDATE_DROPDOWN_DATA({
            key: TYPE,
            value: manupulated_data,
          })
        );
      }
    };
    const onError = (error: any) => {};

    switch (pageStatus) {
      case "CAR":
        const car_params = {
          company_code: `${REDUX_HEAD.Car.SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.company_code}`,
          list_for: TYPE,
        };
        CKYCServcies.CKYC_DROPDOWN_MASTER(onSuccess, onError, car_params);
        break;
      case "TW":
        const tw_params = {
          company_code: `${REDUX_HEAD.TW.SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.company_code}`,
          list_for: TYPE,
        };
        CKYCServcies.CKYC_DROPDOWN_MASTER(onSuccess, onError, tw_params);
        break;
      case "TERM":
        break;
      case "HP":
        const health_params = {
          company_code: `${REDUX_HEAD.Health.SELECTED_QUOTE_DATA.CompanyDetails.company_code}`,
          list_for: TYPE,
        };
        CKYCServcies.CKYC_DROPDOWN_MASTER(onSuccess, onError, health_params);
        break;
      default:
        break;
    }
  };

  const validateForm = () => {
    let data: TManualCKYCForm = { ...formDetails };

    if (CKYC.CKYC_DETAILS.poiStatus !== "FOUND") {
      data = {
        ...data,
        doc_type_id_proof: {
          ...data.doc_type_id_proof,
          warning: isEmpty(data.doc_type_id_proof.value),
        },
        doc_id_proof_image: {
          ...data.doc_id_proof_image,
          warning: isEmpty(data.doc_id_proof_image.value),
        },
      };
    } else {
      data = {
        ...data,
        doc_type_id_proof: {
          ...data.doc_type_id_proof,
          warning: false,
        },
        doc_id_proof_image: {
          ...data.doc_id_proof_image,
          warning: false,
        },
      };
    }

    if (CKYC.CKYC_DETAILS.poaStatus !== "FOUND") {
      data = {
        ...data,
        doc_type_address_proof: {
          ...data.doc_type_address_proof,
          warning: isEmpty(data.doc_type_address_proof.value),
        },
        doc_address_proof_image: {
          ...data.doc_address_proof_image,
          warning: isEmpty(data.doc_address_proof_image.value),
        },
      };
    } else {
      data = {
        ...data,
        doc_type_address_proof: {
          ...data.doc_type_address_proof,
          warning: false,
        },
        doc_address_proof_image: {
          ...data.doc_address_proof_image,
          warning: false,
        },
      };
    }

    setFormDetails(data);

    let hasError =
      data.doc_type_address_proof.warning ||
      // data.doc_address_proof_image.warning ||
      data.doc_type_id_proof.warning ||
      data.doc_id_proof_image.warning;

    if (
      data.doc_id_proof_image.warning
      //  ||
      // data.doc_address_proof_image.warning
    ) {
      toast.error("Please attach documents");
    }

    if (!hasError) {
      MANUAL_CKYC_DETAILS(data);
    }
  };

  const MANUAL_CKYC_DETAILS = (data: TManualCKYCForm) => {
    const onSuccess = (res: any) => {
      setLoader(false);
      const results = res.results;
      const error = results.error;
      if (!error) {
        const message = results.message;
        const response = results.response;
        if (window.location.hostname === CLIENTS.JIO) {
          pushMotorDataLayer(
            "jioinsure_flow_details",
            "ckyc details success",
            "na",
            pageStatus === "TW"
              ? "two wheeler"
              : pageStatus?.toLocaleLowerCase(),

            Car.REDIRECTED ? "JFS app" : "Insure website"
          );
          const eventProps = {
            action: "ckyc",
            status: "ckyc details success",
            message: "na",
            type:
              pageStatus === "TW"
                ? "two wheeler"
                : pageStatus?.toLocaleLowerCase(),
            EntrySource: Car.REDIRECTED ? "JFS app" : "Insure website",
          };
          sendEventToNativeApp("jioinsure_flow_details", eventProps);
        }
        if (message === "No record found") {
          const kycManualURL = response.kycManualURL;
          setShowManualPopup(true);
        } else {
          dispatch(
            CKYCSlice.actions.BULK_UPDATE({
              ...CKYC,
              CKYC_DETAILS: response,
            })
          );
          setShowCKYCDetailsPopup(true);
        }
      } else {
        const message = results.message;
        toast.error(`${message}`);
        setShowManualPopup(true);
        if (window.location.hostname === CLIENTS.JIO) {
          pushMotorDataLayer(
            "jioinsure_flow_details",
            `${message}`,
            "failure message",
            pageStatus === "TW"
              ? "two wheeler"
              : pageStatus?.toLocaleLowerCase(),

            Car.REDIRECTED ? "JFS app" : "Insure website"
          );
          const eventProps = {
            action: "ckyc",
            status: "ckyc details failure",
            message: `${message}`,
            type:
              pageStatus === "TW"
                ? "two wheeler"
                : pageStatus?.toLocaleLowerCase(),
            EntrySource: Car.REDIRECTED ? "JFS app" : "Insure website",
          };
          sendEventToNativeApp("jioinsure_flow_details", eventProps);
        }
      }
    };

    const onError = (err: any) => {
      setLoader(false);
      console.log(err);
    };

    setLoader(true);
    switch (pageStatus) {
      case "TW":
        let tw_dto = {
          quote_no: TW.ADD_FORM_RESPONSE.quote_no,
          cusType: data.customer_type.value,
          kycCusName: TW.OWNER_DETAILS.name.value,
          dobForKyc: TW.OWNER_DETAILS.dob.value,
          docNameForKyc: data.doc_type_address_proof.value,
          docIDNoForKyc: data.doc_no_id_proof.value,
          genderForKyc: TW.OWNER_DETAILS.gender.value,
          insuranceType: "TW",
          correlationId:
            REDUX_HEAD.TW.SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails
              .correlationId,
          ckycdetails: "doc",
          docNameForKyc1: data.doc_type_address_proof.value,
          indetityDocument: data.doc_id_proof_image.value,
          addressDocument: data.doc_address_proof_image.value,
          transactionId: `${REDUX_HEAD.TW.SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails.pTransactionId}`,
          section: "premium_details",
          insurance_type: pageStatus,
        };

        CKYCServcies.BAJAJ_MANUAL_KYC(onSuccess, onError, tw_dto);
        break;

      case "CAR":
        let car_dto = {
          quote_no: Car.ADD_FORM_RESPONSE.quote_no,
          cusType: data.customer_type.value,
          kycCusName: Car.OWNER_DETAILS.name.value,
          dobForKyc: Car.OWNER_DETAILS.dob.value,
          docNameForKyc: data.doc_type_address_proof.value,
          docIDNoForKyc: data.doc_no_id_proof.value,
          genderForKyc: Car.OWNER_DETAILS.gender.value,
          insuranceType: "CAR",
          correlationId:
            REDUX_HEAD.Car.SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails
              .correlationId,
          ckycdetails: "doc",
          docNameForKyc1: data.doc_type_address_proof.value,
          indetityDocument: data.doc_id_proof_image.value,
          addressDocument: data.doc_address_proof_image.value,
          transactionId: `${REDUX_HEAD.Car.SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails.pTransactionId}`,
          section: "premium_details",
          insurance_type: pageStatus,
        };

        CKYCServcies.BAJAJ_MANUAL_KYC(onSuccess, onError, car_dto);
        break;

      case "HP":
        let health_dto = {
          quote_no: Health.ADD_FORM_RESPONSE.quote_no,
          product_code: Health.SELECTED_QUOTE_DATA.productDetails.product_code,
          product_id: Health.SELECTED_QUOTE_DATA.productDetails.id,
          cusType: data.customer_type.value,
          kycCusName: data.full_name.value,
          dobForKyc: data.dob.value,
          docNameForKyc: data.doc_type_address_proof.value,
          docIDNoForKyc: data.doc_no_id_proof.value,
          genderForKyc: data.gender.value,
          insuranceType: "health",
          ckycdetails: "doc",
          docNameForKyc1: data.doc_type_address_proof.value,
          indetityDocument: data.doc_id_proof_image.value,
          addressDocument: data.doc_address_proof_image.value,
          section: "premium_details",
          insurance_type: pageStatus,
        };

        CKYCServcies.BAJAJ_MANUAL_HEALTH_KYC(onSuccess, onError, health_dto);
        break;
      default:
        break;
    }
  };

  const CREATE_PROPOSAL = () => {
    const onSuccess = (res: any) => {
      const results = res.results;
      const error = results.error;

      setTimeout(() => {
        setLoader(false);
      }, 3000);

      if (error) {
        const message = results.message;
        toast.error(`${message}`);
      } else {
        const response = results.response;

        const paymentRequest = response["paymentRequest"];
        const actionURL = paymentRequest["actionURL"];
        const actionMethod = paymentRequest["actionMethod"];

        let payment_gateway_url = "";

        if (actionMethod === "POST") {
          let form = document.createElement("form");
          form.action = actionURL;
          form.method = actionMethod;

          Object.entries(paymentRequest).forEach(([key, value]) => {
            if (key !== "actionURL" && key !== "actionMethod") {
              const input = document.createElement("input");
              input.type = "hidden";
              input.name = key;
              input.value = `${value}`;
              form.appendChild(input);
            }
          });

          document.body.appendChild(form);
          form.submit();
        } else {
          payment_gateway_url = actionURL;
          window.location.href = actionURL;
        }

        dispatch(
          PaymentGatewaySlice.actions.UPDATE_DATA({
            METHOD: actionMethod,
            PAYMENT_URL: payment_gateway_url,
          })
        );
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
    };

    setLoader(true);

    switch (pageStatus) {
      case "TW":
        let param = TW_CREATE_PROPOSAL_REQUEST(TW, CKYC, ADD_FORM_RESPONSE);
        TW_ADD_FORM_SERVICES.PAYMENT(onSuccess, onError, param);
        break;
      case "CAR":
        let car_param = CAR_CREATE_PROPOSAL_REQUEST(
          Car,
          CKYC,
          ADD_FORM_RESPONSE
        );
        CAR_SERVICES.CREATE_PROPOSAL(onSuccess, onError, car_param);
        break;
      default:
        break;
    }
  };

  const CONTINUE_TO_PROPOSAL = () => {
    switch (pageStatus) {
      case "TW":
        CREATE_PROPOSAL();
        break;
      case "CAR":
        CREATE_PROPOSAL();
        break;
      case "HP":
        dispatch(
          HealthSlice.actions.BULK_UPLOAD({
            ...Health,
            INSURED_MEMBER_DETAILS: {
              ...Health.INSURED_MEMBER_DETAILS,
              PROPOSER_DETAILS: {
                ...Health.INSURED_MEMBER_DETAILS.PROPOSER_DETAILS,
                name: {
                  value: CKYC.CKYC_DETAILS.kycCustomerName,
                  warning: false,
                },
                dob: {
                  value: CKYC.CKYC_DETAILS.kycDOB,
                  warning: false,
                },
                mobile: { value: CKYC.CKYC_DETAILS.kycMobile, warning: false },
                emergencyPhone: { value: "", warning: false },
                email: { value: CKYC.CKYC_DETAILS.kycEmail, warning: false },
                aadhar: { value: "", warning: false },
                gender: {
                  value: VERIFY_KYC_FORM.gender.value,
                  warning: false,
                },
                maritalStatus: { value: "", warning: false },
                weight: { value: "", warning: false },
                heightFeet: { value: "", warning: false },
                heightInches: { value: "", warning: false },
                occupation: { value: "", warning: false },
                relationship: { value: "", warning: false },
                proposer_name: { value: "", warning: false },
              },
            },
            ADDRESS_DETAILS: {
              ...Health.ADDRESS_DETAILS,
              pincode: { value: CKYC.CKYC_DETAILS.kycPincode, warning: false },
              city: CKYC.CKYC_DETAILS.kycCity,
              state: CKYC.CKYC_DETAILS.kycState,
              addressLineOne: {
                value: CKYC.CKYC_DETAILS.kycAddress1,
                warning: false,
              },
              addressLineTwo: {
                value: CKYC.CKYC_DETAILS.kycAddress2,
                warning: false,
              },
              isMailingAddressSame: true,
              mailingPinCode: { value: "", warning: false },
              mailingCity: "",
              mailingState: "",
              mailingAddressLineOne: { value: "", warning: false },
              mailingAddressLineTwo: { value: "", warning: false },
            },
          })
        );
        break;
      default:
        break;
    }
  };
  return (
    <>
      {isMobile ? (
        <MBajajManualKYC
          updateMasterState={updateMasterState}
          formDetails={formDetails}
          validateForm={validateForm}
          CONTINUE_TO_PROPOSAL={CONTINUE_TO_PROPOSAL}
          setShowCKYCDetailsPopup={setShowCKYCDetailsPopup}
          setShowManualPopup={setShowManualPopup}
          showCKYCDetailsPopup={showCKYCDetailsPopup}
          showManualPopup={showManualPopup}
          loader={loader}
        />
      ) : (
        <BajajManualKYC
          updateMasterState={updateMasterState}
          formDetails={formDetails}
          validateForm={validateForm}
          CONTINUE_TO_PROPOSAL={CONTINUE_TO_PROPOSAL}
          setShowCKYCDetailsPopup={setShowCKYCDetailsPopup}
          setShowManualPopup={setShowManualPopup}
          showCKYCDetailsPopup={showCKYCDetailsPopup}
          showManualPopup={showManualPopup}
          loader={loader}
        />
      )}
    </>
  );
}

export default BajajManualKYCContainer;
