import React from "react";
import "./ToggleSwitch.scss";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
interface Props {
  toggle_status: boolean | undefined;
  value_update: Function | undefined;
  attrName: any;
  disabled?: boolean;
}

const ToggleSwitch: React.FC<Props> = ({
  toggle_status,
  value_update,
  attrName,
  disabled,
}) => {
  return (
    <ToggleButtonGroup
      className="switch"
      value={toggle_status}
      exclusive
      onChange={(e, value) => {
        if (value === null) return;
        value_update?.(attrName, value);
      }}
      disabled={disabled}>
      <ToggleButton id="Yes" value={true}>
        Yes
      </ToggleButton>
      <ToggleButton id="No" value={false}>
        No
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default ToggleSwitch;
