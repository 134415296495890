import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { ETermCompanyCode } from "../../../../../Enum/ETermCompanyCode";
import NomineeDetails from "../../../../../Page/Desktop/TermFullJourney/HDFC/Proposal/NomineeDetails/NomineeDetails";
import MNomineeDetails from "../../../../../Page/Mobile/TermFullJourney/HDFC/Proposal/NomineeDetails/MNomineeDetails";
import { TERM_FULL_JOURNEY_ROUTES } from "../../../../../Router/Path/TermFullJourneyRoutes";
import { NomineeDetailsAPIDTO } from "../../../../../Services/DTO/TermFullJourney/NomineeDetailsAPIDTO";
import { PROPOSAL_PAGE_SERVICES } from "../../../../../Services/TermFullJourneyServices/ProposalPageServices";
import { TermFullJourneySlice } from "../../../../../Store/Slice_Reducer/TermFullJourney/TermFullJourneySlice";
import { useAppDispatch, useAppSelector } from "../../../../../Store/hooks";
import {
  FORMAT_DATE_DD_MM_YYYY,
  validateFullName,
} from "../../../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";
import { TDropdown } from "../../../../../types/TermFullJourney/TDropdown";
import { TNomineeDetails } from "../../../../../types/TermFullJourney/TNomineeDetails";
import { PROPOSAL_PAGE_SECTION_STATUS_API } from "../../../../../types/TermFullJourney/TProposerDetails";

function NomineeDetailsContainer() {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const [loader, setLoader] = useState<boolean>(false);
  const proposerPage = useAppSelector(
    (state) => state.TermFullJourney.proposerPage
  );
  const { SELECTED_QUOTE_DATA, ADD_FORM_RESPONSE } = useAppSelector(
    (state) => state.Term
  );
  const nominee_details = useAppSelector(
    (state) => state.TermFullJourney.nomineeDetails
  );

  const [nomineeDetails, setNomineeDetails] =
    useState<TNomineeDetails>(nominee_details);

  useEffect(() => {
    getNomineeRelationshipList();
  }, []);

  const getNomineeRelationshipList = () => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;

      if (error === false) {
        const relationshipData: Array<TDropdown> = response.map((obj: any) => {
          return {
            value: obj.code,
            label: obj.title,
          };
        });

        dispatch(
          TermFullJourneySlice.actions.setNomineeRelationshipData(
            relationshipData
          )
        );
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      console.log(err);
    };
    PROPOSAL_PAGE_SERVICES.getNomineeRelationshipList(onSuccess, onError, {
      product_code: SELECTED_QUOTE_DATA.productDetails.product_code,
      product_type_code: "TP",
      buy_online_code: SELECTED_QUOTE_DATA.productDetails.buy_online_code,
    });
  };

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName[0] === "full_name") {
      attrName[1]((prev: any) => ({
        ...prev,
        [attrName[0]]: {
          value: value,
          warning: !validateFullName(value),
        },
      }));
    } else {
      attrName[1]((prev: any) => ({
        ...prev,
        [attrName[0]]: {
          value: value,
          warning:
            attrName[2] === false
              ? false
              : value.toString().length > 0
              ? false
              : true,
        },
      }));
    }
  };

  const validateForm = () => {
    var nominee_details: TNomineeDetails = { ...nomineeDetails };

    nominee_details = {
      ...nominee_details,
      full_name: {
        ...nominee_details.full_name,
        warning: !validateFullName(`${nominee_details?.full_name?.value}`),
      },
    };
    nominee_details = {
      ...nominee_details,
      dob: {
        ...nominee_details.dob,
        warning: nominee_details?.dob?.value
          ? nominee_details?.dob?.value.toString().length > 0
            ? false
            : true
          : true,
      },
    };
    if (
      SELECTED_QUOTE_DATA.companyDetails.company_code !== ETermCompanyCode.ICICI
    ) {
      nominee_details = {
        ...nominee_details,
        gender: {
          ...nominee_details.gender,
          warning: nominee_details?.gender?.value
            ? nominee_details?.gender?.value.toString().length > 0
              ? false
              : true
            : true,
        },
      };
    }
    nominee_details = {
      ...nominee_details,
      relationship: {
        ...nominee_details.relationship,
        warning: nominee_details?.relationship?.value
          ? nominee_details?.relationship?.value.toString().length > 0
            ? false
            : true
          : true,
      },
    };

    setNomineeDetails(nominee_details);

    var hasError = false;
    if (
      nominee_details.full_name.warning === true ||
      nominee_details.dob.warning === true ||
      nominee_details.gender.warning === true ||
      nominee_details.relationship.warning === true
    ) {
      hasError = true;
    }

    if (!hasError) {
      dispatch(TermFullJourneySlice.actions.setNomineeDetails(nominee_details));

      updateNomineeDetailsData(nominee_details);
    }
    console.log("hasError", hasError);
  };

  const updateNomineeDetailsData = (nominee_details: TNomineeDetails) => {
    const onSuccess = (data: any) => {
      setLoader(false);
      const error = data.error;
      const message = data.message;
      const response = data.response;
      console.log(error);
      if (error === false) {
        if (proposerPage.update_status) {
          navigate(-1);
          dispatch(TermFullJourneySlice.actions.updateUpdateStatus(false));
        } else {
          navigate(TERM_FULL_JOURNEY_ROUTES.ADDRESS_DETAILS);
        }
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      setLoader(false);
      console.log(err);
    };

    const data: NomineeDetailsAPIDTO = {
      buy_online_code: SELECTED_QUOTE_DATA.productDetails.buy_online_code,
      quote_no: btoa(ADD_FORM_RESPONSE.quote_no),
      section: PROPOSAL_PAGE_SECTION_STATUS_API.NOMINEE_DETAILS,
      details: {
        nominee_dob: FORMAT_DATE_DD_MM_YYYY(nominee_details.dob.value),
        nominee_gender: nomineeDetails.gender.value,
        nominee_name: nominee_details.full_name.value,
        nominee_relationship: nominee_details.relationship.value,
      },
    };
    setLoader(true);
    if (
      SELECTED_QUOTE_DATA.companyDetails.company_code === ETermCompanyCode.HDFC
    ) {
      PROPOSAL_PAGE_SERVICES.updateProposalPageHDFCDetails(
        onSuccess,
        onError,
        data
      );
    } else {
      PROPOSAL_PAGE_SERVICES.updateProposalPageDetails(
        onSuccess,
        onError,
        data
      );
    }
  };

  return (
    <>
      {isMobile ? (
        <MNomineeDetails
          nomineeDetails={nomineeDetails}
          setNomineeDetails={setNomineeDetails}
          continueFunction={validateForm}
          updateMasterState={updateMasterState}
        />
      ) : (
        <NomineeDetails
          nomineeDetails={nomineeDetails}
          setNomineeDetails={setNomineeDetails}
          continueFunction={validateForm}
          updateMasterState={updateMasterState}
        />
      )}
    </>
  );
}

export default NomineeDetailsContainer;
