import { PatchAPI, PostAPI } from "../../Axios/AxiosInstanceToken";
import { TravelURLs } from "../../URLCollection/Travel/TravelURLS";

const getPeriumByAddon = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    TravelURLs.GET_PREIUM_BY_ADDON,
    dto,
    (res: any) => onSuccess(res.data),
    (res: any) => onError(res.data)
  );
};

const getOccupationData = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: {
    product_code: string;
    product_type_code: string;
  }
) => {
  PostAPI.call(
    TravelURLs.GET_OCCUPATION_DATA,
    dto,
    (res: any) => onSuccess(res.data),
    (res: any) => onError(res.data)
  );
};

const getNomineeRelationData = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: {
    product_type_code: string;
    product_code?: string;
  }
) => {
  PostAPI.call(
    TravelURLs.NOMINEE_RELATION_LIST,
    dto,
    (res: any) => onSuccess(res.data),
    (res: any) => onError(res.data)
  );
};

const getCapturedRecordData = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  console.log("dto>>", dto);
  PatchAPI.call(
    TravelURLs.Capture_Record,
    dto,
    (res: any) => onSuccess(res.data),
    (res: any) => onError(res.data)
  );
};
const makePayment = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    TravelURLs.MAKE_PAYMENT,
    dto,
    (res: any) => onSuccess(res.data),
    (res: any) => onError(res.data)
  );
};
const getMedicalData = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PatchAPI.call(
    TravelURLs.MEDICAL_DATA,
    dto,
    (res: any) => onSuccess(res.data),
    (res: any) => onError(res.data)
  );
};

export const TRAVEL_PROPOSAL_SERVICES = {
  getPeriumByAddon,
  getMedicalData,
  getNomineeRelationData,
  getOccupationData,
  getCapturedRecordData,
  makePayment,
};
