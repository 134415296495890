import { useState } from "react";
import {
  TBank_Details_AdityaBirla,
  TBank_Details_NIVABUPA,
} from "../../../types/Health/THealthSlice";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import {
  isEmpty,
  validateIFSC,
} from "../../../SupportingFiles/HelpingFunction";
import { HealthSlice } from "../../../Store/Slice_Reducer/Health/HealthSlice";
import { useNavigate } from "react-router-dom";
import { HEALTH_ROUTE } from "../../../Router/Path/HealthRoutes";
import { HEALTH_PROPOSAL_SERVICES } from "../../../Services/health-php/HealthPropsalServices";
import BankDetailsAdityaBirla from "../../../Page/Desktop/health-php/ADITYA_BIRLA/BankDetails/BankDetailsAdityaBirla";

const AdityaBirlaBankDetailsContainer = () => {
  const { Health } = useAppSelector((state) => state);
  const navigate = useNavigate();
  const { BANK_DETAILS_ADITYABIRLA, ADD_FORM_RESPONSE, ADD_FORM } =
    useAppSelector((state) => state.Health);
  const [disbaledButton, setDisbaledButton] = useState(false);
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const [BankDetails, setBankDetails] = useState<TBank_Details_AdityaBirla>({
    ...BANK_DETAILS_ADITYABIRLA,
  });

  const getCapturedRecordData = () => {
    const onSuccess = (res: any) => {
      console.log("res", res);
    };

    const onError = (err: any) => {
      console.log("err..", err);
    };

    let param = {
      Bank_Account_No: BankDetails.Bank_Account_No.value,
      Bank_Account_Type: BankDetails.Bank_Account_Type.value,
      Bank_Name: BankDetails.Bank_Name.value,
      IFSC_Code: BankDetails.IFSC_Code.value,
      Branch_Name: BankDetails.Branch_Name.value,
      MICR_Code: BankDetails.MICR_Code.value,
      quote_no: ADD_FORM_RESPONSE.quote_no,
      section: "bank_details",
      cover: `${ADD_FORM?.spouse_status ? "2A" : "1A"}${
        (parseInt(ADD_FORM?.son_count?.value) || 0) +
          (parseInt(ADD_FORM?.daughter_count?.value) || 0) >
        0
          ? `${
              (parseInt(ADD_FORM?.son_count?.value) || 0) +
              (parseInt(ADD_FORM?.daughter_count?.value) || 0)
            }C`
          : ""
      }`,
    };
    HEALTH_PROPOSAL_SERVICES.getCapturedRecordData(onSuccess, onError, param);
  };

  const validateForm = () => {
    let bank_details_data = { ...BankDetails };

    bank_details_data = {
      ...bank_details_data,
      Bank_Account_No: {
        ...bank_details_data.Bank_Account_No,
        warning: isEmpty(`${bank_details_data.Bank_Account_No.value} `),
      },
      Bank_Account_Type: {
        ...bank_details_data.Bank_Account_Type,
        warning: isEmpty(`${bank_details_data.Bank_Account_Type.value} `),
      },
      Bank_Name: {
        ...bank_details_data.Bank_Name,
        warning: isEmpty(`${bank_details_data.Bank_Name.value} `),
      },
      IFSC_Code: {
        ...bank_details_data.IFSC_Code,
        warning: !validateIFSC(`${bank_details_data.IFSC_Code.value} `),
      },
    };

    setBankDetails({ ...bank_details_data });
    let hasError = false;
    if (
      bank_details_data.Bank_Account_No.warning ||
      bank_details_data.Bank_Account_Type.warning ||
      bank_details_data.Bank_Name.warning ||
      bank_details_data.IFSC_Code.warning ||
      hasError
    ) {
      hasError = true;
    }

    console.log(hasError, "hasError", bank_details_data);
    if (!hasError) {
      dispatch(
        HealthSlice.actions.BULK_UPLOAD({
          ...Health,
          BANK_DETAILS_ADITYABIRLA: {
            Bank_Account_No: BankDetails.Bank_Account_No,
            Bank_Account_Type: BankDetails.Bank_Account_Type,
            Bank_Name: BankDetails.Bank_Name,
            IFSC_Code: BankDetails.IFSC_Code,
            Branch_Name: BankDetails.Branch_Name,
            MICR_Code: BankDetails.MICR_Code,
          },
        })
      );
      getCapturedRecordData();
      navigate(
        `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.ADDRESS_DETAILS}?type=HP&companyCode=${Health?.SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${Health?.ADD_FORM_RESPONSE.quote_no}&product_id=${Health?.SELECTED_QUOTE_DATA?.productDetails.id}`
      );
    }
  };

  const updateBankDetail = (
    attrName: keyof TBank_Details_AdityaBirla,
    value: string
  ) => {
    console.log(attrName, "attrName", value);
    setBankDetails((prevState) => ({
      ...prevState,
      [attrName]: { ...prevState[attrName], value },
    }));
  };

  return isMobile ? null : (
    <BankDetailsAdityaBirla
      BankDetails={BankDetails}
      updateBankDetail={updateBankDetail}
      validateForm={validateForm}
      disbaledButton={disbaledButton}
    />
  );
};

export default AdityaBirlaBankDetailsContainer;
